import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../assets/svg/view/close_circle.svg";
import PersonalInfoEdit from "./profile/PersonalInfoEdit";
import ContactInfoEdit from "./profile/ContactInfoEdit";

const UserProfileEdit = ({
  updateData,
  onUpdateChange,
  onSubmit,
  onCancel,
  profileImageId,
  isUpdating,
  theme,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <div className={`GM__${theme}-profile-user-edit`}>
    <button
      className="edit-toggle-btn"
      onClick={onCancel}
      aria-label="Cancelar edición"
    >
      <CloseIcon />
    </button>

    <div className="profile-header">
      <div className="profile-info">
        <form onSubmit={handleSubmit} noValidate>
          <PersonalInfoEdit
            personalInfo={updateData.personal}
            onPersonalInfoChange={(field, value) =>
              onUpdateChange("personal", field, value)
            }
            theme={theme}
          />

          <ContactInfoEdit
            contactInfo={updateData.contact}
            onContactInfoChange={(field, value) =>
              onUpdateChange("contact", field, value)
            }
            theme={theme}
          />

          <div className="form-actions">
            <button
              type="submit"
              className="save-button"
              disabled={isUpdating}
            >
              {isUpdating ? "Guardando..." : "Guardar Cambios"}
            </button>
            <button
              type="button"
              className="cancel-button"
              onClick={onCancel}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  );
};

UserProfileEdit.propTypes = {
  updateData: PropTypes.shape({
    personal: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      firstLastName: PropTypes.string.isRequired,
      secondLastName: PropTypes.string,
      documentNumber: PropTypes.string,
      documentTypeId: PropTypes.number,
      documentExpDate: PropTypes.string,
      documentExpeditionPlace: PropTypes.string,
      nationality_id: PropTypes.number,
      birthDate: PropTypes.string,
    }).isRequired,
    contact: PropTypes.shape({
      primaryPhoneNumber: PropTypes.string,
      primaryPhoneCountryId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      secondaryPhoneNumber: PropTypes.string,
      secondaryPhoneCountryId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      email: PropTypes.string,
      location: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  onUpdateChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  profileImageId: PropTypes.string,
  isUpdating: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default UserProfileEdit;
