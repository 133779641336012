// src/utils/visibilityManager.js
export class VisibilityManager {
    constructor() {
      this.pageVisible = !document.hidden;
      this.listeners = new Set();
      this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
  
    handleVisibilityChange() {
      this.pageVisible = !document.hidden;
      this.listeners.forEach(listener => listener(this.pageVisible));
    }
  
    isVisible() {
      return this.pageVisible;
    }
  
    addListener(listener) {
      this.listeners.add(listener);
      return () => this.listeners.delete(listener);
    }
  
    destroy() {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
      this.listeners.clear();
    }
  }