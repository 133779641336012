import { useState, useCallback } from "react";
import { calculateRoute } from "../../utils/routeUtils";
export const GOOGLE_MAPS_LIBRARIES = Object.freeze(["places", "drawing", "geometry"]);
export const useMapRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const [activeRouteId, setActiveRouteId] = useState(null);


  const updateRoute = useCallback((routeId, updates) => {
    setRoutes((prev) =>
      prev.map((route) =>
        route.id === routeId ? { ...route, ...updates } : route
      )
    );
  }, []);
  const addRoute = useCallback((newRoute) => {
    const routeWithId = {
      ...newRoute,
      id: Date.now().toString(),
      waypoints: newRoute.waypoints || [],
      color: newRoute.color || "#3388ff",
      visible: true,
      opacity: 1,
      weight: 3, // Cambiado a 3 para líneas más delgadas
    };

    setRoutes((prev) => [...prev, routeWithId]);
    setActiveRouteId(routeWithId.id);
    
    // Si hay exactamente 2 waypoints, calcular la ruta inmediatamente
    if (routeWithId.waypoints && routeWithId.waypoints.length >= 2) {
      console.log("Calculando ruta automáticamente al crear");
      calculateRoute(routeWithId.waypoints)
        .then((routeData) => {
          updateRoute(routeWithId.id, { routeData });
        })
        .catch((error) => {
          console.error("Error al calcular la ruta:", error);
        });
    }
    
    return routeWithId.id;
  }, []);
  const addWaypoint = useCallback(
    (routeId, waypoint) => {
      // Obtener los waypoints actualizados primero para asegurar datos consistentes
      let updatedWaypoints = [];
      let routeToUpdate = null;
      
      setRoutes((prev) => {
        return prev.map((route) => {
          if (route.id === routeId) {
            updatedWaypoints = [...route.waypoints, waypoint];
            routeToUpdate = {...route, waypoints: updatedWaypoints};
            return routeToUpdate;
          }
          return route;
        });
      });
  
      // Asegurarnos de que tengamos los waypoints correctos antes de calcular
      if (updatedWaypoints.length >= 2) {
        calculateRoute(updatedWaypoints)
          .then((routeData) => {
            updateRoute(routeId, { routeData });
          })
          .catch((error) => {
            console.error("Error al calcular la ruta:", error);
          });
      }
    },
    [updateRoute]
  );

  const deleteRoute = useCallback((routeId) => {
    setRoutes((prev) => prev.filter((route) => route.id !== routeId));
    if (activeRouteId === routeId) {
      setActiveRouteId(null);
    }
  }, [activeRouteId]);

  const setActiveRoute = useCallback((routeId) => {
    setActiveRouteId(routeId);
  }, []);

  const removeWaypoint = useCallback(
    (routeId, waypointIndex) => {
      setRoutes((prev) =>
        prev.map((route) => {
          if (route.id === routeId) {
            const updatedWaypoints = route.waypoints.filter(
              (_, index) => index !== waypointIndex
            );
            return {
              ...route,
              waypoints: updatedWaypoints,
            };
          }
          return route;
        })
      );

      // Recalcular la ruta si quedan al menos dos puntos
      const route = routes.find((r) => r.id === routeId);
      if (route && route.waypoints.length >= 3) {
        const updatedWaypoints = route.waypoints.filter(
          (_, index) => index !== waypointIndex
        );
        calculateRoute(updatedWaypoints)
          .then((routeData) => {
            updateRoute(routeId, { routeData });
          })
          .catch((error) => {
            console.error("Error al calcular la ruta:", error);
          });
      } else if (route && route.waypoints.length < 3) {
        updateRoute(routeId, { routeData: null });
      }
    },
    [routes, updateRoute]
  );

  return {
    routes,
    activeRouteId,
    addRoute,
    updateRoute,
    deleteRoute,
    setActiveRoute,
    addWaypoint,
    removeWaypoint,
  };
};