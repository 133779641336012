import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { Upload, FileText, Trash2 } from "lucide-react";
import { Pagination } from "@mui/material";

// Importa tu contexto de notificaciones
import {
  useNotification,
  NotificationSeverity,
} from "../context/NotificationContext"; // Ajusta la ruta según tu proyecto

// Iconos para documentos
import PDFIcon from "../assets/svg/view/pdf.svg";
import DOCXIcon from "../assets/svg/view/docx.svg";
import XLSXIcon from "../assets/svg/view/xlsx.svg";

import "../assets/scss/components/FileDropZone.scss";

/**
 * FileDropzone Component
 */
export function FileDropzone({
  onFileUpload,
  size = "medium",
  title = "Arrastra aquí los archivos o haz clic para seleccionarlos",
  icon = null,
  acceptedFileTypes = {
    "application/pdf": [".pdf"],
    "image/*": [".jpg", ".jpeg", ".png"],
    "application/msword": [".doc", ".docx"],
  },
  maxFileSize = 5242880, // 5MB por defecto
  singleFile = false,
  maxColumns = 1,
  externalFiles = null,
  onExternalFileRemove = null,
}) {
  // Para notificaciones
  const { showNotification } = useNotification();

  // multiple = false si singleFile = true
  const multiple = !singleFile;

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Paginación (solo aplica realmente cuando multiple=true)
  const filesPerPage = maxColumns * 3;
  const totalPages = Math.ceil(uploadedFiles.length / filesPerPage);
  const paginatedFiles = uploadedFiles.slice(
    (currentPage - 1) * filesPerPage,
    currentPage * filesPerPage
  );

  // Sincroniza archivos externos (si llegan por props)
  useEffect(() => {
    if (externalFiles !== null) {
      setUploadedFiles(singleFile ? externalFiles.slice(0, 1) : externalFiles);
    }
  }, [externalFiles, singleFile]);

  /**
   * Convierte un archivo a base64
   */
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          name: file.name,
          type: file.type,
          size: file.size,
          content: reader.result,
        });
      reader.onerror = (error) => reject(error);
    });
  };

  /**
   * Maneja el drop de archivos
   */
  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Procesamos cada archivo
      const processed = [];
      for (const file of acceptedFiles) {
        // Revisa si ya existe uno con el mismo nombre
        const isDuplicate = uploadedFiles.some((f) => f.name === file.name);
        if (isDuplicate) {
          // Notifica archivo duplicado
          showNotification({
            message: `El archivo "${file.name}" ya fue agregado.`,
            severity: NotificationSeverity.WARNING,
          });
          continue; // se salta este archivo
        }

        try {
          const base64Content = await convertToBase64(file);
          processed.push({
            file,
            ...base64Content,
            preview: URL.createObjectURL(file),
            uploadedAt: new Date().toISOString(),
          });
        } catch (error) {
          // Si falla la conversión (muy raro), muestra error
          showNotification({
            message: `Error al procesar el archivo "${file.name}".`,
            severity: NotificationSeverity.ERROR,
          });
        }
      }

      // Si no se agregó nada, salimos
      if (processed.length === 0) return;

      // singleFile => solo tomamos el primer archivo
      const updatedFiles = multiple
        ? [...uploadedFiles, ...processed]
        : [processed[0]];

      setUploadedFiles(updatedFiles);
      onFileUpload(updatedFiles);
    },
    [uploadedFiles, onFileUpload, showNotification, multiple]
  );

  /**
   * Remueve un archivo del array
   */
  const handleRemoveFile = (fileIndex) => {
    const updated = [...uploadedFiles];
    const removed = updated.splice(fileIndex, 1)[0];
    setUploadedFiles(updated);
    onFileUpload(updated);

    if (onExternalFileRemove) {
      onExternalFileRemove(removed);
    }
  };

  /**
   * Determina clases de tamaño
   */
  const getSizeClasses = () => {
    switch (size) {
      case "small":
        return "size-small";
      case "large":
        return "size-large";
      default:
        return "size-medium";
    }
  };

  /**
   * Retorna tamaño del ícono
   */
  const getIconSize = () => {
    switch (size) {
      case "small":
        return 32;
      case "large":
        return 48;
      default:
        return 40;
    }
  };

  /**
   * Retorna ícono según el tipo de archivo
   */
  const getFileIcon = (fileType) => {
    const lowerType = fileType.toLowerCase();
    if (lowerType.includes("pdf")) return PDFIcon;
    if (
      lowerType.includes("msword") ||
      lowerType.includes("wordprocessingml")
    )
      return DOCXIcon;
    if (
      lowerType.includes("excel") ||
      lowerType.includes("spreadsheetml") ||
      lowerType.includes("sheet")
    )
      return XLSXIcon;
    return null;
  };

  // Configuración de react-dropzone
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections, // <= archivos que no pasaron validaciones
  } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple,
    maxSize: maxFileSize,
  });

  // Maneja las notificaciones de error de dropzone (por ejemplo, archivos muy grandes)
  useEffect(() => {
    if (fileRejections && fileRejections.length > 0) {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          if (error.code === "file-too-large") {
            showNotification({
              message: `El archivo "${file.name}" excede el tamaño máximo permitido (${(
                maxFileSize /
                1024 /
                1024
              ).toFixed(1)} MB).`,
              severity: NotificationSeverity.ERROR,
            });
          } else if (error.code === "file-invalid-type") {
            showNotification({
              message: `El archivo "${file.name}" no es de un tipo permitido.`,
              severity: NotificationSeverity.ERROR,
            });
          } else {
            showNotification({
              message: `No se pudo subir el archivo "${file.name}".`,
              severity: NotificationSeverity.ERROR,
            });
          }
        });
      });
    }
  }, [fileRejections, maxFileSize, showNotification]);

  // ------------------------------------------------------------------------------
  // RENDERIZADO
  // ------------------------------------------------------------------------------

  // singleFile => Vista especial para 1 archivo
  if (singleFile) {
    return (
      <div
        className={`documents-dropzone-section ${getSizeClasses()}`}
        style={{ "--max-columns": maxColumns }}
      >
        {uploadedFiles.length === 0 ? (
          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? "active" : ""}`}
          >
            <input {...getInputProps({ style: { display: "none" } })} />
            {icon ? <img src={icon} alt="icon" /> : <Upload size={getIconSize()} />}
            <div className="upload-text">{title}</div>
            <div className="upload-subtext">
              Máx. {(maxFileSize / 1024 / 1024).toFixed(1)} MB
            </div>
          </div>
        ) : (
          // Mostrar el único archivo
          <>
            {uploadedFiles.map((fileItem, index) => {
              if (!fileItem) return null;
              const isImage = fileItem.type.toLowerCase().startsWith("image/");
              if (isImage) {
                // Imagen => circular con fondo
                return (
                  <div
                    key={fileItem.name}
                    className="single-file-preview-image"
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "50%",
                      backgroundImage: `url(${fileItem.preview})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="delete-icon"
                      aria-label={`Eliminar ${fileItem.name}`}
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 4,
                        background: "rgba(255,255,255,0.8)",
                        borderRadius: "50%",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Trash2 size={20} color="#ef4444" />
                    </button>
                  </div>
                );
              } else {
                // Documento => ícono
                const fileIcon = getFileIcon(fileItem.type);
                return (
                  <div key={fileItem.name} className="single-file-preview-doc">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                        width: "200px",
                        height: "200px",
                        justifyContent: "center",
                        border: "1px solid #e5e7eb",
                        borderRadius: "50%",
                        background: "#f9fafb",
                      }}
                    >
                      {fileIcon ? (
                        <img
                          src={fileIcon}
                          alt="document-icon"
                          style={{ width: "80px", height: "80px" }}
                        />
                      ) : (
                        <FileText size={40} color="#1a237e" />
                      )}
                      <button
                        onClick={() => handleRemoveFile(index)}
                        className="delete-icon"
                        aria-label={`Eliminar ${fileItem.name}`}
                        style={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          background: "rgba(255,255,255,0.8)",
                          borderRadius: "50%",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        <Trash2 size={20} color="#ef4444" />
                      </button>
                    </div>
                    <p style={{ marginTop: 8, fontWeight: 500 }}>
                      {fileItem.name}
                    </p>
                  </div>
                );
              }
            })}
          </>
        )}
      </div>
    );
  }

  // multiple => Vista original con lista y paginación
  return (
    <div
      className={`documents-dropzone-section ${getSizeClasses()}`}
      style={{ "--max-columns": maxColumns }}
    >
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps({ style: { display: "none" } })} />
        {icon ? <img src={icon} alt="icon" /> : <Upload size={getIconSize()} />}
        <div className="upload-text">{title}</div>
        <div className="upload-subtext">
          Máx. {(maxFileSize / 1024 / 1024).toFixed(1)} MB
        </div>
      </div>

      {uploadedFiles.length > 0 && (
        <div className="uploaded-files">
          {paginatedFiles.map((fileItem, index) => {
            if (!fileItem) return null;
            return (
              <div key={fileItem.name} className="file-item">
                <FileText className="file-icon" size={24} />
                <div className="file-details">
                  <div className="file-name">{fileItem.name}</div>
                  <div className="file-size">
                    {(fileItem.size / 1024 / 1024).toFixed(2)} MB
                  </div>
                </div>
                <button
                  onClick={() =>
                    handleRemoveFile((currentPage - 1) * filesPerPage + index)
                  }
                  className="delete-icon"
                  aria-label={`Eliminar ${fileItem.name}`}
                >
                  <Trash2 size={20} />
                </button>
              </div>
            );
          })}
        </div>
      )}

      {totalPages > 1 && (
        <Pagination
          className="pagination"
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
        />
      )}
    </div>
  );
}

// PropTypes
FileDropzone.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  title: PropTypes.string,
  icon: PropTypes.node,
  acceptedFileTypes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  maxFileSize: PropTypes.number,
  singleFile: PropTypes.bool,
  maxColumns: PropTypes.number,
  externalFiles: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.instanceOf(File),
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      content: PropTypes.string,
      documentTypeId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  ),
  onExternalFileRemove: PropTypes.func,
};

export default FileDropzone;
