import React from "react";
import PropTypes from 'prop-types';
import "../../../assets/scss/components/ui/cards/OperatorCard.scss";
import { usePlateWithVehicle } from "../../../hooks/useVehicleQuery";
import LoadingAnimation from "../../loading/LoadingAnimation";
import UserAvatar from "../avatar/UserAvatar";
import { Link } from "react-router-dom";

const OperatorCard = ({ userId, name, lastName, personImage, documentNumber, theme = "dark" }) => {
  const {
    data: plateWithVehicle,
    isLoading,
    error,
  } = usePlateWithVehicle(userId);

  const vehiclePlate = plateWithVehicle?.license_plate || "";
  
  // Determinar la clase basada en el tema
  const cardClass = `operator-card ${theme === 'light' ? 'theme-light' : 'theme-dark'}`;

  return (
    <Link to={`/persons/profile/${userId}`} className="card-link">
      <div className={cardClass}>
        <div className="card-header">
          <div className="column first">
            <div className="column first-margenv1">
              {isLoading ? (<LoadingAnimation />) : error ? ( "Error al cargar placa") : (vehiclePlate)}
            </div>
            <div className="column first-margen"></div>
          </div>

          <div className="column second">
            <div className="column second-content"></div>
            <div className="column second-margenv2"></div>
            <div className="column second-margenv3"></div>
          </div>
        </div>
        <div className="card-content">
          <div className="image-wrapper">
            <div className="image-border">
              <UserAvatar userId={userId} theme={theme} />
            </div>
          </div>
          <div className="info-wrapper">
            <div className="info">
              <div className="line"></div>
              <span className="text">{name || 'Sin nombre'}</span>
            </div>
            <div className="info">
              <div className="line"></div>
              <span className="text">{lastName || 'Sin apellido'}</span>
            </div>
            <div className="info">
              <div className="line"></div>
              <span className="text">{documentNumber || 'No disponible'}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

OperatorCard.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  lastName: PropTypes.string,
  personImage: PropTypes.string,
  cellPhone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.oneOf(["light", "dark"]),
};

export default OperatorCard;