import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/scss/layout/AppHeader.scss';
import { SwitchTheme } from '../../layout/SwitchTheme';
import LogoImg from '../../assets/img/logotipo_cootranspetrols.png';

export default function AppHeader({ 
  showLogo, 
  documentId, 
  isAuthenticated,
  theme,
  toggleTheme 
}) {
  return (
    <div className={`app-header-main-container ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
      {/* Contenedor principal con fondo igual al layout */}
      <div className="app-header-wrapper">
        {/* Primer div: 85% altura - contenedor del logo */}
        <div className="app-header-logo-section">
          <div className="app-header-logo-section-content">
            {showLogo && (
              <div className="app-header-logo-section-logo-container">
                <img src={LogoImg} alt="Logo" className="app-header-logo" />
              </div>
            )}
          </div>
        </div>
        
        {/* Segundo div: 15% altura - sección inferior */}
        <div className={`${theme === 'dark' ? 'dark-app-header-bottom-section' : 'light-app-header-bottom-section'}`}>
          {/* Div izquierdo para el switch: 100px ancho */}
          <div className="app-header-switch-area">
            {/* Fondo blanco contenedor */}
            <div className="app-header-switch-area-background">
              {/* Área para el switch */}
              <div className="theme-switch-container">
                <div className="sidebar-body-routes-route-icon">
                  <SwitchTheme
                    checked={theme === "dark"}
                    onChange={toggleTheme}
                    sx={{
                      m: 0,
                      "& .MuiSwitch-switchBase": {
                        "&.Mui-checked": {
                          transform: "translateX(22px)",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              
              {/* Área restante */}
              <div className="theme-switch-spacer"></div>
            </div>
          </div>
          
          {/* Div derecho blanco: resto del ancho */}
          <div className="app-header-bottom-spacer"></div>
        </div>
      </div>
    </div>
  );
}

// Definición de PropTypes
AppHeader.propTypes = {
  showLogo: PropTypes.bool,
  documentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAuthenticated: PropTypes.bool,
  theme: PropTypes.string,
  toggleTheme: PropTypes.func
};