import axios from "axios";
//const API_URL = process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL_PRODUCTION;
export const vehicleService = {
  // ========= Vehicles =========
  getVehicles: async (selectedPage, vehiclesPerPage, filter, sortOption) => {
    try {
      const response = await axios.get(`${API_URL}/vehicle`, {
        params: { selectedPage, vehiclesPerPage, filter, sortOption },
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error obteniendo los vehículos:", error);
      throw error.response?.data || error.message;
    }
  },
  getVehiclesWithDocuments: async ({
    page = 1,
    limit = 10,
    search = "",
    vehicleTypeId = null,
    operationId = null,
    documentFilter = "all",
  } = {}) => {
    try {
      const params = new URLSearchParams();
      params.append("page", page);
      params.append("limit", limit);

      if (search) params.append("search", search);
      if (vehicleTypeId) params.append("vehicleTypeId", vehicleTypeId);
      if (operationId) params.append("operationId", operationId);
      params.append("documentFilter", documentFilter);

      const response = await axios.get(
        `${API_URL}/vehicle/vehicles-with-documents?${params.toString()}`
      );
      return response.data;
    } catch (error) {
      console.error("Error obteniendo vehículos con documentos:", error);
      throw error.response?.data || error.message;
    }
  },
  getVehicleCompleteProfile: async (id) => {
    try {
      const response = await axios.get(`${API_URL}/vehicle/${id}/complete-profile`
      );
      return response.data;
    } catch (error) {
      console.error("Error obteniendo perfil completo del vehículo:", error);
      throw error.response?.data || error.message;
    }
  },
  uploadVehicleDocument: async (documentData) => {
    try {
      // Procesamiento de archivo a Base64
      const processFileToBase64 = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(",")[1]);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };
      // Convertir archivo a base64
      const base64Content = await processFileToBase64(documentData.file);

      const payload = {
        documentTypeId: documentData.docType,
        fileName: documentData.fileName || documentData.file.name,
        originalName: documentData.file.name,
        fileType: documentData.file.type,
        fileSize: documentData.file.size,
        content: base64Content,
        expeditionDate: documentData.expeditionDate || null,
        expirationDate: documentData.expirationDate || null,
        originalNameDescription: documentData.description || "",
        required: Boolean(documentData.required),
        vehicleId: documentData.vehicleId,
      };

      const response = await axios.post(
        `${API_URL}/vehicle-documents`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error en uploadVehicleDocument:", error);
      throw error.response?.data || error;
    }
  },

  // Actualizar documento
  updateVehicleDocument: async (docId, updateData) => {
    try {
      // Procesamiento de archivo a Base64
      const processFileToBase64 = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(",")[1]);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };
      let payload = {
        documentTypeId: updateData.docType,
        originalNameDescription: updateData.description || "",
        required: Boolean(updateData.required),
        expeditionDate: updateData.expeditionDate || null,
        expirationDate: updateData.expirationDate || null,
        vehicleId: updateData.vehicleId,
      };

      // Si hay archivo nuevo
      if (updateData.file && updateData.file instanceof File) {
        const base64Content = await processFileToBase64(updateData.file);
        payload = {
          ...payload,
          fileName: updateData.fileName || updateData.file.name,
          fileType: updateData.file.type,
          fileSize: updateData.file.size,
          content: base64Content,
        };
      }

      const response = await axios.put(
        `${API_URL}/vehicle-documents/${docId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error en updateVehicleDocument:", error);
      throw error.response?.data || error;
    }
  },

  // Eliminar documento
  deleteVehicleDocument: async (docId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/vehicle-documents/${docId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error al eliminar documento:", error);
      throw error.response?.data || error;
    }
  },

  // Descargar documento
  downloadVehicleDocument: async (fileId) => {
    try {
      const response = await axios.get(
        `${API_URL}/download-vehicle-document/${fileId}`,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `vehiculo_doc_${fileId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar documento:", error);
      throw error;
    }
  },

  // Descargar múltiples documentos
  downloadMultipleVehicleDocuments: async (fileIds) => {
    try {
      const response = await axios.post(
        `${API_URL}/download-multiple-vehicle-documents`,
        { fileIds, typeData: 2 }, // typeData 2 = vehículos
        {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        }
      );

      if (!(response.data instanceof Blob)) {
        throw new Error("Respuesta inválida del servidor");
      }

      const blob = new Blob([response.data], { type: "application/pdf" });
      if (blob.size === 0) {
        throw new Error("PDF generado está vacío");
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `documentos_vehiculo_${Date.now()}.pdf`);

      setTimeout(() => {
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }, 100);

      return { success: true };
    } catch (error) {
      console.error("Error en la descarga:", error);
      throw error;
    }
  },
  createVehicle: async (vehicleData) => {
    try {
      // Función para procesar el archivo y convertirlo a Base64
      const processFile = async (file) => {
        if (!file) return null;
        // Si ya tenemos el contenido en base64, lo retornamos directamente
        if (typeof file === "string" && file.startsWith("data:")) {
          return file.split(",")[1];
        }
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result.split(",")[1]);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      // Función específica para procesar documentos
      const processDocuments = async (documents, section = "main") => {
        if (!documents || !Array.isArray(documents)) {
          console.warn(
            `No hay documentos para procesar en la sección ${section}`
          );
          return [];
        }

        try {
          const processedDocs = await Promise.all(
            documents.map(async (doc) => {
              if (!doc || !doc.file) return null;

              try {
                const base64Content = await processFile(doc.file);

                // Determinamos la categoría del documento para su organización
                let documentCategory = "documents";
                if (doc.docType) {
                  // Aquí se podría implementar lógica para determinar categorías específicas
                  // basadas en el tipo de documento (licencias, seguros, etc.)
                }

                return {
                  documentTypeId: doc.docType,
                  fileName: doc.fileName || doc.file.name,
                  originalName: doc.file.name,
                  fileType: doc.file.type,
                  fileSize: doc.file.size,
                  content: base64Content,
                  expeditionDate: doc.expeditionDate || null,
                  expirationDate: doc.expirationDate || null,
                  originalNameDescription: doc.description || "",
                  required: Boolean(doc.required),
                  section, // Guardamos la sección (main/trailer)
                  category: documentCategory, // Categoría para organización
                };
              } catch (docError) {
                console.error(
                  `Error procesando documento ${section}:`,
                  docError
                );
                return null;
              }
            })
          );

          // Filtrar documentos nulos
          return processedDocs.filter((doc) => doc !== null);
        } catch (error) {
          console.error(
            `Error en el procesamiento de documentos ${section}:`,
            error
          );
          return [];
        }
      };

      // 1. Procesar información principal del vehículo
      const vehicleMainData = {
        vehicleTypeId: vehicleData.vehicleTypeId,
        plate: (vehicleData.plate || "").toUpperCase().trim(),
        brand: vehicleData.brand,
        model: (vehicleData.model || "").trim(),
        capacity: vehicleData.capacity || null,
        year: vehicleData.year || null,
        ownerId: vehicleData.ownerId || null,
        operatorId: vehicleData.operatorId || null,
        contactInfo: {
          primaryPhoneNumber: (
            vehicleData.contactInfo?.primaryPhoneNumber || ""
          ).trim(),
          primaryPhoneCountryId:
            vehicleData.contactInfo?.primaryPhoneCountryId || null,
          secondaryPhoneNumber:
            (vehicleData.contactInfo?.secondaryPhoneNumber || "").trim() ||
            null,
          secondaryPhoneCountryId:
            vehicleData.contactInfo?.secondaryPhoneCountryId || null,
          nationalityId: vehicleData.contactInfo?.nationalityId || null,
        },
        contractStartDate: vehicleData.contractStartDate || null,
        contractEndDate: vehicleData.contractEndDate || null,
        operationId: vehicleData.operationId || null,
        belongsToOperation: vehicleData.belongsToOperation || false,
        limitedNotifications: vehicleData.limitedNotifications !== false,
        ownFleet: vehicleData.ownFleet || true,
      };

      // 2. Procesar documentos del vehículo principal
      const mainDocuments = await processDocuments(
        vehicleData.documents,
        "main"
      );

      // 3. Construir payload principal
      const payload = {
        ...vehicleMainData,
        documents: mainDocuments,
        trailerRequired: vehicleData.trailerRequired !== false,
      };

      // 4. Procesar remolque si es necesario
      if (vehicleData.trailer && vehicleData.trailerRequired !== false) {
        const trailerDocuments = await processDocuments(
          vehicleData.trailer.documents,
          "trailer"
        );

        // Estructura actualizada para incluir información de contacto del remolque
        payload.trailer = {
          vehicleTypeId: vehicleData.trailer.vehicleTypeId,
          plate: (vehicleData.trailer.plate || "").toUpperCase().trim(),
          brand: vehicleData.trailer.brand,
          model: (vehicleData.trailer.model || "").trim(),
          capacity: vehicleData.trailer.capacity || null,
          year: vehicleData.trailer.year || null,
          ownerId: vehicleData.trailer.ownerId || vehicleData.ownerId || null,
          operatorId: vehicleData.trailer.operatorId || null,
          contactInfo: {
            primaryPhoneNumber: (
              vehicleData.trailer.contactInfo?.primaryPhoneNumber || ""
            ).trim(),
            primaryPhoneCountryId:
              vehicleData.trailer.contactInfo?.primaryPhoneCountryId || null,
            secondaryPhoneNumber:
              (
                vehicleData.trailer.contactInfo?.secondaryPhoneNumber || ""
              ).trim() || null,
            secondaryPhoneCountryId:
              vehicleData.trailer.contactInfo?.secondaryPhoneCountryId || null,
            nationalityId:
              vehicleData.trailer.contactInfo?.nationalityId || null,
          },
          contractStartDate: vehicleData.trailer.contractStartDate || null,
          contractEndDate: vehicleData.trailer.contractEndDate || null,
          documents: trailerDocuments,
          belongsToOperation: vehicleData.trailer.belongsToOperation || vehicleData.belongsToOperation || false,
          limitedNotifications: vehicleData.trailer.limitedNotifications !== false,
          ownFleet: vehicleData.trailer.ownFleet || true,
        };
      }

      // 5. Enviar solicitud al backend

      const response = await axios.post(`${API_URL}/vehicle`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error en el registro de vehículo:", error);
      // Manejo estructurado de errores para proporcionar mensajes útiles
      if (error.response && error.response.data) {
        throw error.response.data;
      } else if (error.request) {
        throw { message: "No se pudo establecer conexión con el servidor" };
      } else {
        throw {
          message:
            error.message || "Error desconocido al procesar la solicitud",
        };
      }
    }
  },
  updateVehicle: async (id, vehicleData) => {
    try {
      // Solo un ejemplo donde “borrado” lógico se haría con active = false
      const response = await axios.put(`${API_URL}/vehicle/${id}`, vehicleData);
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  deleteVehicle: async (id) => {
    try {
      // Borrado lógico: { active: false }
      const response = await axios.patch(`${API_URL}/vehicle/${id}`, {
        active: false,
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  // ========= Vehicle Types =========
  getVehicleTypes: async () => {
    try {
      const response = await axios.get(`${API_URL}/vehicle/types`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  /** Crear un nuevo tipo de vehículo. */
  createVehicleType: async (typeData) => {
    try {
      console.log(`creating ${typeData}`);
      console.log(`${API_URL}/vehicle/types`);
      const response = await axios.post(`${API_URL}/vehicle/types`, typeData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  /** Actualizar un tipo de vehículo. */
  updateVehicleType: async (id, typeData) => {
    try {
      console.log(`updating ${typeData}`);
      const response = await axios.put(
        `${API_URL}/vehicle/types/${id}`,
        typeData
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  /** Borrado lógico: “desactivar” un tipo de vehículo. */
  deleteVehicleType: async (id) => {
    try {
      const response = await axios.patch(`${API_URL}/vehicle/types/${id}`, {
        active: false,
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  // ========= Vehicle BRANDS =========
  getVehicleBrands: async () => {
    try {
      const response = await axios.get(`${API_URL}/vehicle/brands`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  /** Crear una nueva marca de vehículo. */
  createVehicleBrand: async (brandData) => {
    try {
      const response = await axios.post(`${API_URL}/vehicle/brands`, brandData);
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  /** Actualizar una marca de vehículo. */
  updateVehicleBrand: async (id, brandData) => {
    try {
      const response = await axios.put(
        `${API_URL}/vehicle/brands/${id}`,
        brandData
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  /** Borrado lógico: “desactivar” una marca de vehículo. */
  deleteVehicleBrand: async (id) => {
    try {
      const response = await axios.patch(`${API_URL}/vehicle/brands/${id}`, {
        active: false,
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  // ========= OWNERS =========

  createOwner: async (ownerData) => {
    try {
      const response = await axios.post(`${API_URL}/owners`, ownerData);
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  updateOwner: async (id, ownerData) => {
    try {
      const response = await axios.put(`${API_URL}/owners/${id}`, ownerData);
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  deleteOwner: async (id) => {
    try {
      const response = await axios.patch(`${API_URL}/owners/${id}`, {
        active: false,
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  // ========= vehicle and plate =========
  getPlateWithVehicle: async (userId) => {
    try {
      const response = await axios.get(
        `${API_URL}/vehicle/user-vehicles/${userId}`
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  getVehicleWithPlate: async (licensePlate) => {
    try {
      const response = await axios.get(
        `${API_URL}/vehicle//manual-driver/${licensePlate}`
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  // ========= COURSES =========
  getCourses: async () => {
    try {
      console.log("entramos");
      const response = await axios.get(`${API_URL}/transport/courses`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  /** Crear un nuevo curso */
  createCourse: async (courseData) => {
    try {
      const response = await axios.post(
        `${API_URL}/transport/courses`,
        courseData
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  /** Actualizar un curso */
  updateCourse: async (id, courseData) => {
    try {
      const response = await axios.put(
        `${API_URL}/transport/courses/${id}`,
        courseData
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  /** Borrado lógico: "desactivar" un curso */
  deleteCourse: async (id) => {
    try {
      const response = await axios.patch(`${API_URL}/transport/courses/${id}`, {
        active: false,
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  /** Obtener cursos por tipo de vehículo */
  getCoursesByVehicleType: async (vehicleTypeId) => {
    try {
      const response = await axios.get(
        `${API_URL}/transport/vehicle-types/${vehicleTypeId}/courses`
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  /** Asociar un curso a un tipo de vehículo */
  associateCourseToVehicleType: async (
    vehicleTypeId,
    courseId,
    isOptional = false
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/transport/vehicle-types/${vehicleTypeId}/courses/${courseId}`,
        { is_optional: isOptional }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
};
