import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useCommentAndResidenceService } from "../service/commentsAndResidenceService";

export const COMMENTS_KEY = "comments";
export const LOCATIONS_KEY = "locations";

export const useCommentsAndLocations = () => {
  const service = useCommentAndResidenceService();
  const queryClient = useQueryClient();

  // Queries para comentarios
  const useCommentsByReference = (referenceId) => {
    return useQuery({
      queryKey: [COMMENTS_KEY, referenceId],
      queryFn: () => service.getCommentsByReference(referenceId),
      staleTime: 3 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: !!referenceId,
    });
  };

  // Queries para ubicaciones con userId
  const useUserLocations = (userId) => {
    return useQuery({
      queryKey: [LOCATIONS_KEY, userId],
      queryFn: () => service.getUserLocations(userId),
      staleTime: 3 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: !!userId,
    });
  };

  // Mutations para comentarios
  const createCommentMutation = useMutation({
    mutationFn: (data) => service.createComment(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([COMMENTS_KEY, variables.reference_id]);
    },
  });

  const updateCommentMutation = useMutation({
    mutationFn: ({ commentId, data }) => service.updateComment(commentId, data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([COMMENTS_KEY]);
    },
  });

  const deleteCommentMutation = useMutation({
    mutationFn: (commentId) => service.deleteComment(commentId),
    onSuccess: () => {
      queryClient.invalidateQueries([COMMENTS_KEY]);
    },
  });

  // Mutations para ubicaciones
  const createLocationMutation = useMutation({
    mutationFn: (data) => service.createLocation(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([LOCATIONS_KEY, variables.user_id]);
    },
  });

  const updateLocationMutation = useMutation({
    mutationFn: ({ locationId, data }) => service.updateLocation(locationId, data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([LOCATIONS_KEY, variables.data.user_id]);
    },
  });

  const deleteLocationMutation = useMutation({
    mutationFn: (locationId) => service.deleteLocation(locationId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([LOCATIONS_KEY, variables.user_id]);
    },
  });

  const setPrimaryLocationMutation = useMutation({
    mutationFn: (locationId) => service.setPrimaryLocation(locationId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([LOCATIONS_KEY, variables.user_id]);
    },
  });

  return {
    // Queries
    useCommentsByReference,
    useUserLocations,
    
    // Mutations para comentarios
    createComment: createCommentMutation.mutateAsync,
    updateComment: updateCommentMutation.mutateAsync,
    deleteComment: deleteCommentMutation.mutateAsync,
    
    // Mutations para ubicaciones
    createLocation: createLocationMutation.mutateAsync,
    updateLocation: updateLocationMutation.mutateAsync,
    deleteLocation: deleteLocationMutation.mutateAsync,
    setPrimaryLocation: setPrimaryLocationMutation.mutateAsync,
    
    // Estados de mutaciones
    isLoadingMutation: 
      createLocationMutation.isLoading || 
      updateLocationMutation.isLoading || 
      deleteLocationMutation.isLoading || 
      setPrimaryLocationMutation.isLoading,
  };
};