// lib/transportData.js
// Tipos de vehículos disponibles con sus configuraciones
export const vehicleConfigurations = [
    { 
      id: 'volqueta_dos_ejes',
      nombre: 'Volqueta dos ejes - Sencillo', 
      combustible: 'DIESEL',
      pesoMaximo: 10500,
      rendimientoCargado: 7.5,
      rendimientoVacio: 9.5
    },
    { 
      id: 'camion_dos_ejes_sencillo_pbv_8001_9000',
      nombre: 'Camión dos ejes - Sencillo PBV 8001-9000 Kg', 
      combustible: 'DIESEL',
      pesoMaximo: 9000,
      rendimientoCargado: 8.0,
      rendimientoVacio: 10.0
    },
    {
      id: 'camion_dos_ejes_sencillo_pbv_10500',
      nombre: 'Camión dos ejes - Sencillo PBV mas de 10500 Kg',
      combustible: 'DIESEL',
      pesoMaximo: 15000,
      rendimientoCargado: 7.0,
      rendimientoVacio: 9.0
    },
    {
      id: 'camion_tres_ejes_dobletroque',
      nombre: 'Camión tres ejes - Dobletroque',
      combustible: 'DIESEL',
      pesoMaximo: 28000,
      rendimientoCargado: 6.0,
      rendimientoVacio: 8.5
    }
  ];
  
  // Mapa de carrocerías por vehículo
  export const bodyTypesByVehicle = {
    'volqueta_dos_ejes': ['VOLCO'],
    'camion_dos_ejes_sencillo_pbv_8001_9000': ['ESTACAS', 'ESTIBAS', 'FURGON', 'FURGON REFRIGERADO', 'PLATAFORMA'],
    'camion_dos_ejes_sencillo_pbv_10500': ['ESTACAS', 'ESTIBAS', 'FURGON', 'FURGON REFRIGERADO', 'PLATAFORMA', 'PORTACONTENEDORES', 'TANQUE', 'VOLCO'],
    'camion_tres_ejes_dobletroque': ['ESTACAS', 'FURGON', 'TANQUE', 'VOLCO']
  };
  
  // Mapa de tipos de carga por carrocería
  export const cargoTypesByBodyType = {
    'ESTACAS': ['Carga General', 'Animales Vivos', 'Materiales de Construcción'],
    'ESTIBAS': ['Carga General', 'Mercancía Paletizada'],
    'FURGON': ['Carga General', 'Mercancía Seca', 'Electrodomésticos'],
    'FURGON REFRIGERADO': ['Alimentos Perecederos', 'Carga Refrigerada', 'Productos Farmacéuticos'],
    'PLATAFORMA': ['Maquinaria', 'Materiales de Construcción', 'Contenedores'],
    'PORTACONTENEDORES': ['Contenedores'],
    'TANQUE': ['Líquidos Alimenticios', 'Combustible', 'Productos Químicos', 'Granel líquido'],
    'VOLCO': ['Materiales de Construcción', 'Arena', 'Grava', 'Material Pétreo']
  };
  
  // Mapa de condiciones de carga por tipo de carga
  export const cargoConditionsByType = {
    'Carga General': ['CARGADO', 'VACIO'],
    'Alimentos Perecederos': ['Carga Refrigerada'],
    'Carga Refrigerada': ['Carga Refrigerada'],
    'Mercancía Paletizada': ['CARGADO', 'VACIO'],
    'Productos Farmacéuticos': ['CARGADO', 'Carga Refrigerada', 'VACIO'],
    'Contenedores': ['CARGADO', 'VACIO'],
    'Líquidos Alimenticios': ['CARGADO', 'VACIO'],
    'Combustible': ['CARGADO', 'VACIO'],
    'Productos Químicos': ['CARGADO', 'VACIO'],
    'Granel líquido': ['CARGADO', 'VACIO'],
    'Materiales de Construcción': ['CARGADO', 'VACIO'],
    'Arena': ['CARGADO', 'VACIO'],
    'Grava': ['CARGADO', 'VACIO'],
    'Material Pétreo': ['CARGADO', 'VACIO'],
    'Mercancía Seca': ['CARGADO', 'VACIO'],
    'Electrodomésticos': ['CARGADO', 'VACIO'],
    'Maquinaria': ['CARGADO', 'VACIO'],
    'Animales Vivos': ['CARGADO', 'VACIO']
  };
  
  // Precios de combustibles (valores en COP)
  export const fuelPrices = {
    'DIESEL': 9800,
    'GASOLINA': 12500
  };
  
  // Función para calcular costos de transporte
// lib/transportData.js - Función calculateTransportCosts corregida

export const calculateTransportCosts = (params) => {
  const { 
    vehicleConfig, 
    bodyType, 
    cargoType, 
    // Para viaje de ida
    outboundCargoCondition,
    // Para viaje de vuelta (si aplica)
    inboundCargoCondition,
    // Datos geográficos
    distance,
    estimatedDuration,
    // Datos logísticos
    departureDateTime,
    arrivalDateTime,
    loadingHours = 1,
    loadingWaitHours = 0,
    unloadingHours = 1,
    unloadingWaitHours = 0,
    tripsPerMonth = 1,
    routeType = 'oneway',
    // Nuevos parámetros opcionales
    routeCharacteristics,
    generalParameters
  } = params;

  if (!vehicleConfig || !bodyType || !cargoType || !outboundCargoCondition || !distance) {
    return { error: 'Faltan parámetros obligatorios para el cálculo' };
  }

  // Si es viaje de ida y vuelta pero no se ha especificado la condición de regreso
  if (routeType === 'roundtrip' && !inboundCargoCondition) {
    return { error: 'Para viajes de ida y vuelta, debe especificar la condición de carga de regreso' };
  }

  // Buscar vehículo
  const vehicle = vehicleConfigurations.find(v => v.id === vehicleConfig);
  if (!vehicle) {
    return { error: 'Vehículo no encontrado' };
  }

  // Factores de ajuste para topografía
  let fuelEfficiencyFactor = 1.0;
  let timeAdjustmentFactor = 1.0;
  
  // Procesar características topográficas si están disponibles
  if (routeCharacteristics && routeCharacteristics.segments) {
    // Factores de eficiencia por tipo de terreno
    const terrainFactors = {
      'Plano': { fuel: 1.0, time: 1.0 },
      'Pendiente baja positiva': { fuel: 1.15, time: 1.1 },
      'Pendiente baja negativa': { fuel: 0.9, time: 1.0 },
      'Pendiente media positiva': { fuel: 1.3, time: 1.3 },
      'Pendiente media negativa': { fuel: 0.85, time: 1.1 },
      'Pendiente alta positiva': { fuel: 1.5, time: 1.5 },
      'Pendiente alta negativa': { fuel: 0.8, time: 1.2 },
      'Zona urbana': { fuel: 1.25, time: 1.8 },
      'Zona despavimentada': { fuel: 1.4, time: 2.0 }
    };
    
    // Calcular factores ponderados por distancia
    let weightedFuelFactorSum = 0;
    let weightedTimeFactorSum = 0;
    let totalSegmentDistance = 0;
    
    routeCharacteristics.segments.forEach(segment => {
      if (segment.distance > 0) {
        weightedFuelFactorSum += segment.distance * 
          (terrainFactors[segment.type]?.fuel || 1.0);
        
        weightedTimeFactorSum += segment.distance * 
          (terrainFactors[segment.type]?.time || 1.0);
        
        totalSegmentDistance += segment.distance;
      }
    });
    
    if (totalSegmentDistance > 0) {
      fuelEfficiencyFactor = weightedFuelFactorSum / totalSegmentDistance;
      timeAdjustmentFactor = weightedTimeFactorSum / totalSegmentDistance;
    }
  }

  // Ajustar rendimiento de combustible basado en topografía
  const outboundFuelEfficiency = outboundCargoCondition === 'CARGADO' ? 
    vehicle.rendimientoCargado / fuelEfficiencyFactor : 
    vehicle.rendimientoVacio / fuelEfficiencyFactor;
    
  const outboundFuelCost = (distance / outboundFuelEfficiency) * fuelPrices[vehicle.combustible];
  
  // Cálculos para regreso (si aplica)
  let inboundFuelCost = 0;
  let inboundFuelEfficiency = 0;
  if (routeType === 'roundtrip') {
    inboundFuelEfficiency = inboundCargoCondition === 'CARGADO' ? 
      vehicle.rendimientoCargado / fuelEfficiencyFactor : 
      vehicle.rendimientoVacio / fuelEfficiencyFactor;
      
    inboundFuelCost = (distance / inboundFuelEfficiency) * fuelPrices[vehicle.combustible];
  }
  
  // Costo total de combustible
  const totalFuelCost = outboundFuelCost + inboundFuelCost;
  
  // Costo por horas logísticas
  const logisticHourRate = 50000;
  const baseLogisticHours = 4;
  const totalLogisticHours = (
    Number(loadingHours) + 
    Number(loadingWaitHours) + 
    Number(unloadingHours) + 
    Number(unloadingWaitHours)
  );
  const additionalLogisticHours = Math.max(0, totalLogisticHours - baseLogisticHours);
  const logisticHoursCost = additionalLogisticHours * logisticHourRate;
  
  // Costo peajes (simulado por distancia)
  const tollCostPerKm = 250;
  const oneWayTollCost = distance * tollCostPerKm;
  const totalTollCost = routeType === 'roundtrip' ? oneWayTollCost * 2 : oneWayTollCost;
  
  // Costos variables según tipo de carrocería y carga
  let variableCostPerKm = 0;
  if (bodyType === 'FURGON REFRIGERADO' || outboundCargoCondition === 'Carga Refrigerada') {
    variableCostPerKm += 500; // Costo adicional refrigeración
  }
  
  if (bodyType === 'TANQUE') {
    variableCostPerKm += 300; // Costo adicional transporte líquidos
  }

  const totalDistance = routeType === 'roundtrip' ? distance * 2 : distance;
  const variableCost = totalDistance * variableCostPerKm;
  
  // Cálculo de estimación de tiempos usando datos de generalParameters o ajustando por topografía
  let departureTime, arrivalTime, totalTripHours;
  
  // Priorizar tiempos de generalParameters si están disponibles
  if (generalParameters && generalParameters.times && generalParameters.times.totalTripTime) {
    totalTripHours = generalParameters.times.totalTripTime;
    
    // Reconstruir tiempos de salida/llegada si se proporcionaron
    if (departureDateTime) {
      departureTime = new Date(departureDateTime);
      arrivalTime = new Date(departureTime);
      arrivalTime.setHours(arrivalTime.getHours() + totalTripHours);
    }
  } else if (departureDateTime && arrivalDateTime) {
    // Usar tiempos proporcionados directamente
    departureTime = new Date(departureDateTime);
    arrivalTime = new Date(arrivalDateTime);
    
    // Diferencia en milisegundos convertida a horas
    const timeDiffMs = arrivalTime - departureTime;
    totalTripHours = timeDiffMs / (1000 * 60 * 60);
  } else if (estimatedDuration) {
    // Aplicar factor de ajuste topográfico a la duración estimada
    totalTripHours = estimatedDuration * timeAdjustmentFactor + totalLogisticHours;
    
    // Si hay hora de salida pero no de llegada, calculamos la llegada
    if (departureDateTime) {
      departureTime = new Date(departureDateTime);
      arrivalTime = new Date(departureTime);
      arrivalTime.setHours(arrivalTime.getHours() + totalTripHours);
    }
  } else {
    // Estimación aproximada basada en la distancia con factor topográfico
    const baseSpeed = 60; // km/h promedio base
    const adjustedSpeed = baseSpeed / timeAdjustmentFactor;
    const estimatedHours = routeType === 'roundtrip' ? 
      (distance * 2) / adjustedSpeed : distance / adjustedSpeed;
    
    totalTripHours = estimatedHours + totalLogisticHours;
  }
  
  // Cálculo de costo por viaje
  const tripCost = totalFuelCost + logisticHoursCost + totalTollCost + variableCost;
  
  // Costo mensual (multiplicado por el número de viajes)
  const monthlyCost = tripCost * tripsPerMonth;
  
  // Resultado completo con análisis topográfico
  return {
    vehicleDetails: vehicle,
    distance: totalDistance,
    estimatedDuration: totalTripHours,
    departureTime: departureTime ? departureTime.toISOString() : null,
    arrivalTime: arrivalTime ? arrivalTime.toISOString() : null,
    fuelCost: totalFuelCost,
    logisticHoursCost,
    tollCost: totalTollCost,
    variableCost,
    tripCost,
    monthlyCost,
    breakdown: {
      outbound: {
        fuelEfficiency: outboundFuelEfficiency,
        fuelCost: outboundFuelCost,
        distance,
        cargoCondition: outboundCargoCondition
      },
      inbound: routeType === 'roundtrip' ? {
        fuelEfficiency: inboundFuelEfficiency,
        fuelCost: inboundFuelCost,
        distance,
        cargoCondition: inboundCargoCondition
      } : null,
      fuelPrice: fuelPrices[vehicle.combustible],
      totalLogisticHours,
      baseLogisticHours,
      additionalLogisticHours,
      logisticHourRate,
      tripsPerMonth
    },
    // Análisis topográfico (nuevo)
    topographyAnalysis: routeCharacteristics ? {
      fuelEfficiencyFactor,
      timeAdjustmentFactor,
      terrainDistribution: routeCharacteristics.segments.map(s => ({
        type: s.type,
        percentage: s.percentage,
        distance: s.distance
      }))
    } : null
  };
};