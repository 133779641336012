import React from 'react';
import { TextField, Button } from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';
import './PasswordRecoverySection.scss';

const PasswordRecoverySection = () => {
  return (
    <section className="password-recovery-section">
      <h2 className="password-recovery-title">Recuperar contraseña</h2>
      <TextField
        className="password-recovery-input"
        label="Correo electrónico"
        type="email"
        variant="outlined"
        fullWidth
      />
      <div className="password-recovery-actions">
        <Button variant="contained" className="password-recovery-button">
          Enviar solicitud
        </Button>
        <Button
          variant="text"
          className="password-recovery-back-button"
          startIcon={<ArrowBackIosNew />}
        >
          Volver al inicio de sesión
        </Button>
      </div>
    </section>
  );
};

export default PasswordRecoverySection;