import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import CalendarIcon from "../../assets/svg/components/calendar.svg";
import ArrowUpIcon from "../../assets/svg/view/arrow_up.svg";
import LoadingIcon from "../../assets/svg/view/loading.svg";
import UploadSuccessIcon from "../../assets/svg/view/upload_success.svg";
import UploadMandatoryIcon from "../../assets/svg/view/upload_mandatory.svg";
import dayjs from "dayjs";
import {
  useDocumentationAnalytics,
  useVehicleDocumentRequirements,
} from "../../hooks/useDocumentationVehicleQuery";
import SortDropdown from "../ui/dropdawn/SortDropdown";
import CustomDatePicker from "../ui/CustomDatePicker";
import CustomInputField from "../ui/inputs/CustomInputField";

export default function DocumentsSection({
  vehicleTypeId,
  operationId,
  onOperationChange,
  documents,
  onDocumentsChange,
  theme,
  operations,
  requiresOperation = false,
  allowChangeOperation = true,
  isTrailer = false,
  belongsToOperation,
  onBelongsToOperationChange
}) {
  const { useOperationRequirements } = useDocumentationAnalytics();
  
  // Una única consulta para obtener los requisitos por operación
  const { 
    data: operationRequirementsData, 
    isLoading: isLoadingOperationReqs, 
    error: operationReqsError 
  } = useOperationRequirements(operationId, {
    // Solo habilitamos si hay operationId y si estamos en un vehículo principal que requiere operación o es un trailer
    enabled: !!operationId && (isTrailer || requiresOperation),
    onError: (err) => console.error("Error cargando requisitos por operación:", err)
  });
  
  // Consulta de requisitos por tipo de vehículo 
  // Modificado para cargarlos siempre que haya un tipo de vehículo (independientemente de si requiere operación)
  const {
    data: vehicleTypeRequirementsData,
    isLoading: isLoadingVehicleTypeReqs,
    error: vehicleTypeReqsError
  } = useVehicleDocumentRequirements(vehicleTypeId, {
    enabled: !!vehicleTypeId, // Habilitamos siempre que haya un tipo definido
    onError: (err) => console.error("Error cargando requisitos por tipo de vehículo:", err)
  });
  
  // Lógica de carga y error actualizada
  const isLoading = (isTrailer || (requiresOperation && operationId)) ? 
    isLoadingOperationReqs : 
    isLoadingVehicleTypeReqs;
      
  const error = (isTrailer || (requiresOperation && operationId)) ? 
    operationReqsError : 
    vehicleTypeReqsError;
  
  // Función para encontrar los documentos del remolque basados en los datos de operación
  const findTrailerDocuments = (operationData) => {
    if (!operationData || !operationData.vehicleRequirements) return [];
    
    // Buscamos requisitos para tipos de vehículo que sean remolques
    const trailerRequirements = operationData.vehicleRequirements.find(
      req => req.vehicleType.category?.includes('Remolque')
    );
    
    return trailerRequirements?.requiredDocuments || [];
  };
  
  // Lógica de obtención de tipos de documento simplificada
  const requiredDocTypes = useMemo(() => {
    // Caso del remolque - Filtramos para encontrar los documentos de remolque
    if (isTrailer && operationId && operationRequirementsData?.data) {
      return findTrailerDocuments(operationRequirementsData.data);
    }
    
    // Caso de vehículo con operación - Filtramos para el tipo de vehículo específico
    if (requiresOperation && operationId && !isTrailer && operationRequirementsData?.data) {
      const vehicleRequirements = operationRequirementsData.data.vehicleRequirements || [];
      const matchingRequirement = vehicleRequirements.find(
        req => req.vehicleType.id === vehicleTypeId
      );
      
      return matchingRequirement?.requiredDocuments || [];
    }
    
    // Caso de vehículo sin operación o si no pertenece a una operación - Usamos los requisitos por tipo
    if (vehicleTypeRequirementsData?.data) {
      return vehicleTypeRequirementsData.data.documents || [];
    }
    
    return [];
  }, [
    isTrailer, 
    operationId, 
    vehicleTypeId, 
    requiresOperation,
    operationRequirementsData, 
    vehicleTypeRequirementsData
  ]);

  // Funciones de manejo de documentos 
  const handleAddDocument = (docTypeId) => {
    onDocumentsChange({
      type: "ADD_DOCUMENT",
      payload: {
        document: {
          id: `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          fileName: "",
          file: null,
          expeditionDate: null,
          expirationDate: null,
          description: "",
          docType: String(docTypeId),
          required: false,
        },
      },
    });
  };

  const handleFileSelect = (docId, event) => {
    const file = event.target.files[0];
    if (file) {
      onDocumentsChange({
        type: "UPDATE_DOCUMENT",
        payload: { docId, field: "file", value: file },
      });

      onDocumentsChange({
        type: "UPDATE_DOCUMENT",
        payload: { docId, field: "fileName", value: file.name },
      });
    }
  };

  const handleUpdateDocument = (docId, field, value) => {
    onDocumentsChange({
      type: "UPDATE_DOCUMENT",
      payload: { docId, field, value },
    });
  };

  const handleDeleteDocument = (docId) => {
    onDocumentsChange({
      type: "DELETE_DOCUMENT",
      payload: { docId },
    });
  };

  // *** ACTUALIZACIÓN IMPORTANTE: UI para selección de operación cuando se requiere y no hay una seleccionada ***
  if (requiresOperation && !isTrailer) {
    return (
      <div className={`GM__${theme}-documents-section`}>
        <h2 className={`GM__${theme}-section-title`}>Documentación</h2>

        <div className={`GM__${theme}-operation-section`}>
          <div className={`GM__${theme}-input-group`}>
            <label htmlFor="operationId" className={`GM__${theme}-input-label`}>
              Operación
            </label>
            <SortDropdown
              id="operationId"
              value={operationId || "no-operation"}
              onChange={(value) => {
                if (value === "no-operation") {
                  // Si selecciona "sin operación"
                  onOperationChange("");
                  if (onBelongsToOperationChange) {
                    onBelongsToOperationChange(false);
                  }
                } else {
                  // Si selecciona una operación válida
                  onOperationChange(value);
                  if (onBelongsToOperationChange) {
                    onBelongsToOperationChange(true);
                  }
                }
              }}
              options={[
                {
                  value: "no-operation",
                  label: "Este vehículo no contará con operación",
                },
                ...operations.map((op) => ({
                  value: op.id,
                  label: op.name,
                })),
              ]}
              theme={theme}
              placeholder="Seleccione una operación"
              disabled={!allowChangeOperation}
            />
            
            {!operationId && (
              <p className={`GM__${theme}-info-text`}>
                Al no seleccionar una operación, los documentos requeridos se
                determinarán por el tipo de vehículo.
              </p>
            )}
          </div>
        </div>

        {/* Renderizar documentos si ya se ha tomado una decisión sobre la operación */}
        {(operationId || operationId === "") && requiredDocTypes.map((docType) => {
          // Obtener documentos de este tipo específico
          const typeDocuments = documents.filter(
            (doc) => String(doc.docType) === String(docType.id)
          );

          return (
            <div key={docType.id} className={`GM__${theme}-document-type-table`}>
              <div className={`GM__${theme}-table-header`}>
                <h3 className={`GM__${theme}-table-title`}>{docType.name}</h3>
                <p className={`GM__${theme}-table-description`}>
                  {docType.description}
                </p>
                <button
                  className={`GM__${theme}-add-document-btn`}
                  onClick={() => handleAddDocument(docType.id)}
                >
                  <img
                    src={ArrowUpIcon}
                    alt="Agregar"
                    className={`GM__${theme}-icon-image`}
                  />
                </button>
              </div>

              <table className={`GM__${theme}-document-table`}>
                <thead className={`GM__${theme}-table-head`}>
                  <tr>
                    <th className={`GM__${theme}-table-header-cell`}>Estado</th>
                    <th className={`GM__${theme}-table-header-cell`}>Archivo</th>
                    <th className={`GM__${theme}-table-header-cell`}>
                      Fecha de Expedición
                    </th>
                    <th className={`GM__${theme}-table-header-cell`}>
                      Fecha de Vencimiento
                    </th>
                    <th className={`GM__${theme}-table-header-cell`}>
                      Descripción
                    </th>
                    <th className={`GM__${theme}-table-header-cell`}>
                      Requerido
                    </th>
                    <th className={`GM__${theme}-table-header-cell`}>Acciones</th>
                  </tr>
                </thead>
                <tbody className={`GM__${theme}-table-body`}>
                  {typeDocuments.length > 0 ? (
                    typeDocuments.map((doc) => (
                      <tr key={doc.id} className={`GM__${theme}-table-row`}>
                        <td
                          className={`GM__${theme}-table-cell GM__${theme}-status-cell`}
                        >
                          {doc.file ? (
                            <img
                              src={UploadSuccessIcon}
                              alt="Cargado"
                              className={`GM__${theme}-status-icon`}
                            />
                          ) : doc.required ? (
                            <img
                              src={UploadMandatoryIcon}
                              alt="Obligatorio"
                              className={`GM__${theme}-status-icon`}
                            />
                          ) : (
                            <img
                              src={LoadingIcon}
                              alt="Pendiente"
                              className={`GM__${theme}-status-icon`}
                            />
                          )}
                        </td>
                        <td
                          className={`GM__${theme}-table-cell GM__${theme}-file-cell`}
                        >
                          <div className={`GM__${theme}-file-upload`}>
                            <input
                              type="file"
                              onChange={(e) => handleFileSelect(doc.id, e)}
                              accept=".pdf,.jpg,.jpeg,.png"
                              id={`file-${doc.id}`}
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor={`file-${doc.id}`}
                              className={`GM__${theme}-file-label`}
                            >
                              {doc.fileName || "Seleccionar archivo"}
                            </label>
                          </div>
                        </td>
                        <td
                          className={`GM__${theme}-table-cell GM__${theme}-date-cell`}
                        >
                          <CustomDatePicker
                            value={
                              doc.expeditionDate
                                ? dayjs(doc.expeditionDate).toDate()
                                : null
                            }
                            onChange={(date) =>
                              handleUpdateDocument(
                                doc.id,
                                "expeditionDate",
                                date ? dayjs(date).format("YYYY-MM-DD") : null
                              )
                            }
                            icon={<img src={CalendarIcon} alt="Calendario" />}
                            theme={theme}
                          />
                        </td>
                        <td
                          className={`GM__${theme}-table-cell GM__${theme}-date-cell`}
                        >
                          <CustomDatePicker
                            value={
                              doc.expirationDate
                                ? dayjs(doc.expirationDate).toDate()
                                : null
                            }
                            onChange={(date) =>
                              handleUpdateDocument(
                                doc.id,
                                "expirationDate",
                                date ? dayjs(date).format("YYYY-MM-DD") : null
                              )
                            }
                            icon={<img src={CalendarIcon} alt="Calendario" />}
                            theme={theme}
                          />
                        </td>
                        <td
                          className={`GM__${theme}-table-cell GM__${theme}-description-cell`}
                        >
                          <CustomInputField
                            type="text"
                            value={doc.description || ""}
                            onChange={(e) =>
                              handleUpdateDocument(
                                doc.id,
                                "description",
                                e.target.value
                              )
                            }
                            placeholder="Descripción del documento"
                            theme={theme}
                          />
                        </td>
                        <td
                          className={`GM__${theme}-table-cell GM__${theme}-checkbox-cell`}
                        >
                          <input
                            type="checkbox"
                            checked={!!doc.required}
                            onChange={(e) =>
                              handleUpdateDocument(
                                doc.id,
                                "required",
                                e.target.checked
                              )
                            }
                            className={`GM__${theme}-checkbox`}
                          />
                        </td>
                        <td
                          className={`GM__${theme}-table-cell GM__${theme}-actions-cell`}
                        >
                          <button
                            className={`GM__${theme}-delete-btn`}
                            onClick={() => handleDeleteDocument(doc.id)}
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className={`GM__${theme}-empty-row`}>
                      <td colSpan={7} className={`GM__${theme}-empty-message`}>
                        No hay documentos de este tipo. Para agregar un documento,
                        use el botón "+" arriba.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          );
        })}
        
        {/* Mensaje si no hay requisitos después de seleccionar una operación o "sin operación" */}
        {(operationId || operationId === "") && requiredDocTypes.length === 0 && (
          <p className={`GM__${theme}-no-requirements`}>
            No se encontraron requisitos documentales para este tipo de vehículo
            {operationId ? " en la operación seleccionada" : ""}.
          </p>
        )}
      </div>
    );
  }
  
  // Caso especial para remolque sin operación seleccionada
  if (isTrailer && !operationId) {
    return (
      <div className={`GM__${theme}-documents-section`}>
        <h2 className={`GM__${theme}-section-title`}>
          Documentación del Remolque
        </h2>
        <div className={`GM__${theme}-empty-state`}>
          <p>
            Para visualizar los documentos del remolque, primero debe
            seleccionar una operación en la sección de documentación del
            vehículo principal.
          </p>
        </div>
      </div>
    );
  }
  
  // Muestra de carga
  if (isLoading) {
    return (
      <div className={`GM__${theme}-documents-section`}>
        <h2 className={`GM__${theme}-section-title`}>
          Documentación{isTrailer ? " del Remolque" : ""}
        </h2>
        <div className={`GM__${theme}-loading-container`}>
          Cargando requisitos documentales...
        </div>
      </div>
    );
  }
  
  // Manejo de errores
  if (error) {
    return (
      <div className={`GM__${theme}-documents-section`}>
        <h2 className={`GM__${theme}-section-title`}>
          Documentación{isTrailer ? " del Remolque" : ""}
        </h2>
        <div className={`GM__${theme}-error-container`}>
          Error al cargar requisitos documentales. Por favor, inténtelo de
          nuevo.
          {isTrailer && (
            <p>
              Verifique que la operación haya sido seleccionada correctamente.
            </p>
          )}
        </div>
      </div>
    );
  }

  // Renderizado principal para casos que no son cabezote o ya tienen operación seleccionada
  return (
    <div className={`GM__${theme}-documents-section`}>
      <h2 className={`GM__${theme}-section-title`}>
        Documentación{isTrailer ? " del Remolque" : ""}
      </h2>
      
      {/* Mensaje informativo en caso de remolque con operación heredada */}
      {isTrailer && operationId && (
        <div className={`GM__${theme}-inherited-operation-info`}>
          <p>
            <strong>Operación aplicada:</strong>{" "}
            {operations.find((op) => op.id === operationId)?.name ||
              "Operación seleccionada"}
          </p>
          <p className={`GM__${theme}-info-text`}>
            Los documentos mostrados corresponden a los requisitos específicos
            para remolques en esta operación.
          </p>
        </div>
      )}

      {/* Mensaje si no hay requisitos */}
      {requiredDocTypes.length === 0 && (
        <p className={`GM__${theme}-no-requirements`}>
          No se encontraron requisitos documentales para este{" "}
          {isTrailer ? "tipo de remolque" : "tipo de vehículo"}
          {requiresOperation || isTrailer
            ? " en la operación seleccionada"
            : ""}.
        </p>
      )}

      {/* Renderizar cada tipo de documento */}
      {requiredDocTypes.map((docType) => {
        // Obtener documentos de este tipo específico
        const typeDocuments = documents.filter(
          (doc) => String(doc.docType) === String(docType.id)
        );

        return (
          <div key={docType.id} className={`GM__${theme}-document-type-table`}>
            <div className={`GM__${theme}-table-header`}>
              <h3 className={`GM__${theme}-table-title`}>{docType.name}</h3>
              <p className={`GM__${theme}-table-description`}>
                {docType.description}
              </p>
              <button
                className={`GM__${theme}-add-document-btn`}
                onClick={() => handleAddDocument(docType.id)}
              >
                <img
                  src={ArrowUpIcon}
                  alt="Agregar"
                  className={`GM__${theme}-icon-image`}
                />
              </button>
            </div>

            <table className={`GM__${theme}-document-table`}>
              <thead className={`GM__${theme}-table-head`}>
                <tr>
                  <th className={`GM__${theme}-table-header-cell`}>Estado</th>
                  <th className={`GM__${theme}-table-header-cell`}>Archivo</th>
                  <th className={`GM__${theme}-table-header-cell`}>
                    Fecha de Expedición
                  </th>
                  <th className={`GM__${theme}-table-header-cell`}>
                    Fecha de Vencimiento
                  </th>
                  <th className={`GM__${theme}-table-header-cell`}>
                    Descripción
                  </th>
                  <th className={`GM__${theme}-table-header-cell`}>
                    Requerido
                  </th>
                  <th className={`GM__${theme}-table-header-cell`}>Acciones</th>
                </tr>
              </thead>
              <tbody className={`GM__${theme}-table-body`}>
                {typeDocuments.length > 0 ? (
                  typeDocuments.map((doc) => (
                    <tr key={doc.id} className={`GM__${theme}-table-row`}>
                      <td
                        className={`GM__${theme}-table-cell GM__${theme}-status-cell`}
                      >
                        {doc.file ? (
                          <img
                            src={UploadSuccessIcon}
                            alt="Cargado"
                            className={`GM__${theme}-status-icon`}
                          />
                        ) : doc.required ? (
                          <img
                            src={UploadMandatoryIcon}
                            alt="Obligatorio"
                            className={`GM__${theme}-status-icon`}
                          />
                        ) : (
                          <img
                            src={LoadingIcon}
                            alt="Pendiente"
                            className={`GM__${theme}-status-icon`}
                          />
                        )}
                      </td>
                      <td
                        className={`GM__${theme}-table-cell GM__${theme}-file-cell`}
                      >
                        <div className={`GM__${theme}-file-upload`}>
                          <input
                            type="file"
                            onChange={(e) => handleFileSelect(doc.id, e)}
                            accept=".pdf,.jpg,.jpeg,.png"
                            id={`file-${doc.id}`}
                            style={{ display: "none" }}
                          />
                          <label
                            htmlFor={`file-${doc.id}`}
                            className={`GM__${theme}-file-label`}
                          >
                            {doc.fileName || "Seleccionar archivo"}
                          </label>
                        </div>
                      </td>
                      <td
                        className={`GM__${theme}-table-cell GM__${theme}-date-cell`}
                      >
                        <CustomDatePicker
                          value={
                            doc.expeditionDate
                              ? dayjs(doc.expeditionDate).toDate()
                              : null
                          }
                          onChange={(date) =>
                            handleUpdateDocument(
                              doc.id,
                              "expeditionDate",
                              date ? dayjs(date).format("YYYY-MM-DD") : null
                            )
                          }
                          icon={<img src={CalendarIcon} alt="Calendario" />}
                          theme={theme}
                        />
                      </td>
                      <td
                        className={`GM__${theme}-table-cell GM__${theme}-date-cell`}
                      >
                        <CustomDatePicker
                          value={
                            doc.expirationDate
                              ? dayjs(doc.expirationDate).toDate()
                              : null
                          }
                          onChange={(date) =>
                            handleUpdateDocument(
                              doc.id,
                              "expirationDate",
                              date ? dayjs(date).format("YYYY-MM-DD") : null
                            )
                          }
                          icon={<img src={CalendarIcon} alt="Calendario" />}
                          theme={theme}
                        />
                      </td>
                      <td
                        className={`GM__${theme}-table-cell GM__${theme}-description-cell`}
                      >
                        <CustomInputField
                          type="text"
                          value={doc.description || ""}
                          onChange={(e) =>
                            handleUpdateDocument(
                              doc.id,
                              "description",
                              e.target.value
                            )
                          }
                          placeholder="Descripción del documento"
                          theme={theme}
                        />
                      </td>
                      <td
                        className={`GM__${theme}-table-cell GM__${theme}-checkbox-cell`}
                      >
                        <input
                          type="checkbox"
                          checked={!!doc.required}
                          onChange={(e) =>
                            handleUpdateDocument(
                              doc.id,
                              "required",
                              e.target.checked
                            )
                          }
                          className={`GM__${theme}-checkbox`}
                        />
                      </td>
                      <td
                        className={`GM__${theme}-table-cell GM__${theme}-actions-cell`}
                      >
                        <button
                          className={`GM__${theme}-delete-btn`}
                          onClick={() => handleDeleteDocument(doc.id)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={`GM__${theme}-empty-row`}>
                    <td colSpan={7} className={`GM__${theme}-empty-message`}>
                      No hay documentos de este tipo. Para agregar un documento,
                      use el botón "+" arriba.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

DocumentsSection.propTypes = {
  vehicleTypeId: PropTypes.string,
  operationId: PropTypes.string,
  onOperationChange: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  onDocumentsChange: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  operations: PropTypes.array.isRequired,
  requiresOperation: PropTypes.bool,
  allowChangeOperation: PropTypes.bool,
  isTrailer: PropTypes.bool,
  belongsToOperation: PropTypes.bool,
  onBelongsToOperationChange: PropTypes.func,
};