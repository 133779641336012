import React, { useState, useCallback, useMemo, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { useCommentsAndLocations } from "../../../hooks/commentsAndResidenceQuery";
import { useLayout } from "../../../context/LayoutContext";
import {
  useNotification,
  NotificationSeverity,
} from "../../../context/NotificationContext";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";

import MultiDownloadModal from "../../../components/ui/modals/MultiDownloadModal";
import PreviewModal from "../../../components/ui/modals/PreviewModal";
import ConfirmDeleteModal from "../../../components/ui/modals/ConfirmDeleteModal";
import "../../../assets/scss/views/VehicleProfile.scss";
import CommentsLocationsDrawer from "../../../components/ui/drawner/CommentsLocationsDrawer";
import { useQueryClient } from "@tanstack/react-query";
import { useVehicleProfile } from "../../../hooks/useVehicleQuery";
import VehicleProfileInfo from "../VehicleProfileInfo";
import {
  useDocumentationAnalytics,
  useDocumentTypes,
} from "../../../hooks/useDocumentationVehicleQuery";
import VehicleDocumentsSectionProfile from "../../../components/ui/tables/VehicleDocumentsSectionProfile";
import VehicleDocumentFilters from "../../../components/filters/VehicleDocumentFilters";
import { useDownload } from "../../../context/DownloadContext";

// Constante para documentos que no expiran
export const NON_EXPIRABLE_VEHICLE_DOCUMENTS = [
  "Tarjeta de propiedad",
  "Registro fotográfico",
];

// Memoización de componentes hijo para prevenir rerenderizaciones innecesarias
const MemoizedVehicleProfileInfo = memo(VehicleProfileInfo);
const MemoizedVehicleDocumentFilters = memo(VehicleDocumentFilters);
const MemoizedVehicleDocumentsSectionProfile = memo(VehicleDocumentsSectionProfile);
const MemoizedPreviewModal = memo(PreviewModal);
const MemoizedMultiDownloadModal = memo(MultiDownloadModal);
const MemoizedConfirmDeleteModal = memo(ConfirmDeleteModal);
const MemoizedCommentsLocationsDrawer = memo(CommentsLocationsDrawer);

export default memo(function VehicleProfile({ id }) {

  const { theme } = useLayout();
  const { showNotification } = useNotification();
     const { handleDownloadMultipleDocuments } = useDownload();
  const queryClient = useQueryClient();
  const { useOperationRequirements } = useDocumentationAnalytics();

  // Estados locales agrupados por funcionalidad
  // Estados para filtrado y búsqueda
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("all");
  
  // Estados para modales
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [openMultiDownload, setOpenMultiDownload] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);
  
  // Estado para tipos de documentos
  const [documentTypes, setDocumentTypes] = useState([]);

  // Configuración optimizada del hook principal con opciones de caché
  const {
    vehicleData,
    isLoading,
    isError,
    error,
    uploadDocument,
    updateDocument,
    deleteDocument,
    downloadDocument,
    downloadMultipleDocuments,
    selectedDocumentId,
    selectDocument,
  } = useVehicleProfile(id, {
    staleTime: 5 * 60 * 1000, // 5 minutos
    cacheTime: 10 * 60 * 1000, // 10 minutos
    refetchOnWindowFocus: false
  });

  // Optimización de los queries dependientes
  const {
    data: operationData,
    isLoading: isLoadingOperation,
    isError: isErrorOperation,
  } = useOperationRequirements(
    vehicleData?.belongs_to_operation && vehicleData?.operation_id
      ? vehicleData.operation_id
      : null,
    {
      enabled: !!vehicleData?.belongs_to_operation && !!vehicleData?.operation_id,
      staleTime: 10 * 60 * 1000 // 10 minutos
    }
  );

  // Optimización de la consulta de tipos de documentos
  const { data: docTypesData, isLoading: isLoadingDocTypes } = useDocumentTypes(
    {
      vehicleDocuments: true,
    },
    {
      staleTime: 30 * 60 * 1000, // 30 minutos para datos estáticos
      cacheTime: 60 * 60 * 1000 // 1 hora
    }
  );

  // Optimización del hook de comentarios
  const {
    useCommentsByReference,
    createComment,
    updateComment,
    deleteComment,
  } = useCommentsAndLocations();

  const { data: documentComments } = useCommentsByReference(
    selectedDocumentId || "",
    {
      enabled: !!selectedDocumentId,
      staleTime: 2 * 60 * 1000 // 2 minutos
    }
  );

  // Optimización del efecto para determinar tipos de documentos
  useEffect(() => {
    if (!vehicleData) return;
    
    let newDocumentTypes = [];
    
    // Caso 1: Vehículo en operación con requisitos específicos
    if (vehicleData.belongs_to_operation && operationData?.data?.vehicleRequirements) {
      const { vehicleRequirements } = operationData.data;
      const requirements = vehicleRequirements.find(
        (req) => req.vehicleType.id === vehicleData.vehicle_type_id
      );

      if (requirements?.requiredDocuments) {
        newDocumentTypes = requirements.requiredDocuments;
      } else {
        newDocumentTypes = vehicleData.requiredDocuments || [];
      }
    }
    // Caso 2: Tipos de documentos genéricos
    else if (docTypesData?.data) {
      newDocumentTypes = docTypesData.data;
    }
    
    // Actualización condicional para evitar ciclos
    if (JSON.stringify(newDocumentTypes) !== JSON.stringify(documentTypes)) {
      setDocumentTypes(newDocumentTypes);
    }
  }, [vehicleData, operationData?.data, docTypesData?.data]);

  // Optimización de la normalización de documentos con useMemo
  const normalizeDocuments = useMemo(() => {
    return (documents = []) => documents.map((doc) => ({
      ...doc,
      document_type: {
        id: doc.document_type_id,
        name: doc.document_type_name,
        description: doc.document_type_description || "",
      },
    }));
  }, []);

  // Conversión de useCallback a useMemo para filtrado de documentos
  const filteredData = useMemo(() => {
    if (!vehicleData?.documents) return { documents: [], types: [] };

    // Normalizar documentos
    let filtered = normalizeDocuments(vehicleData.documents);

    // Normalizar tipos de documentos
    let relevantTypes = [...(documentTypes || [])].map((type) => ({
      id: type.id,
      name: type.name,
      description: type.description || "",
    }));

    // Filtro por texto
    if (searchValue.trim()) {
      const searchLower = searchValue.toLowerCase();
      filtered = filtered.filter(
        (doc) =>
          doc.original_name_description?.toLowerCase().includes(searchLower) ||
          doc.file_name?.toLowerCase().includes(searchLower)
      );

      relevantTypes = relevantTypes.filter((type) =>
        filtered.some((doc) => doc.document_type.id === type.id)
      );
    }

    // Aplicación de filtros específicos
    switch (filterValue) {
      case "expiration": {
        const now = new Date();
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(now.getDate() + 30);

        filtered = filtered.filter((doc) => {
          if (NON_EXPIRABLE_VEHICLE_DOCUMENTS.includes(doc.document_type.name)) {
            return false;
          }

          if (!doc.expiration_date) return false;
          const expirationDate = new Date(doc.expiration_date);
          return expirationDate <= thirtyDaysFromNow;
        });

        relevantTypes = relevantTypes.filter((type) =>
          filtered.some((doc) => doc.document_type.id === type.id)
        );
        break;
      }

      case "empty":
        if (documentTypes.length > 0) {
          filtered = [];
          relevantTypes = documentTypes
            .filter(
              (reqDoc) =>
                !vehicleData.documents.some(
                  (doc) => doc.document_type_id === reqDoc.id
                )
            )
            .map((type) => ({
              id: type.id,
              name: type.name,
              description: type.description || "",
            }));
        }
        break;

      case "required":
        filtered = filtered.filter((doc) => doc.required);
        relevantTypes = relevantTypes.filter((type) =>
          filtered.some((doc) => doc.document_type.id === type.id)
        );
        break;

      case "not-required":
        filtered = filtered.filter((doc) => !doc.required);
        relevantTypes = relevantTypes.filter((type) =>
          filtered.some((doc) => doc.document_type.id === type.id)
        );
        break;

      default:
        break;
    }

    return {
      documents: filtered,
      types: relevantTypes,
    };
  }, [vehicleData?.documents, documentTypes, searchValue, filterValue, normalizeDocuments]);

  // Handlers optimizados
  const handlePreviewDocument = useCallback((doc) => {
    setSelectedDoc(doc);
    setOpenPreview(true);
  }, []);

  // Optimización de manejo de actualizaciones
  const handleUpdateDocument = useCallback(
    async (doc) => {
      try {
        // Preparación de payload
        const updatePayload = {
          docType: doc.document_type?.id || doc.document_type_id,
          description: doc.original_name_description || "",
          required: doc.required || false,
          expeditionDate: doc.expedition_date,
          expirationDate: doc.expiration_date,
        };

        // Inclusión condicional de archivo
        if (doc.file && doc.file instanceof File) {
          updatePayload.file = doc.file;
          updatePayload.fileName = doc.file.name;
        }

        // Optimistic UI update
        const currentCache = queryClient.getQueryData(["vehicleProfile", id]);
        if (currentCache?.data?.documents) {
          const optimisticDocs = currentCache.data.documents.map(existingDoc => 
            existingDoc.id === doc.id 
              ? { ...existingDoc, ...updatePayload, updating: true } 
              : existingDoc
          );
          
          queryClient.setQueryData(["vehicleProfile", id], {
            ...currentCache,
            data: {
              ...currentCache.data,
              documents: optimisticDocs
            }
          });
        }

        // Llamada a API
        await updateDocument(doc.id, updatePayload);

        // Actualización de caché post-éxito
        queryClient.invalidateQueries(["vehicleProfile", id]);

        showNotification({
          message: "Documento actualizado correctamente",
          severity: NotificationSeverity.SUCCESS,
        });
      } catch (err) {
        console.error("Error actualizando documento:", err);
        
        // Restaurar caché original en caso de error
        queryClient.invalidateQueries(["vehicleProfile", id]);
        
        showNotification({
          message: "Error al actualizar el documento: " + (err.message || "Error desconocido"),
          severity: NotificationSeverity.ERROR,
        });
      }
    },
    [updateDocument, queryClient, id, showNotification]
  );

  const handleDeleteDocument = useCallback((doc) => {
    setDocToDelete(doc);
    setOpenDeleteDialog(true);
  }, []);

  // Optimización del manejo de eliminación de documentos
  const confirmDeleteDocument = useCallback(async () => {
    if (!docToDelete) return;

    try {
      // Optimistic UI update
      const currentCache = queryClient.getQueryData(["vehicleProfile", id]);
      if (currentCache?.data?.documents) {
        const filteredDocs = currentCache.data.documents.filter(
          doc => doc.id !== docToDelete.id
        );
        
        queryClient.setQueryData(["vehicleProfile", id], {
          ...currentCache,
          data: {
            ...currentCache.data,
            documents: filteredDocs
          }
        });
      }

      // Llamada a API
      await deleteDocument(docToDelete.id);
      
      // Confirmar cambios en caché
      queryClient.invalidateQueries(["vehicleProfile", id]);
      
      showNotification({
        message: "Documento eliminado correctamente",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (error) {
      console.error("Error eliminando documento:", error);
      
      // Restaurar caché original en caso de error
      queryClient.invalidateQueries(["vehicleProfile", id]);
      
      showNotification({
        message: "Error al eliminar el documento: " + (error.message || "Error desconocido"),
        severity: NotificationSeverity.ERROR,
      });
    } finally {
      setOpenDeleteDialog(false);
      setDocToDelete(null);
    }
  }, [docToDelete, deleteDocument, queryClient, id, showNotification]);

  // Optimización del manejo de añadir documentos
  const handleAddDocument = useCallback(
    async (newDoc) => { 
      try {
        // Para documentos locales (sin persistir aún)
        if (newDoc.isNew || newDoc.isLocal || newDoc.id?.startsWith('temp-')) {
          // Optimistic UI update con gestión de casos borde
          const cacheKey = ["vehicleProfile", id];
          const currentCache = queryClient.getQueryData(cacheKey) || { data: { documents: [] } };
          
          // Normalización del documento nuevo
          const normalizedDoc = {
            ...newDoc,
            document_type_id: newDoc.document_type_id,
            document_type_name: newDoc.document_type_name || "",
            expedition_date: newDoc.expedition_date || null,
            expiration_date: newDoc.expiration_date || null,
            original_name_description: newDoc.original_name_description || "",
            required: !!newDoc.required,
            file_name: newDoc.file_name || "",
            // Flag para indicar estado temporal
            isTemporary: true
          };
          
          // Actualización segura de caché
          const existingDocs = Array.isArray(currentCache.data?.documents) 
            ? [...currentCache.data.documents] 
            : [];
            
          const updatedCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              documents: [...existingDocs, normalizedDoc]
            }
          };
          
          queryClient.setQueryData(cacheKey, updatedCache);
          
          // Si no hay archivo, terminar aquí
          if (!newDoc.file) return;
        }
        
        // Para documentos con archivo, realizar la carga al servidor
        if (newDoc.file) {
          // Preparar payload para API
          const uploadPayload = {
            docType: newDoc.document_type_id,
            description: newDoc.original_name_description || "",
            file: newDoc.file,
            fileName: newDoc.file.name,
            required: !!newDoc.required,
            expeditionDate: newDoc.expedition_date,
            expirationDate: newDoc.expiration_date,
          };
          
          // Realizar upload
          await uploadDocument(uploadPayload);
          
          // Actualizar caché con datos del servidor
          await queryClient.invalidateQueries(["vehicleProfile", id]);
          
          showNotification({
            message: "Documento añadido correctamente",
            severity: NotificationSeverity.SUCCESS,
          });
        }
      } catch (error) {
        console.error("Error al añadir documento:", error);
        
        // Restaurar caché en caso de error
        queryClient.invalidateQueries(["vehicleProfile", id]);
        
        showNotification({
          message: "Error al añadir documento: " + (error.message || "Error desconocido"),
          severity: NotificationSeverity.ERROR,
        });
      }
    },
    [uploadDocument, queryClient, id, showNotification]
  );

  // Optimización del manejo de cambios en documentos
  const handleDocumentChange = useCallback(
    (action) => {
      switch (action.type) {
        case "ADD_DOCUMENT":
          handleAddDocument(action.payload);
          break;

        case "UPDATE_DOCUMENT":
          // Para cambios locales antes de guardar permanentemente
          if (vehicleData?.documents) {
            const { docId, field, value } = action.payload;
            
            // Optimistic update para la UI
            const cacheKey = ["vehicleProfile", id];
            const currentCache = queryClient.getQueryData(cacheKey);
            
            if (currentCache?.data?.documents) {
              const updatedDocs = currentCache.data.documents.map((doc) =>
                doc.id === docId ? { ...doc, [field]: value } : doc
              );
              
              queryClient.setQueryData(cacheKey, {
                ...currentCache,
                data: {
                  ...currentCache.data,
                  documents: updatedDocs,
                },
              });
            }
          }
          break;

        case "DELETE_DOCUMENT":
          if (action.payload) {
            setDocToDelete(action.payload);
            setOpenDeleteDialog(true);
          } else if (docToDelete) {
            confirmDeleteDocument();
          }
          break;

        case "DELETE_LOCAL_DOCUMENT":
          // Eliminar documento local (sin persistir)
          const { docId } = action.payload;
          
          // Optimistic UI update
          const cacheKey = ["vehicleProfile", id];
          const currentCache = queryClient.getQueryData(cacheKey);
          
          if (currentCache?.data?.documents) {
            const filteredDocs = currentCache.data.documents.filter(
              doc => doc.id !== docId
            );
            
            queryClient.setQueryData(cacheKey, {
              ...currentCache,
              data: {
                ...currentCache.data,
                documents: filteredDocs,
              },
            });
          }
          break;

        case "VALIDATION_ERROR":
          showNotification({
            message: action.payload.message,
            severity: NotificationSeverity.WARNING,
          });
          break;

        default:
          console.warn("Acción no reconocida:", action.type);
      }
    },
    [
      handleAddDocument,
      docToDelete,
      confirmDeleteDocument,
      vehicleData,
      queryClient,
      id,
      showNotification,
    ]
  );

  // Optimización de preparación de datos para descarga
  const prepareDocumentsForDownload = useMemo(() => {
    if (!vehicleData?.documents)
      return { vehicle: [], trailer: [], others: [] };

    // Constantes de tipos de documentos para mejor mantenibilidad
    const vehicleDocsTypes = [
      "TARJETA DE PROPIEDAD",
      "SOAT",
      "CERTIFICADO TECNOMECANICA",
    ];

    const trailerDocsTypes = [
      "KING PIN",
      "LINEA DE VIDA",
      "TABLA DE AFORO",
      "PRUEBA HIDROSTATICA",
      "CERTIFICADO QUINTA RUEDA",
    ];

    // Filtrado eficiente de documentos válidos
    const validDocuments = vehicleData.documents.filter(
      doc => doc.file_name && doc.file_path
    );
    
    // Optimización de las comparaciones
    const vehicleDocsTypesSet = new Set(vehicleDocsTypes);
    const trailerDocsTypesSet = new Set(trailerDocsTypes);
    
    // Clasificación optimizada
    const categorizedDocs = {
      vehicle: [],
      trailer: [],
      others: []
    };
    
    // Single-pass categorization
    validDocuments.forEach(doc => {
      const docTypeName = doc.document_type_name?.toUpperCase() || "";
      
      if (vehicleDocsTypes.some(type => docTypeName.includes(type))) {
        categorizedDocs.vehicle.push(doc);
      } else if (trailerDocsTypes.some(type => docTypeName.includes(type))) {
        categorizedDocs.trailer.push(doc);
      } else {
        categorizedDocs.others.push(doc);
      }
    });
    
    return categorizedDocs;
  }, [vehicleData?.documents]);

  // Handler optimizado para descarga múltiple
  const handleDownloadAll = useCallback(async () => {
    if (selectedDocs.length === 0) {
      showNotification({
        message: "No hay documentos seleccionados para descargar",
        severity: NotificationSeverity.WARNING,
      });
      return;
    }

    try {
      await handleDownloadMultipleDocuments(selectedDocs, 2);

      showNotification({
        message: "Documentos descargados exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (error) {
      console.error("Error al descargar documentos:", error);
      showNotification({
        message: "Error al descargar los documentos: " + (error.message || "Error desconocido"),
        severity: NotificationSeverity.ERROR,
      });
    }
  }, [selectedDocs, downloadMultipleDocuments, showNotification]);

  // Gestión optimizada de comentarios
  const handleCreateComment = useCallback(
    async (commentData) => {
      if (!selectedDocumentId) {
        showNotification({
          message: "No se puede crear comentario: Documento no seleccionado",
          severity: NotificationSeverity.ERROR,
        });
        return;
      }
      
      try {
        // Optimistic UI update
        const cacheKey = ["comments", selectedDocumentId];
        const currentComments = queryClient.getQueryData(cacheKey);
        
        const optimisticComment = {
          id: `temp-${Date.now()}`,
          content: commentData.content,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          reference_id: selectedDocumentId,
          isOptimistic: true,
          ...commentData
        };
        
        if (currentComments?.data) {
          queryClient.setQueryData(cacheKey, {
            ...currentComments,
            data: [...currentComments.data, optimisticComment]
          });
        }

        // Llamada a API
        await createComment({
          ...commentData,
          reference_id: selectedDocumentId,
        });

        // Actualización post-éxito
        queryClient.invalidateQueries(cacheKey);

        showNotification({
          message: "Comentario creado exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      } catch (error) {
        console.error("Error al crear comentario:", error);
        
        // Restaurar caché en caso de error
        queryClient.invalidateQueries(["comments", selectedDocumentId]);
        
        showNotification({
          message: error.message || "Error al crear comentario",
          severity: NotificationSeverity.ERROR,
        });
      }
    },
    [createComment, selectedDocumentId, queryClient, showNotification]
  );

  // Optimización del resto de handlers de comentarios
  const handleUpdateComment = useCallback(
    async (commentId, commentData) => {
      if (!selectedDocumentId || !commentId) return;
      
      try {
        // Optimistic UI update
        const cacheKey = ["comments", selectedDocumentId];
        const currentComments = queryClient.getQueryData(cacheKey);
        
        if (currentComments?.data) {
          const updatedComments = currentComments.data.map(comment => 
            comment.id === commentId 
              ? { ...comment, ...commentData, updating: true } 
              : comment
          );
          
          queryClient.setQueryData(cacheKey, {
            ...currentComments,
            data: updatedComments
          });
        }

        // Llamada a API
        await updateComment({
          commentId,
          data: {
            ...commentData,
            reference_id: selectedDocumentId,
          },
        });

        // Actualización post-éxito
        queryClient.invalidateQueries(cacheKey);

        showNotification({
          message: "Comentario actualizado exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      } catch (error) {
        console.error("Error al actualizar comentario:", error);
        
        // Restaurar caché en caso de error
        queryClient.invalidateQueries(["comments", selectedDocumentId]);
        
        showNotification({
          message: error.message || "Error al actualizar comentario",
          severity: NotificationSeverity.ERROR,
        });
      }
    },
    [updateComment, selectedDocumentId, queryClient, showNotification]
  );

  const handleDeleteComment = useCallback(
    async (commentId) => {
      if (!selectedDocumentId || !commentId) return;
      
      try {
        // Optimistic UI update
        const cacheKey = ["comments", selectedDocumentId];
        const currentComments = queryClient.getQueryData(cacheKey);
        
        if (currentComments?.data) {
          const filteredComments = currentComments.data.filter(
            comment => comment.id !== commentId
          );
          
          queryClient.setQueryData(cacheKey, {
            ...currentComments,
            data: filteredComments
          });
        }

        // Llamada a API
        await deleteComment(commentId);

        // Confirmar actualización
        queryClient.invalidateQueries(cacheKey);

        showNotification({
          message: "Comentario eliminado exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      } catch (error) {
        console.error("Error al eliminar comentario:", error);
        
        // Restaurar caché en caso de error
        queryClient.invalidateQueries(["comments", selectedDocumentId]);
        
        showNotification({
          message: error.message || "Error al eliminar comentario",
          severity: NotificationSeverity.ERROR,
        });
      }
    },
    [deleteComment, selectedDocumentId, queryClient, showNotification]
  );

  // Estados de carga y error
  if (isLoading || isLoadingOperation || isLoadingDocTypes)
    return <LoadingAnimation />;

  if (isError)
    return (
      <div className={`GM__${theme}-error-message`}>
        Error al cargar los datos del vehículo: {error?.message || "Error desconocido"}
      </div>
    );

  if (isErrorOperation)
    return (
      <div className={`GM__${theme}-error-message`}>
        Error al cargar los requisitos de operación. Se mostrarán documentos
        genéricos.
      </div>
    );

  if (!vehicleData)
    return (
      <div className={`GM__${theme}-error-message`}>
        No se encontró información del vehículo
      </div>
    );

  // Renderizado optimizado con componentes memoizados
  return (
    <div className={`GM__${theme}-vehicle-profile`}>
      {/* Información básica del vehículo - Componente memoizado */}
      <MemoizedVehicleProfileInfo vehicle={vehicleData} theme={theme} />

      {/* Filtros de documentos - Componente memoizado */}
      <div className={`GM__${theme}-multi-download-container`}>
        <MemoizedVehicleDocumentFilters
          documents={vehicleData.documents}
          onSearchChange={(e) => setSearchValue(e.target.value)}
          onFilterChange={(e) => setFilterValue(e.target.value)}
          searchValue={searchValue}
          filterValue={filterValue}
          onExpirationFilterClick={() => setFilterValue("expiration")}
          handleOpenMultiDownload={() => setOpenMultiDownload(true)}
          theme={theme}
        />
      </div>

      {/* Sección de documentos - Componente memoizado */}
      <MemoizedVehicleDocumentsSectionProfile
        documents={vehicleData.documents}
        dataTypes={documentTypes}
        onDocumentsChange={handleDocumentChange}
        isLoading={isLoading}
        canEdit={true}
        onPreview={handlePreviewDocument}
        onUpdate={handleUpdateDocument}
        onDelete={handleDeleteDocument}
        onAddDocument={handleAddDocument}
        theme={theme}
      />

      {/* Modales - Componentes memoizados */}
      <MemoizedPreviewModal
        open={openPreview}
        onClose={() => {
          setOpenPreview(false);
          setSelectedDoc(null);
        }}
        doc={selectedDoc}
      />

      {/* Modal para descarga múltiple */}
      <MemoizedMultiDownloadModal
        open={openMultiDownload}
        onClose={() => setOpenMultiDownload(false)}
        personalDocuments={prepareDocumentsForDownload.vehicle}
        certificates={prepareDocumentsForDownload.trailer}
        otherDocuments={prepareDocumentsForDownload.others}
        selectedDocs={selectedDocs}
        setSelectedDocs={setSelectedDocs}
        onDownloadAll={handleDownloadAll}
        userName={vehicleData.plate || "Vehículo"}
        isVehicle={true}
      />

{/* Modal de confirmación para eliminar documentos */}
<MemoizedConfirmDeleteModal
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setDocToDelete(null);
        }}
        onConfirm={confirmDeleteDocument}
        title="Eliminar Documento"
        message={`¿Seguro que deseas eliminar el documento "${
          docToDelete?.original_name_description || "seleccionado"
        }"?`}
      />

      {/* Componente para gestión de comentarios, con renderizado condicional optimizado */}
      {selectedDocumentId && (
        <MemoizedCommentsLocationsDrawer
          open={!!selectedDocumentId}
          onClose={() => selectDocument(null)}
          type="comments"
          data={documentComments?.data || []}
          onEdit={handleUpdateComment}
          onCreate={handleCreateComment}
          onDelete={handleDeleteComment}
          theme={theme}
        />
      )}
    </div>
  );
});
