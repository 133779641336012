import axios from "axios";
import { useAuth } from "../context/AuthContext";
//const API_URL =  process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL_PRODUCTION;

export const useApi = () => {
  const { token } = useAuth();

  return axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
};
export const createApi = (token = null) => {
  return axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
};
