import JSZip from 'jszip';
import { saveAs } from 'file-saver';

/**
 * Convierte coordenadas a formato KML
 * @param {Array} waypoints - Array de objetos {lat, lng}
 * @returns {String} - String con las coordenadas en formato KML
 */
const waypointsToKmlCoordinates = (waypoints) => {
  return waypoints
    .map(point => `${point.lng},${point.lat},0`)
    .join(' ');
};

/**
 * Genera el contenido KML para las rutas
 * @param {Array} routes - Array de objetos ruta
 * @returns {String} - String con el KML generado
 */
const generateKml = (routes, annotations) => {
  let routesKml = '';
  let annotationsKml = '';
  
  // Generar KML para las rutas
  routes.forEach(route => {
    if (!route.visible || !route.waypoints.length) return;
    
    const routeColor = route.color.replace('#', '');
    // KML usa formato AABBGGRR (alpha, blue, green, red)
    const kmlColor = 'ff' + routeColor.substring(4, 6) + routeColor.substring(2, 4) + routeColor.substring(0, 2);
    
    routesKml += `
      <Placemark>
        <name>${route.name || 'Ruta sin nombre'}</name>
        <description>${route.description || 'Sin descripción'}</description>
        <Style>
          <LineStyle>
            <color>${kmlColor}</color>
            <width>${route.weight}</width>
          </LineStyle>
        </Style>
        <LineString>
          <coordinates>${waypointsToKmlCoordinates(route.waypoints)}</coordinates>
        </LineString>
      </Placemark>
    `;
  });
  
  // Generar KML para las anotaciones
  annotations.forEach(annotation => {
    const pinColor = annotation.color.replace('#', '');
    const kmlPinColor = 'ff' + pinColor.substring(4, 6) + pinColor.substring(2, 4) + pinColor.substring(0, 2);
    
    annotationsKml += `
      <Placemark>
        <name>${annotation.title || 'Anotación sin título'}</name>
        <description>${annotation.description || 'Sin descripción'}</description>
        <Style>
          <IconStyle>
            <color>${kmlPinColor}</color>
            <scale>1.2</scale>
            <Icon>
              <href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
            </Icon>
          </IconStyle>
        </Style>
        <Point>
          <coordinates>${annotation.position.lng},${annotation.position.lat},0</coordinates>
        </Point>
      </Placemark>
    `;
  });
  
  // Estructura KML completa
  return `<?xml version="1.0" encoding="UTF-8"?>
    <kml xmlns="http://www.opengis.net/kml/2.2">
      <Document>
        <name>Mapa de Rutas</name>
        <description>Rutas y anotaciones exportadas</description>
        
        <!-- Rutas -->
        <Folder>
          <name>Rutas</name>
          ${routesKml}
        </Folder>
        
        <!-- Anotaciones -->
        <Folder>
          <name>Anotaciones</name>
          ${annotationsKml}
        </Folder>
      </Document>
    </kml>`;
};

/**
 * Exporta las rutas y anotaciones a formato KMZ
 * @param {Array} routes - Array de objetos ruta
 * @param {Array} annotations - Array de objetos anotación
 * @param {String} filename - Nombre del archivo a exportar
 * @returns {Promise} - Promesa que resuelve cuando se completa la exportación
 */
export const exportToKmz = async (routes, annotations, filename = 'rutas_mapa') => {
  try {
    const kmlContent = generateKml(routes, annotations);
    
    // Crear archivo ZIP (KMZ es un archivo ZIP con KML)
    const zip = new JSZip();
    zip.file('doc.kml', kmlContent);
    
    // Generar el blob del archivo KMZ
    const content = await zip.generateAsync({ type: 'blob' });
    
    // Descargar el archivo
    saveAs(content, `${filename}.kmz`);
    
    return true;
  } catch (error) {
    console.error('Error al exportar a KMZ:', error);
    throw error;
  }
};

/**
 * Exporta las rutas a un archivo GeoJSON
 * @param {Array} routes - Array de objetos ruta
 * @param {Array} annotations - Array de objetos anotación
 * @param {String} filename - Nombre del archivo a exportar
 */
export const exportToGeoJson = (routes, annotations, filename = 'rutas_mapa') => {
  try {
    const features = [];
    
    // Convertir rutas a features GeoJSON
    routes.forEach(route => {
      if (!route.visible || route.waypoints.length < 2) return;
      
      features.push({
        type: 'Feature',
        properties: {
          name: route.name || 'Ruta sin nombre',
          description: route.description || 'Sin descripción',
          color: route.color,
          weight: route.weight,
          type: 'route'
        },
        geometry: {
          type: 'LineString',
          coordinates: route.waypoints.map(point => [point.lng, point.lat])
        }
      });
    });
    
    // Convertir anotaciones a features GeoJSON
    annotations.forEach(annotation => {
      features.push({
        type: 'Feature',
        properties: {
          name: annotation.title || 'Anotación sin título',
          description: annotation.description || 'Sin descripción',
          color: annotation.color,
          icon: annotation.icon,
          type: 'annotation'
        },
        geometry: {
          type: 'Point',
          coordinates: [annotation.position.lng, annotation.position.lat]
        }
      });
    });
    
    // Crear objeto GeoJSON completo
    const geoJson = {
      type: 'FeatureCollection',
      features: features
    };
    
    // Convertir a string y crear blob
    const geoJsonStr = JSON.stringify(geoJson, null, 2);
    const blob = new Blob([geoJsonStr], { type: 'application/json' });
    
    // Descargar el archivo
    saveAs(blob, `${filename}.geojson`);
    
    return true;
  } catch (error) {
    console.error('Error al exportar a GeoJSON:', error);
    throw error;
  }
};