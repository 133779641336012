import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./UploadProgress.scss";

/**
 * Componente de Progreso de Carga Reutilizable
 *
 * @component
 * @param {Object} props - Propiedades del componente
 * @param {number} props.percentage - Porcentaje de carga (0-100)
 * @param {string} props.type - Tipo de diseño de carga
 * @param {string} props.fileName - Nombre del archivo o elemento en carga
 * @param {string} props.uploadLink - Enlace de destino para redirección
 * @param {number} [props.estimatedTime=0] - Tiempo estimado de finalización en segundos
 * @param {string} [props.uploadContext=''] - Contexto adicional de la carga
 */
const UploadProgress = ({
  percentage,
  type,
  fileName,
  uploadLink,
  estimatedTime = 0,
  uploadContext = "",
  onReset = () => {},
  status,
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [internalPercentage, setInternalPercentage] = useState(percentage);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (progressBarWidth < percentage) {
        setProgressBarWidth(progressBarWidth + 1);
      }
    }, 20);

    return () => clearInterval(intervalId);
  }, [percentage, progressBarWidth]);

  // Efecto para manejar el reseteo automático
  useEffect(() => {
    let resetTimer;

    if (percentage === 100 && status === "complete") {
      resetTimer = setTimeout(() => {
        setProgressBarWidth(0);
        setInternalPercentage(0);
        onReset();
      }, 3000); // Reducir a 3 segundos para mejor UX
    }

    return () => {
      if (resetTimer) {
        clearTimeout(resetTimer);
      }
    };
  }, [percentage, onReset, status]);

  // Actualizar el porcentaje interno cuando cambia el porcentaje externo
  useEffect(() => {
    setInternalPercentage(percentage);
  }, [percentage]);
  // Redireccionar a la vista que queramos mostrar la informacion
  const handleProgressClick = () => {
    if (percentage === 100 || isHovered) {
      navigate(uploadLink);
    }
  };

  // Calcular clases dinámicas basadas en el tipo
  const getProgressClass = () => {
    const baseClass = "upload-progress";
    return `${baseClass} ${baseClass}--${type}`;
  };

  // Formatear tiempo estimado
  const formatEstimatedTime = () => {
    if (estimatedTime <= 0) return "Calculando...";

    const minutes = Math.floor(estimatedTime / 60);
    const seconds = estimatedTime % 60;

    return minutes > 0
      ? `${minutes} min ${seconds} seg restantes`
      : `${seconds} seg restantes`;
  };

  return (
    <Tooltip title={`${fileName} - ${formatEstimatedTime()}`} placement="top">
      <div
        className={getProgressClass()}
        onClick={handleProgressClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        aria-label={`Progreso de carga: ${internalPercentage}%`}
      >
        {/* Contenido dinámico según el tipo de progreso */}
        {type === "small-circle" ? (
          <div className="upload-progress__small-circle">
            <span className="upload-progress__percentage">
              {internalPercentage}%
            </span>
            <svg viewBox="0 0 36 36" className="upload-progress__circle-svg">
              <path
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke="#eee"
                strokeWidth="3"
              />
              <path
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke="#4CAF50"
                strokeWidth="3"
                strokeDasharray={`${internalPercentage}, 100`}
              />
            </svg>
          </div>
        ) : (
          <div className="upload-progress__bar">
            <div
              className="upload-progress__bar-fill"
              style={{ width: `${progressBarWidth}%` }}
            />
            <div
              className="upload-progress__bar-shadow"
              style={{ width: `${internalPercentage}%` }}
            />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

// Definición de PropTypes para validación de tipos
UploadProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  type: PropTypes.oneOf([
    "full-circle",
    "linear",
    "sidenav-linear",
    "small-circle",
  ]).isRequired,
  fileName: PropTypes.string.isRequired,
  uploadLink: PropTypes.string.isRequired,
  estimatedTime: PropTypes.number,
  uploadContext: PropTypes.string,
  onReset: PropTypes.func,
};

export default UploadProgress;
