export const getMonthAbbreviation = (month) => {
    const months = ['EN', 'FB', 'MR', 'AB', 'MY', 'JN', 'JL', 'AG', 'SP', 'OC', 'NV', 'DC'];
    return months[month - 1];
  };
  
  export const calculateViolationColor = (count) => {
    if (count <= 10) {
      // Verde a amarillo
      const percentage = count / 10;
      const r = Math.round(255 * percentage);
      const g = 255;
      return `rgb(${r}, ${g}, 0)`;
    } else if (count <= 20) {
      // Amarillo a rojo
      const percentage = (count - 10) / 10;
      const g = Math.round(255 * (1 - percentage));
      return `rgb(255, ${g}, 0)`;
    } else {
      // Rojo oscuro
      const percentage = Math.min((count - 20) / 30, 1);
      const r = Math.round(255 * (1 - percentage * 0.5));
      return `rgb(${r}, 0, 0)`;
    }
  };
  export const getWeekRanges = (period) => {
    if (!period) return [];
    
    const date = new Date(period);
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    
    // Calculamos las semanas del mes
    const weeks = [];
    let currentDay = 1;
    
    while (currentDay <= daysInMonth) {
      const endDay = Math.min(currentDay + 6, daysInMonth);
      weeks.push({
        start: currentDay,
        end: endDay,
        label: `Semana ${weeks.length + 1}\n(${currentDay}-${endDay})`,
        days: Array.from(
          { length: endDay - currentDay + 1 },
          (_, i) => currentDay + i
        )
      });
      currentDay = endDay + 1;
    }
    
    return weeks;
  };