import dayjs from "dayjs";
import { NotificationPositions, NotificationSeverity } from "../../context/NotificationContext";

export const validatePersonalInfo = (personalInfo, showNotification) => {
    // Validación de campos obligatorios básicos que son strings
    const requiredStringFields = {
      firstName: "El nombre",
      firstLastName: "El primer apellido",
      secondLastName: "El segundo apellido",
      documentNumber: "El número de documento",
      documentExpeditionPlace: "El lugar de expedición",
    };
  
    // Validación de campos obligatorios que no son strings
    const requiredNonStringFields = {
      documentTypeId: "El tipo de documento",
      nationality: "La nacionalidad"
    };
  
    // Validar campos tipo string
    for (const [field, message] of Object.entries(requiredStringFields)) {
      if (!personalInfo[field] || typeof personalInfo[field] !== 'string' || !personalInfo[field].trim()) {
        showNotification({
          message: `${message} es obligatorio`,
          severity: NotificationSeverity.WARNING,
          position: NotificationPositions.TOP_RIGHT,
          autoHideDuration: 4000,
        });
        return false;
      }
    }
  
    // Validar campos que no son string
    for (const [field, message] of Object.entries(requiredNonStringFields)) {
      if (!personalInfo[field]) {
        showNotification({
          message: `${message} es obligatorio`,
          severity: NotificationSeverity.WARNING,
          position: NotificationPositions.TOP_RIGHT,
          autoHideDuration: 4000,
        });
        return false;
      }
    }
  
    // Validación específica del documento
    if (!/^\d{8,12}$/.test(personalInfo.documentNumber.trim())) {
      showNotification({
        message: "El número de documento debe contener entre 8 y 12 dígitos numéricos",
        severity: NotificationSeverity.WARNING,
        position: NotificationPositions.TOP_RIGHT,
        autoHideDuration: 4000,
      });
      return false;
    }
  
    // Validación de fechas
    if (!personalInfo.birthDate) {
      showNotification({
        message: "La fecha de nacimiento es obligatoria",
        severity: NotificationSeverity.WARNING,
        position: NotificationPositions.TOP_RIGHT,
        autoHideDuration: 4000,
      });
      return false;
    }
  
    const birthDate = dayjs(personalInfo.birthDate);
    const today = dayjs();
    const minAge = today.subtract(18, "year");
  
    if (birthDate.isAfter(minAge)) {
      showNotification({
        message: "Debe ser mayor de 18 años para registrarse",
        severity: NotificationSeverity.WARNING,
        position: NotificationPositions.TOP_RIGHT,
        autoHideDuration: 4000,
      });
      return false;
    }
  
    if (!personalInfo.documentExpDate) {
      showNotification({
        message: "La fecha de expiración del documento es obligatoria",
        severity: NotificationSeverity.WARNING,
        position: NotificationPositions.TOP_RIGHT,
        autoHideDuration: 4000,
      });
      return false;
    }
  
    const expDate = dayjs(personalInfo.documentExpDate);
    if (expDate.isAfter(today)) {
      showNotification({
        message: "La fecha de expedición no puede ser futura",
        severity: NotificationSeverity.WARNING,
        position: NotificationPositions.TOP_RIGHT,
        autoHideDuration: 4000,
      });
      return false;
    }
  
    return true;
  };

export const validateContactInfo = (contactInfo, showNotification) => {
  // Validación del teléfono principal
  if (!contactInfo.primaryPhoneNumber?.trim()) {
    showNotification({
      message: "El teléfono principal es obligatorio",
      severity: NotificationSeverity.WARNING,
      position: NotificationPositions.TOP_RIGHT,
      autoHideDuration: 4000,
    });
    return false;
  }

  if (!contactInfo.primaryPhoneCountryId) {
    showNotification({
      message: "Debe seleccionar el país del teléfono principal",
      severity: NotificationSeverity.WARNING,
      position: NotificationPositions.TOP_RIGHT,
      autoHideDuration: 4000,
    });
    return false;
  }

  // Validación del email
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!contactInfo.email?.trim() || !emailRegex.test(contactInfo.email.trim())) {
    showNotification({
      message: "Por favor ingrese un correo electrónico válido",
      severity: NotificationSeverity.WARNING,
      position: NotificationPositions.TOP_RIGHT,
      autoHideDuration: 4000,
    });
    return false;
  }

  // Validación de la ubicación
  if (!contactInfo.location) {
    showNotification({
      message: "La ubicación es obligatoria",
      severity: NotificationSeverity.WARNING,
      position: NotificationPositions.TOP_RIGHT,
      autoHideDuration: 4000,
    });
    return false;
  }

  // Validación de teléfono secundario (si existe)
  if (contactInfo.secondaryPhoneNumber?.trim() && !contactInfo.secondaryPhoneCountryId) {
    showNotification({
      message: "Si ingresa un teléfono secundario, debe seleccionar su país",
      severity: NotificationSeverity.WARNING,
      position: NotificationPositions.TOP_RIGHT,
      autoHideDuration: 4000,
    });
    return false;
  }

  return true;
};

export const validateDocuments = (documents, documentTypes, showNotification) => {
    if (!documents.items || documents.items.length === 0) {
      showNotification({
        message: "Debe agregar al menos un documento",
        severity: NotificationSeverity.WARNING,
        position: NotificationPositions.TOP_RIGHT,
        autoHideDuration: 4000,
      });
      return false;
    }
  
    // Validar cada documento en el array de items
    for (const document of documents.items) {
      // Obtener el tipo de documento correspondiente
      const documentType = documentTypes.find(type => String(type.id) === String(document.docType));
      
      if (!documentType) {
        showNotification({
          message: `Tipo de documento no válido para el documento: ${document.fileName}`,
          severity: NotificationSeverity.WARNING,
          position: NotificationPositions.TOP_RIGHT,
          autoHideDuration: 4000,
        });
        return false;
      }
  
      // Validar campos básicos obligatorios para todos los documentos
      const basicRequiredFields = {
        docType: "tipo de documento",
        description: "descripción",
        fileName: "nombre del archivo",
        file: "archivo"
      };
  
      for (const [field, fieldName] of Object.entries(basicRequiredFields)) {
        if (!document[field]) {
          showNotification({
            message: `Falta el ${fieldName} para el documento tipo: ${documentType.name}`,
            severity: NotificationSeverity.WARNING,
            position: NotificationPositions.TOP_RIGHT,
            autoHideDuration: 4000,
          });
          return false;
        }
      }
  
      // Validar fechas solo si el documento es requerido
      if (document.required) {
        if (!document.expeditionDate) {
          showNotification({
            message: `Falta la fecha de expedición para el documento requerido tipo: ${documentType.name}`,
            severity: NotificationSeverity.WARNING,
            position: NotificationPositions.TOP_RIGHT,
            autoHideDuration: 4000,
          });
          return false;
        }
  
        // Validar que la fecha de expedición no sea futura
        const expeditionDate = dayjs(document.expeditionDate);
        const today = dayjs();
        if (expeditionDate.isAfter(today)) {
          showNotification({
            message: `La fecha de expedición no puede ser futura para el documento tipo: ${documentType.name}`,
            severity: NotificationSeverity.WARNING,
            position: NotificationPositions.TOP_RIGHT,
            autoHideDuration: 4000,
          });
          return false;
        }
      }
    }
  
    // Validar que existan todos los tipos de documentos requeridos
    const requiredTypes = documentTypes.filter(type => type.required);
    for (const requiredType of requiredTypes) {
      const hasRequiredDocument = documents.items.some(
        doc => String(doc.docType) === String(requiredType.id)
      );
  
      if (!hasRequiredDocument) {
        showNotification({
          message: `Debe agregar al menos un documento del tipo: ${requiredType.name}`,
          severity: NotificationSeverity.WARNING,
          position: NotificationPositions.TOP_RIGHT,
          autoHideDuration: 4000,
        });
        return false;
      }
    }
  
    return true;
  };

export const hasChangesSendUserInfoUpdated = (originalData, newData) => {
  const compareData = {
    original: {
      first_name: originalData.first_name,
      first_last_name: originalData.first_last_name,
      second_last_name: originalData.second_last_name,
      email: originalData.email,
      document_number: originalData.document_number,
      document_type_id: originalData.document_type_id,
      document_exp_date: originalData.document_exp_date,
      document_expedition_place: originalData.document_expedition_place,
      birth_date: originalData.birth_date,
      primary_phone_number: originalData.primary_phone_number,
      primary_phone_country_id: originalData.primary_phone_country_id,
      secondary_phone_number: originalData.secondary_phone_number,
      secondary_phone_country_id: originalData.secondary_phone_country_id,
      location_id: originalData.location_id,
      nationality: originalData.nationality,
      observations: originalData.observations
    },
    new: {
      first_name: newData.firstName,
      first_last_name: newData.firstLastName,
      second_last_name: newData.secondLastName,
      email: newData.email,
      document_number: newData.documentNumber,
      document_type_id: newData.documentTypeId,
      document_exp_date: newData.documentExpDate,
      document_expedition_place: newData.documentExpeditionPlace,
      birth_date: newData.birthDate,
      primary_phone_number: newData.primaryPhoneNumber,
      primary_phone_country_id: newData.primaryPhoneCountryId,
      secondary_phone_number: newData.secondaryPhoneNumber,
      secondary_phone_country_id: newData.secondaryPhoneCountryId,
      location_id: newData.location?.id,
      nationality: newData.nationality,
      observations: newData.observations
    }
  };

  // Encontrar campos modificados
  const changes = {};
  Object.keys(compareData.new).forEach(key => {
    const originalValue = compareData.original[key]?.toString() || '';
    const newValue = compareData.new[key]?.toString() || '';
    
    if (originalValue !== newValue) {
      changes[key] = {
        from: originalValue,
        to: newValue
      };
    }
  });

  return {
    hasChanges: Object.keys(changes).length > 0,
    changes
  };
};
