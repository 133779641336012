import React, { useState, useEffect } from 'react';
import { useGetUsersWithRole } from '../../../hooks/useReactQuery';
import '../../../assets/scss/views/ViewContentMultiUse.scss';
import PaginationComponent from '../../../components/ui/pagination/PaginationComponent';
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useLayout } from "../../../context/LayoutContext";
import { debounce } from "lodash";
import { Tooltip, IconButton, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CardWithThemeUsers from '../../../components/ui/cards/CardWithThemeUsers';

export default function ViewAdminContent() {
  const { theme } = useLayout();
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(1);
  const [usersPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");
  
  // Definir el ID de rol para administradores
  const roleId = 4;
  
  // Debounce para la búsqueda
  const debouncedSearch = debounce((value) => {
    setSearchTerm(value);
    setSelectedPage(1);
  }, 500);

  useEffect(() => {
    debouncedSearch(searchValue);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue]);
  
  const {
    data: dataUsersWithRole,
    isLoading,
    error,
  } = useGetUsersWithRole(selectedPage, usersPerPage, searchTerm, roleId);

  const handlePageChange = (pageNumber) => {
    setSelectedPage(pageNumber);
  };
  
  const handleAddAdmin = () => {
    navigate("persons/add/user/4");
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return (
      <div className={`GM__${theme}-error`}>
        Error al cargar los datos: {error.message}
      </div>
    );
  }

  const totalPages = Math.ceil(dataUsersWithRole?.total / usersPerPage);

  return (
    <div className={`GM__${theme}-operator-container`}>
      <div className={`GM__${theme}-operator-header`}>
        <div className={`GM__${theme}-search-container`}>
          <div className={`GM__${theme}-search-wrapper`}>
            <input
              type="text"
              placeholder="Buscar administrador..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className={`GM__${theme}-search-input`}
            />
            <IconButton className={`GM__${theme}-search-button`}>
              <SearchIcon />
            </IconButton>
          </div>
        </div>

        <div className={`GM__${theme}-actions-container`}>
          <Tooltip title="Agregar nuevo administrador" arrow placement="top">
            <button
              className={`GM__${theme}-add-button`}
              onClick={handleAddAdmin}
              aria-label="Agregar administrador"
            >
              <AddIcon />
              <span>Agregar administrador</span>
            </button>
          </Tooltip>
        </div>
      </div>

      <div className={`GM__${theme}-operator-grid`}>
        {dataUsersWithRole?.data?.map((user) => (
          <CardWithThemeUsers
            key={user.id}
            userId={user.id}
            name={user.first_name}
            lastName={`${user.first_last_name} ${user.second_last_name}`}
            personImage={user.id}
            cellPhone={user.phone_number}
            roleId={roleId}
            theme={theme}
            grupId={user.grup_id || "admin"}
          />
        ))}
      </div>

      <div className={`GM__${theme}-pagination-container`}>
        <PaginationComponent
          currentPage={selectedPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          theme={theme}
        />
      </div>
    </div>
  );
}