// sections/GeneralParametersSection.jsx
import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { calculateSpeedsByTerrainType } from "../../../lib/speedCalculator.js";
import "../../../assets/scss/components/GeneralParametersSection.scss";

const GeneralParametersSection = ({ data, topographyData, theme, onUpdate }) => {
  const [parametersData, setParametersData] = useState(null);
  const [loading, setLoading] = useState(false);
  const calculationRef = useRef(false);
  const lastTopographyRef = useRef(null);
  const updateBackendTimer = useRef(null);
  
  // Función para calcular parámetros generales basados en topografía
  const calculateParameters = useCallback((baseData, topography) => {
    if (!baseData || !topography || !topography.segments) {
      return null;
    }
    
    try {
      // Calcular velocidades por tipo de terreno
      const { loadedSpeeds, unloadedSpeeds, avgSpeed } = calculateSpeedsByTerrainType(
        baseData.vehicleConfig,
        topography.segments,
        baseData.outboundCargoCondition,
        baseData.inboundCargoCondition,
        baseData.routeType
      );
      
      // Calcular tiempos
      const totalDistance = topography.totalDistance || baseData.calculatedRoute?.distance || 0;
      const routeDuration = totalDistance / avgSpeed; // Horas
      
      // Tiempos de carga/descarga (usar valores por defecto o los proporcionados)
      const loadingHours = 1;
      const unloadingHours = 1;
      const waitingHours = 2;
      const totalWaitingHours = loadingHours + unloadingHours + waitingHours;
      
      // Tiempo total de viaje incluyendo espera
      const totalTripTime = routeDuration + totalWaitingHours;
      
      // Operación mensual
      const operatingDaysPerMonth = 30; // Valor por defecto
      const tripsPerMonth = baseData.tripsPerMonth || 7;
      const tripCycleDays = Math.ceil(totalTripTime / 24); // Días por ciclo de viaje
      const volumePerTrip = baseData.cargoQuantity ? 
        parseFloat(baseData.cargoQuantity) : 11000; // Valor por defecto en galones
      
      // Generar objeto de parámetros calculados
      return {
        speeds: {
          loaded: loadedSpeeds,
          unloaded: unloadedSpeeds
        },
        times: {
          routeDuration: routeDuration,
          averageSpeed: avgSpeed,
          waitingHours: totalWaitingHours,
          totalTripTime: totalTripTime
        },
        operation: {
          operatingDays: operatingDaysPerMonth,
          tripsPerMonth: tripsPerMonth,
          cycleDays: tripCycleDays,
          volumePerTrip: volumePerTrip
        },
        calculatedAt: new Date().toISOString()
      };
    } catch (error) {
      console.error("Error calculando parámetros generales:", error);
      return null;
    }
  }, []);
  
  // Efecto para actualizar cuando cambia la topografía
  useEffect(() => {
    const processData = async () => {
      // Si no hay datos de topografía o estamos calculando, salir
      if (!topographyData || calculationRef.current) {
        return;
      }
      
      // Verificar si topografía ha cambiado
      const topographyKey = topographyData.calculatedAt || JSON.stringify(topographyData.segments);
      const lastTopographyKey = lastTopographyRef.current;
      
      // Si los datos de topografía no han cambiado y ya tenemos parámetros, salir
      if (
        topographyKey === lastTopographyKey &&
        data?.speeds?.loaded &&
        data?.times?.routeDuration
      ) {
        setParametersData(data);
        return;
      }
      
      // Actualizar referencia de última topografía procesada
      lastTopographyRef.current = topographyKey;
      
      try {
        setLoading(true);
        calculationRef.current = true;
        
        // Combinar datos básicos y topografía
        const baseData = {
          vehicleConfig: topographyData.vehicleConfig || "camion_dos_ejes_sencillo_pbv_8001_9000",
          outboundCargoCondition: topographyData.outboundCargoCondition || "CARGADO",
          inboundCargoCondition: topographyData.inboundCargoCondition,
          routeType: topographyData.routeType || "oneway",
          tripsPerMonth: topographyData.tripsPerMonth || 7,
          cargoQuantity: topographyData.cargoQuantity,
          calculatedRoute: topographyData.calculatedRoute
        };
        
        // Calcular parámetros
        const newParameters = calculateParameters(baseData, topographyData);
        
        if (newParameters) {
          setParametersData(newParameters);
          
          // Actualizar backend con debounce
          if (updateBackendTimer.current) {
            clearTimeout(updateBackendTimer.current);
          }
          
          updateBackendTimer.current = setTimeout(() => {
            if (onUpdate && typeof onUpdate === 'function') {
              onUpdate(newParameters);
            }
            updateBackendTimer.current = null;
          }, 1000);
        }
      } catch (err) {
        console.error("Error en procesamiento de parámetros:", err);
      } finally {
        setLoading(false);
        calculationRef.current = false;
      }
    };
    
    processData();
    
    return () => {
      if (updateBackendTimer.current) {
        clearTimeout(updateBackendTimer.current);
      }
    };
  }, [topographyData, data, calculateParameters, onUpdate]);
  
  // Si está cargando, mostrar indicador
  if (loading) {
    return (
      <div className={`GM__${theme}-general-parameters-section loading`}>
        <div className="loading-indicator">
          <div className="spinner"></div>
          <p>Calculando parámetros generales...</p>
        </div>
      </div>
    );
  }
  
  // Si no hay datos, mostrar mensaje
  if (!parametersData && !data) {
    return (
      <div className={`GM__${theme}-general-parameters-section no-data`}>
        <p>Complete las características de ruta para calcular parámetros</p>
      </div>
    );
  }
  
  // Usar datos existentes o datos calculados
  const displayData = parametersData || data || {};
  const speeds = displayData.speeds || {};
  const times = displayData.times || {};
  const operation = displayData.operation || {};
  
  // Renderizar componente con datos
  return (
    <div className={`GM__${theme}-general-parameters-section`}>
      <h3>Parámetros generales</h3>
      
      <div className="parameters-container">
        {/* Columna de Velocidades */}
        <div className="speeds-column">
          <h4>Velocidades</h4>
          <div className="speed-headers">
            <div className="speed-header">Cargado</div>
            <div className="speed-header">Vacío</div>
          </div>
          
          <ul className="speeds-list">
            <li className="speed-item">
              <div className="terrain-icon">
                <img 
                  src={require("../../../assets/svg/topography/plano.svg").default} 
                  alt="Plano" 
                />
              </div>
              <span className="terrain-name">Plano</span>
              <span className="speed-value">{speeds.loaded?.plano || 0} km/h</span>
              <span className="speed-value">{speeds.unloaded?.plano || 0} km/h</span>
            </li>
            
            <li className="speed-item">
              <div className="terrain-icon">
                <img 
                  src={require("../../../assets/svg/topography/pendiente-baja-positiva.svg").default} 
                  alt="Ondulado" 
                />
              </div>
              <span className="terrain-name">Ondulado</span>
              <span className="speed-value">{speeds.loaded?.ondulado || 0} km/h</span>
              <span className="speed-value">{speeds.unloaded?.ondulado || 0} km/h</span>
            </li>
            
            <li className="speed-item">
              <div className="terrain-icon">
                <img 
                  src={require("../../../assets/svg/topography/pendiente-alta-positiva.svg").default}
                  alt="Montañoso" 
                />
              </div>
              <span className="terrain-name">Montañoso</span>
              <span className="speed-value">{speeds.loaded?.montanoso || 0} km/h</span>
              <span className="speed-value">{speeds.unloaded?.montanoso || 0} km/h</span>
            </li>
            
            <li className="speed-item">
              <div className="terrain-icon">
                <img 
                  src={require("../../../assets/svg/topography/zona-urbana.svg").default}
                  alt="Urbano" 
                />
              </div>
              <span className="terrain-name">Urbano</span>
              <span className="speed-value">{speeds.loaded?.urbano || 0} km/h</span>
              <span className="speed-value">{speeds.unloaded?.urbano || 0} km/h</span>
            </li>
            
            <li className="speed-item">
              <div className="terrain-icon">
                <img 
                  src={require("../../../assets/svg/topography/zona-despavimentada.svg").default}
                  alt="Despavimentado" 
                />
              </div>
              <span className="terrain-name">Despavimentado</span>
              <span className="speed-value">{speeds.loaded?.despavimentado || 0} km/h</span>
              <span className="speed-value">{speeds.unloaded?.despavimentado || 0} km/h</span>
            </li>
          </ul>
        </div>
        
        {/* Columna de Tiempos */}
        <div className="times-column">
          <h4>Tiempos</h4>
          <ul className="times-list">
            <li className="time-item">
              <div className="time-icon">
                <img 
                  src={require("../../../assets/svg/parameters/duration.svg").default}
                  alt="Duración" 
                />
              </div>
              <span className="time-name">Duración recorrido</span>
              <span className="time-value">{times.routeDuration?.toFixed(2) || 0} Horas</span>
            </li>
            
            <li className="time-item">
              <div className="time-icon">
                <img 
                  src={require("../../../assets/svg/parameters/average-speed.svg").default}
                  alt="Velocidad promedio" 
                />
              </div>
              <span className="time-name">Velocidad promedio</span>
              <span className="time-value">{times.averageSpeed?.toFixed(0) || 0} km/h</span>
            </li>
            
            <li className="time-item">
              <div className="time-icon">
                <img 
                  src={require("../../../assets/svg/parameters/waiting.svg").default}
                  alt="Espera" 
                />
              </div>
              <span className="time-name">Horas de espera, cargue, descargue y consec. de carga</span>
              <span className="time-value">{times.waitingHours || 0} Horas</span>
            </li>
            
            <li className="time-item">
              <div className="time-icon">
                <img 
                  src={require("../../../assets/svg/parameters/waiting.svg").default}
                  alt="Tiempo total" 
                />
              </div>
              <span className="time-name">Tiempo de viaje (incluida espera del cargue)</span>
              <span className="time-value">{times.totalTripTime?.toFixed(2) || 0} Horas</span>
            </li>
          </ul>
        </div>
        
        {/* Columna de Operación Mensual */}
        <div className="operation-column">
          <h4>Operación mensual</h4>
          <ul className="operation-list">
            <li className="operation-item">
              <div className="operation-icon">
                <img 
                  src={require("../../../assets/svg/parameters/duration.svg").default}
                  alt="Días operativos" 
                />
              </div>
              <span className="operation-name">Días operativos</span>
              <span className="operation-value">{operation.operatingDays || 30} días</span>
            </li>
            
            <li className="operation-item">
              <div className="operation-icon">
                <img 
                  src={require("../../../assets/svg/parameters/duration.svg").default}
                  alt="Viajes" 
                />
              </div>
              <span className="operation-name">Viajes por mes</span>
              <span className="operation-value">{operation.tripsPerMonth || 0} viajes</span>
            </li>
            
            <li className="operation-item">
              <div className="operation-icon">
                <img 
                  src={require("../../../assets/svg/parameters/cycle.svg").default}
                  alt="Ciclo" 
                />
              </div>
              <span className="operation-name">Ciclo de cada viaje</span>
              <span className="operation-value">{operation.cycleDays || 0} días</span>
            </li>
            
            <li className="operation-item">
              <div className="operation-icon">
                <img 
                  src={require("../../../assets/svg/parameters/volume.svg").default}
                  alt="Volumen" 
                />
              </div>
              <span className="operation-name">Volumen por viaje</span>
              <span className="operation-value">{operation.volumePerTrip || 0} gal</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

GeneralParametersSection.propTypes = {
  data: PropTypes.object,
  topographyData: PropTypes.object,
  theme: PropTypes.string,
  onUpdate: PropTypes.func
};

export default GeneralParametersSection;