import React from 'react';
import '../../assets/scss/components/MapControls.scss';

const MapControls = ({ 
  drawingMode, 
  setDrawingMode, 
  isDrawing, 
  setIsDrawing,
  mapRef,
  mapType,
  setMapType,
  selectionMode
}) => {
  const handleSetDrawingMode = (mode) => {
    if (drawingMode === mode) {
      setDrawingMode(null);
      setIsDrawing(false);
    } else {
      setDrawingMode(mode);
      setIsDrawing(true);
    }
  };

  const handleZoomIn = () => {
    if (mapRef && mapRef.current) {
      mapRef.current.setZoom(mapRef.current.getZoom() + 1);
    }
  };

  const handleZoomOut = () => {
    if (mapRef && mapRef.current) {
      mapRef.current.setZoom(mapRef.current.getZoom() - 1);
    }
  };

  const handleChangeMapType = (mapTypeId) => {
    setMapType(mapTypeId);
  };

  return (
    <div className="map-controls">
      <div className="map-controls__drawing">
        <button
          className={`control-btn ${drawingMode === 'route' ? 'active' : ''}`}
          onClick={() => handleSetDrawingMode('route')}
          title="Dibujar Ruta"
          disabled={!!selectionMode}
        >
          <i className="fas fa-route"></i>
        </button>
        <button
          className={`control-btn ${drawingMode === 'annotation' ? 'active' : ''}`}
          onClick={() => handleSetDrawingMode('annotation')}
          title="Añadir Anotación"
          disabled={!!selectionMode}
        >
          <i className="fas fa-map-marker-alt"></i>
        </button>
      </div>

      <div className="map-controls__zoom">
        <button
          className="control-btn"
          onClick={handleZoomIn}
          title="Acercar"
        >
          <i className="fas fa-plus"></i>
        </button>
        <button
          className="control-btn"
          onClick={handleZoomOut}
          title="Alejar"
        >
          <i className="fas fa-minus"></i>
        </button>
      </div>

      <div className="map-controls__types">
        <button
          className={`control-btn ${mapType === 'roadmap' ? 'active' : ''}`}
          onClick={() => handleChangeMapType('roadmap')}
          title="Mapa de Carreteras"
        >
          <i className="fas fa-road"></i>
        </button>
        <button
          className={`control-btn ${mapType === 'satellite' ? 'active' : ''}`}
          onClick={() => handleChangeMapType('satellite')}
          title="Mapa Satelital"
        >
          <i className="fas fa-satellite"></i>
        </button>
        <button
          className={`control-btn ${mapType === 'terrain' ? 'active' : ''}`}
          onClick={() => handleChangeMapType('terrain')}
          title="Mapa de Terreno"
        >
          <i className="fas fa-mountain"></i>
        </button>
        <button
          className={`control-btn ${mapType === 'hybrid' ? 'active' : ''}`}
          onClick={() => handleChangeMapType('hybrid')}
          title="Mapa Híbrido"
        >
          <i className="fas fa-globe-americas"></i>
        </button>
        
        {/* Nuevo botón para modo topográfico (similar al PDF) */}
        <button
          className={`control-btn ${mapType === 'topographic' ? 'active' : ''}`}
          onClick={() => handleChangeMapType('topographic')}
          title="Mapa Topográfico"
        >
          <i className="fas fa-layer-group"></i>
        </button>
      </div>

      {/* Indicador de modo de selección */}
      {selectionMode && (
        <div className="map-controls__selection-mode">
          <div className="selection-indicator">
            <div className="pulse"></div>
            <span>
              {selectionMode === 'start' && "Seleccionando punto de inicio: Haga clic en el mapa"}
              {selectionMode === 'end' && "Seleccionando punto final: Haga clic en el mapa"}
              {selectionMode === 'annotation' && "Seleccionando ubicación para anotación: Haga clic en el mapa"}
            </span>
          </div>
        </div>
      )}

      {/* Indicador de modo de dibujo */}
      {isDrawing && !selectionMode && (
        <div className="map-controls__drawing-info">
          <div className="drawing-indicator">
            <div className="pulse"></div>
            <span>
              {drawingMode === 'route' && "Dibujando Ruta: Haga clic para añadir puntos"}
              {drawingMode === 'annotation' && "Añadiendo Anotación: Haga clic para colocar"}
            </span>
          </div>
          <button 
            className="control-btn cancel-btn"
            onClick={() => {
              setDrawingMode(null);
              setIsDrawing(false);
            }}
          >
            <i className="fas fa-times"></i> Cancelar
          </button>
        </div>
      )}
    </div>
  );
};

export default MapControls;