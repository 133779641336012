import React from 'react';
import PropTypes from 'prop-types';

export default function ReplyPreview({ message, onClose }) {
  return (
    <div className="reply-preview">
      <div className="reply-content">
        <span className="reply-author">
          {message.sender.first_name} {message.sender.first_last_name}
        </span>
        <p className="reply-text">{message.content}</p>
      </div>
      <button className="close-reply" onClick={onClose}>
        <i className="fas fa-times" />
      </button>
    </div>
  );
}

ReplyPreview.propTypes = {
  message: PropTypes.shape({
    sender: PropTypes.shape({
      first_name: PropTypes.string,
      first_last_name: PropTypes.string,
    }),
    content: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};