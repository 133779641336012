import React, { useState } from "react";
import { Pagination, Tooltip, Dialog } from "@mui/material";
import { useNotification } from "../../../context/NotificationContext";
import { useLayout } from "../../../context/LayoutContext";
import PropTypes from "prop-types";
import CopyIcon from "../../../assets/svg/components/copy.svg";
import EditIcon from "../../../assets/svg/view/edit.svg";
import DeleteIcon from "../../../assets/svg/components/trash.svg";
import InfoIcon from "../../../assets/svg/view/img.svg";
import LoadingAnimation from "../../loading/LoadingAnimation";
import { useDocumentTypes } from "../../../hooks/useDocumentationVehicleQuery";

// Función para truncar texto
function truncateText(text = "", maxLength = 30) {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
}

export function DocumentVehicleTypesTable({ fixedColumnsMode = 1 }) {
  // Acceso al tema actual
  const { theme } = useLayout();
  
  // Notificaciones
  const { showNotification } = useNotification();
  
  // Estados para paginación y búsqueda
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [orderDirection, setOrderDirection] = useState("ASC");
  
  // Estados para el diálogo de crear/editar
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentDocType, setCurrentDocType] = useState(null);
  const [docTypeName, setDocTypeName] = useState("");
  const [docTypeDescription, setDocTypeDescription] = useState("");
  
  // Estados para el diálogo de confirmación de eliminación
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [docTypeToDelete, setDocTypeToDelete] = useState(null);

  // Estados para el diálogo de detalles
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [detailsDocType, setDetailsDocType] = useState(null);

  // Usar hook de tipos de documentos con los parámetros actuales
  const { 
    documentTypes, 
    metadata, 
    isLoading, 
    isError, 
    createDocumentType, 
    updateDocumentType, 
    deleteDocumentType 
  } = useDocumentTypes({
    limit,
    offset: (page - 1) * limit,
    search,
    orderBy,
    orderDirection
  });

  // Función para cambiar el orden
  const handleSortChange = (field) => {
    if (orderBy === field) {
      setOrderDirection(orderDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setOrderBy(field);
      setOrderDirection("ASC");
    }
  };

  // Manejador de búsqueda
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reiniciar a primera página cuando se busca
  };

  // Manejador de cambio de página
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Manejador para cambiar el límite de elementos por página
  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1); // Reiniciar a primera página cuando se cambia el límite
  };

  // Copiar al portapapeles
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showNotification({
          message: "¡Copiado exitosamente!",
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: "Error al copiar.",
          severity: "error",
        });
      });
  };

  // Abrir diálogo para ver detalles
  const handleViewDetails = (docType) => {
    setDetailsDocType(docType);
    setDetailsDialogOpen(true);
  };

  // Abrir diálogo para crear
  const handleCreate = () => {
    setIsEditing(false);
    setCurrentDocType(null);
    setDocTypeName("");
    setDocTypeDescription("");
    setDialogOpen(true);
  };

  // Abrir diálogo para editar
  const handleEdit = (docType) => {
    setIsEditing(true);
    setCurrentDocType(docType);
    setDocTypeName(docType.name);
    setDocTypeDescription(docType.description || "");
    setDialogOpen(true);
  };

  // Abrir diálogo para confirmar eliminación
  const handleDeleteClick = (docType) => {
    setDocTypeToDelete(docType);
    setDeleteDialogOpen(true);
  };

  // Cerrar diálogos
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDeleteDialogOpen(false);
    setDetailsDialogOpen(false);
  };

  // Guardar tipo de documento (crear o actualizar)
  const handleSaveDocType = async () => {
    if (!docTypeName.trim()) {
      showNotification({
        message: "El nombre del tipo de documento es requerido",
        severity: "error",
      });
      return;
    }

    try {
      if (isEditing && currentDocType) {
        await updateDocumentType({
          id: currentDocType.id,
          data: { 
            name: docTypeName.trim(),
            description: docTypeDescription.trim() 
          }
        });
        showNotification({
          message: "Tipo de documento actualizado exitosamente",
          severity: "success",
        });
      } else {
        await createDocumentType({ 
          name: docTypeName.trim(),
          description: docTypeDescription.trim() 
        });
        showNotification({
          message: "Tipo de documento creado exitosamente",
          severity: "success",
        });
      }
      handleCloseDialog();
    } catch (error) {
      showNotification({
        message: error.message || "Error al procesar el tipo de documento",
        severity: "error",
      });
    }
  };

  // Confirmar eliminación
  const handleConfirmDelete = async () => {
    if (!docTypeToDelete) return;
    
    try {
      await deleteDocumentType(docTypeToDelete.id);
      showNotification({
        message: "Tipo de documento eliminado exitosamente",
        severity: "success",
      });
      handleCloseDialog();
    } catch (error) {
      showNotification({
        message: error.message || "Error al eliminar el tipo de documento",
        severity: "error",
      });
    }
  };

  // Renderizar indicadores de carga y error
  if (isLoading) return <LoadingAnimation />;
  if (isError) return <div className={`GM__${theme}-error-message`}>Hubo un error al cargar los tipos de documentos.</div>;

  return (
    <div className={`GM__${theme}-vehicle-table-wrapper`}>
      <div className={`GM__${theme}-header-section`}>
        <h2 className={`GM__${theme}-section-title`}>Tipos de Documentos</h2>
        <div className={`GM__${theme}-filter-container`}>
          <input
            type="text"
            placeholder="Buscar por nombre o descripción..."
            value={search}
            onChange={handleSearchChange}
            className={`GM__${theme}-search-input`}
          />
          <select 
            value={limit} 
            onChange={handleLimitChange}
            className={`GM__${theme}-limit-select`}
          >
            <option value={5}>5 por página</option>
            <option value={10}>10 por página</option>
            <option value={25}>25 por página</option>
            <option value={50}>50 por página</option>
          </select>
          <button 
            className={`GM__${theme}-add-button`}
            onClick={handleCreate}
          >
            Agregar Tipo de Documento
          </button>
        </div>
      </div>

      <div className={`GM__${theme}-vehicle-table-wrapper-table`}>
        <table className={`GM__${theme}-vehicle-table fixed-columns-${fixedColumnsMode}`}>
          <thead>
            <tr>
              <th
                className={`${
                  fixedColumnsMode === 1 || fixedColumnsMode === 3
                    ? "fixed-column fixed-column-1"
                    : ""
                } GM__${theme}-sortable-header ${orderBy === 'name' ? 'sorted' : ''}`}
                onClick={() => handleSortChange('name')}
              >
                Nombre
                {orderBy === 'name' && (
                  <span className={`GM__${theme}-sort-icon`}>
                    {orderDirection === 'ASC' ? ' ↑' : ' ↓'}
                  </span>
                )}
              </th>
              <th>Descripción</th>
              <th 
                className={`GM__${theme}-sortable-header ${orderBy === 'created_at' ? 'sorted' : ''}`}
                onClick={() => handleSortChange('created_at')}
              >
                Fecha Creación
                {orderBy === 'created_at' && (
                  <span className={`GM__${theme}-sort-icon`}>
                    {orderDirection === 'ASC' ? ' ↑' : ' ↓'}
                  </span>
                )}
              </th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {documentTypes.length > 0 ? (
              documentTypes.map((docType) => (
                <tr key={docType.id}>
                  {/* Nombre: Truncado, Tooltip y copiable */}
                  <td
                    className={`GM__${theme}-copyable-cell ${
                      fixedColumnsMode === 1 || fixedColumnsMode === 3
                        ? "fixed-column fixed-column-1"
                        : ""
                    }`}
                  >
                    <Tooltip title={docType.name || "—"}>
                      <span>{truncateText(docType.name || "", 30)}</span>
                    </Tooltip>
                    <button
                      className={`GM__${theme}-copy-icon`}
                      onClick={() => handleCopy(docType.name)}
                      aria-label="Copiar nombre"
                    >
                      <img src={CopyIcon} alt="Copiar" />
                    </button>
                  </td>

                  {/* Descripción: Truncada y con Tooltip */}
                  <td>
                    <Tooltip title={docType.description || "Sin descripción"}>
                      <span>{truncateText(docType.description || "Sin descripción", 40)}</span>
                    </Tooltip>
                  </td>

                  {/* Fecha de creación */}
                  <td>
                    {new Date(docType.created_at).toLocaleDateString('es-ES', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })}
                  </td>

                  {/* Acciones */}
                  <td className={`GM__${theme}-action-buttons`}>
                    <button
                      className={`GM__${theme}-info-button`}
                      onClick={() => handleViewDetails(docType)}
                      aria-label="Ver detalles"
                    >
                      <img src={InfoIcon} alt="Detalles" className={`GM__${theme}-button-icon`} />
                      <span>Detalles</span>
                    </button>
                    <button
                      className={`GM__${theme}-edit-button`}
                      onClick={() => handleEdit(docType)}
                      aria-label="Editar"
                    >
                      <img src={EditIcon} alt="Editar" className={`GM__${theme}-button-icon`} />
                      <span>Editar</span>
                    </button>
                    <button
                      className={`GM__${theme}-delete-button`}
                      onClick={() => handleDeleteClick(docType)}
                      aria-label="Eliminar"
                    >
                      <img src={DeleteIcon} alt="Eliminar" className={`GM__${theme}-button-icon`} />
                      <span>Eliminar</span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className={`GM__${theme}-empty-message`}>
                  No se encontraron tipos de documentos
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Paginación */}
      {metadata.pages > 1 && (
        <div className={`GM__${theme}-pagination-container`}>
          <Pagination
            count={metadata.pages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            className={`GM__${theme}-custom-pagination`}
          />
          <div className={`GM__${theme}-pagination-info`}>
            Mostrando {(page - 1) * limit + 1} - {Math.min(page * limit, metadata.total)} de {metadata.total} resultados
          </div>
        </div>
      )}

      {/* Diálogo para crear/editar tipo de documento */}
      <Dialog 
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        className={`GM__modal-container-${theme}`}
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
      >
        <div className={`GM__${theme}-modal GM__${theme}-document-type-modal`}>
          <div className={`GM__${theme}-modal-header-container`}>
            <h3 className={`GM__${theme}-modal-header`}>
              {isEditing ? "Editar Tipo de Documento" : "Crear Nuevo Tipo de Documento"}
            </h3>
          </div>
          <div className={`GM__${theme}-modal-content`}>
            <div className={`GM__${theme}-form-row`}>
              <div className={`GM__${theme}-form-group full-width`}>
                <label className={`GM__${theme}-form-label`} htmlFor="docTypeName">
                  Nombre del Tipo de Documento
                </label>
                <input
                  id="docTypeName"
                  type="text"
                  value={docTypeName}
                  onChange={(e) => setDocTypeName(e.target.value)}
                  className={`GM__${theme}-form-input`}
                  placeholder="Ingrese nombre del tipo de documento"
                  autoFocus
                />
              </div>
            </div>
            <div className={`GM__${theme}-form-row`}>
              <div className={`GM__${theme}-form-group full-width`}>
                <label className={`GM__${theme}-form-label`} htmlFor="docTypeDescription">
                  Descripción (opcional)
                </label>
                <textarea
                  id="docTypeDescription"
                  value={docTypeDescription}
                  onChange={(e) => setDocTypeDescription(e.target.value)}
                  className={`GM__${theme}-form-textarea`}
                  placeholder="Ingrese una descripción para este tipo de documento"
                  rows={4}
                />
              </div>
            </div>
          </div>
          <div className={`GM__${theme}-modal-footer`}>
            <button
              className={`GM__${theme}-btn GM__${theme}-btn-cancel`}
              onClick={handleCloseDialog}
            >
              Cancelar
            </button>
            <button 
              className={`GM__${theme}-btn GM__${theme}-btn-save`}
              onClick={handleSaveDocType}
            >
              {isEditing ? "Actualizar" : "Crear"}
            </button>
          </div>
        </div>
      </Dialog>

      {/* Diálogo de confirmación para eliminar */}
      <Dialog 
        open={deleteDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        className={`GM__modal-container-${theme}`}
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
      >
        <div className={`GM__${theme}-modal GM__${theme}-confirm-modal`}>
          <div className={`GM__${theme}-modal-header-container`}>
            <h3 className={`GM__${theme}-modal-header`}>
              Confirmar Eliminación
            </h3>
          </div>
          <div className={`GM__${theme}-modal-content`}>
            <p>¿Está seguro que desea eliminar el tipo de documento "{docTypeToDelete?.name}"?</p>
            <p className={`GM__${theme}-warning-text`}>
              Esta acción no se puede deshacer y podría afectar a los requisitos documentales existentes.
            </p>
          </div>
          <div className={`GM__${theme}-modal-footer`}>
            <button 
              className={`GM__${theme}-btn GM__${theme}-btn-cancel`}
              onClick={handleCloseDialog}
            >
              Cancelar
            </button>
            <button 
              className={`GM__${theme}-btn GM__${theme}-btn-danger`}
              onClick={handleConfirmDelete}
            >
              Eliminar
            </button>
          </div>
        </div>
      </Dialog>

      {/* Diálogo de detalles */}
      <Dialog 
        open={detailsDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        className={`GM__modal-container-${theme}`}
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
      >
        <div className={`GM__${theme}-modal GM__${theme}-details-modal`}>
          <div className={`GM__${theme}-modal-header-container`}>
            <h3 className={`GM__${theme}-modal-header`}>
              Detalles del Tipo de Documento
            </h3>
          </div>
          <div className={`GM__${theme}-modal-content`}>
            {detailsDocType && (
              <div className={`GM__${theme}-document-type-details`}>
                <div className={`GM__${theme}-detail-row`}>
                  <span className={`GM__${theme}-detail-label`}>Nombre:</span>
                  <span className={`GM__${theme}-detail-value`}>{detailsDocType.name}</span>
                </div>
                
                <div className={`GM__${theme}-detail-row`}>
                  <span className={`GM__${theme}-detail-label`}>Descripción:</span>
                  <span className={`GM__${theme}-detail-value`}>{detailsDocType.description || "Sin descripción"}</span>
                </div>
                
                <div className={`GM__${theme}-detail-row`}>
                  <span className={`GM__${theme}-detail-label`}>Fecha de Creación:</span>
                  <span className={`GM__${theme}-detail-value`}>
                    {new Date(detailsDocType.created_at).toLocaleDateString('es-ES', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </span>
                </div>
                
                <div className={`GM__${theme}-detail-section`}>
                  <h3>Uso del Documento</h3>
                  <p className={`GM__${theme}-detail-note`}>
                    Para ver las operaciones y tipos de vehículos que requieren este documento,
                    vaya a la sección de "Requisitos Documentales".
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className={`GM__${theme}-modal-footer`}>
            <button 
              className={`GM__${theme}-btn GM__${theme}-btn-save`}
              onClick={handleCloseDialog}
            >
              Cerrar
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

DocumentVehicleTypesTable.propTypes = {
  fixedColumnsMode: PropTypes.oneOf([1, 2, 3]),
};