import React, { useState, useMemo } from "react";
import { useLayout } from "../../context/LayoutContext";
import CriticalPointsHotspotsTable from "../../components/ui/tables/CriticalPointsHotspotsTable";
import { FormControl, Select, MenuItem } from "@mui/material";
import "../../assets/scss/views/RepeatOffenders.scss";
import LoadingAnimation from "../../components/loading/LoadingAnimation";

export default function RepeatOffenders() {
  const { monthlyAnalytics, getCurrentDate, isLoading } = useLayout();
  const [viewType, setViewType] = useState("all");

  const currentPeriod = useMemo(() => {
    return monthlyAnalytics?.processedData?.period || '';
  }, [monthlyAnalytics]);

  const processRecurrences = () => {
    if (!monthlyAnalytics?.processedData?.recurrences) {
      return {
        violations: [],
        hasData: false
      };
    }

    const { speedViolations = [], criticalPointViolations = [] } = monthlyAnalytics.processedData.recurrences;

    // Procesar violaciones de puntos críticos
    const processedCriticalViolations = criticalPointViolations.map(violation => ({
      type: 'criticalPoint',
      placa: violation.placa,
      pointName: violation.pointName,
      criticalPointId: violation.criticalPointId,
      criticalPointData: violation.criticalPointData,
      occurrenceCount: violation.occurrenceCount,
      occurrences: violation.occurrences.map(occ => ({
        dateTime: occ.dateTime,
        location: occ.location,
        speed: occ.speed,
        tripData: {
          routeName: occ.tripData?.routeName,
          origin: {
            dateTime: occ.tripData?.origin?.dateTime,
            lat: occ.tripData?.origin?.lat,
            lon: occ.tripData?.origin?.lon
          },
          destination: {
            dateTime: occ.tripData?.destination?.dateTime,
            lat: occ.tripData?.destination?.lat,
            lon: occ.tripData?.destination?.lon
          },
          durationMinutes: occ.tripData?.durationMinutes
        }
      }))
    }));

    // Procesar violaciones de velocidad
    const processedSpeedViolations = speedViolations.map(violation => ({
      type: 'speed',
      placa: violation.placa,
      location: violation.location,
      speed: violation.speed,
      occurrenceCount: violation.occurrences?.length || 0,
      occurrences: violation.occurrences
    }));

    let violations = [];
    switch(viewType) {
      case "critical":
        violations = processedCriticalViolations;
        break;
      case "speed":
        violations = processedSpeedViolations;
        break;
      default:
        violations = [
          ...processedSpeedViolations,
          ...processedCriticalViolations
        ].sort((a, b) => a.placa?.localeCompare(b.placa || ''));
    }

    return {
      violations,
      hasData: violations.length > 0
    };
  };

  const { violations, hasData } = processRecurrences();

  return (
    <div className="repeatOffenders-container">
      <div className="repeatOffenders-header">
        <h1 className="repeatOffenders-title">Reincidencias por vehículo</h1>
        <div className="repeatOffenders-period">
          Período: {getCurrentDate || 'No disponible'}
        </div>
        <FormControl className="repeatOffenders-filter">
          <Select
            value={viewType}
            onChange={(e) => setViewType(e.target.value)}
            className="filter-select"
          >
            <MenuItem value="all">Todas las reincidencias</MenuItem>
            <MenuItem value="critical">Puntos críticos</MenuItem>
            <MenuItem value="speed">Excesos de velocidad</MenuItem>
          </Select>
        </FormControl>
      </div>

      {isLoading ? (
        <LoadingAnimation/>
      ) : (
        <div className="repeatOffenders-content">
          <CriticalPointsHotspotsTable
            violations={violations}
            hasData={hasData}
            theme="dark"
            currentMonth={currentPeriod}
            viewType={viewType}
          />
        </div>
      )}
    </div>
  );
}