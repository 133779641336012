import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import '../../assets/scss/components/common/ColorPicker.scss';

const ColorPicker = ({ color, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  
  const handleChange = (color) => {
    onChange(color.hex);
  };

  return (
    <div className="color-picker">
      <div 
        className="color-picker__swatch" 
        onClick={handleClick}
      >
        <div 
          className="color-picker__color" 
          style={{ backgroundColor: color }}
        />
      </div>
      {displayColorPicker ? (
        <div className="color-picker__popover">
          <div 
            className="color-picker__cover" 
            onClick={handleClose}
          />
          <SketchPicker 
            color={color} 
            onChange={handleChange} 
            disableAlpha={true}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;