import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ChevronLeft from '../icons/ChevronLeft';
import ChevronRight from '../icons/ChevronRight';
import '../../../assets/scss/components/ui/carousel/CarouselCards.scss';

const CarouselCards = ({ 
  children, 
  itemsPerView = 3, 
  autoRun = false, 
  autoRunInterval = 4000  // Modificado a 4 segundos por defecto
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchPosition, setTouchPosition] = useState(null);
  const carouselRef = useRef(null);

  const totalItems = React.Children.count(children);
  const maxIndex = totalItems - itemsPerView;

  const handlePrevious = () => {
    setCurrentIndex(prev => {
      if (prev === 0) {
        return maxIndex;
      }
      return prev - 1;
    });
  };

  const handleNext = () => {
    setCurrentIndex(prev => {
      if (prev >= maxIndex) {
        return 0;  // Volver al inicio cuando llega al final
      }
      return prev + 1;
    });
  };

  useEffect(() => {
    let interval;
    if (autoRun) {
      interval = setInterval(() => {
        handleNext();
      }, autoRunInterval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentIndex, autoRun, autoRunInterval, maxIndex]);

  const handleTouchStart = (e) => {
    setTouchPosition(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (touchPosition === null) return;

    const currentTouch = e.touches[0].clientX;
    const diff = touchPosition - currentTouch;

    if (diff > 5) {
      handleNext();
    }
    if (diff < -5) {
      handlePrevious();
    }

    setTouchPosition(null);
  };

  return (
    <div className="cards-carousel-container">
      <button 
        className="carousel-button previous" 
        onClick={handlePrevious}
      >
        <ChevronLeft />
      </button>

      <div 
        className="carousel-content"
        ref={carouselRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <div 
          className="carousel-items"
          style={{
            transform: `translateX(-${currentIndex * (100 / itemsPerView)}%)`,
          }}
        >
          {React.Children.map(children, (child) => (
            <div 
              className="carousel-item"
              style={{ width: `${100 / itemsPerView}%` }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>

      <button 
        className="carousel-button next"
        onClick={handleNext}
      >
        <ChevronRight />
      </button>
    </div>
  );
};
CarouselCards.propTypes = {
  children: PropTypes.node.isRequired,
  itemsPerView: PropTypes.number,
  autoRun: PropTypes.bool,
  autoRunInterval: PropTypes.number
};
export default CarouselCards;