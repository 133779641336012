import React, { useEffect, useState } from "react";
import ColorPicker from "../common/ColorPicker";
import "../../assets/scss/components/AnnotationManager.scss";

const AnnotationManager = ({
  annotations,
  activeAnnotationId,
  onCreateAnnotation,
  onUpdateAnnotation,
  onDeleteAnnotation,
  onSelectAnnotation,
  onFocusAnnotation,
  setSelectionMode,
}) => {
  const [isCreatingAnnotation, setIsCreatingAnnotation] = useState(false);
  const [newAnnotation, setNewAnnotation] = useState({
    title: "",
    description: "",
    color: "#FF0000",
    icon: "pin",
    coordinates: { lat: "", lng: "" },
  });
  const [expandedAnnotationId, setExpandedAnnotationId] = useState(null);
  const [isSelectingPosition, setIsSelectingPosition] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!newAnnotation.title.trim()) {
      alert("El título de la anotación es obligatorio");
      return;
    }

    // Verificar que haya coordenadas válidas
    const lat = parseFloat(newAnnotation.coordinates.lat);
    const lng = parseFloat(newAnnotation.coordinates.lng);

    if (isNaN(lat) || isNaN(lng)) {
      alert("Se requieren coordenadas válidas para la anotación");
      return;
    }

    // Crear la anotación
    onCreateAnnotation({
      ...newAnnotation,
      id: Date.now().toString(),
      position: { lat, lng },
    });

    // Resetear formulario
    setNewAnnotation({
      title: "",
      description: "",
      color: "#FF0000",
      icon: "pin",
      coordinates: { lat: "", lng: "" },
    });
    setIsCreatingAnnotation(false);
    setIsSelectingPosition(false);
  };

  const handleAnnotationClick = (annotationId) => {
    onSelectAnnotation(annotationId);
    setExpandedAnnotationId(
      expandedAnnotationId === annotationId ? null : annotationId
    );
  };

  const handleColorChange = (annotationId, color) => {
    onUpdateAnnotation(annotationId, { color });
  };

  const handleCoordinateChange = (coord, value) => {
    setNewAnnotation((prev) => ({
      ...prev,
      coordinates: {
        ...prev.coordinates,
        [coord]: value,
      },
    }));
  };

  const handleSelectOnMap = () => {
    setNewAnnotation((prev) => ({
      ...prev,
      coordinates: { lat: "", lng: "" },
    }));

    setIsSelectingPosition(true);
    setSelectionMode("annotation");
  };

  // Cuando se selecciona una anotación, escuchar los clics en el mapa
  useEffect(() => {
    // Creamos una función manejadora para el evento personalizado
    const handleAnnotationPointSelected = (event) => {
      const position = event.detail;
      if (position && isSelectingPosition) {
        setNewAnnotation(prev => ({
          ...prev,
          coordinates: {
            lat: position.lat.toString(),
            lng: position.lng.toString()
          }
        }));
        setIsSelectingPosition(false);
      }
    };
    
    // Añadimos el listener al documento
    document.addEventListener('annotation-point-selected', handleAnnotationPointSelected);
    
    // Limpiamos el listener cuando el componente se desmonte
    return () => {
      document.removeEventListener('annotation-point-selected', handleAnnotationPointSelected);
    };
  }, [isSelectingPosition]);

  return (
    <div className="annotation-manager">
      <div className="annotation-manager__header">
        <h3>Mis Anotaciones ({annotations.length})</h3>
        <button
          className="btn btn-primary"
          onClick={() => setIsCreatingAnnotation(true)}
        >
          Nueva Anotación
        </button>
      </div>

      {isCreatingAnnotation && (
        <div className="annotation-manager__form-container">
          <form onSubmit={handleSubmit} className="annotation-manager__form">
            <div className="form-group">
              <label>Título</label>
              <input
                type="text"
                value={newAnnotation.title}
                onChange={(e) =>
                  setNewAnnotation({ ...newAnnotation, title: e.target.value })
                }
                placeholder="Título de la anotación"
                className="form-control"
                required
              />
            </div>

            <div className="form-group">
              <label>Descripción</label>
              <textarea
                value={newAnnotation.description}
                onChange={(e) =>
                  setNewAnnotation({
                    ...newAnnotation,
                    description: e.target.value,
                  })
                }
                placeholder="Descripción (opcional)"
                className="form-control"
                rows={3}
              />
            </div>

            <div className="form-group">
              <label>Color</label>
              <ColorPicker
                color={newAnnotation.color}
                onChange={(color) =>
                  setNewAnnotation({ ...newAnnotation, color })
                }
              />
            </div>

            <div className="form-group">
              <label>Icono</label>
              <select
                value={newAnnotation.icon}
                onChange={(e) =>
                  setNewAnnotation({ ...newAnnotation, icon: e.target.value })
                }
                className="form-control"
              >
                <option value="pin">Pin</option>
                <option value="circle">Círculo</option>
                <option value="square">Cuadrado</option>
              </select>
            </div>

            {/* Sección de coordenadas */}
            <div className="form-group">
              <label>Ubicación</label>
              <div className="coordinates-container">
                <div className="coordinate-inputs">
                  <div className="coordinate-input">
                    <label>Latitud</label>
                    <input
                      type="number"
                      step="any"
                      value={newAnnotation.coordinates.lat}
                      onChange={(e) =>
                        handleCoordinateChange("lat", e.target.value)
                      }
                      placeholder="Latitud"
                      className="form-control"
                    />
                  </div>
                  <div className="coordinate-input">
                    <label>Longitud</label>
                    <input
                      type="number"
                      step="any"
                      value={newAnnotation.coordinates.lng}
                      onChange={(e) =>
                        handleCoordinateChange("lng", e.target.value)
                      }
                      placeholder="Longitud"
                      className="form-control"
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary map-select-btn"
                  onClick={handleSelectOnMap}
                  disabled={isSelectingPosition}
                >
                  {isSelectingPosition
                    ? "Seleccionando..."
                    : "Seleccionar en Mapa"}
                </button>
              </div>
            </div>

            <div className="form-buttons">
              <button
                type="submit"
                className="btn btn-success"
                disabled={
                  !newAnnotation.title.trim() ||
                  !newAnnotation.coordinates.lat ||
                  !newAnnotation.coordinates.lng
                }
              >
                Crear
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  setIsCreatingAnnotation(false);
                  setIsSelectingPosition(false);
                  setSelectionMode(null);
                }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="annotation-manager__list">
        {annotations.length === 0 ? (
          <p className="no-data-message">No hay anotaciones disponibles</p>
        ) : (
          annotations.map((annotation) => (
            <div
              key={annotation.id}
              className={`annotation-item ${
                annotation.id === activeAnnotationId ? "active" : ""
              }`}
              onClick={() => handleAnnotationClick(annotation.id)}
            >
              <div className="annotation-item__header">
                <div
                  className="annotation-item__color"
                  style={{ backgroundColor: annotation.color }}
                ></div>
                <h4 className="annotation-item__title">{annotation.title}</h4>
                <div className="annotation-item__actions">
                  <button
                    className="focus-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      onFocusAnnotation(annotation.id);
                    }}
                    title="Enfocar anotación"
                  >
                    <i className="fas fa-search-location"></i>
                  </button>
                </div>
              </div>

              {expandedAnnotationId === annotation.id && (
                <div className="annotation-item__details">
                  {annotation.description && (
                    <p className="annotation-item__description">
                      {annotation.description}
                    </p>
                  )}

                  <div className="annotation-item__coords">
                    <span>Ubicación: </span>
                    <span>
                      {annotation.position.lat.toFixed(6)},{" "}
                      {annotation.position.lng.toFixed(6)}
                    </span>
                  </div>

                  <div className="annotation-item__edit">
                    <div className="form-group">
                      <label>Color</label>
                      <ColorPicker
                        color={annotation.color}
                        onChange={(color) =>
                          handleColorChange(annotation.id, color)
                        }
                      />
                    </div>

                    <button
                      className="btn btn-danger btn-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          window.confirm(
                            "¿Estás seguro de eliminar esta anotación?"
                          )
                        ) {
                          onDeleteAnnotation(annotation.id);
                        }
                      }}
                    >
                      Eliminar Anotación
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AnnotationManager;
