import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';
import LocationSelector from '../../components/LocationSelector';

const LocationForm = ({ initialData, onSubmit, onCancel, theme = 'light' }) => {
  // Transformar initialData al formato que espera LocationSelector
  const transformInitialDataForSelector = (data) => {
    if (!data) return null;
    return {
      country: { label: data.country, value: data.country },
      department: { label: data.department, value: data.department },
      city: { label: data.city, value: data.city },
      addressDetails: data.address_details,
      postalCode: data.postal_code
    };
  };
  
  const [locationData, setLocationData] = useState(transformInitialDataForSelector(initialData));
  const [formData, setFormData] = useState({
    location_type: initialData?.location_type || 'home',
    is_primary: initialData?.is_primary || false,
    country: initialData?.country || '',
    department: initialData?.department || '',
    city: initialData?.city || '',
    address_details: initialData?.address_details || '',
    postal_code: initialData?.postal_code || ''
  });
  const [errors, setErrors] = useState({});
  const initialFormDataRef = useRef({...formData});
  
  const handleLocationSelect = (newLocationData) => {
    setLocationData(newLocationData);
    setFormData(prev => ({
      ...prev,
      country: newLocationData.country.label,
      department: newLocationData.department.label,
      city: newLocationData.city.label,
      address_details: newLocationData.addressDetails,
      postal_code: newLocationData.postalCode
    }));
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.country) newErrors.country = 'El país es requerido';
    if (!formData.department) newErrors.department = 'El departamento es requerido';
    if (!formData.city) newErrors.city = 'La ciudad es requerida';
    if (!formData.address_details) newErrors.address_details = 'La dirección es requerida';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };
  
  const hasChanges = () => {
    return JSON.stringify(formData) !== JSON.stringify(initialFormDataRef.current);
  };

  const handleCancel = () => {
    setFormData(initialFormDataRef.current);
    setLocationData(transformInitialDataForSelector(initialData));
    onCancel();
  };

  return (
    <form className={`GM__${theme}-location-form`}>
      <LocationSelector
        onLocationSelect={handleLocationSelect}
        locationData={locationData}
        theme={theme}
      />

      <div className={`GM__${theme}-form-additional-fields`}>
        <FormControlLabel
          control={
            <Switch
              checked={formData.is_primary}
              onChange={(e) => setFormData(prev => ({ ...prev, is_primary: e.target.checked }))}
              color="primary"
            />
          }
          label="Establecer como dirección principal"
          className={`GM__${theme}-form-switch`}
        />
      </div>

      <div className={`GM__${theme}-form-actions`}>
        <button 
          type="button" 
          className={`GM__${theme}-cancel-btn cancel`} 
          onClick={handleCancel}>
          Cancelar
        </button>
        <button 
          type="button"
          disabled={!hasChanges()} 
          onClick={handleSubmit} 
          className={`GM__${theme}-submit-btn submit`}
        >
          {initialData ? 'Actualizar' : 'Crear'} Ubicación
        </button>
      </div>
    </form>
  );
};

LocationForm.propTypes = {
  initialData: PropTypes.shape({
    country: PropTypes.string,
    department: PropTypes.string,
    city: PropTypes.string,
    address_details: PropTypes.string,
    postal_code: PropTypes.string,
    location_type: PropTypes.string,
    is_primary: PropTypes.bool
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark'])
};

export default LocationForm;