import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDocument } from '../../context/DocumentContext';
import ErrorMessage from '../../components/common/ErrorMessage';
import BasicDataSection from './sections/BasicDataSection';
import ExecutiveSummarySection from './sections/ExecutiveSummarySection';
import RouteCharacteristicsSection from './sections/RouteCharacteristicsSection';
import GeneralParametersSection from './sections/GeneralParametersSection';
import FixedCostsSection from './sections/FixedCostsSection';
import VariableCostsSection from './sections/VariableCostsSection';
import OtherCostsSection from './sections/OtherCostsSection';
import LoadingAnimation from '../../components/loading/LoadingAnimation';
// Importación directa del archivo SCSS
import '../../assets/scss/pages/DocumentViewer.scss';
import { useLayout } from '../../context/LayoutContext';

const DocumentViewer = () => {
  const { theme } = useLayout();
  const { documentId } = useParams();
  const { document, loading, error, generatePdf } = useDocument();
  useEffect(() => {
    // Actualizar título de la página con el ID del documento
    document.title = `Documento de Costos ${documentId}`;
    
    return () => {
      document.title = 'Sistema de Cálculo de Costos de Transporte';
    };
  }, [documentId]);

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!document) {
    return <ErrorMessage message="El documento solicitado no existe o ha expirado" />;
  }

  // Función para manejar la descarga de PDF
  const handleDownloadPdf = async () => {
    try {
      const pdfUrl = await generatePdf();
      if (pdfUrl) {
        window.open(pdfUrl, '_blank');
      }
    } catch (err) {
      console.error('Error downloading PDF:', err);
    }
  };

  return (
    <div className="document-viewer">
      <div className="document-header">
        <h1>Documento de Costos de Transporte</h1>
        <p className="document-id">ID: {documentId}</p>
        <p className="last-updated">
          Última actualización: {new Date(document.lastUpdated).toLocaleString()}
        </p>
        <button 
          className="download-button"
          onClick={handleDownloadPdf}
        >
          Descargar PDF
        </button>
      </div>
      
      <div className="document-content">
        <BasicDataSection 
          data={document.basicData}
          onUpdate={() => {}} // No-op
          onConsult={() => {}} // No-op
          onClear={() => {}} // No-op
          onGeneratePdf={handleDownloadPdf}
          onShare={() => {}} // No-op
          isReadOnly={true}
          hasResults={true}
          theme={theme}
        />
        
        <ExecutiveSummarySection data={document.executiveSummary} />
        
        <div className="results-grid">
          <RouteCharacteristicsSection data={document.routeCharacteristics} />
          <GeneralParametersSection data={document.generalParameters} />
        </div>
        
        <div className="costs-grid">
          <FixedCostsSection data={document.fixedCosts} />
          <VariableCostsSection data={document.variableCosts} />
        </div>
        
        <OtherCostsSection 
          data={document.otherCosts}
          finalCostVariable={document.finalCostVariable}
          finalCostFixed={document.finalCostFixed}
          totalFleetValue={document.totalFleetValue}
        />
      </div>
    </div>
  );
};

export default DocumentViewer;