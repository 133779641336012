import React, { useState, useEffect } from "react";
import RouteManager from "./RouteManager";
import AnnotationManager from "./AnnotationManager";
import ExportManager from "./ExportManager";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "../../assets/scss/components/LeftNavMaps.scss";
import SearchMini from "../ui/search/SearchMini.jsx";
import { calculateRoute } from "../../utils/routeUtils.js";
const LeftNavMaps = ({
  routes,
  activeRouteId,
  addRoute,
  updateRoute,
  deleteRoute,
  setActiveRoute,
  
  startPoint,
  setStartPoint,
  endPoint,
  setEndPoint,
  selectionMode,
  setSelectionMode,
  
  annotations,
  activeAnnotationId,
  addAnnotation,
  updateAnnotation,
  deleteAnnotation,
  setActiveAnnotation,
  
  exportToKmz,
  exportToGeoJson,
  
  mapRef
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState(routes);
  const [filteredAnnotations, setFilteredAnnotations] = useState(annotations);

  // Filtrar rutas y anotaciones cuando cambia el término de búsqueda
  useEffect(() => {
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      
      setFilteredRoutes(
        routes.filter(route => route.name?.toLowerCase().includes(term))
      );
      
      setFilteredAnnotations(
        annotations.filter(annotation => 
          annotation.title?.toLowerCase().includes(term) || 
          annotation.description?.toLowerCase().includes(term)
        )
      );
    } else {
      setFilteredRoutes(routes);
      setFilteredAnnotations(annotations);
    }
  }, [searchTerm, routes, annotations]);

  // Función para crear una nueva ruta con puntos de inicio y fin específicos
  const handleCreateRoute = (routeData) => {
    // Verificar que haya puntos de inicio y fin
    if (!routeData.startPoint || !routeData.endPoint) {
      alert("Se requieren puntos de inicio y fin para crear una ruta");
      return;
    }
    
    console.log("Creando ruta con puntos:", routeData.startPoint, routeData.endPoint);
    
    // Incluir los waypoints en la creación de la ruta
    const waypoints = [routeData.startPoint, routeData.endPoint];
    const routeId = addRoute({
      name: routeData.name,
      description: routeData.description,
      color: routeData.color || "#3388ff",
      waypoints: waypoints,
      visible: true,
    });
    
    // Calcular inmediatamente la ruta entre estos puntos
    // IMPORTANTE: Sin usar importación dinámica
    calculateRoute(waypoints)
      .then((routeData) => {
        console.log("Ruta calculada exitosamente:", routeData);
        updateRoute(routeId, { routeData });
      })
      .catch((error) => {
        console.error("Error al calcular la ruta inicial:", error);
        alert("No se pudo calcular la ruta. Error: " + error.message);
      });
    
    // Limpiar los puntos después de crear la ruta
    setStartPoint(null);
    setEndPoint(null);
  };

  // Función para centrar el mapa en una ruta
  const handleFocusOnRoute = (routeId) => {
    const route = routes.find(r => r.id === routeId);
    if (route && route.waypoints.length > 0 && mapRef) {
      const bounds = new window.google.maps.LatLngBounds();
      route.waypoints.forEach(waypoint => {
        bounds.extend(new window.google.maps.LatLng(waypoint.lat, waypoint.lng));
      });
      mapRef.fitBounds(bounds);
    }
  };

  // Función para centrar el mapa en una anotación
  const handleFocusOnAnnotation = (annotationId) => {
    const annotation = annotations.find(a => a.id === annotationId);
    if (annotation && mapRef) {
      mapRef.panTo(annotation.position);
      mapRef.setZoom(15);
    }
  };

  return (
    <div className="left-panel">
      <div className="left-panel__header">
        <h2>Gestión de Rutas</h2>
        <SearchMini 
          value={searchTerm} 
          onChange={setSearchTerm} 
          placeholder="Buscar rutas o anotaciones..." 
        />
      </div>

      <Tabs className="left-panel__tabs">
        <TabList>
          <Tab>Rutas</Tab>
          <Tab>Anotaciones</Tab>
          <Tab>Exportar</Tab>
        </TabList>

        <TabPanel>
          <RouteManager
            routes={filteredRoutes}
            activeRouteId={activeRouteId}
            onCreateRoute={handleCreateRoute}
            onUpdateRoute={updateRoute}
            onDeleteRoute={deleteRoute}
            onSelectRoute={setActiveRoute}
            onFocusRoute={handleFocusOnRoute}
            startPoint={startPoint}
            setStartPoint={setStartPoint}
            endPoint={endPoint}
            setEndPoint={setEndPoint}
            selectionMode={selectionMode}
            setSelectionMode={setSelectionMode}
          />
        </TabPanel>

        <TabPanel>
          <AnnotationManager
            annotations={filteredAnnotations}
            activeAnnotationId={activeAnnotationId}
            onCreateAnnotation={addAnnotation}
            onUpdateAnnotation={updateAnnotation}
            onDeleteAnnotation={deleteAnnotation}
            onSelectAnnotation={setActiveAnnotation}
            onFocusAnnotation={handleFocusOnAnnotation}
            setSelectionMode={setSelectionMode}
          />
        </TabPanel>

        <TabPanel>
          <ExportManager
            routes={routes}
            annotations={annotations}
            onExportToKmz={exportToKmz}
            onExportToGeoJson={exportToGeoJson}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default LeftNavMaps;