export const ROUTE_ACCESS = {
  '/dashboard': ['Administrador', 'vehicle', 'thirdParty', "Recursos Humanos"],
  '/transport-calculator': ['Administrador', 'vehicle', 'thirdParty', "Recursos Humanos"],
  '/transport-calculator/:id': ['Administrador', 'vehicle', 'thirdParty', "Recursos Humanos"],
  '/agregar-info': ['Administrador', 'vehicle'],
  '/reportes': ['Administrador'],
  '/perfil': ['Administrador', 'vehicle', 'thirdParty', "Recursos Humanos"],
  '/satellite-uploader': ['Administrador'],
  '/register': ['Administrador'],
  '/trends': ['Administrador'],
  '/hotspots': ['Administrador'],
  '/repeat-offenders': ['Administrador'],
  '/alerts-persons': ['Administrador', 'Recursos Humanos'],
  '/geofences-travel-and-more': ['Administrador', 'Recursos Humanos'],
  '/wssp-connection': ['Administrador'],
  '/persons': ['Administrador','Recursos Humanos'],
  '/persons/administrators': ['Administrador','Recursos Humanos'],
  '/persons/administrators/:cc': ['Administrador','Recursos Humanos'],
  '/persons/setting/data': ['Administrador','Recursos Humanos'],
  '/persons/operators': ['Administrador'],
  '/persons/operators/:cc': ['Administrador'],
  '/persons/ADO': ['Administrador','Recursos Humanos'],
  '/persons/ADO/:id': ['Administrador','Recursos Humanos'],
  '/persons/owners': ['Administrador','Recursos Humanos'],
  '/persons/owner"': ['Administrador','Recursos Humanos'],
  '/persons/third-parties': ['Administrador'],
  '/persons/third-parties/:cc': ['Administrador','Recursos Humanos'],
  '/persons/RRHH': ['Administrador','Recursos Humanos'],
  '/persons/RRHH/:cc': ['Administrador','Recursos Humanos'],
  '/persons/add/user/:id': ['Administrador','Recursos Humanos'],
  '/persons/profile/:id': ['Administrador','Recursos Humanos'],
  '/vehicles': ['Administrador', 'ADO'],
  '/alerts-vehicles': ['Administrador','ADO', 'vehicle'],
  '/vehicles/:id': ['Administrador', 'ADO'],
  '/vehicles/update/:id': ['Administrador', 'ADO'],
  '/vehicles/setting/data': ['Administrador', 'ADO'],
  '/vehicles/owner/:id': ['Administrador', 'ADO'],
  '/vehicles/add/vehicle': ['Administrador', 'ADO'],
  '/vehicles/add/user/:id': ['Administrador', 'ADO'],
  '/carriers': ['Administrador'],
  '/message': ['Administrador', 'vehicle', 'thirdParty'],
};

  export const PERSON_ROUTES = {
    main: {
      path: "/persons",
      title: "Personas",
      subtitle: "Personas/"
    },
    administrators: {
      path: "/persons/administrators",
      title: "Administradores",
    },
    administrator: {
      path: "/persons/administrators/:cc",
      title: "Administrador",
      subtitle: "Personas/Administradores/{cc}"
    },
    operators: {
      path: "/persons/operators",
      title: "Operadores",
      subtitle: "Personas/Operadores"
    },
    operator: {
      path: "/persons/operators/:cc",
      title: "Operador",
      subtitle: "Personas/Operadores/{cc}"
    },
    ADOS: {
      path: "/persons/ADO",
      title: "Auxiliares de operaciones",
      subtitle: "Personas/Auxiliar de Operaciones"
    },
    ADO: {
      path: "/persons/ADO/:id",
      title: "Auxiliar de operaciones",
      subtitle: "Personas/Auxiliar de Operaciones/{id}"
    },
    owners: {
      path: "/persons/owners",
      title: "Propietarios",
      subtitle: "Personas/Propietarios"
    },
    owner: {
      path: "/persons/owners/:id",
      title: "Propietario",
      subtitle: "Personas/Propietarios/{id}"
    },
    thirdParties: {
      path: "/persons/third-parties",
      title: "Terceros",
      subtitle: "Personas/Terceros"
    },
    thirdParty: {
      path: "/persons/third-parties/:cc",
      title: "Tercero",
      subtitle: "Personas/Terceros/{cc}"
    },
    rrhh: {
      path: "/persons/RRHH",
      title: "Recursos Humanos",
      subtitle: "Personas/Recursos Humanos"
    },
    rrhhPerson: {
      path: "/persons/RRHH/:cc",
      title: "Recurso Humano",
      subtitle: "Personas/Recursos Humanos/{cc}"
    },
    addUser: {
      path: "/persons/add/user/:id",
      title: "Agregar Persona",
      subtitle: "Personas/{id}"
    },
    profile: {
      path: "/persons/profile/:id",
      title: "Perfil Persona",
      subtitle: "Personas/{id}"
    },
    setting: {
      path: "/persons/setting/data",
      title: "Configuracion de data",
      subtitle: "Personas/Configuración",
    },
  };
  export const VEHICLES_ROUTES = {
    main: {
      path: "/vehicles",
      title: "Vehiculos",
      subtitle: "Vehiculos"
    },
    vechicle: {
      path: "/vehicles/:id",
      title: "vehiculo",
      subtitle: "Vehiculos/{id}",
    },
    updated: {
      path: "/vehicles/update/:id",
      title: "Actulizar vehiculo",
      subtitle: "Vehiculos/{id}",
    },
    setting: {
      path: "/vehicles/setting/data",
      title: "Informacion requeridad para la creacion de un vehiculo",
      subtitle: "Vehiculos/Configuración",
    },
    add: {
      path: "/vehicles/add/vehicle",
      title: "Agregar vehiculo",
      subtitle: "Vehiculos/Agregar vehiculos",
    },
    addOwner: {
      path: "/vehicles/add/user/:id",
      title: "Agregar Propietario vehicular",
      subtitle: "Vehiculos/{id}",
    },
    owner: {
      path: "/vehicles/owner/:id",
      title: "Propietario vehicular",
      subtitle: "Vehiculos/Configuración/{id}",
    },
  };
  
  export const ROUTES = {
    Administrador: {
      dashboard: '/dashboard',
      agregarInfo: '/agregar-info',
      reportes: '/reportes',
      perfil: '/perfil',
      persons: '/persons',
      satellite: '/satellite-uploader',
      register: '/register',
      trends: '/trends',
      hotspots: '/hotspots',
      repeatOffenders: '/repeat-offenders',
      users: '/users',
      vehicles: '/vehicles',
      carriers: '/carriers',
      message: '/message',
    },
    vehicle: {
      dashboard: '/dashboard',
      agregarInfo: '/agregar-info',
      perfil: '/perfil'
    },
    thirdParty: {
      dashboard: '/dashboard',
      perfil: '/perfil'
    }
  };