import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  useOperationService,
  useDocumentTypeService,
  useVehicleDocumentService,
  useDocumentationAnalyticsService,
} from "../service/documentationVehicleService";

// Claves de query para identificar y gestionar el caché
export const OPERATIONS_KEY = "operations";
export const DOCUMENT_TYPES_KEY = "documentTypes";
export const VEHICLE_DOCUMENT_REQUIREMENTS_KEY = "vehicleDocumentRequirements";
export const OPERATION_DOCUMENT_REQUIREMENTS_KEY =
  "operationDocumentRequirements";
export const DOCUMENTATION_ANALYTICS_KEY = "documentationAnalytics";

/**
 * Hook para gestionar operaciones logísticas
 * @param {Object} options Opciones adicionales para las consultas
 * @returns {Object} Métodos y datos para gestionar operaciones
 */
export const useOperations = (options = {}) => {
  const operationService = useOperationService();
  const queryClient = useQueryClient();

  // Parámetros por defecto para la consulta
  const defaultQueryParams = {
    limit: options.limit || 5,
    offset: options.offset || 0,
    search: options.search || "",
    orderBy: options.orderBy || "created_at",
    orderDirection: options.orderDirection || "DESC",
  };

  // Consulta principal para obtener operaciones
  const query = useQuery({
    queryKey: [OPERATIONS_KEY, defaultQueryParams],
    queryFn: () => operationService.getAllOperations(defaultQueryParams),
    staleTime: 3 * 60 * 1000, // 3 minutos
    cacheTime: 5 * 60 * 1000, // 5 minutos
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000, // Refresca cada 5 minutos
    retry: 2,
    ...options.queryOptions,
  });

  // Memorización de datos para evitar re-renders innecesarios
  const memoizedOperations = useMemo(() => {
    return query.data?.data || [];
  }, [query.data]);

  // Memorización de metadatos de paginación
  const memoizedMetadata = useMemo(() => {
    return (
      query.data?.metadata || {
        total: 0,
        limit: defaultQueryParams.limit,
        offset: defaultQueryParams.offset,
        pages: 0,
      }
    );
  }, [query.data, defaultQueryParams.limit, defaultQueryParams.offset]);

  // Mutación para crear operación
  const createMutation = useMutation({
    mutationFn: (data) => operationService.createOperation(data),
    onSuccess: () => {
      queryClient.invalidateQueries([OPERATIONS_KEY]);
    },
  });

  // Mutación para actualizar operación
  const updateMutation = useMutation({
    mutationFn: ({ id, data }) => operationService.updateOperation(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries([OPERATIONS_KEY]);
    },
  });

  // Mutación para eliminar operación
  const deleteMutation = useMutation({
    mutationFn: (id) => operationService.deleteOperation(id),
    onSuccess: () => {
      queryClient.invalidateQueries([OPERATIONS_KEY]);
    },
  });

  // Mutación para asignar documentos a operación
  const assignDocumentsMutation = useMutation({
    mutationFn: ({ operationId, documentIds }) =>
      operationService.assignDocumentsToOperation(operationId, documentIds),
    onSuccess: (_, variables) => {
      // Invalidar consultas específicas relacionadas con esta operación
      queryClient.invalidateQueries([OPERATIONS_KEY]);
      queryClient.invalidateQueries([
        OPERATION_DOCUMENT_REQUIREMENTS_KEY,
        variables.operationId,
      ]);
    },
  });

  return {
    ...query,
    operations: memoizedOperations,
    metadata: memoizedMetadata,
    createOperation: createMutation.mutateAsync,
    updateOperation: updateMutation.mutateAsync,
    deleteOperation: deleteMutation.mutateAsync,
    assignDocumentsToOperation: assignDocumentsMutation.mutateAsync,
    isLoading:
      query.isLoading ||
      createMutation.isLoading ||
      updateMutation.isLoading ||
      deleteMutation.isLoading ||
      assignDocumentsMutation.isLoading,
    isError:
      query.isError ||
      createMutation.isError ||
      updateMutation.isError ||
      deleteMutation.isError ||
      assignDocumentsMutation.isError,
  };
};

/**
 * Hook para obtener detalles de una operación específica por su ID
 * @param {String} id Identificador UUID de la operación
 * @param {Object} options Opciones adicionales para la consulta
 * @returns {Object} Datos y estado de la operación
 */
export const useOperationDetails = (id, options = {}) => {
  const operationService = useOperationService();
  const queryClient = useQueryClient();

  // Consulta para obtener detalles de una operación
  const query = useQuery({
    queryKey: [OPERATIONS_KEY, id],
    queryFn: () => operationService.getOperationById(id),
    staleTime: 3 * 60 * 1000,
    enabled: !!id, // Solo ejecutar si hay un ID
    ...options.queryOptions,
  });

  // Consulta para obtener documentos requeridos por la operación
  const documentsQuery = useQuery({
    queryKey: [OPERATION_DOCUMENT_REQUIREMENTS_KEY, id],
    queryFn: () => operationService.getDocumentsByOperation(id),
    staleTime: 3 * 60 * 1000,
    enabled: !!id, // Solo ejecutar si hay un ID
    ...options.documentsQueryOptions,
  });

  // Memorización de datos
  const operation = useMemo(() => query.data?.data || null, [query.data]);
  const documents = useMemo(
    () => documentsQuery.data?.data?.documents || [],
    [documentsQuery.data]
  );

  // Mutación para asignar un documento individual
  const assignDocumentMutation = useMutation({
    mutationFn: (documentId) =>
      operationService.assignDocumentToOperation(id, documentId),
    onSuccess: () => {
      queryClient.invalidateQueries([OPERATION_DOCUMENT_REQUIREMENTS_KEY, id]);
    },
  });

  // Mutación para remover un documento
  const removeDocumentMutation = useMutation({
    mutationFn: (documentId) =>
      operationService.removeDocumentFromOperation(id, documentId),
    onSuccess: () => {
      queryClient.invalidateQueries([OPERATION_DOCUMENT_REQUIREMENTS_KEY, id]);
    },
  });

  return {
    isLoading:
      query.isLoading ||
      documentsQuery.isLoading ||
      assignDocumentMutation.isLoading ||
      removeDocumentMutation.isLoading,
    isError:
      query.isError ||
      documentsQuery.isError ||
      assignDocumentMutation.isError ||
      removeDocumentMutation.isError,
    operation,
    documents,
    assignDocument: assignDocumentMutation.mutateAsync,
    removeDocument: removeDocumentMutation.mutateAsync,
    operationError: query.error,
    documentsError: documentsQuery.error,
    refetch: query.refetch,
    refetchDocuments: documentsQuery.refetch,
  };
};

/**
 * Hook para gestionar tipos de documentos
 * @param {Object} options Opciones adicionales para las consultas
 * @returns {Object} Métodos y datos para gestionar tipos de documentos
 */
export const useDocumentTypes = (options = {}) => {
  const documentTypeService = useDocumentTypeService();
  const queryClient = useQueryClient();

  // Parámetros por defecto para la consulta
  const defaultQueryParams = {
    limit: options.limit || 20,
    offset: options.offset || 0,
    search: options.search || "",
    orderBy: options.orderBy || "name",
    orderDirection: options.orderDirection || "ASC",
  };

  // Consulta principal para obtener tipos de documentos
  const query = useQuery({
    queryKey: [DOCUMENT_TYPES_KEY, defaultQueryParams],
    queryFn: () => documentTypeService.getAllDocumentTypes(defaultQueryParams),
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
    ...options.queryOptions,
  });

  // Memorización de datos para evitar re-renders innecesarios
  const memoizedDocumentTypes = useMemo(() => {
    return query.data?.data || [];
  }, [query.data]);

  // Memorización de metadatos de paginación
  const memoizedMetadata = useMemo(() => {
    return (
      query.data?.metadata || {
        total: 0,
        limit: defaultQueryParams.limit,
        offset: defaultQueryParams.offset,
        pages: 0,
      }
    );
  }, [query.data, defaultQueryParams.limit, defaultQueryParams.offset]);

  // Mutación para crear tipo de documento
  const createMutation = useMutation({
    mutationFn: (data) => documentTypeService.createDocumentType(data),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_KEY]);
    },
  });

  // Mutación para actualizar tipo de documento
  const updateMutation = useMutation({
    mutationFn: ({ id, data }) =>
      documentTypeService.updateDocumentType(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_KEY]);
    },
  });

  // Mutación para eliminar tipo de documento
  const deleteMutation = useMutation({
    mutationFn: (id) => documentTypeService.deleteDocumentType(id),
    onSuccess: () => {
      // Invalidar múltiples consultas que podrían verse afectadas por esta operación
      queryClient.invalidateQueries([DOCUMENT_TYPES_KEY]);
      // También invalidar consultas que podrían mostrar requisitos de documentos
      queryClient.invalidateQueries([OPERATION_DOCUMENT_REQUIREMENTS_KEY]);
      queryClient.invalidateQueries([VEHICLE_DOCUMENT_REQUIREMENTS_KEY]);
    },
  });

  return {
    ...query,
    documentTypes: memoizedDocumentTypes,
    metadata: memoizedMetadata,
    createDocumentType: createMutation.mutateAsync,
    updateDocumentType: updateMutation.mutateAsync,
    deleteDocumentType: deleteMutation.mutateAsync,
    isLoading:
      query.isLoading ||
      createMutation.isLoading ||
      updateMutation.isLoading ||
      deleteMutation.isLoading,
    isError:
      query.isError ||
      createMutation.isError ||
      updateMutation.isError ||
      deleteMutation.isError,
  };
};

/**
 * Hook para obtener detalles de un tipo de documento específico por su ID
 * @param {String} id Identificador UUID del tipo de documento
 * @param {Object} options Opciones adicionales para las consultas
 * @returns {Object} Datos y estado del tipo de documento
 */
export const useDocumentTypeDetails = (id, options = {}) => {
  const documentTypeService = useDocumentTypeService();

  // Consulta para obtener detalles del tipo de documento
  const query = useQuery({
    queryKey: [DOCUMENT_TYPES_KEY, id],
    queryFn: () => documentTypeService.getDocumentTypeById(id),
    staleTime: 3 * 60 * 1000,
    enabled: !!id, // Solo ejecutar si hay un ID
    ...options.queryOptions,
  });

  // Consulta para obtener operaciones que requieren este documento
  const operationsQuery = useQuery({
    queryKey: [DOCUMENT_TYPES_KEY, id, "operations"],
    queryFn: () => documentTypeService.getOperationsByDocument(id),
    staleTime: 3 * 60 * 1000,
    enabled: !!id && options.includeOperations !== false, // Solo ejecutar si hay un ID y no se ha desactivado
    ...options.operationsQueryOptions,
  });

  // Consulta para obtener tipos de vehículos que requieren este documento
  const vehicleTypesQuery = useQuery({
    queryKey: [DOCUMENT_TYPES_KEY, id, "vehicleTypes"],
    queryFn: () => documentTypeService.getVehicleTypesByDocument(id),
    staleTime: 3 * 60 * 1000,
    enabled: !!id && options.includeVehicleTypes !== false, // Solo ejecutar si hay un ID y no se ha desactivado
    ...options.vehicleTypesQueryOptions,
  });

  // Memorización de datos
  const documentType = useMemo(() => query.data?.data || null, [query.data]);
  const operations = useMemo(
    () => operationsQuery.data?.data || [],
    [operationsQuery.data]
  );
  const vehicleTypes = useMemo(
    () => vehicleTypesQuery.data?.data || [],
    [vehicleTypesQuery.data]
  );

  return {
    isLoading:
      query.isLoading ||
      (options.includeOperations !== false && operationsQuery.isLoading) ||
      (options.includeVehicleTypes !== false && vehicleTypesQuery.isLoading),
    isError:
      query.isError ||
      (options.includeOperations !== false && operationsQuery.isError) ||
      (options.includeVehicleTypes !== false && vehicleTypesQuery.isError),
    documentType,
    operations,
    vehicleTypes,
    refetch: query.refetch,
    refetchOperations: operationsQuery.refetch,
    refetchVehicleTypes: vehicleTypesQuery.refetch,
  };
};

/**
 * Hook para gestionar relaciones entre tipos de vehículos y documentos
 * @param {String} vehicleTypeId Identificador UUID del tipo de vehículo
 * @param {Object} options Opciones adicionales para las consultas
 * @returns {Object} Métodos y datos para gestionar relaciones vehículo-documento
 */
export const useVehicleDocumentRequirements = (vehicleTypeId, options = {}) => {
  const vehicleDocumentService = useVehicleDocumentService();
  const queryClient = useQueryClient();

  // Consulta para obtener documentos requeridos por un tipo de vehículo
  const query = useQuery({
    queryKey: [VEHICLE_DOCUMENT_REQUIREMENTS_KEY, vehicleTypeId],
    queryFn: () =>
      vehicleDocumentService.getDocumentsByVehicleType(vehicleTypeId),
    staleTime: 3 * 60 * 1000,
    enabled: !!vehicleTypeId, // Solo ejecutar si hay un ID
    ...options.queryOptions,
  });

  // Memorización de datos
  const documents = useMemo(() => {
    return query.data?.data?.documents || [];
  }, [query.data]);

  // Mutación para asignar un documento individual
  const assignDocumentMutation = useMutation({
    mutationFn: (documentId) =>
      vehicleDocumentService.assignDocumentToVehicleType(
        vehicleTypeId,
        documentId
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([
        VEHICLE_DOCUMENT_REQUIREMENTS_KEY,
        vehicleTypeId,
      ]);
    },
  });

  // Mutación para remover un documento
  const removeDocumentMutation = useMutation({
    mutationFn: (documentId) =>
      vehicleDocumentService.removeDocumentFromVehicleType(
        vehicleTypeId,
        documentId
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([
        VEHICLE_DOCUMENT_REQUIREMENTS_KEY,
        vehicleTypeId,
      ]);
    },
  });

  // Mutación para asignar múltiples documentos
  const assignDocumentsMutation = useMutation({
    mutationFn: (documentIds) =>
      vehicleDocumentService.assignDocumentsToVehicleType(
        vehicleTypeId,
        documentIds
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([
        VEHICLE_DOCUMENT_REQUIREMENTS_KEY,
        vehicleTypeId,
      ]);
      // También invalidar consultas que muestran relaciones inversas
      queryClient.invalidateQueries([DOCUMENT_TYPES_KEY, "vehicleTypes"]);
      // Invalidar análisis que dependen de estas relaciones
      queryClient.invalidateQueries([DOCUMENTATION_ANALYTICS_KEY]);
    },
  });

  return {
    ...query,
    documents,
    assignDocument: assignDocumentMutation.mutateAsync,
    removeDocument: removeDocumentMutation.mutateAsync,
    assignDocuments: assignDocumentsMutation.mutateAsync,
    isLoading:
      query.isLoading ||
      assignDocumentMutation.isLoading ||
      removeDocumentMutation.isLoading ||
      assignDocumentsMutation.isLoading,
    isError:
      query.isError ||
      assignDocumentMutation.isError ||
      removeDocumentMutation.isError ||
      assignDocumentsMutation.isError,
  };
};

/**
 * Hook para consultas analíticas y comparativas sobre requisitos documentales
 * @returns {Object} Métodos para realizar análisis sobre la documentación vehicular
 */
export const useDocumentationAnalytics = () => {
  const analyticsService = useDocumentationAnalyticsService();
  const queryClient = useQueryClient();

  /**
   * Obtiene un resumen de requisitos documentales por operación
   * @param {String} operationId Identificador UUID de la operación
   * @param {Object} options Opciones adicionales para la consulta
   * @returns {Object} Datos y estado de la consulta
   */
  const useOperationRequirements = (operationId, options = {}) => {
    return useQuery({
      queryKey: [
        DOCUMENTATION_ANALYTICS_KEY,
        "operationRequirements",
        operationId,
      ],
      queryFn: () =>
        analyticsService.getDocumentRequirementsByOperation(operationId),
      staleTime: 5 * 60 * 1000, // 5 minutos
      enabled: !!operationId,
      ...options,
    });
  };


  /**
   * Obtiene un resumen global de requisitos documentales
   * @param {Object} options Opciones adicionales para la consulta
   * @returns {Object} Datos y estado de la consulta
   */
  const useRequirementsSummary = (options = {}) => {
    return useQuery({
      queryKey: [DOCUMENTATION_ANALYTICS_KEY, "requirementsSummary"],
      queryFn: () => analyticsService.getDocumentRequirementsSummary(),
      staleTime: 5 * 60 * 1000,
      ...options,
    });
  };

  /**
   * Verifica documentos faltantes para un vehículo en una operación
   * @param {String} vehicleId Identificador UUID del vehículo
   * @param {String} operationId Identificador UUID de la operación
   * @param {Object} options Opciones adicionales para la consulta
   * @returns {Object} Datos y estado de la consulta
   */
  const useMissingDocuments = (vehicleId, operationId, options = {}) => {
    return useQuery({
      queryKey: [
        DOCUMENTATION_ANALYTICS_KEY,
        "missingDocuments",
        vehicleId,
        operationId,
      ],
      queryFn: () =>
        analyticsService.getMissingDocumentsForVehicle(vehicleId, operationId),
      staleTime: 2 * 60 * 1000, // 2 minutos (más frecuente para asegurar datos actualizados)
      enabled: !!vehicleId && !!operationId,
      ...options,
    });
  };

  /**
   * Compara requisitos documentales entre múltiples operaciones
   * @param {Array<String>} operationIds Array de identificadores UUID de operaciones
   * @param {Object} options Opciones adicionales para la consulta
   * @returns {Object} Datos y estado de la consulta
   */
  const useOperationsComparison = (operationIds = [], options = {}) => {
    return useQuery({
      queryKey: [
        DOCUMENTATION_ANALYTICS_KEY,
        "operationsComparison",
        operationIds,
      ],
      queryFn: () =>
        analyticsService.compareOperationRequirements(operationIds),
      staleTime: 5 * 60 * 1000,
      enabled: operationIds.length > 0,
      ...options,
    });
  };

  /**
   * Obtiene estadísticas globales sobre documentación y cumplimiento
   * @param {Object} options Opciones adicionales para la consulta
   * @returns {Object} Datos y estado de la consulta
   */
  const useDocumentationStatistics = (options = {}) => {
    return useQuery({
      queryKey: [DOCUMENTATION_ANALYTICS_KEY, "statistics"],
      queryFn: () => analyticsService.getDocumentationStatistics(),
      staleTime: 10 * 60 * 1000, // 10 minutos (menos frecuente para estadísticas)
      ...options,
    });
  };

  /**
   * Función para invalidar manualmente el caché de datos analíticos
   * @returns {void}
   */
  const invalidateAnalyticsCache = () => {
    queryClient.invalidateQueries([DOCUMENTATION_ANALYTICS_KEY]);
  };
/**
 * Obtiene documentos requeridos por tipo de vehículo
 * @param {String} vehicleTypeId Identificador UUID del tipo de vehículo
 * @param {Object} options Opciones adicionales para la consulta
 * @returns {Object} Datos y estado de la consulta
 */

  return {
    useOperationRequirements,
    useRequirementsSummary,
    useMissingDocuments,
    useOperationsComparison,
    useDocumentationStatistics,
    invalidateAnalyticsCache,
  };
};
