import { Modal } from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";

const DocumentTypeForm = forwardRef(
  (
    {
      showModal,
      handleCloseModal,
      data,
      onSave,
      onCancel,
      roles,
      isLoadingRoles,
    },
    ref
  ) => {
    const [formValues, setFormValues] = useState({
      id: data?.id || null,
      name: data?.name || "",
      description: data?.description || "",
      role_id: data?.role_id || "",
      active: data?.active ?? true,
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormValues((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    };

    const validate = () => {
      const newErrors = {};
      if (!formValues.name.trim()) newErrors.name = "El nombre es obligatorio";
      if (!formValues.description.trim())
        newErrors.description = "La descripción es obligatoria";
      if (!formValues.role_id) newErrors.role_id = "Debe seleccionar un rol";
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      if (validate()) {
        onSave(formValues);
      }
    };
    useEffect(() => {
      if (data) {
        setFormValues({
          id: data?.id,
          name: data?.name,
          description: data?.description,
          role_id: data?.role_id,
          active: data?.active,
        });
      }
    }, [data]);
    return (
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        className="document-type-modal"
      >
        <div className="modal-content" ref={ref}>
          <form onSubmit={handleSubmit} className="custom-form">
            <h3 className="modal-title">
              {data ? "Editar Tipo de Documento" : "Nuevo Tipo de Documento"}
            </h3>

            <div className="form-group">
              <label htmlFor="name">Nombre*</label>
              <input
                id="name"
                name="name"
                className="form-control"
                value={formValues.name}
                onChange={handleChange}
                placeholder="Ej: Cédula de Ciudadanía"
              />
              {errors.name && <span className="form-error">{errors.name}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="description">Descripción*</label>
              <textarea
                id="description"
                name="description"
                className="form-control"
                value={formValues.description}
                onChange={handleChange}
                placeholder="Describa el propósito de este tipo de documento"
              />
              {errors.description && (
                <span className="form-error">{errors.description}</span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="role_id">Rol*</label>
              <select
                id="role_id"
                name="role_id"
                className="form-control"
                value={formValues.role_id}
                onChange={handleChange}
                disabled={isLoadingRoles}
              >
                <option value="">Seleccione un rol</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
              {errors.role_id && (
                <span className="form-error">{errors.role_id}</span>
              )}
            </div>

            <div className="form-group form-check">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  name="active"
                  checked={formValues.active}
                  onChange={handleChange}
                />
                Activo
              </label>
            </div>

            <div className="form-actions">
              <button type="submit" className="btn btn-primary">
                {data ? "Guardar cambios" : "Crear documento"}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onCancel}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
);

DocumentTypeForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
    role_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    active: PropTypes.bool,
  }),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      role_code: PropTypes.string,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoadingRoles: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default DocumentTypeForm;
