import React, { useState, useMemo } from "react";
import { Modal, Tooltip, Pagination } from "@mui/material";
import PropTypes from "prop-types";
import {
  useVehicleBrands,
  useSaveVehicleBrand,
  useDeleteVehicleBrand,
} from "../../../hooks/useVehicleQuery";
import { useNotification } from "../../../context/NotificationContext";
import { useLayout } from "../../../context/LayoutContext";
import CopyIcon from "../../../assets/svg/components/copy.svg";
import LoadingAnimation from "../../loading/LoadingAnimation";

// Función para truncar texto
function truncateText(text = "", maxLength = 30) {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
}

export function VehicleBrandTable({ fixedColumnsMode = 2 }) {
  // Estados para la paginación y búsqueda
  const [page, setPage] = useState(1);
  const [limit] = useState(5); // Limitamos a 5 elementos por página
  const [search, setSearch] = useState("");

  // Obtenemos todos los datos sin parámetros adicionales
  const { data, isLoading, isError } = useVehicleBrands();
  
  // Procesamos los datos para filtrado y paginación en el frontend
  const allBrands = data?.data || [];
  
  // Función para filtrar las marcas según el término de búsqueda
  const filteredBrands = useMemo(() => {
    if (!search) return allBrands;
    
    const searchTermLower = search.toLowerCase();
    return allBrands.filter(
      brand => 
        (brand.name && brand.name.toLowerCase().includes(searchTermLower)) ||
        (brand.description && brand.description.toLowerCase().includes(searchTermLower))
    );
  }, [allBrands, search]);
  
  // Calculamos el total de páginas basado en el número de elementos filtrados
  const totalPages = Math.ceil(filteredBrands.length / limit);
  
  // Obtenemos los elementos para la página actual
  const paginatedBrands = useMemo(() => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    return filteredBrands.slice(startIndex, endIndex);
  }, [filteredBrands, page, limit]);

  const saveMutation = useSaveVehicleBrand();
  const deleteMutation = useDeleteVehicleBrand();

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const { showNotification } = useNotification();
  const { theme } = useLayout();

  if (isLoading) return <LoadingAnimation />;
  if (isError) return <div className={`GM__${theme}-error-message`}>Hubo un error al cargar las marcas de vehículo.</div>;

  // Abre el modal (crear / editar)
  const handleOpenModal = (brand = null) => {
    setEditData(brand);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditData(null);
    setShowModal(false);
  };

  // Eliminar (borrado lógico)
  const handleDelete = (id) => {
    if (window.confirm("¿Eliminar esta marca de vehículo?")) {
      deleteMutation.mutate(id);
    }
  };

  // Guardar (crear / editar)
  const handleSave = async (formValues) => {
    await saveMutation.mutateAsync({
      id: formValues.id,
      data: {
        name: formValues.name,
        description: formValues.description,
      },
    });
    handleCloseModal();
  };

  // Copiar texto y notificar
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showNotification({
          message: "¡Copiado exitosamente!",
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: "Error al copiar.",
          severity: "error",
        });
      });
  };
  
  // Función para manejar el cambio en la búsqueda
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reiniciamos a la página 1 cada vez que se filtra
  };

  // Función para manejar el cambio de página
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <div className={`GM__${theme}-vehicle-table-wrapper`}>
      <div className={`GM__${theme}-header-section`}>
        <h2 className={`GM__${theme}-section-title`}>Marcas de Vehículo</h2>
        <div className={`GM__${theme}-filter-container`}>
          <input
            type="text"
            placeholder="Filtrar por nombre o descripción..."
            value={search}
            onChange={handleSearchChange}
            className={`GM__${theme}-search-input`}
          />
          <button
            className={`GM__${theme}-add-button`}
            onClick={() => handleOpenModal(null)}
          >
            Agregar nueva marca
          </button>
        </div>
      </div>
      <div className={`GM__${theme}-vehicle-table-wrapper-table`}>
        <table
          className={`GM__${theme}-vehicle-table fixed-columns-${fixedColumnsMode}`}
        >
          <thead>
            <tr>
              <th
                className={`${
                  fixedColumnsMode === 1 || fixedColumnsMode === 3
                    ? "fixed-column fixed-column-1"
                    : ""
                }`}
              >
                ID
              </th>
              <th
                className={`${
                  fixedColumnsMode === 2 || fixedColumnsMode === 3
                    ? "fixed-column fixed-column-2"
                    : ""
                }`}
              >
                Nombre
              </th>
              <th>Descripción</th>
              <th>Activo</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {paginatedBrands.length > 0 ? (
              paginatedBrands.map((brand) => (
                <tr key={brand.id}>
                  <td
                    className={`GM__${theme}-copyable-cell ${
                      fixedColumnsMode === 1 || fixedColumnsMode === 3
                        ? "fixed-column fixed-column-1"
                        : ""
                    }`}
                  >
                    <Tooltip title={brand.id?.toString() || "—"}>
                      <span>{truncateText(brand.id?.toString() || "", 10)}</span>
                    </Tooltip>
                    <button
                      className={`GM__${theme}-copy-icon`}
                      onClick={() => handleCopy(brand.id?.toString() || "")}
                      aria-label="Copiar ID"
                    >
                      <img src={CopyIcon} alt="Copiar" />
                    </button>
                  </td>

                  <td
                    className={`GM__${theme}-copyable-cell ${
                      fixedColumnsMode === 2 || fixedColumnsMode === 3
                        ? "fixed-column fixed-column-2"
                        : ""
                    }`}
                  >
                    <Tooltip title={brand.name || "—"}>
                      <span>{truncateText(brand.name || "", 15)}</span>
                    </Tooltip>
                    {brand.name && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() => handleCopy(brand.name || "")}
                        aria-label="Copiar nombre"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  <td className={`GM__${theme}-copyable-cell`}>
                    <Tooltip title={brand.description || "—"}>
                      <span>{truncateText(brand.description || "", 20)}</span>
                    </Tooltip>
                    {brand.description && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() => handleCopy(brand.description || "")}
                        aria-label="Copiar descripción"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  <td>{brand.active ? "Sí" : "No"}</td>

                  <td className={`GM__${theme}-action-buttons`}>
                    <button
                      className={`GM__${theme}-edit-button`}
                      onClick={() => handleOpenModal(brand)}
                    >
                      Editar
                    </button>
                    <button
                      className={`GM__${theme}-delete-button`}
                      onClick={() => handleDelete(brand.id)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className={`GM__${theme}-empty-message`}>
                  {search ? "No se encontraron marcas de vehículo con ese filtro" : "No hay marcas de vehículo registradas"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {/* PAGINACIÓN - Solo se muestra si hay más de una página */}
      {totalPages > 1 && (
        <div className={`GM__${theme}-pagination-container`}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            className={`GM__${theme}-custom-pagination`}
          />
        </div>
      )}

      <Modal
        open={showModal}
        onClose={handleCloseModal}
        className={`GM__modal-container-${theme}`}
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BrandForm
          data={editData}
          onSave={handleSave}
          onCancel={handleCloseModal}
          theme={theme}
        />
      </Modal>
    </div>
  );
}

VehicleBrandTable.propTypes = {
  fixedColumnsMode: PropTypes.oneOf([1, 2, 3]),
};

function BrandForm({ data, onSave, onCancel, theme }) {
  const [formValues, setFormValues] = useState(
    data || {
      id: null,
      name: "",
      description: "",
    }
  );

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.name.trim()) newErrors.name = "El nombre es obligatorio.";
    if (!formValues.description.trim())
      newErrors.description = "La descripción es obligatoria.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      onSave(formValues);
    }
  };

  return (
    <div className={`GM__${theme}-modal GM__${theme}-vehicle-type-modal`}>
      <div className={`GM__${theme}-modal-header-container`}>
        <h3 className={`GM__${theme}-modal-header`}>
          {data ? "Editar Marca" : "Nueva Marca"}
        </h3>
        <div className={`GM__${theme}-modal-subtitle`}>
          {data ? "Modificar información de la marca de vehículo" : "Crear nueva marca de vehículo"}
        </div>
      </div>

      <div className={`GM__${theme}-modal-content`}>
        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-form-group full-width`}>
            <label className={`GM__${theme}-form-label`} htmlFor="name">
              Nombre
            </label>
            <input
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              placeholder="Ej: Toyota"
              className={`GM__${theme}-form-input ${
                errors.name ? "is-invalid" : ""
              }`}
            />
            {errors.name && (
              <span className={`GM__${theme}-form-error`}>{errors.name}</span>
            )}
          </div>
        </div>

        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-form-group full-width`}>
            <label className={`GM__${theme}-form-label`} htmlFor="description">
              Descripción
            </label>
            <input
              id="description"
              name="description"
              value={formValues.description}
              onChange={handleChange}
              placeholder="Ej: Marca japonesa de vehículos"
              className={`GM__${theme}-form-input ${
                errors.description ? "is-invalid" : ""
              }`}
            />
            {errors.description && (
              <span className={`GM__${theme}-form-error`}>
                {errors.description}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className={`GM__${theme}-modal-footer`}>
        <button
          className={`GM__${theme}-btn GM__${theme}-btn-cancel`}
          onClick={onCancel}
        >
          Cancelar
        </button>
        <button
          className={`GM__${theme}-btn GM__${theme}-btn-save`}
          onClick={handleSubmit}
        >
          {data ? "Guardar Cambios" : "Crear"}
        </button>
      </div>
    </div>
  );
}

BrandForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
  }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};