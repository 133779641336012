import React, { useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Paper,
  CircularProgress,
  Alert,
} from "@mui/material";
import DataVisualization from "../../components/DataVisualization";
import "../../assets/scss/views/ExcelUploader.scss";
import FileDropzone from "../../components/FileDropZone";
import { useSatelliteData } from "../../context/SatelliteDataContext";
import ButtonDinamic from "../../components/ui/btn/ButtonDinamic";
import SendIcon from "../../assets/svg/components/send.svg";

const steps = ["Subir Archivos", "Procesar Datos", "Confirmar"];

const DocumentUploader = () => {
  const { GenerateDataJSON, activeStep, setActiveStep, files, setFiles } = useSatelliteData();

  
  const [processedFiles, setProcessedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileUpload = (newFiles) => {
    setFiles(newFiles);
    setError(null);
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = files.filter(
      (fileObj) => fileObj.name !== fileToRemove.name
    );
    setFiles(updatedFiles);
  };

  const handleProcessFiles = async () => {
    if (files.length === 0) {
      setError("Por favor, selecciona al menos un archivo");
      return;
    }
  
    setIsLoading(true);
    setError(null);
  
    try {
      const processedData = await GenerateDataJSON(files);
      setProcessedFiles(processedData); // Actualizar estado local con todos los datos procesados
      setActiveStep(1);
    } catch (err) {
      setError(err.message || "Error al procesar los archivos");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    setProcessedFiles([]);
  };

  const handleSave = () => {
    alert("Datos guardados exitosamente");
    setActiveStep(2);
  };

  return (
    <Box className="excel-uploader">
      <Paper elevation={3} className="excel-uploader__container">
        <Stepper activeStep={activeStep} className="excel-uploader__stepper">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Box className="excel-uploader__content">
            <Typography variant="h5" className="excel-uploader__title">
              Cargar Datos Históricos
            </Typography>

            <FileDropzone
              onFileUpload={handleFileUpload}
              onFileRemove={handleRemoveFile}
              externalFiles={files}
              size="large"
              title="Arrastra, suelta o selecciona tus archivos"
              acceptedFileTypes={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
                "application/vnd.ms-excel": [".xls"],
              }}
              maxFileSize={20485760} // 10MB
              multiple
              maxColumns={3}
            />

            {error && (
              <Alert severity="error" className="excel-uploader__alert">
                {error}
              </Alert>
            )}

            {isLoading && (
              <Box className="excel-uploader__loading">
                <CircularProgress />
              </Box>
            )}
            <ButtonDinamic
              type="medium"
              theme="light"
              disabled={files.length === 0 || isLoading}
              onClick={handleProcessFiles}
              iconOne={SendIcon}
            >
              {isLoading?"Subiendo la información": "Subir la información"}
            </ButtonDinamic>
          </Box>
        )}

        {activeStep === 1 && (
          <DataVisualization
            data={processedFiles}
            onBack={handleBack}
            onSave={handleSave}
          />
        )}
      </Paper>
    </Box>
  );
};

export default DocumentUploader;
