import React, { memo } from "react";
import PropTypes from 'prop-types';
import "../../../assets/scss/components/ui/cards/CardWithThemeUsers.scss";
import OperatorCard from "./operatorCard";
// Importar otras tarjetas según sea necesario

/**
 * Componente genérico que renderiza diferentes tipos de tarjetas de usuario
 * basado en el roleId proporcionado
 */
const CardWithThemeUsers = (props) => {
  // Extraemos las props necesarias para la selección de componentes
  const { 
    theme = "dark", 
    roleId, 
    ...otherProps 
  } = props;
  
  // Aplicamos la clase de tema al contenedor
  const themeClass = theme === "dark" 
    ? "GM__dark-users-rol-card" 
    : "GM__light-users-rol-card";
  
  // Función para renderizar el componente de tarjeta correcto según el roleId
  const renderCardComponent = () => {
    switch (roleId) {
      case 4: // Administrador
        return <OperatorCard {...otherProps} theme={theme} />;
      case 5: // Operador
        return <OperatorCard {...otherProps} theme={theme} />;
      // Añadir más casos según sea necesario para otros roles
      default:
        // Fallback a OperatorCard si no se especifica el roleId
        console.log(`Por defecto se usara la card del operador ya que el id:${roleId} no se logro identificar... console.log("En produccion se vale!!!??");`);
        return <OperatorCard {...otherProps} theme={theme} />;
    }
  };
  
  return (
    <div className={themeClass}>
      {renderCardComponent()}
    </div>
  );
};

// PropTypes para validación
CardWithThemeUsers.propTypes = {
  /** Tema a aplicar (dark o light) */
  theme: PropTypes.oneOf(["light", "dark"]),
  roleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  lastName: PropTypes.string,
  personImage: PropTypes.string,
  documentNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filters: PropTypes.array,
  grupId: PropTypes.string,
};

export default memo(CardWithThemeUsers);