import React, { useState, useEffect } from 'react';
import { 
  Modal, Box, Typography, TextField, Button, FormControl, 
  InputLabel, Select, MenuItem, FormHelperText, CircularProgress,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQueryClient } from '@tanstack/react-query';
import { filterService } from '../../service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '90vh',
  overflow: 'auto'
};

const iconOptions = [
  { value: 'DirectionsCarIcon', label: 'Vehículo Propio' },
  { value: 'LocalShippingIcon', label: 'Camión de Carga' },
  { value: 'PublicIcon', label: 'Internacional' },
  { value: 'CategoryIcon', label: 'Categoría' },
];

export default function CreateFilterModal({ 
  open, 
  onClose, 
  onFilterCreated, 
  roleId, 
  existingFilters = [], 
  theme 
}) {
  const [formData, setFormData] = useState({
    name: '',
    category: '',
    icon: '',
    isSubfilter: false,
    parentId: '',
    newCategory: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableParentFilters, setAvailableParentFilters] = useState([]);
  const queryClient = useQueryClient();

  // Extraer categorías únicas de los filtros existentes
  useEffect(() => {
    if (existingFilters && existingFilters.length > 0) {
      const categories = [...new Set(existingFilters.map(filter => filter.category))];
      setAvailableCategories(categories);
      
      // Filtros que pueden ser padres (solo los principales)
      const parentFilters = existingFilters.filter(filter => 
        !filter.is_child || filter.is_child === false
      );
      setAvailableParentFilters(parentFilters);
    }
  }, [existingFilters]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Si cambiamos la categoría, actualizar los filtros padres disponibles
    if (name === 'category') {
      const categoryParentFilters = existingFilters.filter(filter => 
        filter.category === value && (!filter.is_child || filter.is_child === false)
      );
      setAvailableParentFilters(categoryParentFilters);
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Limpiar error del campo
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'El nombre es requerido';
    }
    
    if (!formData.category && !formData.newCategory) {
      newErrors.category = 'Debe seleccionar o crear una categoría';
    }
    
    if (formData.isSubfilter && !formData.parentId) {
      newErrors.parentId = 'Debe seleccionar un filtro padre';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    
    try {
      // Determinar categoría final
      const finalCategory = formData.newCategory 
        ? formData.newCategory.toLowerCase() 
        : formData.category;
      
      // Crear el objeto de filtro (sin valor, se generará automáticamente en el backend)
      const filterData = {
        name: formData.name,
        category: finalCategory,
        icon: formData.icon,
      };
      
      // Crear el filtro
      const newFilter = await filterService.createFilter(filterData);
      
      // Si es subfiltro, crear la relación
      if (formData.isSubfilter && formData.parentId) {
        await filterService.createFilterRelationship(formData.parentId, newFilter.data.id);
      }
      
      // Asignar el filtro al rol
      await filterService.assignFilterToRole(roleId, newFilter.data.id);
      
      // Actualizar los datos
      queryClient.invalidateQueries(['filtersByRole', roleId]);
      
      // Notificar éxito
      if (onFilterCreated) {
        onFilterCreated(newFilter.data);
      }
      
      // Resetear formulario
      setFormData({
        name: '',
        category: '',
        icon: '',
        isSubfilter: false,
        parentId: '',
        newCategory: '',
      });
      
    } catch (error) {
      console.error('Error al crear el filtro:', error);
      setErrors(prev => ({
        ...prev,
        submit: 'Error al crear el filtro: ' + (error.message || 'Intente nuevamente')
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-create-filter"
      aria-describedby="modal-create-new-filter-form"
    >
      <Box sx={style} className={`GM__${theme}-modal-container`}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography id="modal-create-filter" variant="h6" component="h2">
            Crear Nuevo Filtro
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Nombre del filtro"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            required
          />
          
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <FormControl fullWidth error={!!errors.category}>
              <InputLabel id="category-select-label">Categoría</InputLabel>
              <Select
                labelId="category-select-label"
                name="category"
                value={formData.category}
                onChange={handleChange}
                label="Categoría"
                disabled={!!formData.newCategory}
              >
                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                {availableCategories.map(category => (
                  <MenuItem key={category} value={category}>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </MenuItem>
                ))}
              </Select>
              {errors.category && <FormHelperText>{errors.category}</FormHelperText>}
            </FormControl>
            
            <Typography sx={{ mt: 2, mx: 1 }}>O</Typography>
            
            <TextField
              fullWidth
              margin="normal"
              label="Nueva categoría"
              name="newCategory"
              value={formData.newCategory}
              onChange={handleChange}
              disabled={!!formData.category}
              placeholder="ej: ubicacion, clase, etc."
            />
          </Box>
          
          <FormControl fullWidth margin="normal">
            <InputLabel id="icon-select-label">Icono (opcional)</InputLabel>
            <Select
              labelId="icon-select-label"
              name="icon"
              value={formData.icon}
              onChange={handleChange}
              label="Icono (opcional)"
            >
              <MenuItem value=""><em>Sin icono</em></MenuItem>
              {iconOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <Box sx={{ mt: 3 }}>
            <Button 
              variant="outlined"
              onClick={() => setFormData(prev => ({
                ...prev,
                isSubfilter: !prev.isSubfilter
              }))}
              fullWidth
            >
              {formData.isSubfilter ? "Este es un filtro principal" : "Este es un subfiltro"}
            </Button>
          </Box>
          
          {formData.isSubfilter && (
            <FormControl fullWidth margin="normal" error={!!errors.parentId}>
              <InputLabel id="parent-filter-select-label">Filtro padre</InputLabel>
              <Select
                labelId="parent-filter-select-label"
                name="parentId"
                value={formData.parentId}
                onChange={handleChange}
                label="Filtro padre"
                required={formData.isSubfilter}
              >
                <MenuItem value=""><em>Seleccionar filtro padre</em></MenuItem>
                {availableParentFilters.map(filter => (
                  <MenuItem key={filter.id} value={filter.id}>
                    {filter.name} ({filter.category})
                  </MenuItem>
                ))}
              </Select>
              {errors.parentId && <FormHelperText>{errors.parentId}</FormHelperText>}
            </FormControl>
          )}
          
          {errors.submit && (
            <Typography color="error" sx={{ mt: 2 }}>{errors.submit}</Typography>
          )}
          
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button 
              variant="outlined" 
              onClick={onClose}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              {loading ? 'Creando...' : 'Crear Filtro'}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}