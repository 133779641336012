// src/components/common/ErrorMessage.jsx
import PropTypes from 'prop-types';
import '../../assets/scss/components/ErrorMessage.scss';

const ErrorMessage = ({ message }) => {
  return (
    <div className="error-container">
      <div className="error-content">
        <div className="error-icon">❌</div>
        <h2 className="error-title">Ha ocurrido un error</h2>
        <p className="error-message">{message}</p>
        <p className="error-hint">
          Si el problema persiste, por favor contacte al soporte técnico.
        </p>
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default ErrorMessage;