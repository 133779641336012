import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import '../../../assets/scss/components/ui/cards/RoleWithUsersCard.scss';
import UserAvatar from '../avatar/UserAvatar';
import MoreCircleIcon from '../../../assets/svg/components/more_cirecle.svg';
import MoreCircleIconLight from '../../../assets/svg/components/more_cirecle_light.svg';
import PropTypes from 'prop-types';
import { useLayout } from '../../../context/LayoutContext';

const ROLE_MAPPINGS = {
  'Administrador': {
    route: 'administrators',
    plural: 'Administradores',
    singular: 'Administrador'
  },
  'Vehículos': {
    route: 'Vehiculos',
    plural: 'Vehiculos',
    singular: 'Vehiculo'
  },
  'Terceros': {
    route: 'third-parties',
    plural: 'Terceros',
    singular: 'Tercero'
  },
  'Recursos Humanos': {
    route: 'RRHH',
    plural: 'Recursos Humanos',
    singular: 'Recurso Humano'
  },
  'Operador': {
    route: 'operators',
    plural: 'Operadores',
    singular: 'Operador'
  },
  'Propietario': {
    route: 'owners',
    plural: 'Propietarios',
    singular: 'Propietario'
  },
  'Auxiliar de Operaciones': {
    route: 'ADO',
    plural: 'Auxiliares de Operaciones',
    singular: 'Auxiliar de Operaciones'
  }
};

const RoleWithUsersCard = ({ role, role_id, users, theme: propTheme }) => {
  const navigate = useNavigate();
  const { theme: contextTheme } = useLayout();
  
  // Usar el tema proporcionado como prop o el del contexto
  const theme = propTheme || contextTheme || 'dark';
  
  const roleInfo = ROLE_MAPPINGS[role] || {
    route: 'unknown',
    plural: 'unknown',
    singular: 'unknown'
  };

  const getUserCountText = (count) => {
    if (count === 0) return 'Sin usuarios';
    if (count === 1) return '1 usuario';
    return `${count} usuarios`;
  };

  const handleCardClick = () => {
    navigate(`/persons/${roleInfo.route}`);
  };

  const handleAvatarClick = (userId, e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/persons/profile/${userId}`);
  };
  
  const handleIconClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/persons/add/user/${role_id}`);
  };

  // Seleccionar el icono basado en el tema
  const moreCircleIcon = theme === 'dark' ? MoreCircleIconLight : MoreCircleIcon;

  return (
    <button 
      className={`GM__${theme}-role-card`}
      onClick={handleCardClick}
      type="button"
      aria-label={`Ver ${roleInfo.plural}`}
    >
      <div className={`GM__${theme}-card-header`}>
        <h2 className={`GM__${theme}-card-title`}>{roleInfo.singular}</h2>
        <span className={`GM__${theme}-card-counter`}>
          {getUserCountText(users.length)}
        </span>
      </div>
      <div className={`GM__${theme}-avatar-group`}>
        {users.slice(0, 5).map((user) => (
          <Tooltip key={user.id} title={user.name} arrow>
            <button
              type="button"
              onClick={(e) => handleAvatarClick(user.id, e)}
              className={`GM__${theme}-avatar-button`}
              aria-label={`Ver perfil de ${user.name}`}
            >
              <UserAvatar userId={user.id} theme={theme} />
            </button>
          </Tooltip>
        ))}
        {users.length > 5 && (
          <div className={`GM__${theme}-avatar`}>
            <span className={`GM__${theme}-placeholder`}>+{users.length - 5}</span>
          </div>
        )}
        <button
          type="button"
          className={`GM__${theme}-action-icon-button`}
          onClick={handleIconClick}
          aria-label={`Agregar nuevo ${roleInfo.singular}`}
        >
          <img
            src={moreCircleIcon}
            alt={`Agregar ${roleInfo.singular}`}
            className={`GM__${theme}-action-icon`}
          />
        </button>
      </div>
    </button>
  );
};

// PropTypes actualizados para incluir theme
RoleWithUsersCard.propTypes = {
  role: PropTypes.string,
  role_id: PropTypes.number,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string
    })
  ),
  theme: PropTypes.oneOf(['light', 'dark'])
};

export default RoleWithUsersCard;