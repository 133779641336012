import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';
import clsx from 'clsx';
import '../../../assets/scss/components/ui/tables/CriticalPointsTable.scss';
import { calculateViolationColor, getWeekRanges } from '../../../utils/tableHelpers';
import StatusDisplay from "../../loading/StatusDisplay";

const ROWS_PER_PAGE = 10;

const CriticalPointsTable = ({
  criticalPoints = [],
  violations = [],
  theme = 'light',
  period,
  onViolationClick,
  hasData = criticalPoints.length > 0 
}) => {
  const [page, setPage] = useState(1);

  // Optimización de cálculos con useMemo
  const { weeks, violationsMap, rowTotals, weekTotals, paginatedData } = useMemo(() => {
    const currentWeeks = getWeekRanges(period);
    
    // Mapa de violaciones optimizado
    const vMap = new Map();
    violations.forEach(violation => {
      if (violation.violation_details?.[0]) {
        const detail = violation.violation_details[0];
        const date = new Date(detail.dateTime);
        const day = date.getDate();
        const weekIndex = currentWeeks.findIndex(week => 
          day >= week.start && day <= week.end
        );
        
        if (weekIndex !== -1) {
          const key = `${violation.critical_point_id}-${weekIndex}`;
          vMap.set(key, (vMap.get(key) || 0) + 1);
        }
      }
    });

    // Cálculo de totales por punto crítico
    const rTotals = new Map();
    criticalPoints.forEach(point => {
      let total = 0;
      currentWeeks.forEach((_, weekIndex) => {
        total += vMap.get(`${point.id}-${weekIndex}`) || 0;
      });
      rTotals.set(point.id, total);
    });

    // Cálculo de totales por semana
    const wTotals = currentWeeks.map((_, weekIndex) => {
      return criticalPoints.reduce((total, point) => 
        total + (vMap.get(`${point.id}-${weekIndex}`) || 0), 0
      );
    });

    // Paginación
    const startIndex = (page - 1) * ROWS_PER_PAGE;
    const paginatedPoints = criticalPoints.slice(startIndex, startIndex + ROWS_PER_PAGE);
    const totalPages = Math.ceil(criticalPoints.length / ROWS_PER_PAGE);

    return {
      weeks: currentWeeks,
      violationsMap: vMap,
      rowTotals: rTotals,
      weekTotals: wTotals,
      paginatedData: {
        points: paginatedPoints,
        totalPages
      }
    };
  }, [criticalPoints, violations, period, page]);

  const renderViolationIndicator = (pointId, weekIndex) => {
    const count = violationsMap.get(`${pointId}-${weekIndex}`);
    if (!count) return null;

    const backgroundColor = calculateViolationColor(count);
    
    return (
      <div 
        className="violation-indicator"
        style={{ backgroundColor }}
        onClick={() => onViolationClick?.(pointId, weeks[weekIndex], count)}
        title={`${count} violaciones en la semana ${weekIndex + 1}`}
      >
        {count}
      </div>
    );
  };

  if (!hasData) {
    return (
      <div className={clsx('critical-points-no-data', `theme-${theme}`)}>
        <StatusDisplay
          type={204}
          message="Lo sentimos, no pudimos encontrar resultados..."
        />
      </div>
    );
  }

  return (
    <div className={clsx('critical-points-table-container', `theme-${theme}`)}>
      <table className="critical-points-table">
        <thead>
          <tr>
            <th className="point-name-header">Puntos críticos (lugar)</th>
            {weeks.map((week, index) => (
              <th key={index} className="week-header">
                <div className="week-label">
                  {`Semana ${index + 1}`}
                  <small>{`(${week.start}-${week.end})`}</small>
                </div>
              </th>
            ))}
            <th className="total-header">Total</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.points.map(point => (
            <tr key={point.id}>
              <td className="point-name">{point.point_name}</td>
              {weeks.map((_, weekIndex) => (
                <td
                  key={`${point.id}-${weekIndex}`}
                  className="violation-cell"
                >
                  {renderViolationIndicator(point.id, weekIndex)}
                </td>
              ))}
              <td className="row-total">
                {rowTotals.get(point.id) || 0}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="total-label">Total semanal</td>
            {weekTotals.map((total, index) => (
              <td key={`total-${index}`} className="weekly-total">
                {total}
              </td>
            ))}
            <td className="grand-total">
              {weekTotals.reduce((a, b) => a + b, 0)}
            </td>
          </tr>
        </tfoot>
      </table>
      
      {paginatedData.totalPages > 1 && (
        <div className="pagination-container">
          <Pagination 
            count={paginatedData.totalPages}
            page={page}
            onChange={(_, value) => setPage(value)}
            shape="rounded"
            className={`custom-pagination ${theme}`}
          />
        </div>
      )}
    </div>
  );
};

CriticalPointsTable.propTypes = {
  criticalPoints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      point_name: PropTypes.string.isRequired,
    })
  ),
  violations: PropTypes.arrayOf(
    PropTypes.shape({
      critical_point_id: PropTypes.number.isRequired,
      violation_details: PropTypes.arrayOf(
        PropTypes.shape({
          dateTime: PropTypes.string.isRequired,
          speed: PropTypes.number,
          location: PropTypes.shape({
            lat: PropTypes.number,
            lon: PropTypes.number
          }),
          tripData: PropTypes.object
        })
      ),
    })
  ),
  theme: PropTypes.oneOf(['light', 'dark']),
  period: PropTypes.string,
  onViolationClick: PropTypes.func,
  hasData: PropTypes.bool
};

export default CriticalPointsTable;