import styles from '../../../assets/scss/components/ui/avatar/UserAvatar.module.scss';
import StreamingMediaType from '../../../utils/StreamingMediaType';
import PropTypes from 'prop-types';


const UserAvatar = ({ userId }) => {
  return (
    <div className={styles.avatar}>
      <StreamingMediaType
        url={userId}
        type="Foto de Perfil"
        className={styles.media}
      />
    </div>
  );
};

export default UserAvatar;
UserAvatar.propTypes = {
  userId: PropTypes.string.isRequired,
};
