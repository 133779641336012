import React, { useState } from "react";
import PropTypes from "prop-types";
import { useCommentsAndLocations } from "../../hooks/commentsAndResidenceQuery";
import { useNotification, NotificationSeverity } from "../../context/NotificationContext";
import { useQueryClient } from "@tanstack/react-query";
import CommentsLocationsDrawer from "../../components/ui/drawner/CommentsLocationsDrawer";
import { ReactComponent as EditIcon } from "../../assets/svg/view/edit.svg";
import VehicleStreamingMediaType from "../../utils/VehicleStreamingMedia";

const VehicleProfileInfo = ({ vehicle, theme, onEditClick }) => {
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();
  const {
    useCommentsByReference,
    createComment,
    updateComment,
    deleteComment,
  } = useCommentsAndLocations();
  
  // Estado para el drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState(null);
  
  // Obtener comentarios
  const { data: comments } = useCommentsByReference(vehicle?.id);
  const commentData = comments?.data || [];
  
  // Manejadores
  const handleOpenDrawer = (type) => {
    setDrawerType(type);
    setDrawerOpen(true);
  };
  
  const handleEdit = async (id, item) => {
    try {
      await updateComment({
        commentId: id,
        data: { ...item, reference_id: vehicle?.id },
      });
      showNotification({
        message: "Comentario actualizado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
      queryClient.invalidateQueries(["comments", vehicle?.id]);
    } catch (error) {
      console.error("Error al editar:", error);
      showNotification({
        message: error.response?.data?.message || "Error al actualizar el comentario",
        severity: NotificationSeverity.ERROR,
      });
    }
  };
  
  const handleDelete = async (id) => {
    try {
      await deleteComment(id);
      showNotification({
        message: "Comentario eliminado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
      queryClient.invalidateQueries(["comments", vehicle?.id]);
    } catch (error) {
      console.error("Error al eliminar:", error);
      showNotification({
        message: error.response?.data?.message || "Error al eliminar el comentario",
        severity: NotificationSeverity.ERROR,
      });
    }
  };
  
  const handleCreate = async (item) => {
    try {
      await createComment({ ...item, reference_id: vehicle?.id });
      showNotification({
        message: "Comentario creado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
      queryClient.invalidateQueries(["comments", vehicle?.id]);
    } catch (error) {
      console.error("Error al crear:", error);
      showNotification({
        message: error.response?.data?.message || "Error al crear el comentario",
        severity: NotificationSeverity.ERROR,
      });
    }
  };
  
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };
  
  return (
    <div className={`GM__${theme}-vehicle-profile-info`}>
      {/* Botón de edición */}
      <button className="edit-toggle-btn" onClick={onEditClick}>
        <EditIcon />
      </button>

      <div className="profile-header">
        {/* Nuevo contenedor de imagen del vehículo */}
        <div className="profile-image-container">
          <div className="rotated-rectangle">
            <div className="profile-image-wrapper">
              <div className="profile-main-image">
                <VehicleStreamingMediaType
                  url={vehicle.id} 
                  type="vehiclePhoto" 
                  className="profile-image"
                />
              </div>
              <div className="profile-backdrop">
                {/* Puedes usar una imagen de fondo predeterminada para vehículos */}
                <VehicleStreamingMediaType 
                  url={vehicle.id} 
                  type="vehiclePhoto" 
                  className="profile-backdrop-image"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="profile-info">
          <div className="info-display">
            <h2>{vehicle.plate} - {vehicle.type_name}</h2>
            
            <div className="info-sections">
              {/* Columna Izquierda */}
              <div className="info-column">
                {/* Sección de Información del Vehículo */}
                <div className="info-section">
                  <h3 className="section-title">Información del Vehículo</h3>
                  <div className="section-content">
                    <div className="info-item">
                      <span className="label">Marca:</span>
                      <span className="value">{vehicle.brand_name}</span>
                    </div>
                    <div className="info-item">
                      <span className="label">Modelo:</span>
                      <span className="value">{vehicle.model}</span>
                    </div>
                    <div className="info-item">
                      <span className="label">Año:</span>
                      <span className="value">{vehicle.year}</span>
                    </div>
                    <div className="info-item">
                      <span className="label">Capacidad:</span>
                      <span className="value">
                        {vehicle.capacity ? `${vehicle.capacity} ton` : "No especificada"}
                      </span>
                    </div>
                    <div className="info-item">
                      <span className="label">Color:</span>
                      <span className="value">{vehicle.color || "No especificado"}</span>
                    </div>
                    {vehicle.operation_name && (
                      <div className="info-item">
                        <span className="label">Operación:</span>
                        <span className="value">{vehicle.operation_name}</span>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Sección de Vehículo Relacionado (si existe) */}
                {vehicle.relatedVehicle && (
                  <div className="info-section">
                    <h3 className="section-title">
                      {vehicle.relatedVehicle.type === 'trailer' ? 'Remolque Asociado' : 'Cabezote Asociado'}
                    </h3>
                    <div className="section-content">
                      <div className="info-item">
                        <span className="label">Placa:</span>
                        <span className="value">{vehicle.relatedVehicle.data.plate}</span>
                      </div>
                      <div className="info-item">
                        <span className="label">Tipo:</span>
                        <span className="value">{vehicle.relatedVehicle.data.vehicle_type_name}</span>
                      </div>
                      <div className="info-item">
                        <span className="label">Modelo:</span>
                        <span className="value">{vehicle.relatedVehicle.data.model}</span>
                      </div>
                      <div className="info-item">
                        <span className="label">Año:</span>
                        <span className="value">{vehicle.relatedVehicle.data.year}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Columna Derecha */}
              <div className="info-column">
                {/* Sección de Propietario y Operador */}
                <div className="info-section">
                  <h3 className="section-title">Propietario y Operador</h3>
                  <div className="section-content">
                    {vehicle.owner && (
                      <>
                        <div className="info-item">
                          <span className="label">Propietario:</span>
                          <span className="value">
                            {`${vehicle.owner.first_name} ${vehicle.owner.first_last_name} ${vehicle.owner.second_last_name || ''}`}
                          </span>
                        </div>
                        <div className="info-item">
                          <span className="label">Documento:</span>
                          <span className="value">{vehicle.owner.document_number}</span>
                        </div>
                      </>
                    )}
                    
                    {vehicle.operator && (
                      <>
                        <div className="info-item">
                          <span className="label">Operador:</span>
                          <span className="value">
                            {`${vehicle.operator.first_name} ${vehicle.operator.first_last_name} ${vehicle.operator.second_last_name || ''}`}
                          </span>
                        </div>
                        <div className="info-item">
                          <span className="label">Documento:</span>
                          <span className="value">{vehicle.operator.document_number}</span>
                        </div>
                      </>
                    )}
                    
                    {vehicle.contract_start_date && (
                      <div className="info-item">
                        <span className="label">Periodo de Contrato:</span>
                        <span className="value">
                          {new Date(vehicle.contract_start_date).toLocaleDateString()} - 
                          {vehicle.contract_end_date ? new Date(vehicle.contract_end_date).toLocaleDateString() : 'Sin finalización'}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Sección de Información de Contacto */}
                <div className="info-section">
                  <h3 className="section-title">Información de Contacto</h3>
                  <div className="section-content">
                    {vehicle.primary_phone_number && (
                      <div className="info-item">
                        <span className="label">Teléfono Principal:</span>
                        <span className="value">
                          ({vehicle.primary_phone_code}) {vehicle.primary_phone_number}
                        </span>
                      </div>
                    )}
                    
                    {vehicle.secondary_phone_number && (
                      <div className="info-item">
                        <span className="label">Teléfono Secundario:</span>
                        <span className="value">
                          ({vehicle.secondary_phone_code}) {vehicle.secondary_phone_number}
                        </span>
                      </div>
                    )}
                    
                    {vehicle.nationality_country && (
                      <div className="info-item">
                        <span className="label">Nacionalidad:</span>
                        <span className="value">{vehicle.nationality_country}</span>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Sección de Observaciones y Comentarios */}
                <div className="info-section">
                  <h3 className="section-title">Observaciones y Comentarios</h3>
                  <div className="section-content">
                    {commentData.length > 0 ? (
                      <>
                        <div className="info-item">
                          <span className="label">Título:</span>
                          <span className="value">{commentData[0]?.title}</span>
                        </div>
                        <div className="info-item">
                          <span className="label">Descripción:</span>
                          <span className="value">
                            {commentData[0]?.comment_text?.substring(0, 100)}
                            {commentData[0]?.comment_text?.length > 100 && "..."}
                          </span>
                        </div>
                        <div className="info-item footer-item">
                          <span className="label">Total de observaciones:</span>
                          <span className="value">{commentData.length}</span>
                          <button
                            className="view-more-btn"
                            onClick={() => handleOpenDrawer("comments")}
                          >
                            Ver más
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="info-item empty-state">
                        <span className="value">No hay observaciones registradas</span>
                        <button
                          className="add-btn"
                          onClick={() => handleOpenDrawer("comments")}
                        >
                          Agregar observación
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Drawer de comentarios */}
      <CommentsLocationsDrawer
        open={drawerOpen}
        onClose={handleCloseDrawer}
        type={drawerType}
        data={commentData}
        onEdit={handleEdit}
        onCreate={handleCreate}
        onDelete={handleDelete}
        theme={theme}
      />
    </div>
  );
};

// Actualizar PropTypes para incluir onEditClick
VehicleProfileInfo.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    plate: PropTypes.string.isRequired,
    type_name: PropTypes.string,
    color: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.number,
    brand_name: PropTypes.string,
    capacity: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    operation_name: PropTypes.string,
    primary_phone_number: PropTypes.string,
    primary_phone_code: PropTypes.string,
    secondary_phone_number: PropTypes.string,
    secondary_phone_code: PropTypes.string,
    nationality_country: PropTypes.string,
    contract_start_date: PropTypes.string,
    contract_end_date: PropTypes.string,
    documents: PropTypes.array.isRequired,
    owner: PropTypes.object,
    operator: PropTypes.object,
    relatedVehicle: PropTypes.object
  }).isRequired,
  theme: PropTypes.oneOf(['dark', 'light']).isRequired,
  onEditClick: PropTypes.func
};

export default VehicleProfileInfo;