// CommentsLocationsDrawer.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { ReactComponent as EditIcon } from "../../../assets/svg/view/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/components/trash.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/view/close_circle.svg";
import { ReactComponent as AddIcon } from "../../../assets/svg/components/upload.svg";
import CommentForm from "../../../page/profile/CommentForm";
import LocationForm from "../../../page/profile/LocationForm";
import "../../../assets/scss/components/ui/drawner/CommentsLocationsDrawer.scss";

const CommentsLocationsDrawer = ({
  open,
  onClose,
  type,
  data,
  onEdit,
  onDelete,
  onCreate,
  theme
}) => {
  const [editItem, setEditItem] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const isComments = type === "comments";
  const processData = (rawData) => {
    if (!rawData) return [];
    // Maneja tanto el caso de {data: [...]} como el array directo
    return Array.isArray(rawData.data)
      ? rawData.data
      : Array.isArray(rawData)
      ? rawData
      : [];
  };

  const processedData = processData(data);
  const handleCreateClick = () => {
    setEditItem(null);
    setShowForm(true);
  };

  const handleEditClick = (item) => {
    setEditItem(item);
    setShowForm(true);
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setDeleteConfirmOpen(true);
  };

  const handleFormSubmit = async (formData) => {
    try {
      if (editItem) {
        await onEdit(editItem.id, {
          ...formData,
          user_id: editItem.user_id,
        });
      } else {
        await onCreate({
          ...formData,
          user_id: data?.[0]?.user_id,
        });
      }
      setShowForm(false);
      setEditItem(null);
      // Mostrar notificación de éxito
    } catch (error) {
      console.error("Error en la operación:", error);
      // Mostrar notificación de error
    }
  };
  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      await onDelete(itemToDelete);
      setDeleteConfirmOpen(false);
      setItemToDelete(null);
    }
  };

  const renderForm = () => {
    return isComments ? (
      <CommentForm
        initialData={editItem}
        onSubmit={handleFormSubmit}
        onCancel={() => setShowForm(false)}
        theme={theme}
      />
    ) : (
      <LocationForm
        initialData={editItem}
        onSubmit={handleFormSubmit}
        onCancel={() => setShowForm(false)}
        theme={theme}
      />
    );
  };

  const renderLocationCard = (location) => (
    <div className={`GM__${theme}-content-card`} key={location.id}>
      <div className={`GM__${theme}-card-header`}>
        <div className={`GM__${theme}-header-content`}>
          <h3>{location.address_details}</h3>
          {location.is_primary && (
            <span className={`GM__${theme}-primary-badge`}>Principal</span>
          )}
        </div>
        <div className={`GM__${theme}-action-buttons`}>
          <button
            onClick={() => handleEditClick(location)}
            className={`GM__${theme}-edit-button`}
          >
            <EditIcon />
          </button>
          <button
            onClick={() => handleDeleteClick(location)}
            className={`GM__${theme}-delete-button`}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
      <div className={`GM__${theme}-card-body`}>
        <p className={`GM__${theme}-location-type`}>
          {location.location_type === "primary"
            ? "Principal"
            : location.location_type === "home"
            ? "Casa"
            : location.location_type === "work"
            ? "Trabajo"
            : "Otro"}
        </p>
        <p className={`GM__${theme}-address`}>{location.address_details}</p>
        {location.city && location.department && location.country && (
          <p className={`GM__${theme}-location-details`}>
            {`${location.city}, ${location.department}, ${location.country}`}
          </p>
        )}
        {location.postal_code && (
          <p className={`GM__${theme}-postal-code`}>CP: {location.postal_code}</p>
        )}
      </div>
      <div className={`GM__${theme}-card-footer`}>
        {location.created_by && <span>Creado por: {location.created_by}</span>}
        {location.created_at && (
          <span>{new Date(location.created_at).toLocaleDateString()}</span>
        )}
      </div>
    </div>
  );

  const renderCommentCard = (comment) => (
    <div className={`GM__${theme}-content-card`} key={comment.id}>
      <div className={`GM__${theme}-card-header`}>
        <h3>{comment.title}</h3>
        <div className={`GM__${theme}-action-buttons`}>
          <button onClick={() => handleEditClick(comment)} className={`GM__${theme}-edit-button`}>
            <EditIcon />
          </button>
          <button onClick={() => handleDeleteClick(comment)} className={`GM__${theme}-delete-button`}>
            <DeleteIcon />
          </button>
        </div>
      </div>
      <div className={`GM__${theme}-card-body`}>
        <p>{comment.comment_text}</p>
      </div>
      <div className={`GM__${theme}-card-footer`}>
        <span>Creado por: {comment.first_last_name}</span>
        <span>{new Date(comment.created_at).toLocaleDateString()}</span>
      </div>
    </div>
  );

  const renderContent = () => {
    if (showForm) {
      return renderForm();
    }

    const isEmpty = processedData.length === 0;

    return (
      <>
        <div className={`GM__${theme}-drawer-header`}>
          <h2>{isComments ? "Comentarios" : "Ubicaciones"}</h2>
          <div className={`GM__${theme}-header-actions`}>
            <button
              className={`GM__${theme}-add-btn`}
              onClick={handleCreateClick}
              aria-label={`Agregar ${isComments ? "comentario" : "ubicación"}`}
            >
              <AddIcon />
            </button>
            <button className={`GM__${theme}-close-btn`} onClick={onClose} aria-label="Cerrar">
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className={`GM__${theme}-drawer-content`}>
          {!isEmpty ? (
            processedData.map((item) => (
              <React.Fragment key={item.id}>
                {isComments
                  ? renderCommentCard(item)
                  : renderLocationCard(item)}
              </React.Fragment>
            ))
          ) : (
            <div className={`GM__${theme}-empty-state`}>
              <p>No hay {isComments ? "comentarios" : "ubicaciones"}</p>
              <button className={`GM__${theme}-add-btn`} onClick={handleCreateClick}>
                Agregar {isComments ? "comentario" : "ubicación"}
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        className={`GM__${theme}-comments-locations-drawer`}
      >
        <div className={`GM__${theme}-drawer-container`}>{renderContent()}</div>
      </Drawer>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        className={`GM__${theme}-confirmation-dialog`}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          ¿Está seguro que desea eliminar{" "}
          {isComments ? "este comentario" : "esta ubicación"}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CommentsLocationsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["comments", "locations"]),
  data: PropTypes.oneOfType([
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          address_details: PropTypes.string,
          city: PropTypes.string,
          country: PropTypes.string,
          department: PropTypes.string,
          created_at: PropTypes.string,
          is_primary: PropTypes.bool,
          location_type: PropTypes.string,
          postal_code: PropTypes.string,
          updated_at: PropTypes.string,
          user_id: PropTypes.string,
          created_by: PropTypes.string,
        })
      ),
    }),
    PropTypes.array,
  ]),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark'])
};

export default CommentsLocationsDrawer;
