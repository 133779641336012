import { useQuery } from "@tanstack/react-query";
import { satelliteService, userService, mediaService, rolService } from "../service/index";

export const useMonthlyAnalytics = (selectedDate, isAuthenticated = true) => {
  return useQuery({
    queryKey: ["monthlyAnalytics", selectedDate],
    queryFn: async () => {
      if (!selectedDate) throw new Error("Fecha no proporcionada");
      const data = await satelliteService.getMonthlyVehicleAnalytics(selectedDate);
      return data;
    },
    staleTime: 3 * 60 * 1000, // 3 minutos
    cacheTime: 5 * 60 * 1000, // 5 minutos
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000, // Refrescar cada 5 minutos
    retry: 2,
    enabled: !!selectedDate && isAuthenticated,
  });
};
export const useDailyAnalytics = (selectedDate) => {
  return useQuery({
    queryKey: ['dailyAnalytics', selectedDate],
    queryFn: async () => {
      if (!selectedDate) {
        throw new Error('Fecha no proporcionada');
      }
      return satelliteService.getDailyVehicleAnalytics(selectedDate);
    },
    staleTime: 3 * 60 * 1000,  // 3 minutos
    cacheTime: 5 * 60 * 1000,  // 5 minutos
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000, // Refrescar cada 5 minutos
    retry: 2,
    enabled: !!selectedDate, // Solo habilitar si `selectedDate` está definido
  });
};
export const useAnnualAnalytics = (selectedDate) => {
  return useQuery({
    queryKey: ["annualAnalytics", selectedDate],
    queryFn: async () => {
      if (!selectedDate) throw new Error("Fecha no proporcionada");
      const data = await satelliteService.getAnnualVehicleAnalytics(selectedDate);
      return data;
    },
    staleTime: 3 * 60 * 1000, // 3 minutos
    cacheTime: 5 * 60 * 1000, // 5 minutos
    refetchOnMount: true, // Añadir esto
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000, // Refrescar cada 5 minutos
    retry: 2,
    enabled: !!selectedDate, // Solo habilitar si `selectedDate` está definido
  });
};
export const useAnnualHotspots = (selectedDate, isAuthenticated = true) => {
  return useQuery({
    queryKey: ["annualHotspots", selectedDate],
    queryFn: async () => {
      if (!selectedDate) throw new Error("Fecha no proporcionada");
      const data = await satelliteService.getAnnualHotspots(selectedDate);
      return data;
    },
    staleTime: 3 * 60 * 1000, // 3 minutos
    cacheTime: 5 * 60 * 1000, // 5 minutos
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000, // Refrescar cada 5 minutos
    retry: 2,
    enabled: !!selectedDate && isAuthenticated,
  });
};
export const useGetUser = (id) => {
  return useQuery({
    queryKey: ["user", id],
    queryFn: async () => {
      if (!id ) throw new Error("id no proporcionada");
      const data = await userService.getUser(id);
      return data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutos
    cacheTime: 10 * 60 * 1000, // 10 minutos
    refetchOnWindowFocus: false, // No refrescar al enfocar la ventana
    retry: 2, // Intentar 2 veces en caso de error
  });
};

export const useGetRoleWithUsers = (selectedPage) => {
  return useQuery({
    queryKey: ["dataRoleWithUsers", selectedPage],
    queryFn: async () => {
      if (!selectedPage) throw new Error("Fecha no proporcionada");
      const data = await userService.getRoleWithUsers(selectedPage);
      return data;
    },
    staleTime: 3 * 60 * 1000, // 3 minutos
    cacheTime: 5 * 60 * 1000, // 5 minutos
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 60 * 1000, // Refrescar cada 10 minutos
    retry: 2,
    enabled: !!selectedPage, // Solo habilitar si `selectedPage` está definido
  });
};
export const useGetMedia = (fileId) => {
  return useQuery({
    queryKey: ["mediaFile", fileId],
    queryFn: async () => {
      if (!fileId) throw new Error("FileId no proporcionado");
      const data = await mediaService.getMediaByFileId(fileId);
      return data;
    },
    staleTime: 3 * 60 * 1000, // 3 minutos
    cacheTime: 5 * 60 * 1000, // 5 minutos
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 60 * 1000, // Refrescar cada 10 minutos
    retry: 2,
    enabled: !!fileId,
  });
};
export const useGetMediaWithUrl = (url, type) => {
  return useQuery({
    queryKey: ["dataMediaWithUrl", url, type],
    queryFn: async () => {
      if (!url) throw new Error("Fecha no proporcionada");
      const data = await mediaService.getMediaWithUrl(url, type);
      return data;
    },
    staleTime: 3 * 60 * 1000, // 3 minutos
    cacheTime: 5 * 60 * 1000, // 5 minutos
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 60 * 1000, // Refrescar cada 10 minutos
    retry: 2,
    enabled: !!url, // Solo habilitar si `selectedPage` está definido
  });
};
export const useGetVehicleMediaWithUrl = (vehicleId, type) => {
  return useQuery({
    queryKey: ["vehicleMediaWithUrl", vehicleId, type],
    queryFn: async () => {
      if (!vehicleId) throw new Error("ID de vehículo no proporcionado");
      const data = await mediaService.getVehicleMediaWithUrl(vehicleId, type);
      return data;
    },
    staleTime: 3 * 60 * 1000, // 3 minutos
    cacheTime: 5 * 60 * 1000, // 5 minutos
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 60 * 1000, // Refrescar cada 10 minutos
    retry: 2,
    enabled: !!vehicleId, // Solo habilitar si vehicleId está definido
  });
};
export const useGetFiltersByRoleId = (roleId, isAuthenticated = true) => {
  return useQuery({
    queryKey: ['filtersByRole', roleId],
    queryFn: async () => {
      if (!roleId) throw new Error('ID de rol no proporcionado');
      const data = await userService.getFiltersByRoleId(roleId);
      return data.data;
    },
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!roleId && isAuthenticated,
  });
};

export const useGetUsersWithRole = (selectedPage, usersPerPage, searchTerm, role, isAuthenticated = true, filterIds = []) => {
  return useQuery({
    queryKey: ["usersWithRole", selectedPage, usersPerPage, searchTerm, role, filterIds],
    queryFn: async () => {
      if (!selectedPage && !usersPerPage && !role) throw new Error("Datos incorrectamente proporcionados");
      const data = await userService.getUsersWithRol(selectedPage, usersPerPage, searchTerm, role, filterIds);
      return data;
    },
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 60 * 1000,
    retry: 2,
    enabled: !!selectedPage && isAuthenticated,
  });
};

export const useGetRoles = (isAuthenticated = true) => {
  return useQuery({
    queryKey: ['roles'],
    queryFn: rolService.getRolService,
    staleTime: 5 * 60 * 1000, // 5 minutos
    cacheTime: 10 * 60 * 1000, // 10 minutos
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: isAuthenticated // Solo ejecuta si está autenticado
  });
};
