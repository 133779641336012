import React, { useState } from 'react';
import './Contacto.scss';
import abejasImage from '../../assets/img/abejaparchera.png';

// Importación de íconos SVG (ajusta las rutas según tu estructura de proyecto)
import IconLocation from '../../assets/svg/parameters/cycle.svg';
import IconEmail from '../../assets/svg/parameters/cycle.svg';
import IconPhone from '../../assets/svg/parameters/cycle.svg';

export default function Contacto() {
  // Corrección de la declaración del estado del formulario
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    mensaje: ''
  });

  const [enviando, setEnviando] = useState(false);
  const [enviado, setEnviado] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEnviando(true);
    
    try {
      // Simulamos un tiempo de espera para el envío
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setEnviado(true);
      setFormData({ nombre: '', correo: '', mensaje: '' });
      
      // Reseteamos el estado de enviado después de 3 segundos
      setTimeout(() => {
        setEnviado(false);
      }, 3000);
    } catch (error) {
      console.error('Error al enviar formulario:', error);
    } finally {
      setEnviando(false);
    }
  };

  return (
    <section className="contacto-section">
      <div className="contacto-container">
        <h2 className="contacto-title">
          Contacta<span className="contacto-title-highlight">nos</span>
        </h2>
        
        <div className="contacto-content">
          {/* Imagen de las abejas */}
          <div className="contacto-image-container">
            <img src={abejasImage} alt="Abejas" className="contacto-image" />
          </div>
          
          {/* Formulario de contacto */}
          <div className="contacto-form-container">
            <form onSubmit={handleSubmit} className="contacto-form">
              <div className="form-group">
                <label htmlFor="nombre">Nombre</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="correo">Correo electrónico</label>
                <input
                  type="email"
                  id="correo"
                  name="correo"
                  value={formData.correo}
                  onChange={handleChange}
                  required
                  className="form-control"
                  placeholder="mi_persona@reblish.com "
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="mensaje">Mensaje</label>
                <textarea
                  id="mensaje"
                  name="mensaje"
                  value={formData.mensaje}
                  onChange={handleChange}
                  required
                  className="form-control"
                  rows={5}
                />
              </div>
              
              <div className="form-submit">
                <button 
                  type="submit" 
                  className={`submit-button ${enviando ? 'enviando' : ''} ${enviado ? 'enviado' : ''}`}
                  disabled={enviando}
                >
                  {enviando ? 'Enviando...' : enviado ? '¡Enviado!' : 'Enviar'}
                </button>
              </div>
            </form>
          </div>
        </div>
        
        {/* Información de contacto */}
        <div className="contacto-info">
          <div className="info-item">
            <div className="info-icon">
              <img src={IconLocation} alt="Ubicación" />
            </div>
            <div className="info-content">
              <h3>Dirección</h3>
              <p>Villagarzon, Putumayo</p>
              <p>Cra 4 Nª 05- 07 Barrio Fátima</p>
            </div>
          </div>
          
          <div className="info-item">
            <div className="info-icon">
              <img src={IconEmail} alt="Email" />
            </div>
            <div className="info-content">
              <h3>Correo electrónico</h3>
              <p>gerencia@cootranspetrols.com</p>
            </div>
          </div>
          
          <div className="info-item">
            <div className="info-icon">
              <img src={IconPhone} alt="Teléfono" />
            </div>
            <div className="info-content">
              <h3>Teléfono</h3>
              <p>314 282 3032 / 310 770 5849</p>
            </div>
          </div>
        </div>
        
        {/* Footer */}
        <footer className="contacto-footer">
          <div className="footer-divider"></div>
          <p className="footer-quote">
          “En CootransPetrols estamos altamente comprometida a brindar nuestros servicios de transporte y lógistica a nuestros clientes”
          </p>
          <p className="footer-copyright">
          COOTRANSPETROLS - 2025
          </p>
        </footer>
      </div>
    </section>
  );
}