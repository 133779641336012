import PropTypes from 'prop-types';
import React from 'react';
import CustomInputField from '../ui/inputs/CustomInputField';
import CustomDatePicker from '../ui/CustomDatePicker';
import UploadSuccessIcon from'../../assets/svg/view/upload_success.svg';
import ArrowUpIcon from'../../assets/svg/view/arrow_up.svg';
import LoadingIcon from '../../assets/svg/view/loading.svg';
import dayjs from 'dayjs';
import CalendarIcon from "../../assets/svg/components/calendar.svg";
import '../../assets/scss/components/ui/tables/DocumentsSectionAddPersons.scss'
export default function DocumentsSectionAddPersons({ roleId, documents, onDocumentsChange, dataTypes, isLoading, theme }) {


  const handleAddDocument = (typeId) => {
    onDocumentsChange({
      type: 'ADD_DOCUMENT',
      payload: {
        typeId,
        document: {
          id: `temp-${Date.now()}`,
          fileName: '',
          file: null,
          expeditionDate: null,
          expirationDate: null,
          description: '',
          docType: String(typeId),
          required: true
        }
      }
    });
  };

  const handleFileSelect = (typeId, docId, event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpdateDocument(typeId, docId, 'file', file);
      handleUpdateDocument(typeId, docId, 'fileName', file.name);
    }
  };

  const handleUpdateDocument = (typeId, docId, field, value) => {
    onDocumentsChange({
      type: 'UPDATE_DOCUMENT',
      payload: { typeId, docId, field, value }
    });
  };

  const handleDeleteDocument = (typeId, docId) => {
    onDocumentsChange({
      type: 'DELETE_DOCUMENT',
      payload: { typeId, docId }
    });
  };
  const filteredDataTypes = dataTypes.filter(type => 
    type.name.toLowerCase() !== 'foto de perfil'
  );
  if (isLoading) return <div>Cargando tipos de documentos...</div>;

  return (
<div className={`GM__${theme}-documents-section`}>
  {filteredDataTypes.map((type) => (
    <div key={type.id} className={`GM__${theme}-document-type-table`}>
      <div className={`GM__${theme}-table-header`}>
        <h3 className={`GM__${theme}-table-title`}>{type.name}</h3>
        <p className={`GM__${theme}-table-description`}>{type.description}</p>
        <button 
          className={`GM__${theme}-add-document-btn`}
          onClick={() => handleAddDocument(type.id)}
        >
          <img src={ArrowUpIcon} alt="Arrow Icon" className={`GM__${theme}-icon-image`} />
        </button>
      </div>

      <table className={`GM__${theme}-document-table`}>
        <thead className={`GM__${theme}-table-head`}>
          <tr>
            <th className={`GM__${theme}-table-header-cell`}>Estado</th>
            <th className={`GM__${theme}-table-header-cell`}>Archivo</th>
            <th className={`GM__${theme}-table-header-cell`}>Fecha de Expedición</th>
            <th className={`GM__${theme}-table-header-cell`}>Fecha de Vencimiento</th>
            <th className={`GM__${theme}-table-header-cell`}>Nombre/Descripción</th>
            <th className={`GM__${theme}-table-header-cell`}>Requerido</th>
            <th className={`GM__${theme}-table-header-cell`}>Acciones</th>
          </tr>
        </thead>
        <tbody className={`GM__${theme}-table-body`}>
          {documents.items
            .filter(doc => String(doc.docType) === String(type.id))
            .map((doc) => (
              <tr key={doc.id} className={`GM__${theme}-table-row`}>
                <td className={`GM__${theme}-table-cell GM__${theme}-status-cell`}>
                  {doc.file ? 
                    <img src={UploadSuccessIcon} alt="Upload Icon" className={`GM__${theme}-status-icon`} /> : 
                    <img src={LoadingIcon} alt="Loading icon" className={`GM__${theme}-status-icon`} />
                  }
                </td>
                <td className={`GM__${theme}-table-cell GM__${theme}-file-cell`}>
                  <div className={`GM__${theme}-file-upload`}>
                    <input
                      type="file"
                      onChange={(e) => handleFileSelect(type.id, doc.id, e)}
                      accept=".pdf,.jpg,.jpeg,.png"
                      id={`file-${doc.id}`}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor={`file-${doc.id}`} className={`GM__${theme}-file-label`}>
                      {doc.fileName || 'Seleccionar archivo'}
                    </label>
                  </div>
                </td>
                <td className={`GM__${theme}-table-cell GM__${theme}-date-cell`}>
                  <CustomDatePicker
                    value={doc.expeditionDate ? dayjs(doc.expeditionDate).toDate() : null}
                    onChange={(date) => handleUpdateDocument(
                      type.id,
                      doc.id,
                      'expeditionDate',
                      date
                    )}
                    icon={<img src={CalendarIcon} alt="Calendar Icon" />}
                    theme={theme}
                    className={`GM__${theme}-date-picker`}
                  />
                </td>
                <td className={`GM__${theme}-table-cell GM__${theme}-date-cell`}>
                  <CustomDatePicker
                    value={doc.expirationDate ? dayjs(doc.expirationDate).toDate() : null}
                    onChange={(date) => handleUpdateDocument(
                      type.id,
                      doc.id,
                      'expirationDate',
                      date
                    )}
                    icon={<img src={CalendarIcon} alt="Calendar Icon" />}
                    theme={theme}
                    className={`GM__${theme}-date-picker`}
                  />
                </td>
                <td className={`GM__${theme}-table-cell GM__${theme}-description-cell`}>
                  <CustomInputField
                    type="text"
                    value={doc.description}
                    onChange={(e) => handleUpdateDocument(
                      type.id,
                      doc.id,
                      'description',
                      e.target.value
                    )}
                    placeholder="Ej. Certificado de curso X"
                    theme={theme}
                    className={`GM__${theme}-input-field`}
                  />
                </td>
                <td className={`GM__${theme}-table-cell GM__${theme}-checkbox-cell`}>
                  <input
                    type="checkbox"
                    checked={doc.required}
                    onChange={(e) => handleUpdateDocument(
                      type.id,
                      doc.id,
                      'required',
                      e.target.checked
                    )}
                    className={`GM__${theme}-checkbox`}
                  />
                </td>
                <td className={`GM__${theme}-table-cell GM__${theme}-actions-cell`}>
                  <button
                    className={`GM__${theme}-delete-btn`}
                    onClick={() => handleDeleteDocument(type.id, doc.id)}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
            {documents.items.filter(doc => String(doc.docType) === String(type.id)).length === 0 && (
              <tr className={`GM__${theme}-empty-row`}>
                <td colSpan={7} className={`GM__${theme}-empty-message`}>
                  No hay documentos para este tipo
                </td>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  ))}
</div>
  );
}

DocumentsSectionAddPersons.propTypes = {
    roleId: PropTypes.string.isRequired,
    documents: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        fileName: PropTypes.string,
        file: PropTypes.object,
        expeditionDate: PropTypes.string,
        expirationDate: PropTypes.string,
        description: PropTypes.string,
        docType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
          ]),
        required: PropTypes.bool,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string
      })),
      types: PropTypes.array,
      deletedItems: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    onDocumentsChange: PropTypes.func.isRequired,
    dataTypes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      required: PropTypes.bool
    })),
    isLoading: PropTypes.bool
  };