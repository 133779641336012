import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useDocumentTypeService, usePhoneCountryCodeService } from "../service/userComplementaryService";
import { useMemo } from "react";

export const DOCUMENT_TYPES_KEY = "documentTypes";
export const PHONE_COUNTRY_CODES_KEY = "phoneCountryCodes";
export const useDocumentTypes = () => {
  const documentTypeService = useDocumentTypeService();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [DOCUMENT_TYPES_KEY],
    queryFn: () => documentTypeService.getAllDocumentTypes(),
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
  });

  // Memorizamos los documentTypes para evitar re-renders innecesarios
  const memoizedDocumentTypes = useMemo(() => {
    return query.data?.data || [];
  }, [query.data]);

  const createMutation = useMutation({
    mutationFn: (data) => documentTypeService.createDocumentType(data),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_KEY]);
    },
  });

  const updateMutation = useMutation({
    mutationFn: ({ id, data }) => documentTypeService.updateDocumentType(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_KEY]);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => documentTypeService.deleteDocumentType(id),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_KEY]);
    },
  });

  return {
    ...query,
    documentTypes: memoizedDocumentTypes,
    createDocumentType: createMutation.mutateAsync,
    updateDocumentType: updateMutation.mutateAsync,
    deleteDocumentType: deleteMutation.mutateAsync,
    isLoading: query.isLoading || createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading,
    isError: query.isError || createMutation.isError || updateMutation.isError || deleteMutation.isError,
  };
};
export const usePhoneCountryCodes = () => {
    const phoneCountryCodeService = usePhoneCountryCodeService();
    const queryClient = useQueryClient();
  
    const query = useQuery({
      queryKey: [PHONE_COUNTRY_CODES_KEY],
      queryFn: () => phoneCountryCodeService.getAllPhoneCountryCodes(),
      staleTime: 3 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchInterval: 5 * 60 * 1000,
      retry: 2,
    });
    const memoizedCountryCodes = useMemo(() => {
      return query.data?.data || [];
    }, [query.data]);

    const createMutation = useMutation({
      mutationFn: (data) => phoneCountryCodeService.createPhoneCountryCode(data),
      onSuccess: () => {
        queryClient.invalidateQueries([PHONE_COUNTRY_CODES_KEY]);
      },
    });
  
    const updateMutation = useMutation({
      mutationFn: ({ id, data }) => phoneCountryCodeService.updatePhoneCountryCode(id, data),
      onSuccess: () => {
        queryClient.invalidateQueries([PHONE_COUNTRY_CODES_KEY]);
      },
    });
  
    const deleteMutation = useMutation({
      mutationFn: (id) => phoneCountryCodeService.deletePhoneCountryCode(id),
      onSuccess: () => {
        queryClient.invalidateQueries([PHONE_COUNTRY_CODES_KEY]);
      },
    });
  
    return {
      ...query,
      countryCodes: memoizedCountryCodes,
      createPhoneCountryCode: createMutation.mutate,
      updatePhoneCountryCode: updateMutation.mutate,
      deletePhoneCountryCode: deleteMutation.mutate,
      isLoading: query.isLoading || createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading,
      isError: query.isError || createMutation.isError || updateMutation.isError || deleteMutation.isError,
    };
  };