import React, { memo } from "react";
import VehiclesCard from "./VehiclesCard";
import "../../../assets/scss/components/ui/cards/VehiclesCardWithTheme.scss";

const VehiclesCardWithTheme = (props) => {
  // Recibimos todas las props originales y añadimos la clase de tema
  const { theme = "dark", ...otherProps } = props;
  
  // Aplicamos la clase de tema al contenedor
  const themeClass = theme === "dark" ? "GM__dark-vehicle-card" : "GM__light-vehicle-card";
  
  return (
    <div className={themeClass}>
      <VehiclesCard {...otherProps} theme={theme} />
    </div>
  );
};

export default memo(VehiclesCardWithTheme);