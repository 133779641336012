import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorMessage from "../../components/common/ErrorMessage";
import BasicDataSection from "./sections/BasicDataSection";
import ExecutiveSummarySection from "./sections/ExecutiveSummarySection";
import RouteCharacteristicsSection from "./sections/RouteCharacteristicsSection";
import FixedCostsSection from "./sections/FixedCostsSection";
import VariableCostsSection from "./sections/VariableCostsSection";
import OtherCostsSection from "./sections/OtherCostsSection";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import "../../assets/scss/pages/TransportCostCalculator.scss";
import { useDocument } from "../../context/DocumentContext";
import { useLayout } from "../../context/LayoutContext";
import GeneralParametersSection from "./sections/GeneralParametersSection";

const TransportCostCalculator = () => {
  const { documentId } = useParams();
  const documentContext = useDocument();
  const [isCalculating, setIsCalculating] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [internalError, setInternalError] = useState(null);
  const { theme } = useLayout();
  const [recalculationState, setRecalculationState] = useState({
    topographyUpdated: false,
    topographyTimestamp: null,
    parametersUpdated: false,
    parametersTimestamp: null,
    costsUpdated: false,
    costsTimestamp: null,
  });

  const {
    document,
    loading,
    error,
    updateDocument,
    generatePdf,
    shareDocument,
    clearDocument,
  } = documentContext;

  useEffect(() => {}, [documentId, loading, error, document]);

  useEffect(() => {
    // Limpiar mensaje de éxito después de 3 segundos
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);
  useEffect(() => {
    if (recalculationState.topographyUpdated) {
      // La topografía se actualizó, las secciones dependientes se recalcularán automáticamente
      console.log("Topografía actualizada, recalculando parámetros...");

      // Restablecer flag después de un tiempo para evitar recálculos duplicados
      const timer = setTimeout(() => {
        setRecalculationState((prev) => ({
          ...prev,
          topographyUpdated: false,
        }));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [
    recalculationState.topographyUpdated,
    recalculationState.topographyTimestamp,
  ]);

  // Validar contexto antes de desestructurar
  if (!documentContext) {
    return (
      <ErrorMessage message="Error de configuración: Contexto no disponible" />
    );
  }

  const handleConsult = async () => {
    try {
      setIsCalculating(true);
      setInternalError(null);

      // Validar datos básicos antes de calcular
      if (!document?.basicData?.origin || !document?.basicData?.destination) {
        setInternalError("Por favor complete los campos obligatorios");
        setIsCalculating(false);
        return;
      }

      // Simulamos un tiempo de cálculo
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Actualizamos el estado del documento con los resultados calculados
      await updateDocument("executiveSummary", {
        totalCost: 5960844,
        costDistribution: {
          variables: 45,
          fixed: 46,
          others: 9,
        },
        keyData: {
          totalDistance: 832.22,
          duration: 12.57,
          tripsPerMonth: parseInt(document.basicData.tripsPerMonth) || 7,
          volumePerTrip: 11000,
        },
      });

      setSuccessMessage("Cálculo completado exitosamente");
    } catch (err) {
      setInternalError("Ocurrió un error durante el cálculo");
    } finally {
      setIsCalculating(false);
    }
  };

  const handleGeneratePdf = async () => {
    try {
      setInternalError(null);
      const pdfUrl = await generatePdf();
      if (pdfUrl) {
        window.open(pdfUrl, "_blank");
        setSuccessMessage("PDF generado exitosamente");
      }
    } catch (err) {
      setInternalError("No se pudo generar el PDF");
    }
  };

  const handleShareDocument = async () => {
    try {
      setInternalError(null);
      const shareUrl = await shareDocument();
      if (shareUrl) {
        navigator.clipboard.writeText(shareUrl);
        setSuccessMessage("Enlace copiado al portapapeles");
      }
    } catch (err) {
      setInternalError("No se pudo compartir el documento");
    }
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error || internalError) {
    return <ErrorMessage message={internalError || error} />;
  }

  if (!document) {
    return (
      <ErrorMessage message="No se pudo cargar el documento. Intente nuevamente." />
    );
  }

  const isReadOnly = documentId && document.isEditable === false;
  const containerClass =
    theme === "dark" ? "GM__dark-calculator-container" : "calculator-container";

  return (
    <div className={containerClass}>
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}

      {isCalculating && (
        <div className="calculating-overlay">
          <div className="calculating-content">
            <div className="spinner"></div>
            <p>Calculando costos...</p>
          </div>
        </div>
      )}

      <BasicDataSection
        data={document.basicData || {}}
        onUpdate={(data) => updateDocument("basicData", data)}
        onConsult={handleConsult}
        onClear={clearDocument}
        onGeneratePdf={handleGeneratePdf}
        onShare={handleShareDocument}
        isReadOnly={isReadOnly}
        hasResults={!!document.executiveSummary?.totalCost}
        theme={theme}
      />

      {document.executiveSummary?.totalCost && (
        <>
          <ExecutiveSummarySection
            data={document.executiveSummary}
            theme={theme}
          />

          <div className="calculator-results">
            <RouteCharacteristicsSection
              data={{
                ...document,
                coordinates: document.basicData?.coordinates,
                calculatedRoute: document.basicData?.calculatedRoute,
                vehicleConfig: document.basicData?.vehicleConfig,
                outboundCargoCondition:
                  document.basicData?.outboundCargoCondition,
                inboundCargoCondition:
                  document.basicData?.inboundCargoCondition,
                routeType: document.basicData?.routeType,
                tripsPerMonth: document.basicData?.tripsPerMonth,
                cargoQuantity: document.basicData?.cargoQuantity,
              }}
              onUpdate={(updatedData) => {
                if (updatedData && updatedData.routeCharacteristics) {
                  updateDocument(
                    "routeCharacteristics",
                    updatedData.routeCharacteristics
                  );

                  // Indicar que se ha actualizado la topografía para recalcular secciones dependientes
                  setRecalculationState((prev) => ({
                    ...prev,
                    topographyUpdated: true,
                    topographyTimestamp: Date.now(),
                  }));
                }
              }}
              theme={theme}
            />
            <GeneralParametersSection
              data={document.generalParameters}
              topographyData={{
                ...document.routeCharacteristics,
                vehicleConfig: document.basicData?.vehicleConfig,
                outboundCargoCondition:
                  document.basicData?.outboundCargoCondition,
                inboundCargoCondition:
                  document.basicData?.inboundCargoCondition,
                routeType: document.basicData?.routeType,
                tripsPerMonth: document.basicData?.tripsPerMonth,
                cargoQuantity: document.basicData?.cargoQuantity,
                calculatedRoute: document.basicData?.calculatedRoute,
              }}
              onUpdate={(updatedParams) => {
                updateDocument("generalParameters", updatedParams);

                // Indicar que se han actualizado los parámetros para recalcular costos
                setRecalculationState((prev) => ({
                  ...prev,
                  parametersUpdated: true,
                  parametersTimestamp: Date.now(),
                }));
              }}
              theme={theme}
            />
            <FixedCostsSection data={document.fixedCosts} theme={theme} />
            <VariableCostsSection data={document.variableCosts} theme={theme} />
            <OtherCostsSection
              data={document.otherCosts}
              finalCostVariable={document.finalCostVariable}
              finalCostFixed={document.finalCostFixed}
              totalFleetValue={document.totalFleetValue}
              theme={theme}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TransportCostCalculator;
