import { Routes, Route, Navigate } from "react-router-dom";
import DashboardThirdParty from "../page/thirdParty/DashboardThirdParty";
import DashboardVehicle from "../page/vehicle/view_vehicle/DashboardVehicle";
import DashboardAdmin from "../page/admin/DashboardAdmin";
import { useAuth } from "../context/AuthContext";
import ProtectedRoute from "./ProtectRoute";
import PublicDocumentRoute from "./PublicDocumentRoute";
import LoginSection from "../page/auth/section/Login";

import PasswordRecoverySection from "../page/auth/section/PasswordRecoverySection";
import RegisterSection from "../page/auth/section/RegisterSection";
import Trends from "../page/admin/Trends";
import Hotspots from "../page/admin/Hotspots";
import RepeatOffenders from "../page/admin/RepeatOffenders";
import DashboardCarrier from "../page/carriers/DashboardCarrier";
import UserView from "../page/admin/UserView";
import MessageView from "../page/MessageView";
import DocumentUploader from "../page/admin/DocumentUploader";
import Persons from "../page/admin/Persons";
import { PERSON_ROUTES, VEHICLES_ROUTES } from "./routes.config";
import Vehicles from "../page/vehicle/Vehicles";
import WsspConnection from "../page/WsspConnection";
import DashboardHumanResource from "../page/human_resources/DashboardHumanResource";
import DashboardMaps from "../page/maps/DashboardMaps";
import PublicRoute from "./PublicRoute";

// Nuevos componentes para rutas públicas
import PublicLayout from "../layout/PublicLayout";
import HomePage from "../page/public/HomePage.jsx";
import DocumentViewer from "../pages/transport/DocumentViewer";
import TransportCalculatorContainer from "../pages/transport/TransportCalculatorContainer";
import DashboardVehicleDocuments from "../page/vehicle/DashboardVehicleDocuments";
import Introduction from "../page/public/Introduction.jsx";

const AppRoutes = () => {
  const { user } = useAuth();

  const getDashboardComponent = (role) => {
    switch (role) {
      case 1:
        return <DashboardHumanResource />;
      case 2:
        return <DashboardVehicle />;
      case 3:
        return <DashboardThirdParty />;
      case 4:
        return <DashboardAdmin />;
      default:
        return <Navigate to="/" />;
    }
  };

  return (
    <Routes>
      {/* Rutas Públicas */}
      <Route path="/" element={
        <PublicLayout>
          <Introduction />
        </PublicLayout>
      } />
      
      
      {/* Rutas para cada sección */}
      <Route path="/servicios" element={
        <PublicLayout>
          <HomePage />
        </PublicLayout>
      } />
      <Route path="/acerca-de-nosotros" element={
        <PublicLayout>
          <HomePage />
        </PublicLayout>
      } />
      <Route path="/aliados" element={
        <PublicLayout>
          <HomePage />
        </PublicLayout>
      } />
      <Route path="/contacto" element={
        <PublicLayout>
          <HomePage />
        </PublicLayout>
      } />

      {/* Ruta de login */}
      <Route
        path="/login"
        element={
          <PublicRoute requiresNoAuth={true}>
            <LoginSection />
          </PublicRoute>
        }
      />
      {/* Rutas con autenticacion */}
      <Route
        path="/transport-calculator"
        element={
          <ProtectedRoute>
            <TransportCalculatorContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/transport-calculator/:documentId"
        element={
          <ProtectedRoute>
            <TransportCalculatorContainer />
          </ProtectedRoute>
        }
      />

      <Route
        path="/document/:documentId"
        element={
          <PublicDocumentRoute>
            <DocumentViewer />
          </PublicDocumentRoute>
        }
      />
      <Route
        path="/recoverey"
        element={
          <PublicRoute requiresNoAuth={true}>
            <PasswordRecoverySection />
          </PublicRoute>
        }
      />
      <Route
        path="/wssp-connection"
        element={
          <ProtectedRoute>
            <WsspConnection />
          </ProtectedRoute>
        }
      />
      {/* Administrador */}
      <Route
        path="/register"
        element={
          <ProtectedRoute>
            <RegisterSection />
          </ProtectedRoute>
        }
      />
      <Route
        path="/alerts-persons"
        element={
          <ProtectedRoute>
            <DashboardHumanResource />
          </ProtectedRoute>
        }
      />
      <Route
        path="/alerts-vehicles"
        element={
          <ProtectedRoute>
            <DashboardVehicleDocuments />
          </ProtectedRoute>
        }
      />
      <Route
        path="/geofences-travel-and-more"
        element={
          <ProtectedRoute>
            <DashboardMaps />
          </ProtectedRoute>
        }
      />
      {Object.entries(PERSON_ROUTES).map(([key, route]) => (
        <Route
          key={key}
          path={route.path}
          element={
            <ProtectedRoute>
              <Persons routeConfig={route} />
            </ProtectedRoute>
          }
        />
      ))}
      <Route
        path="/trends"
        element={
          <ProtectedRoute>
            <Trends />
          </ProtectedRoute>
        }
      />
      <Route
        path="/hotspots"
        element={
          <ProtectedRoute>
            <Hotspots />
          </ProtectedRoute>
        }
      />
      <Route
        path="/repeat-offenders"
        element={
          <ProtectedRoute>
            <RepeatOffenders />
          </ProtectedRoute>
        }
      />
      {Object.entries(VEHICLES_ROUTES).map(([key, route]) => (
        <Route
          key={key}
          path={route.path}
          element={
            <ProtectedRoute>
              <Vehicles routeConfig={route} />
            </ProtectedRoute>
          }
        />
      ))}
      <Route
        path="/carriers"
        element={
          <ProtectedRoute>
            <DashboardCarrier />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <UserView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/message"
        element={
          <ProtectedRoute>
            <MessageView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            {user ? getDashboardComponent(user.role_id) : <Navigate to="/" />}
          </ProtectedRoute>
        }
      />
      <Route
        path="/perfil"
        element={
          <ProtectedRoute>
            <div>perfil</div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/reportes"
        element={
          <ProtectedRoute>
            <div>reportes</div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/satellite-uploader"
        element={
          <ProtectedRoute>
            <DocumentUploader />
          </ProtectedRoute>
        }
      />

      {/*       <Route
        path="/agregar-info"
        element={
          <ProtectedRoute>
            <AgregarInfo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/reportes"
        element={
          <ProtectedRoute>
            <Reportes />
          </ProtectedRoute>
        }
      />

      <Route
        path="/perfil"
        element={
          <ProtectedRoute>
            <Perfil />
          </ProtectedRoute>
        }
      /> */}

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
