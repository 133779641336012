import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import EditIcon from '../../assets/svg/view/edit.svg'
import TrashIcon from '../../assets/svg/components/trash.svg'

export default function ChatMessage({
  message,
  currentUser,
  onReply,
  onEdit,
  onDelete
}) {
  const [showActions, setShowActions] = useState(false);
  const isOwnMessage = useMemo(() => {
    // Para chat global, verificar explícitamente el ID del remitente
    if (message.conversationId === 'global') {
      return message.sender.id === currentUser.id;
    }
    // Para chats normales, mantener la lógica existente
    return message.sender.id === currentUser.id;
  }, [message.sender.id, currentUser.id, message.conversationId]);
  console.log("hola");
  const renderAttachments = () => {
    if (!message.attachments?.length) return null;
  
    return (
      <div className="message-attachments">
        {message.attachments.map((attachment) => {
          // Determinar el tipo de archivo
          const isImage = attachment.mimeType.startsWith('image/');
          const isPDF = attachment.mimeType === 'application/pdf';
          const isDoc = attachment.mimeType.includes('word');
  
          if (isImage) {
            return (
              <div key={attachment.id} className="attachment-image-container">
                <img
                  src={attachment.path}
                  alt={attachment.originalName}
                  className="attachment-image"
                  onClick={() => window.open(attachment.path, '_blank')}
                />
                <span className="attachment-name">{attachment.originalName}</span>
              </div>
            );
          }
  
          // Para otros tipos de archivos
          return (
            <div key={attachment.id} className="attachment-file-container">
              <a
                href={attachment.path}
                target="_blank"
                rel="noopener noreferrer"
                className="attachment-file"
              >
                {isPDF && <img src="/icons/pdf-icon.svg" alt="PDF" />}
                {isDoc && <img src="/icons/doc-icon.svg" alt="DOC" />}
                <span className="attachment-name">{attachment.originalName}</span>
                <span className="attachment-size">
                  {(attachment.size / 1024).toFixed(1)} KB
                </span>
              </a>
            </div>
          );
        })}
      </div>
    );
  };
  

  return (
    <div
      className={`message-container ${isOwnMessage ? 'own-message' : ''}`}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      {message.reply_to && (
        <div className="replied-message">
          <span className="reply-author">
            {message.reply_to.sender?.first_name}
          </span>
          <p>{message.reply_to.content}</p>
        </div>
      )}

      <div className="message-content">
        {!isOwnMessage && (
          <span className="message-author">
            {message.sender.first_name} {message.sender.first_last_name}
          </span>
        )}

        <p className="message-text">
          {message.content}
          {message.is_edited && (
            <span className="edited-indicator"> (editado)</span>
          )}
        </p>

        {renderAttachments()}

        <span className="message-timestamp">
          {format(new Date(message.created_at), 'HH:mm', { locale: es })}
        </span>

        {showActions && (
          <div className="message-actions">
            <button onClick={() => onReply(message)}>
              <i className="fas fa-reply" />
            </button>
            {isOwnMessage && (
              <>
                <button onClick={() => onEdit(message)}>
                  <img src={EditIcon} alt="Edit Icon" />
                </button>
                <button onClick={() => onDelete(message.id)}>
                <img src={TrashIcon} alt="Trash Icon" />
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

ChatMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    is_edited: PropTypes.bool,
    sender: PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      first_last_name: PropTypes.string.isRequired,
    }).isRequired,
    reply_to: PropTypes.shape({
      content: PropTypes.string,
      sender: PropTypes.shape({
        first_name: PropTypes.string,
      }),
    }),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        file_path: PropTypes.string.isRequired,
        file_type: PropTypes.string.isRequired,
        original_filename: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onReply: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
