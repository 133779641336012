import { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useJsApiLoader } from "@react-google-maps/api";
import PDFIcon from "../../../assets/svg/view/pdf.svg";
import ClearIcon from "../../../assets/svg/components/trash.svg";
import ShareIcon from "../../../assets/svg/components/share.svg";
import LocationInput from "../../../components/ui/inputs/LocationInput";
import { 
  vehicleConfigurations, 
  bodyTypesByVehicle, 
  cargoTypesByBodyType, 
  cargoConditionsByType,
  calculateTransportCosts
} from "../../../lib/transportData";

import "../../../assets/scss/components/BasicDataSection.scss";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const libraries = ["places"];

// Caché para almacenar cálculos de rutas previos
const routeCache = new Map();

// Función para generar clave de caché basada en coordenadas
const getRouteCacheKey = (origin, destination) => {
  if (!origin || !destination) return null;
  return `${origin.lat},${origin.lng}|${destination.lat},${destination.lng}`;
};

const BasicDataSection = ({
  data,
  onUpdate,
  onConsult,
  onClear,
  onGeneratePdf,
  onShare,
  isReadOnly,
  hasResults,
  theme,
}) => {
  // Utilizar useJsApiLoader para gestionar la carga de la API
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });
  
  // Referencias para controlar flujo y actualizaciones
  const lastSyncedData = useRef({});
  const updateBackendTimer = useRef(null);
  const routeCalculationRef = useRef(false);
  const lastCalculationTime = useRef(0);
  
  // Estado principal del formulario
  const [formData, setFormData] = useState({
    origin: "",
    destination: "",
    routeType: "roundtrip",
    vehicleConfig: "",
    bodyType: "",
    cargoType: "",
    outboundCargoCondition: "",
    inboundCargoCondition: "",
    tripsPerMonth: "",
    cargoQuantity: "",
    departureDateTime: "",
    arrivalDateTime: "",
  });

  // Estado para las opciones dependientes
  const [availableBodyTypes, setAvailableBodyTypes] = useState([]);
  const [availableCargoTypes, setAvailableCargoTypes] = useState([]);
  const [availableCargoConditions, setAvailableCargoConditions] = useState([]);

  // Coordenadas y distancia/tiempo calculados
  const [coordinates, setCoordinates] = useState({
    origin: null,
    destination: null,
  });

  const [calculatedRoute, setCalculatedRoute] = useState({
    distance: null,
    duration: null,
    isCalculating: false,
  });

  // Función para determinar si hay cambios significativos
  const hasSignificantChanges = (newData, oldData) => {
    if (Object.keys(oldData).length === 0) return true;
    
    // Verificación original
    if (!oldData.origin && newData.origin) return true;
    if (!oldData.destination && newData.destination) return true;
    if (oldData.vehicleConfig !== newData.vehicleConfig) return true;
    if (oldData.bodyType !== newData.bodyType) return true;
    if (oldData.cargoType !== newData.cargoType) return true;
    if (oldData.routeType !== newData.routeType) return true;
    
    // Campos nuevos :v
    if (oldData.cargoQuantity !== newData.cargoQuantity) return true;
    if (oldData.outboundCargoCondition !== newData.outboundCargoCondition) return true;
    if (oldData.inboundCargoCondition !== newData.inboundCargoCondition) return true;
    if (oldData.departureDateTime !== newData.departureDateTime) return true;
    if (oldData.arrivalDateTime !== newData.arrivalDateTime) return true;
    if (oldData.tripsPerMonth !== newData.tripsPerMonth) return true;
    
    return false;
  };
  // Función para actualizar el backend con control de frecuencia
  const updateBackend = useCallback(() => {
    if (hasSignificantChanges(formData, lastSyncedData.current)) {
      // Limpiar temporizador previo si existe
      if (updateBackendTimer.current) {
        clearTimeout(updateBackendTimer.current);
      }
      
      // Programar actualización
      updateBackendTimer.current = setTimeout(() => {
        onUpdate({
          ...formData,
          coordinates,
          calculatedRoute
        });
        lastSyncedData.current = {...formData};
        updateBackendTimer.current = null;
      }, 1000); // Debounce de 1 segundo
    }
  }, [formData, coordinates, calculatedRoute, onUpdate]);
  
  // Efecto para actualizar backend cuando cambian datos importantes
  useEffect(() => {
    if (formData.origin || formData.destination || formData.vehicleConfig) {
      updateBackend();
    }
    
    // Limpiar temporizador al desmontar
    return () => {
      if (updateBackendTimer.current) {
        clearTimeout(updateBackendTimer.current);
      }
    };
  }, [formData, updateBackend]);

  // Sincronizar estado local con props
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setFormData({
        origin: data.origin || "",
        destination: data.destination || "",
        routeType: data.routeType || "roundtrip",
        vehicleConfig: data.vehicleConfig || "",
        bodyType: data.bodyType || "",
        cargoType: data.cargoType || "",
        outboundCargoCondition: data.outboundCargoCondition || data.cargoCondition || "",
        inboundCargoCondition: data.inboundCargoCondition || "",
        tripsPerMonth: data.tripsPerMonth || "",
        cargoQuantity: data.cargoQuantity || "",
        departureDateTime: data.departureDateTime || "",
        arrivalDateTime: data.arrivalDateTime || "",
      });

      if (data.coordinates) {
        setCoordinates(data.coordinates);
      }

      if (data.calculatedRoute) {
        setCalculatedRoute(data.calculatedRoute);
      }
      
      // Actualizar la referencia de datos sincronizados
      lastSyncedData.current = {...data};
    }
  }, [data]);

  // Actualizar opciones dependientes cuando cambia el vehículo
  useEffect(() => {
    if (formData.vehicleConfig) {
      const bodyTypes = bodyTypesByVehicle[formData.vehicleConfig] || [];
      setAvailableBodyTypes(bodyTypes);

      // Resetear opciones dependientes si ya no son válidas
      if (!bodyTypes.includes(formData.bodyType)) {
        setFormData((prev) => ({
          ...prev,
          bodyType: "",
          cargoType: "",
          outboundCargoCondition: "",
          inboundCargoCondition: "",
        }));
        setAvailableCargoTypes([]);
        setAvailableCargoConditions([]);
      }
    } else {
      setAvailableBodyTypes([]);
    }
  }, [formData.vehicleConfig]);

  // Actualizar opciones de tipo de carga cuando cambia la carrocería
  useEffect(() => {
    if (formData.bodyType) {
      const cargoTypes = cargoTypesByBodyType[formData.bodyType] || [];
      setAvailableCargoTypes(cargoTypes);

      // Resetear opciones dependientes si ya no son válidas
      if (!cargoTypes.includes(formData.cargoType)) {
        setFormData((prev) => ({
          ...prev,
          cargoType: "",
          outboundCargoCondition: "",
          inboundCargoCondition: "",
        }));
        setAvailableCargoConditions([]);
      }
    } else {
      setAvailableCargoTypes([]);
    }
  }, [formData.bodyType]);

  // Actualizar opciones de condición de carga cuando cambia el tipo de carga
  useEffect(() => {
    if (formData.cargoType) {
      const cargoConditions = cargoConditionsByType[formData.cargoType] || [];
      setAvailableCargoConditions(cargoConditions);

      // Resetear si ya no son válidas
      if (!cargoConditions.includes(formData.outboundCargoCondition)) {
        setFormData((prev) => ({
          ...prev,
          outboundCargoCondition: "",
        }));
      }

      if (!cargoConditions.includes(formData.inboundCargoCondition)) {
        setFormData((prev) => ({
          ...prev,
          inboundCargoCondition: "",
        }));
      }
    } else {
      setAvailableCargoConditions([]);
    }
  }, [formData.cargoType]);

  // Función optimizada para calcular rutas
  const calculateRoute = useCallback(() => {
    // Verificar si tenemos la API cargada
    if (!isLoaded || loadError || !window.google || !window.google.maps) {
      console.log("Google Maps API no está disponible aún");
      return;
    }
    
    // Verificar si tenemos coordenadas
    if (!coordinates.origin || !coordinates.destination) {
      return;
    }
    
    // Verificar si ya estamos calculando
    if (routeCalculationRef.current) {
      return;
    }
    
    // Controlar frecuencia de cálculos (máximo cada 2 segundos)
    const now = Date.now();
    if (now - lastCalculationTime.current < 2000) {
      return;
    }
    
    // Verificar caché primero
    const cacheKey = getRouteCacheKey(coordinates.origin, coordinates.destination);
    if (cacheKey && routeCache.has(cacheKey)) {
      const cachedRoute = routeCache.get(cacheKey);
      setCalculatedRoute(cachedRoute);
      return;
    }
    
    // Iniciar cálculo de ruta
    routeCalculationRef.current = true;
    setCalculatedRoute((prev) => ({ ...prev, isCalculating: true }));
    lastCalculationTime.current = now;
    
    try {
      const directionsService = new window.google.maps.DirectionsService();
      
      directionsService.route(
        {
          origin: coordinates.origin,
          destination: coordinates.destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          routeCalculationRef.current = false;
          
          if (status === "OK") {
            const route = response.routes[0];
            const leg = route.legs[0];
            
            const routeData = {
              distance: leg.distance.value / 1000, // km
              duration: leg.duration.value / 3600, // horas
              isCalculating: false,
            };
            
            // Guardar en caché
            if (cacheKey) {
              routeCache.set(cacheKey, routeData);
            }
            
            setCalculatedRoute(routeData);
            
            // Actualizar la hora de llegada si hay una hora de salida establecida
            if (formData.departureDateTime) {
              const departureTime = new Date(formData.departureDateTime);
              const arrivalTime = new Date(
                departureTime.getTime() + routeData.duration * 60 * 60 * 1000
              );
              
              setFormData(prev => ({
                ...prev,
                arrivalDateTime: arrivalTime.toISOString().slice(0, 16)
              }));
            }
          } else {
            console.error("Error calculating route:", status);
            setCalculatedRoute({
              distance: null,
              duration: null,
              isCalculating: false,
            });
          }
        }
      );
    } catch (error) {
      console.error("Error al calcular ruta:", error);
      routeCalculationRef.current = false;
      setCalculatedRoute({
        distance: null,
        duration: null,
        isCalculating: false,
      });
    }
  }, [coordinates, isLoaded, loadError, formData.departureDateTime]);

  // Efecto para calcular ruta cuando cambian las coordenadas
  useEffect(() => {
    if (coordinates.origin && coordinates.destination) {
      // Usar setTimeout para evitar llamadas inmediatas
      const timer = setTimeout(() => {
        calculateRoute();
      }, 1000);
      
      return () => clearTimeout(timer);
    }
  }, [coordinates, calculateRoute]);

  // Manejar cambios en el formulario
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevData => {
      const updatedData = { ...prevData, [name]: value };
      
      // Lógica especial para el caso de cambio en la hora de salida
      if (name === "departureDateTime" && calculatedRoute.duration) {
        const departureTime = new Date(value);
        const arrivalTime = new Date(
          departureTime.getTime() + calculatedRoute.duration * 60 * 60 * 1000
        );

        updatedData.arrivalDateTime = arrivalTime.toISOString().slice(0, 16);
      }

      // Lógica para cuando se cambia el tipo de ruta
      if (name === "routeType" && value === "oneway") {
        updatedData.inboundCargoCondition = "";
      }
      
      return updatedData;
    });
  }, [calculatedRoute.duration]);

  // Manejar cambios en las coordenadas
  const handleCoordinatesChange = useCallback((fieldName, coords) => {
    setCoordinates(prev => {
      // No actualizar si son las mismas coordenadas
      if (prev[fieldName] && coords &&
          prev[fieldName].lat === coords.lat && 
          prev[fieldName].lng === coords.lng) {
        return prev;
      }
      
      // Reiniciar el flag de cálculo de ruta para forzar recálculo
      routeCalculationRef.current = false;
      
      return { ...prev, [fieldName]: coords };
    });
  }, []);

  // Manejar envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!calculatedRoute.distance) {
      alert(
        "No se ha podido calcular la distancia entre los puntos. Por favor, verifica el origen y destino."
      );
      return;
    }

    // Calcular costos
    const costs = calculateTransportCosts({
      vehicleConfig: formData.vehicleConfig,
      bodyType: formData.bodyType,
      cargoType: formData.cargoType,
      outboundCargoCondition: formData.outboundCargoCondition,
      inboundCargoCondition: formData.inboundCargoCondition,
      distance: calculatedRoute.distance,
      estimatedDuration: calculatedRoute.duration,
      departureDateTime: formData.departureDateTime,
      arrivalDateTime: formData.arrivalDateTime,
      tripsPerMonth: formData.tripsPerMonth,
      routeType: formData.routeType,
    });

    // Pasar los datos completos incluyendo coordenadas y costos calculados
    onConsult({
      ...formData,
      coordinates,
      calculatedRoute,
      transportCosts: costs,
    });
  };

  // Validación del formulario
  const isFormValid = () => {
    const baseValidation =
      formData.origin &&
      formData.destination &&
      formData.vehicleConfig &&
      formData.bodyType &&
      formData.cargoType &&
      formData.outboundCargoCondition &&
      formData.cargoQuantity &&
      formData.tripsPerMonth &&
      coordinates.origin &&
      coordinates.destination &&
      calculatedRoute.distance;

    // Validación adicional para viajes de ida y vuelta
    if (formData.routeType === "roundtrip") {
      return baseValidation && formData.inboundCargoCondition;
    }

    return baseValidation;
  };

  // Formatear fecha y hora para los inputs
  const formatDateForInput = () => {
    const now = new Date();
    // Redondear a la siguiente hora
    now.setHours(now.getHours() + 1);
    now.setMinutes(0, 0, 0);
    return now.toISOString().slice(0, 16); // Formato YYYY-MM-DDTHH:MM
  };

  // Mostrar mensaje de error si la carga de API falla
  if (loadError) {
    return (
      <div className="api-loading-error">
        Error al cargar Google Maps API: {loadError.message}
      </div>
    );
  }

  return (
    <div className={`GM__${theme}-basic-data-section`}>
      <h2 className={`GM__${theme}-section-title`}>
        Datos básicos para generar ruta y calcular costos
      </h2>

      <form onSubmit={handleSubmit} className={`GM__${theme}-data-form`}>
        {/* Primera fila - Origen, Destino y Tipo de Recorrido */}
        <div className={`GM__${theme}-form-row`}>
          <LocationInput
            name="origin"
            value={formData.origin}
            onChange={handleChange}
            placeholder="Ej. Cali, Colombia"
            label="Origen"
            disabled={isReadOnly || !isLoaded}
            required
            theme={theme}
            onCoordinatesChange={(field, coords) => handleCoordinatesChange("origin", coords)}
            googleMapsLoaded={isLoaded}
          />

          <LocationInput
            name="destination"
            value={formData.destination}
            onChange={handleChange}
            placeholder="Ej. Bogotá, Colombia"
            label="Destino"
            disabled={isReadOnly || !isLoaded}
            required
            theme={theme}
            onCoordinatesChange={(field, coords) => handleCoordinatesChange("destination", coords)}
            googleMapsLoaded={isLoaded}
          />

          <div className={`GM__${theme}-input-group`}>
            <label htmlFor="routeType" className={`GM__${theme}-input-label`}>
              Recorrido
            </label>
            <select
              id="routeType"
              name="routeType"
              value={formData.routeType}
              onChange={handleChange}
              disabled={isReadOnly}
              className={`GM__${theme}-select`}
            >
              <option value="roundtrip">Ida y regreso</option>
              <option value="oneway">Solo ida</option>
            </select>
          </div>

          <div className={`GM__${theme}-input-group`}>
            <label
              htmlFor="tripsPerMonth"
              className={`GM__${theme}-input-label`}
            >
              *Viajes por mes
            </label>
            <input
              type="number"
              id="tripsPerMonth"
              name="tripsPerMonth"
              value={formData.tripsPerMonth}
              onChange={handleChange}
              placeholder="123"
              disabled={isReadOnly}
              className={`GM__${theme}-input`}
              required
            />
          </div>
        </div>

        {/* Segunda fila - Configuración de vehículo y carrocería */}
        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-input-group`}>
            <label
              htmlFor="vehicleConfig"
              className={`GM__${theme}-input-label`}
            >
              *Configuración de vehículo
            </label>
            <select
              id="vehicleConfig"
              name="vehicleConfig"
              value={formData.vehicleConfig}
              onChange={handleChange}
              disabled={isReadOnly}
              className={`GM__${theme}-select`}
              required
            >
              <option value="">Seleccione...</option>
              {vehicleConfigurations.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.id}>
                  {vehicle.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className={`GM__${theme}-input-group`}>
            <label htmlFor="bodyType" className={`GM__${theme}-input-label`}>
              *Tipo de carrocería
            </label>
            <select
              id="bodyType"
              name="bodyType"
              value={formData.bodyType}
              onChange={handleChange}
              disabled={isReadOnly || !formData.vehicleConfig}
              className={`GM__${theme}-select`}
              required
            >
              <option value="">Seleccione...</option>
              {availableBodyTypes.map((bodyType) => (
                <option key={bodyType} value={bodyType}>
                  {bodyType}
                </option>
              ))}
            </select>
          </div>

          <div className={`GM__${theme}-input-group`}>
            <label htmlFor="cargoType" className={`GM__${theme}-input-label`}>
              *Tipo de carga
            </label>
            <select
              id="cargoType"
              name="cargoType"
              value={formData.cargoType}
              onChange={handleChange}
              disabled={isReadOnly || !formData.bodyType}
              className={`GM__${theme}-select`}
              required
            >
              <option value="">Seleccione...</option>
              {availableCargoTypes.map((cargoType) => (
                <option key={cargoType} value={cargoType}>
                  {cargoType}
                </option>
              ))}
            </select>
          </div>

          <div className={`GM__${theme}-input-group`}>
            <label
              htmlFor="cargoQuantity"
              className={`GM__${theme}-input-label`}
            >
              *Cantidad de carga
            </label>
            <input
              type="text"
              id="cargoQuantity"
              name="cargoQuantity"
              value={formData.cargoQuantity}
              onChange={handleChange}
              placeholder="Toneladas/litros/unidades"
              disabled={isReadOnly}
              className={`GM__${theme}-input`}
              required
            />
          </div>
        </div>

        {/* Tercera fila - Condición de carga ida/vuelta y fechas */}
        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-input-group`}>
            <label
              htmlFor="outboundCargoCondition"
              className={`GM__${theme}-input-label`}
            >
              *Condición carga (Ida)
            </label>
            <select
              id="outboundCargoCondition"
              name="outboundCargoCondition"
              value={formData.outboundCargoCondition}
              onChange={handleChange}
              disabled={isReadOnly || !formData.cargoType}
              className={`GM__${theme}-select`}
              required
            >
              <option value="">Seleccione...</option>
              {availableCargoConditions.map((condition) => (
                <option key={condition} value={condition}>
                  {condition}
                </option>
              ))}
            </select>
          </div>

          {formData.routeType === "roundtrip" && (
            <div className={`GM__${theme}-input-group`}>
              <label
                htmlFor="inboundCargoCondition"
                className={`GM__${theme}-input-label`}
              >
                *Condición carga (Regreso)
              </label>
              <select
                id="inboundCargoCondition"
                name="inboundCargoCondition"
                value={formData.inboundCargoCondition}
                onChange={handleChange}
                disabled={isReadOnly || !formData.cargoType}
                className={`GM__${theme}-select`}
                required
              >
                <option value="">Seleccione...</option>
                {availableCargoConditions.map((condition) => (
                  <option key={condition} value={condition}>
                    {condition}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className={`GM__${theme}-input-group`}>
            <label
              htmlFor="departureDateTime"
              className={`GM__${theme}-input-label`}
            >
              Fecha/hora de salida
            </label>
            <input
              type="datetime-local"
              id="departureDateTime"
              name="departureDateTime"
              value={formData.departureDateTime}
              onChange={handleChange}
              min={formatDateForInput()}
              disabled={isReadOnly}
              className={`GM__${theme}-input`}
            />
          </div>

          <div className={`GM__${theme}-input-group`}>
            <label
              htmlFor="arrivalDateTime"
              className={`GM__${theme}-input-label`}
            >
              Fecha/hora de llegada est.
            </label>
            <input
              type="datetime-local"
              id="arrivalDateTime"
              name="arrivalDateTime"
              value={formData.arrivalDateTime}
              onChange={handleChange}
              min={formData.departureDateTime || formatDateForInput()}
              disabled={
                isReadOnly ||
                (!formData.departureDateTime && !calculatedRoute.duration)
              }
              className={`GM__${theme}-input`}
            />
          </div>
        </div>

        {/* Cuarta fila - Información calculada y botones */}
        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-input-group`}>
            <label className={`GM__${theme}-input-label`}>
              Distancia calculada
            </label>
            <div className={`GM__${theme}-static-field`}>
              {calculatedRoute.isCalculating
                ? "Calculando..."
                : calculatedRoute.distance
                ? `${calculatedRoute.distance.toFixed(2)} km`
                : "Pendiente"}
            </div>
          </div>

          <div className={`GM__${theme}-input-group`}>
            <label className={`GM__${theme}-input-label`}>
              Tiempo estimado
            </label>
            <div className={`GM__${theme}-static-field`}>
              {calculatedRoute.isCalculating
                ? "Calculando..."
                : calculatedRoute.duration
                ? `${calculatedRoute.duration.toFixed(2)} horas`
                : "Pendiente"}
            </div>
          </div>

          <div className={`GM__${theme}-form-actions-container`}>
            <div className={`GM__${theme}-form-actions`}>
              {!hasResults ? (
                <button
                  type="submit"
                  className={`GM__${theme}-button GM__${theme}-consult-button`}
                  disabled={
                    !isFormValid() ||
                    isReadOnly ||
                    calculatedRoute.isCalculating
                  }
                >
                  Calcular Costos
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className={`GM__${theme}-button GM__${theme}-clear-button`}
                    onClick={onClear}
                    disabled={isReadOnly}
                  >
                    <img src={ClearIcon} alt="Limpiar datos" />
                  </button>
                  <button
                    type="button"
                    className={`GM__${theme}-button GM__${theme}-pdf-button`}
                    onClick={onGeneratePdf}
                  >
                    <img src={PDFIcon} alt="Generar PDF" />
                  </button>
                  <button
                    type="button"
                    className={`GM__${theme}-button GM__${theme}-share-button`}
                    onClick={onShare}
                  >
                    <img src={ShareIcon} alt="Compartir" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

BasicDataSection.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  onConsult: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onGeneratePdf: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  hasResults: PropTypes.bool,
  theme: PropTypes.string,
};

export default BasicDataSection;