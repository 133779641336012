import React, { useEffect, useState } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import { VEHICLES_ROUTES } from "../../routes/routes.config";
import PropTypes from "prop-types";
import DashboardVehicle from "./view_vehicle/DashboardVehicle";
import VehicleDatils from "./view_vehicle/VehicleDatils";
import UpdateVehicle from "./view_vehicle/UpdateVehicle";
import AddVehicle from "./view_vehicle/AddVehicle";
import { SettingVehicle } from "./view_vehicle/SettingVehicle";
import RegisterSection from "../auth/section/RegisterSection";
import { useGetRoles } from "../../hooks/useReactQuery";
import ProfileUser from "../ProfileUser";
import "../../assets/scss/views/Vehicles.scss";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useLayout } from "../../context/LayoutContext";

export default function Vehicles({ routeConfig }) {
  const location = useLocation();
  const { id } = useParams();
  const { setHeaderTitle, isHeaderVisible, theme, setIsHeaderVisible } = useLayout();
  const [lastScrollY, setLastScrollY] = useState(0);
  
  // Manejo de roles
  const { data, isLoading, error } = useGetRoles();
  const roles = data?.data || [];

  // Determinar si la ruta actual requiere validación de rol
  const isRoleRequiredRoute = location.pathname.includes("add/user") && id;
  const isOwnerRoute = location.pathname.includes("owner") && id;

  const role = isRoleRequiredRoute ? roles.find((r) => r.id.toString() === id) : null;
  const roleName = isRoleRequiredRoute ? (role ? role.name : "Rol no encontrado") : null;

  // -------------------------------------------------------------
  // Título del header
  // -------------------------------------------------------------
  const getHeaderTitle = () => {
    if (!routeConfig) {
      let currentRoute = Object.values(VEHICLES_ROUTES).find(
        (route) => route.path === location.pathname
      );
      if (!currentRoute) {
        currentRoute = Object.values(VEHICLES_ROUTES).find((route) => {
          const dynamicPattern = route.path.replace(/:id/, id || "");
          return location.pathname === dynamicPattern;
        });
      }
      return currentRoute?.title || "Vehículos";
    }
    return routeConfig.title;
  };
  
  const headerTitle = getHeaderTitle();

  // -------------------------------------------------------------
  // Breadcrumbs
  // -------------------------------------------------------------
  const getNavigationParts = () => {
    if (location.pathname === VEHICLES_ROUTES.main.path) {
      return ["Vehículos"];
    }

    let baseRoute = null;

    if (location.pathname === VEHICLES_ROUTES.add.path) {
      baseRoute = VEHICLES_ROUTES.add;
    } else if (location.pathname === VEHICLES_ROUTES.setting.path) {
      baseRoute = VEHICLES_ROUTES.setting;
    } else if (location.pathname.includes("update") && id) {
      baseRoute = VEHICLES_ROUTES.updated;
    } else if (location.pathname.includes("add/user") && id) {
      baseRoute = VEHICLES_ROUTES.addOwner;
    } else if (location.pathname.includes("owner") && id) {
      baseRoute = VEHICLES_ROUTES.owner;
    } else if (id && !location.pathname.includes("update")) {
      baseRoute = VEHICLES_ROUTES.vechicle;
    }

    if (!baseRoute) {
      return ["Vehículos"];
    }

    if (id && baseRoute.subtitle) {
      const parts = baseRoute.subtitle.split("/").map((part) => {
        if (part === "{id}") {
          if (isRoleRequiredRoute) {
            return roleName;
          }
          if (isOwnerRoute) {
            return `Perfil`;
          }
          return id;
        }
        return part;
      });
      return parts;
    }

    if (baseRoute.subtitle) {
      return baseRoute.subtitle.split("/");
    }

    if (baseRoute.title) {
      return ["Vehículos", baseRoute.title];
    }

    return ["Vehículos"];
  };

  // -------------------------------------------------------------
  // Ruta anterior
  // -------------------------------------------------------------
  const getPreviousPath = () => {
    if (id) {
      if (location.pathname.includes("update")) {
        return VEHICLES_ROUTES.vechicle.path.replace(":id", id);
      }
      if (location.pathname.includes("add/user")) {
        return VEHICLES_ROUTES.main.path;
      }
      if (location.pathname.includes("owner")) {
        return VEHICLES_ROUTES.vechicle.path.replace(":id", id);
      }
      return VEHICLES_ROUTES.main.path;
    }

    const parts = location.pathname.split("/");
    return parts.slice(0, -1).join("/") || "/";
  };

  // -------------------------------------------------------------
  // Contenido específico según la ruta
  // -------------------------------------------------------------
  const getRouteSpecificContent = () => {
    if (isLoading) {
      return <LoadingAnimation />;
    }

    if (error) {
      return <div>Error al cargar los roles</div>;
    }

    if (isRoleRequiredRoute && !role) {
      return <div>El rol no se encuentra vigente.</div>;
    }

    if (location.pathname === VEHICLES_ROUTES.add.path) {
      return <AddVehicle />;
    } else if (location.pathname === VEHICLES_ROUTES.setting.path) {
      return <SettingVehicle />;
    } else if (location.pathname.includes("add/user") && id) {
      return <RegisterSection id={id} />;
    } else if (location.pathname.includes("owner") && id) {
      return <ProfileUser id={id} />;
    }

    if (id) {
      if (location.pathname.includes("update")) {
        return <UpdateVehicle id={id} />;
      }
      return <VehicleDatils id={id} />;
    }

    return <DashboardVehicle />;
  };

  // Establecer el título del header
  useEffect(() => {
    setHeaderTitle(headerTitle);
  }, [headerTitle, setHeaderTitle]);
  
  // Manejo de scroll con debounce
  useEffect(() => {
    const handleScroll = (event) => {
      const container = event.target;
      const currentScrollY = container.scrollTop;
      const isScrollingUp = currentScrollY < lastScrollY;

      // Constantes para los umbrales
      const SCROLL_THRESHOLD = 100;
      const SHOW_HEADER_THRESHOLD = 50;

      if (!isScrollingUp && currentScrollY > SCROLL_THRESHOLD) {
        setIsHeaderVisible(false);
      } else if (currentScrollY <= SHOW_HEADER_THRESHOLD) {
        setIsHeaderVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    // Optimización con debounce
    const debouncedHandleScroll = debounce(handleScroll, 16);

    // Actualizamos el selector para trabajar con cualquier tema
    const contentContainers = document.querySelectorAll(
      '[class*="-vehicles-content"]'
    );

    contentContainers.forEach((container) => {
      container.addEventListener("scroll", debouncedHandleScroll, {
        passive: true,
      });
    });

    return () => {
      contentContainers.forEach((container) => {
        container.removeEventListener("scroll", debouncedHandleScroll);
      });
    };
  }, [lastScrollY, setIsHeaderVisible]);
  
  // Función de utilidad para debounce
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const navigationParts = getNavigationParts();
  const isMainRoute = location.pathname === VEHICLES_ROUTES.main.path;

  return (
    <div className={`GM__${theme}-vehicles-container`}>
      <header 
        className={`GM__${theme}-vehicles-header ${
          !isHeaderVisible ? "header-hidden" : ""
        }`}
      >
        <h1 className={`GM__${theme}-vehicles-title`}>{getHeaderTitle()}</h1>

        <nav className={`GM__${theme}-vehicles-navigation`}>
          {isMainRoute ? (
            <span className={`GM__${theme}-navigation-item-current`}>
              {navigationParts[0]}
            </span>
          ) : (
            <>
              {navigationParts.map((part, index) => (
                <React.Fragment key={`${index}-${part}`}>
                  {index > 0 && (
                    <span className={`GM__${theme}-navigation-separator`}>
                      /
                    </span>
                  )}
                  {index === navigationParts.length - 1 ? (
                    <span className={`GM__${theme}-navigation-item-current`}>
                      {part}
                    </span>
                  ) : (
                    <Link
                      to={
                        index === 0
                          ? VEHICLES_ROUTES.main.path
                          : getPreviousPath()
                      }
                      className={`GM__${theme}-navigation-item-clickable`}
                    >
                      {part}
                    </Link>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </nav>

        <div className={`GM__${theme}-header-divider`} />
      </header>

      <main 
        className={`GM__${theme}-vehicles-content ${
          !isHeaderVisible ? "header-hidden" : ""
        }`}
      >
        {getRouteSpecificContent()}
      </main>
    </div>
  );
}

Vehicles.propTypes = {
  routeConfig: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};