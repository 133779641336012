import React from "react";

function LoadingAnimation() {
  return (
    <div className="loading-animation">
      <div className="loading-animation__sprite"/>
    </div>
  );
}

export default LoadingAnimation;