import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Tabs, Tab, Box } from "@mui/material";

import PaginationComponent from "../../../components/ui/pagination/PaginationComponent";
import ButtonDinamic from "../../../components/ui/btn/ButtonDinamic";
import AddIcon from "../../../assets/svg/components/more_cirecle.svg";
import SettingsIcon from "../../../assets/svg/view/settings.svg";
import "../../../assets/scss/views/DashboardVehicle.scss";
import SearchMini from "../../../components/ui/search/SearchMini";
import SortDropdown from "../../../components/ui/dropdawn/SortDropdown";
import { useVehicles } from "../../../hooks/useVehicleQuery";
import { useLayout } from "../../../context/LayoutContext";
import VehiclesCardWithTheme from "../../../components/ui/cards/VehiclesCardWithTheme";

export default function DashboardVehicle() {
  const navigate = useNavigate();
  const { theme } = useLayout();
  
  // Estado para filtros y paginación (mantener igual)
  const [selectedPage, setSelectedPage] = useState(1);
  const [vehiclesPerPage] = useState(8);
  const [filter, setFilter] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  // Obtener datos (mantener igual)
  const { data: dataVehicles, isLoading } = useVehicles(
    selectedPage, 
    vehiclesPerPage, 
    filter,
    sortOption
  );

  // Helpers y handlers (mantener igual)
  const handlePageChange = (page) => setSelectedPage(page);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedPage(1);
  };

  const handleNavigateToVehicle = () => navigate("/vehicles/add/vehicle");
  const handleNavigateToSettings = () => navigate("/vehicles/setting/data");

  // Determinar datos basados en la pestaña activa (mantener igual)
  const vehicles = activeTab === 0 
    ? dataVehicles?.normalVehicles?.vehicles || []
    : dataVehicles?.combinedVehicles?.vehicles || [];
  
  const totalVehicles = activeTab === 0
    ? dataVehicles?.normalVehicles?.totalVehicles || 0
    : dataVehicles?.combinedVehicles?.totalVehicles || 0;

  // Clase CSS dinámica basada en el tema
  const themeClass = theme === 'dark' ? 'GM__dark-dashboard-vehicle' : 'GM__light-dashboard-vehicle';

  return (
    <div className={themeClass}>
      <header>
        <div className="header-left">
          <SearchMini
            placeholder="Buscar por matrícula o tipo de vehículo"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            theme={theme}
          />
        </div>

        <div className="header-right">
          <SortDropdown
            value={sortOption}
            onChange={(val) => setSortOption(val)}
            options={[
              { label: "Placa A-Z", value: "placa_asc" },
              { label: "Placa Z-A", value: "placa_desc" },
              { label: "Conductor A-Z", value: "conductor_asc" },
              { label: "Propietario A-Z", value: "propietario_asc" },
              { label: "Activos", value: "activos" },
              { label: "Listo", value: "listo" },
            ]}
            theme={theme}
          />

          <ButtonDinamic
            type="medium"
            theme={theme}
            disabled={false}
            onClick={handleNavigateToVehicle}
            iconOne={AddIcon}
          >
            {isLoading ? "Cargando..." : "Nuevo vehículo"}
          </ButtonDinamic>

          <Tooltip title="Configuración de vehículos" arrow placement="top">
            <div className="settings-icon-wrapper" onClick={handleNavigateToSettings}>
              <img src={SettingsIcon} alt="Configuración" className="settings-icon" />
            </div>
          </Tooltip>
        </div>
      </header>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab label="Vehículos Individuales" />
          <Tab label="Cabezote + Remolque" />
        </Tabs>
      </Box>

      <main>
        {isLoading ? (
          <div className="loading-indicator">Cargando...</div>
        ) : vehicles.length > 0 ? (
          <div className="vehicles-grid">
            {vehicles.map((vehicle) => (
              activeTab === 0 ? (
                <VehiclesCardWithTheme
                  key={vehicle.id}
                  id={vehicle.id}
                  plate={vehicle.plate}
                  vehicleType={vehicle.vehicle_type_name}
                  color={vehicle.color}
                  operatorName={vehicle.operator_id ? "Operador asignado" : "Sin operador"}
                  vehicleImage={`/vehicles/${vehicle.id}/image`}
                  theme={theme}
                />
              ) : (
                <VehiclesCardWithTheme
                  key={vehicle.id}
                  id={vehicle.id}
                  vehiclePlate={vehicle.vehicle_plate}
                  vehicleType={vehicle.vehicle_type_name}
                  vehicleColor={vehicle.vehicle_color}
                  trailerPlate={vehicle.trailer_plate}
                  trailerType={vehicle.trailer_type_name}
                  trailerColor={vehicle.trailer_color}
                  operatorName={vehicle.operator_id ? "Operador asignado" : "Sin operador"}
                  vehicleImage={`/vehicles/${vehicle.vehicle_id}/image`}
                  trailerImage={`/vehicles/${vehicle.trailer_id}/image`}
                  theme={theme}
                  vehicle_id={vehicle.vehicle_id}
                  trailer_id={vehicle.trailer_id}
                />
              )
            ))}
          </div>
        ) : (
          <div className="no-results">No se encontraron vehículos</div>
        )}
      </main>

      <footer>
        <PaginationComponent
          currentPage={selectedPage}
          totalPages={Math.ceil(totalVehicles / vehiclesPerPage)}
          onPageChange={handlePageChange}
          theme={theme}
        />
      </footer>
    </div>
  );
}