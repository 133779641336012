import React, { useState, useEffect } from "react";
import { Modal, Pagination, Switch } from "@mui/material";
import PropTypes from "prop-types";
import {
  useVehicleTypes,
  useCourses,
  useCoursesByVehicleType,
  useAssociateCourseToVehicleType
} from "../../../hooks/useVehicleQuery";
import { useNotification } from "../../../context/NotificationContext";
import { useLayout } from "../../../context/LayoutContext";
import LoadingAnimation from "../../loading/LoadingAnimation";

export function CourseRequirementsManager() {
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [showAddModal, setShowAddModal] = useState(false);

  const { theme } = useLayout();
  const { showNotification } = useNotification();

  // Obtener todos los tipos de vehículo
  const { data: vehicleTypesData, isLoading: loadingVehicleTypes } = useVehicleTypes();
  const vehicleTypes = vehicleTypesData?.data || [];

  // Obtener cursos asociados al tipo de vehículo seleccionado
  const { 
    data: associatedCoursesData, 
    isLoading: loadingAssociatedCourses,
    refetch: refetchAssociatedCourses
  } = useCoursesByVehicleType(selectedVehicleType?.id);
  
  const associatedCourses = associatedCoursesData?.data || [];

  // Obtener todos los cursos disponibles para añadir
  const { data: allCoursesData, isLoading: loadingAllCourses } = useCourses();
  const allCourses = allCoursesData?.data || [];

  // Mutación para asociar cursos
  const associateMutation = useAssociateCourseToVehicleType();

  // Efecto para seleccionar el primer tipo de vehículo por defecto
  useEffect(() => {
    if (vehicleTypes.length > 0 && !selectedVehicleType) {
      setSelectedVehicleType(vehicleTypes[0]);
    }
  }, [vehicleTypes, selectedVehicleType]);

  // Paginación
  const totalPages = Math.ceil(associatedCourses.length / limit);
  const paginatedCourses = associatedCourses.slice(
    (page - 1) * limit,
    page * limit
  );

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  // Manejar cambio de tipo de vehículo
  const handleVehicleTypeChange = (event) => {
    const selectedId = event.target.value;
    const selectedType = vehicleTypes.find(type => type.id === selectedId);
    setSelectedVehicleType(selectedType);
    setPage(1);
  };

  // Obtener cursos disponibles para añadir (excluir los ya asociados)
  const getAvailableCourses = () => {
    const associatedIds = associatedCourses.map(course => course.id);
    return allCourses.filter(course => !associatedIds.includes(course.id));
  };

  // Mostrar modal para añadir cursos
  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  // Cerrar modal
  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  // Asociar curso al tipo de vehículo
  const handleAssociateCourse = async (courseId, isOptional = false) => {
    if (!selectedVehicleType?.id) {
      showNotification({
        message: "Primero seleccione un tipo de vehículo",
        severity: "warning"
      });
      return;
    }

    try {
      await associateMutation.mutateAsync({
        vehicleTypeId: selectedVehicleType.id,
        courseId,
        isOptional
      });
      
      // Actualizar la lista después de asociar
      refetchAssociatedCourses();
      
      showNotification({
        message: "Curso asociado correctamente",
        severity: "success"
      });
    } catch (error) {
      showNotification({
        message: "Error al asociar el curso",
        severity: "error"
      });
    }
  };

  // Cambiar la opcionalidad de un curso
  const handleToggleOptional = async (course) => {
    try {
      await associateMutation.mutateAsync({
        vehicleTypeId: selectedVehicleType.id,
        courseId: course.id,
        isOptional: !course.is_optional
      });
      
      refetchAssociatedCourses();
      
      showNotification({
        message: `Curso marcado como ${!course.is_optional ? "opcional" : "obligatorio"}`,
        severity: "success"
      });
    } catch (error) {
      showNotification({
        message: "Error al cambiar la opcionalidad del curso",
        severity: "error"
      });
    }
  };

  // Formatear precio como moneda
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  if (loadingVehicleTypes) return <LoadingAnimation />;

  return (
    <div className={`GM__${theme}-vehicle-table-wrapper`}>
      <div className={`GM__${theme}-header-section`}>
        <h2 className={`GM__${theme}-section-title`}>Requisitos de Cursos por Tipo de Vehículo</h2>
        
        <div className={`GM__${theme}-filter-container`}>
          <select
            className={`GM__${theme}-select-input`}
            value={selectedVehicleType?.id || ""}
            onChange={handleVehicleTypeChange}
          >
            {vehicleTypes.map(type => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
          
          <button
            className={`GM__${theme}-add-button`}
            onClick={handleShowAddModal}
            disabled={!selectedVehicleType}
          >
            Asociar nuevo curso
          </button>
        </div>
      </div>

      {loadingAssociatedCourses ? (
        <LoadingAnimation />
      ) : (
        <div className={`GM__${theme}-vehicle-table-wrapper-table`}>
          <table className={`GM__${theme}-vehicle-table`}>
            <thead>
              <tr>
                <th>Curso</th>
                <th>Descripción</th>
                <th>Precio</th>
                <th>Vigencia (meses)</th>
                <th>Opcional</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {paginatedCourses.length > 0 ? (
                paginatedCourses.map((course) => (
                  <tr key={course.id}>
                    <td>{course.name}</td>
                    <td>{course.description}</td>
                    <td>{formatCurrency(course.price || 0)}</td>
                    <td>{course.validity_months || 12}</td>
                    <td>
                      <Switch
                        checked={!!course.is_optional}
                        onChange={() => handleToggleOptional(course)}
                        color="primary"
                      />
                    </td>
                    <td className={`GM__${theme}-action-buttons`}>
                      <button
                        className={`GM__${theme}-delete-button`}
                        onClick={() => {
                          if (window.confirm("¿Desea eliminar este curso del tipo de vehículo?")) {
                            // Aquí iría la lógica para eliminar la asociación
                            // Por ahora, simplemente refrescamos la lista
                            refetchAssociatedCourses();
                          }
                        }}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className={`GM__${theme}-empty-message`}>
                    {selectedVehicleType 
                      ? `No hay cursos asociados al tipo de vehículo ${selectedVehicleType.name}`
                      : "Seleccione un tipo de vehículo para ver sus cursos asociados"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Paginación */}
      {totalPages > 1 && (
        <div className={`GM__${theme}-pagination-container`}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            shape="rounded"
            className={`GM__${theme}-custom-pagination`}
          />
        </div>
      )}

      {/* Modal para añadir cursos */}
      <Modal
        open={showAddModal}
        onClose={handleCloseAddModal}
        className={`GM__modal-container-${theme}`}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <AddCourseModal
          availableCourses={getAvailableCourses()}
          onAssociate={handleAssociateCourse}
          onCancel={handleCloseAddModal}
          isLoading={loadingAllCourses}
          theme={theme}
          vehicleTypeName={selectedVehicleType?.name}
        />
      </Modal>
    </div>
  );
}

function AddCourseModal({ availableCourses, onAssociate, onCancel, isLoading, theme, vehicleTypeName }) {
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [optionalMap, setOptionalMap] = useState({});

  // Manejar selección de todos los cursos
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    
    if (newSelectAll) {
      setSelectedCourses(availableCourses.map(c => c.id));
    } else {
      setSelectedCourses([]);
    }
  };

  // Manejar selección individual
  const handleSelectCourse = (courseId) => {
    if (selectedCourses.includes(courseId)) {
      setSelectedCourses(prev => prev.filter(id => id !== courseId));
    } else {
      setSelectedCourses(prev => [...prev, courseId]);
    }
  };

  // Manejar cambio de opcionalidad
  const handleOptionalChange = (courseId, isOptional) => {
    setOptionalMap(prev => ({
      ...prev,
      [courseId]: isOptional
    }));
  };

  // Asociar cursos seleccionados
  const handleAssociateSelected = async () => {
    for (const courseId of selectedCourses) {
      const isOptional = optionalMap[courseId] || false;
      await onAssociate(courseId, isOptional);
    }
    onCancel();
  };

  return (
    <div className={`GM__${theme}-modal`} style={{ width: '700px', maxHeight: '80vh', overflowY: 'auto' }}>
      <div className={`GM__${theme}-modal-header-container`}>
        <h3 className={`GM__${theme}-modal-header`}>
          Asociar Cursos a {vehicleTypeName}
        </h3>
        <div className={`GM__${theme}-modal-subtitle`}>
          Seleccione los cursos que desea asociar a este tipo de vehículo
        </div>
      </div>

      <div className={`GM__${theme}-modal-content`}>
        {isLoading ? (
          <LoadingAnimation />
        ) : availableCourses.length === 0 ? (
          <div className={`GM__${theme}-empty-message`}>
            No hay cursos disponibles para asociar
          </div>
        ) : (
          <>
            <div className={`GM__${theme}-select-all-container`}>
              <label>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                Seleccionar todos
              </label>
            </div>
            
            <div className={`GM__${theme}-courses-grid`}>
              {availableCourses.map(course => (
                <div key={course.id} className={`GM__${theme}-course-item`}>
                  <div className={`GM__${theme}-course-header`}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedCourses.includes(course.id)}
                        onChange={() => handleSelectCourse(course.id)}
                      />
                      {course.name}
                    </label>
                  </div>
                  
                  <div className={`GM__${theme}-course-details`}>
                    <p>{course.description}</p>
                    <div className={`GM__${theme}-course-meta`}>
                      <span>Precio: {new Intl.NumberFormat('es-CO', {
                        style: 'currency',
                        currency: 'COP'
                      }).format(course.price || 0)}</span>
                      <span>Vigencia: {course.validity_months || 12} meses</span>
                    </div>
                  </div>
                  
                  {selectedCourses.includes(course.id) && (
                    <div className={`GM__${theme}-course-options`}>
                      <label>
                        <input
                          type="checkbox"
                          checked={!!optionalMap[course.id]}
                          onChange={(e) => handleOptionalChange(course.id, e.target.checked)}
                        />
                        Marcar como opcional
                      </label>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <div className={`GM__${theme}-modal-footer`}>
        <button
          className={`GM__${theme}-btn GM__${theme}-btn-cancel`}
          onClick={onCancel}
        >
          Cancelar
        </button>
        <button
          className={`GM__${theme}-btn GM__${theme}-btn-save`}
          onClick={handleAssociateSelected}
          disabled={selectedCourses.length === 0}
        >
          Asociar Seleccionados ({selectedCourses.length})
        </button>
      </div>
    </div>
  );
}

AddCourseModal.propTypes = {
  availableCourses: PropTypes.array.isRequired,
  onAssociate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  vehicleTypeName: PropTypes.string
};