import { useState, useEffect } from 'react';
import { 
  PictureAsPdf, 
  Description, 
} from '@mui/icons-material';
import styles from '../assets/scss/components/ui/StreamingMedia.module.scss';
import { useGetVehicleMediaWithUrl } from '../hooks/useReactQuery';
import PropTypes from "prop-types";
import NotFound from "../assets/img/Pagina_204.png";
import LoadingAnimation from '../components/loading/LoadingAnimation';

const VehicleStreamingMediaType = ({ url, type = 'vehiclePhoto', className = '' }) => {
  const [mediaContent, setMediaContent] = useState(null);
  const [mediaMetadata, setMediaMetadata] = useState(null);

  const { data: dataMediaWithUrl, isLoading, error } = useGetVehicleMediaWithUrl(url, type);

  useEffect(() => {
    if (dataMediaWithUrl) {
      // Solo actualizamos el estado si hay datos válidos
      if (dataMediaWithUrl.data && Object.keys(dataMediaWithUrl.data).length > 0) {
        setMediaContent(dataMediaWithUrl.data.content);
        setMediaMetadata({
          fileName: dataMediaWithUrl.data.fileName,
          fileType: dataMediaWithUrl.data.fileType,
          fileSize: dataMediaWithUrl.data.fileSize,
        });
      }
    }
  }, [dataMediaWithUrl]);

  const renderContent = () => {
    // Si hay un error o la respuesta es 204 (No Content)
    if (error || (!mediaContent && dataMediaWithUrl?.status === 204)) {
      return (
        <div className={styles.mediaContainer}>
          <img 
            src={NotFound} 
            alt="Imagen por defecto" 
            className={styles.image}
          />
        </div>
      );
    }

    if (!mediaMetadata?.fileType) {
      return (
        <div className={styles.mediaContainer}>
          <img 
            src={NotFound} 
            alt="Imagen por defecto" 
            className={styles.image}
          />
        </div>
      );
    }

    switch (mediaMetadata.fileType.split('/')[0]) {
      case 'image':
        return <img 
          src={mediaContent || NotFound} 
          alt={mediaMetadata.fileName || 'Imagen por defecto'} 
          className={styles.image} 
        />;

      case 'video':
        return <video controls src={mediaContent} className={styles.video} />;

      case 'application':
        return (
          <div className={styles.document}>
            {mediaMetadata.fileName?.endsWith('.pdf') ? (
              <PictureAsPdf />
            ) : (
              <Description />
            )}
            <p>{mediaMetadata.fileName}</p>
          </div>
        );

      default:
        return (
          <img 
            src={NotFound} 
            alt="Imagen por defecto" 
            className={styles.image}
          />
        );
    }
  };

  return (
    <div className={`${styles.mediaContainer} ${className}`}>
      {isLoading ? <LoadingAnimation /> : renderContent()}
    </div>
  );
};

VehicleStreamingMediaType.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['vehiclePhoto', 'technicalReview', 'insurance', 'circulation']).isRequired,
  className: PropTypes.string,
};

export default VehicleStreamingMediaType;