import { useState, useCallback } from 'react';

export const useAnnotations = () => {
  const [annotations, setAnnotations] = useState([]);
  const [activeAnnotationId, setActiveAnnotationId] = useState(null);

  const addAnnotation = useCallback((newAnnotation) => {
    // Validar que la anotación tenga una posición válida
    if (!newAnnotation.position || 
        typeof newAnnotation.position.lat !== 'number' || 
        typeof newAnnotation.position.lng !== 'number') {
      console.error("Error: Intentando añadir anotación sin posición válida", newAnnotation);
      return null;
    }
    
    console.log("Añadiendo anotación en posición:", newAnnotation.position);
    
    const annotationWithId = {
      ...newAnnotation,
      id: newAnnotation.id || Date.now().toString(),
    };

    setAnnotations(prev => [...prev, annotationWithId]);
    setActiveAnnotationId(annotationWithId.id);
    return annotationWithId.id;
  }, []);

  const updateAnnotation = useCallback((annotationId, updates) => {
    setAnnotations(prev => 
      prev.map(annotation => 
        annotation.id === annotationId 
          ? { ...annotation, ...updates } 
          : annotation
      )
    );
  }, []);

  const deleteAnnotation = useCallback((annotationId) => {
    setAnnotations(prev => prev.filter(annotation => annotation.id !== annotationId));
    if (activeAnnotationId === annotationId) {
      setActiveAnnotationId(null);
    }
  }, [activeAnnotationId]);

  const setActiveAnnotation = useCallback((annotationId) => {
    setActiveAnnotationId(annotationId);
  }, []);

  return {
    annotations,
    activeAnnotationId,
    addAnnotation,
    updateAnnotation,
    deleteAnnotation,
    setActiveAnnotation,
  };
};