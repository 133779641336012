import React, { useState } from 'react';
import { useChat } from '../../context/ChatContext';
import { useAuth } from '../../context/AuthContext';
import "../../assets/scss/components/SidebarChat.scss"
export default function SidebarChat() {
  const { 
    conversations,
    users,
    rolesData,
    activeChatId,
    selectedRoleId,
    onlineUsers,
    setActiveChatId,
    setSelectedRecipient,
    setSelectedRoleId
  } = useChat();
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredConversations = conversations.filter(conv => 
    conv.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isUserOnline = (userId) => onlineUsers.has(userId);
  const onlineUsersCount = onlineUsers.size;

  const filteredUsers = users.filter(u => 
    u.id !== user.id && 
    (`${u.first_name} ${u.first_last_name}`).toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUserSelect = (selectedUser) => {
    const existingConversation = conversations.find(conv => 
      conv.participants.some(p => p.id === selectedUser.id)
    );

    if (existingConversation) {
      setActiveChatId(existingConversation.id);
    } else {
      setSelectedRecipient(selectedUser);
      setActiveChatId(null);
    }
  };

  return (
    <div className="sidebar-container">
      <div className="search-bar">
        <input 
          type="text" 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Buscar conversaciones o usuarios" 
        />
      </div>

      {/* Sección de Conversaciones Recientes */}
      {filteredConversations.length > 0 && (
        <div className="conversations-section">
          <h3>Conversaciones Recientes</h3>
          <div className="conversations-list">
            {filteredConversations.map((conv) => (
              <div
                key={conv.id}
                className={`conversation-item ${conv.id === activeChatId ? 'active' : ''}`}
                onClick={() => {
                  setActiveChatId(conv.id);
                  setSelectedRecipient(null);
                }}
              >
                <div className="conversation-info">
                  <span className="name">{conv.name}</span>
                  <span className="last-message">{conv.lastMessage?.content}</span>
                </div>
                {conv.unreadCount > 0 && (
                  <span className="unread-badge">{conv.unreadCount}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Sección de Chat Global */}
      <div className="global-chat-section">
        <h3>Chat Global</h3>
        <div 
          className={`global-chat-item ${activeChatId === 'global' ? 'active' : ''}`}
          onClick={() => {
            setActiveChatId('global');
            setSelectedRecipient(null);
          }}
        >
          <span>Chat Global</span>
          <span className="online-count">
          {onlineUsersCount} en línea
          </span>
        </div>
      </div>

      {/* Sección de Roles y Usuarios */}
      <div className="roles-section">
        <select 
          value={selectedRoleId}
          onChange={(e) => setSelectedRoleId(e.target.value)}
          className="role-selector"
        >
          {rolesData?.map(role => (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          ))}
        </select>

        <div className="users-list">
          {filteredUsers.map(user => (
            <div
              key={user.id}
              className="user-item"
              onClick={() => handleUserSelect(user)}
            >
              <span className="user-name">
                {`${user.first_name} ${user.first_last_name}`}
              </span>
              <span className={`status-indicator ${
                isUserOnline(user.id) ? 'online' : 'offline'
              }`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}