import React, { useState } from 'react';
import "../assets/scss/components/PDFViewer.scss";
const PDFViewer = ({ fileId }) => {
  const [error, setError] = useState(null);
  // Construir la URL para el streaming directo
  const pdfUrl = `${process.env.REACT_APP_API_URL_PRODUCTION}/stream-document/${fileId}`;

  return (
    <div className="pdf-viewer">
      {error ? (
        <div className="pdf-viewer-error">
          <p>Error al cargar el PDF. Por favor, intente nuevamente.</p>
        </div>
      ) : (
        <iframe
          src={pdfUrl}
          className="pdf-viewer-iframe"
          title="PDF Viewer"
          onError={(e) => setError(e)}
        />
      )}
    </div>
  );
};

export default PDFViewer;