// src/components/modals/ConfirmDeleteModal.jsx
import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

function ConfirmDeleteModal({ open, onClose, onConfirm, title, message }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title || "Confirmar Eliminación"}</DialogTitle>
      <DialogContent dividers>
        <p>{message || "¿Estás seguro de que deseas eliminar este elemento?"}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="secondary" variant="contained">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default ConfirmDeleteModal;
