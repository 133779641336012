import React from 'react';
import PropTypes from 'prop-types';

import CalendarIcon from '../../assets/svg/components/calendar.svg';
import dayjs from 'dayjs';
import { useUsersByRole } from '../../hooks/useVehicleQuery';
import { usePhoneCountryCodes } from '../../hooks/userComplementaryQuery';
import SortDropdown from '../ui/dropdawn/SortDropdown';
import CustomDatePicker from '../ui/CustomDatePicker';
import CustomInputField from '../ui/inputs/CustomInputField';
import '../../assets/scss/components/vehicle/ContactSectionAddVehicle.scss';

export default function ContactSectionAddVehicle({
  contactInfo,
  onContactInfoChange,
  theme
}) {
  const { data: countryCodes } = usePhoneCountryCodes();
  const { data: operators } = useUsersByRole(5, 1, 100, '');
  const { data: owners } = useUsersByRole(6, 1, 100, '');
  
  const countryCodesData = countryCodes?.data || [];
  const operatorsData = operators?.data || [];
  const ownersData = owners?.data || [];
  
  return (
    <div className={`GM__${theme}-contact-section`}>
      <h2 className={`GM__${theme}-section-title`}>Información de Contacto</h2>
      
      <div className={`GM__${theme}-contact-grid`}>
        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="operatorId" className={`GM__${theme}-input-label`}>
            Operador
          </label>
          <SortDropdown
            id="operatorId"
            value={contactInfo.operatorId}
            onChange={(value) => onContactInfoChange('operatorId', value)}
            options={operatorsData.map((operator) => ({
              value: operator.id,
              label: `${operator.first_name} ${operator.first_last_name}`,
            }))}
            theme={theme}
            placeholder="Seleccione un operador"
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="ownerId" className={`GM__${theme}-input-label`}>
            Propietario
          </label>
          <SortDropdown
            id="ownerId"
            value={contactInfo.ownerId}
            onChange={(value) => onContactInfoChange('ownerId', value)}
            options={ownersData.map((owner) => ({
              value: owner.id,
              label: `${owner.first_name} ${owner.first_last_name}`,
            }))}
            theme={theme}
            placeholder="Seleccione un propietario"
          />
        </div>
        
        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="contractStartDate" className={`GM__${theme}-input-label`}>
            Fecha inicio contrato
          </label>
          <CustomDatePicker
            id="contractStartDate"
            value={contactInfo.contractStartDate ? dayjs(contactInfo.contractStartDate).toDate() : null}
            onChange={(date) => onContactInfoChange("contractStartDate", date ? dayjs(date).format('YYYY-MM-DD') : null)}
            icon={<img src={CalendarIcon} alt="calendar" />}
            theme={theme}
          />
        </div>
        
        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="contractEndDate" className={`GM__${theme}-input-label`}>
            Fecha fin contrato
          </label>
          <CustomDatePicker
            id="contractEndDate"
            value={contactInfo.contractEndDate ? dayjs(contactInfo.contractEndDate).toDate() : null}
            onChange={(date) => onContactInfoChange("contractEndDate", date ? dayjs(date).format('YYYY-MM-DD') : null)}
            icon={<img src={CalendarIcon} alt="calendar" />}
            theme={theme}
          />
        </div>
        
        <div className={`GM__${theme}-phone-group`}>
          <div className={`GM__${theme}-input-group`}>
            <label htmlFor="primaryPhone" className={`GM__${theme}-input-label`}>
              Número de Celular Principal
            </label>
            <div className={`GM__${theme}-phone-input-container`}>
              <SortDropdown
                id="primaryPhoneCountry"
                value={contactInfo.primaryPhoneCountryId || ""}
                onChange={(value) => onContactInfoChange('primaryPhoneCountryId', value)}
                options={countryCodesData.map((country) => ({
                  value: country.id,
                  label: `${country.country_code} (${country.country_name})`,
                  country_code: country.country_code,
                }))}
                type="phone"
                theme={theme}
                placeholder="Código"
              />
              <CustomInputField
                id="primaryPhone"
                type="text"
                name="primaryPhoneNumber"
                value={contactInfo.primaryPhoneNumber}
                onChange={(e) => onContactInfoChange('primaryPhoneNumber', e.target.value)}
                placeholder="Número"
                theme={theme}
              />
            </div>
          </div>
        </div>

        <div className={`GM__${theme}-phone-group`}>
          <div className={`GM__${theme}-input-group`}>
            <label htmlFor="secondaryPhone" className={`GM__${theme}-input-label`}>
              Número de Celular Secundario (Opcional)
            </label>
            <div className={`GM__${theme}-phone-input-container`}>
              <SortDropdown
                id="secondaryPhoneCountry"
                value={contactInfo.secondaryPhoneCountryId || ""}
                onChange={(value) => onContactInfoChange('secondaryPhoneCountryId', value)}
                options={countryCodesData.map((country) => ({
                  value: country.id,
                  label: `${country.country_code} (${country.country_name})`,
                  country_code: country.country_code,
                }))}
                type="phone"
                theme={theme}
                placeholder="Código"
              />
              <CustomInputField
                id="secondaryPhone"
                type="text"
                name="secondaryPhoneNumber"
                value={contactInfo.secondaryPhoneNumber}
                onChange={(e) => onContactInfoChange('secondaryPhoneNumber', e.target.value)}
                placeholder="Número"
                theme={theme}
              />
            </div>
          </div>
        </div>
        
        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="nationalityId" className={`GM__${theme}-input-label`}>Nacionalidad</label>
          <SortDropdown
            id="nationalityId"
            value={contactInfo.nationalityId || ""}
            onChange={(value) => onContactInfoChange('nationalityId', value)}
            options={countryCodesData.map((country) => ({
              value: country.id,
              label: country.country_name,
              country_code: country.country_code
            }))}
            type="nationality"
            theme={theme}
            placeholder="Seleccione país"
          />
        </div>
      </div>
    </div>
  );
}

ContactSectionAddVehicle.propTypes = {
  contactInfo: PropTypes.shape({
    operatorId: PropTypes.string,
    ownerId: PropTypes.string,
    primaryPhoneNumber: PropTypes.string,
    primaryPhoneCountryId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    secondaryPhoneNumber: PropTypes.string,
    secondaryPhoneCountryId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    contractStartDate: PropTypes.string,
    contractEndDate: PropTypes.string,
    nationalityId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  }).isRequired,
  onContactInfoChange: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired
};