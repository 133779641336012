import { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { userService } from "../service";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
 
  const [token, setToken] = useState(() => {
    return localStorage.getItem("token");
  });

  const [isLoading, setIsLoading] = useState(false);

  // Función para obtener credenciales guardadas
  const getSavedCredentials = () => {
    const savedCredentials = localStorage.getItem("savedCredentials");
    return savedCredentials ? JSON.parse(savedCredentials) : null;
  };

  // Actualización de localStorage para el usuario
  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  // Actualización de localStorage para el token
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
      // Si se elimina el token, también eliminamos las credenciales guardadas
      localStorage.removeItem("savedCredentials");
    }
  }, [token]);

  const login = async (email, password, captchaToken, rememberUser = false) => {
    try {
      setIsLoading(true);
      const loginResponse = await userService.login(email, password, captchaToken);
      
      if (loginResponse.success) {
        setUser(loginResponse.user);
        setToken(loginResponse.token);
        
        // Si se activó "recordar usuario", guardamos las credenciales
        if (rememberUser) {
          localStorage.setItem("savedCredentials", JSON.stringify({ email }));
        } else {
          localStorage.removeItem("savedCredentials");
        }
        
        return loginResponse;
      }
    } catch (error) {
      console.error("Error en el inicio de sesión:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Nuevo método para actualizar el token
  const updateToken = (newToken) => {
    setToken(newToken);
  };

  const logout = () => {
    setUser(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      token, 
      login, 
      logout, 
      updateToken, 
      isLoading,
      getSavedCredentials 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};