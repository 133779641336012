import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "../../assets/scss/components/filters/FilterMenu.scss";
import { Button } from "react-scroll";
import FilterListIcon from "@mui/icons-material/FilterList";
// Componente para elementos tipo carpeta (con posibilidad de expandir)
const FilterFolder = ({
  item,
  theme,
  onItemSelected,
  level = 0,
  selectedFilters,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isSelected = selectedFilters && selectedFilters.includes(item.value);

  const handleFolderClick = (e) => {
    e.stopPropagation();
    onItemSelected(item.value, item.category);
  };

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className={`filter-item folder-item ${theme} ${
          isSelected ? "selected" : ""
        }`}
        style={{ paddingLeft: `${level * 8 + 16}px` }}
        onClick={handleFolderClick}
      >
        <div className="item-content">
          {item.icon && (
            <div className="item-icon">
              {typeof item.icon === "string" ? (
                <img src={item.icon} alt={item.label} />
              ) : (
                item.icon
              )}
            </div>
          )}
          <div className="item-text">{item.label}</div>
        </div>
        <div className="folder-actions">
          <div className="action-divider"></div>
          <div className="settings-icon" onClick={handleExpandClick}>
            <svg width="18" height="18" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
              />
            </svg>
          </div>
        </div>
      </div>

      {isOpen && item.items && (
        <div className={`subfolder-container ${theme}`}>
          {item.items.map((subItem, idx) => (
            <FilterItem
              key={`${subItem.value}-${idx}`}
              item={subItem}
              theme={theme}
              onItemSelected={onItemSelected}
              level={level + 1}
              selectedFilters={selectedFilters}
            />
          ))}
        </div>
      )}
    </>
  );
};

// Componente para elementos tipo opción (sin expandir)
const FilterOption = ({
  item,
  theme,
  onItemSelected,
  level = 0,
  selectedFilters,
}) => {
  const isSelected = selectedFilters && selectedFilters.includes(item.value);

  return (
    <div
      className={`filter-item option-item ${theme} ${
        isSelected ? "selected" : ""
      }`}
      style={{ paddingLeft: `${level * 8 + 16}px` }}
      onClick={() => onItemSelected(item.value, item.category)}
    >
      <div className="item-content">
        {item.icon && (
          <div className="item-icon">
            {typeof item.icon === "string" ? (
              <img src={item.icon} alt={item.label} />
            ) : (
              item.icon
            )}
          </div>
        )}
        <div className="item-text">{item.label}</div>
      </div>
    </div>
  );
};

// Componente que determina si se debe renderizar una carpeta o una opción
// En FilterMenu.jsx - Modifica el componente FilterItem
const FilterItem = (props) => {
  const { item, onItemSelected } = props;

  // Asegurarnos que todos los subelementos hereden la categoría del padre
  const enhancedItem = {
    ...item,
    items: item.items?.map((subItem) => ({
      ...subItem,
      category: item.category || subItem.category,
    })),
  };

  if (enhancedItem.type === "folder") {
    return <FilterFolder {...props} item={enhancedItem} />;
  } else {
    return <FilterOption {...props} item={enhancedItem} />;
  }
};

// Componente principal del menú de filtros
const FilterMenu = ({
  theme = "light",
  filterData,
  onFilterChange,
  selectedFilters = [],
  buttonLabel = "Filtrar por",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleItemSelected = (value, category) => {
    onFilterChange(category, value);
    // No cerramos el menú para permitir múltiples selecciones
  };

  // Cierra el menú cuando se hace clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`filter-menu-container ${theme}`} ref={menuRef}>
      <button className={`filter-button ${theme}`} onClick={handleToggleMenu}>
        <FilterListIcon className="filter-icon" />
        <span>{buttonLabel}</span>
      </button>

      {isOpen && (
        <div className={`menu-dropdown ${theme}`}>
          {filterData.map((section, sectionIndex) => (
            <div key={`section-${sectionIndex}`} className="menu-section">
              {sectionIndex > 0 && <div className={`divider ${theme}`} />}

              <div className={`section-header ${theme}`}>
                {section.sectionTitle}
              </div>

              <div className={`divider ${theme}`} />

              {section.items.map((item, idx) => (
                <FilterItem
                  key={`${item.value}-${idx}`}
                  item={{ ...item, category: section.category }}
                  theme={theme}
                  onItemSelected={handleItemSelected}
                  selectedFilters={selectedFilters}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// PropTypes definitions
FilterMenu.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  filterData: PropTypes.arrayOf(
    PropTypes.shape({
      sectionTitle: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(["folder", "option"]).isRequired,
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
          items: PropTypes.arrayOf(PropTypes.object),
        })
      ).isRequired,
    })
  ).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  buttonLabel: PropTypes.string,
};

export default FilterMenu;
