import React, { createContext, useContext, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { adminService } from '../service/adminService';
import LoadingDownload from '../components/loading/LoadingDownload';
import { mediaService } from '../service';

// Renombramos el contexto para ser más general
const FileTransferContext = createContext(null);

const TransferContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})(({ theme, isCollapsed }) => ({
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  width: isCollapsed ? '300px' : '500px',
  height: isCollapsed ? '60px' : '80px',
  display: 'flex',
  zIndex: 9999,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
}));

const ContentSection = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})(({ isCollapsed }) => ({
  width: isCollapsed ? '150px' : '70%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '8px',
  transition: 'all 0.3s ease-in-out',
  overflow: 'hidden',
  padding: '4px 0'
}));


const ImageSection = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})(({ isCollapsed }) => ({
  width: isCollapsed ? '150px' : '30%',
  position: 'relative',
  overflow: 'visible',
  transition: 'all 0.3s ease-in-out'
}));

const FloatingImageContainer = styled(Box)({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const FileTransferImplementation = ({ children }) => {
  const [transferState, setTransferState] = useState({
    isActive: false,
    title: '',
    imageUrl: '',
    progress: 0,
    isError: false,
    abortController: null,
    type: 'download', // 'download' o 'upload'
  });
  const [isCollapsed, setIsCollapsed] = useState(false);

  const cancelTransfer = useCallback(() => {
    if (transferState.abortController) {
      transferState.abortController.abort();
      setTransferState(prev => ({
        ...prev,
        isActive: false,
        isError: false
      }));
    }
  }, [transferState.abortController]);

  const startTransfer = useCallback((title, type = 'download') => {
    const abortController = type === 'download' ? new AbortController() : null;
    setTransferState({
      isActive: true,
      title,
      imageUrl: '/path-to-your-animation.png',
      progress: 0,
      isError: false,
      abortController,
      type
    });
    return abortController?.signal;
  }, []);

  const finishTransfer = useCallback((error = false) => {
    setTransferState(prev => ({
      ...prev,
      isActive: false,
      isError: error
    }));
  }, []);

  // Métodos de descarga (mantenemos la compatibilidad existente)
  const handleDownloadYearAndMonth = useCallback(async (selectedDate) => {
    if (transferState.isActive) return;
    
    const signal = startTransfer(`Descargando reporte ${dayjs(selectedDate).format("MMMM YYYY")}`);
    
    try {
      const response = await adminService.getReportsMontly(selectedDate, signal);
      finishTransfer(false);
      return response;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Descarga cancelada');
        return;
      }
      console.error("Error al descargar:", error);
      finishTransfer(true);
      throw error;
    }
  }, [transferState.isActive, startTransfer, finishTransfer]);

  const handleDownloadYear = useCallback(async (selectedDate) => {
    if (transferState.isActive) return;
    
    startTransfer(`Descargando reporte anual ${dayjs(selectedDate).format("YYYY")}`);
    
    try {
      const response = await adminService.getReportsYear(selectedDate);
      finishTransfer(false);
      return response;
    } catch (error) {
      console.error("Error al descargar:", error);
      finishTransfer(true);
      throw error;
    }
  }, [transferState.isActive, startTransfer, finishTransfer]);

  const handleDownloadPeriod = useCallback(async (selectedDate, adminService) => {
    if (transferState.isActive) return;
    
    startTransfer(`Descargando periodo ${dayjs(selectedDate).format("MMMM YYYY")}`);
    
    try {
      const response = await adminService.getReportsPeriod(selectedDate);
      finishTransfer(false);
      return response;
    } catch (error) {
      console.error("Error al descargar:", error);
      finishTransfer(true);
      throw error;
    }
  }, [transferState.isActive, startTransfer, finishTransfer]);

  const handleDownloadMultipleDocuments = useCallback(async (fileIds, typeData) => {
    if (transferState.isActive) return;
    
    const signal = startTransfer(`Descargando ${fileIds.length} documentos`);
    
    try {
      const response = await mediaService.downloadMultipleDocuments(fileIds, typeData, signal);
      finishTransfer(false);
      return response;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Descarga cancelada');
        return;
      }
      console.error("Error al descargar:", error);
      finishTransfer(true);
      throw error;
    }
  }, [transferState.isActive, startTransfer, finishTransfer]);

  // Subir documentos del usuario
  const handleUploadDocument = useCallback(async (documentData) => {
    if (transferState.isActive) return;
    
    // Iniciamos la transferencia sin abortController ya que no se puede cancelar
    startTransfer(`Subiendo documento: ${documentData.file?.name || 'Documento'}`, 'upload');
    
    try {
      const response = await mediaService.createDocument(documentData);
      finishTransfer(false);
      return response;
    } catch (error) {
      console.error("Error al subir documento:", error);
      finishTransfer(true);
      throw error;
    }
  }, [transferState.isActive, startTransfer, finishTransfer]);

  useEffect(() => {
    let timer;
    if (transferState.isActive) {
      // Esperar 5 segundos antes de colapsar
      timer = setTimeout(() => {
        setIsCollapsed(true);
      }, 5000);
    } else {
      setIsCollapsed(false);
    }
    return () => clearTimeout(timer);
  }, [transferState.isActive]);

  return (
    <FileTransferContext.Provider 
      value={{ 
        transferState,
        handleDownloadYearAndMonth,
        handleDownloadYear,
        handleDownloadPeriod,
        handleDownloadMultipleDocuments,
        handleUploadDocument, // Nuevo método para subir archivos
        cancelTransfer
      }}
    >
      {children}
      {transferState.isActive && (
        <TransferContainer isCollapsed={isCollapsed}>
          <ContentSection isCollapsed={isCollapsed}>
            <Typography 
              variant="h7" 
              component="div"
              sx={{ 
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minHeight: '24px',
                lineHeight: '24px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {isCollapsed ? 
                (transferState.type === 'upload' ? "Subiendo..." : "Descargando...") : 
                transferState.title
              }
            </Typography>
            {!isCollapsed && (
              <Typography variant="body2" color="text.secondary">
                {transferState.isError 
                  ? `Error en la ${transferState.type === 'upload' ? 'subida' : 'descarga'}` 
                  : `Procesando ${transferState.type === 'upload' ? 'subida' : 'descarga'}...`
                }
              </Typography>
            )}
            {/* Mostrar botón de cancelar solo para descargas */}
            {transferState.type === 'download' && (
              <Button 
                onClick={cancelTransfer} 
                size="small" 
                color="error"
                sx={{ minWidth: 'auto' }}
              >
                Cancelar
              </Button>
            )}
          </ContentSection>
          <ImageSection isCollapsed={isCollapsed}>
            <FloatingImageContainer>
              <LoadingDownload />
            </FloatingImageContainer>
          </ImageSection>
        </TransferContainer>
      )}
    </FileTransferContext.Provider>
  );
};

export const FileTransferProvider = ({ children }) => {
  return (
    <FileTransferImplementation>
      {children}
    </FileTransferImplementation>
  );
};

export const useFileTransfer = () => {
  const context = useContext(FileTransferContext);
  if (!context) {
    throw new Error('useFileTransfer debe usarse dentro de un FileTransferProvider');
  }
  return context;
};

// Mantener la compatibilidad con código existente mediante alias
export const DownloadProvider = FileTransferProvider;
export const useDownload = () => {
  const context = useContext(FileTransferContext);
  if (!context) {
    throw new Error('useDownload debe usarse dentro de un DownloadProvider');
  }
  return context;
};

FileTransferProvider.propTypes = {
  children: PropTypes.node.isRequired
};

FileTransferImplementation.propTypes = {
  children: PropTypes.node.isRequired
};