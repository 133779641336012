import React, { useMemo } from "react";
import "../../assets/scss/views/Trends.scss";
import { useLayout } from "../../context/LayoutContext";
import {  Paper } from "@mui/material";
import BarChart from "../../components/ui/charts/BarChart";
import PieChart from "../../components/ui/charts/PieChart";
import { getWeekRanges } from "../../utils/tableHelpers";
import LoadingAnimation from "../../components/loading/LoadingAnimation";

export default function Trends() {
  const { monthlyAnalytics, isLoading } = useLayout();
  const analyticsData = monthlyAnalytics?.processedData || {};

  // Configuración de la animación del spinner
  const styleSheet = document.createElement("style");
  styleSheet.textContent = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  document.head.appendChild(styleSheet);

  // Procesamiento de datos para el gráfico de barras
  const chartData = useMemo(() => {
    if (!analyticsData.trips) {
      return {
        categoryData: [],
        seriesData: []
      };
    }
  
    const weeks = getWeekRanges(analyticsData.period);
    
    // Inicializamos los contadores por semana
    const weeklyData = weeks.map(() => ({
      speedExcesses: 0,
      totalTrips: 0,
      criticalPointEvents: 0
    }));
  
    // Procesamos los viajes por semana
    Object.values(analyticsData.trips).forEach(vehicleTrips => {
      vehicleTrips.forEach(trip => {
        const tripDate = new Date(trip.origin.dateTime);
        const tripDay = tripDate.getDate();
        
        // Encontramos la semana correspondiente
        const weekIndex = weeks.findIndex(week => 
          tripDay >= week.start && tripDay <= week.end
        );
  
        if (weekIndex !== -1) {
          // Sumamos los excesos de velocidad
          weeklyData[weekIndex].speedExcesses += trip.overspeedCount || 0;
          
          // Contamos el viaje
          weeklyData[weekIndex].totalTrips++;
          
          // Sumamos las violaciones a puntos críticos
          weeklyData[weekIndex].criticalPointEvents += trip.criticalViolationsCount || 0;
        }
      });
    });
  
    return {
      categoryData: weeks.map(week => week.label),
      seriesData: [
        {
          name: "Excesos de Velocidad",
          data: weeklyData.map(data => data.speedExcesses),
        },
        {
          name: "Viajes Realizados",
          data: weeklyData.map(data => data.totalTrips),
        },
        {
          name: "Violaciones a Puntos Críticos",
          data: weeklyData.map(data => data.criticalPointEvents),
        },
      ],
    };
  }, [analyticsData]);

  // Datos para el primer gráfico circular (Excesos de velocidad por vehículo)
  const speedViolationsData = useMemo(() => {
    if (!analyticsData.trips) return [];

    const vehicleViolations = {};

    Object.entries(analyticsData.trips).forEach(([placa, trips]) => {
      const totalExcesses = trips.reduce((sum, trip) => 
        sum + (trip.overspeedCount || 0), 0);
      
      if (totalExcesses > 0) {
        vehicleViolations[placa] = totalExcesses;
      }
    });

    return Object.entries(vehicleViolations)
      .map(([placa, value]) => ({
        name: placa,
        value
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10); // Top 10 vehículos
  }, [analyticsData]);

  // Datos para el segundo gráfico circular (Violaciones por punto crítico)
  const criticalPointsData = useMemo(() => {
    if (!analyticsData.trips) return [];
  
    const vehicleViolations = {};
  
    // Recorremos todos los viajes por vehículo
    Object.entries(analyticsData.trips).forEach(([placa, trips]) => {
      // Sumamos todas las violaciones a puntos críticos para cada vehículo
      const totalViolations = trips.reduce((sum, trip) => 
        sum + (trip.criticalViolationsCount || 0), 0);
      
      if (totalViolations > 0) {
        vehicleViolations[placa] = totalViolations;
      }
    });
  
    // Transformamos y ordenamos los datos
    return Object.entries(vehicleViolations)
      .map(([placa, value]) => ({
        name: placa,
        value
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10); // Tomamos solo los top 10
  }, [analyticsData]);
  // Calculamos totales
  const totalViolations = speedViolationsData.reduce(
    (sum, item) => sum + item.value, 0
  );
  const totalCriticalPoints = criticalPointsData.reduce(
    (sum, item) => sum + item.value, 0
  );
  return (
    <div className={`trends-container`}>
      <div className="trends-header">
        <h1 className="trends-title">Tendencias</h1>
      </div>
      {isLoading && (
        <LoadingAnimation/>
      )}
      <div className="charts">
        <section className="section-1">
          <Paper
            elevation={3}
            style={{ padding: "1rem", height: "100%", width: "100%" }}
          >
            <BarChart
              title="Análisis de Eventos por Día"
              categoryData={chartData.categoryData}
              seriesData={chartData.seriesData}
              theme="dark"
              height="100%"
            />
          </Paper>
        </section>
        <section className="section-2">
          <div className="pie-chart">
            <Paper elevation={3} style={{ padding: "1rem", height: "100%" }}>
              <PieChart
                title="Top 10 - Vehículos con Más Excesos de Velocidad"
                data={speedViolationsData}
                theme="dark"
                height="350px"
                centerText={`Total\n${totalViolations}`}
                hideHeader={true}
              />
            </Paper>
          </div>
          <div className="pie-chart">
            <Paper elevation={3} style={{ padding: "1rem", height: "100%" }}>
              <PieChart
                title="Top 10 - Vehículos con Más Violaciones a Puntos Críticos"
                data={criticalPointsData}
                theme="dark"
                height="350px"
                centerText={`Total\n${totalCriticalPoints}`}
                hideHeader={true}
              />
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}