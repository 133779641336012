import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ReCaptchaContext = createContext(null);

export const ReCaptchaProvider = ({ children }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  
  const executeVerification = useCallback(async (action = 'default') => {
    if (!executeRecaptcha) return null;
    
    try {
      setIsLoading(true);
      const token = await executeRecaptcha(action);
      return token;
    } catch (error) {
      console.error('Error al ejecutar reCAPTCHA:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [executeRecaptcha]);
  
  return (
    <ReCaptchaContext.Provider value={{ executeVerification, isLoading }}>
      {children}
    </ReCaptchaContext.Provider>
  );
};

export const useReCaptcha = () => {
  const context = useContext(ReCaptchaContext);
  if (!context) {
    throw new Error('useReCaptcha debe usarse dentro de un ReCaptchaProvider');
  }
  return context;
};
ReCaptchaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};