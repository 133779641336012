import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useDocumentTypeService } from "../service/fileService";
import { useCallback, useState } from "react";

// Constantes para las keys de cache
const DOCUMENT_TYPES_WITH_ROLE_KEY = "documentTypesWithRole";
const DOCUMENT_TYPES_BY_ROLE_KEY = "documentTypesByRole";
const DOCUMENTS_QUERY_KEY = "userDocuments";
export const useDocumentTypesWithRole = () => {
  const documentTypeService = useDocumentTypeService();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [DOCUMENT_TYPES_WITH_ROLE_KEY],
    queryFn: () => documentTypeService.getDocumentTypesWithRole(),
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
  });

  const createMutation = useMutation({
    mutationFn: (data) => documentTypeService.createDocumentType(data),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_WITH_ROLE_KEY]);
    },
  });

  const updateMutation = useMutation({
    mutationFn: ({ id, data }) => documentTypeService.updateDocumentType(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_WITH_ROLE_KEY]);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => documentTypeService.deleteDocumentType(id),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_WITH_ROLE_KEY]);
    },
  });

  return {
    ...query,
    documentTypes: query.data?.data || [],
    createDocumentType: createMutation.mutate,
    updateDocumentType: updateMutation.mutate,
    deleteDocumentType: deleteMutation.mutate,
    isLoading: query.isLoading || createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading,
    isError: query.isError || createMutation.isError || updateMutation.isError || deleteMutation.isError,
  };
};

export const useDocumentTypesByRole = (roleId) => {
  const documentTypeService = useDocumentTypeService();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [DOCUMENT_TYPES_BY_ROLE_KEY, roleId],
    queryFn: () => documentTypeService.getDocumentTypesByRole(roleId),
    enabled: !!roleId,
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
  });

  const createMutation = useMutation({
    mutationFn: (data) => documentTypeService.createDocumentType(data),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_BY_ROLE_KEY, roleId]);
    },
  });

  const updateMutation = useMutation({
    mutationFn: ({ id, data }) => documentTypeService.updateDocumentType(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_BY_ROLE_KEY, roleId]);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => documentTypeService.deleteDocumentType(id),
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENT_TYPES_BY_ROLE_KEY, roleId]);
    },
  });

  return {
    ...query,
    documentTypes: query.data?.data || [],
    createDocumentType: createMutation.mutate,
    updateDocumentType: updateMutation.mutate,
    deleteDocumentType: deleteMutation.mutate,
    isLoading: query.isLoading || createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading,
    isError: query.isError || createMutation.isError || updateMutation.isError || deleteMutation.isError,
  };
};
export const useDocuments = () => {
  const documentService = useDocumentTypeService();
  const queryClient = useQueryClient();
  
  // Estados locales para filtros y paginación
  const [filters, setFilters] = useState({
    roleId: 5,
    search: '',
    documentFilter: 'all',
    page: 1,
    limit: 10
  });

  // Función para actualizar filtros
  const updateFilters = useCallback((newFilters) => {
    setFilters(prev => ({
      ...prev,
      ...newFilters,
      // Resetear página a 1 si cambian los filtros (excepto la página misma)
      page: newFilters.hasOwnProperty('page') ? newFilters.page : 1
    }));
  }, []);

  // Consulta principal usando React Query
  const query = useQuery({
    queryKey: [DOCUMENTS_QUERY_KEY, filters],
    queryFn: () => documentService.getDocuments(filters),
    keepPreviousData: true, // Mantener datos anteriores mientras se cargan nuevos
    staleTime: 2 * 60 * 1000, // 2 minutos
    cacheTime: 5 * 60 * 1000, // 5 minutos
    refetchOnWindowFocus: false,
    retry: 1,
  });

  // Función para refrescar datos manualmente
  const refreshDocuments = useCallback(() => {
    queryClient.invalidateQueries([DOCUMENTS_QUERY_KEY, filters]);
  }, [queryClient, filters]);

  // Funciones de paginación
  const goToNextPage = useCallback(() => {
    if (query.data?.pagination?.currentPage < query.data?.pagination?.lastPage) {
      updateFilters({ page: filters.page + 1 });
    }
  }, [filters.page, query.data?.pagination, updateFilters]);

  const goToPrevPage = useCallback(() => {
    if (filters.page > 1) {
      updateFilters({ page: filters.page - 1 });
    }
  }, [filters.page, updateFilters]);

  const goToPage = useCallback((pageNumber) => {
    updateFilters({ page: pageNumber });
  }, [updateFilters]);

  return {
    // Datos y estado de la consulta
    documents: query.data?.data || [],
    pagination: query.data?.pagination || {
      total: 0,
      perPage: filters.limit,
      currentPage: filters.page,
      lastPage: 1,
      from: 0,
      to: 0
    },
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    
    // Filtros actuales
    filters,
    
    // Métodos para manejar filtros y paginación
    updateFilters,
    refreshDocuments,
    goToNextPage,
    goToPrevPage,
    goToPage,
    
    // Método para cambiar el límite de resultados por página
    setResultsPerPage: (newLimit) => updateFilters({ limit: newLimit, page: 1 }),
  };
};