import React from 'react';
import "../assets/scss/views/MessageView.scss";
import SidebarChat from '../components/chat/SidebarChat';
import Chat from '../components/chat/Chat';
export default function MessageView() {
  return (
    <div className="message-container">
    <SidebarChat />
    <Chat />
  </div>
  )
}
