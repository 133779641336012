import { useCallback, useRef } from 'react';
import { useSocket } from '../context/SocketContext';
import { useAuth } from '../context/AuthContext';
import { useNotification } from '../context/NotificationContext';

/**
 * Hook personalizado para gestionar la comunicación de documentos a través de WebSockets
 * Utiliza la conexión socket existente del SocketContext
 */
export const useDocumentSocket = () => {
  // Obtener la instancia de socket del contexto
  const socket = useSocket();
  const { user } = useAuth();
  const { showNotification } = useNotification();
  
  // Usamos una referencia para rastrear las salas (rooms) de documentos a las que nos hemos unido
  const joinedRoomsRef = useRef(new Set());
  
  /**
   * Conecta al usuario a la sala de un documento específico
   * @param {string} documentId - ID del documento
   * @param {string|null} userId - ID del usuario (opcional)
   */
  const connectToDocument = useCallback((documentId, userId) => {
    if (!socket || !documentId) return;
    
    // Evitar unirse múltiples veces a la misma sala
    if (joinedRoomsRef.current.has(documentId)) return;
    
    const userIdentifier = userId || (user?.id || 'anonymous');
    const userName = user ? `${user.first_name} ${user.first_last_name}`.trim() : 'Usuario anónimo';
    
    socket.emit('join-document', {
      documentId,
      userId: userIdentifier,
      userName: userName
    });
    
    // Registrar que nos hemos unido a esta sala
    joinedRoomsRef.current.add(documentId);
    
    console.log(`Conectado al documento: ${documentId}`);
    
    // Manejar confirmación de unión al documento
    const handleJoinConfirmation = (data) => {
      if (data.documentId === documentId) {
        showNotification({
          message: `Conectado al documento #${documentId.substring(0, 8)}...`,
          severity: 'info',
          autoHideDuration: 3000,
        });
      }
    };
    
    // Suscribirse a la confirmación
    socket.on('join-confirmed', handleJoinConfirmation);
    
    return () => {
      socket.off('join-confirmed', handleJoinConfirmation);
    };
  }, [socket, user, showNotification]);
  
  /**
   * Desconecta al usuario de la sala de un documento específico
   * @param {string} documentId - ID del documento
   */
  const disconnectFromDocument = useCallback((documentId) => {
    if (!socket || !documentId) return;
    
    // Solo intentar desconectarse si estamos unidos a esta sala
    if (joinedRoomsRef.current.has(documentId)) {
      socket.emit('leave-document', { 
        documentId,
        userId: user?.id || 'anonymous'
      });
      
      // Eliminar de nuestro registro de salas
      joinedRoomsRef.current.delete(documentId);
      
      console.log(`Desconectado del documento: ${documentId}`);
    }
  }, [socket, user]);
  
  /**
   * Suscribe a un callback para recibir actualizaciones de un documento
   * @param {string} documentId - ID del documento
   * @param {Function} callback - Función a llamar cuando se reciban actualizaciones
   * @returns {Function} Función para cancelar la suscripción
   */
  const subscribeToDocumentUpdates = useCallback((documentId, callback) => {
    if (!socket || !documentId) return () => {};
    
    const handleDocumentUpdate = (data) => {
      if (data.documentId === documentId) {
        console.log(`Recibida actualización para documento: ${documentId}`);
        callback(data.document);
      }
    };
    
    socket.on('document-updated', handleDocumentUpdate);
    
    // Devolver función para cancelar la suscripción
    return () => {
      socket.off('document-updated', handleDocumentUpdate);
    };
  }, [socket]);
  
  /**
   * Envía una actualización al documento
   * @param {string} documentId - ID del documento
   * @param {Object} data - Datos actualizados
   */
  const sendDocumentUpdate = useCallback((documentId, data) => {
    if (!socket || !documentId) return;
    
    socket.emit('update-document', {
      documentId,
      userId: user?.id || 'anonymous',
      data,
      timestamp: new Date().toISOString()
    });
  }, [socket, user]);
  
  /**
   * Suscribe a usuarios que se unen al documento
   * @param {string} documentId - ID del documento 
   * @param {Function} callback - Función a llamar cuando un usuario se une
   * @returns {Function} Función para cancelar la suscripción
   */
  const subscribeToUserJoined = useCallback((documentId, callback) => {
    if (!socket || !documentId) return () => {};
    
    const handleUserJoined = (data) => {
      if (data.documentId === documentId) {
        callback(data);
      }
    };
    
    socket.on('user-joined', handleUserJoined);
    
    return () => {
      socket.off('user-joined', handleUserJoined);
    };
  }, [socket]);
  
  /**
   * Suscribe a usuarios que abandonan el documento
   * @param {string} documentId - ID del documento
   * @param {Function} callback - Función a llamar cuando un usuario abandona
   * @returns {Function} Función para cancelar la suscripción
   */
  const subscribeToUserLeft = useCallback((documentId, callback) => {
    if (!socket || !documentId) return () => {};
    
    const handleUserLeft = (data) => {
      if (data.documentId === documentId) {
        callback(data);
      }
    };
    
    socket.on('user-left', handleUserLeft);
    
    return () => {
      socket.off('user-left', handleUserLeft);
    };
  }, [socket]);
  
  /**
   * Suscribe a errores relacionados con documentos
   * @param {Function} callback - Función a llamar cuando ocurre un error
   * @returns {Function} Función para cancelar la suscripción
   */
  const subscribeToErrors = useCallback((callback) => {
    if (!socket) return () => {};
    
    const handleError = (error) => {
      callback(error);
      
      showNotification({
        message: `Error: ${error.message || 'Error en operación de documento'}`,
        severity: 'error',
        autoHideDuration: 4000,
      });
    };
    
    socket.on('document-error', handleError);
    
    return () => {
      socket.off('document-error', handleError);
    };
  }, [socket, showNotification]);
  
  return {
    isConnected: !!socket?.connected,
    connectToDocument,
    disconnectFromDocument,
    subscribeToDocumentUpdates,
    sendDocumentUpdate,
    subscribeToUserJoined,
    subscribeToUserLeft,
    subscribeToErrors,
  };
};