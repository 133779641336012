import React, { useMemo, useState } from "react";
import { Pagination } from "@mui/material";
import clsx from "clsx";
import { useNotification } from "../../../context/NotificationContext";
import StatusDisplay from "../../loading/StatusDisplay";
import "../../../assets/scss/components/ui/tables/DocumentTypesTable.scss";
import { usePhoneCountryCodes } from "../../../hooks/userComplementaryQuery";
import PhoneCountryCodeForm from "../modals/PhoneCountryCodeForm";

const ROWS_PER_PAGE = 5;

function PhoneCountryCodesTable() {
  const {
    countryCodes, // Ahora obtenemos directamente el array memoizado
    isLoading,
    createPhoneCountryCode,
    updatePhoneCountryCode,
    deletePhoneCountryCode,
  } = usePhoneCountryCodes();

  const { showNotification } = useNotification();
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  const [theme] = useState("light");

  const paginatedData = useMemo(() => {
    const startIndex = (page - 1) * ROWS_PER_PAGE;
    const paginatedCodes = countryCodes.slice(
      startIndex,
      startIndex + ROWS_PER_PAGE
    );
    const totalPages = Math.ceil(countryCodes.length / ROWS_PER_PAGE);

    return {
      items: paginatedCodes,
      totalPages,
    };
  }, [countryCodes, page]);

  const handleOpenModal = (countryCode = null) => {
    setEditData(countryCode);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditData(null);
    setShowModal(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm("¿Está seguro de eliminar este código de país?")) {
      try {
        deletePhoneCountryCode(id);
        showNotification({
          message: "Código de país eliminado exitosamente",
          severity: "success",
        });
      } catch (error) {
        showNotification({
          message: "Error al eliminar el código de país",
          severity: "error",
        });
      }
    }
  };

  const handleSave = async (formValues) => {
    try {
      if (formValues.id) {
        // Añadimos await aquí
        updatePhoneCountryCode({
          id: formValues.id,
          data: {
            country_name: formValues.country_name,
            country_code: formValues.country_code,
            phone_length: parseInt(formValues.phone_length),
          },
        });
      } else {
        // Añadimos await aquí
        createPhoneCountryCode({
          country_name: formValues.country_name,
          country_code: formValues.country_code,
          phone_length: parseInt(formValues.phone_length),
        });
      }
  
      showNotification({
        message: `Código de país ${
          formValues.id ? "actualizado" : "creado"
        } exitosamente`,
        severity: "success",
      });
  
      handleCloseModal();
    } catch (error) {
      showNotification({
        message: `Error al ${
          formValues.id ? "actualizar" : "crear"
        } el código de país`,
        severity: "error",
      });
    }
  };

  if (isLoading) {
    return (
      <StatusDisplay type="loading" message="Cargando códigos de país..." />
    );
  }

  if (!countryCodes.length) {
    return (
      <div className={clsx("phone-country-codes-no-data", `theme-${theme}`)}>
        <StatusDisplay
          type={204}
          message="No hay códigos de país registrados"
        />
      </div>
    );
  }

  return (
    <div className="custom-table-container">
      <div className="table-header">
        <h2>Códigos Telefónicos por País y Nacionalidad</h2>
        <button
          className="btn btn-primary"
          onClick={() => handleOpenModal(null)}
        >
          Agregar
        </button>
      </div>

      <div className="table-wrapper">
        <table className="custom-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>País</th>
              <th>Código</th>
              <th>Longitud</th>
              <th>Fecha de Creación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.items.map((countryCode) => (
              <tr key={countryCode.id}>
                <td>{countryCode.id}</td>
                <td>{countryCode.country_name}</td>
                <td>{countryCode.country_code}</td>
                <td>{countryCode.phone_length}</td>
                <td>{new Date(countryCode.created_at).toLocaleDateString()}</td>
                <td className="table-actions">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={() => handleOpenModal(countryCode)}
                  >
                    Editar
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(countryCode.id)}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {paginatedData.totalPages > 1 && (
        <div className="pagination-wrapper">
          <Pagination
            count={paginatedData.totalPages}
            page={page}
            onChange={(_, value) => setPage(value)}
            shape="rounded"
            className={`custom-pagination ${theme}`}
          />
        </div>
      )}

      <PhoneCountryCodeForm
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        data={editData}
        onSave={handleSave}
        onCancel={handleCloseModal}
      />
    </div>
  );
}

export default PhoneCountryCodesTable;
