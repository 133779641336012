// src/components/modals/UpdateModal.jsx
import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

function UpdateModal({
  open,
  onClose,
  docToUpdate,
  newFile,
  setNewFile,
  onConfirmUpdate,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Actualizar Documento</DialogTitle>
      <DialogContent dividers>
        <p>Selecciona un nuevo archivo para sustituir:</p>
        <input
          type="file"
          onChange={(e) => {
            if (e.target.files?.[0]) {
              setNewFile(e.target.files[0]);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={onConfirmUpdate}
          variant="contained"
          color="primary"
          disabled={!newFile || !docToUpdate}
        >
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  docToUpdate: PropTypes.object,
  newFile: PropTypes.object,
  setNewFile: PropTypes.func.isRequired,
  onConfirmUpdate: PropTypes.func.isRequired,
};

export default UpdateModal;
