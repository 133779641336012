import React, { useState, useEffect, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Chip, IconButton } from "@mui/material";
import MoreWhiteIcon from "../assets/svg/layout/more_white.svg"
import MoreDarkIcon from "../assets/svg/layout/more.svg"
import CloseWhiteIcon from "../assets/svg/layout/close_white.svg";
import CloseDarkIcon from "../assets/svg/layout/close.svg";
import LogoIcon from "../assets/img/logotivo_v2.png"
import { useAuth } from "../context/AuthContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useLayout } from "../context/LayoutContext";
import "../assets/scss/components/Header.scss";

const Header = () => {
  const { user } = useAuth();
  const [greeting, setGreeting] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    selectedDate,
    handleDateChange,
    getCurrentDate,
    headerTitle,
    isHeaderVisible,
    theme,
  } = useLayout();

  useEffect(() => {
    const getGreeting = () => {
      const hour = new Date().getHours();
      const greetings = [
        { time: [5, 12], text: "Buenos días" },
        { time: [12, 18], text: "Buenas tardes" },
        { time: [18, 24], text: "Buenas noches" },
        { time: [0, 5], text: "Buenas noches" },
      ];

      const selectedGreeting = greetings.find(
        (g) => hour >= g.time[0] && hour < g.time[1]
      );

      return selectedGreeting ? selectedGreeting.text : "Hola";
    };

    setGreeting(getGreeting());
  }, []);

  const handleOpenDateDialog = () => {
    setOpenDateDialog(true);
  };

  const handleCloseDateDialog = () => {
    setOpenDateDialog(false);
  };

  const handleSearch = () => {
    console.log("Buscando:", searchTerm);
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Función para obtener el icono actual
  const getCurrentIcon = () => {
    const iconType = isMenuOpen ? 'close' : 'more';
    return menuIcons[iconType][theme];
  };
  
  const menuIcons = {
    more: {
      dark: MoreWhiteIcon,
      light: MoreDarkIcon
    },
    close: {
      dark: CloseWhiteIcon,
      light: CloseDarkIcon
    },
    logo: LogoIcon
  };
  const handleDateSelection = (newDate) => {
    const formattedDate = newDate.format("YYYY-MM-DD");
    handleDateChange(formattedDate);
    handleCloseDateDialog();
  };
  // Verificación de fecha actual
  const isCurrentDate = useMemo(() => {
    const today = dayjs().format("YYYY-MM-DD");
    return selectedDate === today;
  }, [selectedDate]);

  // Estilo dinámico para el Chip
  const chipClassName = useMemo(() => {
    return isCurrentDate
      ? "chip-pointer chip-current"
      : "chip-pointer chip-selected";
  }, [isCurrentDate]);

  // Determinar estilo del Chip
  const getChipStyle = () => {
    const today = dayjs().format("YYYY-MM-DD");
    return selectedDate === today ? "chip-current" : "chip-selected";
  };

  return (
    <header className={`GM__${theme}-header`}>
      <div className={`GM__${theme}-header-container`}>
        <div className={`GM__${theme}-header-container-greeting`}>
          {isHeaderVisible ? (
            <span>
              {greeting}, <strong>{user.first_name}</strong>
            </span>
          ) : (
            <span className={`GM__${theme}-route-title`}>{headerTitle}</span>
          )}
        </div>

        <div className={`GM__${theme}-header-container-center`}>
          <div className={`GM__${theme}-search-wrapper`}>
            <input
              type="text"
              placeholder="Buscar por placa, operador (cc)..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`GM__${theme}-search-input`}
            />
            <IconButton
              className={`GM__${theme}-search-button`}
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </div>
        </div>

        <div className={`GM__${theme}-header-container-right-section`}>
          <Chip
            icon={<CalendarTodayIcon />}
            label={getCurrentDate}
            variant="outlined"
            onClick={handleOpenDateDialog}
            className={`GM__${theme}-chip-pointer ${
              isCurrentDate ? "chip-current" : "chip-selected"
            }`}
          />

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={dayjs.locale("es")}
          >
            {openDateDialog && (
              <DatePicker
                label="Seleccionar Fecha"
                value={dayjs(selectedDate)}
                onChange={handleDateSelection}
                views={["year", "month", "day"]}
                open={openDateDialog}
                onClose={handleCloseDateDialog}
                slotProps={{
                  textField: {
                    fullWidth: false,
                    variant: "outlined",
                    className: `GM__${theme}-date-picker-custom`,
                  },
                }}
                className={`GM__${theme}-date-picker-modal`}
              />
            )}
          </LocalizationProvider>

          <div className={`GM__${theme}-header-actions`}>
            <div className={`GM__${theme}-action-info`}>
              <span>Conocer la página</span>
              <IconButton className={`GM__${theme}-icon-button`}>
                <HelpOutlineIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
