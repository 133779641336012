import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";

const IdentityDocumentTypeForm = forwardRef(
  ({ showModal, handleCloseModal, data, onSave, onCancel }, ref) => {
    const [formValues, setFormValues] = useState({
      id: data?.id || null,
      name: data?.name || "",
      category: data?.category || "",
      description: data?.description || "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    const validate = () => {
      const newErrors = {};
      if (!formValues.name.trim()) newErrors.name = "El nombre es obligatorio";
      if (!formValues.category.trim())
        newErrors.category = "La categoría es obligatoria";
      if (!formValues.description.trim())
        newErrors.description = "La descripción es obligatoria";
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      if (validate()) {
        onSave(formValues);
      }
    };
    useEffect(() => {
      if (data) {
        setFormValues({
          id: data?.id,
          name: data?.name,
          category: data?.category,
          description: data?.description,
        });
      }
    }, [data]);
    return (
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        className="identity-document-type-modal"
      >
        <div className="modal-content" ref={ref}>
          <form onSubmit={handleSubmit} className="custom-form">
            <h3 className="modal-title">
              {data ? "Editar Tipo de Documento" : "Nuevo Tipo de Documento"}
            </h3>

            <div className="form-group">
              <label htmlFor="name">Nombre*</label>
              <input
                id="name"
                name="name"
                className="form-control"
                value={formValues.name}
                onChange={handleChange}
                placeholder="Ej: Cédula de Ciudadanía"
              />
              {errors.name && <span className="form-error">{errors.name}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="category">Categoría*</label>
              <input
                id="category"
                name="category"
                className="form-control"
                value={formValues.category}
                onChange={handleChange}
                placeholder="Ej: CC"
              />
              {errors.category && (
                <span className="form-error">{errors.category}</span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="description">Descripción*</label>
              <textarea
                id="description"
                name="description"
                className="form-control"
                value={formValues.description}
                onChange={handleChange}
                placeholder="Describa el propósito de este tipo de documento"
              />
              {errors.description && (
                <span className="form-error">{errors.description}</span>
              )}
            </div>

            <div className="form-actions">
              <button type="submit" className="btn btn-primary">
                {data ? "Guardar cambios" : "Crear documento"}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onCancel}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
);

IdentityDocumentTypeForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default IdentityDocumentTypeForm;
