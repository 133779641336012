import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HashRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { LayoutProvider } from "./context/LayoutContext";
import AppRoutes from "./routes/AppRoutes";
import { NotificationProvider } from "./context/NotificationContext";
import { SocketProvider } from "./context/SocketContext";
import { ChatProvider } from "./context/ChatContext";
import "./assets/scss/components/Loading.scss";
import { DownloadProvider } from "./context/DownloadContext";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ReCaptchaProvider } from "./context/ReCaptchaContext";

// Configuración con opciones más agresivas de caché para mejorar rendimiento
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
      <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'head',
      }}
      language="es"
    >
      <ReCaptchaProvider>
        <NotificationProvider>
          <AuthProvider>
            <DownloadProvider>
              <LayoutProvider>
                <SocketProvider>
                  <ChatProvider>
                    <AppRoutes />
                  </ChatProvider>
                </SocketProvider>
              </LayoutProvider>
            </DownloadProvider>
          </AuthProvider>
        </NotificationProvider>
        </ReCaptchaProvider>
        </GoogleReCaptchaProvider>
      </HashRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;