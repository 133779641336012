import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence  } from 'framer-motion';
import './Aliados.scss';
import Terpel from '../../assets/svg/aliados/terpel.svg';
import GrantierraEnergi from '../../assets/svg/aliados/grantierra_energy.svg';
import Ecopetrol from '../../assets/svg/aliados/ecopetrol.svg';
import Pioneer from '../../assets/svg/aliados/pioneer.svg';
import Emerald from '../../assets/svg/aliados/emerald_energy.svg';
import Trafigura from '../../assets/svg/aliados/trafigura.svg';
import Nov from '../../assets/svg/aliados/noy.svg';
import ConfiPetrol from '../../assets/svg/aliados/confipetrol.svg';


// Importa las imágenes (asumiendo que las tendrás disponibles)
// Reemplaza estas rutas con las correctas cuando tengas los PNG
const logosRow1 = [
  { id: 1, src: Terpel, alt: 'Terpel' },
  { id: 2, src: GrantierraEnergi, alt: 'GranTierra Energy' },
  { id: 3, src: Ecopetrol, alt: 'Ecopetrol' },
  { id: 4, src: Pioneer, alt: 'Pioneer Energy Services' },
];

const logosRow2 = [
  { id: 5, src: Emerald, alt: 'Emerald Energy' },
  { id: 6, src: Trafigura, alt: 'Trafigura' },
  { id: 7, src: Nov, alt: 'NOV' },
  { id: 8, src: ConfiPetrol, alt: 'Confipetrol' },
];

// Lista de aliados terciarios (los que aparecen como texto en la parte inferior)
const aliadosTerciarios = [
  { id: 1, name: 'Weatherford' },
  { id: 2, name: 'Transportes Caribe S.A.S.' },
  { id: 3, name: 'Transportes Vigia' },
  { id: 4, name: 'Pioneer Energy Services' },
  { id: 5, name: 'Omia Colombia S.A.S.' },
  { id: 6, name: 'Oilfield Services & Supplies S.A.S.' },
  { id: 7, name: 'Tuscany South America Ltd. Sucursal Colombia' },
  { id: 8, name: 'C.I. Transfigura Petroleum Colombia S.A.S' },
];

export default function Aliados() {
  const carouselRef1 = useRef(null);
  const carouselRef2 = useRef(null);
  
  // Estado para controlar el carousel de aliados terciarios
  const [activeSlide, setActiveSlide] = useState(0);
  const slidesCount = Math.ceil(aliadosTerciarios.length / 2);

  // Navegación del carousel terciario
  const nextSlide = () => {
    setActiveSlide((prev) => (prev + 1) % slidesCount);
  };

  const prevSlide = () => {
    setActiveSlide((prev) => (prev - 1 + slidesCount) % slidesCount);
  };

  // Obtener los elementos actuales a mostrar en el carousel
  const getVisibleItems = () => {
    const start = activeSlide * 2;
    return aliadosTerciarios.slice(start, start + 2);
  };

  useEffect(() => {
    if (carouselRef1.current && carouselRef2.current) {
      // Calculamos el ancho exacto de un conjunto completo de logos
      const singleSetWidth1 = carouselRef1.current.scrollWidth / 2;
      const singleSetWidth2 = carouselRef2.current.scrollWidth / 2;
      
      // Configuramos las animaciones con valores precisos
      const animation1 = carouselRef1.current.animate(
        [
          { transform: 'translateX(0)' },
          { transform: `translateX(-${singleSetWidth1}px)` }
        ],
        {
          duration: 30000,
          iterations: Infinity,
          easing: 'linear'
        }
      );
      
      const animation2 = carouselRef2.current.animate(
        [
          { transform: `translateX(-${singleSetWidth2}px)` },
          { transform: 'translateX(0)' }
        ],
        {
          duration: 30000,
          iterations: Infinity,
          easing: 'linear'
        }
      );
      
      return () => {
        animation1.cancel();
        animation2.cancel();
      };
    }
  }, []);

  return (
    <section className="aliados-section">
      <div className="container">
        <h2 className="aliados-title">
          Nuestros <span className="aliados-title-highlight">Aliados</span>
        </h2>
        
        {/* Carruseles principales - Sin cambios */}
        <div className="carousel-container">
          <motion.div 
            className="carousel"
            ref={carouselRef1}
            animate={{ x: [0, -1000] }}
            transition={{
              repeat: Infinity,
              repeatType: "loop",
              duration: 30,
              ease: "linear"
            }}
          >
            {[...logosRow1, ...logosRow1].map((logo, index) => (
              <div className="logo-card" key={`row1-${logo.id}-${index}`}>
                <img src={logo.src} alt={logo.alt} />
              </div>
            ))}
          </motion.div>
        </div>
        
        <div className="carousel-container">
          <motion.div 
            className="carousel"
            ref={carouselRef2}
            animate={{ x: [-1000, 0] }}
            transition={{
              repeat: Infinity,
              repeatType: "loop",
              duration: 30,
              ease: "linear"
            }}
          >
            {[...logosRow2, ...logosRow2].map((logo, index) => (
              <div className="logo-card" key={`row2-${logo.id}-${index}`}>
                <img src={logo.src} alt={logo.alt} />
              </div>
            ))}
          </motion.div>
        </div>
        
        {/* Aliados terciarios - Desktop view (grid) */}
        <div className="aliados-terciarios desktop-view">
          <div className="aliados-terciarios-grid">
            {aliadosTerciarios.map((aliado) => (
              <div className="aliado-terciario-item" key={`desktop-${aliado.id}`}>
                {aliado.name}
              </div>
            ))}
          </div>
        </div>
        
        {/* Aliados terciarios - Mobile view (carousel) */}
        <div className="aliados-terciarios mobile-view">
          <div className="carousel-navigation">
            <button 
              className="nav-btn prev-btn" 
              onClick={prevSlide}
              aria-label="Aliados anteriores"
            >
              &lsaquo;
            </button>
            
            <div className="aliados-terciarios-carousel">
              <AnimatePresence mode="wait">
                <motion.div 
                  key={activeSlide}
                  className="carousel-slide"
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.3 }}
                >
                  {getVisibleItems().map((aliado) => (
                    <div className="aliado-terciario-item" key={`mobile-${aliado.id}`}>
                      {aliado.name}
                    </div>
                  ))}
                </motion.div>
              </AnimatePresence>
            </div>
            
            <button 
              className="nav-btn next-btn" 
              onClick={nextSlide}
              aria-label="Aliados siguientes"
            >
              &rsaquo;
            </button>
          </div>
          
          {/* Indicadores de posición */}
          <div className="carousel-indicators">
            {Array(slidesCount).fill(0).map((_, index) => (
              <span 
                key={index} 
                className={`indicator ${activeSlide === index ? 'active' : ''}`}
                onClick={() => setActiveSlide(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}