import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@mui/material';

const CommentForm = ({ initialData, onSubmit, onCancel, theme = 'light' }) => {
  // Inicialización del estado con los datos correctos
  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    comment_text: initialData?.comment_text || '',
    reference_id: initialData?.reference_id || null,
    reference_type: initialData?.reference_type || 'user',
    created_at: initialData?.created_at || new Date().toISOString(),
    created_by: initialData?.created_by || '',
    updated_at: initialData?.updated_at || new Date().toISOString(),
    id: initialData?.id || null
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validación básica
    if (!formData.title.trim() || !formData.comment_text.trim()) {
      return;
    }

    // Preparar datos para envío
    const submitData = {
      ...formData,
      updated_at: new Date().toISOString()
    };

    onSubmit(submitData);
  };

  return (
    <form onSubmit={handleSubmit} className={`GM__${theme}-comment-form`}>
      <TextField
        fullWidth
        label="Título"
        value={formData.title}
        onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
        margin="normal"
        required
        error={formData.title.trim() === ''}
        helperText={formData.title.trim() === '' ? 'El título es requerido' : ''}
        className={`GM__${theme}-text-field`}
      />
      <TextField
        fullWidth
        label="Comentario"
        value={formData.comment_text}
        onChange={(e) => setFormData(prev => ({ ...prev, comment_text: e.target.value }))}
        margin="normal"
        multiline
        rows={4}
        required
        error={formData.comment_text.trim() === ''}
        helperText={formData.comment_text.trim() === '' ? 'El comentario es requerido' : ''}
        className={`GM__${theme}-text-field`}
      />
      <div className={`GM__${theme}-form-actions`}>
        <Button 
          variant="outlined" 
          onClick={onCancel}
          className={`GM__${theme}-cancel-btn cancel`}
        >
          Cancelar
        </Button>
        <Button 
          variant="contained" 
          type="submit"
          disabled={!formData.title.trim() || !formData.comment_text.trim()}
          className={`GM__${theme}-submit-btn submit`}
        >
          {initialData ? 'Actualizar' : 'Crear'}
        </Button>
      </div>
    </form>
  );
};

CommentForm.propTypes = {
  initialData: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    comment_text: PropTypes.string,
    reference_id: PropTypes.string,
    reference_type: PropTypes.string,
    created_at: PropTypes.string,
    created_by: PropTypes.string,
    updated_at: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark'])
};

export default CommentForm;