import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '../../../assets/scss/components/ui/cards/FloatingCard.scss';

// Componente Divider estilizado
const Divider = styled.div`
  width: 100%;
  height: 5px;
  background-color: currentColor;
  margin-bottom: 10px;
  padding: 0 0 2px 0;
  border-radius: 0 0 10px 10px;
`;

// Card contenedor principal
const StyledCard = styled.div`
  width: 300px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: ${props => props.$colorTheme.backgroundColor};
  color: ${props => props.$colorTheme.textColor};
  position: relative;
`;

// Contenido principal
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 240px;
  padding: 10px 10px 1px 10px;
`;

const CardTitle = styled.h6`
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;

const CardValue = styled.h4`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

const FloatingCard = ({ 
  title, 
  value, 
  icon, 
  colorTheme = 'theme1' 
}) => {
  const themes = {
    theme1: {
      backgroundColor: '#9747FF',
      textColor: '#FFFFFF',
    },
    theme2: {
      backgroundColor: '#00A6FF',
      textColor: '#FFFFFF',
    },
    theme3: {
      backgroundColor: '#00A54F',
      textColor: '#FFFFFF',
    },
    theme4: {
      backgroundColor: '#BD0000',
      textColor: '#FFFFFF',
    },
  };

  const selectedTheme = themes[colorTheme] || themes['theme1'];

  return (
    <StyledCard 
      $colorTheme={selectedTheme} 
      className="floating-card"
    >
      <img 
        src={icon} 
        alt="Icon" 
        className="card-icon" 
      />

      <CardContent className="card-content">
        <CardTitle className="card-title">
          {title}
        </CardTitle>
        <CardValue className="card-value">
          {value}
        </CardValue>
      </CardContent>
      <Divider className="card-divider" />
    </StyledCard>
  );
};

FloatingCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.object
  ]).isRequired,
  colorTheme: PropTypes.oneOf(['theme1', 'theme2', 'theme3', 'theme4'])
};

export default FloatingCard;