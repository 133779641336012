import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSocket } from './SocketContext';
import PropTypes from 'prop-types';
import { useChatOperations } from '../hooks/useChatQuery';
import { useGetRoles, useGetUsersWithRole } from '../hooks/useReactQuery';
import { useAuth } from './AuthContext';

const ChatContext = createContext(null);

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const socket = useSocket();
  const { user } = useAuth();
  const [activeChatId, setActiveChatId] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState('1');
  const [onlineUsers, setOnlineUsers] = useState(new Set());
  const [typingUsers, setTypingUsers] = useState({});
  const [messagesByConversation, setMessagesByConversation] = useState({
    global: [],
    // Las demás conversaciones se agregarán dinámicamente
  });

  const usersPerRole = 5;

  const {
    useConversations,
    useConversationMessages,
    useSendMessage,
    useEditMessage,
    useDeleteMessage,
    useMarkAsRead,
  } = useChatOperations();

  const isAuthenticated = !!user;
  const { data: roles } = useGetRoles(isAuthenticated);
  const rolesData = roles?.data || [];
  const { data: usersData } = useGetUsersWithRole(1, usersPerRole, '', selectedRoleId, isAuthenticated);
  const users = usersData?.data || [];

  const { data: conversations } = useConversations();
  const { data: activeMessages } = useConversationMessages(activeChatId);

  const { mutate: editMessage } = useEditMessage();
  const { mutate: deleteMessage } = useDeleteMessage();
  const { mutate: markAsRead } = useMarkAsRead();
  const { mutate: sendMessageMutation } = useSendMessage(); 

  useEffect(() => {
    if (activeMessages?.data && activeChatId) {
      setMessagesByConversation(prev => ({
        ...prev,
        [activeChatId]: activeMessages.data
      }));
    }
  }, [activeMessages?.data, activeChatId]);



  const handleSendMessage = async (content, files = [], replyToMessageId = null) => {
    if (!content.trim() && files.length === 0) return;
  
    const messageData = {
      content,
      files,
      replyToMessageId,
      recipientId: selectedRecipient?.id,
      conversationId: activeChatId,
      sender: {
        id: user.id,
        first_name: user.first_name,
        first_last_name: user.first_last_name
      },
      created_at: new Date().toISOString(),
      id: `temp-${Date.now()}`
    };
  
    try {
      if (activeChatId === 'global') {
        socket.emit('new_message', messageData);
        // Agregar mensaje al estado local del chat global
        setMessagesByConversation(prev => ({
          ...prev,
          global: [...(prev.global || []), messageData]
        }));
        return;
      }
    
      // Chat privado
      if (!activeChatId) {
        const response = await sendMessageMutation({
          content,
          files,
          replyToMessageId,
          recipientId: selectedRecipient.id
        });
        console.log(response);
        
        const newConversationId = response.data.conversationId;
        setActiveChatId(newConversationId);
        
        // Actualizar mensajes localmente
        setMessagesByConversation(prev => ({
          ...prev,
          [newConversationId]: [response.data]
        }));
      } else {
        const response = await sendMessageMutation({
          conversationId: activeChatId,
          content,
          files,
          replyToMessageId
        });
    
        // Actualizar mensajes de la conversación existente
        setMessagesByConversation(prev => ({
          ...prev,
          [activeChatId]: [...(prev[activeChatId] || []), response.data]
        }));
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Efecto para manejar eventos de socket
  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (messageData) => {
      const conversationId = messageData.conversationId;
      
      setMessagesByConversation(prev => {
        // Verificar si el mensaje ya existe
        const currentMessages = prev[conversationId] || [];
        const messageExists = currentMessages.some(msg => 
          msg.id === messageData.id || 
          (msg.content === messageData.content && 
           msg.sender.id === messageData.sender.id &&
           Math.abs(new Date(msg.created_at) - new Date(messageData.created_at)) < 1000)
        );
        
        if (messageExists) return prev;
        
        return {
          ...prev,
          [conversationId]: [...currentMessages, messageData]
        };
      });
    };
    const socketEventHandlers = {
      message_received: handleNewMessage,
      user_connected: (userId) => {
        setOnlineUsers(prev => new Set([...prev, userId]));
      },
      user_disconnected: (userId) => {
        setOnlineUsers(prev => {
          const updated = new Set(prev);
          updated.delete(userId);
          return updated;
        });
      },
      typing_start: ({ userId, conversationId }) => {
        setTypingUsers(prev => ({
          ...prev,
          [conversationId]: [...(prev[conversationId] || []), userId]
        }));
      },
      typing_end: ({ userId, conversationId }) => {
        setTypingUsers(prev => ({
          ...prev,
          [conversationId]: (prev[conversationId] || []).filter(id => id !== userId)
        }));
      }
    };

    Object.entries(socketEventHandlers).forEach(([event, handler]) => {
      socket.on(event, handler);
    });

    return () => {
      Object.keys(socketEventHandlers).forEach(event => {
        socket.off(event);
      });
    };
  }, [socket, activeChatId]);
  useEffect(() => {
  if (!socket) return;

  const handleUsersStatusUpdate = (usersStatus) => {
    const onlineUserIds = new Set(usersStatus.map(status => status.userId));
    setOnlineUsers(onlineUserIds);
  };

  socket.on('users_status_update', handleUsersStatusUpdate);

  // Solicitar estado inicial de usuarios
  socket.emit('request_users_status');

  return () => {
    socket.off('users_status_update', handleUsersStatusUpdate);
  };
}, [socket]);
useEffect(() => {
  if (activeChatId) {
    // Asegurarse de que la conversación tenga un array de mensajes inicializado
    setMessagesByConversation(prev => ({
      ...prev,
      [activeChatId]: prev[activeChatId] || []
    }));
  }
}, [activeChatId]);
useEffect(() => {
  const loadConversationMessages = async () => {
    if (activeChatId === 'global') {
      // Para el chat global, mantenemos los mensajes existentes
      return;
    }

    if (activeChatId) {
      try {
        // Para conversaciones privadas
        const response = await activeMessages;
        if (response?.data) {
          setMessagesByConversation(prev => ({
            ...prev,
            [activeChatId]: response.data
          }));
          markAsRead(activeChatId);
        }
      } catch (error) {
        console.error('Error loading messages:', error);
      }
    }
  };

  loadConversationMessages();
}, [activeChatId, activeMessages]);
  
  const value = {
    conversations: conversations?.data || [],
    messages: messagesByConversation[activeChatId] || [],
    activeChatId,
    selectedRecipient,
    selectedRoleId,
    onlineUsers,
    typingUsers,
    users,
    rolesData,
    setActiveChatId,
    setSelectedRecipient,
    setSelectedRoleId,
    sendMessage: handleSendMessage,
    editMessage,
    deleteMessage,
    markAsRead,
    setMessagesByConversation
  };

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
};