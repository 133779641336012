import { useApi } from "./api";

export const useDocumentTypeService = () => {
  const api = useApi();
  
  return {
    getAllDocumentTypes: async () => {
      try {
        const response = await api.get("/document-types");
        return response.data;
      } catch (error) {
        console.error("Error obteniendo tipos de documentos:", error);
        throw error.response?.data || error.message;
      }
    },

    getDocumentTypeById: async (id) => {
      try {
        const response = await api.get(`/document-types/${id}`);
        return response.data;
      } catch (error) {
        console.error("Error obteniendo tipo de documento:", error);
        throw error.response?.data || error.message;
      }
    },

    createDocumentType: async (documentType) => {
      try {
        const response = await api.post("/document-types", documentType);
        return response.data;
      } catch (error) {
        console.error("Error creando tipo de documento:", error);
        throw error.response?.data || error.message;
      }
    },

    updateDocumentType: async (id, documentType) => {
      try {
        const response = await api.put(`/document-types/${id}`, documentType);
        return response.data;
      } catch (error) {
        console.error("Error actualizando tipo de documento:", error);
        throw error.response?.data || error.message;
      }
    },

    deleteDocumentType: async (id) => {
      try {
        const response = await api.delete(`/document-types/${id}`);
        return response.data;
      } catch (error) {
        console.error("Error eliminando tipo de documento:", error);
        throw error.response?.data || error.message;
      }
    },
  };
};
export const usePhoneCountryCodeService = () => {
  const api = useApi();
  
  return {
    getAllPhoneCountryCodes: async () => {
      try {
        const response = await api.get("/phone-country-codes");
        return response.data;
      } catch (error) {
        console.error("Error obteniendo códigos de país:", error);
        throw error.response?.data || error.message;
      }
    },

    getPhoneCountryCodeById: async (id) => {
      try {
        const response = await api.get(`/phone-country-codes/${id}`);
        return response.data;
      } catch (error) {
        console.error("Error obteniendo código de país:", error);
        throw error.response?.data || error.message;
      }
    },

    createPhoneCountryCode: async (countryCode) => {
      try {
        const response = await api.post("/phone-country-codes", countryCode);
        return response.data;
      } catch (error) {
        console.error("Error creando código de país:", error);
        throw error.response?.data || error.message;
      }
    },

    updatePhoneCountryCode: async (id, countryCode) => {
      try {
        const response = await api.put(`/phone-country-codes/${id}`, countryCode);
        return response.data;
      } catch (error) {
        console.error("Error actualizando código de país:", error);
        throw error.response?.data || error.message;
      }
    },

    deletePhoneCountryCode: async (id) => {
      try {
        const response = await api.delete(`/phone-country-codes/${id}`);
        return response.data;
      } catch (error) {
        console.error("Error eliminando código de país:", error);
        throw error.response?.data || error.message;
      }
    },
  };
};
