import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";

const PhoneCountryCodeForm = forwardRef(({ showModal, handleCloseModal, data, onSave, onCancel }, ref) => {
  const [formValues, setFormValues] = useState({
    id: data?.id || null,
    country_name: data?.country_name || "",
    country_code: data?.country_code || "",
    phone_length: data?.phone_length || "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.country_name.trim()) {
      newErrors.country_name = "El nombre del país es obligatorio";
    }
    if (!formValues.country_code.trim()) {
      newErrors.country_code = "El código es obligatorio";
    }
    if (!formValues.phone_length) {
      newErrors.phone_length = "La longitud es obligatoria";
    }
    if (isNaN(formValues.phone_length) || formValues.phone_length < 1) {
      newErrors.phone_length = "La longitud debe ser un número válido mayor a 0";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      onSave(formValues);
    }
  };
  useEffect(() => {
    if (data) {
      setFormValues({
        id: data.id,
        country_name: data.country_name,
        country_code: data.country_code,
        phone_length: data.phone_length,
      });
    }
  }, [data]);
  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      className="phone-country-code-modal"
    >
      <div className="modal-content" ref={ref}>
        <form onSubmit={handleSubmit} className="custom-form">
          <h3 className="modal-title">
            {data ? "Editar Código de País" : "Nuevo Código de País"}
          </h3>

          <div className="form-group">
            <label htmlFor="country_name">País*</label>
            <input
              id="country_name"
              name="country_name"
              className="form-control"
              value={formValues.country_name}
              onChange={handleChange}
              placeholder="Ej: Colombia"
            />
            {errors.country_name && (
              <span className="form-error">{errors.country_name}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="country_code">Código*</label>
            <input
              id="country_code"
              name="country_code"
              className="form-control"
              value={formValues.country_code}
              onChange={handleChange}
              placeholder="Ej: +57"
            />
            {errors.country_code && (
              <span className="form-error">{errors.country_code}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="phone_length">Longitud del número*</label>
            <input
              id="phone_length"
              name="phone_length"
              type="number"
              className="form-control"
              value={formValues.phone_length}
              onChange={handleChange}
              placeholder="Ej: 10"
              min="1"
            />
            {errors.phone_length && (
              <span className="form-error">{errors.phone_length}</span>
            )}
          </div>

          <div className="form-actions">
            <button type="submit" className="btn btn-primary">
              {data ? "Guardar cambios" : "Crear código"}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCancel}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
});

PhoneCountryCodeForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    country_name: PropTypes.string,
    country_code: PropTypes.string,
    phone_length: PropTypes.number,
  }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired
};


export default PhoneCountryCodeForm;