import { useApi } from "./api";

export const useDocumentTypeService = () => {
  const api = useApi();
  
  return {
    // Obtener todos los tipos de documento
    getAllDocumentTypes: async () => {
      try {
        const response = await api.get("/files/document-types");
        return response.data;
      } catch (error) {
        console.error("Error obteniendo todos los tipos de documentos:", error);
        throw error.response?.data || error.message;
      }
    },

    // Obtener tipos de documento por rol
    getDocumentTypesByRole: async (roleId) => {
      try {
        const response = await api.get(`/files/document-types/${roleId}`);
        return response.data;
      } catch (error) {
        console.error(`Error obteniendo tipos de documentos para el rol ${roleId}:`, error);
        throw error.response?.data || error.message;
      }
    },

    // Crear nuevo tipo de documento
    createDocumentType: async (documentTypeData) => {
      try {
        const response = await api.post("/files/document-types", documentTypeData);
        return response.data;
      } catch (error) {
        console.error("Error creando tipo de documento:", error);
        throw error.response?.data || error.message;
      }
    },

    // Actualizar tipo de documento existente
    updateDocumentType: async (id, documentTypeData) => {
      try {
        const response = await api.put(`/files/document-types/${id}`, documentTypeData);
        return response.data;
      } catch (error) {
        console.error(`Error actualizando tipo de documento ${id}:`, error);
        throw error.response?.data || error.message;
      }
    },

    // Eliminar tipo de documento
    deleteDocumentType: async (id) => {
      try {
        const response = await api.delete(`/files/document-types/${id}`);
        return response.data;
      } catch (error) {
        console.error(`Error eliminando tipo de documento ${id}:`, error);
        throw error.response?.data || error.message;
      }
    },

    // Obtener tipos de documento con información del rol
    getDocumentTypesWithRole: async () => {
      try {
        const response = await api.get("/files/document-types-with-role");
        return response.data;
      } catch (error) {
        console.error("Error obteniendo tipos de documentos con roles:", error);
        throw error.response?.data || error.message;
      }
    },
    getDocuments: async ({ 
      roleId = null, 
      search = '', 
      documentFilter = 'all', 
      page = 1, 
      limit = 10 
    } = {}) => {
      try {
        const params = new URLSearchParams();
        if (roleId) params.append('roleId', roleId);
        if (search) params.append('search', search);
        params.append('documentFilter', documentFilter);
        params.append('page', page);
        params.append('limit', limit);

        const response = await api.get(`/currents-documents?${params.toString()}`);
        return response.data;
      } catch (error) {
        console.error("Error obteniendo documentos:", error);
        throw error.response?.data || error.message;
      }
    },
    
    // Obtener un documento específico por usuario y tipo
    getDocumentByUserAndType: async (userId, typeId) => {
      try {
        const response = await api.get(`/documents/${userId}/type/${typeId}`);
        return response.data;
      } catch (error) {
        console.error("Error obteniendo documento específico:", error);
        throw error.response?.data || error.message;
      }
    }
  };
};