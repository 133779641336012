import React from 'react';
import { Circle, Polyline, Marker } from '@react-google-maps/api';

const GeofenceLayer = ({ geofences = [], activeGeofenceId, onGeofenceClick }) => {
  if (!geofences.length) return null;

  return (
    <>
      {geofences.map((geofence) => {
        const isActive = geofence.id === activeGeofenceId;
        
        return (
          <React.Fragment key={geofence.id}>
            {/* Marcador de inicio */}
            <Marker
              position={geofence.start}
              label={{
                text: `Inicio: ${geofence.name}`,
                className: "marker-label",
              }}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: isActive ? 8 : 6,
                fillColor: "#4CAF50",
                fillOpacity: 1,
                strokeColor: "#ffffff",
                strokeWeight: 2,
              }}
              onClick={() => onGeofenceClick && onGeofenceClick(geofence.id)}
            />
            
            {/* Marcador de fin */}
            <Marker
              position={geofence.end}
              label={{
                text: `Fin: ${geofence.name}`,
                className: "marker-label",
              }}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: isActive ? 8 : 6,
                fillColor: "#F44336",
                fillOpacity: 1,
                strokeColor: "#ffffff",
                strokeWeight: 2,
              }}
            />
            
            {/* Línea conectora */}
            <Polyline
              path={[geofence.start, geofence.end]}
              options={{
                strokeColor: isActive ? "#FF4081" : "#FF9800",
                strokeOpacity: 0.8,
                strokeWeight: isActive ? 4 : 3,
              }}
            />
            
            {/* Círculos (geocercas) */}
            {geofence.circles && geofence.circles.map((circle, index) => (
              <Circle
                key={`${geofence.id}-circle-${index}`}
                center={circle.center}
                radius={circle.radius}
                options={{
                  fillColor: geofence.color || "#FF0000",
                  fillOpacity: 0.2,
                  strokeColor: geofence.color || "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: isActive ? 2 : 1,
                }}
              />
            ))}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default GeofenceLayer;