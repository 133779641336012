import React, { useMemo, useState } from "react";
import { Pagination } from "@mui/material";
import clsx from "clsx";
import { useNotification } from "../../../context/NotificationContext";
import StatusDisplay from "../../loading/StatusDisplay";
import "../../../assets/scss/components/ui/tables/GenericDataTable.scss";
import { useGetRoles } from "../../../hooks/useReactQuery";
import DocumentTypeForm from "../modals/DocumentTypeForm";
import { useDocumentTypesWithRole } from "../../../hooks/useFileQuery";

const ROWS_PER_PAGE = 5;
function DocumentTypesTable() {
  const {
    documentTypes,
    isLoading,
    createDocumentType,
    updateDocumentType,
    deleteDocumentType
  } = useDocumentTypesWithRole();
  const { data: roles, isLoading: isLoadingRoles } = useGetRoles();
  const rol = roles?.data || [];

  const { showNotification } = useNotification();
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [page, setPage] = useState(1);
  const [theme] = useState("light");

  const paginatedData = useMemo(() => {
    let filteredDocs = documentTypes;

    // Aplicar filtro de búsqueda
    if (searchTerm) {
      filteredDocs = filteredDocs.filter(
        (doc) =>
          doc.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          doc.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Aplicar filtro de rol - convertimos a string para la comparación
    if (selectedRole) {
      filteredDocs = filteredDocs.filter(
        (doc) => doc.role_id.toString() === selectedRole.toString()
      );
    }

    const startIndex = (page - 1) * ROWS_PER_PAGE;
    const paginatedDocs = filteredDocs.slice(
      startIndex,
      startIndex + ROWS_PER_PAGE
    );
    const totalPages = Math.ceil(filteredDocs.length / ROWS_PER_PAGE);

    return {
      items: paginatedDocs,
      totalPages,
    };
  }, [documentTypes, page, searchTerm, selectedRole]);

  const handleOpenModal = (documentType = null) => {
    setEditData(documentType);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditData(null);
    setShowModal(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm("¿Está seguro de eliminar este tipo de documento?")) {
      try {
        deleteDocumentType(id);
        showNotification({
          message: "Tipo de documento eliminado exitosamente",
          severity: "success"
        });
      } catch (error) {
        showNotification({
          message: "Error al eliminar el tipo de documento",
          severity: "error"
        });
      }
    }
  };


  const handleSave = async (formValues) => {
    try {
      if (formValues.id) {
        updateDocumentType({
          id: formValues.id,
          data: {
            name: formValues.name,
            description: formValues.description,
            role_id: formValues.role_id,
            active: formValues.active
          }
        });
      } else {
        createDocumentType({
          name: formValues.name,
          description: formValues.description,
          role_id: formValues.role_id,
          active: formValues.active
        });
      }

      showNotification({
        message: `Tipo de documento ${formValues.id ? "actualizado" : "creado"} exitosamente`,
        severity: "success"
      });

      handleCloseModal();
    } catch (error) {
      showNotification({
        message: `Error al ${formValues.id ? "actualizar" : "crear"} el tipo de documento`,
        severity: "error"
      });
    }
  };

  if (isLoading) {
    return (
      <StatusDisplay type="loading" message="Cargando tipos de documentos..." />
    );
  }

  if (!documentTypes.length) {
    return (
      <div className={clsx("document-types-no-data", `theme-${theme}`)}>
        <StatusDisplay
          type={204}
          message="No hay tipos de documentos registrados"
        />
      </div>
    );
  }
  const handleRoleChange = (e) => {
    const value = e.target.value;
    setSelectedRole(value);
  };
  return (
    <div className="custom-table-container">
      <div className="table-header">
        <h2>Tipos de Documentos por Rol</h2>

        <div className="table-controls">
          <div className="search-container">
            <input
              type="text"
              placeholder="Buscar por nombre o descripción..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
          <div className="filter-container">
            <select
              value={selectedRole}
              onChange={handleRoleChange}
              className="role-filter"
            >
              <option value="">Todos los roles</option>
              {rol?.map((role) => (
                <option key={role.id} value={role.id.toString()}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => handleOpenModal(null)}
          >
            Agregar
          </button>
        </div>
      </div>

      <div className="table-wrapper">
        <table className="custom-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Rol</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.items.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>{item.role_name}</td>
                <td>
                  <span
                    className={`status-indicator ${
                      item.active ? "status-active" : "status-inactive"
                    }`}
                  >
                    {item.active ? "Activo" : "Inactivo"}
                  </span>
                </td>
                <td className="table-actions">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={() => handleOpenModal(item)}
                  >
                    Editar
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(item.id)}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {paginatedData.totalPages > 1 && (
        <div className="pagination-wrapper">
          <Pagination
            count={paginatedData.totalPages}
            page={page}
            onChange={(_, value) => setPage(value)}
            shape="rounded"
            className={`custom-pagination ${theme}`}
          />
        </div>
      )}

      <DocumentTypeForm
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        data={editData}
        onSave={handleSave}
        onCancel={handleCloseModal}
        roles={rol}
        isLoadingRoles={isLoadingRoles}
      />
    </div>
  );
}

export default DocumentTypesTable;
