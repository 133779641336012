import React from "react";
import "../../../assets/scss/components/ui/btn/ButtonDinamic.scss";
import PropTypes from "prop-types";

const ButtonDinamic = ({
  type = "medium", // Valores posibles: 'long', 'medium', 'small'
  theme = "light", // Valores posibles: 'light', 'dark'
  iconOne = null,
  iconTwo = null,
  iconPosition = "start", // Valores posibles: 'start', 'end'
  variant = "primary", // prop para diferentes variantes
  disabled = false,    // prop para estado deshabilitado
  loading = false,     // prop para estado de carga
  children,
  onClick,
  className,
  ...props
}) => {
  const buttonClasses = `
    custom-button 
    custom-button--${type} 
    custom-button--${theme}
    custom-button--${variant}
    ${disabled ? 'custom-button--disabled' : ''}
    ${loading ? 'custom-button--loading' : ''}
    ${className || ""}
    ${iconOne && iconTwo ? "custom-button--dual-icons" : ""}
    ${iconOne && !iconTwo ? `custom-button--single-icon-${iconPosition}` : ""}
  `.trim();

  const handleClick = (e) => {
    if (disabled || loading) return;
    onClick?.(e);
  };

  // Renderizado condicional para el estado de carga
  const renderLoadingSpinner = () => (
    <span className="custom-button__spinner">
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2" />
      </svg>
    </span>
  );

  return (
    <button 
      className={buttonClasses} 
      onClick={handleClick}
      disabled={disabled || loading}
      {...props}
    >
      {loading && renderLoadingSpinner()}
      
      {!loading && iconOne && (iconTwo || iconPosition === "start") && (
        <span className="custom-button__icon custom-button__icon--start">
          <img src={iconOne} alt="Icon two" />
        </span>
      )}

      <span className="custom-button__text">{children}</span>

      {!loading && (iconTwo || (iconOne && iconPosition === "end")) && (
        <span className="custom-button__icon custom-button__icon--end">
          {iconTwo ? <img src={iconTwo} alt="Icon two" /> : <img src={iconOne} alt="Icon one" />}
        </span>
      )}
    </button>
  );
};

ButtonDinamic.propTypes = {
  type: PropTypes.oneOf(["long", "medium", "small"]),
  theme: PropTypes.oneOf(["light", "dark"]),
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  iconOne: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
  iconTwo: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
  iconPosition: PropTypes.oneOf(["start", "end"]),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ButtonDinamic;