import { useApi } from "./api";

/**
 * Servicio para la gestión de operaciones en el sistema de documentación vehicular.
 * Proporciona métodos para realizar operaciones CRUD y consultas relacionadas con las operaciones logísticas.
 * @returns {Object} Colección de métodos para interactuar con los endpoints de operaciones
 */
export const useOperationService = () => {
  // Obtener instancia de cliente HTTP con configuración base (incluye prefijo '/vehicle')
  const api = useApi();
  
  return {
    /**
     * Obtiene todas las operaciones con soporte para paginación, búsqueda y ordenamiento
     * @param {Object} params Parámetros de consulta para filtrado y paginación
     * @param {Number} params.limit Cantidad máxima de registros a retornar (default: 5)
     * @param {Number} params.offset Desplazamiento para paginación
     * @param {String} params.search Término de búsqueda para filtrar por nombre
     * @param {String} params.orderBy Campo para ordenamiento (default: created_at)
     * @param {String} params.orderDirection Dirección del ordenamiento (ASC/DESC)
     * @returns {Promise<Object>} Promesa que resuelve a los datos de operaciones
     */
    getAllOperations: async (params = {}) => {
      try {
        const response = await api.get("/vehicle/operations", { params });
        return response.data;
      } catch (error) {
        console.error("Error obteniendo operaciones:", error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Obtiene una operación específica por su ID
     * @param {String} id Identificador UUID de la operación
     * @returns {Promise<Object>} Promesa que resuelve a los datos de la operación
     */
    getOperationById: async (id) => {
      try {
        const response = await api.get(`/vehicle/operations/${id}`);
        return response.data;
      } catch (error) {
        console.error(`Error obteniendo operación con ID ${id}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Crea una nueva operación
     * @param {Object} data Datos de la operación a crear
     * @param {String} data.name Nombre de la operación
     * @returns {Promise<Object>} Promesa que resuelve a los datos de la nueva operación
     */
    createOperation: async (data) => {
      try {
        const response = await api.post("/vehicle/operations", data);
        return response.data;
      } catch (error) {
        console.error("Error creando operación:", error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Actualiza una operación existente
     * @param {String} id Identificador UUID de la operación
     * @param {Object} data Datos actualizados de la operación
     * @param {String} data.name Nombre actualizado de la operación
     * @returns {Promise<Object>} Promesa que resuelve a los datos de la operación actualizada
     */
    updateOperation: async (id, data) => {
      try {
        const response = await api.put(`/vehicle/operations/${id}`, data);
        return response.data;
      } catch (error) {
        console.error(`Error actualizando operación con ID ${id}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Elimina una operación
     * @param {String} id Identificador UUID de la operación
     * @returns {Promise<Object>} Promesa que resuelve al resultado de la eliminación
     */
    deleteOperation: async (id) => {
      try {
        const response = await api.delete(`/vehicle/operations/${id}`);
        return response.data;
      } catch (error) {
        console.error(`Error eliminando operación con ID ${id}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Obtiene todos los documentos requeridos para una operación específica
     * @param {String} operationId Identificador UUID de la operación
     * @returns {Promise<Object>} Promesa que resuelve a los documentos requeridos
     */
    getDocumentsByOperation: async (operationId) => {
      try {
        const response = await api.get(`/vehicle/operations/${operationId}/documents`);
        return response.data;
      } catch (error) {
        console.error(`Error obteniendo documentos para operación ${operationId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Asigna un documento a una operación
     * @param {String} operationId Identificador UUID de la operación
     * @param {String} documentId Identificador UUID del documento
     * @returns {Promise<Object>} Promesa que resuelve al resultado de la asignación
     */
    assignDocumentToOperation: async (operationId, documentId) => {
      try {
        const response = await api.post(`/vehicle/operations/${operationId}/documents/${documentId}`);
        return response.data;
      } catch (error) {
        console.error(`Error asignando documento ${documentId} a operación ${operationId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Elimina la asignación de un documento a una operación
     * @param {String} operationId Identificador UUID de la operación
     * @param {String} documentId Identificador UUID del documento
     * @returns {Promise<Object>} Promesa que resuelve al resultado de la eliminación
     */
    removeDocumentFromOperation: async (operationId, documentId) => {
      try {
        const response = await api.delete(`/vehicle/operations/${operationId}/documents/${documentId}`);
        return response.data;
      } catch (error) {
        console.error(`Error eliminando documento ${documentId} de operación ${operationId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Asigna múltiples documentos a una operación (reemplaza asignaciones existentes)
     * @param {String} operationId Identificador UUID de la operación
     * @param {Array<String>} documentIds Array de identificadores UUID de documentos
     * @returns {Promise<Object>} Promesa que resuelve al resultado de la asignación
     */
    assignDocumentsToOperation: async (operationId, documentIds) => {
      try {
        const response = await api.post(`/vehicle/operations/${operationId}/documents`, { documentIds });
        return response.data;
      } catch (error) {
        console.error(`Error asignando documentos a operación ${operationId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    }
  };
};

/**
 * Servicio para la gestión de tipos de documentos en el sistema de documentación vehicular.
 * Proporciona métodos para realizar operaciones CRUD y consultas relacionadas con los tipos de documentos.
 * @returns {Object} Colección de métodos para interactuar con los endpoints de tipos de documentos
 */
export const useDocumentTypeService = () => {
  const api = useApi();
  
  return {
    /**
     * Obtiene todos los tipos de documentos con soporte para paginación, búsqueda y ordenamiento
     * @param {Object} params Parámetros de consulta para filtrado y paginación
     * @param {Number} params.limit Cantidad máxima de registros a retornar (default: 5)
     * @param {Number} params.offset Desplazamiento para paginación
     * @param {String} params.search Término de búsqueda para filtrar por nombre o descripción
     * @param {String} params.orderBy Campo para ordenamiento (default: name)
     * @param {String} params.orderDirection Dirección del ordenamiento (ASC/DESC)
     * @returns {Promise<Object>} Promesa que resuelve a los datos de tipos de documentos
     */
    getAllDocumentTypes: async (params = {}) => {
      try {
        const response = await api.get("/vehicle/document-vehicle-types", { params });
        return response.data;
      } catch (error) {
        console.error("Error obteniendo tipos de documentos:", error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Obtiene un tipo de documento específico por su ID
     * @param {String} id Identificador UUID del tipo de documento
     * @returns {Promise<Object>} Promesa que resuelve a los datos del tipo de documento
     */
    getDocumentTypeById: async (id) => {
      try {
        const response = await api.get(`/vehicle/document-vehicle-types/${id}`);
        return response.data;
      } catch (error) {
        console.error(`Error obteniendo tipo de documento con ID ${id}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Crea un nuevo tipo de documento
     * @param {Object} data Datos del tipo de documento a crear
     * @param {String} data.name Nombre del tipo de documento
     * @param {String} [data.description] Descripción opcional del tipo de documento
     * @returns {Promise<Object>} Promesa que resuelve a los datos del nuevo tipo de documento
     */
    createDocumentType: async (data) => {
      try {
        const response = await api.post("/vehicle/document-vehicle-types", data);
        return response.data;
      } catch (error) {
        console.error("Error creando tipo de documento:", error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Actualiza un tipo de documento existente
     * @param {String} id Identificador UUID del tipo de documento
     * @param {Object} data Datos actualizados del tipo de documento
     * @param {String} data.name Nombre actualizado del tipo de documento
     * @param {String} [data.description] Descripción actualizada del tipo de documento
     * @returns {Promise<Object>} Promesa que resuelve a los datos del tipo de documento actualizado
     */
    updateDocumentType: async (id, data) => {
      try {
        const response = await api.put(`/vehicle/document-vehicle-types/${id}`, data);
        return response.data;
      } catch (error) {
        console.error(`Error actualizando tipo de documento con ID ${id}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Elimina un tipo de documento
     * @param {String} id Identificador UUID del tipo de documento
     * @returns {Promise<Object>} Promesa que resuelve al resultado de la eliminación
     */
    deleteDocumentType: async (id) => {
      try {
        const response = await api.delete(`/vehicle/document-vehicle-types/${id}`);
        return response.data;
      } catch (error) {
        console.error(`Error eliminando tipo de documento con ID ${id}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Obtiene todas las operaciones que requieren un documento específico
     * @param {String} documentId Identificador UUID del documento
     * @returns {Promise<Object>} Promesa que resuelve a las operaciones que requieren el documento
     */
    getOperationsByDocument: async (documentId) => {
      try {
        const response = await api.get(`/vehicle/document-vehicle-types/${documentId}/operations`);
        return response.data;
      } catch (error) {
        console.error(`Error obteniendo operaciones para documento ${documentId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Obtiene todos los tipos de vehículos que requieren un documento específico
     * @param {String} documentId Identificador UUID del documento
     * @returns {Promise<Object>} Promesa que resuelve a los tipos de vehículos que requieren el documento
     */
    getVehicleTypesByDocument: async (documentId) => {
      try {
        const response = await api.get(`/vehicle/vehicle/document-vehicle-types/${documentId}/vehicle-types`);
        return response.data;
      } catch (error) {
        console.error(`Error obteniendo tipos de vehículos para documento ${documentId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    }
  };
};

/**
 * Servicio para la gestión de relaciones entre tipos de vehículos y documentos.
 * Proporciona métodos para asignar y gestionar los requisitos documentales por tipo de vehículo.
 * @returns {Object} Colección de métodos para interactuar con los endpoints de relaciones vehículo-documento
 */
export const useVehicleDocumentService = () => {
  const api = useApi();
  
  return {
    /**
     * Obtiene todos los documentos requeridos para un tipo de vehículo específico
     * @param {String} vehicleTypeId Identificador UUID del tipo de vehículo
     * @returns {Promise<Object>} Promesa que resuelve a los documentos requeridos
     */
    getDocumentsByVehicleType: async (vehicleTypeId) => {
      try {
        const response = await api.get(`/vehicle/vehicle-types/${vehicleTypeId}/documents`);
        return response.data;
      } catch (error) {
        console.error(`Error obteniendo documentos para tipo de vehículo ${vehicleTypeId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Asigna un documento a un tipo de vehículo
     * @param {String} vehicleTypeId Identificador UUID del tipo de vehículo
     * @param {String} documentId Identificador UUID del documento
     * @returns {Promise<Object>} Promesa que resuelve al resultado de la asignación
     */
    assignDocumentToVehicleType: async (vehicleTypeId, documentId) => {
      try {
        const response = await api.post(`/vehicle/vehicle-types/${vehicleTypeId}/documents/${documentId}`);
        return response.data;
      } catch (error) {
        console.error(`Error asignando documento ${documentId} a tipo de vehículo ${vehicleTypeId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Elimina la asignación de un documento a un tipo de vehículo
     * @param {String} vehicleTypeId Identificador UUID del tipo de vehículo
     * @param {String} documentId Identificador UUID del documento
     * @returns {Promise<Object>} Promesa que resuelve al resultado de la eliminación
     */
    removeDocumentFromVehicleType: async (vehicleTypeId, documentId) => {
      try {
        const response = await api.delete(`/vehicle/vehicle-types/${vehicleTypeId}/documents/${documentId}`);
        return response.data;
      } catch (error) {
        console.error(`Error eliminando documento ${documentId} de tipo de vehículo ${vehicleTypeId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Asigna múltiples documentos a un tipo de vehículo (reemplaza asignaciones existentes)
     * @param {String} vehicleTypeId Identificador UUID del tipo de vehículo
     * @param {Array<String>} documentIds Array de identificadores UUID de documentos
     * @returns {Promise<Object>} Promesa que resuelve al resultado de la asignación
     */
    assignDocumentsToVehicleType: async (vehicleTypeId, documentIds) => {
      try {
        const response = await api.post(`/vehicle/vehicle-types/${vehicleTypeId}/documents`, { documentIds });
        return response.data;
      } catch (error) {
        console.error(`Error asignando documentos a tipo de vehículo ${vehicleTypeId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    }
  };
};

/**
 * Servicio para consultas complejas y agregadas en el sistema de documentación vehicular.
 * Proporciona métodos para realizar análisis, comparativas y verificaciones de cumplimiento documental.
 * @returns {Object} Colección de métodos para interactuar con los endpoints de consultas complejas
 */
export const useDocumentationAnalyticsService = () => {
  const api = useApi();
  
  return {
    /**
     * Obtiene requisitos documentales organizados por tipo de vehículo para una operación
     * @param {String} operationId Identificador UUID de la operación
     * @returns {Promise<Object>} Promesa que resuelve a los requisitos organizados por tipo de vehículo
     */
    getDocumentRequirementsByOperation: async (operationId) => {
      try {
        const response = await api.get(`/vehicle/documentation/operation-requirements/${operationId}`);
        return response.data;
      } catch (error) {
        console.error(`Error obteniendo requisitos para operación ${operationId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Obtiene un resumen global de requisitos documentales por documento
     * @returns {Promise<Object>} Promesa que resuelve al resumen de requisitos
     */
    getDocumentRequirementsSummary: async () => {
      try {
        const response = await api.get("/vehicle/documentation/requirements-summary");
        return response.data;
      } catch (error) {
        console.error("Error obteniendo resumen de requisitos documentales:", error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Verifica documentos faltantes para un vehículo en una operación específica
     * @param {String} vehicleId Identificador UUID del vehículo
     * @param {String} operationId Identificador UUID de la operación
     * @returns {Promise<Object>} Promesa que resuelve a la lista de documentos faltantes
     */
    getMissingDocumentsForVehicle: async (vehicleId, operationId) => {
      try {
        const response = await api.get(`/vehicle/documentation/missing-documents/${vehicleId}/${operationId}`);
        return response.data;
      } catch (error) {
        console.error(`Error verificando documentos faltantes para vehículo ${vehicleId} en operación ${operationId}:`, error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Compara requisitos documentales entre múltiples operaciones
     * @param {Array<String>} operationIds Array de identificadores UUID de operaciones a comparar
     * @returns {Promise<Object>} Promesa que resuelve a la matriz comparativa
     */
    compareOperationRequirements: async (operationIds) => {
      try {
        // Convertir array a string separada por comas para parámetro de consulta
        const operationIdsParam = operationIds.join(',');
        const response = await api.get(`/vehicle/documentation/compare-operations`, {
          params: { operationIds: operationIdsParam }
        });
        return response.data;
      } catch (error) {
        console.error("Error comparando requisitos entre operaciones:", error);
        throw error.response?.data || { message: error.message };
      }
    },
    
    /**
     * Obtiene estadísticas globales sobre documentación y cumplimiento
     * @returns {Promise<Object>} Promesa que resuelve a las estadísticas
     */
    getDocumentationStatistics: async () => {
      try {
        const response = await api.get("/vehicle/documentation/statistics");
        return response.data;
      } catch (error) {
        console.error("Error obteniendo estadísticas de documentación:", error);
        throw error.response?.data || { message: error.message };
      }
    }
  };
};