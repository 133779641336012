import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import './PublicHeader.scss';
import LogoPublic from '../assets/img/Logo_base.png';
import LogoPublicMin from '../assets/img/logotipo_cootranspetrols.png';

// Material UI
import { Chip, Collapse, IconButton, Box, List, ListItem } from '@mui/material';
import { ExpandMore, ExpandLess, Menu } from '@mui/icons-material';

const SECTIONS = [
  { id: 'introduction', label: 'Inicio' },
  { id: 'servicios', label: 'Servicios' },
  { id: 'acerca-de-nosotros', label: 'Acerca de nosotros' },
  { id: 'aliados', label: 'Aliados' },
  { id: 'contacto', label: 'Contacto' }
];

const PublicHeader = () => {
  const [activeSection, setActiveSection] = useState('introduction');
  const [isInLoginPage, setIsInLoginPage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  const navigate = useNavigate();
  const isScrollingRef = useRef(false);
  const scrollTimeoutRef = useRef(null);
  
  // Detectar si estamos en la página de login
  useEffect(() => {
    const path = location.pathname;
    const hash = location.hash;
    
    // Verificar si estamos en login
    const loginCheck = path === '/login' || hash.includes('login');
    setIsInLoginPage(loginCheck);
  }, [location]);
  
  // Detector de tamaño de pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setMenuOpen(false);
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // Función mejorada para obtener la sección activa desde la URL
  const getActiveSectionFromUrl = () => {
    // Si estamos en login, no hay sección activa
    if (isInLoginPage) {
      return null;
    }
    
    // Extraer correctamente la ruta de la URL con hash
    const hashPath = location.hash.replace('#/', '').replace('#', '');
    const path = hashPath || location.pathname.substring(1);
    
    // Si estamos en login u otra ruta que no sea sección, no actualizamos
    if (path === 'login' || (path && !SECTIONS.some(s => s.id === path))) {
      return activeSection; // Mantener la sección actual
    }
    
    // Si no hay path, estamos en la raíz
    if (!path) return 'introduction';
    
    // Devolver la sección si está en nuestra lista
    return SECTIONS.some(s => s.id === path) ? path : 'introduction';
  };
  
  // Configurar listeners de scroll optimizados
  useEffect(() => {
    // No configuramos el scroll listener si estamos en login
    if (isInLoginPage) return () => {};
    
    const handleScroll = () => {
      if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);
      if (isScrollingRef.current) return;
      
      scrollTimeoutRef.current = setTimeout(() => {
        const sections = SECTIONS.filter(s => s.id !== 'introduction')
                            .map(s => document.getElementById(s.id))
                            .filter(Boolean);
        const container = document.getElementById('public-content');
        
        if (!container || sections.length === 0) return;
        
        const scrollPos = container.scrollTop;
        const viewportHeight = container.clientHeight;
        const threshold = viewportHeight * 0.4;
        
        let newActiveSection = null;
        for (let i = 0; i < sections.length; i++) {
          const section = sections[i];
          
          const sectionTop = section.offsetTop - container.offsetTop;
          const sectionBottom = sectionTop + section.offsetHeight;
          
          if (
            scrollPos >= sectionTop - threshold &&
            scrollPos < sectionBottom - threshold
          ) {
            newActiveSection = section.id;
            break;
          }
        }
        
        // Si no encontramos sección activa y estamos al inicio, usamos introduction
        if (!newActiveSection && scrollPos < threshold) {
          newActiveSection = 'introduction';
        }
        
        if (newActiveSection && newActiveSection !== activeSection) {
          setActiveSection(newActiveSection);
          // Solo actualizamos URL si no estamos en login
          if (!isInLoginPage) {
            navigate(`/${newActiveSection === 'introduction' ? '' : newActiveSection}`, { replace: true });
          }
        }
      }, 100);
    };
    
    const container = document.getElementById('public-content');
    if (container) {
      container.addEventListener('scroll', handleScroll, { passive: true });
    }
    
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [activeSection, isInLoginPage, navigate]);
  
  // Manejador de navegación mejorado
  const handleNavClick = (sectionId) => {
    if (sectionId === activeSection && !isInLoginPage) return;
    
    setActiveSection(sectionId);
    setIsInLoginPage(false); // Al hacer clic en navegación, ya no estamos en login
    isScrollingRef.current = true;
    
    // Cerrar menú móvil si está abierto
    setMenuOpen(false);
    
    // Caso especial para introduction
    if (sectionId === 'introduction') {
      navigate('/');
      setTimeout(() => {
        isScrollingRef.current = false;
      }, 500);
      return;
    }
    
    // Para otras secciones
    navigate(`/${sectionId}`);
    
    const container = document.getElementById('public-content');
    const targetElement = document.getElementById(sectionId);
    
    if (container && targetElement) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        smooth: 'easeInOutQuart',
        containerId: 'public-content',
        offset: 0,
      });
      
      setTimeout(() => {
        isScrollingRef.current = false;
      }, 1000);
    }
  };
  
  // Efecto para actualizar el estado basado en cambios de URL
  useEffect(() => {
    // Si estamos en login, no hacemos nada más
    if (isInLoginPage) return;
    
    const currentSection = getActiveSectionFromUrl();
    
    // Evitar actualizar si estamos en login u otra ruta no de sección
    if (location.pathname === '/login' || 
        (location.hash && location.hash.includes('login'))) {
      return;
    }
    
    if (currentSection !== activeSection) {
      setActiveSection(currentSection);
    }
    
    // Solo hacer scroll si no es introduction y no estamos ya scrolleando
    if (currentSection !== 'introduction' && !isScrollingRef.current && 
        location.pathname !== '/login') {
      setTimeout(() => {
        const section = document.getElementById(currentSection);
        if (section) {
          isScrollingRef.current = true;
          
          scroller.scrollTo(currentSection, {
            duration: 800,
            smooth: 'easeInOutQuart',
            containerId: 'public-content',
            offset: 0,
          });
          
          setTimeout(() => {
            isScrollingRef.current = false;
          }, 1000);
        }
      }, 300);
    }
  }, [location, activeSection, isInLoginPage]);

  // Obtener la etiqueta de la sección activa para mostrar en el chip
  const getActiveSectionLabel = () => {
    if (isInLoginPage) return "Ingresar";
    const section = SECTIONS.find(s => s.id === activeSection);
    return section ? section.label : "Inicio";
  };
  
  return (
    <header className="public-header">
      <div className="container">
        <div className="logo-container" onClick={() => handleNavClick('introduction')}>
          <img 
            src={isMobile ? LogoPublicMin : LogoPublic} 
            alt="Logo" 
            className={isMobile ? "mobile-logo" : "desktop-logo"} 
          />
        </div>
        
        {/* Navegación para escritorio */}
        <nav className="navigation desktop-nav">
          <ul className="nav-links">
            {SECTIONS.map((section) => (
              <li key={section.id}>
                <div
                  className={`nav-link ${!isInLoginPage && activeSection === section.id ? 'active' : ''}`}
                  onClick={() => handleNavClick(section.id)}
                >
                  {section.label}
                </div>
              </li>
            ))}
          </ul>
        </nav>
        
        {/* Navegación para móvil */}
        <div className="mobile-nav">
          <Chip
            label={getActiveSectionLabel()}
            onClick={() => setMenuOpen(!menuOpen)}
            onDelete={() => setMenuOpen(!menuOpen)}
            deleteIcon={menuOpen ? <ExpandLess /> : <ExpandMore />}
            color={isInLoginPage ? "default" : "primary"}
            className="section-chip"
          />
          
          <Collapse in={menuOpen} timeout="auto" unmountOnExit className="mobile-menu-collapse">
            <List component="nav" className="mobile-menu">
              {SECTIONS.map((section) => (
                <ListItem 
                  button 
                  key={section.id}
                  onClick={() => handleNavClick(section.id)}
                  className={!isInLoginPage && activeSection === section.id ? 'active-item' : ''}
                >
                  {section.label}
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
        
        <div className="auth-actions">
          <RouterLink to="/login" className="login-button" onClick={() => setIsInLoginPage(true)}>
            Ingresar
          </RouterLink>
        </div>
      </div>
    </header>
  );
};

export default PublicHeader;