import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Flag from 'react-world-flags';
import PropTypes from 'prop-types';
import "../../../assets/scss/components/ui/dropdawn/SortDropdown.scss";

const DROPDOWN_TYPES = {
  NORMAL: 'normal',
  NATIONALITY: 'nationality',
  PHONE: 'phone'
};

const countryPhoneCodes = {
  '1': 'us',
  '52': 'mx',
  '55': 'br',
  '56': 'cl',
  '57': 'co', 
  '58': 've',
  '591': 'bo',
  '593': 'ec',
  '595': 'py',
  '51': 'pe',
  '598': 'uy',
  '34': 'es',
};

export default function SortDropdown({ 
  value, 
  onChange, 
  options,
  type = DROPDOWN_TYPES.NORMAL,
  theme = 'dark',
  placeholder = 'Seleccionar...'
}) {
  const isValidValue = options.some(option => option.value === value);
  const safeValue = isValidValue ? value : "";

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const getCountryCode = (option) => {
    if (type === DROPDOWN_TYPES.PHONE) {
      const phoneCode = option.country_code?.replace(/\D/g, '');
      return countryPhoneCodes[phoneCode] || null;
    }
    
    // Para nacionalidad, usamos directamente el código ISO
    return option.iso_code?.toLowerCase() || option.country_code?.toLowerCase();
  };

  const renderMenuItem = (option) => {
    const isCountryRelated = type === DROPDOWN_TYPES.NATIONALITY || type === DROPDOWN_TYPES.PHONE;
    
    if (!isCountryRelated) {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    }
    const countryCode = getCountryCode(option);

    return (
      <MenuItem key={option.value} value={option.value}>
        <div className={`GM__${theme}-dropdown-item-with-flag`}>
          {countryCode && (
            <Flag 
              code={countryCode} 
              height="16"
              width="24"
              className={`GM__${theme}-country-flag`}
            />
          )}
          <span className={`GM__${theme}-dropdown-item-label`}>
            {option.label}
          </span>
        </div>
      </MenuItem>
    );
  };

  return (
    <div className={`GM__${theme}-sort-dropdown`}>
      <Select
        value={safeValue}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Seleccionar" }}
        className={`GM__${theme}-sort-select`}
      >
        <MenuItem value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map(renderMenuItem)}
      </Select>
    </div>
  );
}

SortDropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      label: PropTypes.string.isRequired,
      country_code: PropTypes.string,
      iso_code: PropTypes.string
    })
  ).isRequired,
  type: PropTypes.oneOf(Object.values(DROPDOWN_TYPES)),
  theme: PropTypes.oneOf(['dark', 'light']),
  placeholder: PropTypes.string
};