import React, { useState, useEffect, useCallback, useRef } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import RouteLayer from "./RouteLayer";
import AnnotationLayer from "./AnnotationLayer";
import GeofenceLayer from "./GeofenceLayer";
import MapControls from "./MapControls";
import { useMapState } from "../../hooks/maps/useMapState";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { GOOGLE_MAPS_LIBRARIES } from "../../hooks/maps/useMapRoutes";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const defaultCenter = { lat: 4.570868, lng: -74.297333 };
// Agregar después del componente MapContainer o en un archivo de utilidades
export const createTopographicMapStyle = () => {
    return [
      {
        "featureType": "administrative",
        "elementType": "all",
        "stylers": [{"visibility": "on"}]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels",
        "stylers": [{"visibility": "on"}]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "labels",
        "stylers": [{"visibility": "on"}]
      },
      {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{"visibility": "on"}, {"color": "#f2f2f2"}]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{"visibility": "simplified"}]
      },
      {
        "featureType": "poi.park",
        "elementType": "all",
        "stylers": [{"visibility": "on"}, {"color": "#e8f7dc"}]
      },
      {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{"saturation": -100}, {"lightness": 45}]
      },
      {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [{"visibility": "simplified"}, {"color": "#ffa959"}]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [{"visibility": "off"}]
      },
      {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [{"visibility": "off"}]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{"color": "#a4dff5"}, {"visibility": "on"}]
      },
      {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [{"visibility": "on"}, {"color": "#2b677f"}]
      }
    ];
  };
// Opciones avanzadas para el mapa (mostrando nombres geográficos)
// En el componente MapContainer
const createMapOptions = (mapType) => {
    const baseOptions = {
      mapTypeId: mapType === 'topographic' ? 'roadmap' : mapType, // Usar roadmap como base para topográfico
      mapTypeControl: true,
      zoomControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      styles: []
    };
    
    // Configuración específica para topográfico
    if (mapType === 'topographic') {
      baseOptions.styles = createTopographicMapStyle();
    } else if (mapType === 'terrain' || mapType === 'roadmap') {
      // Configuración para mostrar más detalles en modos terrain y roadmap
      baseOptions.styles = [
        {
          featureType: "water",
          elementType: "labels.text",
          stylers: [{ visibility: "on" }]
        },
        {
          featureType: "administrative.locality",
          elementType: "labels",
          stylers: [{ visibility: "on" }]
        },
        {
          featureType: "administrative.neighborhood",
          elementType: "labels",
          stylers: [{ visibility: "on" }]
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "on" }]
        }
      ];
    }
    
    return baseOptions;
  };

const MapContainer = ({
  routes,
  activeRouteId,
  addWaypoint,
  updateRoute,
  onSelectStartPoint,
  onSelectEndPoint,
  selectionMode,
  setSelectionMode,
  annotations,
  activeAnnotationId,
  addAnnotation,
  updateAnnotation,
}) => {
  const mapRef = useRef(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES, // Usar referencia a constante
  });

  const { 
    center, 
    zoom, 
    mapType, 
    setCenter, 
    setZoom, 
    setMapType 
  } = useMapState(defaultCenter, 8, "roadmap");

  const [drawingMode, setDrawingMode] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [geofences, setGeofences] = useState([]);
  const [activeGeofenceId, setActiveGeofenceId] = useState(null);
  
  // Referencia para mantener las coordenadas del último clic
  const lastClickRef = useRef(null);

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

// components/maps/MapContainer.jsx
const handleMapClick = useCallback(
    (event) => {
      const clickedLat = event.latLng.lat();
      const clickedLng = event.latLng.lng();
      const clickedPosition = { lat: clickedLat, lng: clickedLng };
      
      // Log para depuración
      console.log("Clic en el mapa en coordenadas:", clickedPosition);
      
      // Guardar la posición del clic para referencia
      lastClickRef.current = clickedPosition;
  
      if (selectionMode === "start") {
        onSelectStartPoint(clickedPosition);
        setSelectionMode(null);
      } else if (selectionMode === "end") {
        onSelectEndPoint(clickedPosition);
        setSelectionMode(null);
      } else if (selectionMode === "annotation") {
        // IMPORTANTE: Crear la anotación directamente con las coordenadas exactas del clic
        const newAnnotation = {
          id: Date.now().toString(),
          position: clickedPosition, // Usar exactamente las coordenadas del clic
          title: "Nueva Anotación",
          description: "",
          color: "#FF0000",
          icon: "pin"
        };
        
        console.log("Creando anotación en posición:", clickedPosition);
        addAnnotation(newAnnotation);
        setSelectionMode(null);
      } else if (drawingMode === "route" && activeRouteId) {
        addWaypoint(activeRouteId, clickedPosition);
      } else if (drawingMode === "annotation") {
        addAnnotation({
          id: Date.now().toString(),
          position: clickedPosition,
          title: "Nueva Anotación",
          description: "",
          color: "#FF0000",
          icon: "pin"
        });
        setDrawingMode(null);
      }
    },
    [drawingMode, activeRouteId, addWaypoint, addAnnotation, selectionMode, onSelectStartPoint, onSelectEndPoint, setSelectionMode]
  );
  // Actualizar cursor del mapa según el modo
  useEffect(() => {
    if (!mapRef.current) return;
    
    if (selectionMode || drawingMode) {
      mapRef.current.setOptions({ draggableCursor: 'crosshair' });
    } else {
      mapRef.current.setOptions({ draggableCursor: null });
    }
  }, [selectionMode, drawingMode]);

  if (loadError) {
    return <div className="error-container">Error al cargar Google Maps: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <LoadingAnimation />;
  }

  return (
    <div className="map-container">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom}
        options={createMapOptions(mapType)}
        onLoad={handleMapLoad}
        onClick={handleMapClick}
      >
        {/* Capa de rutas */}
        <RouteLayer 
          routes={routes} 
          activeRouteId={activeRouteId}
          updateRoute={updateRoute}
        />

        {/* Capa de anotaciones */}
        <AnnotationLayer 
          annotations={annotations} 
          activeAnnotationId={activeAnnotationId}
          updateAnnotation={updateAnnotation}
        />

        {/* Capa de geocercas */}
        <GeofenceLayer
          geofences={geofences}
          activeGeofenceId={activeGeofenceId}
          onGeofenceClick={setActiveGeofenceId}
        />

        {/* Controles del mapa */}
        <MapControls 
          drawingMode={drawingMode}
          setDrawingMode={setDrawingMode}
          isDrawing={isDrawing}
          setIsDrawing={setIsDrawing}
          mapRef={mapRef}
          mapType={mapType}
          setMapType={setMapType}
          selectionMode={selectionMode}
        />
      </GoogleMap>
    </div>
  );
};

export default MapContainer;