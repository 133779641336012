import React from 'react';
import PropTypes from 'prop-types';
import LoadingAnimation from '../loading/LoadingAnimation';
import { useVehicleTypes } from '../../hooks/useVehicleQuery';
import '../../assets/scss/components/vehicle/VehicleTypeSelector.scss';

export default function VehicleTypeSelector({ selectedTypeId, onChange, theme }) {
  const { data: typesData, isLoading } = useVehicleTypes();
  
  if (isLoading) return <LoadingAnimation />;
  
  const vehicleTypes = typesData?.data || [];
  
  // Agrupar por categoría para mejor organización visual
  const groupedTypes = vehicleTypes.reduce((groups, type) => {
    // Validación de seguridad: asegurar que category existe
    const category = type.category ? type.category.split(':')[0] : 'Otros';
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(type);
    return groups;
  }, {});

  // Generar iniciales de forma segura
  const getInitials = (name) => {
    // Verificar que name es una cadena válida
    if (!name || typeof name !== 'string' || name.trim() === '') {
      return '?'; // Carácter por defecto si no hay nombre válido
    }
    return name.charAt(0).toUpperCase();
  };

  return (
    <div className={`GM__${theme}-vehicle-type-selector`}>
      <h2 className={`GM__${theme}-section-title`}>Seleccione el tipo de vehículo</h2>
      
      {Object.keys(groupedTypes).length === 0 ? (
        <div className={`GM__${theme}-no-types-message`}>
          No hay tipos de vehículos disponibles
        </div>
      ) : (
        <div className={`GM__${theme}-type-categories`}>
          {Object.entries(groupedTypes).map(([category, types]) => (
            <div key={category} className={`GM__${theme}-category-section`}>
              <h3 className={`GM__${theme}-category-title`}>{category}</h3>
              
              <div className={`GM__${theme}-types-grid`}>
                {types.map(type => (
                  <div 
                    key={type.id}
                    className={`GM__${theme}-type-card ${selectedTypeId === type.id ? 'selected' : ''}`}
                    onClick={() => onChange(type.id)}
                  >
                    <div className={`GM__${theme}-type-icon`}>
                      {getInitials(type.name)}
                    </div>
                    <h4 className={`GM__${theme}-type-name`}>{type.name || 'Sin nombre'}</h4>
                    <p className={`GM__${theme}-type-description`}>
                      {type.description || 'Sin descripción'}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      
      {selectedTypeId && (
        <div className={`GM__${theme}-selection-info`}>
          <p>Tipo seleccionado: {vehicleTypes.find(t => t.id === selectedTypeId)?.name || 'Tipo desconocido'}</p>
          {vehicleTypes.find(t => t.id === selectedTypeId)?.category?.includes('Cabezote') && (
            <p className={`GM__${theme}-info-message`}>
              Este tipo de vehículo permite registrar también un remolque compatible.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

VehicleTypeSelector.propTypes = {
  selectedTypeId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired
};