import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as EditIcon } from "../assets/svg/view/edit.svg";
import StreamingMedia from "../utils/StreamingMedia";
import { useCommentsAndLocations } from "../hooks/commentsAndResidenceQuery";
import CommentsLocationsDrawer from "../components/ui/drawner/CommentsLocationsDrawer";
import { useQueryClient } from "@tanstack/react-query";
import {
  NotificationSeverity,
  useNotification,
} from "../context/NotificationContext";
import PugaraMelisaImg from "../assets/img/pulgara_melisa.png";
const UserProfileInfo = ({
  user,
  canEditProfile,
  onEditClick,
  profileImageId,
  theme,
}) => {
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();
  const {
    useUserLocations,
    useCommentsByReference,
    createLocation,
    updateLocation,
    deleteLocation,
    createComment,
    updateComment,
    deleteComment,
  } = useCommentsAndLocations();
  const { data: comments } = useCommentsByReference(user?.id);
  const { data: locations, isError } = useUserLocations(user?.id);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState(null);

  // Procesar los datos de ubicación
  const locationData = locations?.data || [];
  const commentData = comments?.data || [];

  // Manejadores mejorados
  const handleOpenDrawer = (type) => {
    setDrawerType(type);
    setDrawerOpen(true);
  };

  const handleEdit = async (id, item) => {
    try {
      if (drawerType === "locations") {
        await updateLocation({
          locationId: id,
          data: { ...item, user_id: user?.id },
        });
        showNotification({
          message: "Ubicación actualizada exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      } else {
        await updateComment({
          commentId: id,
          data: { ...item, reference_id: user?.id },
        });
        showNotification({
          message: "Comentario actualizado exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      }
      // Recargar datos después de la edición
      queryClient.invalidateQueries([drawerType, user?.id]);
    } catch (error) {
      console.error("Error al editar:", error);
      showNotification({
        message:
          error.response?.data?.message ||
          `Error al actualizar ${
            drawerType === "locations" ? "la ubicación" : "el comentario"
          }`,
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      if (drawerType === "locations") {
        await deleteLocation(id);
        showNotification({
          message: "Ubicación eliminada exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      } else {
        await deleteComment(id);
        showNotification({
          message: "Comentario eliminado exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      }
      // Recargar datos después de la eliminación
      queryClient.invalidateQueries([drawerType, user?.id]);
    } catch (error) {
      console.error("Error al eliminar:", error);
      showNotification({
        message:
          error.response?.data?.message ||
          `Error al eliminar ${
            drawerType === "locations" ? "la ubicación" : "el comentario"
          }`,
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  const handleCreate = async (item) => {
    try {
      if (drawerType === "locations") {
        await createLocation({ ...item, user_id: user?.id });
        showNotification({
          message: "Ubicación creada exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      } else {
        await createComment({ ...item, reference_id: user?.id });
        showNotification({
          message: "Comentario creado exitosamente",
          severity: NotificationSeverity.SUCCESS,
        });
      }
      // Recargar datos después de la creación
      queryClient.invalidateQueries([drawerType, user?.id]);
    } catch (error) {
      console.error("Error al crear:", error);
      showNotification({
        message:
          error.response?.data?.message ||
          `Error al crear ${
            drawerType === "locations" ? "la ubicación" : "el comentario"
          }`,
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    // Opcional: recargar datos al cerrar el drawer
    if (drawerType) {
      queryClient.invalidateQueries([drawerType, user?.id]);
    }
  };

  // Manejo de errores para la carga de datos
  useEffect(() => {
    if (isError) {
      showNotification({
        message: "Error al cargar los datos de ubicación",
        severity: NotificationSeverity.ERROR,
      });
    }
  }, [isError, showNotification]);

  return (
    <div className={`GM__${theme}-profile-user-info`}>
    {canEditProfile && (
      <button className="edit-toggle-btn" onClick={onEditClick}>
        <EditIcon />
      </button>
    )}

    <div className="profile-header">
      <div className="profile-image-container">
        <div className="rotated-rectangle">
          <div className="profile-image-wrapper">
            <div className="profile-main-image">
              <StreamingMedia
                fileId={profileImageId}
                className="profile-image"
              />
            </div>
            <div className="profile-backdrop">
              <img
                src={PugaraMelisaImg}
                alt="Melisa dando me gusta"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="profile-info">
        <div className="info-display">
          <h2>{`${user?.first_name} ${user?.first_last_name} ${user?.second_last_name}`}</h2>

          <div className="info-sections">
            {/* Columna Izquierda */}
            <div className="info-column">
              {/* Sección de Información Personal */}
              <div className="info-section">
                <h3 className="section-title">Información Personal</h3>
                <div className="section-content">
                  <div className="info-item">
                    <span className="label">Tipo de Documento:</span>
                    <span className="value">{user?.document_type_name}</span>
                  </div>
                  <div className="info-item">
                    <span className="label">Número de Documento:</span>
                    <span className="value">{user?.document_number}</span>
                  </div>
                  <div className="info-item">
                    <span className="label">Fecha de Expedición:</span>
                    <span className="value">
                      {user?.document_exp_date
                        ? new Date(user?.document_exp_date).toLocaleDateString()
                        : "No disponible"}
                    </span>
                  </div>
                  <div className="info-item">
                    <span className="label">Lugar de Expedición:</span>
                    <span className="value">
                      {user?.document_expedition_place || "No disponible"}
                    </span>
                  </div>
                  <div className="info-item">
                    <span className="label">Fecha de Nacimiento:</span>
                    <span className="value">
                      {user?.birth_date
                        ? new Date(user?.birth_date).toLocaleDateString()
                        : "No disponible"}
                    </span>
                  </div>
                  <div className="info-item">
                    <span className="label">Nacionalidad:</span>
                    <span className="value">{user?.nationality_country}</span>
                  </div>
                </div>
              </div>

              {/* Sección de Rol y Estado */}
              <div className="info-section">
                <h3 className="section-title">Rol y Estado</h3>
                <div className="section-content">
                  <div className="info-item">
                    <span className="label">Rol:</span>
                    <span className="value">{user?.role_name}</span>
                  </div>
                  <div className="info-item">
                    <span className="label">Estado:</span>
                    <span className="value status">
                      {user?.active_status ? "Activo" : "Inactivo"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Columna Derecha */}
            <div className="info-column">
              {/* Sección de Contacto */}
              <div className="info-section">
                <h3 className="section-title">Información de Contacto</h3>
                <div className="section-content">
                  <div className="info-item">
                    <span className="label">Email:</span>
                    <span className="value">{user?.email}</span>
                  </div>
                  <div className="info-item">
                    <span className="label">Teléfono Principal:</span>
                    <span className="value">
                      ({user?.primary_phone_code}){" "}
                      {user?.primary_phone_number}
                    </span>
                  </div>
                  {user?.secondary_phone_number && (
                    <div className="info-item">
                      <span className="label">Teléfono Secundario:</span>
                      <span className="value">
                        ({user?.secondary_phone_code}){" "}
                        {user?.secondary_phone_number}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {/* Sección de Validación y Configuraciones */}
              <div className="info-section">
                <h3 className="section-title">
                  Validación y Configuraciones
                </h3>
                <div className="section-content">
                  <div className="info-item">
                    <span className="label">Email Verificado:</span>
                    <span className="value status">
                      {user?.verify_email ? "Sí" : "No"}
                    </span>
                  </div>
                  <div className="info-item">
                    <span className="label">Términos Aceptados:</span>
                    <span className="value status">
                      {user?.terms_accepted ? "Sí" : "No"}
                    </span>
                  </div>
                  <div className="info-item">
                    <span className="label">Política de Privacidad:</span>
                    <span className="value status">
                      {user?.privacy_policy_accepted
                        ? "Aceptada"
                        : "Pendiente"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="info-section">
                <h3 className="section-title">Observaciones y Comentarios</h3>
                <div className="section-content">
                  {commentData.length > 0 ? (
                    <>
                      <div className="info-item">
                        <span className="label">Título:</span>
                        <span className="value">{commentData[0]?.title}</span>
                      </div>
                      <div className="info-item">
                        <span className="label">Descripción:</span>
                        <span className="value">
                          {commentData[0]?.comment_text?.substring(0, 100)}
                          {commentData[0]?.comment_text?.length > 100 &&
                            "..."}
                        </span>
                      </div>
                      <div className="info-item footer-item">
                        <span className="label">Total de observaciones:</span>
                        <span className="value">{commentData.length}</span>
                        <button
                          className="view-more-btn"
                          onClick={() => handleOpenDrawer("comments")}
                        >
                          Ver más
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="info-item empty-state">
                      <span className="value">
                        No hay observaciones registradas
                      </span>
                      <button
                        className="add-btn"
                        onClick={() => handleOpenDrawer("comments")}
                      >
                        Agregar observación
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* Sección de Localizaciones */}
              <div className="info-section">
                <h3 className="section-title">Ubicaciones</h3>
                <div className="section-content">
                  {locationData.length > 0 ? (
                    <>
                      <div className="info-item">
                        <span className="label">Dirección principal:</span>
                        <span className="value">
                          {locationData[0]?.address_details}
                        </span>
                      </div>
                      {locationData[0]?.reference && (
                        <div className="info-item">
                          <span className="label">Referencia:</span>
                          <span className="value">
                            {locationData[0]?.reference}
                          </span>
                        </div>
                      )}
                      <div className="info-item footer-item">
                        <span className="label">Total de ubicaciones:</span>
                        <span className="value">{locationData.length}</span>
                        <button
                          className="view-more-btn"
                          onClick={() => handleOpenDrawer("locations")}
                        >
                          Ver más
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="info-item empty-state">
                      <span className="value">
                        No hay ubicaciones registradas
                      </span>
                      <button
                        className="add-btn"
                        onClick={() => handleOpenDrawer("locations")}
                      >
                        Agregar ubicación
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommentsLocationsDrawer
      open={drawerOpen}
      onClose={handleCloseDrawer}
      type={drawerType}
      data={drawerType === "comments" ? commentData : locationData}
      onEdit={handleEdit}
      onCreate={handleCreate}
      onDelete={handleDelete}
      theme={theme}
    />
  </div>
  );
};

UserProfileInfo.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    first_last_name: PropTypes.string,
    second_last_name: PropTypes.string,
    email: PropTypes.string,
    document_type_name: PropTypes.string,
    document_number: PropTypes.string,
    document_exp_date: PropTypes.string,
    document_expedition_place: PropTypes.string,
    birth_date: PropTypes.string,
    primary_phone_number: PropTypes.string,
    primary_phone_code: PropTypes.string,
    secondary_phone_number: PropTypes.string,
    secondary_phone_code: PropTypes.string,
    role_name: PropTypes.string,
    role_code: PropTypes.string,
    nationality_country: PropTypes.string,
    active_status: PropTypes.bool,
    verify_email: PropTypes.bool,
    terms_accepted: PropTypes.bool,
    privacy_policy_accepted: PropTypes.bool,
    observations: PropTypes.string,
  }).isRequired,
  canEditProfile: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  profileImageId: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default UserProfileInfo;
