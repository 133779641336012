import React, { useRef, useState, useEffect } from "react";
import MapContainer from "./MapContainer";
import { useAnnotations } from "../../hooks/maps/useAnnotations";
import { exportToKmz, exportToGeoJson } from "../../utils/kmzExporter";
import "../../assets/scss/views/GeocercaMaps.scss";
import LeftNavMaps from "../../components/layout/LeftNavMaps";
import { useMapRoutes } from "../../hooks/maps/useMapRoutes";
import { useLayout } from "../../context/LayoutContext";

const RouteMapPage = () => {
  const { isNavExpanded } = useLayout(); // Obtenemos el estado de expansión
  
  const {
    routes,
    activeRouteId,
    addRoute,
    updateRoute,
    deleteRoute,
    setActiveRoute,
    addWaypoint,
    removeWaypoint,
  } = useMapRoutes();

  const {
    annotations,
    addAnnotation,
    updateAnnotation,
    deleteAnnotation,
    activeAnnotationId,
    setActiveAnnotation,
  } = useAnnotations();

  const mapRef = useRef(null);
  
  const [selectionMode, setSelectionMode] = useState(null);
  const [lastSelectedPosition, setLastSelectedPosition] = useState(null);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);

  const handleSelectStartPoint = (position) => {
    setStartPoint(position);
    setLastSelectedPosition(position);
  };

  const handleSelectEndPoint = (position) => {
    setEndPoint(position);
    setLastSelectedPosition(position);
  };
  
  const handleSelectAnnotationPoint = (position) => {
    setLastSelectedPosition(position);
  };
  
  useEffect(() => {
    if (lastSelectedPosition && selectionMode === "annotation") {
      const customEvent = new CustomEvent('annotation-point-selected', { 
        detail: lastSelectedPosition 
      });
      document.dispatchEvent(customEvent);
      setSelectionMode(null);
    }
  }, [lastSelectedPosition, selectionMode]);

  return (
    <div className={`route-map-page ${isNavExpanded ? 'nav-expanded' : 'nav-collapsed'}`}>
      <LeftNavMaps
        routes={routes}
        activeRouteId={activeRouteId}
        addRoute={addRoute}
        updateRoute={updateRoute}
        deleteRoute={deleteRoute}
        setActiveRoute={setActiveRoute}
        
        startPoint={startPoint}
        setStartPoint={setStartPoint}
        endPoint={endPoint}
        setEndPoint={setEndPoint}
        selectionMode={selectionMode}
        setSelectionMode={setSelectionMode}
        lastSelectedPosition={lastSelectedPosition}
        
        annotations={annotations}
        activeAnnotationId={activeAnnotationId}
        addAnnotation={addAnnotation}
        updateAnnotation={updateAnnotation}
        deleteAnnotation={deleteAnnotation}
        setActiveAnnotation={setActiveAnnotation}
        
        exportToKmz={exportToKmz}
        exportToGeoJson={exportToGeoJson}
        
        mapRef={mapRef.current}
      />
      
      <div className="map-dashboard-container">
        <MapContainer
          ref={mapRef}
          routes={routes}
          activeRouteId={activeRouteId}
          addWaypoint={addWaypoint}
          updateRoute={updateRoute}
          
          selectionMode={selectionMode}
          setSelectionMode={setSelectionMode}
          onSelectStartPoint={handleSelectStartPoint}
          onSelectEndPoint={handleSelectEndPoint}
          onSelectAnnotationPoint={handleSelectAnnotationPoint}
          
          annotations={annotations}
          activeAnnotationId={activeAnnotationId}
          addAnnotation={addAnnotation}
          updateAnnotation={updateAnnotation}
        />
      </div>
    </div>
  );
};

export default RouteMapPage;