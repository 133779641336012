import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ROUTE_ACCESS } from './routes.config';
import MainLayout from '../layout/MainLayout';
import DocumentLayout from '../layout/DocumentLayout';
import { SatelliteDataProvider } from '../context/SatelliteDataContext';
import { DocumentProvider } from '../context/DocumentContext';

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();

  // Verificar si el usuario está autenticado
  if (!user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Verificar acceso para rutas exactas o dinámicas
  const hasAccess = Object.entries(ROUTE_ACCESS).some(([route, roles]) => {
    const dynamicRouteRegex = new RegExp(
      `^${route.replace(/:[^\s/]+/g, '[^/]+')}$`
    );
    const matches = dynamicRouteRegex.test(location.pathname);

    if (matches && roles.includes(user.role_name)) {
      return true;
    }

    return matches;
  });

  if (!hasAccess) {
    return <Navigate to="/dashboard" replace />;
  }

  // Usar DocumentLayout para la ruta del calculador de transporte
  if (location.pathname === '/transport-calculator') {
    return (
      <DocumentProvider>
        <DocumentLayout>{children}</DocumentLayout>
      </DocumentProvider>
    );
  }

  // Para el resto de rutas protegidas, usar el MainLayout normal
  return (
    <SatelliteDataProvider>
      <MainLayout>{children}</MainLayout>
    </SatelliteDataProvider>
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;