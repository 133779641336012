import { useState, useCallback } from 'react';

export const useMapState = (initialCenter, initialZoom, initialMapType) => {
  const [center, setCenter] = useState(initialCenter);
  const [zoom, setZoom] = useState(initialZoom);
  const [mapType, setMapType] = useState(initialMapType || 'roadmap');
  const [bounds, setBounds] = useState(null);

  const updateCenter = useCallback((newCenter) => {
    setCenter(newCenter);
  }, []);

  const updateZoom = useCallback((newZoom) => {
    setZoom(newZoom);
  }, []);

  const updateMapType = useCallback((newMapType) => {
    setMapType(newMapType);
  }, []);

  const updateBounds = useCallback((newBounds) => {
    setBounds(newBounds);
  }, []);

  const fitBounds = useCallback((latlngBounds, padding = 50) => {
    setBounds({ bounds: latlngBounds, padding });
  }, []);

  return {
    center,
    zoom,
    mapType,
    bounds,
    setCenter: updateCenter,
    setZoom: updateZoom,
    setMapType: updateMapType,
    setBounds: updateBounds,
    fitBounds,
  };
};