import PropTypes from "prop-types";
import React from "react";
import CustomInputField from "../ui/inputs/CustomInputField";
import LocationSelector from "../LocationSelector";
import SortDropdown from "../ui/dropdawn/SortDropdown";
import { usePhoneCountryCodes } from "../../hooks/userComplementaryQuery";

export default function ContactSectionAddPersons({
  contactInfo,
  onContactInfoChange,
  onLocationSelect,
  theme,
}) {
  const { data: countryCodes, isLoading: isLoadingCountryCodes } =
    usePhoneCountryCodes();
  const countryCodesData = countryCodes?.data || [];

  const handlePrimaryPhoneCountryChange = (value) => {
    onContactInfoChange({
      target: {
        name: "primaryPhoneCountryId",
        value,
      },
    });
  };

  const handleSecondaryPhoneCountryChange = (value) => {
    onContactInfoChange({
      target: {
        name: "secondaryPhoneCountryId",
        value,
      },
    });
  };

  return (
<div className={`GM__${theme}-contact-section`}>
  <h2 className={`GM__${theme}-section-title`}>Información de contacto</h2>

  <div className={`GM__${theme}-contact-row`}>
    <div className={`GM__${theme}-phone-group`}>
      <div className={`GM__${theme}-input-group`}>
        <label htmlFor="primaryPhone" className={`GM__${theme}-input-label`}>
          Número de Celular Principal
        </label>
        <div className={`GM__${theme}-phone-input-container`}>
          <SortDropdown
            id="primaryPhoneCountry"
            value={contactInfo.primaryPhoneCountryId || ""}
            onChange={handlePrimaryPhoneCountryChange}
            options={
              !isLoadingCountryCodes && countryCodesData
                ? countryCodesData.map((country) => ({
                    value: country.id,
                    label: `${country.country_code} (${country.country_name})`,
                    country_code: country.country_code,
                  }))
                : []
            }
            type="phone"
            theme={theme}
            placeholder="Seleccione código"
          />
          <CustomInputField
            id="primaryPhone"
            type="text"
            name="primaryPhoneNumber"
            value={contactInfo.primaryPhoneNumber}
            onChange={onContactInfoChange}
            placeholder="Número"
            theme={theme}
          />
        </div>
      </div>
    </div>

    <div className={`GM__${theme}-phone-group`}>
      <div className={`GM__${theme}-input-group`}>
        <label htmlFor="secondaryPhone" className={`GM__${theme}-input-label`}>
          Número de Celular Secundario (Opcional)
        </label>
        <div className={`GM__${theme}-phone-input-container`}>
          <SortDropdown
            id="secondaryPhoneCountry"
            value={contactInfo.secondaryPhoneCountryId || ""}
            onChange={handleSecondaryPhoneCountryChange}
            options={
              !isLoadingCountryCodes && countryCodesData
                ? countryCodesData.map((country) => ({
                    value: country.id,
                    label: `${country.country_code} (${country.country_name})`,
                    country_code: country.country_code,
                  }))
                : []
            }
            type="phone"
            theme={theme}
            placeholder="Seleccione código"
          />
          <CustomInputField
            id="secondaryPhone"
            type="text"
            name="secondaryPhoneNumber"
            value={contactInfo.secondaryPhoneNumber}
            onChange={onContactInfoChange}
            placeholder="Número"
            theme={theme}
          />
        </div>
      </div>
    </div>

    <div className={`GM__${theme}-input-group GM__${theme}-email-group`}>
      <label htmlFor="email" className={`GM__${theme}-input-label`}>
        Correo Electrónico
      </label>
      <CustomInputField
        id="email"
        type="email"
        name="email"
        value={contactInfo.email}
        onChange={onContactInfoChange}
        placeholder="Ej. usuario@correo.com"
        theme={theme}
      />
    </div>
  </div>

  <div className={`GM__${theme}-location-section`}>
    <LocationSelector
      onLocationSelect={onLocationSelect}
      locationData={contactInfo.location}
      theme={theme}
    />
  </div>
</div>
  );
}

ContactSectionAddPersons.propTypes = {
  contactInfo: PropTypes.shape({
    primaryPhoneNumber: PropTypes.string,
    primaryPhoneCountryId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    secondaryPhoneNumber: PropTypes.string,
    secondaryPhoneCountryId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    email: PropTypes.string,
    contactName: PropTypes.string,
    location: PropTypes.object,
  }).isRequired,
  onContactInfoChange: PropTypes.func.isRequired,
  onLocationSelect: PropTypes.func.isRequired,
};
