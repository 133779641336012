import axios from "axios";
//const API_URL =  process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL_PRODUCTION;
export const adminService = {
    getReportsMontly: async (selectedDate, signal) => {
        try {
            if (!selectedDate || !isValidDateFormat(selectedDate)) {
                throw new Error('Formato de fecha inválido. Use YYYY-MM-DD');
            }
    
            const [year, month] = selectedDate.split('-');
    
            // Configuramos axios con AbortController
            const response = await axios.get(
                `${API_URL}/reports/monthly`,
                {
                    params: { year, month },
                    responseType: 'blob',
                    headers: {
                        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    },
                    // Pasamos el signal al axios
                    signal: signal
                }
            );
    
            // Si la descarga fue cancelada, no continuamos con el proceso
            if (signal?.aborted) {
                throw new Error('Descarga cancelada');
            }
    
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
    
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `Reporte-${year}-${month}.xlsx`;
    
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
    
            return { success: true };
    
        } catch (error) {
            // Manejamos específicamente la cancelación
            if (axios.isCancel(error)) {
                console.log('Solicitud cancelada');
                throw new Error('Descarga cancelada por el usuario');
            }
    
            console.error('Error en la descarga:', error);
            
            if (error.response?.data instanceof Blob) {
                const texto = await error.response.data.text();
                try {
                    const errorJson = JSON.parse(texto);
                    throw new Error(errorJson.message);
                } catch (e) {
                    throw new Error('Error al procesar la respuesta del servidor');
                }
            }
            
            throw error.message || 'Error al procesar la solicitud';
        }
    },
    getReportsYear: async (selectedDate) => {
        try {
            // Validación de la fecha
            if (!selectedDate || !isValidDateFormat(selectedDate)) {
                throw new Error('Formato de fecha inválido. Use YYYY-MM-DD');
            }

            // Extraer mes y año de la fecha proporcionada
            const [year, month] = selectedDate.split('-');

            // Configuración de la petición para manejar la descarga del archivo
            const response = await axios.get(
                `${API_URL}/reports/annual`,
                {
                    params: { year },
                    responseType: 'blob', // Especificamos que esperamos un archivo binario
                    headers: {
                        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }
                }
            );

            // Crear el objeto Blob con el tipo MIME correcto para Excel
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            // Crear la URL del blob
            const downloadUrl = window.URL.createObjectURL(blob);

            // Crear un elemento de enlace temporal para la descarga
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `Reporte-${year}.xlsx`; // Nombre del archivo a descargar

            // Agregar el enlace al documento, hacer clic y luego removerlo
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Liberar la URL del objeto para liberar memoria
            window.URL.revokeObjectURL(downloadUrl);

            return { success: true };

        } catch (error) {
            console.error('Error obteniendo el archivo con el reporte mensual:', error);
            
            // Manejo de errores mejorado para diferentes casos
            if (error.response) {
                // Si el error viene con datos binarios, intentamos convertirlo a texto
                if (error.response.data instanceof Blob) {
                    const texto = await error.response.data.text();
                    try {
                        const errorJson = JSON.parse(texto);
                        throw new Error(errorJson.message || 'Error al descargar el archivo');
                    } catch (e) {
                        throw new Error('Error al procesar la respuesta del servidor');
                    }
                }
                throw error.response.data?.message || 'Error al descargar el archivo';
            }
            throw error.message || 'Error al procesar la solicitud';
        }
    }
};

// Función de utilidad para validar el formato de fecha
function isValidDateFormat(dateString) {
    // Expresión regular para validar formato YYYY-MM-DD
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    
    if (!dateFormatRegex.test(dateString)) {
        return false;
    }
  
    // Validación adicional de fecha válida
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
}