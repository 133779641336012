import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts";

const BarChart = ({
  title = "Análisis Mensual",
  categoryData = [], // Semanas
  seriesData = [], // Datos de series
  theme = "light",
  height = "100%",
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const themeConfig = {
    light: {
      backgroundColor: '#ffffff',
      textColor: '#333333',
      axisColor: '#333333',
      splitLineColor: '#eeeeee',
      tooltipBackground: '#ffffff',
      tooltipBorder: '#dddddd',
      colors: ['#5470c6', '#91cc75', '#ee6666'],
      legendTextColor: '#333333',
      titleColor: '#333333',
    },
    dark: {
      backgroundColor: '#1f1f1f',
      textColor: '#ffffff',
      axisColor: '#ffffff',
      splitLineColor: '#333333',
      tooltipBackground: '#1f1f1f',
      tooltipBorder: '#333333',
      colors: ['#58a6ff', '#7ee787', '#ff7b72'],
      legendTextColor: '#ffffff',
      titleColor: '#ffffff',
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.dispose();
      }
      chartInstance.current = echarts.init(chartRef.current);
      
      return () => {
        chartInstance.current?.dispose();
      };
    }
  }, [theme]);

  useEffect(() => {
    if (!chartInstance.current) return;

    const currentTheme = themeConfig[theme];

    const option = {
      backgroundColor: currentTheme.backgroundColor,
      title: {
        text: categoryData.length === 0 ? "No hay datos disponibles" : title,
        textStyle: {
          color: currentTheme.titleColor,
          fontSize: 16,
          fontWeight: 'normal'
        },
        left: 'center',
        top: 20
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params) => {
          const weekData = params[0].name.split('\n');
          let tooltip = `<div style="font-weight: bold;">${weekData[0]}</div>`;
          tooltip += `<div style="font-size: 12px;">${weekData[1]}</div><br/>`;
          
          params.forEach(param => {
            tooltip += `<div style="color: ${param.color}">
              ${param.seriesName}: <strong>${param.value}</strong>
            </div>`;
          });
          
          return tooltip;
        }
      },
      legend: {
        data: seriesData.map(series => series.name),
        textStyle: {
          color: currentTheme.legendTextColor,
        },
        top: 60
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '25%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          color: currentTheme.axisColor
        },
        splitLine: {
          lineStyle: {
            color: currentTheme.splitLineColor
          }
        }
      },
      yAxis: {
        type: 'category',
        data: categoryData,
        axisLabel: {
          color: currentTheme.axisColor,
          formatter: (value) => {
            const lines = value.split('\n');
            return lines.join('\n');
          },
          rich: {
            week: {
              fontWeight: 'bold',
              lineHeight: 20
            },
            days: {
              fontSize: 12,
              lineHeight: 16
            }
          }
        }
      },
      series: seriesData.map((series, index) => ({
        name: series.name,
        type: 'bar',
        data: series.data,
        color: currentTheme.colors[index],
        label: {
          show: true,
          position: 'right',
          color: currentTheme.textColor
        }
      }))
    };

    chartInstance.current.setOption(option);
  }, [categoryData, seriesData, theme, title]);

  useEffect(() => {
    const handleResize = () => {
      chartInstance.current?.resize();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <div ref={chartRef} style={{ height, width: "100%" }} />;
};

BarChart.propTypes = {
  title: PropTypes.string,
  categoryData: PropTypes.arrayOf(PropTypes.string),
  seriesData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ),
  theme: PropTypes.oneOf(["light", "dark"]),
  height: PropTypes.string,
};

export default BarChart;