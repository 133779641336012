import React, { useEffect, useRef } from 'react';
import { Element } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import Servicios from './Servicios';
import AcercaDeNosotros from './AcercaDeNosotros';
import Aliados from './Aliados';
import Contacto from './Contacto';
import './HomePage.scss';

const HomePage = () => {
  const location = useLocation();
  const contentRef = useRef(null);
  const initialScrollDoneRef = useRef(false);
  
  useEffect(() => {
    // Ejecutamos este efecto solo una vez al montar el componente
    if (!initialScrollDoneRef.current) {
      // Extraer la sección correctamente de hash o pathname
      const sectionFromHash = location.hash.replace('#/', '').replace('#', '');
      const path = sectionFromHash || location.pathname.substring(1);
      const validSections = ['servicios', 'acerca-de-nosotros', 'aliados', 'contacto'];
      
      if (validSections.includes(path)) {
        // Un timeout para asegurar que todo esté renderizado
        setTimeout(() => {
          const targetElement = document.getElementById(path);
          const container = document.getElementById('public-content');
          
          if (targetElement && container) {
            container.scrollTo({
              top: targetElement.offsetTop,
              behavior: 'smooth'
            });
            
            // Marcamos que ya hicimos el scroll inicial
            initialScrollDoneRef.current = true;
          }
        }, 500); // Mayor timeout para asegurar renderizado completo
      }
    }
  }, [location]);
  
  return (
    <div className="home-page" ref={contentRef}>
      <Element name="servicios" className="section servicios-section" id="servicios">
        <Servicios />
      </Element>
      
      <Element name="acerca-de-nosotros" className="section about-section" id="acerca-de-nosotros">
        <AcercaDeNosotros />
      </Element>
      
      <Element name="aliados" className="section aliados-section" id="aliados">
        <Aliados />
      </Element>
      
      <Element name="contacto" className="section contacto-section" id="contacto">
        <Contacto />
      </Element>
    </div>
  );
};

export default HomePage;