import React, { useEffect, useState } from "react";
import ColorPicker from "../common/ColorPicker.jsx";
import "../../assets/scss/components/RouteManager.scss";

const RouteManager = ({
    routes,
    activeRouteId,
    onCreateRoute,
    onUpdateRoute,
    onDeleteRoute,
    onSelectRoute,
    onFocusRoute,
    startPoint,
    setStartPoint,
    endPoint,
    setEndPoint,
    selectionMode,
    setSelectionMode,
  }) => {
    const [isCreatingRoute, setIsCreatingRoute] = useState(false);
    const [newRoute, setNewRoute] = useState({
      name: "",
      description: "",
      color: "#3388ff",
    });
    const [expandedRouteId, setExpandedRouteId] = useState(null);
    
    // Estado para la entrada manual de coordenadas
    const [manualCoordinates, setManualCoordinates] = useState({
      start: { lat: "", lng: "" },
      end: { lat: "", lng: "" },
    });
    
    // Sincronizar puntos seleccionados con los campos manuales
    useEffect(() => {
      if (startPoint) {
        setManualCoordinates(prev => ({
          ...prev,
          start: {
            lat: startPoint.lat.toString(),
            lng: startPoint.lng.toString()
          }
        }));
      }
      
      if (endPoint) {
        setManualCoordinates(prev => ({
          ...prev,
          end: {
            lat: endPoint.lat.toString(),
            lng: endPoint.lng.toString()
          }
        }));
      }
    }, [startPoint, endPoint]);
    
    // Actualizar puntos cuando se modifican manualmente las coordenadas
    const handleCoordinateChange = (type, coord, value) => {
      setManualCoordinates(prev => ({
        ...prev,
        [type]: {
          ...prev[type],
          [coord]: value
        }
      }));
      
      // Convertir a número y actualizar el punto correspondiente
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        if (type === 'start') {
          setStartPoint(prev => ({
            ...prev,
            [coord]: numValue
          }));
        } else if (type === 'end') {
          setEndPoint(prev => ({
            ...prev,
            [coord]: numValue
          }));
        }
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      
      if (!newRoute.name.trim()) {
        alert("El nombre de la ruta es obligatorio");
        return;
      }
      
      // Verificar que haya coordenadas válidas tanto para inicio como fin
      const validStartPoint = startPoint || 
        (manualCoordinates.start.lat && manualCoordinates.start.lng ? 
          {
            lat: parseFloat(manualCoordinates.start.lat),
            lng: parseFloat(manualCoordinates.start.lng)
          } : null);
          
      const validEndPoint = endPoint || 
        (manualCoordinates.end.lat && manualCoordinates.end.lng ? 
          {
            lat: parseFloat(manualCoordinates.end.lat),
            lng: parseFloat(manualCoordinates.end.lng)
          } : null);
      
      if (!validStartPoint || !validEndPoint) {
        alert("Se requieren coordenadas válidas para los puntos de inicio y fin");
        return;
      }
      
      // Crear la ruta con los puntos de inicio y fin como waypoints
      const waypoints = [validStartPoint, validEndPoint];
      
      // Importante: llamar a onCreateRoute con los waypoints completos
      onCreateRoute({
        ...newRoute,
        waypoints: waypoints,
        startPoint: validStartPoint,
        endPoint: validEndPoint,
      });
      
      // Resetear formulario
      setNewRoute({ name: "", description: "", color: "#3388ff" });
      setManualCoordinates({
        start: { lat: "", lng: "" },
        end: { lat: "", lng: "" }
      });
      setIsCreatingRoute(false);
    };
  
    const handleRouteClick = (routeId) => {
      onSelectRoute(routeId);
      
      // Toggle expandir/contraer detalle de ruta
      setExpandedRouteId(expandedRouteId === routeId ? null : routeId);
    };
  
    const handleToggleVisibility = (e, routeId) => {
      e.stopPropagation();
      const route = routes.find(r => r.id === routeId);
      if (route) {
        onUpdateRoute(routeId, { visible: !route.visible });
      }
    };
  
    const handleColorChange = (routeId, color) => {
      onUpdateRoute(routeId, { color });
    };
    
    // Activar modo de selección para punto de inicio
    const handleSelectStartPoint = () => {
      setSelectionMode("start");
    };
    
    // Activar modo de selección para punto final
    const handleSelectEndPoint = () => {
      setSelectionMode("end");
    };
  
    return (
      <div className="route-manager">
        <div className="route-manager__header">
          <h3>Mis Rutas ({routes.length})</h3>
          <button 
            className="btn btn-primary" 
            onClick={() => setIsCreatingRoute(true)}
          >
            Nueva Ruta
          </button>
        </div>
  
        {isCreatingRoute && (
          <div className="route-manager__form-container">
            <form onSubmit={handleSubmit} className="route-manager__form">
              <div className="form-group">
                <label>Nombre de la Ruta</label>
                <input
                  type="text"
                  value={newRoute.name}
                  onChange={(e) => setNewRoute({ ...newRoute, name: e.target.value })}
                  placeholder="Nombre de la ruta"
                  className="form-control"
                  required
                />
              </div>
              
              <div className="form-group">
                <label>Descripción</label>
                <textarea
                  value={newRoute.description}
                  onChange={(e) => setNewRoute({ ...newRoute, description: e.target.value })}
                  placeholder="Descripción (opcional)"
                  className="form-control"
                  rows={3}
                />
              </div>
              
              <div className="form-group">
                <label>Color de la Ruta</label>
                <ColorPicker
                  color={newRoute.color}
                  onChange={(color) => setNewRoute({ ...newRoute, color })}
                />
              </div>
              
              {/* Sección de coordenadas de inicio */}
              <div className="form-group">
                <label>Punto de Inicio</label>
                <div className="coordinates-container">
                  <div className="coordinate-inputs">
                    <div className="coordinate-input">
                      <label>Latitud</label>
                      <input
                        type="number"
                        step="any"
                        value={manualCoordinates.start.lat}
                        onChange={(e) => handleCoordinateChange('start', 'lat', e.target.value)}
                        placeholder="Latitud"
                        className="form-control"
                      />
                    </div>
                    <div className="coordinate-input">
                      <label>Longitud</label>
                      <input
                        type="number"
                        step="any"
                        value={manualCoordinates.start.lng}
                        onChange={(e) => handleCoordinateChange('start', 'lng', e.target.value)}
                        placeholder="Longitud"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary map-select-btn"
                    onClick={handleSelectStartPoint}
                    disabled={selectionMode === "start"}
                  >
                    {selectionMode === "start" ? "Seleccionando..." : "Seleccionar en Mapa"}
                  </button>
                </div>
              </div>
              
              {/* Sección de coordenadas de fin */}
              <div className="form-group">
                <label>Punto Final</label>
                <div className="coordinates-container">
                  <div className="coordinate-inputs">
                    <div className="coordinate-input">
                      <label>Latitud</label>
                      <input
                        type="number"
                        step="any"
                        value={manualCoordinates.end.lat}
                        onChange={(e) => handleCoordinateChange('end', 'lat', e.target.value)}
                        placeholder="Latitud"
                        className="form-control"
                      />
                    </div>
                    <div className="coordinate-input">
                      <label>Longitud</label>
                      <input
                        type="number"
                        step="any"
                        value={manualCoordinates.end.lng}
                        onChange={(e) => handleCoordinateChange('end', 'lng', e.target.value)}
                        placeholder="Longitud"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary map-select-btn"
                    onClick={handleSelectEndPoint}
                    disabled={selectionMode === "end"}
                  >
                    {selectionMode === "end" ? "Seleccionando..." : "Seleccionar en Mapa"}
                  </button>
                </div>
              </div>
              
              <div className="form-buttons">
                <button 
                  type="submit" 
                  className="btn btn-success"
                  disabled={!newRoute.name.trim() || 
                           (!startPoint && (!manualCoordinates.start.lat || !manualCoordinates.start.lng)) ||
                           (!endPoint && (!manualCoordinates.end.lat || !manualCoordinates.end.lng))}
                >
                  Guardar
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setIsCreatingRoute(false);
                    setManualCoordinates({
                      start: { lat: "", lng: "" },
                      end: { lat: "", lng: "" }
                    });
                    setStartPoint(null);
                    setEndPoint(null);
                    setSelectionMode(null);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        )}
  
        <div className="route-manager__list">
          {routes.length === 0 ? (
            <p className="no-data-message">No hay rutas disponibles</p>
          ) : (
            routes.map((route) => (
              <div
                key={route.id}
                className={`route-item ${route.id === activeRouteId ? "active" : ""}`}
                onClick={() => handleRouteClick(route.id)}
              >
                <div className="route-item__header">
                  <div className="route-item__color" style={{ backgroundColor: route.color }}></div>
                  <h4 className="route-item__name">{route.name}</h4>
                  <div className="route-item__actions">
                    <button
                      className={`visibility-btn ${route.visible ? "visible" : "hidden"}`}
                      onClick={(e) => handleToggleVisibility(e, route.id)}
                      title={route.visible ? "Ocultar ruta" : "Mostrar ruta"}
                    >
                      <i className={`fas fa-${route.visible ? "eye" : "eye-slash"}`}></i>
                    </button>
                    <button
                      className="focus-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        onFocusRoute(route.id);
                      }}
                      title="Enfocar ruta"
                    >
                      <i className="fas fa-search-location"></i>
                    </button>
                  </div>
                </div>
                
                {expandedRouteId === route.id && (
                  <div className="route-item__details">
                    {route.description && (
                      <p className="route-item__description">{route.description}</p>
                    )}
                    
                    <div className="route-item__stats">
                      <div className="stat">
                        <span className="stat-label">Puntos:</span>
                        <span className="stat-value">{route.waypoints?.length || 0}</span>
                      </div>
                      {route.routeData && (
                        <div className="stat">
                          <span className="stat-label">Distancia:</span>
                          <span className="stat-value">
                            {(route.routeData.routes[0].legs.reduce(
                              (acc, leg) => acc + leg.distance.value, 0
                            ) / 1000).toFixed(2)} km
                          </span>
                        </div>
                      )}
                    </div>
                    
                    {/* Mostrar coordenadas de inicio y fin */}
                    {route.waypoints && route.waypoints.length >= 2 && (
                      <div className="route-item__waypoints">
                        <div className="waypoint">
                          <span className="waypoint-label">Inicio:</span>
                          <span className="waypoint-value">
                            {route.waypoints[0].lat.toFixed(6)}, {route.waypoints[0].lng.toFixed(6)}
                          </span>
                        </div>
                        <div className="waypoint">
                          <span className="waypoint-label">Fin:</span>
                          <span className="waypoint-value">
                            {route.waypoints[route.waypoints.length - 1].lat.toFixed(6)}, 
                            {route.waypoints[route.waypoints.length - 1].lng.toFixed(6)}
                          </span>
                        </div>
                      </div>
                    )}
                    
                    <div className="route-item__edit">
                      <div className="form-group">
                        <label>Color de Ruta</label>
                        <ColorPicker
                          color={route.color}
                          onChange={(color) => handleColorChange(route.id, color)}
                        />
                      </div>
                      
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (window.confirm("¿Estás seguro de eliminar esta ruta?")) {
                            onDeleteRoute(route.id);
                          }
                        }}
                      >
                        Eliminar Ruta
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    );
  };
  
  export default RouteManager;