import React from 'react';
import PropTypes from "prop-types";
import DeMomentoImg from '../../assets/img/de_momento.jpg';
import Pagina204 from '../../assets/img/Pagina_204.png';
import '../../assets/scss/components/StatusDisplay.scss';

export default function StatusDisplay({type, message}) {
    // Definimos los estados posibles incluyendo loading
    const statusImages = {
        loading: null, // Podríamos agregar una imagen de loading si lo deseas
        200: null,
        204: Pagina204,
        400: DeMomentoImg,
        //401: UnauthorizedImage,
        //403: ForbiddenImage,
        //404: NotFoundImage,
        //408: TimeoutImage,
        //429: TooManyRequestsImage,
        //500: ServerErrorImage,
        //503: ServiceUnavailableImage,
    };

    // Renderizado condicional para el estado de carga
    if (type === 'loading') {
        return (
            <div className="status-display">
                <div className="status-display__content">
                    <div className="status-display__loading">
                        {/* Puedes agregar aquí un spinner o animación de carga */}
                        <div className="loading-spinner"></div>
                    </div>
                    <p className="status-display__message">{message}</p>
                </div>
            </div>
        );
    }

    const statusImage = statusImages[type];
    if (!statusImage && type !== 200) return null;

    return (
        <div className="status-display">
            <div className="status-display__content">
                {statusImage && (
                    <div className="status-display__image-container">
                        <img 
                            src={statusImage} 
                            alt={`Status ${type}`} 
                            className="status-display__image"
                        />
                    </div>
                )}
                <p className="status-display__message">{message}</p>
            </div>
        </div>
    );
}

// Actualizamos PropTypes para incluir 'loading'
StatusDisplay.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.oneOf(['loading']),
        PropTypes.oneOf([200, 204, 400, 401, 403, 404, 408, 429, 500, 503])
    ]).isRequired,
    message: PropTypes.string.isRequired,
};