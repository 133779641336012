import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
} from "@mui/material";
import {
  useVehicleTypes,
  useVehicleBrands,
  useSaveVehicle,
} from "../../../hooks/useVehicleQuery";
import {
  useNotification,
  NotificationSeverity,
} from "../../../context/NotificationContext";
import { useLayout } from "../../../context/LayoutContext";
import VehicleTypeSelector from "../../../components/vehicle/VehicleTypeSelector";
import VehicleInfoSection from "../../../components/vehicle/VehicleInfoSection";
import ContactSectionAddVehicle from "../../../components/vehicle/ContactSectionAddVehicle";
import DocumentsSection from "../../../components/vehicle/DocumentsSection.jsx";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import "../../../assets/scss/views/AddVehicle.scss";
import { useOperations } from "../../../hooks/useDocumentationVehicleQuery.js";

export default function AddVehicle() {
  const { theme } = useLayout();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { mutateAsync: saveVehicle, isLoading: isSaving } = useSaveVehicle();
  const { data: vehicleTypesData } = useVehicleTypes();
  const { data: operationsData } = useOperations({ limit: 100 });

  // Estado principal para datos del vehículo con nuevas propiedades
  const [vehicleData, setVehicleData] = useState({
    main: {
      info: {
        vehicleTypeId: "",
        plate: "",
        brand: "",
        model: "",
        capacity: "",
        year: "",
        limitedNotifications: true,
        ownFleet: true

      },
      contact: {
        operatorId: "",
        ownerId: "",
        primaryPhoneNumber: "",
        primaryPhoneCountryId: "",
        secondaryPhoneNumber: "",
        secondaryPhoneCountryId: "",
        contractStartDate: null,
        contractEndDate: null,
        nationalityId: "",
      },
      documents: [],
    },
    trailer: {
      info: {
        vehicleTypeId: "",
        plate: "",
        brand: "",
        model: "",
        capacity: "",
        year: "",
        limitedNotifications: true,
        ownFleet: true
      },
      contact: {
        operatorId: "",
        ownerId: "",
        primaryPhoneNumber: "",
        primaryPhoneCountryId: "",
        secondaryPhoneNumber: "",
        secondaryPhoneCountryId: "",
        contractStartDate: null,
        contractEndDate: null,
        nationalityId: "",
      },
      documents: [],
    },
    belongsToOperation: false,
    oprationId: "",
    isTrailerRequired: false
  });
  
  // Estados para control de flujo y UI
  const [activeStep, setActiveStep] = useState(0);
  const [needsTrailer, setNeedsTrailer] = useState(false);
  const [selectedOperationId, setSelectedOperationId] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Función auxiliar para determinar si un tipo de vehículo requiere trailer
  const doesVehicleTypeRequireTrailer = (vehicleTypeId) => {
    if (!vehicleTypeId || !vehicleTypesData?.data) return false;
    
    const selectedType = vehicleTypesData.data.find(
      (type) => type.id === vehicleTypeId
    );
    
    // Verificar si es uno de los tipos que requieren trailer
    return selectedType?.category?.includes("Cabezote") || 
           selectedType?.name?.includes("Cama baja") || 
           selectedType?.name?.includes("Tractocamión") || 
           false;
  };

  // Función auxiliar para determinar si un tipo de vehículo es cabezote
  const isVehicleTypeCabezote = (vehicleTypeId) => {
    if (!vehicleTypeId || !vehicleTypesData?.data) return false;
    
    const selectedType = vehicleTypesData.data.find(
      (type) => type.id === vehicleTypeId
    );
    
    return selectedType?.category?.includes("Cabezote") || false;
  };

  // Detectar si el tipo seleccionado requiere trailer y actualizar estado
  useEffect(() => {
    if (vehicleData.main.info.vehicleTypeId && vehicleTypesData?.data) {
      const requiresTrailer = doesVehicleTypeRequireTrailer(vehicleData.main.info.vehicleTypeId);
      setNeedsTrailer(requiresTrailer);
      
      // Actualizar isTrailerRequired basado en el tipo de vehículo
      setVehicleData(prev => ({
        ...prev,
        isTrailerRequired: requiresTrailer
      }));
    }
  }, [vehicleData.main.info.vehicleTypeId, vehicleTypesData]);

  // Función para omitir el trailer
  const handleSkipTrailer = () => {
    setVehicleData(prev => ({
      ...prev,
      isTrailerRequired: false
    }));
    
    // Saltar a la documentación del remolque (último paso)
    setActiveStep(steps.length - 1);
    setHasChanges(true);
    
    // Notificación con parámetros explícitos y completos
    try {
      showNotification({
        message: "Se ha omitido el registro del remolque",
        severity: NotificationSeverity.INFO,
        position: { 
          vertical: 'top', 
          horizontal: 'right' 
        },
      });
    } catch (error) {
      console.error("Error al mostrar notificación:", error);
    }
  };
  const handleBelongsToOperationChange = (value) => {
    setVehicleData(prev => ({
      ...prev,
      belongsToOperation: value
    }));
    
    // Si se marca como "no pertenece a operación", limpiamos el ID de operación
    if (!value) {
      setSelectedOperationId("");
    }
    
    setHasChanges(true);
  };
  
  // Modificar el manejador existente de operación
  const handleOperationChange = (operationId) => {
    setSelectedOperationId(operationId);
    
    // Actualizamos automáticamente belongsToOperation basado en si hay un ID válido
    setVehicleData(prev => ({
      ...prev,
      belongsToOperation: !!operationId
    }));
    
    setHasChanges(true);
  };
  // Pasos del Stepper dinámicos actualizados
  const steps = needsTrailer
    ? [
        "Tipo de Vehículo",
        "Información del Cabezote",
        "Información de Contacto",
        "Documentación",
        "Información del Remolque",
        "Información de Contacto del Remolque",
        "Documentación del Remolque",
      ]
    : [
        "Tipo de Vehículo",
        "Información del Vehículo",
        "Información de Contacto",
        "Documentación",
      ];

  // Manejadores para actualización de datos
  const handleVehicleInfoChange = (section, field, value) => {
    setVehicleData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        info: {
          ...prev[section].info,
          [field]: value,
        },
      },
    }));
    setHasChanges(true);
  };

  const handleContactInfoChange = (section, field, value) => {
    setVehicleData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        contact: {
          ...prev[section].contact,
          [field]: value,
        },
      },
    }));
    setHasChanges(true);
  };

  const handleDocumentsChange = (section, action) => {
    switch (action.type) {
      case "ADD_DOCUMENT":
        setVehicleData((prev) => ({
          ...prev,
          [section]: {
            ...prev[section],
            documents: [
              ...prev[section].documents,
              {
                ...action.payload.document,
                docType: String(action.payload.document.docType),
                createdAt: new Date().toISOString(),
              },
            ],
          },
        }));
        break;

      case "UPDATE_DOCUMENT":
        setVehicleData((prev) => ({
          ...prev,
          [section]: {
            ...prev[section],
            documents: prev[section].documents.map((doc) =>
              doc.id === action.payload.docId
                ? {
                    ...doc,
                    [action.payload.field]:
                      action.payload.field === "docType"
                        ? String(action.payload.value)
                        : action.payload.value,
                    updatedAt: new Date().toISOString(),
                  }
                : doc
            ),
          },
        }));
        break;

      case "DELETE_DOCUMENT":
        setVehicleData((prev) => ({
          ...prev,
          [section]: {
            ...prev[section],
            documents: prev[section].documents.filter(
              (doc) => doc.id !== action.payload.docId
            ),
          },
        }));
        break;

      default:
        break;
    }
    setHasChanges(true);
  };

  // Navegación del Stepper
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // formatear notificaciones correctamente
  const safeShowNotification = (message, severity = NotificationSeverity.INFO) => {
    try {
      // Garantizar que siempre pasamos un objeto de posición completo y válido
      showNotification({
        message: message || "Notificación del sistema",
        severity: severity || NotificationSeverity.INFO,
        position: { 
          vertical: 'top', 
          horizontal: 'right' 
        },
      });
    } catch (error) {
      console.error("Error al mostrar notificación:", error);
      // Fallback más seguro que un alert
      console.warn(message);
    }
  };
  
  // validación por paso con lógica actualizada
  const validateStep = () => {
    const currentSection = activeStep <= 3 || !needsTrailer ? "main" : "trailer";
    const isContactStep = activeStep === 2 || (needsTrailer && activeStep === 5);
  
    // Si estamos en un paso relacionado con el trailer y se ha marcado como no requerido, saltamos la validación
    if ((activeStep >= 4) && !vehicleData.isTrailerRequired) {
      return true;
    }
  
    switch (activeStep) {
      case 0: // Tipo de vehículo
        if (!vehicleData.main.info.vehicleTypeId) {
          safeShowNotification("Por favor, seleccione un tipo de vehículo", NotificationSeverity.ERROR);
          return false;
        }
        return true;
  
      case 1: // Información principal del vehículo
      case 4: // Información del remolque
        const info = vehicleData[currentSection]?.info || {};
        const missingFields = [];
  
        if (!info.plate) missingFields.push("Placa");
        if (!info.brand) missingFields.push("Marca");
        if (!info.model) missingFields.push("Modelo");
  
        if (missingFields.length > 0) {
          safeShowNotification(
            `Por favor, complete los siguientes campos obligatorios: ${missingFields.join(", ")}`,
            NotificationSeverity.ERROR
          );
          return false;
        }
        return true;
  
      case 2: // Información de contacto del vehículo principal
      case 5: // Información de contacto del remolque
        const contact = vehicleData[currentSection].contact || {};
        const missingContactFields = [];
  
        if (!contact.primaryPhoneNumber) missingContactFields.push("Número de teléfono principal");
        if (!contact.primaryPhoneCountryId) missingContactFields.push("Código de país del teléfono principal");
        if (!contact.nationalityId) missingContactFields.push("Nacionalidad");
  
        if (missingContactFields.length > 0) {
          safeShowNotification(
            `Por favor, complete los siguientes campos obligatorios: ${missingContactFields.join(", ")}`,
            NotificationSeverity.ERROR
          );
          return false;
        }
        return true;
  
      case 3: // Documentación principal
        const mainVehicleTypeId = vehicleData.main.info.vehicleTypeId;
        const mainRequiresOperation = isVehicleTypeCabezote(mainVehicleTypeId);
        
        // Si necesita operación pero no hay una seleccionada
        if (mainRequiresOperation && !selectedOperationId) {
          safeShowNotification(
            "Por favor, seleccione una operación para este tipo de vehículo",
            NotificationSeverity.ERROR
          );
          return false;
        }
        
        // Verificar documentos requeridos
        const mainRequiredDocsCount = vehicleData.main.documents.filter(
          (doc) => doc.required && !doc.file
        ).length || 0;

        if (mainRequiredDocsCount > 0) {
          safeShowNotification(
            `Faltan ${mainRequiredDocsCount} documento${mainRequiredDocsCount === 1 ? "" : "s"} obligatorio${
              mainRequiredDocsCount === 1 ? "" : "s"
            } por cargar`,
            NotificationSeverity.ERROR
          );
          return false;
        }
        return true;

      case 6: // Documentación del remolque - Caso especial
        // Para el remolque, solo verificamos los documentos requeridos ya que
        // la operación es heredada del vehículo principal
        
        // Verificar que haya un tipo de remolque seleccionado
        if (!vehicleData.trailer.info.vehicleTypeId) {
          safeShowNotification(
            "Por favor, complete la información del remolque antes de continuar",
            NotificationSeverity.ERROR
          );
          return false;
        }
        
        // Verificar documentos requeridos
        const trailerRequiredDocsCount = vehicleData.trailer.documents.filter(
          (doc) => doc.required && !doc.file
        ).length || 0;

        if (trailerRequiredDocsCount > 0) {
          safeShowNotification(
            `Faltan ${trailerRequiredDocsCount} documento${trailerRequiredDocsCount === 1 ? "" : "s"} obligatorio${
              trailerRequiredDocsCount === 1 ? "" : "s"
            } del remolque por cargar`,
            NotificationSeverity.ERROR
          );
          return false;
        }
        return true;

      default:
        return true;
    }
  };

  const handleNextWithValidation = () => {
    if (validateStep()) {
      handleNext();
    }
  };

  // Envio del formulario actualizado para considerar el trailer opcional
  const handleSubmit = async () => {
    if (isSubmitting) return;
  
    try {
      setIsSubmitting(true);
  
      // Validar el paso actual
      if (!validateStep()) {
        setIsSubmitting(false);
        return;
      }
  
      // Validación adicional pre-envío para toda la información
      const validationErrors = validateCompleteForm();
      if (validationErrors.length > 0) {
        showNotification({
          message: `Por favor corrija los siguientes errores antes de continuar: ${validationErrors.join(
            ", "
          )}`,
          severity: NotificationSeverity.ERROR,
          position: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setIsSubmitting(false);
        return;
      }
  
      // Preparar datos para envío (sanitización)
      const submitData = {
        // Campos básicos del vehículo
        vehicleTypeId: vehicleData.main.info.vehicleTypeId,
        plate: (vehicleData.main.info.plate || "").toUpperCase().trim(),
        brand: vehicleData.main.info.brand,
        model: (vehicleData.main.info.model || "").trim(),
        capacity: vehicleData.main.info.capacity || null,
        year: vehicleData.main.info.year || null,
        
        // Operación y notificaciones - NUEVOS CAMPOS
        limitedNotifications: vehicleData.main.info.limitedNotifications,
        ownFleet: vehicleData.main.info.ownFleet,
        belongsToOperation: vehicleData.belongsToOperation,
        
        // Relaciones con usuarios
        ownerId: vehicleData.main.contact.ownerId,
        operatorId: vehicleData.main.contact.operatorId || null,
        
        // Información de contacto
        contactInfo: {
          primaryPhoneNumber: (
            vehicleData.main.contact.primaryPhoneNumber || ""
          ).trim(),
          primaryPhoneCountryId: vehicleData.main.contact.primaryPhoneCountryId,
          secondaryPhoneNumber:
            (vehicleData.main.contact.secondaryPhoneNumber || "").trim() ||
            null,
          secondaryPhoneCountryId:
            vehicleData.main.contact.secondaryPhoneCountryId || null,
          nationalityId: vehicleData.main.contact.nationalityId,
        },
        
        // Documentos procesados
        documents: vehicleData.main.documents.map((doc) => ({
          ...doc,
          docType: String(doc.docType || ""),
        })),
        
        // Fechas de contrato
        contractStartDate: vehicleData.main.contact.contractStartDate,
        contractEndDate: vehicleData.main.contact.contractEndDate,
      };
  
      // Solo agregar operationId si pertenece a una operación y tiene ID
      if (vehicleData.belongsToOperation && selectedOperationId) {
        submitData.operationId = selectedOperationId;
      }
  
      // Manejar trailer solo si es necesario y requerido
      if (needsTrailer && vehicleData.isTrailerRequired) {
        submitData.trailer = {
          // Campos básicos del remolque
          vehicleTypeId: vehicleData.trailer.info.vehicleTypeId,
          plate: (vehicleData.trailer.info.plate || "").toUpperCase().trim(),
          brand: vehicleData.trailer.info.brand,
          model: (vehicleData.trailer.info.model || "").trim(),
          capacity: vehicleData.trailer.info.capacity || null,
          year: vehicleData.trailer.info.year || null,
          
          // Operación y notificaciones - CAMPOS CONSISTENTES
          limitedNotifications: vehicleData.trailer.info.limitedNotifications,
          ownFleet: vehicleData.trailer.info.ownFleet,
          belongsToOperation: vehicleData.belongsToOperation, // Hereda del vehículo principal
          
          // Relaciones con usuarios
          ownerId:
            vehicleData.trailer.contact.ownerId ||
            vehicleData.main.contact.ownerId,
          operatorId: vehicleData.trailer.contact.operatorId || null,
          
          // Información de contacto
          contactInfo: {
            primaryPhoneNumber: (
              vehicleData.trailer.contact.primaryPhoneNumber || ""
            ).trim(),
            primaryPhoneCountryId:
              vehicleData.trailer.contact.primaryPhoneCountryId,
            secondaryPhoneNumber:
              (vehicleData.trailer.contact.secondaryPhoneNumber || "").trim() ||
              null,
            secondaryPhoneCountryId:
              vehicleData.trailer.contact.secondaryPhoneCountryId || null,
            nationalityId: vehicleData.trailer.contact.nationalityId,
          },
          
          // Documentos procesados
          documents: vehicleData.trailer.documents.map((doc) => ({
            ...doc,
            docType: String(doc.docType || ""),
          })),
          
          // Fechas de contrato
          contractStartDate: vehicleData.trailer.contact.contractStartDate,
          contractEndDate: vehicleData.trailer.contact.contractEndDate,
        };
        
        // Solo agregar operationId al trailer si pertenece a una operación y tiene ID
        if (vehicleData.belongsToOperation && selectedOperationId) {
          submitData.trailer.operationId = selectedOperationId;
        }
      }
  
      // Indicar explícitamente si el trailer es requerido
      if (needsTrailer) {
        submitData.trailerRequired = vehicleData.isTrailerRequired;
      }
  
      // Ejecutar la mutación para guardar el vehículo
      const response = await saveVehicle({ data: submitData });
      console.log("Respuesta del servidor:", response);
  
      showNotification({
        message: "Vehículo registrado exitosamente",
        severity: NotificationSeverity.SUCCESS,
        position: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setHasChanges(false);
      navigate("/vehicles");
    } catch (error) {
      showNotification({
        message:
          error.response?.data?.message || "Error al registrar el vehículo",
        severity: NotificationSeverity.ERROR,
        position: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  // Función para validación completa del formulario actualizada
  const validateCompleteForm = () => {
    const errors = [];
    
    // Validar información del vehículo principal
    if (!vehicleData.main.info.vehicleTypeId) errors.push("Tipo de vehículo no seleccionado");
    if (!vehicleData.main.info.plate) errors.push("Placa del vehículo principal no especificada");
    if (!vehicleData.main.info.brand) errors.push("Marca del vehículo principal no especificada");
    if (!vehicleData.main.info.model) errors.push("Modelo del vehículo principal no especificado");
    
    // Validar información de contacto
    if (!vehicleData.main.contact.primaryPhoneNumber || !vehicleData.main.contact.primaryPhoneCountryId) {
      errors.push("Número de teléfono principal incompleto");
    }
    if (!vehicleData.main.contact.nationalityId) {
      errors.push("Nacionalidad del vehículo principal no especificada");
    }
    
    // Validar operación SOLO si el tipo de vehículo la requiere
    const mainVehicleNeedsOperation = isVehicleTypeCabezote(vehicleData.main.info.vehicleTypeId);
    if (mainVehicleNeedsOperation && !selectedOperationId) {
      errors.push("Operación no seleccionada");
    }
    
    const mainRequiredDocs = vehicleData.main.documents.filter(doc => doc.required && !doc.file);
    if (mainRequiredDocs.length > 0) errors.push("Documentos obligatorios del vehículo principal pendientes");
    
    // Validar información del remolque solo si es necesario y requerido
    if (needsTrailer && vehicleData.isTrailerRequired) {
      if (!vehicleData.trailer.info.vehicleTypeId) errors.push("Tipo de remolque no seleccionado");
      if (!vehicleData.trailer.info.plate) errors.push("Placa del remolque no especificada");
      if (!vehicleData.trailer.info.brand) errors.push("Marca del remolque no especificada");
      if (!vehicleData.trailer.info.model) errors.push("Modelo del remolque no especificado");
      
      if (!vehicleData.trailer.contact.primaryPhoneNumber || !vehicleData.trailer.contact.primaryPhoneCountryId) {
        errors.push("Número de teléfono principal del remolque incompleto");
      }
      if (!vehicleData.trailer.contact.nationalityId) {
        errors.push("Nacionalidad del remolque no especificada");
      }
      
      const trailerRequiredDocs = vehicleData.trailer.documents.filter(doc => doc.required && !doc.file);
      if (trailerRequiredDocs.length > 0) errors.push("Documentos obligatorios del remolque pendientes");
    }
    
    return errors;
  };

  // Renderizado condicional según el paso activo (actualizado)
  const renderStepContent = () => {
    // Ajuste para manejar correctamente el nuevo paso de contacto del remolque
    let currentSection = "main";
    
    if (needsTrailer && activeStep >= 4) {
      currentSection = "trailer";
    }

    switch (activeStep) {
      case 0:
        return (
          <VehicleTypeSelector
            selectedTypeId={vehicleData.main.info.vehicleTypeId}
            onChange={(value) =>
              handleVehicleInfoChange("main", "vehicleTypeId", value)
            }
            theme={theme}
          />
        );

      case 1:
      case 4:
        return (
          <VehicleInfoSection
            vehicleInfo={vehicleData[currentSection].info}
            onChange={(field, value) =>
              handleVehicleInfoChange(currentSection, field, value)
            }
            theme={theme}
            isTrailer={activeStep === 4}
          />
        );

      case 2:
      case 5: // Nuevo caso para información de contacto del remolque
        return (
          <ContactSectionAddVehicle
            contactInfo={vehicleData[currentSection].contact}
            onContactInfoChange={(field, value) =>
              handleContactInfoChange(currentSection, field, value)
            }
            theme={theme}
          />
        );

      case 3: // Documentación del vehículo principal
        return (
          <DocumentsSection
            vehicleTypeId={vehicleData.main.info.vehicleTypeId}
            operationId={selectedOperationId}
            onOperationChange={setSelectedOperationId}
            documents={vehicleData.main.documents}
            onDocumentsChange={(action) =>
              handleDocumentsChange("main", action)
            }
            onBelongsToOperationChange={handleBelongsToOperationChange}
            theme={theme}
            operations={operationsData?.data || []}
            requiresOperation={isVehicleTypeCabezote(vehicleData.main.info.vehicleTypeId)}
            allowChangeOperation={true}
            isTrailer={false}
          />
        );

      case 6: // Documentación del remolque - Separado para mayor claridad
        return (
          <DocumentsSection
            vehicleTypeId={vehicleData.trailer.info.vehicleTypeId || ''} 
            operationId={selectedOperationId}
            onOperationChange={setSelectedOperationId}
            documents={vehicleData.trailer.documents}
            onDocumentsChange={(action) =>
              handleDocumentsChange("trailer", action)
            }
            theme={theme}
            operations={operationsData?.data || []}
            requiresOperation={false} 
            allowChangeOperation={false}
            isTrailer={true}
          />
        );

      default:
        return <div>Paso no definido</div>;
    }
  };
  
  // Evento para confirmar antes de abandonar la página con cambios sin guardar
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasChanges]);

  return (
    <div className={`GM__${theme}-add-vehicle-container`}>
      {isSubmitting && <LoadingAnimation />}

      <Paper elevation={3} className={`GM__${theme}-vehicle-form-container`}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={`GM__${theme}-stepper`}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className={`GM__${theme}-step-content`}>{renderStepContent()}</div>

        <div className={`GM__${theme}-navigation-buttons`}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={`GM__${theme}-back-button`}
          >
            Atrás
          </Button>

          {/* Botón "Omitir Trailer" visible en los pasos relacionados con el remolque (excepto el último) */}
          {needsTrailer && activeStep >= 4 && activeStep < steps.length - 1 && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSkipTrailer}
              className={`GM__${theme}-skip-button`}
            >
              Omitir Trailer
            </Button>
          )}

          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
              className={`GM__${theme}-submit-button`}
            >
              {isSubmitting ? "Procesando..." : "Registrar Vehículo"}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextWithValidation}
              className={`GM__${theme}-next-button`}
            >
              Siguiente
            </Button>
          )}
        </div>
      </Paper>
    </div>
  );
}