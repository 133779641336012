import React, { createContext, useContext, useCallback } from 'react';
import { SnackbarProvider as NotistackProvider, useSnackbar } from 'notistack';
import { Alert, Slide } from '@mui/material';
import PropTypes from 'prop-types';

const TransitionTypes = {
  Slide: Slide,
};

const NotificationContext = createContext(null);

// Componente intermedio que proporciona la funcionalidad de notistack
const NotificationImplementation = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNotification = useCallback((config) => {
    // Validación más robusta de la configuración recibida
    const validConfig = typeof config === 'object' ? config : { message: String(config) };
    
    const {
      message = 'Notificación',
      severity = 'info',
      position,
      autoHideDuration = 3000,
      transition = TransitionTypes.Slide
    } = validConfig;

    // Garantizar que position siempre tenga un formato válido
    const validPosition = position && 
      typeof position === 'object' && 
      position.vertical && 
      position.horizontal ? position : 
      { vertical: 'top', horizontal: 'right' };

    enqueueSnackbar(message, {
      variant: severity,
      anchorOrigin: validPosition,
      autoHideDuration,
      TransitionComponent: transition,
      onClose: (event, reason, key) => {
        if (reason === 'timeout') {
          closeSnackbar(key);
        }
      },
      content: (key) => (
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => closeSnackbar(key)}
          severity={severity}
          sx={{
            width: '100%',
            '& .MuiAlert-message': {
              display: 'flex',
              alignItems: 'center'
            }
          }}
        >
          {message}
        </Alert>
      )
    });
  }, [enqueueSnackbar, closeSnackbar]);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Componente principal que combina notistack con nuestro contexto personalizado
export const NotificationProvider = ({ children }) => {
  return (
    <NotistackProvider
      maxSnack={3}
      preventDuplicate
      dense
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      TransitionComponent={Slide}
      style={{
        marginTop: '10px'
      }}
    >
      <NotificationImplementation>
        {children}
      </NotificationImplementation>
    </NotistackProvider>
  );
};

// Hook personalizado para usar notificaciones
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification debe usarse dentro de un NotificationProvider');
  }
  return context;
};

// Validación de PropTypes
NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

NotificationImplementation.propTypes = {
  children: PropTypes.node.isRequired
};

// Tipos de severidad exportados para facilidad de uso
export const NotificationSeverity = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info'
};

// Posiciones predefinidas
export const NotificationPositions = {
  TOP_RIGHT: { vertical: 'top', horizontal: 'right' },
  TOP_LEFT: { vertical: 'top', horizontal: 'left' },
  BOTTOM_RIGHT: { vertical: 'bottom', horizontal: 'right' },
  BOTTOM_LEFT: { vertical: 'bottom', horizontal: 'left' }
};