import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "@tanstack/react-query";
import { useCommentsAndLocations } from "../../../hooks/commentsAndResidenceQuery";
import {
  useNotification,
  NotificationSeverity,
} from "../../../context/NotificationContext";

import LoadingAnimation from "../../loading/LoadingAnimation";
import CommentsLocationsDrawer from "../drawner/CommentsLocationsDrawer";
import CustomDatePicker from "../CustomDatePicker";
import CustomInputField from "../inputs/CustomInputField";
import { Tooltip } from "@mui/material";

// Iconos
import UploadSuccessIcon from "../../../assets/svg/view/upload_success.svg";
import SaveIcon from "../../../assets/svg/components/save.svg";
import LoadingIcon from "../../../assets/svg/view/loading.svg";
import LoadingLightIcon from "../../../assets/svg/view/loading_light.svg";
import CalendarIcon from "../../../assets/svg/components/calendar.svg";
import CalendarLightIcon from "../../../assets/svg/components/calendar_light.svg";
import TrashIcon from "../../../assets/svg/components/trash.svg";
import SeeIcon from "../../../assets/svg/components/see.svg";
import UpdatedIcon from "../../../assets/svg/components/updated.svg";
import ComentIcon from "../../../assets/svg/components/coment.svg";
import ArrowUpIcon from "../../../assets/svg/view/arrow_up.svg";

export default function VehicleDocumentsSectionProfile({
  documents,
  onDocumentsChange,
  dataTypes,
  isLoading,
  canEdit,
  onPreview,
  onUpdate,
  onDelete,
  onAddDocument,
  theme,
}) {
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();
  const {
    useCommentsByReference,
    createComment,
    updateComment,
    deleteComment,
  } = useCommentsAndLocations();

  // Estado para gestión de comentarios
  const [commentDrawerOpen, setCommentDrawerOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const dateIconSrc = theme === "dark" ? CalendarLightIcon : CalendarIcon;
  const loadingIconSrc = theme === "dark" ? LoadingLightIcon : LoadingIcon;
  
  // Estado para rastrear cambios locales en documentos
  const [modifiedDocs, setModifiedDocs] = useState({});

  // Verifica si un documento tiene cambios pendientes
  const hasChanges = useCallback(
    (docId) => {
      return modifiedDocs[docId] !== undefined;
    },
    [modifiedDocs]
  );

  // Obtener comentarios del documento seleccionado
  const { data: documentComments } = useCommentsByReference(
    selectedDocumentId || ""
  );

  // Manejadores para comentarios
  const handleOpenComments = (docId) => {
    setSelectedDocumentId(docId);
    setCommentDrawerOpen(true);
  };

  const handleCloseComments = () => {
    setCommentDrawerOpen(false);
  };

  const handleCreateComment = async (commentData) => {
    try {
      await createComment({ ...commentData, reference_id: selectedDocumentId });
      queryClient.invalidateQueries(["comments", selectedDocumentId]);
      showNotification({
        message: "Comentario creado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (error) {
      showNotification({
        message:
          error.response?.data?.message || "Error al crear el comentario",
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  const handleUpdateComment = async (commentId, commentData) => {
    try {
      await updateComment({
        commentId,
        data: { ...commentData, reference_id: selectedDocumentId },
      });
      queryClient.invalidateQueries(["comments", selectedDocumentId]);
      showNotification({
        message: "Comentario actualizado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (error) {
      showNotification({
        message:
          error.response?.data?.message || "Error al actualizar el comentario",
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteComment(commentId);
      queryClient.invalidateQueries(["comments", selectedDocumentId]);
      showNotification({
        message: "Comentario eliminado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (error) {
      showNotification({
        message:
          error.response?.data?.message || "Error al eliminar el comentario",
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  // Manejadores para documentos
  const handleFileSelect = (typeId, docId, event) => {
    const file = event.target.files[0];
    if (file) {
      // Almacenar cambios localmente
      setModifiedDocs((prev) => ({
        ...prev,
        [docId]: {
          ...(prev[docId] || {}),
          file: file,
          hasFileChange: true,
        },
      }));
    }
  };

  const handleUpdateDocument = (typeId, docId, field, value) => {
    // Actualizar en estado local primero
    setModifiedDocs((prev) => ({
      ...prev,
      [docId]: {
        ...(prev[docId] || {}),
        [field]: value,
        docId: docId,
        typeId: typeId,
      },
    }));
  };

  // Guardar cambios de un documento existente
  const handleSaveChanges = (doc) => {
    // Combinar el documento original con los cambios pendientes
    const changes = modifiedDocs[doc.id] || {};
    const updatedDoc = {
      ...doc,
      ...changes,
      // Asegurar que los campos críticos se preserven
      document_type_id: doc.document_type_id,
      id: doc.id,
    };

    // Enviar al componente padre para actualización
    onUpdate(updatedDoc);

    // Limpiar cambios pendientes para este documento
    setModifiedDocs((prev) => {
      const updated = { ...prev };
      delete updated[doc.id];
      return updated;
    });
  };

  // Agregar nuevo documento
// En VehicleDocumentsSectionProfile.jsx
const handleAddDocument = (typeId) => {
  if (!canEdit) return;

  // Generación de ID mejorada con componente aleatorio para garantizar unicidad
  const randomComponent = Math.random().toString(36).substring(2, 10);
  const timestamp = Date.now();
  const uniqueId = `temp-${timestamp}-${randomComponent}`;

  // Crear documento temporal con estructura correcta
  const tempDoc = {
    id: uniqueId,
    file_name: "",
    file: null,
    expedition_date: null,
    expiration_date: null,
    original_name_description: "",
    document_type_id: typeId,
    document_type_name:
      dataTypes.find((type) => type.id === typeId)?.name || "",
    required: false,
    isNew: true,
    isLocal: true,
  };

  // Agregar documento al estado del componente padre
  onAddDocument(tempDoc);
};

  // Eliminar documento local
  const handleLocalDelete = (doc) => {
    onDocumentsChange({
      type: "DELETE_LOCAL_DOCUMENT",
      payload: {
        typeId: doc.document_type_id,
        docId: doc.id,
      },
    });

    // Limpiar cambios pendientes
    setModifiedDocs((prev) => {
      const updated = { ...prev };
      delete updated[doc.id];
      return updated;
    });
  };

  // Guardar nuevo documento
  const handleSaveNewDocument = (doc) => {
    // Combinar documento con cambios pendientes
    const changes = modifiedDocs[doc.id] || {};
    const documentToUpload = {
      ...doc,
      ...changes,
      file: changes.file || doc.file,
      document_type_id: doc.document_type_id,
      original_name_description:
        changes.original_name_description ||
        doc.original_name_description ||
        "",
      required:
        changes.required !== undefined
          ? changes.required
          : doc.required || false,
      expedition_date: changes.expedition_date || doc.expedition_date || null,
      expiration_date: changes.expiration_date || doc.expiration_date || null,
    };

    // Validar que haya un archivo
    if (!documentToUpload.file) {
      showNotification({
        message: "Debe seleccionar un archivo para guardar el documento",
        severity: NotificationSeverity.WARNING,
      });
      return;
    }

    // Enviar documento al componente padre
    onDocumentsChange({
      type: "ADD_DOCUMENT",
      payload: documentToUpload,
    });

    // Limpiar cambios pendientes
    setModifiedDocs((prev) => {
      const updated = { ...prev };
      delete updated[doc.id];
      return updated;
    });
  };

  // Obtener valores actuales de un documento (combinando original con cambios)
  const getDocumentWithChanges = useCallback(
    (doc) => {
      const changes = modifiedDocs[doc.id] || {};
      return {
        ...doc,
        file: changes.file || doc.file,
        expedition_date: changes.expedition_date || doc.expedition_date,
        expiration_date: changes.expiration_date || doc.expiration_date,
        original_name_description:
          changes.original_name_description || doc.original_name_description || "",
        required: changes.required !== undefined ? changes.required : doc.required || false,
      };
    },
    [modifiedDocs]
  );

  // Verificar si un campo específico ha sido modificado
  const isFieldModified = useCallback(
    (docId, fieldName) => {
      return modifiedDocs[docId] && modifiedDocs[docId][fieldName] !== undefined;
    },
    [modifiedDocs]
  );

  // Renderizar botones de acción
  const renderActionButtons = useCallback(
    (doc) => {
      if (!canEdit) return null;

      const isLocalDoc = doc.id?.startsWith("temp-") || doc.isLocal;
      const hasDocChanges = hasChanges(doc.id);

      const tooltipProps = {
        arrow: true,
        placement: "top",
        enterDelay: 500,
        leaveDelay: 200,
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: "rgba(33, 33, 33, 0.9)",
            color: "#ffffff",
            fontSize: "0.75rem",
            padding: "6px 12px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
          },
          "& .MuiTooltip-arrow": {
            color: "rgba(33, 33, 33, 0.9)",
          },
        },
      };

      return (
        <div className={`GM__${theme}-action-buttons-container`}>
          {/* Botón de previsualización (solo para documentos existentes con archivo) */}
          {!isLocalDoc &&
            (doc.file_name?.toLowerCase().endsWith(".pdf") ||
              doc.file_name?.toLowerCase().match(/\.(jpe?g|png|gif|webp)$/)) && (
              <Tooltip title="Previsualizar documento" {...tooltipProps}>
                <button
                  className={`GM__${theme}-preview-btn`}
                  onClick={() => onPreview(doc)}
                >
                  <img
                    src={SeeIcon}
                    alt="Previsualizar"
                    className={`GM__${theme}-action-icon`}
                  />
                </button>
              </Tooltip>
            )}

          {/* Botón de guardar/actualizar (cambia visualmente si hay cambios pendientes) */}
          {/* MODIFICADO: Uso de span como wrapper para botones deshabilitados */}
          <Tooltip
            title={
              isLocalDoc
                ? "Guardar documento"
                : hasDocChanges
                ? "Guardar cambios"
                : "Actualizar documento"
            }
            {...tooltipProps}
          >
            {/* Span wrapper para evitar el error con botones deshabilitados */}
            <span>
              <button
                className={`GM__${theme}-${
                  isLocalDoc 
                    ? "save" 
                    : hasDocChanges 
                      ? "modified" 
                      : "update"
                }-btn`}
                onClick={() =>
                  isLocalDoc
                    ? handleSaveNewDocument(doc)
                    : hasDocChanges
                    ? handleSaveChanges(doc)
                    : null
                }
                disabled={!isLocalDoc && !hasDocChanges}
              >
                <img
                  src={
                    isLocalDoc 
                      ? SaveIcon 
                      : hasDocChanges 
                        ? SaveIcon 
                        : UpdatedIcon
                  }
                  alt={
                    isLocalDoc
                      ? "Guardar"
                      : hasDocChanges
                      ? "Guardar cambios"
                      : "Actualizar"
                  }
                  className={`GM__${theme}-action-icon`}
                />
              </button>
            </span>
          </Tooltip>

          {/* Botón de eliminar */}
          <Tooltip
            title={isLocalDoc ? "Eliminar entrada" : "Eliminar documento"}
            {...tooltipProps}
            sx={{
              ...tooltipProps.sx,
              "& .MuiTooltip-tooltip": {
                ...tooltipProps.sx["& .MuiTooltip-tooltip"],
                backgroundColor: "rgba(211, 47, 47, 0.9)",
              },
              "& .MuiTooltip-arrow": {
                color: "rgba(211, 47, 47, 0.9)",
              },
            }}
          >
            <button
              className={`GM__${theme}-delete-btn`}
              onClick={() =>
                isLocalDoc
                  ? handleLocalDelete(doc)
                  : onDelete({
                      id: doc.id,
                      name:
                        doc.original_name_description ||
                        doc.file_name ||
                        "Documento sin nombre",
                    })
              }
            >
              <img
                src={TrashIcon}
                alt="Eliminar"
                className={`GM__${theme}-action-icon`}
              />
            </button>
          </Tooltip>

          {/* Botón de comentario */}
          <Tooltip
            title="Comentario del documento"
            {...tooltipProps}
            sx={{
              ...tooltipProps.sx,
              "& .MuiTooltip-tooltip": {
                ...tooltipProps.sx["& .MuiTooltip-tooltip"],
                backgroundColor: "rgb(22, 64, 213)",
              },
              "& .MuiTooltip-arrow": {
                color: "rgb(22, 64, 213)",
              },
            }}
          >
            <button
              className={`GM__${theme}-coment-btn`}
              onClick={() => handleOpenComments(doc.id)}
            >
              <img
                src={ComentIcon}
                alt="Comentar"
                className={`GM__${theme}-action-icon`}
              />
            </button>
          </Tooltip>
        </div>
      );
    },
    [canEdit, onPreview, onDelete, hasChanges, theme]
  );

  // Renderizar fila de documento
  const renderDocumentRow = useCallback(
    
    (doc, type) => {
      const displayDoc = getDocumentWithChanges(doc);
      const hasDocChanges = hasChanges(doc.id);
      const isLocalDoc = doc.id?.startsWith("temp-") || doc.isLocal;
      
      // Clases condicionales para campos modificados
      const nameModifiedClass = isFieldModified(doc.id, "original_name_description") ? "modified" : "";
      const expModifiedClass = isFieldModified(doc.id, "expedition_date") ? "modified" : "";
      const expirModifiedClass = isFieldModified(doc.id, "expiration_date") ? "modified" : "";
      const reqModifiedClass = isFieldModified(doc.id, "required") ? "modified" : "";
      const fileModifiedClass = isFieldModified(doc.id, "file") ? "modified" : "";
      
      return (
        <tr
          key={doc.id}
          className={`${doc.isEmpty ? `GM__${theme}-empty-document` : ""} ${
            hasDocChanges ? `GM__${theme}-modified-row` : ""
          }`}
        >
          <td>
            <img
              src={
                isLocalDoc
                  ? displayDoc.file
                    ? UploadSuccessIcon
                    : loadingIconSrc
                  : doc.file_path
                  ? UploadSuccessIcon
                  : loadingIconSrc
              }
              alt={
                isLocalDoc
                  ? displayDoc.file
                    ? "Documento local listo para subir"
                    : "Pendiente de selección"
                  : doc.file_path
                  ? "Documento cargado"
                  : "Pendiente"
              }
              className={`GM__${theme}-status-icon`}
            />
          </td>

          <td>
            {canEdit ? (
              <div className={`GM__${theme}-file-upload`}>
                <input
                  type="file"
                  onChange={(e) =>
                    handleFileSelect(
                      doc.document_type_id,
                      doc.id,
                      e
                    )
                  }
                  accept=".pdf,.jpg,.jpeg,.png"
                  id={`file-${doc.id}`}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor={`file-${doc.id}`}
                  className={`GM__${theme}-file-label ${fileModifiedClass}`}
                >
                  {modifiedDocs[doc.id]?.file
                    ? modifiedDocs[doc.id].file.name
                    : doc.file
                    ? doc.file.name
                    : doc.original_filename ||
                      doc.file_name ||
                      "Seleccionar archivo"}
                </label>
              </div>
            ) : (
              <span className={`GM__${theme}-file-name`}>
                {doc.file_name || "Sin archivo"}
              </span>
            )}
          </td>
          
          <td>
            <CustomDatePicker
              value={displayDoc.expedition_date}
              onChange={(date) =>
                handleUpdateDocument(
                  type.id,
                  doc.id,
                  "expedition_date",
                  date
                )
              }
              icon={<img src={dateIconSrc} alt="Calendar" />}
              disabled={!canEdit}
              className={`GM__${theme}-date-picker ${expModifiedClass}`}
              theme={theme}
            />
          </td>
          
          <td>
            <CustomDatePicker
              value={displayDoc.expiration_date}
              onChange={(date) =>
                handleUpdateDocument(
                  type.id,
                  doc.id,
                  "expiration_date",
                  date
                )
              }
              icon={<img src={dateIconSrc} alt="Calendar" />}
              disabled={!canEdit}
              className={`GM__${theme}-date-picker ${expirModifiedClass}`}
              theme={theme}
            />
          </td>
          
          <td>
            <CustomInputField
              type="text"
              value={displayDoc.original_name_description || ""}
              onChange={(e) =>
                handleUpdateDocument(
                  type.id,
                  doc.id,
                  "original_name_description",
                  e.target.value
                )
              }
              placeholder="Descripción del documento"
              disabled={!canEdit}
              className={`GM__${theme}-input-field ${nameModifiedClass}`}
              theme={theme}
            />
          </td>
          
          <td>
            <input
              type="checkbox"
              checked={displayDoc.required || false}
              onChange={(e) =>
                handleUpdateDocument(
                  type.id,
                  doc.id,
                  "required",
                  e.target.checked
                )
              }
              disabled={!canEdit}
              className={`GM__${theme}-checkbox ${reqModifiedClass}`}
            />
          </td>
          
          {canEdit && (
            <td className={`GM__${theme}-action-buttons`}>
              {renderActionButtons(doc)}
            </td>
          )}
        </tr>
      );
    },
    [
      canEdit, 
      hasChanges, 
      renderActionButtons, 
      getDocumentWithChanges, 
      isFieldModified, 
      modifiedDocs,
      dateIconSrc, 
      loadingIconSrc, 
      theme
    ]
  );

  if (isLoading) return <LoadingAnimation />;

  return (
    <div className={`GM__${theme}-documents-section`}>
      {dataTypes.map((type) => {
        // Filtrar documentos por tipo adaptado para vehículos
        const typeDocuments = documents.filter(
          (doc) => doc.document_type_id === type.id
        );

        if (typeDocuments.length === 0 && !canEdit) return null;

        return (
          <div key={type.id} className={`GM__${theme}-document-type-table`}>
            <div className={`GM__${theme}-table-header`}>
              <h3>{type.name}</h3>
              <p>{type.description}</p>
              {canEdit && (
                <button
                  className={`GM__${theme}-add-document-btn`}
                  onClick={() => handleAddDocument(type.id)}
                >
                  <img src={ArrowUpIcon} alt="Arrow Icon" />
                </button>
              )}
            </div>

            <table className={`GM__${theme}-table`}>
              <thead>
                <tr>
                  <th>Estado</th>
                  <th>Archivo</th>
                  <th>Fecha de Expedición</th>
                  <th>Fecha de Vencimiento</th>
                  <th>Nombre/Descripción</th>
                  <th>Requerido</th>
                  {canEdit && <th>Acciones</th>}
                </tr>
              </thead>
              <tbody>
                {typeDocuments.length > 0 ? (
                  typeDocuments.map((doc) => renderDocumentRow(doc, type))
                ) : (
                  <tr className={`GM__${theme}-empty-document`}>
                    <td
                      colSpan={canEdit ? 7 : 6}
                      className={`GM__${theme}-no-documents`}
                    >
                      No hay documentos para este tipo
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      })}

      <CommentsLocationsDrawer
        open={commentDrawerOpen}
        onClose={handleCloseComments}
        type="comments"
        data={documentComments?.data || []}
        onEdit={handleUpdateComment}
        onCreate={handleCreateComment}
        onDelete={handleDeleteComment}
        theme={theme}
      />
    </div>
  );
}

VehicleDocumentsSectionProfile.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      document_type_id: PropTypes.string,
      document_type_name: PropTypes.string,
      file_name: PropTypes.string,
      file_path: PropTypes.string,
      expedition_date: PropTypes.string,
      expiration_date: PropTypes.string,
      original_name_description: PropTypes.string,
      required: PropTypes.bool,
      uploaded_at: PropTypes.string,
    })
  ).isRequired,
  onDocumentsChange: PropTypes.func.isRequired,
  dataTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  canEdit: PropTypes.bool,
  onPreview: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAddDocument: PropTypes.func,
  theme: PropTypes.oneOf(["light", "dark"]),
};