import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts";

const LineChart = ({
  title = "Sin título",
  xAxisData = [],
  seriesData = [],
  theme = "light",
  height = "400px",
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // Definición de temas personalizados
  const themeConfig = {
    light: {
      backgroundColor: '#ffffff',
      textColor: '#333333',
      axisColor: '#333333',
      splitLineColor: '#eeeeee',
      tooltipBackground: '#ffffff',
      tooltipBorder: '#dddddd',
      colors: ['#5470c6', '#91cc75', '#ee6666', '#fac858', '#73c0de'],
      legendTextColor: '#333333',
      titleColor: '#333333',
      toolboxColor: '#666666',
    },
    dark: {
      backgroundColor: '#1f1f1f',
      textColor: '#ffffff',
      axisColor: '#ffffff',
      splitLineColor: '#333333',
      tooltipBackground: '#1f1f1f',
      tooltipBorder: '#333333',
      colors: ['#58a6ff', '#7ee787', '#ff7b72', '#ffa657', '#d2a8ff'],
      legendTextColor: '#ffffff',
      titleColor: '#ffffff',
      toolboxColor: '#ffffff',
    }
  };

  useEffect(() => {
    // Inicialización del gráfico con tema personalizado
    if (chartRef.current) {
      // Si existe una instancia previa, la destruimos
      if (chartInstance.current) {
        chartInstance.current.dispose();
      }

      // Inicializamos sin especificar tema en el init
      chartInstance.current = echarts.init(chartRef.current);

      return () => {
        chartInstance.current?.dispose();
      };
    }
  }, [theme]);

  useEffect(() => {
    if (!chartInstance.current) return;

    const currentTheme = themeConfig[theme];

    const option = {
      backgroundColor: currentTheme.backgroundColor,
      title: {
        text: xAxisData.length === 0 ? "No hay datos disponibles" : title,
        textStyle: {
          color: currentTheme.titleColor,
        },
        top: 0,  // Posiciona el título en la parte superior
        left: 'center',
        padding: [25, 0, 20, 0]  // Añade padding inferior al título
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: xAxisData.length > 7 ? "15%" : "3%", // Ajustar espacio inferior según rotación
        top: "15%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: currentTheme.tooltipBackground,
        borderColor: currentTheme.tooltipBorder,
        textStyle: {
          color: currentTheme.textColor,
        },
        axisPointer: {
          type: 'line',
          lineStyle: {
            color: currentTheme.splitLineColor,
          }
        }
      },
      legend: {
        data: seriesData.map((series) => series.name),
        textStyle: {
          color: currentTheme.legendTextColor,
        }
        
      },
      toolbox: {
        feature: {
          saveAsImage: {
            title: "Guardar imagen",
            iconStyle: {
              borderColor: currentTheme.toolboxColor,
            }
          }
        },
        iconStyle: {
          borderColor: currentTheme.toolboxColor,
        }
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xAxisData,
        axisLabel: {
          color: currentTheme.axisColor,
        },
        axisLine: {
          lineStyle: {
            color: currentTheme.splitLineColor,
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: currentTheme.splitLineColor,
            type: 'dashed'
          }
        }
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: currentTheme.axisColor,
        },
        axisLine: {
          lineStyle: {
            color: currentTheme.splitLineColor,
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: currentTheme.splitLineColor,
            type: 'dashed'
          }
        }
      },
      series: seriesData.map((series, index) => ({
        name: series.name,
        type: "line",
        data: series.data,
        stack: "Total",
        smooth: true,
        color: currentTheme.colors[index % currentTheme.colors.length],
        lineStyle: {
          width: 2
        },
        symbol: 'circle',
        symbolSize: 6,
        emphasis: {
          focus: 'series',
          scale: true,
        }
      })),
      animation: true,
      animationDuration: 1000,
      animationEasing: 'cubicInOut'
    };

    chartInstance.current.setOption(option, true);
  }, [xAxisData, seriesData, theme, title]);

  useEffect(() => {
    const handleResize = () => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <div ref={chartRef} style={{ height, width: "100%" }} />;
};

LineChart.propTypes = {
  title: PropTypes.string,
  xAxisData: PropTypes.arrayOf(PropTypes.string),
  seriesData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ),
  theme: PropTypes.oneOf(["light", "dark"]),
  height: PropTypes.string,
};

export default LineChart;