import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DocumentLayout from '../layout/DocumentLayout';
import LoadingAnimation from '../components/loading/LoadingAnimation';
import ErrorMessage from '../components/common/ErrorMessage';
const PublicDocumentRoute = ({ children }) => {
    console.log("entramos aqui!!!")
  const { documentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [documentExists, setDocumentExists] = useState(false);

  useEffect(() => {
    const validateDocument = async () => {
      try {
        setLoading(true);
        const document = "xd"//await getDocumentById(documentId);
        setDocumentExists(!!document);
        setLoading(false);
      } catch (err) {
        console.error('Error validating document:', err);
        setError('No se pudo verificar el documento. Intente de nuevo más tarde.');
        setLoading(false);
      }
    };

    validateDocument();
  }, [documentId]);

  if (loading) {
    return <LoadingAnimation/>;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!documentExists) {
    return <ErrorMessage message="El documento solicitado no existe o ha expirado." />;
  }

  return <DocumentLayout>{children}</DocumentLayout>;
};

PublicDocumentRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicDocumentRoute;