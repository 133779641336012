// src/utils/StreamingMedia.jsx
import { useState, useEffect } from 'react';
import { 
  BrokenImage, 
  PictureAsPdf, 
  Description 
} from '@mui/icons-material';
import styles from '../assets/scss/components/ui/StreamingMedia.module.scss';
import { useGetMedia } from '../hooks/useReactQuery';
import PropTypes from "prop-types";
import PDFViewer from '../components/PDFViewer';
import NotFound from "../assets/img/Pagina_204.png";
import LoadingAnimation from '../components/loading/LoadingAnimation';

const StreamingMedia = ({ fileId, className = '' }) => {
  const [mediaContent, setMediaContent] = useState(null);
  const [mediaMetadata, setMediaMetadata] = useState(null);

  // Solo realizamos la consulta si fileId existe y no está vacío
  const { data: dataMedia, isLoading, error } = useGetMedia(
    fileId || undefined, 
    { 
      enabled: !!fileId // Esto evita que se realice la consulta si fileId está vacío
    }
  );

  useEffect(() => {
    if (dataMedia) {
      // Solo actualizamos el estado si hay datos válidos
      if (Object.keys(dataMedia).length > 0) {
        setMediaContent(dataMedia.content || null);
        setMediaMetadata({
          fileName: dataMedia.fileName,
          fileType: dataMedia.fileType,
          fileSize: dataMedia.fileSize,
        });
      }
    }
  }, [dataMedia]);

  const renderContent = () => {
    // Si no hay fileId, mostramos la imagen por defecto
    if (!fileId) {
      return (
        <div className={styles.mediaContainer}>
          <img 
            src={NotFound} 
            alt="Imagen por defecto" 
            className={styles.image}
          />
        </div>
      );
    }

    // Si hay un error
    if (error) {
      return (
        <div className={styles.error}>
          <BrokenImage />
          <p style={{ color: "red" }}>Error al cargar el archivo</p>
        </div>
      );
    }

    // Si no hay mediaMetadata o está incompleto
    if (!mediaMetadata?.fileType) {
      return (
        <div className={styles.mediaContainer}>
          <img 
            src={NotFound} 
            alt="Imagen por defecto" 
            className={styles.image}
          />
        </div>
      );
    }

    const { fileName, fileType } = mediaMetadata;
    const mainType = fileType?.split('/')[0];

    switch (mainType) {
      case 'image':
        return (
          <img 
            src={mediaContent || NotFound} 
            alt={fileName || 'Imagen por defecto'} 
            className={styles.image} 
          />
        );

      case 'video':
        return mediaContent ? (
          <video controls src={mediaContent} className={styles.video} />
        ) : (
          <img src={NotFound} alt="Video no disponible" className={styles.image} />
        );

      case 'application':
        if (fileType === 'application/pdf') {
          return <PDFViewer fileId={fileId} />;
        }
        return (
          <div className={styles.document}>
            {fileName?.endsWith('.pdf') ? (
              <PictureAsPdf />
            ) : (
              <Description />
            )}
            <p>{fileName}</p>
          </div>
        );

      default:
        return (
          <img 
            src={NotFound} 
            alt="Formato no soportado" 
            className={styles.image}
          />
        );
    }
  };

  return (
    <div className={`${styles.mediaContainer} ${className}`}>
      {isLoading ? <LoadingAnimation /> : renderContent()}
    </div>
  );
};

StreamingMedia.propTypes = {
  fileId: PropTypes.string,
  className: PropTypes.string,
};

export default StreamingMedia;