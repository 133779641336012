// components/maps/AnnotationLayer.jsx
import React, { useState, useEffect } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";

const AnnotationLayer = ({ annotations, activeAnnotationId, updateAnnotation }) => {
  const [openInfoWindowId, setOpenInfoWindowId] = useState(null);

  // Log para depuración
  useEffect(() => {
    console.log("Renderizando anotaciones:", annotations);
  }, [annotations]);

  if (!annotations.length) return null;

  return (
    <>
      {annotations.map((annotation) => {
        const isActive = annotation.id === activeAnnotationId;
        
        // Determinar el ícono basado en la configuración
        let icon;
        if (annotation.icon === "pin") {
          icon = {
            path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
            fillColor: annotation.color,
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: "#ffffff",
            scale: 1.5,
            // IMPORTANTE: Posicionar correctamente el anclaje del pin
            anchor: new window.google.maps.Point(12, 22),
          };
        } else if (annotation.icon === "circle") {
          icon = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: annotation.color,
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: "#ffffff",
            scale: 10,
            // Para un círculo el anclaje debe estar en el centro
            anchor: new window.google.maps.Point(0, 0),
          };
        } else {
          // Ícono por defecto
          icon = {
            url: annotation.icon || "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
            scaledSize: new window.google.maps.Size(32, 32),
            // Para íconos estándar
            anchor: new window.google.maps.Point(16, 32),
          };
        }

        return (
          <Marker
            key={annotation.id}
            position={annotation.position}
            icon={icon}
            animation={isActive ? window.google.maps.Animation.BOUNCE : null}
            onClick={() => setOpenInfoWindowId(annotation.id)}
            draggable={isActive}
            onDragEnd={(e) => {
              const newPosition = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
              };
              console.log(`Anotación ${annotation.id} movida a:`, newPosition);
              updateAnnotation(annotation.id, { position: newPosition });
            }}
          >
            {openInfoWindowId === annotation.id && (
              <InfoWindow
                position={annotation.position}
                onCloseClick={() => setOpenInfoWindowId(null)}
              >
                <div className="annotation-info">
                  <h3 style={{ color: annotation.color }}>{annotation.title}</h3>
                  {annotation.description && <p>{annotation.description}</p>}
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </>
  );
};

export default AnnotationLayer;