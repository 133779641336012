// sections/RouteCharacteristicsSection.jsx
import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { GoogleMap, Polyline } from "@react-google-maps/api";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import "../../../assets/scss/components/RouteCharacteristicsSection.scss";
import { analyzeRouteTopography } from "../../../service/google/routeElevationService";

// Iconos para cada tipo de segmento
const topographyIcons = {
  Plano: require("../../../assets/svg/topography/plano.svg").default,
  "Pendiente baja positiva":
    require("../../../assets/svg/topography/pendiente-baja-positiva.svg").default,
  "Pendiente baja negativa":
    require("../../../assets/svg/topography/pendiente-baja-negativa.svg").default,
  "Pendiente media positiva":
    require("../../../assets/svg/topography/pendiente-media-positiva.svg").default,
  "Pendiente media negativa":
    require("../../../assets/svg/topography/pendiente-media-negativa.svg").default,
  "Pendiente alta positiva":
    require("../../../assets/svg/topography/pendiente-alta-positiva.svg").default,
  "Pendiente alta negativa":
    require("../../../assets/svg/topography/pendiente-alta-negativa.svg").default,
  "Zona urbana": require("../../../assets/svg/topography/zona-urbana.svg").default,
  "Zona despavimentada":
    require("../../../assets/svg/topography/zona-despavimentada.svg").default,
};

// Colores para cada tipo de segmento
const topographyColors = {
  Plano: "#41b6e6",
  "Pendiente baja positiva": "#7ebce6",
  "Pendiente baja negativa": "#4a86e8",
  "Pendiente media positiva": "#00b0f0",
  "Pendiente media negativa": "#0070c0",
  "Pendiente alta positiva": "#00b050",
  "Pendiente alta negativa": "#00b0f0",
  "Zona urbana": "#ffd966",
  "Zona despavimentada": "#d9d9d9",
};

const mapContainerStyle = {
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
};

const mapOptions = {
  mapTypeId: "terrain",
  streetViewControl: false,
  mapTypeControl: false,
};

const RouteCharacteristicsSection = ({ data, theme, onUpdate }) => {
  // Estados principales del componente
  const [topographyData, setTopographyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mapsApiLoaded, setMapsApiLoaded] = useState(false);
  const [bounds, setBounds] = useState(null);
  
  // Referencias para control preciso del ciclo de vida y actualizaciones
  const mapRef = useRef(null);
  const analysisRef = useRef(false);
  const analysisInProgressRef = useRef(false);
  const lastAnalysisCoordinates = useRef(null);
  const updateBackendTimer = useRef(null);
  const lastSyncedData = useRef(null);

  // Verificar si Google Maps ya está cargado
  useEffect(() => {
    const checkGoogleMapsLoaded = () => {
      if (window.google && window.google.maps) {
        setMapsApiLoaded(true);
      } else {
        setTimeout(checkGoogleMapsLoaded, 100);
      }
    };

    checkGoogleMapsLoaded();
  }, []);

  // Función para verificar si las coordenadas han cambiado significativamente
  const hasCoordinatesChanged = useCallback((oldCoords, newCoords) => {
    if (!oldCoords?.origin || !oldCoords?.destination) return true;
    if (!newCoords?.origin || !newCoords?.destination) return false;
    
    return (
      oldCoords.origin.lat !== newCoords.origin.lat ||
      oldCoords.origin.lng !== newCoords.origin.lng ||
      oldCoords.destination.lat !== newCoords.destination.lat ||
      oldCoords.destination.lng !== newCoords.destination.lng
    );
  }, []);

  // Función para actualizar el backend con control de frecuencia (debounce)
  const updateBackend = useCallback((result) => {
    // Evitar actualizaciones redundantes
    if (
      lastSyncedData.current &&
      JSON.stringify(lastSyncedData.current) === JSON.stringify(result)
    ) {
      return;
    }

    // Limpiar temporizador previo si existe
    if (updateBackendTimer.current) {
      clearTimeout(updateBackendTimer.current);
    }
    
    // Programar actualización con debounce
    updateBackendTimer.current = setTimeout(() => {
      if (onUpdate && typeof onUpdate === 'function' && result) {
        // Formato esperado por el componente padre
        onUpdate({
          routeCharacteristics: {
            ...result,
            analyzed: true,
            pending: false,
            lastAnalyzed: new Date().toISOString()
          }
        });
        
        // Actualizar referencia de datos sincronizados
        lastSyncedData.current = result;
      }
      updateBackendTimer.current = null;
    }, 1200); // 1.2 segundos de debounce para evitar actualizaciones frecuentes
  }, [onUpdate]);

  // Efecto principal para análisis topográfico
  useEffect(() => {
    const analyzeTopography = async () => {
      // Verificaciones de prerrequisitos
      if (!data?.coordinates?.origin || !data?.coordinates?.destination || !mapsApiLoaded) {
        return;
      }
      
      // Control de concurrencia
      if (analysisRef.current || analysisInProgressRef.current) return;
      
      // Verificación de coordenadas
      const coordsChanged = hasCoordinatesChanged(
        lastAnalysisCoordinates.current, 
        data.coordinates
      );
      
      // Usar datos existentes si las coordenadas no han cambiado
      if (
        !coordsChanged && 
        data.routeCharacteristics?.analyzed && 
        data.routeCharacteristics?.segments && 
        data.routeCharacteristics?.path
      ) {
        setTopographyData(data.routeCharacteristics);
        
        if (data.routeCharacteristics.path.length > 0) {
          const newBounds = new window.google.maps.LatLngBounds();
          data.routeCharacteristics.path.forEach((point) => {
            newBounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
          });
          setBounds(newBounds);
        }
        return;
      }
      
      // Evitar análisis redundante
      if (!coordsChanged && data.routeCharacteristics?.pending) {
        return;
      }
      
      try {
        // Control de estado
        analysisInProgressRef.current = true;
        analysisRef.current = true;
        setLoading(true);
        setError(null);
        
        // Actualizar coordenadas de referencia
        lastAnalysisCoordinates.current = JSON.parse(JSON.stringify(data.coordinates));
        
        // Verificación adicional de Google Maps API
        if (!window.google || !window.google.maps) {
          throw new Error("Google Maps API no está completamente cargada");
        }
        
        // Verificar presencia de DirectionsService
        if (typeof window.google.maps.DirectionsService !== 'function') {
          console.warn("DirectionsService no está disponible, cargando librería");
          
          // Informar al usuario
          setLoading(true);
          setError("Cargando servicios adicionales de Google Maps...");
          
          // Intentar cargar la librería
          await new Promise((resolve) => {
            // Si no hay DirectionsService después de 5 segundos, continuar de todos modos
            const timeout = setTimeout(resolve, 5000);
            
            const checkDirectionsService = () => {
              if (typeof window.google.maps.DirectionsService === 'function') {
                clearTimeout(timeout);
                resolve();
              } else {
                setTimeout(checkDirectionsService, 500);
              }
            };
            
            checkDirectionsService();
          });
          
          setError(null);
        }
        
        // Intentar análisis con reintentos
        let result;
        let attempts = 0;
        
        while (attempts < 3) {
          try {
            result = await analyzeRouteTopography(
              data.coordinates.origin,
              data.coordinates.destination,
              window.google
            );
            break; // Éxito, salir del bucle
          } catch (err) {
            attempts++;
            if (attempts >= 3) throw err; // Reintento final fallido
            
            // Esperar antes de reintentar
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
        
        // Verificar resultado
        if (!result || !result.segments) {
          throw new Error("Resultado de análisis topográfico inválido");
        }
        
        // Actualizar estado
        setTopographyData(result);
        
        // Configurar mapa
        if (result?.path?.length > 0) {
          const newBounds = new window.google.maps.LatLngBounds();
          result.path.forEach((point) => {
            newBounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
          });
          setBounds(newBounds);
        }
        
        // Actualizar backend con control
        updateBackend(result);
      } catch (err) {
        console.error("Error al analizar topografía:", err);
        
        // Error más amigable para el usuario
        setError("No se pudo obtener información topográfica. Usando estimación.");
        
        // Crear datos sintéticos como fallback
        const syntheticData = generateSyntheticTopographyData(
          data.coordinates.origin,
          data.coordinates.destination,
          data.calculatedRoute?.distance || 0
        );
        
        setTopographyData(syntheticData);
        updateBackend(syntheticData);
      } finally {
        setLoading(false);
        analysisRef.current = false;
        analysisInProgressRef.current = false;
      }
    };
    
    analyzeTopography();
    
    return () => {
      if (updateBackendTimer.current) {
        clearTimeout(updateBackendTimer.current);
      }
    };
  }, [data?.coordinates, mapsApiLoaded, hasCoordinatesChanged, updateBackend]);
// Función para generar datos topográficos sintéticos como fallback
  const generateSyntheticTopographyData = (origin, destination, totalDistance) => {
    // Crear un camino directo entre origen y destino
    const path = [origin, destination];
    
    // Generar distribución típica de segmentos
    const segments = [
      { type: 'Plano', distance: totalDistance * 0.45, percentage: 45 },
      { type: 'Pendiente baja positiva', distance: totalDistance * 0.25, percentage: 25 },
      { type: 'Pendiente baja negativa', distance: totalDistance * 0.15, percentage: 15 },
      { type: 'Zona urbana', distance: totalDistance * 0.1, percentage: 10 },
      { type: 'Pendiente media positiva', distance: totalDistance * 0.05, percentage: 5 }
    ];
    
    // Completar con tipos restantes
    ['Pendiente media negativa', 'Pendiente alta positiva', 'Pendiente alta negativa', 'Zona despavimentada'].forEach(type => {
      segments.push({ type, distance: 0, percentage: 0 });
    });
    
    return {
      path,
      totalDistance,
      segments,
      synthetic: true // Marcar como datos sintéticos
    };
  };

  // Preparar datos para el gráfico de distribución
  const prepareChartData = () => {
    if (!topographyData?.segments) return [];
    
    return topographyData.segments
      .filter((s) => s.percentage > 0)
      .map((segment) => ({
        name: segment.type,
        value: segment.percentage,
        color: topographyColors[segment.type],
      }));
  };

  // Callback para el tooltip del gráfico
  const renderTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{`${payload[0].name}: ${payload[0].value.toFixed(2)}%`}</p>
        </div>
      );
    }
    return null;
  };

  // Callback cuando el mapa está listo
  const onMapLoad = (map) => {
    mapRef.current = map;
    if (bounds) {
      map.fitBounds(bounds);
    }
  };

  // Renderizado condicional según el estado
  if (loading) {
    return (
      <div className={`GM__${theme}-route-characteristics-section loading`}>
        <div className="loading-indicator">
          <div className="spinner"></div>
          <p>Analizando características de la ruta...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`GM__${theme}-route-characteristics-section error`}>
        <div className="error-message">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!mapsApiLoaded) {
    return (
      <div className={`GM__${theme}-route-characteristics-section loading`}>
        <div className="loading-indicator">
          <p>Cargando servicios de mapas...</p>
        </div>
      </div>
    );
  }

  if (!topographyData) {
    return (
      <div className={`GM__${theme}-route-characteristics-section no-data`}>
        <p>Defina origen y destino para ver características de ruta</p>
      </div>
    );
  }

  const chartData = prepareChartData();
  const sortedSegments = [...topographyData.segments].sort(
    (a, b) => b.distance - a.distance
  );

  return (
    <div className={`GM__${theme}-route-characteristics-section`}>
      <h3>Características</h3>

      <div className="characteristics-container">
        {/* Columna de Topografía */}
        <div className="topography-column">
          <h4>Topografía</h4>
          <ul className="topography-list">
            {sortedSegments.map((segment, index) => (
              <li
                key={index}
                className={`topography-item ${
                  segment.distance === 0 ? "zero-segment" : ""
                }`}
              >
                <div className="topography-icon">
                  <img src={topographyIcons[segment.type]} alt={segment.type} />
                </div>
                <span className="topography-name">{segment.type}</span>
                <span className="topography-distance">
                  {segment.distance.toFixed(2)} km
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* Columna de Mapa */}
        <div className="map-column">
          <h4>Mapa geográfico</h4>
          <div className="map-container">
            <div
              className="map-wrapper"
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={data.coordinates.origin}
                zoom={8}
                options={mapOptions}
                onLoad={onMapLoad}
              >
                {topographyData.path && (
                  <Polyline
                    path={topographyData.path}
                    options={{
                      strokeColor: "#0066cc",
                      strokeOpacity: 0.8,
                      strokeWeight: 3,
                    }}
                  />
                )}
              </GoogleMap>
            </div>
          </div>
        </div>

        {/* Columna de Gráfico */}
        <div className="chart-column">
          <div className="chart-container">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius="50%"
                  outerRadius="90%"
                  dataKey="value"
                  labelLine={false}
                  paddingAngle={1}
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip content={renderTooltip} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="chart-legend">
            {chartData.map((segment, index) => (
              <div key={index} className="legend-item">
                <div
                  className="legend-color"
                  style={{ backgroundColor: segment.color }}
                ></div>
                <span className="legend-text">
                  {segment.value.toFixed(2)}% {segment.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

RouteCharacteristicsSection.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default RouteCharacteristicsSection;