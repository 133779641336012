import { useCallback } from 'react';
import axios from 'axios';

// Constantes para endpoints
const API_BASE_URL =  process.env.REACT_APP_API_URL;
//const API_BASE_URL = process.env.REACT_APP_API_URL_PRODUCTION;

export const useDocumentData = () => {
  // Obtener documento por ID
  const fetchDocument = useCallback(async (documentId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/transport/${documentId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching document:', error);
      return null;
    }
  }, []);

  // Crear nuevo documento
  const createDocument = useCallback(async (documentId, forceReset = false) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/transport`, {
        documentId,
        forceReset,
      });
      return response.data.data;
    } catch (error) {
      console.error('Error creating document:', error);
      throw error;
    }
  }, []);

  // Guardar cambios en el documento
  const saveDocument = useCallback(async (documentId, data, section = null) => {
    try {
      const endpoint = section 
        ? `${API_BASE_URL}/transport/${documentId}?section=${section}`
        : `${API_BASE_URL}/transport/${documentId}`;
        
      const response = await axios.put(endpoint, data);
      return response.data.data;
    } catch (error) {
      console.error('Error saving document:', error);
      throw error;
    }
  }, []);

  // Calcular costos
  const calculateCosts = useCallback(async (documentId, basicData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/transport/${documentId}/calculate`,
        basicData
      );
      return response.data.data;
    } catch (error) {
      console.error('Error calculating costs:', error);
      throw error;
    }
  }, []);

  // Generar PDF del documento
  const generatePdf = useCallback(async (documentId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/transport/${documentId}/pdf`);
      return response.data.data.pdfUrl;
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  }, []);

  // Compartir documento
  const shareDocument = useCallback(async (documentId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/transport/${documentId}/share`);
      return response.data.data.shareUrl;
    } catch (error) {
      console.error('Error sharing document:', error);
      throw error;
    }
  }, []);

  return {
    fetchDocument,
    createDocument,
    saveDocument,
    calculateCosts,
    generatePdf,
    shareDocument,
  };
};