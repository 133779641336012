import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Country, State, City } from "country-state-city";
import "../assets/scss/components/ui/dropdawn/LocationSelector.scss";

export default function LocationSelector({
  onLocationSelect,
  locationData = null,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [addressDetails, setAddressDetails] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locationStatus, setLocationStatus] = useState("");

  // Se obtienen todos los países
  const countries = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  // Estados/Departamentos según el país seleccionado
  const departments = selectedCountry
    ? State.getStatesOfCountry(selectedCountry.value).map((dept) => ({
        value: dept.isoCode,
        label: dept.name,
      }))
    : [];

  // Ciudades según el Estado/Departamento seleccionado
  const cities =
    selectedCountry && selectedDepartment
      ? City.getCitiesOfState(
          selectedCountry.value,
          selectedDepartment.value
        ).map((city) => ({
          value: city.name,
          label: city.name,
        }))
      : [];

  useEffect(() => {
    // Solo actualizar si hay locationData y no estamos en modo edición activa
    if (locationData && !isEditing) {
      try {
        // Función auxiliar para encontrar coincidencia exacta o parcial
        const findMatch = (array, searchTerm) => {
          const exactMatch = array.find(item => item.label.toLowerCase() === searchTerm?.label?.toLowerCase());
          if (exactMatch) return exactMatch;
          
          const partialMatch = array.find(item => 
            item.label.toLowerCase().includes(searchTerm?.label?.toLowerCase())
          );
          return partialMatch || null;
        };
  
        // Buscar y establecer país
        const country = findMatch(countries, locationData.country);
        if (!country) throw new Error('País no encontrado');
        setSelectedCountry(country);
  
        // Obtener y mapear departamentos del país seleccionado
        const availableDepartments = State.getStatesOfCountry(country.value).map(dept => ({
          value: dept.isoCode,
          label: dept.name,
        }));
  
        // Buscar y establecer departamento
        const department = findMatch(availableDepartments, locationData.department);
        if (!department) throw new Error('Departamento no encontrado');
        setSelectedDepartment(department);
  
        // Obtener y mapear ciudades del departamento seleccionado
        const availableCities = City.getCitiesOfState(
          country.value,
          department.value
        ).map(city => ({
          value: city.name,
          label: city.name,
        }));
  
        // Buscar y establecer ciudad
        const city = findMatch(availableCities, locationData.city);
        if (!city) throw new Error('Ciudad no encontrada');
        setSelectedCity(city);
  
        // Establecer detalles adicionales
        setAddressDetails(locationData.addressDetails?.trim() || '');
        setPostalCode(locationData.postalCode?.trim() || '');
        
        // Marcar como cargado exitosamente
        setLocationStatus('uploaded');
        setIsEditing(true);
      } catch (error) {
        console.warn('Error al cargar ubicación:', error.message);
        // Restablecer estados en caso de error
        setSelectedCountry(null);
        setSelectedDepartment(null);
        setSelectedCity(null);
        setAddressDetails('');
        setPostalCode('');
        setLocationStatus('not-uploaded');
      }
    }
  }, [locationData, countries, isEditing]);

  // Manejo de cambio de país
  const handleCountryChange = (e) => {
    const country = countries.find((c) => c.value === e.target.value);
    setSelectedCountry(country || null);
    setSelectedDepartment(null);
    setSelectedCity(null);
  };

  // Manejo de cambio de departamento
  const handleDepartmentChange = (e) => {
    const department = departments.find((d) => d.value === e.target.value);
    setSelectedDepartment(department || null);
    setSelectedCity(null);
  };

  // Manejo de cambio de ciudad
  const handleCityChange = (e) => {
    const city = cities.find((c) => c.value === e.target.value);
    setSelectedCity(city || null);
  };

  // Función para truncar la dirección (solo para mostrar en el status)
  const truncateAddress = (address) => {
    return address.length > 15 ? `${address.substring(0, 15)}...` : address;
  };

  // Renderiza el estado de la ubicación abajo del botón
  const renderLocationStatus = () => {
    if (!locationStatus) return null;

    if (locationStatus === "uploaded") {
      const uploadedAddress = truncateAddress(addressDetails);
      return (
        <p className="status-message status-uploaded">
          Dirección subida: {uploadedAddress}
        </p>
      );
    }

    if (locationStatus === "not-uploaded") {
      return (
        <p className="status-message status-error">
          No se ha subido la dirección
        </p>
      );
    }
  };

  // Manejo de envío de ubicación
  const handleSubmit = () => {
    const dataToSend = {
      country: selectedCountry,
      department: selectedDepartment,
      city: selectedCity,
      addressDetails,
      postalCode,
    };

    if (selectedCountry && selectedDepartment && selectedCity) {
      onLocationSelect(dataToSend);
      setLocationStatus("uploaded");
      setIsEditing(false); 
    } else {
      console.warn("Por favor complete todos los campos de ubicación");
      setLocationStatus("not-uploaded");
    }
  };

  return (
    <div className="location-selector">
      {/* Fila 1: País, Departamento/Estado, Ciudad */}
      <div className="flex-row row-1">
        {/* País */}
        <div className="field-group">
          <label htmlFor="country">País</label>
          <select
            id="country"
            value={selectedCountry?.value || ""}
            onChange={handleCountryChange}
          >
            <option value="">-- Seleccione un país --</option>
            {countries.map((country) => (
              <option
                key={country.value}
                value={country.value}
                selected={country.label === locationData?.country?.label}
              >
                {country.label}
              </option>
            ))}
          </select>
        </div>

        {/* Departamento / Estado */}
        {selectedCountry && (
          <div className="field-group">
            <label htmlFor="department">Departamento / Estado</label>
            <select
              id="department"
              value={selectedDepartment?.value || ""}
              onChange={handleDepartmentChange}
            >
              <option value="">-- Seleccione un departamento/estado --</option>
              {departments.map((dept) => (
                <option key={dept.value} value={dept.value}>
                  {dept.label}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Ciudad */}
        {selectedDepartment && (
          <div className="field-group">
            <label htmlFor="city">Ciudad</label>
            <select
              id="city"
              value={selectedCity?.value || ""}
              onChange={handleCityChange}
            >
              <option value="">-- Seleccione una ciudad --</option>
              {cities.map((city) => (
                <option key={city.value} value={city.value}>
                  {city.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {/* Fila 2: Dirección, Código Postal */}
      <div className="flex-row row-2">
        {/* Dirección (campo grande) */}
        <div className="field-group field-address">
          <label htmlFor="addressDetails">Dirección</label>
          <textarea
            id="addressDetails"
            value={addressDetails}
            onChange={(e) => setAddressDetails(e.target.value)}
            rows="2"
          />
        </div>

        {/* Código Postal (campo más pequeño) */}
        <div className="field-group field-postal">
          <label htmlFor="postalCode">Código Postal</label>
          <input
            id="postalCode"
            type="text"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div>
      </div>

      {/* Fila 3: Botón Guardar Ubicación + Estado */}
      <div className="flex-row row-3">
        <button
          className="save-button"
          onClick={handleSubmit}
          disabled={!selectedCountry || !selectedDepartment || !selectedCity}
        >
          Guardar Ubicación
        </button>
        {renderLocationStatus()}
      </div>
    </div>
  );
}

LocationSelector.propTypes = {
  onLocationSelect: PropTypes.func.isRequired,
  locationData: PropTypes.shape({
    country: PropTypes.object,
    department: PropTypes.object,
    city: PropTypes.object,
    addressDetails: PropTypes.string,
    postalCode: PropTypes.string,
  }),
};
