import { comment } from "postcss";
import { useApi } from "./api";

export const useCommentAndResidenceService = () => {
  const api = useApi();

  return {
    /**
     * Crear un nuevo comentario
     * @param {Object} commentData - Datos del comentario
     * @param {string} commentData.title - Título del comentario
     * @param {string} commentData.comment_text - Contenido del comentario
     * @param {string} commentData.reference_id - ID de referencia
     * @returns {Promise<Object>} Comentario creado
     */
    createComment: async (commentData) => {
      try {
        const response = await api.post("/comments", commentData);
        return response.data;
      } catch (error) {
        console.error("Error creando comentario:", error);
        throw error.response?.data || error.message;
      }
    },

    /**
     * Obtener comentarios por ID de referencia
     * @param {string} referenceId - ID de referencia
     * @returns {Promise<Array>} Lista de comentarios
     */
    getCommentsByReference: async (referenceId) => {
      try {
        const response = await api.get(`/comments/reference/${referenceId}`);
        return response.data;
      } catch (error) {
        console.error("Error obteniendo comentarios:", error);
        throw error.response?.data || error.message;
      }
    },

    /**
     * Actualizar un comentario existente
     * @param {string} commentId - ID del comentario
     * @param {Object} updateData - Datos a actualizar
     * @param {string} updateData.title - Nuevo título
     * @param {string} updateData.comment_text - Nuevo contenido
     * @returns {Promise<Object>} Comentario actualizado
     */
    updateComment: async (commentId, updateData) => {
      try {
        const response = await api.put(`/comments/${commentId}`, updateData);
        return response.data;
      } catch (error) {
        console.error("Error actualizando comentario:", error);
        throw error.response?.data || error.message;
      }
    },

    /**
     * Eliminar un comentario
     * @param {string} commentId - ID del comentario
     * @returns {Promise<Object>} Respuesta de confirmación
     */
    deleteComment: async (commentId) => {
      try {
        const response = await api.delete(`/comments/${commentId.id}`);
        return response.data;
      } catch (error) {
        console.error("Error eliminando comentario:", error);
        throw error.response?.data || error.message;
      }
    },
    createLocation: async (locationData) => {
        try {
          const response = await api.post("/locations", locationData);
          return response.data;
        } catch (error) {
          console.error("Error creando ubicación:", error);
          throw error.response?.data || error.message;
        }
      },
  
      /**
       * Obtener todas las ubicaciones del usuario
       * @returns {Promise<Array>} Lista de ubicaciones
       */
      getUserLocations: async (userId) => {
        try {
          const response = await api.get(`/locations/${userId}`);
          return response.data;
        } catch (error) {
          console.error("Error obteniendo ubicaciones:", error);
          throw error.response?.data || error.message;
        }
      },
      /**
       * Actualizar una ubicación existente
       * @param {string} locationId - ID de la ubicación
       * @param {Object} updateData - Datos a actualizar
       * @returns {Promise<Object>} Ubicación actualizada
       */
      updateLocation: async (locationId, updateData) => {
        try {
          const response = await api.put(`/locations/${locationId}`, updateData);
          return response.data;
        } catch (error) {
          console.error("Error actualizando ubicación:", error);
          throw error.response?.data || error.message;
        }
      },
  
      /**
       * Eliminar una ubicación
       * @param {string} locationId - ID de la ubicación
       * @returns {Promise<Object>} Respuesta de confirmación
       */
      deleteLocation: async (locationId) => {
        try {
          const response = await api.delete(`/locations/${locationId.id}/${locationId.user_id}`);
          return response.data;
        } catch (error) {
          console.error("Error eliminando ubicación:", error);
          throw error.response?.data || error.message;
        }
      },
  
      /**
       * Establecer una ubicación como primaria
       * @param {string} locationId - ID de la ubicación
       * @returns {Promise<Object>} Ubicación actualizada
       */
      setPrimaryLocation: async (locationId) => {
        try {
          const response = await api.patch(`/locations/${locationId}/set-primary`);
          return response.data;
        } catch (error) {
          console.error("Error estableciendo ubicación primaria:", error);
          throw error.response?.data || error.message;
        }
      }
  };
};