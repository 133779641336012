import React, { useState, useEffect } from 'react';
import { 
  Modal, Box, Typography, Button, CircularProgress, 
  List, ListItem, ListItemText, ListItemAvatar, Avatar,
  ListItemSecondaryAction, Checkbox, TextField, InputAdornment,
  IconButton, Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import PersonIcon from '@mui/icons-material/Person';
import { useQueryClient } from '@tanstack/react-query';
import { filterService, userService } from '../../service';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '90vh',
  overflow: 'auto'
};

export default function AssignUsersToFilterModal({
  open,
  onClose,
  onUsersAssigned,
  filterId,
  filterName,
  roleId,
  theme
}) {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();

  // Cargar usuarios cuando se abre el modal
  useEffect(() => {
    if (open && filterId) {
      loadUsers();
    }
  }, [open, filterId]);

  // Filtrar usuarios cuando cambia el término de búsqueda
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredUsers(users);
      return;
    }

    const lowercaseSearch = searchTerm.toLowerCase();
    const filtered = users.filter(user => 
      user.first_name?.toLowerCase().includes(lowercaseSearch) ||
      user.first_last_name?.toLowerCase().includes(lowercaseSearch) ||
      user.second_last_name?.toLowerCase().includes(lowercaseSearch) ||
      user.document_number?.toLowerCase().includes(lowercaseSearch)
    );
    
    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  const loadUsers = async () => {
    setLoading(true);
    try {
      // Cargar todos los usuarios con el rol especificado
      const response = await userService.getUsersByRoleWithoutPagination(roleId);
      
      if (response && response.data) {
        // Marcar los usuarios que ya tienen asignado este filtro
        const usersWithFilterStatus = response.data.map(user => {
          const hasFilter = user.assigned_filters && 
                           user.assigned_filters.some(filter => filter.id === filterId);
          return {
            ...user,
            hasFilter
          };
        });
        
        setUsers(usersWithFilterStatus);
        setFilteredUsers(usersWithFilterStatus);
        
        // Seleccionar automáticamente usuarios que ya tienen el filtro
        setSelectedUsers(
          usersWithFilterStatus
            .filter(user => user.hasFilter)
            .map(user => user.id)
        );
      }
    } catch (error) {
      console.error('Error al cargar usuarios:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleUser = (userId) => {
    setSelectedUsers(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      } else {
        return [...prev, userId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedUsers.length === filteredUsers.length) {
      // Si todos están seleccionados, deseleccionar todos
      setSelectedUsers([]);
    } else {
      // Seleccionar todos los filtrados
      setSelectedUsers(filteredUsers.map(user => user.id));
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      // 1. Identificar qué usuarios tenían el filtro inicialmente
      const initiallyAssigned = users
        .filter(user => user.hasFilter)
        .map(user => user.id);
      
      // 2. Determinar qué usuarios añadir y cuáles quitar
      const toAdd = selectedUsers.filter(id => !initiallyAssigned.includes(id));
      const toRemove = initiallyAssigned.filter(id => !selectedUsers.includes(id));
      
      // 3. Crear operaciones en paralelo
      const operations = [
        ...toAdd.map(userId => filterService.assignFilterToUser(userId, filterId)),
        ...toRemove.map(userId => filterService.removeFilterFromUser(userId, filterId))
      ];
      
      // 4. Ejecutar todas las operaciones
      await Promise.all(operations);
      
      // 5. Invalidar consultas para refrescar datos
      queryClient.invalidateQueries(['usersWithRole']);
      
      // 6. Notificar éxito
      if (onUsersAssigned) {
        onUsersAssigned();
      }
    } catch (error) {
      console.error('Error al guardar asignaciones:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-assign-users-to-filter"
    >
      <Box sx={style} className={`GM__${theme}-modal-container`}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" component="h2" id="modal-assign-users-to-filter">
            Asignar Operadores al Filtro: {filterName}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <FilterListIcon sx={{ mr: 1, color: 'primary.main' }} />
          <Typography variant="body1">
            Seleccione los operadores que desea asociar con este filtro.
          </Typography>
        </Box>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="Buscar operadores..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="body2">
            {selectedUsers.length} operadores seleccionados
          </Typography>
          <Button 
            size="small" 
            onClick={handleSelectAll}
          >
            {selectedUsers.length === filteredUsers.length ? 'Deseleccionar todos' : 'Seleccionar todos'}
          </Button>
        </Box>

        <Divider sx={{ mb: 2 }} />

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <List sx={{ 
            maxHeight: 400, 
            overflow: 'auto', 
            bgcolor: theme === 'dark' ? 'rgba(66, 66, 66, 0.8)' : 'rgba(245, 245, 245, 0.9)',
            borderRadius: 1
          }}>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <ListItem key={user.id} divider>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${user.first_name} ${user.first_last_name} ${user.second_last_name || ''}`}
                    secondary={`Documento: ${user.document_number}`}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleToggleUser(user.id)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText 
                  primary="No se encontraron operadores" 
                  secondary="Intente con otra búsqueda o agregue operadores nuevos"
                />
              </ListItem>
            )}
          </List>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
          <Button variant="outlined" onClick={onClose} disabled={saving}>
            Cancelar
          </Button>
          <Button 
            variant="contained" 
            onClick={handleSave} 
            disabled={saving || loading}
            startIcon={saving ? <CircularProgress size={20} /> : null}
          >
            {saving ? 'Guardando...' : 'Guardar'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}