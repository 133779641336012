import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CustomInputField from "../../components/ui/inputs/CustomInputField";
import CustomDatePicker from "../../components/ui/CustomDatePicker";
import { useDocumentTypes, usePhoneCountryCodes } from "../../hooks/userComplementaryQuery";
import dayjs from "dayjs";
import CalendarIcon from "../../assets/svg/components/calendar.svg";
import CalendarLightIcon from "../../assets/svg/components/calendar_light.svg";
import SortDropdown from '../../components/ui/dropdawn/SortDropdown';

const PersonalInfoEdit = ({
  personalInfo,
  onPersonalInfoChange,
  theme
  
}) => {
  const { data: documentTypes, isLoading: isLoadingDocTypes } = useDocumentTypes();
  const { data: countryCodes, isLoading: isLoadingCountryCodes } = usePhoneCountryCodes();
  
  const documentTypesData = useMemo(() => {
    if (!documentTypes?.data) return [];
    return documentTypes.data.map(type => ({
      value: type.id,
      label: type.name
    }));
  }, [documentTypes]);

  const countryCodesData = useMemo(() => {
    if (!countryCodes?.data) return [];
    return countryCodes.data.map(country => ({
      value: country.id,
      label: country.country_name,
      country_code: country.country_code
    }));
  }, [countryCodes]);

  const handleInputChange = (field, value) => {
    onPersonalInfoChange(field, value);
  };

  const handleDateChange = (field, date) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    onPersonalInfoChange(field, formattedDate);
  };
  const dateIconSrc = theme === 'dark' ? CalendarLightIcon : CalendarIcon;
  return (
    <div className="main-section">
    <div className="left-column">
      <div className="input-group">
        <label htmlFor="firstName">Nombre *</label>
        <CustomInputField
          id="firstName"
          value={personalInfo.firstName}
          onChange={(e) => handleInputChange('firstName', e.target.value)}
          placeholder="Ej. Juan Carlos"
          required
          theme={theme}
        />
      </div>

      <div className="input-group">
        <label htmlFor="firstLastName">Primer Apellido *</label>
        <CustomInputField
          id="firstLastName"
          value={personalInfo.firstLastName}
          onChange={(e) => handleInputChange('firstLastName', e.target.value)}
          placeholder="Ej. Pérez"
          required
          theme={theme}
        />
      </div>

      <div className="input-group">
        <label htmlFor="secondLastName">Segundo Apellido</label>
        <CustomInputField
          id="secondLastName"
          value={personalInfo.secondLastName}
          onChange={(e) => handleInputChange('secondLastName', e.target.value)}
          placeholder="Ej. Martínez"
          theme={theme}
        />
      </div>

      <div className="additional-info">
        <div className="input-group">
          <label htmlFor="birthDate">Fecha de Nacimiento</label>
          <CustomDatePicker
            id="birthDate"
            value={personalInfo.birthDate ? dayjs(personalInfo.birthDate).toDate() : null}
            onChange={(date) => handleDateChange('birthDate', date)}
            icon={<img src={dateIconSrc} alt="calendar" />}
            theme={theme}
          />
        </div>

        <div className="input-group">
          <label htmlFor="nationality">Nacionalidad</label>
          <SortDropdown
            value={personalInfo.nationality_id || ""}
            onChange={(value) => handleInputChange('nationality_id', value)}
            options={countryCodesData}
            type="nationality"
            theme={theme}
            placeholder="Seleccione país"
            isLoading={isLoadingCountryCodes}
          />
        </div>
      </div>
    </div>

    <div className="right-column">
      <div className="input-group">
        <label htmlFor="documentTypeId">Tipo de Documento *</label>
        <SortDropdown
          value={personalInfo.documentTypeId || ""}
          onChange={(value) => handleInputChange('documentTypeId', value)}
          options={documentTypesData}
          isLoading={isLoadingDocTypes}
          placeholder="Seleccione tipo de documento"
          theme={theme}
        />
      </div>

      <div className="input-group">
        <label htmlFor="documentNumber">Documento de identidad *</label>
        <CustomInputField
          id="documentNumber"
          value={personalInfo.documentNumber}
          onChange={(e) => handleInputChange('documentNumber', e.target.value)}
          placeholder="Ej. 12345678"
          required
          theme={theme}
        />
      </div>

      <div className="input-group">
        <label htmlFor="documentExpDate">Fecha de Expedición</label>
        <CustomDatePicker
          id="documentExpDate"
          value={personalInfo.documentExpDate ? dayjs(personalInfo.documentExpDate).toDate() : null}
          onChange={(date) => handleDateChange('documentExpDate', date)}
          icon={<img src={dateIconSrc} alt="calendar" />}
          theme={theme}
        />
      </div>

      <div className="input-group">
        <label htmlFor="documentExpeditionPlace">Lugar de Expedición</label>
        <CustomInputField
          id="documentExpeditionPlace"
          value={personalInfo.documentExpeditionPlace}
          onChange={(e) => handleInputChange('documentExpeditionPlace', e.target.value)}
          placeholder="Ej. Bogotá"
          theme={theme}
        />
      </div>
    </div>
  </div>
  );
};

PersonalInfoEdit.propTypes = {
  personalInfo: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    firstLastName: PropTypes.string.isRequired,
    secondLastName: PropTypes.string,
    documentNumber: PropTypes.string,
    documentExpDate: PropTypes.string,
    documentTypeId: PropTypes.number,
    documentExpeditionPlace: PropTypes.string,
    nationality_id: PropTypes.number,
    birthDate: PropTypes.string
  }).isRequired,
  onPersonalInfoChange: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default PersonalInfoEdit;