import React, { useEffect, useRef, useState } from 'react';
import { Element } from 'react-scroll';
import anime from 'animejs/lib/anime.es.js';
import AOS from 'aos';  // Importación añadida
import 'aos/dist/aos.css'; // Importación añadida
import '../../assets/scss/views/Servicios.scss';
import ImgVehicle from '../../assets/img/carro_especial.jpg';
import Tractomula from '../../assets/img/flota/tractomula.jpg';
import CargaSeca from '../../assets/img/flota/carga_seca.jpg';
import Contingencia from '../../assets/img/flota/contingencia.jpg';

// Datos de servicios estructurados
const serviciosData = [
  {
    id: 'carga-liquida',
    title: 'Carga líquida',
    description: 'Transporte especializado para líquidos, incluyendo combustibles, productos químicos y alimentos líquidos, garantizando seguridad y manipulación adecuada.',
    image: Tractomula,
    vehicles: [
      {
        type: 'VEHÍCULOS TIPO TRACTO CAMIÓN',
        items: [
          'Transporte de hidrocarburos.',
          'Transporte de aceite residual.',
          'Transporte de combustibles (BIO, ACPM).',
          'Transporte de carga seco.'
        ]
      },
      {
        type: 'VEHÍCULOS TIPO SENCILLO',
        items: [
          'Transporte de agua potable.',
          'Cargas de ACPM.',
          'Cargas de agua industrial.'
        ]
      },
      {
        type: 'VEHÍCULOS TIPO DOBLE TROQUE',
        items: [
          'Transporte de agua potable con sistema de filtro.',
          'Transporte de transferencia de crudo.',
          'Transporte de aguas contaminadas.'
        ]
      }
    ]
  },
  {
    id: 'carga-seca',
    title: 'Carga seca',
    description: 'Movilización eficiente de productos sólidos no perecederos, mercancías industriales, maquinaria y mercancía en general, con protección adecuada.',
    image: CargaSeca,
    vehicles: [
      {
        type: 'VEHÍCULOS CARGA SECA',
        items: [
          'Transporte de carga seca con sistema de ganches.',
          'Montacargas.',
          'Cama baja.',
          'Cama alta.',
          'Servicio de Grúa.'
        ]
      }
    ]
  },
  {
    id: 'especial',
    title: 'Especial',
    description: 'Transporte seguro y confiable para el traslado de personal, garantizando comodidad, cumplimiento normativo y soluciones adaptadas a las necesidades de cada cliente.',
    image: ImgVehicle,
    vehicles: [
      {
        type: 'VEHÍCULOS TIPO ESPECIAL',
        items: [
          'Camionetas'
        ]
      }
    ]
  },
  {
    id: 'contingencias',
    title: 'Contingencias',
    description: 'Servicio de respuesta rápida para emergencias y situaciones imprevistas, asegurando la continuidad del suministro y operaciones críticas.',
    image: Contingencia,
    vehicles: [
      {
        type: 'VEHÍCULOS DE CONTINGENCIA',
        items: [
          'Camioneta con kit de contingencia de derrames.',
          'Turbos 8x4-3S'
        ]
      }
    ]
  }
];

const Servicios = () => {
  const dotsRef = useRef(null);
  const sectionRef = useRef(null);
  const [visibleVehicles, setVisibleVehicles] = useState({});
  
  // Función para togglear visibilidad de detalles
  const toggleVehicleDetails = (servicioId) => {
    setVisibleVehicles(prev => ({
      ...prev,
      [servicioId]: !prev[servicioId]
    }));
  };
  
  useEffect(() => {
    // Inicializar AOS - NUEVO
    AOS.init({
      duration: 800,
      once: false,
      mirror: true
    });
    
    // Refrescar AOS cuando el componente se monte completamente - NUEVO
    setTimeout(() => {
      AOS.refresh();
    }, 500);
    
    if (!dotsRef.current) return;
    
    // Limpiar contenedor antes de crear nuevos puntos
    dotsRef.current.innerHTML = '';
    
    // Dimensiones específicas para el área de puntos
    const width = 500;
    const height = 300;
    
    // Establecer dimensiones del contenedor
    dotsRef.current.style.width = `${width}px`;
    dotsRef.current.style.height = `${height}px`;
    dotsRef.current.style.position = 'absolute';
    dotsRef.current.style.top = '150px';
    dotsRef.current.style.left = '0';
    
    // Crear grid de puntos
    const dotSize = 8;
    const spacing = 25;
    const container = dotsRef.current;
    
    // Calcular número de filas y columnas
    const columns = Math.ceil(width / spacing);
    const rows = Math.ceil(height / spacing);
    
    // Crear los puntos
    for (let y = 0; y < rows; y++) {
      for (let x = 0; x < columns; x++) {
        const dot = document.createElement('div');
        dot.classList.add('dot');
        dot.style.width = `${dotSize}px`;
        dot.style.height = `${dotSize}px`;
        dot.style.borderRadius = '50%';
        dot.style.position = 'absolute';
        dot.style.top = `${y * spacing}px`;
        dot.style.left = `${x * spacing}px`;
        dot.style.backgroundColor = '#0dd143';
        dot.style.opacity = '0.15';
        dot.style.transform = 'scale(0.5)';
        
        container.appendChild(dot);
      }
    }
    
    // Animación de puntos con anime.js
    const dots = container.querySelectorAll('.dot');
    
    // Animación inicial
    anime({
      targets: dots,
      scale: [0.5, 1],
      opacity: [0.15, 0.3],
      delay: anime.stagger(10, {grid: [columns, rows], from: 'center'}),
      duration: 1000,
      easing: 'easeInOutQuad',
      complete: () => {
        // Animación continua
        anime({
          targets: dots,
          scale: [1, 0.5],
          opacity: [0.3, 0.15],
          duration: 3000,
          easing: 'easeInOutSine',
          direction: 'alternate',
          loop: true,
          delay: anime.stagger(100, {grid: [columns, rows], from: 'center'})
        });
      }
    });

    // Solución: Implementar manejo para animación de entrada basado en visibilidad
    let hasAnimated = false;

    const handleEntryAnimation = () => {
      if (hasAnimated) return;
      
      const section = document.getElementById('servicios-page');
      if (!section) return;
      
      const rect = section.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight * 0.7 && rect.bottom >= 0;
      
      if (isVisible) {
        hasAnimated = true;
        
        // Animación de entrada tipo onda
        anime({
          targets: dots,
          scale: [0.5, 1.5],
          opacity: [0.15, 0.4],
          delay: anime.stagger(20, {grid: [columns, rows], from: 'center'}),
          duration: 800,
          easing: 'easeOutExpo',
          complete: () => {
            anime({
              targets: dots,
              scale: [1.5, 1],
              opacity: [0.4, 0.3],
              duration: 800,
              easing: 'easeInOutBack',
              complete: () => {
                // Volver a la animación normal
                anime({
                  targets: dots,
                  scale: [1, 0.5],
                  opacity: [0.3, 0.15],
                  duration: 3000,
                  easing: 'easeInOutSine',
                  direction: 'alternate',
                  loop: true,
                  delay: anime.stagger(100, {grid: [columns, rows], from: 'center'})
                });
              }
            });
          }
        });
        
        // Eliminar listener después de la animación
        window.removeEventListener('scroll', handleEntryAnimation);
      }
    };
    
    // Añadir listener de scroll
    window.addEventListener('scroll', handleEntryAnimation);
    
    // Verificar estado inicial (puede que la sección ya sea visible)
    setTimeout(handleEntryAnimation, 500);
    
    // Limpieza al desmontar el componente
    return () => {
      anime.remove(dots);
      window.removeEventListener('scroll', handleEntryAnimation);
    };
  }, []);
  
  const handleCotizar = (servicioId) => {
    console.log(`Cotizando servicio: ${servicioId}`);
    // Implementar lógica de cotización
  };

  return (
    <Element name="servicios-page" className="section servicios-page-section" id="servicios-page" ref={sectionRef}>
      {/* Contenedor de puntos animados - mantener igual */}
      <div className="servicios-dots-container">
        <div className="servicios-dots-background" ref={dotsRef}></div>
      </div>
      
      {/* Segunda área de puntos al final - mantener igual */}
      <div className="servicios-dots-container right-dots">
        <div className="servicios-dots-background right"></div>
      </div>
      
      <div className="servicios-page-container">
        <h2 className="servicios-page-title">
          Nuestros <span className="servicios-highlight">Servicios</span>
        </h2>
        
        <div className="servicios-grid">
          {serviciosData.map((servicio) => (
            <div className="servicio-item" key={servicio.id} data-aos="fade-up">
              <div className="servicio-card">
                <div className="servicio-image">
                  <img src={servicio.image} alt={servicio.title} />
                </div>
                <div className="servicio-info">
                  <h3>{servicio.title}</h3>
                  <p>{servicio.description}</p>
                  
                  {/* Contenedor para botones (desktop y móvil) */}
                  <div className="servicio-actions">
                    <button 
                      className="btn-cotizar"
                      onClick={() => handleCotizar(servicio.id)}
                    >
                      Cotizar
                    </button>
                    
                    {/* Botón para móvil - Mostrar vehículos */}
                    {servicio.vehicles && servicio.vehicles.length > 0 && (
                      <button
                        className="btn-ver-vehiculos"
                        onClick={() => toggleVehicleDetails(servicio.id)}
                      >
                        {visibleVehicles[servicio.id] ? 'Ocultar vehículos' : 'Ver vehículos'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              
              {/* Detalles de vehículos con clase para controlar visibilidad */}
              {servicio.vehicles && servicio.vehicles.length > 0 && (
                <div className={`servicio-details ${visibleVehicles[servicio.id] ? 'visible' : ''}`}>
                  {servicio.vehicles.map((vehicle, idx) => (
                    <div key={idx} className="vehicle-group">
                      <h4>{vehicle.type}</h4>
                      <ul>
                        {vehicle.items.map((item, i) => (
                          <li key={i}>• {item}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Element>
  );
};

export default Servicios;