import React, { useState } from "react";
import { useGetRoleWithUsers } from "../../../hooks/useReactQuery";
import RoleWithUsersCard from "../../../components/ui/cards/RoleWithUsersCard";
import { useNavigate } from "react-router-dom";
import SettingsWhiteIcon from "../../../assets/svg/view/settings_white.svg";
import SettingsIcon from "../../../assets/svg/view/settings.svg";
import "../../../assets/scss/views/MainPersonContent.scss";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { Tooltip } from "@mui/material";
import { useLayout } from "../../../context/LayoutContext";

export default function MainPersonContent() {
  const { theme } = useLayout();
  const navigate = useNavigate();
  const selectedPage = 1;
  const [usersPerPage] = useState(5);

  const {
    data: dataRoleWithUsers,
    isLoading,
    error,
  } = useGetRoleWithUsers(selectedPage, usersPerPage);

  if (isLoading) {
    return <LoadingAnimation/>;
  }

  if (error) {
    return (
      <div className="error">
        Error al cargar los datos: {error.message}
      </div>
    );
  }

  const normalizeText = (text) => {
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const sortedRoleData = dataRoleWithUsers?.data
    .filter((roleData) => roleData.role_info.name !== "Vehículos")
    .sort((a, b) => {
      const roleA = normalizeText(a.role_info.name);
      const roleB = normalizeText(b.role_info.name);
      return roleA.localeCompare(roleB, 'es', { sensitivity: 'base' });
    });

  const handleNavigateToSettings = () => {
    navigate("/persons/setting/data");
  };
  const settingsIconSrc = theme === 'dark' ? SettingsWhiteIcon : SettingsIcon;
  return (
    <div className={`GM__${theme}-person-container`}>
      <header className={`GM__${theme}-person-header`}>
        <div className={`GM__${theme}-header-content`}>
          <div className={`GM__${theme}-settings-container`}>
            <Tooltip title="Configuración de personas, toda la data necesaria de precreación de la persona o usuario" arrow placement="top">
              <button 
                className={`GM__${theme}-settings-container-icon`}
                onClick={handleNavigateToSettings}
                aria-label="Configuración"
                type="button"
              >
                <img 
                  src={settingsIconSrc} 
                  alt="Configuración" 
                  className={`GM__${theme}-settings-icon`}
                />
              </button>
            </Tooltip>
          </div>
        </div>
      </header>
      <div className={`GM__${theme}-person-grid`}>
        {sortedRoleData.map((roleData) => (
          <div key={roleData.role_info.id} className={`GM__${theme}-grid-item`}>
            <RoleWithUsersCard
              role={roleData.role_info.name}
              role_id={roleData.role_info.id}
              users={roleData.users.map((user) => ({
                id: user.id,
                name: `${user.first_name} ${user.first_last_name}`,
                document: user.document_number,
              }))}
              theme={theme}
            />
          </div>
        ))}
      </div>
    </div>
  );
}