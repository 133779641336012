import React, { useState, useEffect, useRef } from "react";
import "../../../assets/scss/views/SettingsVehicles.scss";

// Componentes de tablas existentes
import { VehicleBrandTable } from "../../../components/ui/tables/VehicleBrandTable";
import { OperatorTable } from "../../../components/ui/tables/OperatorTable";
import { OwnerTable } from "../../../components/ui/tables/OwnerTable";
import { VehicleTypeTable } from "../../../components/ui/tables/VehicleTypeTable";

// Nuevos componentes de tablas para documentación vehicular
import { OperationsTable } from "../../../components/ui/tables/OperationsTable";
import { DocumentRequirementsManager } from "../../../components/ui/tables/DocumentRequirementsManager";
import { DocumentVehicleTypesTable } from "../../../components/ui/tables/DocumentVehicleTypesTable";
import { useLayout } from "../../../context/LayoutContext";
import { CourseTable } from "../../../components/ui/tables/CourseTable";
import { CourseRequirementsManager } from "../../../components/ui/tables/CourseRequirementsManager";

export function SettingVehicle() {
  const { theme } = useLayout();
  const [activeTab, setActiveTab] = useState("vehicleTypes");
  const indicatorRef = useRef(null);
  const tabContainerRef = useRef(null);

  // Manejador para cambiar entre pestañas
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  // Efecto para manejar la animación del indicador
  useEffect(() => {
    if (indicatorRef.current && tabContainerRef.current) {
      const activeButton = tabContainerRef.current.querySelector(
        `[data-tab="${activeTab}"]`
      );
      if (activeButton) {
        const buttonRect = activeButton.getBoundingClientRect();
        const containerRect = tabContainerRef.current.getBoundingClientRect();

        // Calcular posición relativa al contenedor
        const left = buttonRect.left - containerRect.left;

        // Actualizar el indicador
        indicatorRef.current.style.transform = `translateX(${left}px)`;
        indicatorRef.current.style.width = `${buttonRect.width}px`;
      }
    }
  }, [activeTab]);

  return (
    <div className={`GM__${theme}-dashboard-vehicle`}>
      <h1>Configuraciones de Vehículos</h1>

      {/* Pestañas de navegación con indicador animado */}
      <div className={`GM__${theme}-settings-tabs`}>
        <div
          className={`GM__${theme}-tab-indicator-container`}
          ref={tabContainerRef}
        >
          <div
            className={`GM__${theme}-tab-indicator`}
            ref={indicatorRef}
          ></div>

          <button
            className={`GM__${theme}-tab-button ${
              activeTab === "vehicleTypes" ? `GM__${theme}-active` : ""
            }`}
            onClick={() => handleTabChange("vehicleTypes")}
            data-tab="vehicleTypes"
          >
            Tipos de Vehículo
          </button>

          <button
            className={`GM__${theme}-tab-button ${
              activeTab === "brands" ? `GM__${theme}-active` : ""
            }`}
            onClick={() => handleTabChange("brands")}
            data-tab="brands"
          >
            Marcas
          </button>

          <button
            className={`GM__${theme}-tab-button ${
              activeTab === "owners" ? `GM__${theme}-active` : ""
            }`}
            onClick={() => handleTabChange("owners")}
            data-tab="owners"
          >
            Propietarios
          </button>

          <button
            className={`GM__${theme}-tab-button ${
              activeTab === "operators" ? `GM__${theme}-active` : ""
            }`}
            onClick={() => handleTabChange("operators")}
            data-tab="operators"
          >
            Operadores
          </button>

          <button
            className={`GM__${theme}-tab-button ${
              activeTab === "operations" ? `GM__${theme}-active` : ""
            }`}
            onClick={() => handleTabChange("operations")}
            data-tab="operations"
          >
            Operaciones
          </button>

          <button
            className={`GM__${theme}-tab-button ${
              activeTab === "documentTypes" ? `GM__${theme}-active` : ""
            }`}
            onClick={() => handleTabChange("documentTypes")}
            data-tab="documentTypes"
          >
            Tipos de Documentos
          </button>

          <button
            className={`GM__${theme}-tab-button ${
              activeTab === "requirements" ? `GM__${theme}-active` : ""
            }`}
            onClick={() => handleTabChange("requirements")}
            data-tab="requirements"
          >
            Requisitos Documentales
          </button>
          <button
            className={`GM__${theme}-tab-button ${
              activeTab === "courses" ? `GM__${theme}-active` : ""
            }`}
            onClick={() => handleTabChange("courses")}
            data-tab="courses"
          >
            Cursos
          </button>

          <button
            className={`GM__${theme}-tab-button ${
              activeTab === "courseRequirements" ? `GM__${theme}-active` : ""
            }`}
            onClick={() => handleTabChange("courseRequirements")}
            data-tab="courseRequirements"
          >
            Requisitos de Cursos
          </button>
        </div>
      </div>

      {/* Contenido de las pestañas */}
      <div className={`GM__${theme}-tab-content`}>
        {activeTab === "vehicleTypes" && <VehicleTypeTable />}
        {activeTab === "brands" && <VehicleBrandTable />}
        {activeTab === "owners" && <OwnerTable />}
        {activeTab === "operators" && <OperatorTable />}
        {activeTab === "operations" && <OperationsTable />}
        {activeTab === "documentTypes" && <DocumentVehicleTypesTable />}
        {activeTab === "requirements" && <DocumentRequirementsManager />}
        {activeTab === "courses" && <CourseTable />}
        {activeTab === "courseRequirements" && <CourseRequirementsManager />}
      </div>
    </div>
  );
}
