import React from "react";
import "../../../assets/scss/components/ui/search/SearchMini.scss";

export default function SearchMini({ placeholder, value, onChange, theme = "light" }) {
  const themeClass = theme === "dark" ? "search-dark" : "search-light";
  
  return (
    <div className={`search-component ${themeClass}`}>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={themeClass}
      />
    </div>
  );
}