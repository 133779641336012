import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { vehicleService } from "../service/vehicleService";
import { mediaService, userService } from "../service";
import { useCallback, useState } from "react";
import { NotificationSeverity, useNotification } from "../context/NotificationContext";
// ============================================================
// 1. VEHICLE 
// ============================================================
export const useVehicles = (page, limit, filter, sortOption) => {
  return useQuery({
    queryKey: ["vehicles", page, limit, filter, sortOption],
    queryFn: async () => {
      const data = await vehicleService.getVehicles(page, limit, filter, sortOption);
      return data;
    },
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
    enabled: !!page, 
  });
};
export const useVehicleDocuments = () => {
  // Estados para filtros y paginación
  const [filters, setFilters] = useState({
    vehicleTypeId: null,
    operationId: null,
    search: '',
    documentFilter: 'all',
    page: 1,
    limit: 10
  });

  // Actualización de filtros
  const updateFilters = useCallback((newFilters) => {
    setFilters(prev => ({
      ...prev,
      ...newFilters,
      // Resetear página si cambian otros filtros
      page: newFilters.hasOwnProperty('page') ? newFilters.page : 1
    }));
  }, []);

  // Consulta principal
  const query = useQuery({
    queryKey: ["vehiclesWithDocuments", filters],
    queryFn: () => vehicleService.getVehiclesWithDocuments(filters),
    keepPreviousData: true,
    staleTime: 2 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  // Funciones de paginación
  const goToNextPage = useCallback(() => {
    if (query.data?.pagination?.currentPage < query.data?.pagination?.lastPage) {
      updateFilters({ page: filters.page + 1 });
    }
  }, [filters.page, query.data?.pagination, updateFilters]);

  const goToPrevPage = useCallback(() => {
    if (filters.page > 1) {
      updateFilters({ page: filters.page - 1 });
    }
  }, [filters.page, updateFilters]);

  const goToPage = useCallback((pageNumber) => {
    updateFilters({ page: pageNumber });
  }, [updateFilters]);

  return {
    vehicles: query.data?.data || [],
    pagination: query.data?.pagination || {
      total: 0,
      perPage: filters.limit,
      currentPage: filters.page,
      lastPage: 1,
      from: 0,
      to: 0
    },
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    filters,
    updateFilters,
    goToNextPage,
    goToPrevPage,
    goToPage,
    setResultsPerPage: (newLimit) => updateFilters({ limit: newLimit, page: 1 }),
    refreshVehicles: query.refetch
  };
};

/**
 * Para crear o editar un vehículo
 * - Si recibe un id, es edición.
 * - Si no recibe id, es creación.
 */
export const useSaveVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }) => {
      if (id) {
        // Llamada al servicio para editar
        return await vehicleService.updateVehicle(id, data);
      } else {
        // Llamada al servicio para crear
        return await vehicleService.createVehicle(data);
      }
    },
    onSuccess: () => {
      // Invalida o refresca la cache de vehículos
      queryClient.invalidateQueries(["vehicles"]);
    },
  });
};
export const useVehicleProfile = (id) => {
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  
  // Estado para gestión de documentos seleccionados
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  
  // Consulta principal: perfil completo del vehículo
  const vehicleQuery = useQuery({
    queryKey: ["vehicleProfile", id],
    queryFn: () => vehicleService.getVehicleCompleteProfile(id),
    enabled: !!id,
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  
  // Mutación: Subir documento
  const uploadDocumentMutation = useMutation({
    mutationFn: (docData) => {
      const enrichedData = { ...docData, vehicleId: id };
      return vehicleService.uploadVehicleDocument(enrichedData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["vehicleProfile", id]);
      showNotification({
        message: "Documento cargado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    },
    onError: (error) => {
      showNotification({
        message: error.message || "Error al cargar documento",
        severity: NotificationSeverity.ERROR,
      });
    }
  });
  
  // Mutación: Actualizar documento
  const updateDocumentMutation = useMutation({
    mutationFn: ({ docId, updateData }) => {
      const enrichedData = { ...updateData, vehicleId: id };
      return vehicleService.updateVehicleDocument(docId, enrichedData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["vehicleProfile", id]);
      showNotification({
        message: "Documento actualizado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    },
    onError: (error) => {
      showNotification({
        message: error.message || "Error al actualizar documento",
        severity: NotificationSeverity.ERROR,
      });
    }
  });
  
  // Mutación: Eliminar documento
  const deleteDocumentMutation = useMutation({
    mutationFn: (docId) => vehicleService.deleteVehicleDocument(docId),
    onSuccess: () => {
      queryClient.invalidateQueries(["vehicleProfile", id]);
      showNotification({
        message: "Documento eliminado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    },
    onError: (error) => {
      showNotification({
        message: error.message || "Error al eliminar documento",
        severity: NotificationSeverity.ERROR,
      });
    }
  });
  
  // Funciones públicas del hook
  const uploadDocument = useCallback((docData) => {
    return uploadDocumentMutation.mutateAsync(docData);
  }, [uploadDocumentMutation]);
  
  const updateDocument = useCallback((docId, updateData) => {
    return updateDocumentMutation.mutateAsync({ docId, updateData });
  }, [updateDocumentMutation]);
  
  const deleteDocument = useCallback((docId) => {
    return deleteDocumentMutation.mutateAsync(docId);
  }, [deleteDocumentMutation]);
  
  const selectDocument = useCallback((docId) => {
    setSelectedDocumentId(docId);
  }, []);
  
  const downloadDocument = useCallback((docId) => {
    return vehicleService.downloadVehicleDocument(docId);
  }, []);
  
  const downloadMultipleDocuments = useCallback((docIds) => {
    return vehicleService.downloadMultipleVehicleDocuments(docIds);
  }, []);
  
  return {
    // Datos principales
    vehicleData: vehicleQuery.data?.data,
    isLoading: vehicleQuery.isLoading,
    isError: vehicleQuery.isError,
    error: vehicleQuery.error,
    refetch: vehicleQuery.refetch,
    
    // Estado de operaciones
    isUploading: uploadDocumentMutation.isLoading,
    isUpdating: updateDocumentMutation.isLoading,
    isDeleting: deleteDocumentMutation.isLoading,
    
    // Funciones para documentos
    uploadDocument,
    updateDocument,
    deleteDocument,
    downloadDocument,
    downloadMultipleDocuments,
    
    // Gestión documento seleccionado
    selectedDocumentId,
    selectDocument,
  };
};

/**
 * "Eliminar" lógicamente un vehículo (poner active en false, por ejemplo).
 */
export const useDeleteVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      return await vehicleService.deleteVehicle(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["vehicles"]);
    },
  });
};

// ============================================================
// 1. VEHICLE TYPES
// ============================================================
export const useVehicleTypes = () => {
  return useQuery({
    queryKey: ["vehicleTypes"],
    queryFn: async () => {
      return await vehicleService.getVehicleTypes();
    },
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
  });
};

/** Crear o editar un tipo de vehículo */
export const useSaveVehicleType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }) => {
      console.log("handleSave() llamado", data);
      if (id) {
        // Editar
        return await vehicleService.updateVehicleType(id, data);
      } else {
        // Crear
        return await vehicleService.createVehicleType(data);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["vehicleTypes"]);
    },
  });
};

/** Eliminar (borrado lógico) un tipo de vehículo */
export const useDeleteVehicleType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      return await vehicleService.deleteVehicleType(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["vehicleTypes"]);
    },
  });
};

// ============================================================
// 2. VEHICLE BRANDS
// ============================================================
export const useVehicleBrands = () => {
  return useQuery({
    queryKey: ["vehicleBrands"],
    queryFn: async () => {
      return await vehicleService.getVehicleBrands();
    },
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
  });
};

/** Crear o editar una marca de vehículo */
export const useSaveVehicleBrand = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }) => {
      if (id) {
        // Editar
        return await vehicleService.updateVehicleBrand(id, data);
      } else {
        // Crear
        return await vehicleService.createVehicleBrand(data);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["vehicleBrands"]);
    },
  });
};

/** Eliminar (borrado lógico) una marca de vehículo */
export const useDeleteVehicleBrand = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      return await vehicleService.deleteVehicleBrand(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["vehicleBrands"]);
    },
  });
};
// ============================================================
// 2. Operadores
// ============================================================
export const useUsersByRole = (roleId, page, limit, search) => {
  return useQuery({
    queryKey: ["usersByRole", roleId, page, limit, search],
    queryFn: () => userService.getUsersByRole(roleId, page, limit, search),
    enabled: !!roleId,
    keepPreviousData: true,
    staleTime: 3 * 60 * 1000,
  });
};

// ============================================================
// 1. VEHICLE Owner (el servicio para consultar a los usuarios esta al inicio de operadoes)
// ============================================================


export const useSaveOwner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }) => {
      if (id) {
        return await vehicleService.updateOwner(id, data);
      } else {
        return await vehicleService.createOwner(data);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["owners"]);
    },
  });
};

export const useDeleteOwner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      return await vehicleService.deleteOwner(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["owners"]);
    },
  });
};

// ============================================================
// GET PLATE (OR DATA VEHICLE INCLUDE PLATE)  WITH OPERATOR and GET OPERATOR WITH PLATE
// ============================================================
export const usePlateWithVehicle = (id) => {
  return useQuery({
    queryKey: ["plateWithVehicle", id],
    queryFn: async () => {
      const data = await vehicleService.getPlateWithVehicle(id);
      return data;
    },
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
    enabled: !!id, 
  });
};
export const useVehicleWithPlate = (id) => {
  return useQuery({
    queryKey: ["vehicleWithPlate", id],
    queryFn: async () => {
      const data = await vehicleService.getVehicleWithPlate(id);
      return data;
    },
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
    enabled: !!id, 
  });
};
// ============================================================
// COURSES
// ============================================================
export const useCourses = () => {
  return useQuery({
    queryKey: ["courses"],
    queryFn: async () => {
      return await vehicleService.getCourses();
    },
    staleTime: 3 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    retry: 2,
  });
};

/** Crear o editar un curso */
export const useSaveCourse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }) => {
      if (id) {
        // Editar
        return await vehicleService.updateCourse(id, data);
      } else {
        // Crear
        return await vehicleService.createCourse(data);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["courses"]);
    },
  });
};

/** Eliminar (borrado lógico) un curso */
export const useDeleteCourse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      return await vehicleService.deleteCourse(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["courses"]);
    },
  });
};

/** Obtener cursos por tipo de vehículo */
export const useCoursesByVehicleType = (vehicleTypeId) => {
  return useQuery({
    queryKey: ["coursesByVehicleType", vehicleTypeId],
    queryFn: async () => {
      if (!vehicleTypeId) return { data: [] };
      return await vehicleService.getCoursesByVehicleType(vehicleTypeId);
    },
    enabled: !!vehicleTypeId,
  });
};

/** Asociar un curso a un tipo de vehículo */
export const useAssociateCourseToVehicleType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ vehicleTypeId, courseId, isOptional }) => {
      return await vehicleService.associateCourseToVehicleType(
        vehicleTypeId,
        courseId,
        isOptional
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["coursesByVehicleType", variables.vehicleTypeId]);
    },
  });
};