import axios from 'axios';

// Caché para optimizar rendimiento
const elevationCache = new Map();
const roadTypeCache = new Map();

// Función para generar clave de caché
const getCacheKey = (origin, destination) => {
  return `${origin.lat},${origin.lng}|${destination.lat},${destination.lng}`;
};

/**
 * Obtiene y procesa datos completos de elevación y características de ruta
 */
export const analyzeRouteTopography = async (origin, destination, google) => {
  if (!google || !google.maps) {
    throw new Error('Google Maps API no disponible');
  }
  
  // Verificar caché
  const cacheKey = getCacheKey(origin, destination);
  if (elevationCache.has(cacheKey)) {
    return elevationCache.get(cacheKey);
  }
  
  try {
    // 1. Obtener ruta detallada usando Directions Service
    const route = await getDirectionsRoute(origin, destination, google);
    const routePath = extractRoutePath(route);
    const totalDistance = route.legs.reduce((total, leg) => total + leg.distance.value, 0) / 1000; // km
    
    // 2. Muestrear puntos para análisis (máximo 300 para no sobrecargar APIs)
    const sampledPoints = sampleRoutePoints(routePath, 300);
    
    // 3. Obtener datos de elevación para los puntos muestreados
    const elevationData = await getElevationData(sampledPoints, google);
    
    // 4. Obtener información sobre tipo de vía (urbana, sin pavimentar)
    const roadTypeInfo = await getRoadTypeData(sampledPoints);
    
    // 5. Analizar y clasificar segmentos
    const segments = await classifyRouteSegments(
      elevationData, 
      roadTypeInfo, 
      totalDistance,
      google
    );
    
    // 6. Calcular distribución porcentual
    const segmentDistribution = calculateDistribution(segments, totalDistance);
    
    const result = {
      path: routePath,
      totalDistance,
      segments: segmentDistribution,
      originalRoute: route
    };
    
    // Guardar en caché
    elevationCache.set(cacheKey, result);
    
    return result;
  } catch (error) {
    console.error('Error al analizar topografía:', error);
    throw new Error('No se pudo completar el análisis topográfico de la ruta');
  }
};

/**
 * Obtiene la ruta detallada usando Google Directions Service
 */
const getDirectionsRoute = async (origin, destination, google) => {
  // Verificar si DirectionsService está disponible
  if (!google || !google.maps || typeof google.maps.DirectionsService !== 'function') {
    throw new Error('Google Maps DirectionsService no está disponible');
  }
  
  try {
    const directionsService = new google.maps.DirectionsService();
    
    return new Promise((resolve, reject) => {
      directionsService.route(
        {
          origin,
          destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            resolve(result.routes[0]);
          } else {
            reject(new Error(`Error obteniendo ruta: ${status}`));
          }
        }
      );
    });
  } catch (error) {
    console.error("Error con DirectionsService:", error);
    // Fallback: construir una ruta simple directa
    return {
      overview_path: [
        { lat: () => origin.lat, lng: () => origin.lng },
        { lat: () => destination.lat, lng: () => destination.lng }
      ],
      legs: [{
        distance: { value: calculateDistance(origin.lat, origin.lng, destination.lat, destination.lng) },
        duration: { value: calculateDistance(origin.lat, origin.lng, destination.lat, destination.lng) / 60000 } // Estimación
      }]
    };
  }
};
/**
 * Extrae puntos de coordenadas de la ruta
 */
const extractRoutePath = (route) => {
  const path = [];
  
  // Extraer todos los puntos de la ruta
  route.overview_path.forEach(point => {
    path.push({
      lat: point.lat(),
      lng: point.lng()
    });
  });
  
  return path;
};

/**
 * Reduce el número de puntos para análisis
 */
const sampleRoutePoints = (points, maxSamples) => {
  if (points.length <= maxSamples) return points;
  
  const interval = Math.floor(points.length / maxSamples);
  const sampledPoints = [];
  
  for (let i = 0; i < points.length; i += interval) {
    sampledPoints.push(points[i]);
  }
  
  // Asegurar que se incluya el último punto
  if (sampledPoints[sampledPoints.length - 1] !== points[points.length - 1]) {
    sampledPoints.push(points[points.length - 1]);
  }
  
  return sampledPoints;
};

/**
 * Obtiene datos de elevación usando Google Elevation API
 */
const getElevationData = async (points, google) => {
  try {
    const elevationService = new google.maps.ElevationService();
    
    // Procesar en lotes para evitar límites de API
    const batchSize = 300; // Máximo recomendado por Google
    const batches = [];
    
    for (let i = 0; i < points.length; i += batchSize) {
      batches.push(points.slice(i, i + batchSize));
    }
    
    const batchPromises = batches.map(batch => {
      return new Promise((resolve, reject) => {
        elevationService.getElevationForLocations(
          { locations: batch },
          (results, status) => {
            if (status === google.maps.ElevationStatus.OK) {
              resolve(results);
            } else {
              // Fallar silenciosamente con array vacío
              console.warn(`Error en elevación: ${status}`);
              resolve([]);
            }
          }
        );
      });
    });
    
    const batchResults = await Promise.all(batchPromises);
    const elevationResults = batchResults.flat();
    
    // Combinar resultados con posiciones originales
    return points.map((point, index) => ({
      position: point,
      elevation: elevationResults[index]?.elevation || 0
    }));
  } catch (error) {
    console.error('Error obteniendo elevación:', error);
    // Devolver datos de elevación vacíos pero no fallar completamente
    return points.map(point => ({
      position: point,
      elevation: 0
    }));
  }
};

/**
 * Obtiene información sobre tipo de vías usando OpenStreetMap API
 */
const getRoadTypeData = async (points) => {
  try {
    // Reducir puntos para minimizar carga en API
    const strategicPoints = sampleRoutePoints(points, 5); // Reducido de 15 a 5
    
    // Cola de promesas con control de tasa
    const roadInfoPromises = [];
    const MAX_CONCURRENT = 1; // Solo una petición a la vez
    const DELAY_BETWEEN_REQUESTS = 1500; // 1.5 segundos entre peticiones
    
    // Procesar puntos en serie con delay
    for (let i = 0; i < strategicPoints.length; i++) {
      const point = strategicPoints[i];
      
      // Verificar caché
      const pointKey = `${point.lat},${point.lng}`;
      if (roadTypeCache.has(pointKey)) {
        roadInfoPromises.push(Promise.resolve(roadTypeCache.get(pointKey)));
        continue;
      }
      
      // Esperar antes de la próxima petición
      if (i > 0) {
        await new Promise(resolve => setTimeout(resolve, DELAY_BETWEEN_REQUESTS));
      }
      
      // Añadir promesa con manejo de errores mejorado
      roadInfoPromises.push(
        (async () => {
          try {
            // Consulta a Overpass API con timeout
            const query = `[out:json];way(around:50,${point.lat},${point.lng})[highway];out body;`;
            
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 segundos timeout
            
            const response = await axios.post(
              'https://overpass-api.de/api/interpreter',
              query,
              { 
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                signal: controller.signal 
              }
            );
            
            clearTimeout(timeoutId);
            
            let isUrban = false;
            let isUnpaved = false;
            
            if (response.data?.elements?.length > 0) {
              // Analizar etiquetas para determinar tipo de vía
              response.data.elements.forEach(element => {
                const tags = element.tags || {};
                
                // Detectar zonas urbanas
                if (['residential', 'living_street', 'pedestrian'].includes(tags.highway)) {
                  isUrban = true;
                }
                
                // Detectar vías sin pavimentar
                if (['unpaved', 'dirt', 'gravel'].includes(tags.surface) || 
                    tags.highway === 'track' || tags.tracktype) {
                  isUnpaved = true;
                }
              });
            }
            
            const result = { point, isUrban, isUnpaved };
            roadTypeCache.set(pointKey, result);
            return result;
          } catch (error) {
            console.warn('Error consultando tipo de vía:', error);
            // Fallback sintético basado en patrón común
            const isFallback = true;
            const isUrban = Math.random() < 0.15; // 15% urbano
            const isUnpaved = Math.random() < 0.1; // 10% sin pavimentar
            
            const result = { point, isUrban, isUnpaved, isFallback };
            roadTypeCache.set(pointKey, result);
            return result;
          }
        })()
      );
    }
    
    return Promise.all(roadInfoPromises);
  } catch (error) {
    console.error('Error en análisis de tipo de vía:', error);
    // Fallback con valores sintéticos
    return points.slice(0, 5).map(point => ({ 
      point, 
      isUrban: Math.random() < 0.15,
      isUnpaved: Math.random() < 0.1,
      isFallback: true
    }));
  }
};
/**
 * Clasifica segmentos según elevación y tipo de vía
 */
const classifyRouteSegments = async (elevationData, roadTypeInfo, totalDistance, google) => {
  if (!elevationData || elevationData.length < 2) {
    return [];
  }
  
  const segments = [];
  let currentType = null;
  let currentDistance = 0;
  let segmentStartPoint = null;
  
  // Crear un mapa de índices para consulta rápida de info de vías
  const roadInfoMap = new Map();
  roadTypeInfo.forEach(info => {
    // Encontrar el punto más cercano en los datos de elevación
    let closestPointIndex = 0;
    let minDistance = Infinity;
    
    elevationData.forEach((elPoint, index) => {
      const dist = calculateDistance(
        elPoint.position.lat, elPoint.position.lng,
        info.point.lat, info.point.lng
      );
      
      if (dist < minDistance) {
        minDistance = dist;
        closestPointIndex = index;
      }
    });
    
    roadInfoMap.set(closestPointIndex, info);
  });
  
  // Analizar cada segmento
  for (let i = 0; i < elevationData.length - 1; i++) {
    const p1 = elevationData[i];
    const p2 = elevationData[i + 1];
    
    // Calcular distancia del segmento
    const segmentDistance = calculateDistanceBetweenPoints(
      p1.position.lat, p1.position.lng,
      p2.position.lat, p2.position.lng,
      google
    ) / 1000; // Convertir a km
    
    // Calcular pendiente
    const elevationChange = p2.elevation - p1.elevation;
    const grade = (elevationChange / (segmentDistance * 1000)) * 100;
    
    // Determinar tipo de segmento basado en pendiente
    let segmentType = categorizeByGrade(grade);
    
    // Verificar si es zona urbana o sin pavimentar según OpenStreetMap
    const roadInfo = roadInfoMap.get(i);
    if (roadInfo) {
      if (roadInfo.isUrban) {
        segmentType = 'Zona urbana';
      } else if (roadInfo.isUnpaved) {
        segmentType = 'Zona despavimentada';
      }
    }
    
    // Si es el mismo tipo que el segmento actual, acumular
    if (segmentType === currentType) {
      currentDistance += segmentDistance;
    } else {
      // Si cambia el tipo, guardar el segmento anterior y empezar uno nuevo
      if (currentType !== null) {
        segments.push({
          type: currentType,
          distance: currentDistance,
          startPoint: segmentStartPoint,
          endPoint: p1.position
        });
      }
      
      currentType = segmentType;
      currentDistance = segmentDistance;
      segmentStartPoint = p1.position;
    }
  }
  
  // Agregar el último segmento
  if (currentType !== null) {
    segments.push({
      type: currentType,
      distance: currentDistance,
      startPoint: segmentStartPoint,
      endPoint: elevationData[elevationData.length - 1].position
    });
  }
  
  return segments;
};

/**
 * Calcula distribución porcentual de los segmentos
 */
const calculateDistribution = (segments, totalDistance) => {
  // Agrupar segmentos por tipo
  const groupedSegments = {};
  
  segments.forEach(segment => {
    if (!groupedSegments[segment.type]) {
      groupedSegments[segment.type] = {
        type: segment.type,
        distance: 0,
        percentage: 0
      };
    }
    
    groupedSegments[segment.type].distance += segment.distance;
  });
  
  // Calcular porcentajes
  Object.values(groupedSegments).forEach(segment => {
    segment.percentage = (segment.distance / totalDistance) * 100;
  });
  
  // Asegurar que todos los tipos posibles estén presentes
  const allTypes = [
    'Plano',
    'Pendiente baja positiva',
    'Pendiente baja negativa',
    'Pendiente media positiva',
    'Pendiente media negativa',
    'Pendiente alta positiva',
    'Pendiente alta negativa',
    'Zona urbana',
    'Zona despavimentada'
  ];
  
  const result = allTypes.map(type => {
    return groupedSegments[type] || { type, distance: 0, percentage: 0 };
  });
  
  return result;
};

/**
 * Categoriza segmentos según su pendiente
 */
const categorizeByGrade = (grade) => {
  const absGrade = Math.abs(grade);
  
  if (absGrade < 2) {
    return 'Plano';
  } else if (absGrade < 5) {
    return grade > 0 ? 'Pendiente baja positiva' : 'Pendiente baja negativa';
  } else if (absGrade < 10) {
    return grade > 0 ? 'Pendiente media positiva' : 'Pendiente media negativa';
  } else {
    return grade > 0 ? 'Pendiente alta positiva' : 'Pendiente alta negativa';
  }
};

/**
 * Calcula distancia entre dos puntos geográficos
 */
const calculateDistanceBetweenPoints = (lat1, lng1, lat2, lng2, google) => {
  if (google && google.maps && google.maps.geometry) {
    const p1 = new google.maps.LatLng(lat1, lng1);
    const p2 = new google.maps.LatLng(lat2, lng2);
    return google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
  }
  
  // Fallback si no está disponible geometry library
  return calculateDistance(lat1, lng1, lat2, lng2);
};

/**
 * Implementación de Haversine para cálculo de distancia entre coordenadas
 */
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // Radio de la Tierra en metros
  const φ1 = lat1 * Math.PI / 180;
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2 - lat1) * Math.PI / 180;
  const Δλ = (lon2 - lon1) * Math.PI / 180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c; // Distancia en metros
};