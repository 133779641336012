
import PropTypes from 'prop-types';
import Sidebar from '../components/layout/Sidebar';
import "./mainlayout.scss";
import { useLayout } from '../context/LayoutContext';
import Header from './Header';
const MainLayout = ({ children }) => {
  const { theme, isNavExpanded } = useLayout();
  return (
    <div className={`GM__${theme}-container${isNavExpanded? "-expanded": ""}`}>
      <Sidebar />
      <div className={`GM__${theme}-content${isNavExpanded? "-expanded": ""}`}>
        <div className={`GM__${theme}-main`}>
        <Header />
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
  };