import { DocumentProvider } from '../../context/DocumentContext';
import DocumentLayout from '../../layout/DocumentLayout';
import TransportCostCalculator from './TransportCostCalculator';

const TransportCalculatorContainer = () => {
  return (
    <DocumentProvider>
      <DocumentLayout>
        <TransportCostCalculator />
      </DocumentLayout>
    </DocumentProvider>
  );
};

export default TransportCalculatorContainer;