import React, { useState, useMemo } from "react";
import { useVehicleDocuments, useVehicleTypes } from "../../hooks/useVehicleQuery";
import { useOperations } from "../../hooks/useDocumentationVehicleQuery";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useLayout } from "../../context/LayoutContext";

// Función para calcular el estado de un documento
const calculateDocumentStatus = (expirationDate, required) => {
  if (required === false) return "not-required";
  if (!expirationDate) return "empty";

  const today = new Date();
  const expDate = new Date(expirationDate);
  const daysUntilExpiration = Math.ceil(
    (expDate - today) / (1000 * 60 * 60 * 24)
  );

  if (daysUntilExpiration < 0) return "expired";
  if (daysUntilExpiration <= 15) return "critical";
  if (daysUntilExpiration <= 30) return "warning";
  return "valid";
};

// Función para determinar el estado de la fila de un vehículo
const getVehicleRowStatus = (documents) => {
  const hasExpired = documents.some(
    (doc) =>
      doc.required && calculateDocumentStatus(doc.expiration_date, doc.required) === "expired"
  );
  return hasExpired ? "expired-row" : "";
};

export default function DashboardVehicleDocuments() {
  const { theme } = useLayout();
  const [searchTimeout, setSearchTimeout] = useState(null);

  // Hooks para obtener datos
  const {
    vehicles,
    pagination,
    isLoading,
    isError,
    filters,
    updateFilters,
    goToNextPage,
    goToPrevPage,
    goToPage,
  } = useVehicleDocuments();

  // Obtener tipos de vehículos para filtros
  const { data: vehicleTypesData, isLoading: isLoadingVehicleTypes } = useVehicleTypes({
    active: true,
    limit: 100,
  });
  const vehicleTypes = vehicleTypesData?.data || [];

  // Obtener operaciones para filtros
  const { operations, isLoading: isLoadingOperations } = useOperations({
    limit: 100,
  });

  // Extraer tipos únicos de documentos de todos los vehículos
  const documentTypes = useMemo(() => {
    const types = new Set();
    vehicles.forEach((vehicle) => {
      vehicle.documents.forEach((doc) => {
        if (doc.document_type_name) {
          types.add(doc.document_type_name);
        }
      });
    });
    return Array.from(types);
  }, [vehicles]);

  // Manejar cambios en la búsqueda
  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (searchTimeout) clearTimeout(searchTimeout);
    setSearchTimeout(setTimeout(() => updateFilters({ search: value }), 500));
  };

  if (isLoading && !vehicles.length) {
    return (
      <div className={`dashboard-loading theme-${theme}`}>
        <LoadingAnimation />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={`dashboard-error theme-${theme}`}>
        Error al cargar vehículos y documentos
      </div>
    );
  }

  return (
    <div className={`dashboard-rrhh-container theme-${theme}`}>
      <div className={`controls-section theme-${theme}`}>
        <div className="filters-container">
          {/* Filtro de búsqueda */}
          <div className="filter-group">
            <input
              type="text"
              placeholder="Buscar por placa o modelo..."
              onChange={handleSearchChange}
              defaultValue={filters.search}
              className={`search-input theme-${theme}`}
            />
          </div>

          {/* Filtro de tipo de vehículo */}
          <div className="filter-group">
            <select
              onChange={(e) =>
                updateFilters({
                  vehicleTypeId: e.target.value ? e.target.value : null,
                })
              }
              value={filters.vehicleTypeId || ""}
              className={`select-input theme-${theme}`}
              disabled={isLoadingVehicleTypes}
            >
              <option value="">Todos los tipos de vehículo</option>
              {vehicleTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          {/* Filtro de operación */}
          <div className="filter-group">
            <select
              onChange={(e) =>
                updateFilters({
                  operationId: e.target.value ? e.target.value : null,
                })
              }
              value={filters.operationId || ""}
              className={`select-input theme-${theme}`}
              disabled={isLoadingOperations}
            >
              <option value="">Todas las operaciones</option>
              {operations.map((operation) => (
                <option key={operation.id} value={operation.id}>
                  {operation.name}
                </option>
              ))}
            </select>
          </div>

          {/* Filtro de estado de documentos */}
          <div className="filter-group">
            <select
              onChange={(e) =>
                updateFilters({ documentFilter: e.target.value })
              }
              value={filters.documentFilter}
              className={`select-input theme-${theme}`}
            >
              <option value="all">Todos los documentos</option>
              <option value="empty">Documentos faltantes</option>
              <option value="expired">Documentos vencidos</option>
              <option value="critical">Vencimiento crítico (15 días)</option>
              <option value="warning">Vencimiento próximo (30 días)</option>
            </select>
          </div>
        </div>

        {/* Leyenda de estados */}
        <div className={`status-legend theme-${theme}`}>
          <span className="legend-item">
            <span className="status-dot expired"></span>
            Vencido
          </span>
          <span className="legend-item">
            <span className="status-dot critical"></span>
            {"<"} 15 días
          </span>
          <span className="legend-item">
            <span className="status-dot warning"></span>
            {"<"} 30 días
          </span>
          <span className="legend-item">
            <span className="status-dot valid"></span>
            Vigente
          </span>
          <span className="legend-item">
            <span className="status-dot empty"></span>
            Sin archivos
          </span>
        </div>
      </div>

      {/* Tabla de vehículos y documentos */}
      <div className={`documents-table-container theme-${theme}`}>
        <table className={`documents-table theme-${theme}`}>
          <thead>
            <tr>
              <th className="fixed-column">Placa</th>
              <th className="fixed-column">Tipo</th>
              <th className="fixed-column">Operación</th>
              <th className="fixed-column">Acciones</th>
              {documentTypes.map((type) => (
                <th key={type}>{type}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {vehicles.map((vehicle) => {
              const rowStatus = getVehicleRowStatus(vehicle.documents);
              return (
                <tr key={vehicle.id} className={rowStatus}>
                  <td className="fixed-column">
                    <div className="vehicle-plate">{vehicle.plate}</div>
                    <div className="vehicle-details">{vehicle.model} {vehicle.year}</div>
                  </td>
                  <td className="fixed-column">
                    {vehicle.vehicle_type_name}
                  </td>
                  <td className="fixed-column">
                    {vehicle.operation_name || "No asignada"}
                  </td>
                  <td className="fixed-column">
                    {vehicle.documents.some(
                      (doc) =>
                        doc.required &&
                        ["expired", "critical", "warning"].includes(
                          calculateDocumentStatus(
                            doc.expiration_date,
                            doc.required
                          )
                        )
                    )
                      ? "Requiere acciones"
                      : "Sin acciones pendientes"}
                  </td>
                  {documentTypes.map((type) => {
                    const doc = vehicle.documents.find(
                      (d) => d.document_type_name === type
                    );
                    const status = doc
                      ? calculateDocumentStatus(
                          doc.expiration_date,
                          doc.required
                        )
                      : "empty";

                    return (
                      <td key={type} className={`document-cell ${status}`}>
                        {doc && doc.file_name ? (
                          <div className="document-info">
                            <span className="document-name">
                              {doc.original_filename || doc.file_name}
                            </span>
                            {doc.expiration_date && (
                              <>
                                <span className="expiration-date">
                                  Vence:{" "}
                                  {new Date(
                                    doc.expiration_date
                                  ).toLocaleDateString()}
                                </span>
                                <span className="days-remaining">
                                  {(() => {
                                    const days = Math.ceil(
                                      (new Date(doc.expiration_date) -
                                        new Date()) /
                                        (1000 * 60 * 60 * 24)
                                    );
                                    return days < 0
                                      ? `Vencido hace ${Math.abs(days)} días`
                                      : `Faltan ${days} días`;
                                  })()}
                                </span>
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="no-document">
                            {doc && doc.required ? "Documento requerido" : "Sin archivos"}
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Controles de paginación */}
      {pagination.total > 0 && (
        <div className={`pagination-controls theme-${theme}`}>
          <button
            onClick={goToPrevPage}
            disabled={pagination.currentPage === 1}
            className="pagination-button"
          >
            Anterior
          </button>

          <div className="pagination-numbers">
            {Array.from(
              { length: Math.min(5, pagination.lastPage) },
              (_, i) => {
                let pageToShow =
                  pagination.lastPage <= 5
                    ? i + 1
                    : pagination.currentPage <= 3
                    ? i + 1
                    : pagination.currentPage >= pagination.lastPage - 2
                    ? pagination.lastPage - 4 + i
                    : pagination.currentPage - 2 + i;
                return (
                  <button
                    key={pageToShow}
                    onClick={() => goToPage(pageToShow)}
                    className={`page-number ${
                      pagination.currentPage === pageToShow ? "active" : ""
                    }`}
                  >
                    {pageToShow}
                  </button>
                );
              }
            )}
          </div>

          <button
            onClick={goToNextPage}
            disabled={pagination.currentPage === pagination.lastPage}
            className="pagination-button"
          >
            Siguiente
          </button>

          <span className="pagination-info">
            {pagination.from}-{pagination.to} de {pagination.total}
          </span>
        </div>
      )}
    </div>
  );
}