import React from "react";
import PropTypes from 'prop-types';
import "../../../assets/scss/components/ui/cards/VehicleCard.scss";
import LockIcon from "../../../assets/svg/view/lock.svg";
import LockIconLight from "../../../assets/svg/view/lock_light.svg";
import { Link } from "react-router-dom";
import VehicleStreamingMediaType from "../../../utils/VehicleStreamingMedia";

// Componente para un vehículo individual
const SingleVehicleCard = ({ id, plate, vehicleType, color, operatorName, vehicleImage, theme }) => {
  
  return (
    <Link to={`/vehicles/${id}`} className="card-link">
      <div className="vehicle-card">
        <div className="card-header">
          <div className="column first">
            <div className="column first-margenv1">{plate}</div>
            <div className="column first-margen"></div>
          </div>

          <div className="column second">
            <div className="colum second-content"></div>
            <div className="column second-margenv2"></div>
            <div className="column second-margenv3"></div>
          </div>
        </div>
        <div className="card-content">
          <div className="image-wrapper">
            <div className="image-border">
            <VehicleStreamingMediaType url={id} type="vehiclePhoto" />            
            </div>
          </div>
          <div className="info-wrapper">
            <div className="info">
              <div className="line"></div>
              <span className="text">Tipo: {vehicleType || 'No definido'}</span>
            </div>
            <div className="info">
              <div className="line"></div>
              <span className="text">Color: {color || 'No definido'}</span>
            </div>
            <div className="info">
              <div className="line"></div>
              <span className="text">{operatorName || 'Sin operador'}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

// Componente para vehículos combinados (cabezote + trailer)
// Actualización del componente CombinedVehicleCard
const CombinedVehicleCard = ({ 
  id, vehiclePlate, vehicleType, vehicleColor, 
  trailerPlate, trailerType, trailerColor, 
  operatorName, vehicleImage, trailerImage, theme,
  vehicle_id, trailer_id 
}) => {
  const lockIconSrc = theme === 'light' ? LockIconLight : LockIcon;
  const containerClass = "combined-vehicle-container";
  
  return (
    <div className={containerClass}>
      {/* Cabezote */}
      <Link to={`/vehicles/${vehicle_id}`} className="card-link">
        <div className="vehicle-card">
          {/* Contenido del cabezote (mantener el mismo) */}
          <div className="card-header">
            <div className="column first">
              <div className="column first-margenv1">{vehiclePlate}</div>
              <div className="column first-margen"></div>
            </div>
            <div className="column second">
              <div className="colum second-content"></div>
              <div className="column second-margenv2"></div>
              <div className="column second-margenv3"></div>
            </div>
          </div>
          <div className="card-content">
            <div className="image-wrapper">
              <div className="image-border">
                <VehicleStreamingMediaType url={vehicle_id} type="vehiclePhoto" />
              </div>
            </div>
            <div className="info-wrapper">
              <div className="info">
                <div className="line"></div>
                <span className="text">Tipo: {vehicleType || 'No definido'}</span>
              </div>
              <div className="info">
                <div className="line"></div>
                <span className="text">Color: {vehicleColor || 'No definido'}</span>
              </div>
              <div className="info">
                <div className="line"></div>
                <span className="text">{operatorName || 'Sin operador'}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* Conector */}
      <div className="vehicle-connector">
        <div className="connector-icon">
          <img src={lockIconSrc} alt="Icono lock" />
        </div>
        <div className="connector-line top"></div>
        <div className="connector-line bottom"></div>
      </div>

      {/* Trailer */}
      <Link to={`/vehicles/${trailer_id}`} className="card-link">
        <div className="vehicle-card trailer">
          {/* Contenido del trailer (mantener el mismo) */}
          <div className="card-header">
            <div className="column first">
              <div className="column first-margenv1">{trailerPlate}</div>
              <div className="column first-margen"></div>
            </div>
            <div className="column second">
              <div className="colum second-content"></div>
              <div className="column second-margenv2"></div>
              <div className="column second-margenv3"></div>
            </div>
          </div>
          <div className="card-content">
            <div className="image-wrapper">
              <div className="image-border trailer-border">
                <VehicleStreamingMediaType url={trailer_id} type="vehiclePhoto" />
              </div>
            </div>
            <div className="info-wrapper">
              <div className="info">
                <div className="line trailer-line"></div>
                <span className="text">Trailer</span>
              </div>
              <div className="info">
                <div className="line trailer-line"></div>
                <span className="text">Color: {trailerColor || 'No definido'}</span>
              </div>
              <div className="info">
                <div className="line trailer-line"></div>
                <span className="text">{operatorName || 'Sin operador'}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
// Componente principal que decide qué tipo de tarjeta renderizar
const VehiclesCard = (props) => {
  // Si tiene vehiclePlate y trailerPlate, es una combinación
  if (props.vehiclePlate && props.trailerPlate) {
    return <CombinedVehicleCard {...props} />;
  }
  // De lo contrario, es un vehículo individual
  return <SingleVehicleCard {...props} />;
};

// Actualizar PropTypes para incluir todos los props necesarios
VehiclesCard.propTypes = {
  id: PropTypes.string.isRequired,
  plate: PropTypes.string,
  vehiclePlate: PropTypes.string,
  trailerPlate: PropTypes.string,
  vehicleType: PropTypes.string,
  trailerType: PropTypes.string,
  color: PropTypes.string,
  vehicleColor: PropTypes.string,
  trailerColor: PropTypes.string,
  operatorName: PropTypes.string,
  vehicleImage: PropTypes.string,
  trailerImage: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  vehicle_id: PropTypes.string, // ID específico del cabezote
  trailer_id: PropTypes.string  // ID específico del trailer
};

export default VehiclesCard;