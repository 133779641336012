// lib/speedCalculator.js
import { vehicleConfigurations } from './transportData';

// Mapeo de tipos de terreno a categorías
const terrainTypeMapping = {
  'Plano': 'plano',
  'Pendiente baja positiva': 'ondulado',
  'Pendiente baja negativa': 'ondulado',
  'Pendiente media positiva': 'montanoso',
  'Pendiente media negativa': 'montanoso',
  'Pendiente alta positiva': 'montanoso',
  'Pendiente alta negativa': 'montanoso',
  'Zona urbana': 'urbano',
  'Zona despavimentada': 'despavimentado'
};

// Coeficientes de velocidad por tipo de terreno (% de velocidad máxima)
const speedCoefficients = {
  plano: 1.0,
  ondulado: 0.85,
  montanoso: 0.65,
  urbano: 0.50,
  despavimentado: 0.40
};

// Velocidades base por categoría de vehículo (km/h)
const baseSpeedsByVehicle = {
  // Velocidades máximas por tipo de terreno cuando está cargado
  loaded: {
    plano: 80,
    ondulado: 70,
    montanoso: 50,
    urbano: 40,
    despavimentado: 30
  },
  // Velocidades máximas por tipo de terreno cuando está vacío
  unloaded: {
    plano: 90,
    ondulado: 75,
    montanoso: 60,
    urbano: 45,
    despavimentado: 35
  }
};

/**
 * Calcula velocidades por tipo de terreno basado en datos topográficos
 */
export const calculateSpeedsByTerrainType = (
  vehicleConfigId,
  terrainSegments,
  outboundCargoCondition,
  inboundCargoCondition,
  routeType = 'oneway'
) => {
  // Obtener vehículo
  const vehicle = vehicleConfigurations.find(v => v.id === vehicleConfigId);
  const vehicleWeight = vehicle ? vehicle.pesoMaximo : 10000; // Peso en kg
  
  // Factor de ajuste basado en peso del vehículo (vehículos más pesados son más lentos)
  const weightFactor = Math.max(0.7, 1 - (vehicleWeight / 40000)); // Mínimo 70% de velocidad
  
  // Aplicar factor de peso a velocidades base
  const adjustedLoadedSpeeds = {};
  const adjustedUnloadedSpeeds = {};
  
  Object.keys(baseSpeedsByVehicle.loaded).forEach(terrainType => {
    adjustedLoadedSpeeds[terrainType] = Math.round(
      baseSpeedsByVehicle.loaded[terrainType] * weightFactor
    );
    
    adjustedUnloadedSpeeds[terrainType] = Math.round(
      baseSpeedsByVehicle.unloaded[terrainType] * weightFactor
    );
  });
  
  // Calcular distribución ponderada de terreno
  const terrainDistribution = {};
  let totalDistance = 0;
  
  terrainSegments.forEach(segment => {
    const category = terrainTypeMapping[segment.type] || 'plano';
    
    if (!terrainDistribution[category]) {
      terrainDistribution[category] = 0;
    }
    
    terrainDistribution[category] += segment.distance;
    totalDistance += segment.distance;
  });
  
  // Calcular velocidad promedio ponderada
  let avgSpeed = 0;
  let outboundWeightedSpeed = 0;
  let inboundWeightedSpeed = 0;
  
  Object.keys(terrainDistribution).forEach(terrainType => {
    const proportion = terrainDistribution[terrainType] / totalDistance;
    
    // Velocidad de ida
    const outboundSpeed = outboundCargoCondition === 'CARGADO' ? 
      adjustedLoadedSpeeds[terrainType] : adjustedUnloadedSpeeds[terrainType];
    
    outboundWeightedSpeed += outboundSpeed * proportion;
    
    // Velocidad de vuelta (si aplica)
    if (routeType === 'roundtrip' && inboundCargoCondition) {
      const inboundSpeed = inboundCargoCondition === 'CARGADO' ? 
        adjustedLoadedSpeeds[terrainType] : adjustedUnloadedSpeeds[terrainType];
      
      inboundWeightedSpeed += inboundSpeed * proportion;
    }
  });
  
  // Calcular velocidad promedio final
  if (routeType === 'roundtrip' && inboundCargoCondition) {
    avgSpeed = (outboundWeightedSpeed + inboundWeightedSpeed) / 2;
  } else {
    avgSpeed = outboundWeightedSpeed;
  }
  
  return {
    loadedSpeeds: adjustedLoadedSpeeds,
    unloadedSpeeds: adjustedUnloadedSpeeds,
    avgSpeed: avgSpeed
  };
};