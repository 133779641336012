import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Dialog,
  CircularProgress,
  Chip,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Hooks personalizados
import {
  useOperations,
  useDocumentTypes,
  useVehicleDocumentRequirements,
  useOperationDetails,
  useDocumentationAnalytics,
} from "../../../hooks/useDocumentationVehicleQuery";

// Contexto de notificaciones y tema
import { useNotification } from "../../../context/NotificationContext";
import { useLayout } from "../../../context/LayoutContext";

// Hook para obtener tipos de vehículos
import { useVehicleTypes } from "../../../hooks/useVehicleQuery";

// Componente de carga
import LoadingAnimation from "../../loading/LoadingAnimation";

export function DocumentRequirementsManager() {
  // Contextos
  const { showNotification } = useNotification();
  const { theme } = useLayout();

  // Estado para controlar las pestañas de configuración
  const [activeTab, setActiveTab] = useState(0);

  // Estado para la operación o tipo de vehículo seleccionado
  const [selectedOperationId, setSelectedOperationId] = useState("");
  const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState("");

  // Estado para el diálogo de selección de documentos
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState("operation");
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  // Obtener servicios de análisis
  const documentationAnalytics = useDocumentationAnalytics();

  // Obtener operaciones (sin paginación para este caso de uso)
  const { operations, isLoading: isLoadingOperations } = useOperations({
    limit: 100, // Obtener muchas operaciones
    queryOptions: {
      refetchOnWindowFocus: true,
    },
  });

  // Obtener tipos de vehículos (solo los activos)
  const { data: vehicleTypesData, isLoading: isLoadingVehicleTypes } = useVehicleTypes({
    active: true,
    limit: 100,
    queryOptions: {
      refetchOnWindowFocus: true,
    },
  });
  const vehicleTypes = vehicleTypesData?.data || [];

  // Obtener todos los tipos de documentos
  const { documentTypes, isLoading: isLoadingDocumentTypes } = useDocumentTypes({
    limit: 500, // Obtener todos los documentos
    queryOptions: {
      refetchOnWindowFocus: true,
    },
  });

  // Obtener detalles de la operación seleccionada si hay un ID
  const operationDetails = useOperationDetails(
    selectedOperationId && dialogMode === "operation" ? selectedOperationId : null,
    { includeDocuments: true }
  );

  // Obtener documentos requeridos para el tipo de vehículo seleccionado si hay un ID
  const vehicleDocumentRequirements = useVehicleDocumentRequirements(
    selectedVehicleTypeId && dialogMode === "vehicleType" ? selectedVehicleTypeId : null
  );

  // Efecto para actualizar los documentos seleccionados cuando cambian los detalles
  useEffect(() => {
    if (dialogMode === "operation" && operationDetails.documents && documentDialogOpen) {
      setSelectedDocuments(operationDetails.documents.map((doc) => doc.id));
    }
  }, [dialogMode, operationDetails.documents, documentDialogOpen]);

  // Efecto para actualizar los documentos seleccionados cuando cambian los requisitos del vehículo
  useEffect(() => {
    if (dialogMode === "vehicleType" && vehicleDocumentRequirements.documents && documentDialogOpen) {
      setSelectedDocuments(vehicleDocumentRequirements.documents.map((doc) => doc.id));
    }
  }, [dialogMode, vehicleDocumentRequirements.documents, documentDialogOpen]);

  // Manejador para cambiar entre pestañas
  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
    setSelectedOperationId("");
    setSelectedVehicleTypeId("");
  };

  // Manejador para seleccionar una operación
  const handleSelectOperation = (operationId) => {
    setSelectedOperationId(operationId);
  };

  // Manejador para seleccionar un tipo de vehículo
  const handleSelectVehicleType = (vehicleTypeId) => {
    setSelectedVehicleTypeId(vehicleTypeId);
  };

  // Función para abrir el diálogo de asignación de documentos
  const handleOpenDocumentDialog = (mode) => {
    setDialogMode(mode);
    setDocumentDialogOpen(true);
    // Los documentos se cargarán automáticamente a través de los efectos
  };

  // Función para cerrar el diálogo
  const handleCloseDocumentDialog = () => {
    setDocumentDialogOpen(false);
    setSelectedDocuments([]);
  };

  // Función para manejar cambios en la selección de documentos
  const handleDocumentCheckChange = (documentId) => {
    setSelectedDocuments((prev) => {
      if (prev.includes(documentId)) {
        return prev.filter((id) => id !== documentId);
      } else {
        return [...prev, documentId];
      }
    });
  };

  // Función para guardar los documentos seleccionados
  const handleSaveDocuments = async () => {
    try {
      if (dialogMode === "operation" && selectedOperationId) {
        // Guardar documentos para la operación
        await operationDetails.assignDocumentsToOperation({
          operationId: selectedOperationId,
          documentIds: selectedDocuments,
        });

        showNotification({
          message: "Documentos asignados exitosamente a la operación",
          severity: "success",
        });
      } else if (dialogMode === "vehicleType" && selectedVehicleTypeId) {
        // Guardar documentos para el tipo de vehículo
        await vehicleDocumentRequirements.assignDocuments(selectedDocuments);

        showNotification({
          message: "Documentos asignados exitosamente al tipo de vehículo",
          severity: "success",
        });
      }

      handleCloseDocumentDialog();
    } catch (error) {
      showNotification({
        message: error.message || "Error al asignar documentos",
        severity: "error",
      });
    }
  };

  // Componente para la pestaña de configuración por operación
  const OperationConfigTab = () => {
    // Si está cargando, mostrar indicador
    if (isLoadingOperations) {
      return <LoadingAnimation />;
    }

    // Si no hay operaciones, mostrar mensaje
    if (!operations || operations.length === 0) {
      return (
        <div className={`GM__${theme}-empty-state`}>
          <p>No hay operaciones disponibles.</p>
          <p>Por favor, cree operaciones primero en la sección de Operaciones.</p>
        </div>
      );
    }

    // Renderizar lista de operaciones
    return (
      <div className={`GM__${theme}-operation-config-tab`}>
        <div className={`GM__${theme}-operation-list`}>
          <h3 className={`GM__${theme}-list-title`}>Seleccione una Operación</h3>
          <div className={`GM__${theme}-scrollable-list`}>
            {operations.map((operation) => (
              <div
                key={operation.id}
                className={`GM__${theme}-list-item ${
                  selectedOperationId === operation.id ? `GM__${theme}-selected` : ""
                }`}
                onClick={() => handleSelectOperation(operation.id)}
              >
                {operation.name}
              </div>
            ))}
          </div>
        </div>

        {selectedOperationId && (
          <div className={`GM__${theme}-operation-detail`}>
            <OperationDetail
              operationId={selectedOperationId}
              onConfigureDocuments={() => handleOpenDocumentDialog("operation")}
            />
          </div>
        )}
      </div>
    );
  };

  // Componente para mostrar detalles de una operación seleccionada
  const OperationDetail = ({ operationId, onConfigureDocuments }) => {
    // Usar el hook para obtener detalles y documentos de la operación
    const { operation, documents, isLoading } = useOperationDetails(operationId, {
      includeDocuments: true,
    });

    if (isLoading) {
      return <LoadingAnimation size={24} />;
    }

    if (!operation) {
      return (
        <div className={`GM__${theme}-error-state`}>
          No se pudo cargar la información de la operación.
        </div>
      );
    }

    return (
      <div className={`GM__${theme}-detail-panel`}>
        <div className={`GM__${theme}-detail-header`}>
          <h3 className={`GM__${theme}-detail-title`}>{operation.name}</h3>
          <button 
            className={`GM__${theme}-configure-button`} 
            onClick={onConfigureDocuments}
          >
            Configurar Documentos Requeridos
          </button>
        </div>

        <div className={`GM__${theme}-document-list-section`}>
          <h4 className={`GM__${theme}-section-subtitle`}>Documentos Requeridos</h4>
          {documents && documents.length > 0 ? (
            <div className={`GM__${theme}-document-chips`}>
              {documents.map((doc) => (
                <Chip 
                  key={doc.id} 
                  label={doc.name} 
                  className={`GM__${theme}-document-chip`} 
                />
              ))}
            </div>
          ) : (
            <p className={`GM__${theme}-no-documents-message`}>
              No hay documentos configurados para esta operación.
            </p>
          )}
        </div>
      </div>
    );
  };

  // Componente para la pestaña de configuración por tipo de vehículo
  const VehicleTypeConfigTab = () => {
    // Si está cargando, mostrar indicador
    if (isLoadingVehicleTypes) {
      return <LoadingAnimation />;
    }

    // Si no hay tipos de vehículos, mostrar mensaje
    if (!vehicleTypes || vehicleTypes.length === 0) {
      return (
        <div className={`GM__${theme}-empty-state`}>
          <p>No hay tipos de vehículos disponibles.</p>
          <p>Por favor, cree tipos de vehículos primero en la sección correspondiente.</p>
        </div>
      );
    }

    // Organizar tipos de vehículos por categoría
    const vehicleTypesByCategory = vehicleTypes.reduce((acc, type) => {
      const category = type.component_type || "Otros";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(type);
      return acc;
    }, {});

    // Renderizar lista de tipos de vehículos agrupados por categoría
    return (
      <div className={`GM__${theme}-vehicle-type-config-tab`}>
        <div className={`GM__${theme}-vehicle-type-list`}>
          <h3 className={`GM__${theme}-list-title`}>Seleccione un Tipo de Vehículo</h3>
          <div className={`GM__${theme}-scrollable-list`}>
            {Object.entries(vehicleTypesByCategory).map(([category, types]) => (
              <Accordion 
                key={category} 
                defaultExpanded 
                className={`GM__${theme}-vehicle-accordion`}
              >
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  className={`GM__${theme}-accordion-summary`}
                >
                  <h4 className={`GM__${theme}-category-title`}>{category}</h4>
                </AccordionSummary>
                <AccordionDetails className={`GM__${theme}-accordion-details`}>
                  <div className={`GM__${theme}-category-items`}>
                    {types.map((type) => (
                      <div
                        key={type.id}
                        className={`GM__${theme}-list-item ${
                          selectedVehicleTypeId === type.id ? `GM__${theme}-selected` : ""
                        }`}
                        onClick={() => handleSelectVehicleType(type.id)}
                      >
                        {type.name}
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>

        {selectedVehicleTypeId && (
          <div className={`GM__${theme}-vehicle-type-detail`}>
            <VehicleTypeDetail
              vehicleTypeId={selectedVehicleTypeId}
              onConfigureDocuments={() => handleOpenDocumentDialog("vehicleType")}
            />
          </div>
        )}
      </div>
    );
  };

  // Componente para mostrar detalles de un tipo de vehículo seleccionado
  const VehicleTypeDetail = ({ vehicleTypeId, onConfigureDocuments }) => {
    // Obtener el tipo de vehículo seleccionado de la lista global
    const selectedVehicleType = vehicleTypes.find((type) => type.id === vehicleTypeId);

    // Usar el hook para obtener documentos requeridos para este tipo de vehículo
    const { documents, isLoading } = useVehicleDocumentRequirements(vehicleTypeId);

    if (isLoading || !selectedVehicleType) {
      return <LoadingAnimation size={24} />;
    }

    return (
      <div className={`GM__${theme}-detail-panel`}>
        <div className={`GM__${theme}-detail-header`}>
          <h3 className={`GM__${theme}-detail-title`}>{selectedVehicleType.name}</h3>
          <button 
            className={`GM__${theme}-configure-button`} 
            onClick={onConfigureDocuments}
          >
            Configurar Documentos Requeridos
          </button>
        </div>

        <div className={`GM__${theme}-detail-info`}>
          <div className={`GM__${theme}-info-row`}>
            <span className={`GM__${theme}-info-label`}>Categoría:</span>
            <span className={`GM__${theme}-info-value`}>
              {selectedVehicleType.category || "No especificada"}
            </span>
          </div>
          <div className={`GM__${theme}-info-row`}>
            <span className={`GM__${theme}-info-label`}>Tipo de Componente:</span>
            <span className={`GM__${theme}-info-value`}>
              {selectedVehicleType.component_type || "No especificado"}
            </span>
          </div>
          <div className={`GM__${theme}-info-row`}>
            <span className={`GM__${theme}-info-label`}>Capacidad Máxima:</span>
            <span className={`GM__${theme}-info-value`}>
              {selectedVehicleType.max_capacity
                ? `${selectedVehicleType.max_capacity} ${selectedVehicleType.capacity_unit || ""}`
                : "No especificada"}
            </span>
          </div>
        </div>

        <div className={`GM__${theme}-document-list-section`}>
          <h4 className={`GM__${theme}-section-subtitle`}>Documentos Requeridos</h4>
          {documents && documents.length > 0 ? (
            <div className={`GM__${theme}-document-chips`}>
              {documents.map((doc) => (
                <Chip 
                  key={doc.id} 
                  label={doc.name} 
                  className={`GM__${theme}-document-chip`} 
                />
              ))}
            </div>
          ) : (
            <p className={`GM__${theme}-no-documents-message`}>
              No hay documentos configurados para este tipo de vehículo.
            </p>
          )}
        </div>
      </div>
    );
  };

  // Componente para la pestaña de matriz comparativa
  const ComparisonMatrixTab = () => {
    // Estados para la matriz
    const [selectedOperationIds, setSelectedOperationIds] = useState([]);
    const [isLoadingMatrix, setIsLoadingMatrix] = useState(false);
    const [matrixData, setMatrixData] = useState(null);

    // Uso del hook para comparar operaciones
    const comparisonQuery = documentationAnalytics.useOperationsComparison(
      selectedOperationIds,
      {
        enabled: selectedOperationIds.length > 0,
        onSuccess: (data) => {
          setMatrixData(data.data);
          setIsLoadingMatrix(false);
        },
        onError: (error) => {
          console.error("Error al cargar matriz comparativa:", error);
          showNotification({
            message: "Error al cargar la matriz comparativa",
            severity: "error",
          });
          setIsLoadingMatrix(false);
        },
      }
    );

    // Efecto para actualizar el estado de carga
    useEffect(() => {
      if (selectedOperationIds.length > 0) {
        setIsLoadingMatrix(comparisonQuery.isLoading);
      } else {
        setMatrixData(null);
      }
    }, [selectedOperationIds, comparisonQuery.isLoading]);

    // Manejar selección/deselección de operación
    const handleToggleOperation = (operationId) => {
      setSelectedOperationIds((prev) => {
        if (prev.includes(operationId)) {
          return prev.filter((id) => id !== operationId);
        } else {
          return [...prev, operationId];
        }
      });
    };

    return (
      <div className={`GM__${theme}-comparison-matrix-tab`}>
        <div className={`GM__${theme}-operation-selection`}>
          <h3 className={`GM__${theme}-section-subtitle`}>Seleccione Operaciones para Comparar</h3>
          <div className={`GM__${theme}-operation-checkboxes`}>
            {isLoadingOperations ? (
              <CircularProgress size={20} />
            ) : (
              operations.map((operation) => (
                <FormControlLabel
                  key={operation.id}
                  control={
                    <Checkbox
                      checked={selectedOperationIds.includes(operation.id)}
                      onChange={() => handleToggleOperation(operation.id)}
                      className={`GM__${theme}-operation-checkbox`}
                    />
                  }
                  label={operation.name}
                  className={`GM__${theme}-operation-checkbox-label`}
                />
              ))
            )}
          </div>
        </div>

        <div className={`GM__${theme}-matrix-container`}>
          {isLoadingMatrix ? (
            <LoadingAnimation />
          ) : selectedOperationIds.length === 0 ? (
            <div className={`GM__${theme}-instruction-message`}>
              Seleccione al menos una operación para ver la matriz comparativa.
            </div>
          ) : !matrixData ? (
            <div className={`GM__${theme}-error-message`}>
              No se pudo cargar la información comparativa.
            </div>
          ) : (
            <div className={`GM__${theme}-comparison-table-wrapper`}>
              <h3 className={`GM__${theme}-matrix-title`}>Matriz Comparativa de Requisitos Documentales</h3>
              <div className={`GM__${theme}-vehicle-table-wrapper-table`}>
                <table className={`GM__${theme}-vehicle-table fixed-columns-1`}>
                  <thead>
                    <tr>
                      <th className="fixed-column fixed-column-1">Documento</th>
                      {matrixData.operations.map((op) => (
                        <th key={op.id}>{op.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {matrixData.documentsComparison.map((doc) => (
                      <tr key={doc.id}>
                        <td className={`fixed-column fixed-column-1 GM__${theme}-document-name`}>
                          <Tooltip title={doc.description || "Sin descripción"}>
                            <span>{doc.name}</span>
                          </Tooltip>
                        </td>
                        {matrixData.operations.map((op) => (
                          <td key={op.id} className={`GM__${theme}-requirement-cell`}>
                            {doc.requiredByOperations[op.id]?.required ? (
                              <span className={`GM__${theme}-required-indicator`}>
                                Requerido
                              </span>
                            ) : (
                              <span className={`GM__${theme}-not-required-indicator`}>
                                No Requerido
                              </span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`GM__${theme}-document-requirements-manager`}>
      <div className={`GM__${theme}-header-section`}>
        <h2 className={`GM__${theme}-section-title`}>Gestión de Requisitos Documentales</h2>
      </div>

      <div className={`GM__${theme}-tabs-container`}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          className={`GM__${theme}-configuration-tabs`}
          variant="fullWidth"
        >
          <Tab label="Configurar por Operación" className={`GM__${theme}-tab`} />
          <Tab label="Configurar por Tipo de Vehículo" className={`GM__${theme}-tab`} />
          <Tab label="Matriz Comparativa" className={`GM__${theme}-tab`} />
        </Tabs>

        <div className={`GM__${theme}-tab-content`}>
          {activeTab === 0 && <OperationConfigTab />}
          {activeTab === 1 && <VehicleTypeConfigTab />}
          {activeTab === 2 && <ComparisonMatrixTab />}
        </div>
      </div>

      {/* Diálogo para seleccionar documentos */}
      <Dialog
        open={documentDialogOpen}
        onClose={handleCloseDocumentDialog}
        maxWidth="md"
        fullWidth
        className={`GM__modal-container-${theme}`}
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
      >
        <div className={`GM__${theme}-modal GM__${theme}-document-selection-modal`}>
          <div className={`GM__${theme}-modal-header-container`}>
            <h3 className={`GM__${theme}-modal-header`}>
              {dialogMode === "operation"
                ? "Configurar Documentos Requeridos para la Operación"
                : "Configurar Documentos Requeridos para el Tipo de Vehículo"}
            </h3>
          </div>
          <div className={`GM__${theme}-modal-content`}>
            {isLoadingDocumentTypes ? (
              <LoadingAnimation />
            ) : (
              <div className={`GM__${theme}-document-selection`}>
                <p className={`GM__${theme}-selection-instruction`}>
                  Seleccione los documentos requeridos{" "}
                  {dialogMode === "operation" ? "para esta operación" : "para este tipo de vehículo"}:
                </p>

                <div className={`GM__${theme}-document-list`}>
                  {documentTypes.map((doc) => (
                    <div key={doc.id} className={`GM__${theme}-document-checkbox-item`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDocuments.includes(doc.id)}
                            onChange={() => handleDocumentCheckChange(doc.id)}
                            className={`GM__${theme}-document-checkbox`}
                          />
                        }
                        label={
                          <div className={`GM__${theme}-document-label`}>
                            <span className={`GM__${theme}-document-name-label`}>{doc.name}</span>
                            {doc.description && (
                              <Tooltip title={doc.description}>
                                <span className={`GM__${theme}-info-icon`}>ℹ️</span>
                              </Tooltip>
                            )}
                          </div>
                        }
                        className={`GM__${theme}-document-checkbox-label`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className={`GM__${theme}-modal-footer`}>
            <button 
              className={`GM__${theme}-btn GM__${theme}-btn-cancel`} 
              onClick={handleCloseDocumentDialog}
            >
              Cancelar
            </button>
            <button
              className={`GM__${theme}-btn GM__${theme}-btn-save`}
              onClick={handleSaveDocuments}
              disabled={isLoadingDocumentTypes}
            >
              Guardar
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}