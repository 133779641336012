// src/routes/
import PropTypes from 'prop-types';
import { useAuth } from '../context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import PublicLayout from '../layout/PublicLayout';

const PublicRoute = ({ children, requiresNoAuth = false }) => {
  const { user } = useAuth();
  const location = useLocation();

  // Si requiere no estar autenticado y el usuario está autenticado, redirigir
  if (requiresNoAuth && user) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <PublicLayout>
      {children}
    </PublicLayout>
  );
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
  requiresNoAuth: PropTypes.bool
};

export default PublicRoute;