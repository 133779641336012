import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

const LocationInput = ({ 
  value, 
  onChange, 
  name, 
  placeholder, 
  label, 
  disabled, 
  required, 
  theme,
  onCoordinatesChange,
  googleMapsLoaded 
}) => {
  // Referencia para controlar montaje/desmontaje del componente
  const isMounted = useRef(true);
  const selectionRef = useRef(false);
  const suggestionsRef = useRef(null);
  
  const {
    ready,
    value: inputValue,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'co' },
    },
    debounce: 300,
    defaultValue: value,
    cacheKey: `places-${name}`,
    initOnMount: googleMapsLoaded
  });

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(value);

  // Efecto para limpiar al desmontar
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Sincronizar con el valor externo
  useEffect(() => {
    if (value !== selectedLocation && !selectionRef.current) {
      setValue(value, false);
      setSelectedLocation(value);
    }
    selectionRef.current = false;
  }, [value, setValue, selectedLocation]);

  // Si cambia la disponibilidad de Google Maps
  useEffect(() => {
    if (googleMapsLoaded && value) {
      setValue(value, false);
    }
  }, [googleMapsLoaded, value, setValue]);

  // Manejador de clics fuera del componente para cerrar sugerencias
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInput = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    setShowSuggestions(true);
    
    // Llamar al onChange original para actualizar el estado del formulario padre
    const event = {
      target: {
        name,
        value: newValue
      }
    };
    onChange(event);
  };

  const handleSelect = async (suggestion) => {
    selectionRef.current = true;
    const description = suggestion.description;
    
    // Primero actualizar la UI
    setValue(description, false);
    setSelectedLocation(description);
    clearSuggestions();
    setShowSuggestions(false);

    try {
      // Obtener coordenadas geográficas de la dirección seleccionada
      const results = await getGeocode({ address: description });
      if (results && results.length > 0) {
        const { lat, lng } = await getLatLng(results[0]);
        
        // Actualizar el estado en el componente padre
        const event = {
          target: {
            name,
            value: description
          }
        };
        onChange(event);
        
        // Llamar al callback con las coordenadas
        if (onCoordinatesChange) {
          onCoordinatesChange(name, { lat, lng });
        }
      }
    } catch (error) {
      console.error(`Error al geocodificar dirección (${name}):`, error);
    }
  };

  const handleFocus = () => {
    if (inputValue) {
      setShowSuggestions(true);
    }
  };

  // Detectar si hay valores pero no coordenadas seleccionadas
  useEffect(() => {
    const geocodeExistingValue = async () => {
      if (value && !selectionRef.current && onCoordinatesChange) {
        try {
          const results = await getGeocode({ address: value });
          if (results && results.length > 0) {
            const { lat, lng } = await getLatLng(results[0]);
            onCoordinatesChange(name, { lat, lng });
          }
        } catch (error) {
          console.log(`No se pudo geocodificar el valor actual (${name}):`, error);
        }
      }
    };

    if (googleMapsLoaded && ready && value) {
      geocodeExistingValue();
    }
  }, [googleMapsLoaded, ready, value, name, onCoordinatesChange]);

  return (
    <div className={`GM__${theme}-input-container`} ref={suggestionsRef}>
      <label htmlFor={name} className={`GM__${theme}-input-label`}>
        {required ? `*${label}` : label}
      </label>
      <input
        type="text"
        id={name}
        name={name}
        value={inputValue}
        onChange={handleInput}
        onFocus={handleFocus}
        disabled={!ready || disabled}
        placeholder={placeholder}
        className={`GM__${theme}-input`}
        required={required}
        autoComplete="off"
      />
      
      {showSuggestions && status === "OK" && (
        <ul className={`GM__${theme}-suggestions-list`}>
          {data.map((suggestion) => (
            <li
              key={suggestion.place_id}
              onClick={() => handleSelect(suggestion)}
              className={`GM__${theme}-suggestion-item`}
            >
              {suggestion.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

LocationInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  theme: PropTypes.string,
  onCoordinatesChange: PropTypes.func,
  googleMapsLoaded: PropTypes.bool
};

export default LocationInput;