import React, { useState, useRef, useEffect } from 'react';
import { useChat } from '../../context/ChatContext';
import { useSocket } from '../../context/SocketContext';
import { useAuth } from '../../context/AuthContext';
import ChatMessage from './ChatMessage';
import AttachmentPreview from './AttachmentPreview';
import ReplyPreview from './ReplyPreview';
import "../../assets/scss/components/Chat.scss";
import { useNotification } from '../../context/NotificationContext';
import ArchiveIcon from '../../assets/svg/components/archive.svg'
import SendIcon from '../../assets/svg/components/send.svg'
import EditIcon from '../../assets/svg/view/edit.svg'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function Chat() {
  const { showNotification } = useNotification();
  const {
    messages,
    activeChatId,
    selectedRecipient,
    typingUsers,
    sendMessage,
    users,
    onlineUsers,
    conversations,
    deleteMessage,
    markAsRead,
    setActiveChatId,
    setSelectedRecipient,
    editMessage,
    setMessagesByConversation
  } = useChat();

  const socket = useSocket();
  const { user } = useAuth();
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);

  const [newMessage, setNewMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [replyTo, setReplyTo] = useState(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  console.log(messages)
  useEffect(() => {
    if (activeChatId) {
      markAsRead(activeChatId);
    }
  }, [activeChatId, markAsRead]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const [visibleUsersStart, setVisibleUsersStart] = useState(0);
  const headerRef = useRef(null);
  const [visibleChipsCount, setVisibleChipsCount] = useState(0);

  // Función para calcular cuántos chips pueden mostrarse
  useEffect(() => {
    const calculateVisibleChips = () => {
      if (headerRef.current && activeChatId === 'global') {
        const headerWidth = headerRef.current.offsetWidth;
        const chipWidth = 120; // Ancho aproximado de cada chip
        const navigationWidth = 80; // Espacio para botones de navegación
        const availableWidth = headerWidth - navigationWidth;
        setVisibleChipsCount(Math.floor(availableWidth / chipWidth));
      }
    };

    calculateVisibleChips();
    window.addEventListener('resize', calculateVisibleChips);
    return () => window.removeEventListener('resize', calculateVisibleChips);
  }, [activeChatId]);

  // Función para manejar la navegación de usuarios
  const handleNavigateUsers = (direction) => {
    if (direction === 'prev') {
      setVisibleUsersStart(Math.max(0, visibleUsersStart - visibleChipsCount));
    } else {
      setVisibleUsersStart(Math.min(
        onlineUsers.size - visibleChipsCount,
        visibleUsersStart + visibleChipsCount
      ));
    }
  };

  // Componente para el encabezado del chat global
  const renderGlobalChatHeader = () => {
    if (activeChatId !== 'global') return null;

    const onlineUsersList = Array.from(onlineUsers).map(userId => 
      users.find(u => u.id === userId)
    ).filter(Boolean);

    const visibleUsers = onlineUsersList.slice(
      visibleUsersStart,
      visibleUsersStart + visibleChipsCount
    );
    const handleUserSelect = async (selectedUser) => {
      // Buscar si ya existe una conversación con este usuario
      const existingConversation = conversations.find(conv => 
        conv.participants.some(p => p.id === selectedUser.id)
      );
    
      if (existingConversation) {
        setActiveChatId(existingConversation.id);
        setSelectedRecipient(selectedUser);
      } else {
        // Nueva conversación
        setActiveChatId(null);
        setSelectedRecipient(selectedUser);
        setMessagesByConversation(prev => ({
          ...prev,
          temp: [] // Inicializamos un array vacío para la nueva conversación
        }));
      }
    };

    return (
      <div className="global-chat-header" ref={headerRef}>
        <div className="header-title">
          <h3>Chat Global</h3>
        </div>
        <div className="online-users-container">
          {visibleUsersStart > 0 && (
            <IconButton 
              className="nav-button prev"
              onClick={() => handleNavigateUsers('prev')}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          )}
          <Stack direction="row" spacing={1} className="users-chips">
            {visibleUsers.map(user => (
              <Chip
                key={user.id}
                icon={<FaceIcon />}
                label={`${user.first_name} ${user.first_last_name}`}
                className="user-chip"
                onClick={() => handleUserSelect(user)}
              />
            ))}
          </Stack>
          {visibleUsersStart + visibleChipsCount < onlineUsersList.length && (
            <IconButton
              className="nav-button next"
              onClick={() => handleNavigateUsers('next')}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          )}
        </div>
      </div>
    );
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleTyping = () => {
    if (!isTyping && socket && activeChatId) {
      setIsTyping(true);
      socket.emit('typing_start', { conversationId: activeChatId });
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeout = setTimeout(() => {
      setIsTyping(false);
      socket.emit('typing_end', { conversationId: activeChatId });
    }, 1000);

    setTypingTimeout(timeout);
  };

const handleFileSelect = async (e) => {
  const selectedFiles = Array.from(e.target.files);
  const maxFileSize = 5 * 1024 * 1024; // 5MB
  
  // Validar tamaño y tipo de archivos
  const validFiles = selectedFiles.filter(file => {
    if (file.size > maxFileSize) {
      showNotification({
        message: `El archivo ${file.name} excede el tamaño máximo permitido de 5MB`,
        severity: 'error'
      });
      return false;
    }
    return true;
  });

  // Crear previews y preparar para envío
  const filesWithPreviews = await Promise.all(validFiles.map(async (file) => {
    let preview = null;
    // Solo crear preview para imágenes
    if (file.type.startsWith('image/')) {
      preview = URL.createObjectURL(file);
    }
    return { 
      file,
      preview,
      // Agregamos metadata adicional que puede ser útil
      metadata: {
        type: file.type,
        size: file.size,
        lastModified: file.lastModified
      }
    };
  }));

  setFiles(filesWithPreviews);
};
  const removeFile = (index) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ((!newMessage.trim() && files.length === 0) || (!activeChatId && !selectedRecipient)) {
      return;
    }

    try {
      if (editingMessage) {
        await editMessage({
          messageId: editingMessage.id,
          content: newMessage.trim()
        });
        setEditingMessage(null);
      } else {
        await sendMessage(
          newMessage.trim(),
          files,
          replyTo?.id
        );
      }

      setNewMessage('');
      setFiles([]);
      setReplyTo(null);
    } catch (error) {
      console.error('Error al enviar mensaje:', error);
    }
  };
  const getConversationHeader = () => {
    if (activeChatId === 'global') {
      return 'Chat Global';
    }
    if (selectedRecipient) {
      return `Chat con ${selectedRecipient.first_name} ${selectedRecipient.first_last_name}`;
    }
    return messages?.conversationName || 'Nueva conversación';
  };

  const renderTypingIndicator = () => {
    const typingUsersList = typingUsers[activeChatId] || [];
    if (typingUsersList.length === 0) return null;

    const typingUsersText = typingUsersList
      .filter(id => id !== user.id)
      .map(id => {
        const typingUser = messages?.participants?.find(p => p.id === id);
        return typingUser ? `${typingUser.first_name}` : '';
      })
      .filter(Boolean)
      .join(', ');

    if (!typingUsersText) return null;

    return (
      <div className="typing-indicator">
        {`${typingUsersText} ${typingUsersList.length > 1 ? 'están' : 'está'} escribiendo...`}
      </div>
    );
  };

  if (!activeChatId && !selectedRecipient) {
    return (
      <div className="chat-container">
        <div className="no-chat-selected">
          <h2>Selecciona una conversación para comenzar a chatear</h2>
        </div>
      </div>
    );
  }
  const renderMessages = () => {
    // Para el chat global
    if (activeChatId === 'global') {
      return messages?.map((message) => (
        <ChatMessage
          key={message.id}
          message={message}
          currentUser={user}
          onReply={() => setReplyTo(message)}
          onEdit={() => {
            setEditingMessage(message);
            setNewMessage(message.content);
          }}
          onDelete={() => deleteMessage(message.id)}
        />
      ));
    }
  
    // Para conversaciones privadas
    return messages?.map((message) => (
      <ChatMessage
        key={message.id}
        message={message}
        currentUser={user}
        onReply={() => setReplyTo(message)}
        onEdit={() => {
          setEditingMessage(message);
          setNewMessage(message.content);
        }}
        onDelete={() => deleteMessage(message.id)}
      />
    ));
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
      {activeChatId === 'global' ? renderGlobalChatHeader() : (
          <h3>{getConversationHeader()}</h3>
        )}
      </div>

      <div className="chat-body">
        {renderMessages()}
        {renderTypingIndicator()}
        <div ref={messagesEndRef} />
      </div>

      {replyTo && (
        <ReplyPreview
          message={replyTo}
          onClose={() => setReplyTo(null)}
        />
      )}

      {files.length > 0 && (
        <AttachmentPreview
          files={files}
          onRemove={removeFile}
        />
      )}

      <div className="chat-footer">
        <form onSubmit={handleSubmit}>
          <div className="message-input-container">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
                handleTyping();
              }}
              placeholder={editingMessage ? "Editar mensaje..." : "Escribe un mensaje..."}
            />
            <div className="input-actions">
              <button
                type="button"
                className="attachment-button"
                onClick={() => fileInputRef.current?.click()}
              >
                <img src={ArchiveIcon} alt="Icon Archive" />
              </button>
              <input
                ref={fileInputRef}
                type="file"
                multiple
                onChange={handleFileSelect}
                style={{ display: 'none' }}
              />
              <button type="submit">
                {editingMessage ?<img src={EditIcon} alt="Icon edit" /> : <img src={SendIcon} alt="Icon Send" />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}