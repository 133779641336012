import React, { useEffect, useState } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import { PERSON_ROUTES } from "../../routes/routes.config";
import MainPersonContent from "./views_persons/MainPersonContent";
import ViewRRHHContent from "./views_persons/ViewRRHHContent";
import ViewThirdPartyContent from "./views_persons/ViewThirdPartyContent";
import ViewOperatorContent from "./views_persons/ViewOperatorContent";
import ViewAdminContent from "./views_persons/ViewAdminContent";
import ViewProfileContent from "./views_persons/ViewProfileContent";
import ProfileUser from "../ProfileUser";
import PropTypes from "prop-types";
import "../../assets/scss/views/Persons.scss";

// También si usas roles:
import { useGetRoles } from "../../hooks/useReactQuery";
import RegisterSection from "../auth/section/RegisterSection";
import ViewOperationsAssistant from "./views_persons/ViewOperationsAssistant";
import ViewOwners from "./views_persons/ViewOwners";
import { SettingPerson } from "../human_resources/view_human_resources/SettingPerson";
import { useLayout } from "../../context/LayoutContext";
import LoadingAnimation from "../../components/loading/LoadingAnimation";

export default function Persons({ routeConfig }) {
  const location = useLocation();
  const { cc, id } = useParams();
  const { setHeaderTitle, isHeaderVisible, theme, setIsHeaderVisible } =
    useLayout();
  const [lastScrollY, setLastScrollY] = useState(0);

  // (Opcional) Manejo de roles
  const { data, isLoading, error } = useGetRoles();
  const roles = data?.data || [];

  // Detectamos rutas dinámicas específicas
  const isRoleRequiredRoute = location.pathname.includes("add/user") && id;
  const isProfileRoute = location.pathname.includes("profile") && id;

  // Buscamos el rol
  const role = isRoleRequiredRoute
    ? roles.find((r) => r.id.toString() === id)
    : null;
  const roleName = isRoleRequiredRoute
    ? role
      ? role.name
      : "Rol no encontrado"
    : null;

  // -------------------------------------------------------------
  // Título del header
  // -------------------------------------------------------------
  const getHeaderTitle = () => {
    if (!routeConfig) {
      // Coincidencia exacta
      let currentRoute = Object.values(PERSON_ROUTES).find(
        (route) => route.path === location.pathname
      );

      // Coincidencia dinámica
      if (!currentRoute) {
        currentRoute = Object.values(PERSON_ROUTES).find((route) => {
          const dynamicPathCC = route.path.replace(/:cc/, cc || "");
          const dynamicPathID = route.path.replace(/:id/, id || "");
          return (
            location.pathname === dynamicPathCC ||
            location.pathname === dynamicPathID
          );
        });
      }

      return currentRoute?.title || "Personas";
    }
    return routeConfig.title;
  };
  const headerTitle = getHeaderTitle();
  // -------------------------------------------------------------
  // Breadcrumbs
  // -------------------------------------------------------------
  const getNavigationParts = () => {
    // Ruta principal
    if (location.pathname === PERSON_ROUTES.main.path) {
      return ["Personas"];
    }

    // RUTA NUEVA DE PERFIL
    if (isProfileRoute) {
      // Subtítulo de profile: "Personas/{id}"
      return PERSON_ROUTES.profile.subtitle.split("/").map((part) => {
        if (part === "{id}") return "Perfil";
        return part;
      });
    }

    // Ruta de agregar usuario
    if (isRoleRequiredRoute) {
      return PERSON_ROUTES.addUser.subtitle.split("/").map((part) => {
        if (part === "{id}") {
          return roleName || id;
        }
        return part;
      });
    }

    // Resto de rutas con "administrators", "operators", "third-parties", "RRHH"
    let routeType = "";
    if (location.pathname.includes("administrators")) {
      routeType = "administrators";
    } else if (location.pathname.includes("operators")) {
      routeType = "operators";
    } else if (location.pathname.includes("ADOS")) {
      routeType = "ADOS";
    } else if (location.pathname.includes("owners")) {
      routeType = "ADOS";
    } else if (location.pathname.includes("third-parties")) {
      routeType = "thirdParties";
    } else if (location.pathname.includes("RRHH")) {
      routeType = "rrhh";
    }

    const baseRoute = PERSON_ROUTES[routeType];
    let detailRoute;
    if (routeType === "rrhh") {
      detailRoute = PERSON_ROUTES.rrhhPerson;
    } else if (routeType === "thirdParties") {
      detailRoute = PERSON_ROUTES.thirdParty;
    } else if (routeType === "operators") {
      detailRoute = PERSON_ROUTES.operator;
    } else if (routeType === "owners") {
      detailRoute = PERSON_ROUTES.operator;
    } else if (routeType === "ADOS") {
      detailRoute = PERSON_ROUTES.ADOS;
    } else if (routeType === "administrators") {
      detailRoute = PERSON_ROUTES.administrator;
    }

    if (cc && detailRoute) {
      return detailRoute.subtitle.split("/").map((part) => {
        return part === "{cc}" ? cc : part;
      });
    }

    if (baseRoute) {
      return baseRoute.subtitle
        ? baseRoute.subtitle.split("/")
        : ["Personas", baseRoute.title];
    }

    // Fallback
    const parts = location.pathname.split("/");
    return ["Personas", parts[parts.length - 1]];
  };

  // -------------------------------------------------------------
  // Ruta anterior
  // -------------------------------------------------------------
  const getPreviousPath = () => {
    // Perfil -> vuelve a PERSON_ROUTES.main o donde quieras
    if (isProfileRoute) {
      return PERSON_ROUTES.main.path;
    }
    if (isRoleRequiredRoute) {
      return PERSON_ROUTES.main.path;
    }
    if (cc) {
      if (location.pathname.includes("administrators")) {
        return PERSON_ROUTES.administrators.path;
      } else if (location.pathname.includes("operators")) {
        return PERSON_ROUTES.operators.path;
      } else if (location.pathname.includes("ADOS")) {
        return PERSON_ROUTES.ADOS.path;
      } else if (location.pathname.includes("owners")) {
        return PERSON_ROUTES.owners.path;
      } else if (location.pathname.includes("third-parties")) {
        return PERSON_ROUTES.thirdParties.path;
      } else if (location.pathname.includes("RRHH")) {
        return PERSON_ROUTES.rrhh.path;
      }
    }

    const parts = location.pathname.split("/");
    return parts.slice(0, -1).join("/") || "/";
  };

  // -------------------------------------------------------------
  // Contenido según la ruta
  // -------------------------------------------------------------
  const getRouteSpecificContent = () => {
    if (isLoading) return <LoadingAnimation />;
    if (error) return <div>Error al cargar los roles</div>;

    if (isRoleRequiredRoute && !role) {
      return <div>El rol no se encuentra vigente.</div>;
    }

    // NUEVA RUTA DE PERFIL
    if (isProfileRoute) {
      return <ProfileUser id={id} />; // <-- Componente de perfil a tu gusto
    }

    // RUTA ADD USER
    if (location.pathname.includes("add/user") && id) {
      return <RegisterSection id={id} />;
    }

    // RUTAS DINÁMICAS POR cc
    if (cc) {
      return <ViewProfileContent />;
    }

    // RUTAS ESTÁTICAS
    switch (location.pathname) {
      case PERSON_ROUTES.administrators.path:
        return <ViewAdminContent />;
      case PERSON_ROUTES.operators.path:
        return <ViewOperatorContent />;
      case PERSON_ROUTES.ADOS.path:
        return <ViewOperationsAssistant />;
      case PERSON_ROUTES.owners.path:
        return <ViewOwners />;
      case PERSON_ROUTES.thirdParties.path:
        return <ViewThirdPartyContent />;
      case PERSON_ROUTES.setting.path:
        return <SettingPerson />;
      case PERSON_ROUTES.rrhh.path:
        return <ViewRRHHContent />;
      default:
        return <MainPersonContent />;
    }
  };

  // -------------------------------------------------------------
  // Render principal
  // -------------------------------------------------------------
  const navigationParts = getNavigationParts();
  const isMainRoute = location.pathname === PERSON_ROUTES.main.path;
  useEffect(() => {
    setHeaderTitle(headerTitle);
  }, [headerTitle, setHeaderTitle]);
  useEffect(() => {
    const handleScroll = (event) => {
      const container = event.target;
      const currentScrollY = container.scrollTop;
      const isScrollingUp = currentScrollY < lastScrollY;

      // Constantes para los umbrales
      const SCROLL_THRESHOLD = 100;
      const SHOW_HEADER_THRESHOLD = 50;

      if (!isScrollingUp && currentScrollY > SCROLL_THRESHOLD) {
        setIsHeaderVisible(false);
      } else if (currentScrollY <= SHOW_HEADER_THRESHOLD) {
        setIsHeaderVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    // Optimización con debounce
    const debouncedHandleScroll = debounce(handleScroll, 16);

    // Importante: Actualizamos el selector para trabajar con cualquier tema
    const contentContainers = document.querySelectorAll(
      '[class*="-persons-content"]'
    );

    contentContainers.forEach((container) => {
      container.addEventListener("scroll", debouncedHandleScroll, {
        passive: true,
      });
    });

    return () => {
      contentContainers.forEach((container) => {
        container.removeEventListener("scroll", debouncedHandleScroll);
      });
    };
  }, [lastScrollY, setIsHeaderVisible]);
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  return (
    <div className={`GM__${theme}-persons-container`}>
      <header
        className={`GM__${theme}-persons-header ${
          !isHeaderVisible ? "header-hidden" : ""
        }`}
      >
        <h1 className={`GM__${theme}-persons-title`}>{getHeaderTitle()}</h1>

        <nav className={`GM__${theme}-persons-navigation`}>
          {isMainRoute ? (
            <span className={`GM__${theme}-navigation-item-current`}>
              {navigationParts[0]}
            </span>
          ) : (
            <>
              {navigationParts.map((part, index) => (
                <React.Fragment key={`${index}-${part}`}>
                  {index > 0 && (
                    <span className={`GM__${theme}-navigation-separator`}>
                      /
                    </span>
                  )}
                  {index === navigationParts.length - 1 ? (
                    <span className={`GM__${theme}-navigation-item-current`}>
                      {part}
                    </span>
                  ) : (
                    <Link
                      to={
                        index === 0
                          ? PERSON_ROUTES.main.path
                          : getPreviousPath()
                      }
                      className={`GM__${theme}-navigation-item-clickable`}
                    >
                      {part}
                    </Link>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </nav>

        <div className={`GM__${theme}-header-divider`} />
      </header>

      <main
        className={`GM__${theme}-persons-content ${
          !isHeaderVisible ? "header-hidden" : ""
        }`}
      >
        {getRouteSpecificContent()}
      </main>
    </div>
  );
}

Persons.propTypes = {
  routeConfig: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};
