import React, { useState, useEffect, useCallback } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button,
  Typography,
  Box,
  IconButton,
  Divider
} from '@mui/material';
import { 
  PauseCircle as PauseIcon, 
  PlayCircle as PlayIcon,
  Close as CloseIcon 
} from '@mui/icons-material';
import '../../../assets/scss/components/ui/modal/ResponseModal.scss';

const ResponseModal = ({ 
  open, 
  onClose, 
  responseData, 
  autoCloseTime = 15 
}) => {
  const [timeRemaining, setTimeRemaining] = useState(autoCloseTime);
  const [isPaused, setIsPaused] = useState(false);

  // Manejador sofisticado para el cierre automático del modal
  const handleAutoClose = useCallback(() => {
    if (timeRemaining === 0) {
      onClose();
    }
  }, [timeRemaining, onClose]);

  // Sistema de cuenta regresiva con manejo de pausa
  useEffect(() => {
    let timer;
    if (open && !isPaused && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prev) => {
          const newTime = prev - 1;
          return newTime;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [open, isPaused, timeRemaining]);

  // Efecto para manejar el cierre automático
  useEffect(() => {
    handleAutoClose();
  }, [timeRemaining, handleAutoClose]);

  // Renderizado condicional del contenido de duplicados
  const renderDuplicateContent = () => {
    if (!responseData?.data?.duplicateRecords) {
      return null;
    }

    return (
      <Box className="duplicate-records-container">
        <Typography variant="h6" color="warning.main" gutterBottom>
          Registros Duplicados Encontrados:
        </Typography>
        {Object.entries(responseData.data.duplicateRecords).map(([placa, fechas]) => (
          <Box key={placa} className="duplicate-record-item">
            <Typography variant="subtitle1" fontWeight="bold">
              Placa: {placa}
            </Typography>
            <Typography variant="body2">
              Fechas duplicadas: {fechas.join(', ')}
            </Typography>
            <Divider />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className="response-modal"
    >
      <DialogTitle className="modal-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">
            Resultado del Procesamiento
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton 
              onClick={() => setIsPaused(!isPaused)}
              color="primary"
              size="small"
            >
              {isPaused ? <PlayIcon /> : <PauseIcon />}
            </IconButton>
            <Typography variant="body2">
              Cerrando en: {timeRemaining}s
            </Typography>
            <IconButton 
              onClick={onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className="modal-content">
        <Typography variant="body1" gutterBottom>
          {responseData?.message}
        </Typography>
        {renderDuplicateContent()}
      </DialogContent>
      <DialogActions className="modal-actions">
        <Button onClick={onClose} variant="contained">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResponseModal;