import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import UploadSuccessIcon from "../../../assets/svg/view/upload_success.svg";
import SaveIcon from "../../../assets/svg/components/save.svg";
import LoadingIcon from "../../../assets/svg/view/loading.svg";
import LoadingLightIcon from "../../../assets/svg/view/loading_light.svg";
import CalendarIcon from "../../../assets/svg/components/calendar.svg";
import CalendarLightIcon from "../../../assets/svg/components/calendar_light.svg";
import "../../../assets/scss/components/ui/tables/DocumentsSectionAddPersons.scss";
import CustomDatePicker from "../CustomDatePicker";
import CustomInputField from "../inputs/CustomInputField";
import TrashIcon from "../../../assets/svg/components/trash.svg";
import SeeIcon from "../../../assets/svg/components/see.svg";
import UpdatedIcon from "../../../assets/svg/components/updated.svg";
import ComentIcon from "../../../assets/svg/components/coment.svg";
import { Tooltip } from "@mui/material";
import LoadingAnimation from "../../loading/LoadingAnimation";
import ArrowUpIcon from "../../../assets/svg/view/arrow_up.svg";
import {
  NotificationSeverity,
  useNotification,
} from "../../../context/NotificationContext";
import { useQueryClient } from "@tanstack/react-query";
import { useCommentsAndLocations } from "../../../hooks/commentsAndResidenceQuery";
import CommentsLocationsDrawer from "../drawner/CommentsLocationsDrawer";

export default function DocumentsSectionProfile({
  documents,
  onDocumentsChange,
  dataTypes,
  isLoading,
  canEdit,
  onPreview,
  onUpdate,
  onDelete,
  onAddDocument,
  theme,
}) {
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();
  const {
    useCommentsByReference,
    createComment,
    updateComment,
    deleteComment,
  } = useCommentsAndLocations();
  // Estado para el drawer de comentarios
  const [commentDrawerOpen, setCommentDrawerOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  // Obtener comentarios del documento seleccionado
  const { data: documentComments } = useCommentsByReference(
    selectedDocumentId || ""
  );

  // Handlers para comentarios
  const handleOpenComments = (docId) => {
    setSelectedDocumentId(docId);
    setCommentDrawerOpen(true);
  };

  const handleCloseComments = () => {
    setCommentDrawerOpen(false);
  };

  const handleCreateComment = async (commentData) => {
    try {
      await createComment({ ...commentData, reference_id: selectedDocumentId });
      queryClient.invalidateQueries(["comments", selectedDocumentId]);
      showNotification({
        message: "Comentario creado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (error) {
      showNotification({
        message:
          error.response?.data?.message || "Error al crear el comentario",
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  const handleUpdateComment = async (commentId, commentData) => {
    try {
      await updateComment({
        commentId,
        data: { ...commentData, reference_id: selectedDocumentId },
      });
      queryClient.invalidateQueries(["comments", selectedDocumentId]);
      showNotification({
        message: "Comentario actualizado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (error) {
      showNotification({
        message:
          error.response?.data?.message || "Error al actualizar el comentario",
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteComment(commentId);
      queryClient.invalidateQueries(["comments", selectedDocumentId]);
      showNotification({
        message: "Comentario eliminado exitosamente",
        severity: NotificationSeverity.SUCCESS,
      });
    } catch (error) {
      showNotification({
        message:
          error.response?.data?.message || "Error al eliminar el comentario",
        severity: NotificationSeverity.ERROR,
      });
    }
  };

  const handleFileSelect = (typeId, docId, event) => {
    const file = event.target.files[0];
    if (file) {
      // Actualizar los campos del archivo
      handleUpdateDocument(typeId, docId, "file", file);
    }
  };

  const handleUpdateDocument = (typeId, docId, field, value) => {
    // Mapeo de campos para mantener consistencia entre modelos
    const isLocalDoc = docId.startsWith("temp-");
    let updatedField = field;

    if (field === "original_name_description") {
      // Enviamos la acción con el campo correcto según sea documento local o no
      onDocumentsChange({
        type: "UPDATE_DOCUMENT",
        payload: {
          typeId,
          docId,
          field: isLocalDoc ? "description" : "original_name_description",
          value,
        },
      });

      // Para documentos locales, actualizamos también el campo original para mantener consistencia
      if (isLocalDoc) {
        onDocumentsChange({
          type: "UPDATE_DOCUMENT",
          payload: { typeId, docId, field: "original_name_description", value },
        });
      }

      return;
    }

    // Para el resto de campos, comportamiento normal
    onDocumentsChange({
      type: "UPDATE_DOCUMENT",
      payload: { typeId, docId, field: updatedField, value },
    });
  };

  const handleAddDocument = (typeId) => {
    if (!canEdit) return;

    // Crear un documento temporal con estructura híbrida compatible con ambos modelos
    const tempDoc = {
      id: `temp-${Date.now()}`,
      file_name: "",
      fileName: "",
      file: null,
      expedition_date: null,
      expeditionDate: null,
      expiration_date: null,
      expirationDate: null,
      original_name_description: "",
      description: "",
      document_type: {
        id: typeId,
        name: dataTypes.find((type) => type.id === typeId)?.name || "",
      },
      docType: String(typeId),
      required: true,
      isNew: true,
      isLocal: true,
    };

    // Agregar el documento al estado
    onAddDocument(tempDoc);
  };
  const handleLocalDelete = (doc) => {
    // Identificamos el tipo de documento
    const typeId = doc.document_type?.id || doc.docType;

    // Eliminar documento local directamente sin ir al backend
    onDocumentsChange({
      type: "DELETE_LOCAL_DOCUMENT",
      payload: {
        typeId,
        docId: doc.id,
      },
    });
  };
  const handleSaveNewDocument = (doc) => {
    // Validación del archivo
    if (!doc.file) {
      onDocumentsChange({
        type: "VALIDATION_ERROR",
        payload: {
          message: "Debe seleccionar un archivo para guardar el documento",
        },
      });
      return;
    }

    // Validación de descripción
    if (!doc.original_name_description && !doc.description) {
      onDocumentsChange({
        type: "VALIDATION_ERROR",
        payload: {
          message: "La descripción del documento es obligatoria",
        },
      });
      return;
    }

    // Validaciones para documentos marcados como requeridos
    if (doc.required) {
      // Validar fecha de expedición
      if (!doc.expedition_date && !doc.expeditionDate) {
        onDocumentsChange({
          type: "VALIDATION_ERROR",
          payload: {
            message:
              "La fecha de expedición es obligatoria para documentos requeridos",
          },
        });
        return;
      }

      // Validar fecha de vencimiento
      if (!doc.expiration_date && !doc.expirationDate) {
        onDocumentsChange({
          type: "VALIDATION_ERROR",
          payload: {
            message:
              "La fecha de vencimiento es obligatoria para documentos requeridos",
          },
        });
        return;
      }
    }

    // Normalizar la estructura del documento (mantén el File original)
    const documentToUpload = {
      id: doc.id,
      fileName: doc.fileName || doc.file_name || doc.file.name,
      file: doc.file, // Mantener el objeto File original
      description: doc.original_name_description || doc.description || "",
      docType: String(doc.document_type?.id || doc.docType),
      expeditionDate: doc.expedition_date || doc.expeditionDate || null,
      expirationDate: doc.expiration_date || doc.expirationDate || null,
      required: Boolean(doc.required),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    // Enviar documento normalizado sin anidarlo en document
    onDocumentsChange({
      type: "ADD_DOCUMENT",
      payload: documentToUpload,
    });
  };
  const renderActionButtons = useCallback(
    (doc) => {
      if (!canEdit) return null;

      const isLocalDoc = doc.id?.startsWith("temp-") || doc.isLocal;

      const tooltipProps = {
        arrow: true,
        placement: "top",
        enterDelay: 500,
        leaveDelay: 200,
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: "rgba(33, 33, 33, 0.9)",
            color: "#ffffff",
            fontSize: "0.75rem",
            padding: "6px 12px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
          },
          "& .MuiTooltip-arrow": {
            color: "rgba(33, 33, 33, 0.9)",
          },
        },
      };

      return (
        <div className={`GM__${theme}-action-buttons-container`}>
          {!isLocalDoc &&
            (doc.file_name?.toLowerCase().endsWith(".pdf") ||
              doc.file_name
                ?.toLowerCase()
                .match(/\.(jpe?g|png|gif|webp)$/)) && (
              <Tooltip title="Previsualizar documento" {...tooltipProps}>
                <button
                  className={`GM__${theme}-preview-btn`}
                  onClick={() => onPreview(doc)}
                >
                  <img
                    src={SeeIcon}
                    alt="Previsualizar"
                    className={`GM__${theme}-action-icon`}
                  />
                </button>
              </Tooltip>
            )}

          <Tooltip
            title={isLocalDoc ? "Guardar documento" : "Actualizar documento"}
            {...tooltipProps}
          >
            <button
              className={
                isLocalDoc ? `GM__${theme}-save-btn` : `GM__${theme}-update-btn`
              }
              onClick={() =>
                isLocalDoc ? handleSaveNewDocument(doc) : onUpdate(doc)
              }
            >
              <img
                src={isLocalDoc ? SaveIcon : UpdatedIcon}
                alt={isLocalDoc ? "Guardar" : "Actualizar"}
                className={`GM__${theme}-action-icon`}
              />
            </button>
          </Tooltip>

          <Tooltip
            title={isLocalDoc ? "Eliminar entrada" : "Eliminar documento"}
            {...tooltipProps}
            sx={{
              ...tooltipProps.sx,
              "& .MuiTooltip-tooltip": {
                ...tooltipProps.sx["& .MuiTooltip-tooltip"],
                backgroundColor: "rgba(211, 47, 47, 0.9)",
              },
              "& .MuiTooltip-arrow": {
                color: "rgba(211, 47, 47, 0.9)",
              },
            }}
          >
            <button
              className={`GM__${theme}-delete-btn`}
              onClick={() =>
                isLocalDoc
                  ? handleLocalDelete(doc)
                  : onDelete({
                      id: doc.id,
                      name:
                        doc.original_name_description ||
                        doc.description ||
                        doc.file_name ||
                        "Documento sin nombre",
                    })
              }
            >
              <img
                src={TrashIcon}
                alt="Eliminar"
                className={`GM__${theme}-action-icon`}
              />
            </button>
          </Tooltip>

          <Tooltip
            title="Comentario del documento"
            {...tooltipProps}
            sx={{
              ...tooltipProps.sx,
              "& .MuiTooltip-tooltip": {
                ...tooltipProps.sx["& .MuiTooltip-tooltip"],
                backgroundColor: "rgb(22, 64, 213)",
              },
              "& .MuiTooltip-arrow": {
                color: "rgba(211, 47, 47, 0.9)",
              },
            }}
          >
            <button
              className={`GM__${theme}-coment-btn`}
              onClick={() => handleOpenComments(doc.id)}
            >
              <img
                src={ComentIcon}
                alt="Comentar"
                className={`GM__${theme}-action-icon`}
              />
            </button>
          </Tooltip>
        </div>
      );
    },
    [
      canEdit,
      onPreview,
      onUpdate,
      onDelete,
      onDocumentsChange,
      handleOpenComments,
      theme, // Añade theme como dependencia
    ]
  );
  if (isLoading) return <LoadingAnimation />;
  const dateIconSrc = theme === "dark" ? CalendarLightIcon : CalendarIcon;
  const loadingIconSrc = theme === "dark" ? LoadingLightIcon : LoadingIcon;
  return (
    <div className={`GM__${theme}-documents-section`}>
      {dataTypes.map((type) => {
        const typeDocuments = documents.filter((doc) => {
          // Soporta tanto la estructura anidada como la plana
          const docTypeId = doc.document_type?.id || doc.document_type_id;
          return docTypeId === type.id;
        });

        if (typeDocuments.length === 0 && !canEdit) return null;

        return (
          // Agrega clases de tema a cada componente anidado
          <div key={type.id} className={`GM__${theme}-document-type-table`}>
            <div className={`GM__${theme}-table-header`}>
              <h3>{type.name}</h3>
              <p>{type.description}</p>
              {canEdit && (
                <button
                  className={`GM__${theme}-add-document-btn`}
                  onClick={() => handleAddDocument(type.id)}
                >
                  <img src={ArrowUpIcon} alt="Arrow Icon" />
                </button>
              )}
            </div>

            <table className={`GM__${theme}-table`}>
              <thead>
                <tr>
                  <th>Estado</th>
                  <th>Archivo</th>
                  <th>Fecha de Expedición</th>
                  <th>Fecha de Vencimiento</th>
                  <th>Nombre/Descripción</th>
                  <th>Requerido</th>
                  {canEdit && <th>Acciones</th>}
                </tr>
              </thead>
              <tbody>
                {typeDocuments.length > 0 ? (
                  typeDocuments.map((doc) => (
                    <tr
                      key={doc.id}
                      className={`${
                        doc.isEmpty ? `GM__${theme}-empty-document` : ""
                      }`}
                    >
                      <td>
                        <img
                          src={
                            doc.isNew || doc.isLocal
                              ? doc.file
                                ? UploadSuccessIcon
                                : loadingIconSrc
                              : doc.file_path
                              ? UploadSuccessIcon
                              : loadingIconSrc
                          }
                          alt={
                            doc.isNew || doc.isLocal
                              ? doc.file
                                ? "Documento local listo para subir"
                                : "Pendiente de selección"
                              : doc.file_path
                              ? "Documento cargado"
                              : "Pendiente"
                          }
                          className={`GM__${theme}-status-icon`}
                        />
                      </td>

                      <td>
                        {canEdit ? (
                          <div className={`GM__${theme}-file-upload`}>
                            <input
                              type="file"
                              onChange={(e) =>
                                handleFileSelect(
                                  doc.document_type?.id || doc.docType,
                                  doc.id,
                                  e
                                )
                              }
                              accept=".pdf,.jpg,.jpeg,.png"
                              id={`file-${doc.id}`}
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor={`file-${doc.id}`}
                              className={`GM__${theme}-file-label`}
                            >
                              {doc.file
                                ? doc.file.name
                                : doc.original_filename ||
                                  doc.file_name ||
                                  doc.fileName ||
                                  "Seleccionar archivo"}
                            </label>
                          </div>
                        ) : (
                          <span className={`GM__${theme}-file-name`}>
                            {doc.file_name || doc.fileName || "Sin archivo"}
                          </span>
                        )}
                      </td>
                      <td>
                        <CustomDatePicker
                          value={doc.expedition_date}
                          onChange={(date) =>
                            handleUpdateDocument(
                              type.id,
                              doc.id,
                              "expedition_date",
                              date
                            )
                          }
                          icon={<img src={dateIconSrc} alt="Calendar" />}
                          disabled={!canEdit}
                          className={`GM__${theme}-date-picker`}
                          theme={theme}
                        />
                      </td>
                      <td>
                        <CustomDatePicker
                          value={doc.expiration_date}
                          onChange={(date) =>
                            handleUpdateDocument(
                              type.id,
                              doc.id,
                              "expiration_date",
                              date
                            )
                          }
                          icon={<img src={dateIconSrc} alt="Calendar" />}
                          disabled={!canEdit}
                          className={`GM__${theme}-date-picker`}
                          theme={theme}
                        />
                      </td>
                      <td>
                        <CustomInputField
                          type="text"
                          value={doc.original_name_description}
                          onChange={(e) =>
                            handleUpdateDocument(
                              type.id,
                              doc.id,
                              "original_name_description",
                              e.target.value
                            )
                          }
                          placeholder="Ej. Certificado de curso X"
                          disabled={!canEdit}
                          className={`GM__${theme}-input-field`}
                          theme={theme}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={doc.required}
                          onChange={(e) =>
                            handleUpdateDocument(
                              type.id,
                              doc.id,
                              "required",
                              e.target.checked
                            )
                          }
                          disabled={!canEdit}
                          className={`GM__${theme}-checkbox`}
                        />
                      </td>
                      {canEdit && (
                        <td className={`GM__${theme}-action-buttons`}>
                          <div
                            className={`GM__${theme}-action-buttons-container`}
                          >
                            {renderActionButtons(doc)}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr className={`GM__${theme}-empty-document`}>
                    <td
                      colSpan={canEdit ? 7 : 6}
                      className={`GM__${theme}-no-documents`}
                    >
                      No hay documentos para este tipo
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      })}
      <CommentsLocationsDrawer
        open={commentDrawerOpen}
        onClose={handleCloseComments}
        type="comments"
        data={documentComments?.data || []}
        onEdit={handleUpdateComment}
        onCreate={handleCreateComment}
        onDelete={handleDeleteComment}
        theme={theme}
      />
    </div>
  );
}

DocumentsSectionProfile.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      file_name: PropTypes.string,
      file_path: PropTypes.string,
      expedition_date: PropTypes.string,
      expiration_date: PropTypes.string,
      original_name_description: PropTypes.string,
      document_type: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
      }),
      required: PropTypes.bool,
      uploaded_at: PropTypes.string,
    })
  ).isRequired,
  onDocumentsChange: PropTypes.func.isRequired,
  dataTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      required: PropTypes.bool,
    })
  ),
  isLoading: PropTypes.bool,
  canEdit: PropTypes.bool,
  onPreview: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAddDocument: PropTypes.func,
  theme: PropTypes.oneOf(["light", "dark"]),
};
