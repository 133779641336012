import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { userService } from "../../../service";
import "./RegisterSection.scss";
import {
  useNotification,
  NotificationSeverity,
  NotificationPositions,
} from "../../../context/NotificationContext";
import MainSectionAddPersons from "../../../components/persons/MainSectionAddPersons";
import ContactSectionAddPersons from "../../../components/persons/ContactSectionAddPersons";
import DocumentsSectionAddPersons from "../../../components/persons/DocumentsSectionAddPersons";

import { validateContactInfo, validateDocuments, validatePersonalInfo } from "../../../utils/validations/registrationValidations";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useDocumentTypesByRole } from "../../../hooks/useFileQuery";
import { useLayout } from "../../../context/LayoutContext";
// Función auxiliar para manejar fechas
const parseDateValue = (value) => {
  if (!value) return null;
  const parsed = dayjs(value);
  return parsed.isValid() ? parsed : null;
};

export default function RegisterSection({ id }) {
  const { theme } = useLayout();
  const {
    documentTypes,
    isLoading,
  } = useDocumentTypesByRole(id);
  const profilePhotoType = documentTypes.find(type => 
    type.name.toLowerCase() === 'foto de perfil'
  );

  const { showNotification } = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  // Datos personales
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    firstLastName: "",
    secondLastName: "",
    birthDate: null,
    documentNumber: "",
    documentExpDate: null,
    documentTypeId: "",
    documentExpeditionPlace: "",
    nationality: "",
    profilePhoto: null,
    profilePhotoType: null,
    limitedNotifications: true,
    ownFleet: true
  });
  // Informacion de contacto
  const [contactInfo, setContactInfo] = useState({
    primaryPhoneNumber: "",
    primaryPhoneCountryId: "",
    secondaryPhoneNumber: "",
    secondaryPhoneCountryId: "",
    email: "",
    contactName: "",
    location: null
  });

  const documentsReducer = (state, action) => {
    switch (action.type) {
      case 'ADD_DOCUMENT':
        return {
          ...state,
          items: [...state.items, {
            ...action.payload.document,
            docType: String(action.payload.document.docType),
            createdAt: new Date().toISOString()
          }]
        };
      
      case 'UPDATE_DOCUMENT':
        return {
          ...state,
          items: state.items.map(doc => 
            doc.id === action.payload.docId
              ? {
                  ...doc,
                  [action.payload.field]: action.payload.field === 'docType' 
                    ? String(action.payload.value) 
                    : action.payload.value,
                  updatedAt: new Date().toISOString()
                }
              : doc
          )
        };
      
      case 'DELETE_DOCUMENT':
        return {
          ...state,
          items: state.items.filter(doc => doc.id !== action.payload.docId),
          deletedItems: [...(state.deletedItems || []), action.payload.docId]
        };
      
      default:
        return state;
    }
  };
  // Documentos
  const [documents, dispatchDocuments] = useReducer(documentsReducer, {
    items: [],
    types: []
  });

  // =================================
  // Handlers para inputs
  // =================================
  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prev) => ({ ...prev, [name]: value }));
    setHasChanges(true);
  };

  const handleContactInfoChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleLocationSelect = (locationData) => {
    setContactInfo((prev) => ({ ...prev, location: locationData }));
  };


  // Manejador mejorado para cambios en fechas personales
  const handlePersonalDateChange = (field, date) => {
    const parsedDate = parseDateValue(date);
    setPersonalInfo((prev) => ({
      ...prev,
      [field]: parsedDate ? parsedDate.format("YYYY-MM-DD") : null,
    }));
  };
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasChanges]);

  // =================================
  // Submit final
  // =================================
  const validateForm = () => {
    if (!validatePersonalInfo(personalInfo, showNotification)) return false;
    if (!validateContactInfo(contactInfo, showNotification)) return false;
    if (!validateDocuments(documents, documentTypes, showNotification)) return false;
    return true;
  };
  
  const resetForm = () => {
    // Reinicio de información personal
    setPersonalInfo({
      firstName: "",
      firstLastName: "",
      secondLastName: "",
      birthDate: null,
      documentNumber: "",
      documentExpDate: null,
      documentTypeId: "",
      documentExpeditionPlace: "",
      nationality: "",
      profilePhoto: null,
      profilePhotoType: null,
      limitedNotifications: true 
    });
  
    // Reinicio de información de contacto
    setContactInfo({
      primaryPhoneNumber: "",
      primaryPhoneCountryId: "",
      secondaryPhoneNumber: "",
      secondaryPhoneCountryId: "",
      email: "",
      contactName: "",
      location: null
    });
  
    // Reinicio de documentos
    dispatchDocuments({
      type: 'RESET_DOCUMENTS',
      payload: {
        items: [],
        deletedItems: []
      }
    });
  };
  const handleSubmitSuccess = (response) => {
    showNotification({
      message: response.message || "Usuario registrado exitosamente",
      severity: NotificationSeverity.SUCCESS,
      position: NotificationPositions.TOP_RIGHT,
      autoHideDuration: 5000,
    });
    
    resetForm();
    
    if (response.requirePasswordChange) {
      setTimeout(() => {
        showNotification({
          message: "Por favor, cambie su contraseña en el siguiente inicio de sesión",
          severity: NotificationSeverity.INFO,
          position: NotificationPositions.TOP_RIGHT,
          autoHideDuration: 6000,
        });
      }, 1000);
    }
  };
  
  const handleSubmitError = (error) => {
    showNotification({
      message: error.response?.data?.message || `Error en el registro: ${error.message}`,
      severity: NotificationSeverity.ERROR,
      position: NotificationPositions.TOP_RIGHT,
      autoHideDuration: 5000,
    });
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      
      if (!validateForm()) return;
  
      const userData = {
        roleId: id,
        personalInfo,
        contactInfo: {
          ...contactInfo,
        },
        documents: documents.items
      };
  
      const response = await userService.registerUser(userData);
      handleSubmitSuccess(response);
      setHasChanges(false);
    } catch (error) {
      handleSubmitError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // =================================
  // Render
  // =================================
  return (
<div className={`GM__${theme}-register-section-wrapper`}>
  {isSubmitting && <LoadingAnimation />}

  <div className={`GM__${theme}-register-section-container`}>
    {/* Contenido principal */}
    <div className={`GM__${theme}-content-section`}>
      {/* Datos personales (main-section) */}
      <MainSectionAddPersons
        personalInfo={personalInfo}
        onPersonalInfoChange={handlePersonalInfoChange}
        onDateChange={handlePersonalDateChange}
        profilePhotoTypeId={profilePhotoType?.id}
        onProfilePhotoChange={(files) => {
          if (files && files.length > 0) {
            setPersonalInfo(prev => ({
              ...prev,
              profilePhoto: files[0]
            }));
          }
        }}
        theme={theme}
      />

      {/* Información de contacto */}
      <ContactSectionAddPersons
        contactInfo={contactInfo}
        onContactInfoChange={handleContactInfoChange}
        onLocationSelect={handleLocationSelect}
        theme={theme}
      />

      {/* Tabla unificada con TODOS los documentos */}
      <DocumentsSectionAddPersons
        roleId={id}
        documents={documents}
        onDocumentsChange={dispatchDocuments}
        dataTypes={documentTypes}
        isLoading={isLoading}
        theme={theme}
      />

      {/* Botón de guardar */}
      <div className={`GM__${theme}-save-section`}>
        <button 
          className={`GM__${theme}-save-button`} 
          onClick={handleSubmit}
        >
          {isSubmitting ? 'Procesando...' : 'Guardar Información'}
        </button>
      </div>
    </div>
  </div>
</div>
  );
}
RegisterSection.propTypes = {
  id: PropTypes.string,
};
