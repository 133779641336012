import React, { useState, useEffect, useRef } from 'react';
import '../../assets/scss/views/AcercaDeNosotros.scss';
import Component_92 from '../../assets/img/Component_92.svg';
import HistoriaImage from '../../assets/img/fundadores.png';
import ExtractorCrudoSVG from '../../assets/img/martillo_bombeo.png';

const AcercaDeNosotros = () => {
  const [activeTab, setActiveTab] = useState('gente');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [svgPosition, setSvgPosition] = useState('position-center');
  const autoSwitchRef = useRef(null);
  const containerRef = useRef(null);
  
  // Gestión del cambio automático
  useEffect(() => {
    // Configurar cambio automático cada 8 segundos
    autoSwitchRef.current = setInterval(() => {
      setActiveTab(prev => prev === 'gente' ? 'historia' : 'gente');
    }, 800000);
    
    return () => {
      // Limpiar intervalo al desmontar
      if (autoSwitchRef.current) {
        clearInterval(autoSwitchRef.current);
      }
    };
  }, []);
  
  // Ajuste de posición SVG basado en tamaño de ventana
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      
      // Ajustar posición basada en tamaño de ventana
      if (windowWidth >= 1200) {
        setSvgPosition('position-center');
      } else if (windowWidth >= 768) {
        setSvgPosition('position-right');
      } else {
        setSvgPosition('position-center');
      }
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // Ejecutar inmediatamente
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // Manejador de cambio manual de tabs
  const handleTabChange = (tab) => {
    // Cancelar cambio automático si el usuario interactúa
    if (autoSwitchRef.current) {
      clearInterval(autoSwitchRef.current);
    }
    setActiveTab(tab);
    
    // Reiniciar intervalo después de la interacción (8 segundos)
    autoSwitchRef.current = setInterval(() => {
      setActiveTab(prev => prev === 'gente' ? 'historia' : 'gente');
    }, 800000); 
  };
  
  // Manejador de carga de imagen
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="acerca-section" id="acerca-de-nosotros" ref={containerRef}>
      <div className="acerca-container">
        {/* Navegación de tabs */}
        <div className="acerca-tabs">
          <div 
            className={`acerca-tab ${activeTab === 'gente' ? 'active' : ''}`}
            onClick={() => handleTabChange('gente')}
          >
            Nuestra <span className="acerca-highlight">Gente</span>
          </div>
          <div 
            className={`acerca-tab ${activeTab === 'historia' ? 'active' : ''}`}
            onClick={() => handleTabChange('historia')}
          >
            Nuestra <span className="acerca-highlight">Historia</span>
          </div>
        </div>
        
        {/* Contenido de "Nuestra Gente" - Mejorado con container para SVG */}
        <div className={`acerca-content ${activeTab === 'gente' ? 'active' : ''}`}>
          <div className="svg-container">
            <img 
              src={Component_92} 
              alt="Organigrama de Nuestra Gente" 
              className={svgPosition}
              onLoad={handleImageLoad}
              style={{ 
                visibility: imageLoaded ? 'visible' : 'hidden',
                opacity: imageLoaded ? 1 : 0,
              }}
            />
          </div>
        </div>
        
        {/* Contenido de "Nuestra Historia" */}
        <div className={`acerca-content ${activeTab === 'historia' ? 'active' : ''}`}>
          <div className="acerca-historia-container">
            <div className="historia-background">
              <img 
                src={ExtractorCrudoSVG} 
                alt="Extractor de crudo" 
                className="extractor-svg" 
              />
            </div>
            <div className="historia-content">
              <div className="historia-header">
                <h2>Nuestra <span className="acerca-highlight">Historia</span></h2>
              </div>
              <div className="historia-body">
                <div className="historia-image">
                  <img 
                    src={HistoriaImage} 
                    alt="Fundadores de CootransPetrols" 
                  />
                  <div className="image-size">437 × 737</div>
                </div>
                <div className="historia-text">
                  <p>
                    En el año 2005 Villagarzón, Putumayo inicia su proceso de 
                    ingresar en la lista de Municipios petroleros de Colombia, ante 
                    este hecho; varias personas, emprendedora, visionaria y con 
                    deseos de generar desarrollo y progreso en la región, se 
                    organizan en diferentes frentes de trabajo y empresas y así 
                    buscan responder a las necesidades y servicios que esta labor 
                    petrolera requiere. Así nace la Cooperativa Transportadora de 
                    Derivados del Petroleo y Servicios. "<span className="acerca-highlight">Cootrans</span>Petrols".
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcercaDeNosotros;