import { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';
import { useDocumentData } from '../hooks/useDocumentData';
import { useDocumentSocket } from '../hooks/useDocumentSocket';

const DocumentContext = createContext(null);

export const useDocument = () => useContext(DocumentContext);

export const DocumentProvider = ({ children }) => {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [document, setDocument] = useState(null);
  const initStatusRef = useRef({
    isInitializing: false,
    hasInitialized: false,
    processingId: null,
    initPromise: null,
    generatingNewId: false
  });
  // Hook personalizado para interactuar con Redis
  const { 
    fetchDocument, 
    saveDocument, 
    createDocument,
    generatePdf,
    shareDocument
  } = useDocumentData();
  
  // Hook para comunicación por WebSocket
  const { subscribeToDocumentUpdates } = useDocumentSocket();

  // Inicializar o cargar documento
  useEffect(() => {
    // Control para evitar ejecuciones concurrentes durante una misma carga
    if (initStatusRef.current.isInitializing) {
      return;
    }
    
    // Solo para documentos existentes - verificar si ya se procesó este ID
    if (documentId && initStatusRef.current.hasInitialized && 
        documentId === initStatusRef.current.processingId) {
      return;
    }
    
    const initializeDocument = async () => {
      // Establecer bloqueo de inicialización
      initStatusRef.current.isInitializing = true;
      initStatusRef.current.processingId = documentId;
      
      try {
        setLoading(true);
        setError(null);
        
        // CASO 1: Tenemos un ID específico - Intentar cargar documento existente
        if (documentId) {
          
          const doc = await fetchDocument(documentId);
          
          if (doc) {
            setDocument(doc);
            // Actualizar localStorage solo para referencia, no para reutilización
            localStorage.setItem('lastTransportDocId', documentId);
            localStorage.setItem('docAccessTimestamp', Date.now().toString());
          } else {
            setError('El documento solicitado no existe o ha expirado');
          }
        }
        // CASO 2: No tenemos ID - Siempre crear un documento nuevo
        else {
          // Verificar si hay una creación en progreso (control de concurrencia)
          if (initStatusRef.current.generatingNewId) {
            await new Promise(resolve => setTimeout(resolve, 800));
            return; // Salir y dejar que la otra instancia maneje la creación
          }
          
          // Marcar que estamos generando un nuevo ID
          initStatusRef.current.generatingNewId = true;
          const newDocId = uuidv4();
          
          try {
            // Espera deliberada para sincronización
            await new Promise(resolve => setTimeout(resolve, 300));
            
            // Intentar crear documento con nuevo ID
            const result = await createDocument(newDocId, false, {
              clientTimestamp: Date.now(),
              preventDuplication: true,
              forceNew: true // Indicador para backend de que queremos un doc nuevo
            });
            
            setDocument(result);
            
            // Guardar referencia DESPUÉS de crear exitosamente
            localStorage.setItem('lastTransportDocId', newDocId);
            localStorage.setItem('docCreationTimestamp', Date.now().toString());
            
            // Navegación segura con reemplazo para evitar entradas en historial
            navigate(`/transport-calculator/${newDocId}`, { replace: true });
          } catch (createError) {
            console.error('Error en creación de documento:', createError);
            setError('No se pudo crear el documento');
            localStorage.removeItem('lastTransportDocId');
          } finally {
            // Desmarcar la generación
            initStatusRef.current.generatingNewId = false;
          }
        }
        
        initStatusRef.current.hasInitialized = true;
      } catch (err) {
        console.error('Error de inicialización:', err);
        setError(`Error al inicializar: ${err.message || 'Error desconocido'}`);
      } finally {
        setLoading(false);
        initStatusRef.current.isInitializing = false;
      }
    };
    
    // Almacenar promesa para controlar ejecuciones paralelas
    initStatusRef.current.initPromise = initializeDocument();
  }, [documentId, fetchDocument, createDocument, navigate]);
  
  // Suscribirse a actualizaciones por WebSocket
  useEffect(() => {
    if (documentId) {
      const unsubscribe = subscribeToDocumentUpdates(documentId, (updatedDoc) => {
        setDocument(updatedDoc);
      });
      
      return () => unsubscribe();
    }
  }, [documentId, subscribeToDocumentUpdates]);
  
  // Función para actualizar el documento
  const updateDocument = useCallback(async (sectionName, data) => {
    if (!document || !document.id) return;
    
    try {
      // Verificación específica para routeCharacteristics
      if (sectionName === 'routeCharacteristics') {
        // Verificación de estructura para evitar serialización incorrecta
        const validatedData = typeof data === 'string' ? JSON.parse(data) : data;
        
        const updatedDoc = {
          ...document,
          [sectionName]: validatedData, // Asignación directa, no extensión
          lastUpdated: new Date().toISOString()
        };
        
        setDocument(updatedDoc);
        await saveDocument(document.id, updatedDoc);
      } else {
        // Comportamiento estándar para otras secciones
        const updatedDoc = {
          ...document,
          [sectionName]: {
            ...document[sectionName],
            ...data
          },
          lastUpdated: new Date().toISOString()
        };
        
        setDocument(updatedDoc);
        await saveDocument(document.id, updatedDoc);
      }
    } catch (err) {
      console.error('Error updating document:', err);
      setError('No se pudo actualizar el documento');
    }
  }, [document, saveDocument]);
  
  // Función para generar PDF
  const handleGeneratePdf = useCallback(async () => {
    if (!document || !document.id) return null;
    
    try {
      const pdfUrl = await generatePdf(document.id);
      return pdfUrl;
    } catch (err) {
      console.error('Error generating PDF:', err);
      setError('No se pudo generar el PDF');
      return null;
    }
  }, [document, generatePdf]);
  
  // Función para compartir el documento
  const handleShareDocument = useCallback(async () => {
    if (!document || !document.id) return null;
    
    try {
      const shareUrl = await shareDocument(document.id);
      return shareUrl;
    } catch (err) {
      console.error('Error sharing document:', err);
      setError('No se pudo compartir el documento');
      return null;
    }
  }, [document, shareDocument]);
  
  // Función para limpiar el documento
  const handleClearDocument = useCallback(async () => {
    if (!document || !document.id) return;
    
    try {
      const newDoc = await createDocument(document.id, true);
      setDocument(newDoc);
    } catch (err) {
      console.error('Error clearing document:', err);
      setError('No se pudo limpiar el documento');
    }
  }, [document, createDocument]);

  const value = {
    document,
    loading,
    error,
    updateDocument,
    generatePdf: handleGeneratePdf,
    shareDocument: handleShareDocument,
    clearDocument: handleClearDocument
  };

  return (
    <DocumentContext.Provider value={value}>
      {children}
    </DocumentContext.Provider>
  );
};

DocumentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};