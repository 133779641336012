import React, { useState, useMemo } from "react";
import { Modal, Tooltip, Pagination } from "@mui/material";
import {
  useVehicleTypes,
  useSaveVehicleType,
  useDeleteVehicleType,
} from "../../../hooks/useVehicleQuery";
import { useNotification } from "../../../context/NotificationContext";
import CopyIcon from "../../../assets/svg/components/copy.svg";
import PropTypes from "prop-types";
import LoadingAnimation from "../../loading/LoadingAnimation";
import { useLayout } from "../../../context/LayoutContext";

// Función para truncar texto
function truncateText(text = "", maxLength = 30) {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
}

export function VehicleTypeTable({ fixedColumnsMode = 2 }) {
  // Estados para la paginación y búsqueda
  const [page, setPage] = useState(1);
  const [limit] = useState(5); // Limitamos a 5 elementos por página
  const [search, setSearch] = useState("");
  
  // Obtenemos todos los datos sin parámetros adicionales
  const { data, isLoading, isError } = useVehicleTypes();
  
  // Procesamos los datos para filtrado y paginación en el frontend
  const allTypes = data?.data || [];
  
  // Función para filtrar los tipos según el término de búsqueda
  const filteredTypes = useMemo(() => {
    if (!search) return allTypes;
    
    const searchTermLower = search.toLowerCase();
    return allTypes.filter(
      type => 
        (type.name && type.name.toLowerCase().includes(searchTermLower)) ||
        (type.description && type.description.toLowerCase().includes(searchTermLower)) ||
        (type.category && type.category.toLowerCase().includes(searchTermLower))
    );
  }, [allTypes, search]);
  
  // Calculamos el total de páginas basado en el número de elementos filtrados
  const totalPages = Math.ceil(filteredTypes.length / limit);
  
  // Obtenemos los elementos para la página actual
  const paginatedTypes = useMemo(() => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    return filteredTypes.slice(startIndex, endIndex);
  }, [filteredTypes, page, limit]);
  
  const saveMutation = useSaveVehicleType();
  const deleteMutation = useDeleteVehicleType();

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const { showNotification } = useNotification();
  const { theme } = useLayout();

  if (isLoading) return <LoadingAnimation />;
  if (isError) return <div className={`GM__${theme}-error-message`}>Hubo un error al cargar los tipos de vehículo.</div>;

  const handleOpenModal = (type = null) => {
    setEditData(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditData(null);
    setShowModal(false);
  };

  // Eliminar (borrado lógico)
  const handleDelete = (id) => {
    if (window.confirm("¿Eliminar este tipo de vehículo?")) {
      deleteMutation.mutate(id);
    }
  };

  // Guardar (crear / editar)
  const handleSave = async (formValues) => {
    await saveMutation.mutateAsync({
      id: formValues.id,
      data: {
        name: formValues.name,
        description: formValues.description,
        max_capacity: formValues.max_capacity,
        capacity_unit: formValues.capacity_unit,
        category: formValues.category,
      },
    });
    handleCloseModal();
  };

  // Copiar texto y notificar
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showNotification({
          message: "¡Copiado exitosamente!",
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: "Error al copiar.",
          severity: "error",
        });
      });
  };
  
  // Función para manejar el cambio en la búsqueda
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reiniciamos a la página 1 cada vez que se filtra
  };

  // Función para manejar el cambio de página
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <div className={`GM__${theme}-vehicle-table-wrapper`}>
      <div className={`GM__${theme}-header-section`}>
        <h2 className={`GM__${theme}-section-title`}>Tipos de Vehículo</h2>
        <div className={`GM__${theme}-filter-container`}>
          <input
            type="text"
            placeholder="Filtrar por nombre o descripción..."
            value={search}
            onChange={handleSearchChange}
            className={`GM__${theme}-search-input`}
          />
          <button
            className={`GM__${theme}-add-button`}
            onClick={() => handleOpenModal(null)}
          >
            Agregar nuevo tipo
          </button>
        </div>
      </div>
      <div className={`GM__${theme}-vehicle-table-wrapper-table`}>
        <table
          className={`GM__${theme}-vehicle-table fixed-columns-${fixedColumnsMode}`}
        >
          <thead>
            <tr>
              <th
                className={`${
                  fixedColumnsMode === 1 || fixedColumnsMode === 3
                    ? "fixed-column fixed-column-1"
                    : ""
                }`}
              >
                ID
              </th>
              <th
                className={`${
                  fixedColumnsMode === 2 || fixedColumnsMode === 3
                    ? "fixed-column fixed-column-2"
                    : ""
                }`}
              >
                Nombre
              </th>
              <th>Descripción</th>
              <th>Capacidad Máx.</th>
              <th>Unidad</th>
              <th>Categoría</th>
              <th>Activo</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {paginatedTypes.length > 0 ? (
              paginatedTypes.map((type) => (
                <tr key={type.id}>
                  <td
                    className={`GM__${theme}-copyable-cell ${
                      fixedColumnsMode === 1 || fixedColumnsMode === 3
                        ? "fixed-column fixed-column-1"
                        : ""
                    }`}
                  >
                    <Tooltip title={type.id?.toString() || "—"}>
                      <span>{truncateText(type.id?.toString() || "", 10)}</span>
                    </Tooltip>
                    <button
                      className={`GM__${theme}-copy-icon`}
                      onClick={() => handleCopy(type.id?.toString() || "")}
                      aria-label="Copiar ID"
                    >
                      <img src={CopyIcon} alt="Copiar" />
                    </button>
                  </td>

                  <td
                    className={`GM__${theme}-copyable-cell ${
                      fixedColumnsMode === 2 || fixedColumnsMode === 3
                        ? "fixed-column fixed-column-2"
                        : ""
                    }`}
                  >
                    <Tooltip title={type.name || "—"}>
                      <span>{truncateText(type.name || "", 15)}</span>
                    </Tooltip>
                    {type.name && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() => handleCopy(type.name || "")}
                        aria-label="Copiar nombre"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  <td className={`GM__${theme}-copyable-cell`}>
                    <Tooltip title={type.description || "—"}>
                      <span>{truncateText(type.description || "", 20)}</span>
                    </Tooltip>
                    {type.description && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() => handleCopy(type.description || "")}
                        aria-label="Copiar descripción"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  <td>{type.max_capacity || "—"}</td>
                  <td>{type.capacity_unit || "—"}</td>
                  <td>{type.category || "—"}</td>
                  <td>{type.active ? "Sí" : "No"}</td>

                  <td className={`GM__${theme}-action-buttons`}>
                    <button
                      className={`GM__${theme}-edit-button`}
                      onClick={() => handleOpenModal(type)}
                    >
                      Editar
                    </button>
                    <button
                      className={`GM__${theme}-delete-button`}
                      onClick={() => handleDelete(type.id)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className={`GM__${theme}-empty-message`}>
                  {search ? "No se encontraron tipos de vehículo con ese filtro" : "No hay tipos de vehículo registrados"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {/* PAGINACIÓN - Solo se muestra si hay más de una página */}
      {totalPages > 1 && (
        <div className={`GM__${theme}-pagination-container`}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            className={`GM__${theme}-custom-pagination`}
          />
        </div>
      )}

      <Modal
        open={showModal}
        onClose={handleCloseModal}
        className={`GM__modal-container-${theme}`}
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TypeForm
          data={editData}
          onSave={handleSave}
          onCancel={handleCloseModal}
          theme={theme}
        />
      </Modal>
    </div>
  );
}

VehicleTypeTable.propTypes = {
  fixedColumnsMode: PropTypes.oneOf([1, 2, 3]),
};

function TypeForm({ data, onSave, onCancel, theme }) {
  const [formValues, setFormValues] = useState(
    data || {
      id: null,
      name: "",
      description: "",
      max_capacity: "",
      capacity_unit: "",
      category: "",
    }
  );

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.name.trim()) newErrors.name = "El nombre es obligatorio.";
    if (!formValues.description.trim())
      newErrors.description = "La descripción es obligatoria.";
    if (
      !formValues.max_capacity ||
      isNaN(parseFloat(formValues.max_capacity))
    ) {
      newErrors.max_capacity = "La capacidad máxima debe ser un número válido.";
    }
    if (!formValues.capacity_unit) {
      newErrors.capacity_unit = "La unidad de capacidad es obligatoria.";
    }
    if (!formValues.category.trim()) {
      newErrors.category = "La categoría es obligatoria.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      onSave(formValues);
    }
  };

  return (
    <div className={`GM__${theme}-modal GM__${theme}-vehicle-type-modal`}>
      <div className={`GM__${theme}-modal-header-container`}>
        <h3 className={`GM__${theme}-modal-header`}>
          {data ? "Editar Tipo" : "Nuevo Tipo"}
        </h3>
        <div className={`GM__${theme}-modal-subtitle`}>
          {data ? "Modificar información del tipo de vehículo" : "Crear nuevo tipo de vehículo"}
        </div>
      </div>

      <div className={`GM__${theme}-modal-content`}>
        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-form-group`}>
            <label className={`GM__${theme}-form-label`} htmlFor="name">
              Nombre
            </label>
            <input
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              placeholder="Ej: Camión"
              className={`GM__${theme}-form-input ${
                errors.name ? "is-invalid" : ""
              }`}
            />
            {errors.name && (
              <span className={`GM__${theme}-form-error`}>{errors.name}</span>
            )}
          </div>

          <div className={`GM__${theme}-form-group`}>
            <label className={`GM__${theme}-form-label`} htmlFor="max_capacity">
              Capacidad Máx.
            </label>
            <input
              id="max_capacity"
              name="max_capacity"
              type="number"
              step="0.01"
              value={formValues.max_capacity}
              onChange={handleChange}
              placeholder="Ej: 10000"
              className={`GM__${theme}-form-input ${
                errors.max_capacity ? "is-invalid" : ""
              }`}
            />
            {errors.max_capacity && (
              <span className={`GM__${theme}-form-error`}>
                {errors.max_capacity}
              </span>
            )}
          </div>
        </div>

        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-form-group`}>
            <label className={`GM__${theme}-form-label`} htmlFor="category">
              Categoría
            </label>
            <input
              id="category"
              name="category"
              value={formValues.category}
              onChange={handleChange}
              placeholder="Ej: Carga, Pasajeros, etc."
              className={`GM__${theme}-form-input ${
                errors.category ? "is-invalid" : ""
              }`}
            />
            {errors.category && (
              <span className={`GM__${theme}-form-error`}>
                {errors.category}
              </span>
            )}
          </div>

          <div className={`GM__${theme}-form-group`}>
            <label
              className={`GM__${theme}-form-label`}
              htmlFor="capacity_unit"
            >
              Unidad
            </label>
            <select
              id="capacity_unit"
              name="capacity_unit"
              value={formValues.capacity_unit}
              onChange={handleChange}
              className={`GM__${theme}-form-select ${
                errors.capacity_unit ? "is-invalid" : ""
              }`}
            >
              <option value="" disabled>
                Selecciona una unidad
              </option>
              <option value="kg">Kg</option>
              <option value="lb">Lb</option>
              <option value="liters">Litros</option>
              <option value="toneladas">Toneladas (ton)</option>
              <option value="Metros cúbicos">Metros cúbicos</option>
            </select>
            {errors.capacity_unit && (
              <span className={`GM__${theme}-form-error`}>
                {errors.capacity_unit}
              </span>
            )}
          </div>
        </div>

        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-form-group full-width`}>
            <label className={`GM__${theme}-form-label`} htmlFor="description">
              Descripción
            </label>
            <input
              id="description"
              name="description"
              value={formValues.description}
              onChange={handleChange}
              placeholder="Ej: Vehículo de carga pesada"
              className={`GM__${theme}-form-input ${
                errors.description ? "is-invalid" : ""
              }`}
            />
            {errors.description && (
              <span className={`GM__${theme}-form-error`}>
                {errors.description}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className={`GM__${theme}-modal-footer`}>
        <button
          className={`GM__${theme}-btn GM__${theme}-btn-cancel`}
          onClick={onCancel}
        >
          Cancelar
        </button>
        <button
          className={`GM__${theme}-btn GM__${theme}-btn-save`}
          onClick={handleSubmit}
        >
          {data ? "Guardar Cambios" : "Crear"}
        </button>
      </div>
    </div>
  );
}

TypeForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
    max_capacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    capacity_unit: PropTypes.string,
    category: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};