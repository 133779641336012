import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SortDropdown from "../../components/ui/dropdawn/SortDropdown";
import { usePhoneCountryCodes } from "../../hooks/userComplementaryQuery";
import CustomInputField from '../../components/ui/inputs/CustomInputField';

const ContactInfoEdit = ({
  contactInfo,
  onContactInfoChange,
  theme
}) => {
  const { data: countryCodes, isLoading: isLoadingCountryCodes } = usePhoneCountryCodes();
  
  const countryCodesData = useMemo(() => {
    if (!countryCodes?.data) return [];
    return countryCodes.data.map(country => ({
      value: country.id,
      label: `${country.country_code} (${country.country_name})`,
      country_code: country.country_code
    }));
  }, [countryCodes]);

  const handleInputChange = (field, value) => {
    onContactInfoChange(field, value);
  };

  return (
    <div className="contact-section">
      <h2>Información de contacto</h2>

      <div className="contact-row">
        <div className="phone-group">
          <div className="input-group">
            <label htmlFor="primaryPhone">Número de Celular Principal *</label>
            <div className="phone-input-container">
              <SortDropdown
                id="primaryPhoneCountry"
                value={contactInfo.primaryPhoneCountryId || ""}
                onChange={(value) => handleInputChange('primaryPhoneCountryId', value)}
                options={countryCodesData}
                type="phone"
                theme={theme}
                placeholder="Código"
                isLoading={isLoadingCountryCodes}
                required
              />
              <CustomInputField
                id="primaryPhone"
                type="text"
                value={contactInfo.primaryPhoneNumber}
                onChange={(e) => handleInputChange('primaryPhoneNumber', e.target.value)}
                placeholder="Número"
                required
                theme={theme}
              />
            </div>
          </div>
        </div>

        <div className="phone-group">
          <div className="input-group">
            <label htmlFor="secondaryPhone">
              Número de Celular Secundario (Opcional)
            </label>
            <div className="phone-input-container">
              <SortDropdown
                id="secondaryPhoneCountry"
                value={contactInfo.secondaryPhoneCountryId || ""}
                onChange={(value) => handleInputChange('secondaryPhoneCountryId', value)}
                options={countryCodesData}
                type="phone"
                theme={theme}
                placeholder="Código"
                isLoading={isLoadingCountryCodes}
              />
              <CustomInputField
                id="secondaryPhone"
                type="text"
                value={contactInfo.secondaryPhoneNumber}
                onChange={(e) => handleInputChange('secondaryPhoneNumber', e.target.value)}
                placeholder="Número"
                theme={theme}
              />
            </div>
          </div>
        </div>

        <div className="input-group email-group">
          <label htmlFor="email">Correo Electrónico *</label>
          <CustomInputField
            id="email"
            type="email"
            value={contactInfo.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            placeholder="Ej. usuario@correo.com"
            required
          />
        </div>
      </div>
    </div>
  );
};

ContactInfoEdit.propTypes = {
  contactInfo: PropTypes.shape({
    primaryPhoneNumber: PropTypes.string.isRequired,
    primaryPhoneCountryId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    secondaryPhoneNumber: PropTypes.string,
    secondaryPhoneCountryId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    email: PropTypes.string.isRequired
  }).isRequired,
  onContactInfoChange: PropTypes.func.isRequired,
    theme: PropTypes.oneOf(['dark', 'light']),
};

export default ContactInfoEdit;