import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Pagination,
  Typography,
} from "@mui/material";
import "../../../assets/scss/components/ui/tables/CriticalPointsHotspotsTable.scss";
import StatusDisplay from "../../loading/StatusDisplay";

const ITEMS_PER_PAGE = 6;

const ViolationOccurrenceDetails = ({ violation, onClose }) => {
  if (!violation) return null;

  const formatDateTime = (dateTime) => {
    return new Date(dateTime).toLocaleString('es-CO', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Dialog 
      open={true} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      className="violation-details-dialog"
    >
      <DialogTitle className="dialog-header">
        <div className="dialog-title">
          Detalles de reincidencia - {violation.placa}
        </div>
        <button className="close-button" onClick={onClose}>X</button>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <div className="driver-info">
          <Typography variant="body1">
            Conductor: {violation.driver || "Desconocido"}
          </Typography>
          <div className="stats">
            <span>{violation.occurrences?.length || 0}</span>
            <Typography variant="body2">
              Total de violaciones al PC
            </Typography>
          </div>
        </div>

        <div className="vehicle-info">
          <div className="info-field">
            <Typography variant="body2">Placa remolque</Typography>
            <div className="field-value">{violation.trailerPlate || "No disponible"}</div>
          </div>
          <div className="info-field">
            <Typography variant="body2">N° licencia de tránsito</Typography>
            <div className="field-value">{violation.licenseNumber || "No disponible"}</div>
          </div>
        </div>

        {violation.occurrences?.map((occurrence, index) => (
          <div key={index+1} className="occurrence-card">
            <Typography variant="subtitle1" className="occurrence-title">
              Punto crítico - {formatDateTime(occurrence.dateTime)}
            </Typography>
            
            <div className="occurrence-details">
              <div className="detail-row">
                <Typography variant="body2">Lugar</Typography>
                <div className="detail-value">
                  {violation.pointName}
                  <Button
                    variant="contained"
                    size="small"
                    className="map-button"
                    onClick={() => window.open(`https://www.google.com/maps?q=${occurrence.location?.lat},${occurrence.location?.lon}`)}
                  >
                    Ver en mapa
                  </Button>
                </div>
              </div>

              <div className="detail-row">
                <Typography variant="body2">Ruta</Typography>
                <div className="detail-value">
                  {occurrence.tripData?.routeName}
                </div>
              </div>

              <div className="detail-row">
                <Typography variant="body2">Velocidad</Typography>
                <div className="detail-value">
                  {occurrence.speed} km/h
                </div>
              </div>

              <div className="detail-group">
                <div className="detail-row">
                  <Typography variant="body2">Origen</Typography>
                  <div className="detail-value">
                    {formatDateTime(occurrence.tripData?.origin?.dateTime)}
                  </div>
                </div>
                <div className="detail-row coordinates">
                  <Typography variant="body2">Coordenadas</Typography>
                  <div className="detail-value">
                    {occurrence.tripData?.origin?.lat}, {occurrence.tripData?.origin?.lon}
                  </div>
                </div>
              </div>

              <div className="detail-group">
                <div className="detail-row">
                  <Typography variant="body2">Destino</Typography>
                  <div className="detail-value">
                    {formatDateTime(occurrence.tripData?.destination?.dateTime)}
                  </div>
                </div>
                <div className="detail-row coordinates">
                  <Typography variant="body2">Coordenadas</Typography>
                  <div className="detail-value">
                    {occurrence.tripData?.destination?.lat}, {occurrence.tripData?.destination?.lon}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const CriticalPointsHotspotsTable = ({
  violations = [],
  hasData = false,
  theme = "light",
  viewType
}) => {
  const [page, setPage] = useState(1);
  const [selectedViolation, setSelectedViolation] = useState(null);

  const { displayedViolations, totalPages } = useMemo(() => {
    const total = Math.ceil(violations.length / ITEMS_PER_PAGE);
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const displayed = violations.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    
    return {
      displayedViolations: displayed,
      totalPages: total
    };
  }, [violations, page]);

  if (!hasData) {
    return (
      <div className={`no-data`}>
        <StatusDisplay
          type={204} 
          message="Lo sentimos, no pudimos encontrar resultados..."
        />
      </div>
    );
  }

  const getLocationDisplay = (violation) => {
    if (violation.type === "criticalPoint") {
      return violation.pointName;
    }
    return violation.occurrences?.[0]?.tripData?.routeName || "No disponible";
  };

  const getTotalOccurrences = (violation) => {
    if (viewType === "all") {
      return (violation.criticalPointOccurrences?.length || 0) + 
             (violation.speedOccurrences?.length || 0);
    }
    return violation.occurrenceCount || 0;
  };

  return (
    <>
      <TableContainer component={Paper} className={`violation-table ${theme}`}>
        <Table className={`table`}>
          <TableHead className={`table-header`}>
            <TableRow className={`table-row`}>
              <TableCell>Placa</TableCell>
              <TableCell>Conductor</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Lugar</TableCell>
              <TableCell>Ocurrencias</TableCell>
              <TableCell>Más Información</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedViolations.map((violation, index) => (
              <TableRow
                key={`${violation.placa}-${index}`}
                className={violation.type === "criticalPoint" ? "critical-point-row" : "speed-row"}
              >
                <TableCell>{violation.placa}</TableCell>
                <TableCell>No disponible</TableCell>
                <TableCell>No disponible</TableCell>
                <TableCell>{getLocationDisplay(violation)}</TableCell>
                <TableCell>{getTotalOccurrences(violation)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setSelectedViolation(violation)}
                  >
                    Ver Detalles
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPages > 1 && (
        <div className="pagination-container">
          <Pagination 
            count={totalPages}
            page={page}
            onChange={(_, value) => setPage(value)}
            shape="rounded"
            className={`custom-pagination ${theme}`}
            color="primary"
          />
        </div>
      )}

      {selectedViolation && (
        <ViolationOccurrenceDetails
          violation={selectedViolation}
          onClose={() => setSelectedViolation(null)}
        />
      )}
    </>
  );
};

ViolationOccurrenceDetails.propTypes = {
  violation: PropTypes.shape({
    placa: PropTypes.string.isRequired,
    driver: PropTypes.string,
    trailerPlate: PropTypes.string,
    licenseNumber: PropTypes.string,
    pointName: PropTypes.string,
    occurrences: PropTypes.arrayOf(
      PropTypes.shape({
        dateTime: PropTypes.string.isRequired,
        location: PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number
        }),
        speed: PropTypes.number,
        tripData: PropTypes.shape({
          routeName: PropTypes.string,
          origin: PropTypes.shape({
            dateTime: PropTypes.string,
            lat: PropTypes.number,
            lon: PropTypes.number
          }),
          destination: PropTypes.shape({
            dateTime: PropTypes.string,
            lat: PropTypes.number,
            lon: PropTypes.number
          })
        })
      })
    )
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

CriticalPointsHotspotsTable.propTypes = {
  violations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["speed", "criticalPoint"]).isRequired,
      placa: PropTypes.string.isRequired,
      pointName: PropTypes.string,
      criticalPointId: PropTypes.number,
      criticalPointData: PropTypes.object,
      occurrenceCount: PropTypes.number.isRequired,
      occurrences: PropTypes.arrayOf(
        PropTypes.shape({
          dateTime: PropTypes.string.isRequired,
          location: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lon: PropTypes.number.isRequired
          }),
          speed: PropTypes.number,
          tripData: PropTypes.shape({
            routeName: PropTypes.string,
            origin: PropTypes.shape({
              dateTime: PropTypes.string,
              lat: PropTypes.number,
              lon: PropTypes.number
            }),
            destination: PropTypes.shape({
              dateTime: PropTypes.string,
              lat: PropTypes.number,
              lon: PropTypes.number
            }),
            durationMinutes: PropTypes.number
          })
        })
      ).isRequired
    })
  ).isRequired,
  hasData: PropTypes.bool,
  theme: PropTypes.oneOf(["light", "dark"]),
  viewType: PropTypes.oneOf(["all", "critical", "speed"])
};

export default CriticalPointsHotspotsTable;