import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as PdfIcon } from "../../../assets/svg/view/pdf.svg";
import { ReactComponent as ImageIcon } from "../../../assets/svg/view/img.svg";
import "../../../assets/scss/components/ui/modal/MultiDownloadModal.scss";
import MelisaImg from "../../../assets/img/melisa.png";

// Función auxiliar para reordenar elementos en una lista
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};


function MultiDownloadModal({
  open,
  onClose,
  personalDocuments,
  certificates,
  otherDocuments,
  selectedDocs,
  setSelectedDocs,
  onDownloadAll,
  userName,
  isVehicle = false,

}) {
  // Estados de los arrays que serán "draggables"
  const [personalDocs, setPersonalDocs] = useState([]);
  const [othersDocs, setOthersDocs] = useState([]);

  // Control de despliegue para certificados y otros archivos
  const [otherFilesOpen, setOtherFilesOpen] = useState(false);
  const [personalFilesOpen, setPersonalFilesOpen] = useState(true);
  // Efecto para inicializar (o actualizar) el listado cuando cambia el prop
  useEffect(() => {
    // Configurar documentos personales
    setPersonalDocs([...personalDocuments]);

    // Combinar certificados y otros documentos en una sola lista
    const allOtherDocs = [...certificates, ...otherDocuments];
    setOthersDocs(allOtherDocs);
  }, [personalDocuments, certificates, otherDocuments]);

  // Determina el ícono según la extensión del archivo
  const getFileIcon = (doc) => {
    if (!doc?.file_name) return null;

    const lowerCaseName = doc.file_name.toLowerCase();
    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(lowerCaseName);
    const isPdf = lowerCaseName.endsWith(".pdf");

    if (isImage) return <ImageIcon />;
    if (isPdf) return <PdfIcon />;
    return null;
  };

  // Manejo de checkboxes
  const handleCheckboxChange = (docId, checked) => {
    if (checked) {
      setSelectedDocs((prev) => [...prev, docId]);
    } else {
      setSelectedDocs((prev) => prev.filter((id) => id !== docId));
    }
  };

  // Seleccionar / Deseleccionar todos
  const handleToggleSelectAll = (checked) => {
    const allDocIds = [
      ...personalDocs.map((doc) => doc.id),
      ...othersDocs.map((doc) => doc.id),
    ];

    if (checked) {
      setSelectedDocs(allDocIds);
    } else {
      setSelectedDocs([]);
    }
  };

  // Drag & Drop
  const onDragEnd = (result) => {
    // Si no hay destino (por ejemplo, se suelta fuera)
    if (!result.destination) return;

    // droppableId indica en qué lista se hace el drag
    const { source, destination } = result;

    // Si el ítem se soltó en la misma posición
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Reordenar dentro de la misma sección
    if (source.droppableId === destination.droppableId) {
      switch (source.droppableId) {
        case "personalDocs":
          setPersonalDocs((prev) =>
            reorder(prev, source.index, destination.index)
          );
          break;
        case "othersDocs":
          setOthersDocs((prev) =>
            reorder(prev, source.index, destination.index)
          );
          break;
        default:
          break;
      }
    }
    // **Si quisieras permitir mover entre secciones** (personal -> certificados, etc.)
    // tendrías que hacer una lógica adicional para remover el item de la lista
    // de origen y agregarlo a la lista de destino. Por ahora, mantenemos
    // cada sección con su propia lista.
  };

  // Si el modal no está abierto, no renderizamos nada
  if (!open) return null;

  return (
    <div className="multi-download-modal">
      {/* Fondo oscuro que cierra el modal al hacer click */}
      <div className="modal-backdrop" onClick={onClose}></div>

      <div className="modal-content-preview">
        {/* HEADER */}
        <div className="modal-header">
          <div>
            <h2>Descargar Documentos</h2>
            <p className="user-name">{userName}</p>
          </div>
          <button className="close-button" onClick={onClose}>
            X
          </button>
        </div>

        {/* BODY */}
        <div className="modal-body">
          {/* Columna Izquierda */}
          <div className="column left">
            {/* Seleccionar todo */}
            <div className="actions">
              <label>
                <input
                  type="checkbox"
                  checked={
                    selectedDocs.length ===
                    personalDocs.length + othersDocs.length
                  }
                  onChange={(e) => handleToggleSelectAll(e.target.checked)}
                />
                Seleccionar Todo
              </label>
            </div>

            {/* Iniciamos el DragDropContext para que todas las secciones (Droppables) puedan reordenar */}
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="document-list">
                {/* ========================== */}
                {/* Documentos Personales */}
                {/* ========================== */}
                {!!personalDocs.length && (
                  <div className="section-download">
                    <div
                      className="section-title clickable"
                      onClick={() => setPersonalFilesOpen(!personalFilesOpen)}
                    >
                      <h3>Documentos Personales</h3>
                    </div>

                    {personalFilesOpen && (
                      <Droppable droppableId="personalDocs">
                        {(provided) => (
                          <div
                            className="collapsible-content"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {personalDocs.map((doc, index) => (
                              <Draggable
                                key={doc.id}
                                draggableId={String(doc.id)}
                                index={index}
                              >
                                {(draggableProvided, snapshot) => (
                                  <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                    className={`document-item ${
                                      snapshot.isDragging ? "dragging" : ""
                                    }`}
                                  >
                                    <label>
                                      {getFileIcon(doc)}
                                      <input
                                        type="checkbox"
                                        checked={selectedDocs.includes(doc.id)}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            doc.id,
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <span className="document-info">
                                        {doc.original_name_description && (
                                          <span className="document-description">
                                            {doc.original_name_description}
                                          </span>
                                        )}
                                      </span>
                                    </label>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    )}
                  </div>
                )}
                {/* ========================== */}
                {/* Otros Archivos (colapsable) */}
                {/* ========================== */}
                {!!othersDocs.length && (
                  <div className="section-download">
                    <div
                      className="section-title clickable"
                      onClick={() => setOtherFilesOpen(!otherFilesOpen)}
                    >
                      <h3>Otros Documentos</h3>
                    </div>

                    {otherFilesOpen && (
                      <Droppable droppableId="otherDocs">
                        {(provided) => (
                          <div
                            className="collapsible-content"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {othersDocs.map((doc, index) => (
                              <Draggable
                                key={doc.id}
                                draggableId={String(doc.id)}
                                index={index}
                              >
                                {(draggableProvided, snapshot) => (
                                  <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                    className={`document-item ${
                                      snapshot.isDragging ? "dragging" : ""
                                    }`}
                                  >
                                    <label>
                                      {getFileIcon(doc)}
                                      <input
                                        type="checkbox"
                                        checked={selectedDocs.includes(doc.id)}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            doc.id,
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <span className="document-info">
                                        {doc.original_name_description && (
                                          <span className="document-description">
                                            {doc.original_name_description}
                                          </span>
                                        )}
                                      </span>
                                    </label>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    )}
                  </div>
                )}
              </div>
            </DragDropContext>
          </div>

          {/* Columna Derecha */}
          <div className="column right">
            <img
              src={MelisaImg}
              alt="Imagen de motivación"
              className="preview-image"
            />
            <button
              className="download-button"
              disabled={selectedDocs.length === 0}
              onClick={onDownloadAll}
            >
              Descargar Seleccionados
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

MultiDownloadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  personalDocuments: PropTypes.array.isRequired,
  certificates: PropTypes.array.isRequired,
  otherDocuments: PropTypes.array.isRequired,
  selectedDocs: PropTypes.array.isRequired,
  setSelectedDocs: PropTypes.func.isRequired,
  onDownloadAll: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  isVehicle: PropTypes.bool,
};

export default MultiDownloadModal;
