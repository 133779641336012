import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useChatService } from "../service/chatService";
import { useSocket } from "../context/SocketContext";
import { useEffect } from "react";

export const useChatOperations = () => {
  const chatService = useChatService()
  const queryClient = useQueryClient();
  const socket = useSocket();

  // Configuración de listeners de socket
  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (message) => {
      queryClient.invalidateQueries(["conversations"]);
      queryClient.invalidateQueries(["messages", message.conversation_id]);
    };

    const handleMessageUpdated = (message) => {
      queryClient.invalidateQueries(["messages", message.conversation_id]);
    };

    const handleMessageDeleted = (messageData) => {
      queryClient.invalidateQueries(["messages", messageData.conversation_id]);
    };

    const handleConversationRead = (data) => {
      queryClient.invalidateQueries(["conversations"]);
    };

    socket.on("new_message", handleNewMessage);
    socket.on("message_edited", handleMessageUpdated);
    socket.on("message_deleted", handleMessageDeleted);
    socket.on("conversation_read", handleConversationRead);

    return () => {
      socket.off("new_message", handleNewMessage);
      socket.off("message_edited", handleMessageUpdated);
      socket.off("message_deleted", handleMessageDeleted);
      socket.off("conversation_read", handleConversationRead);
    };
  }, [socket, queryClient]);

  // Query para obtener conversaciones
  const useConversations = (limit = 5, offset = 0 ) => {
    return useQuery({
      queryKey: ["conversations", limit, offset],
      queryFn: () => useChatService.getRecentConversations(limit, offset),
      staleTime: 30000,
      cacheTime: 3600000,
      refetchOnWindowFocus: false,
    });
  };

  // Query para obtener mensajes de una conversación
  const useConversationMessages = (conversationId, limit = 50, before = null) => {
    return useQuery({
      queryKey: ["messages", conversationId, limit, before],
      queryFn: () => useChatService.getConversationMessages(conversationId, limit, before),
      enabled: !!conversationId,
      staleTime: 30000,
      cacheTime: 3600000,
      refetchOnWindowFocus: false,
    });
  };

  // Mutación para enviar mensaje
  const useSendMessage = () => {
    return useMutation({
      mutationFn: async ({ content, recipientId, conversationId }) => {
        const response = await chatService.sendMessage(
          conversationId, 
          content,
          [], // files array vacío por ahora
          null, // replyToMessageId
          recipientId
        );
        return response.data;
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['conversations']);
        queryClient.invalidateQueries(['messages', data.conversation_id]);
      }
    });
  };

  // Mutación para editar mensaje
  const useEditMessage = () => {
    return useMutation({
      mutationFn: ({ messageId, content }) =>
        useChatService.editMessage(messageId, content),
      onSuccess: (data) => {
        queryClient.invalidateQueries(["messages", data.conversation_id]);
      },
    });
  };

  // Mutación para eliminar mensaje
  const useDeleteMessage = () => {
    return useMutation({
      mutationFn: (messageId) => useChatService.deleteMessage(messageId),
      onSuccess: (data) => {
        queryClient.invalidateQueries(["messages", data.conversation_id]);
      },
    });
  };

  // Mutación para marcar como leído
  const useMarkAsRead = () => {
    return useMutation({
      mutationFn: (conversationId) => useChatService.markConversationAsRead(conversationId),
      onSuccess: () => {
        queryClient.invalidateQueries(["conversations"]);
      },
    });
  };

  return {
    useConversations,
    useConversationMessages,
    useSendMessage,
    useEditMessage,
    useDeleteMessage,
    useMarkAsRead,
  };
};