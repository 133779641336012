import React, { useEffect } from "react";
import { Marker, Polyline, DirectionsRenderer } from "@react-google-maps/api";

const RouteLayer = ({ routes, activeRouteId, updateRoute }) => {
  // Depuración de rutas para identificar el problema
  useEffect(() => {
    if (routes.length > 0) {
      console.log("Rutas renderizadas:", routes.map(r => ({
        id: r.id,
        tieneRouteData: !!r.routeData,
        routeDataValido: r.routeData && r.routeData.routes && r.routeData.routes.length > 0
      })));
    }
  }, [routes]);

  if (!routes.length) return null;

  return (
    <>
      {routes.map((route) => {
        if (!route.visible) return null;

        const isActive = route.id === activeRouteId;
        const strokeWeight = isActive ? 4 : 3;
        const zIndex = isActive ? 10 : 1;
        
        // Verificación estricta de la validez de routeData
        const hasValidRouteData = route.routeData && 
                                route.routeData.routes && 
                                route.routeData.routes.length > 0;

        return (
          <React.Fragment key={route.id}>
            {/* Renderizar waypoints como marcadores */}
            {route.waypoints.map((waypoint, index) => (
              <Marker
                key={`${route.id}-waypoint-${index}`}
                position={waypoint}
                label={{
                  text: `${index + 1}`,
                  color: "white",
                  fontWeight: "bold",
                }}
                zIndex={zIndex + 10}
                clickable={true}
                draggable={isActive}
                onDragEnd={(e) => {
                  const updatedWaypoints = [...route.waypoints];
                  updatedWaypoints[index] = {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  };
                  updateRoute(route.id, { waypoints: updatedWaypoints });
                }}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: isActive ? 8 : 6,
                  fillColor: index === 0 ? "#4CAF50" : (index === route.waypoints.length - 1 ? "#F44336" : route.color),
                  fillOpacity: 1,
                  strokeColor: "#ffffff",
                  strokeWeight: 2,
                }}
              />
            ))}

            {/* SOLUCIÓN: Uso de función IIFE para garantizar exclusividad */}
            {(() => {
              // Validación explícita de los datos de ruta
              if (hasValidRouteData) {
                return (
                  <DirectionsRenderer
                    directions={route.routeData}
                    options={{
                      suppressMarkers: true,
                      polylineOptions: {
                        strokeColor: route.color,
                        strokeOpacity: 1,
                        strokeWeight: strokeWeight,
                        zIndex: zIndex + 5,
                      },
                    }}
                  />
                );
              } else if (route.waypoints.length >= 2) {
                return (
                  <Polyline
                    path={route.waypoints}
                    options={{
                      strokeColor: route.color,
                      strokeOpacity: 0.4,
                      strokeWeight: strokeWeight - 1,
                      strokePattern: [10, 5],
                      zIndex: zIndex,
                    }}
                  />
                );
              }
              return null;
            })()}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default RouteLayer;