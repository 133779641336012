import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import "../../../assets/scss/components/ui/input/CustomInputField.scss";
import PaginationComponent from '../pagination/PaginationComponent';
import SearchIcon from "../../../assets/svg/components/send.svg"

const CustomInputField = ({
  type = "text",
  typeValue = "text",
  size = "auto",
  theme,
  placeholder = "",
  icon = null,
  onIconClick = () => {},
  data = [],
  showDescription = false,
  // Nuevas props para paginación y búsqueda
  enableSearch = false,
  enablePagination = false,
  onSearch = () => {},
  currentPage = 1,
  totalPages = 1,
  onPageChange = () => {},
  loading = false,
  searchDebounceTime = 300,
  ...props
}) => {
  const [filter, setFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(placeholder);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const searchTimeoutRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    setFilter("");
    if (props.onChange) {
      // Simulamos un evento para mantener compatibilidad
      props.onChange({ target: { value } });
    }
  };

  // Manejo de búsqueda con debounce
  useEffect(() => {
    if (enableSearch) {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      searchTimeoutRef.current = setTimeout(() => {
        setDebouncedSearchTerm(filter);
        onSearch(filter);
      }, searchDebounceTime);

      return () => {
        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current);
        }
      };
    }
  }, [filter, enableSearch, searchDebounceTime, onSearch]);

  // Click fuera del dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredData = enableSearch 
  ? data 
  : data.filter((item) =>
      typeof item.value === 'string' &&
      item.value.toLowerCase().includes(filter.toLowerCase())
    ).slice(0, 5);

  const renderNoDataMessage = () => (
    <div className={`GM__${theme}-dropdown-no-data`}>
      <p>No se encontraron resultados</p>
    </div>
  );

  const renderSearchInput = () => (
    <div className={`GM__${theme}-dropdown-search-container`}>
      <input
        type="text"
        placeholder="Buscar..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className={`GM__${theme}-dropdown-search`}
        onClick={(e) => e.stopPropagation()}
      />
      {enableSearch && (
        <button 
          className={`GM__${theme}-search-button`}
          onClick={(e) => {
            e.stopPropagation();
            onSearch(filter);
          }}
        >
          <img src={SearchIcon} alt="Buscar" />
        </button>
      )}
    </div>
  );

  const renderDropdown = () => (
    <div className={`GM__${theme}-dropdown`}>
      {renderSearchInput()}
      
      {loading ? (
        <div className={`GM__${theme}-dropdown-loading`}>Cargando...</div>
      ) : (
        <>
          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <button
                className={`GM__${theme}-dropdown-item`}
                key={item.id}
                onClick={() => handleSelect(item.value)}
              >
                <span>{item.value}</span>
                {showDescription && item.description && (
                  <Tooltip title={item.description} placement="right">
                    <img
                      src={icon}
                      alt="info"
                      className={`GM__${theme}-info-icon`}
                    />
                  </Tooltip>
                )}
              </button>
            ))
          ) : (
            renderNoDataMessage()
          )}
          
          {enablePagination && filteredData.length > 0 && (
            <div className={`GM__${theme}-dropdown-pagination`} onClick={e => e.stopPropagation()}>
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                theme={theme}
              />
            </div>
          )}
        </>
      )}
    </div>
  );

  const renderSelect = () => (
    <div
      className={`GM__${theme}-input-wrapper GM__${theme}-${size} GM__${theme}-select-wrapper`}
      onClick={toggleDropdown}
      ref={dropdownRef}
    >
      <div className={`GM__${theme}-selected-value`}>{selectedValue}</div>
      {isOpen && renderDropdown()}
    </div>
  );

  const renderInput = () => {
    switch (type) {
      case "select":
        return renderSelect();
      case "number":
        return (
          <div className={`GM__${theme}-input-wrapper GM__${theme}-${size}`}>
            <input type="number" placeholder={placeholder} {...props} />
          </div>
        );
      default:
        return (
          <div className={`GM__${theme}-input-wrapper GM__${theme}-${size}`}>
            <input type={typeValue} placeholder={placeholder} {...props} />
          </div>
        );
    }
  };

  return renderInput();
};

CustomInputField.propTypes = {
  // Props existentes
  type: PropTypes.oneOf(["text", "search", "icon", "textarea", "select", "email", "number"]),
  typeValue: PropTypes.oneOf(["text", "number", "date", "email"]),
  size: PropTypes.oneOf(["small", "medium", "auto"]),
  theme: PropTypes.oneOf(["dark", "light"]),
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  onIconClick: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ),
  showDescription: PropTypes.bool,
  // Nuevas props
  enableSearch: PropTypes.bool,
  enablePagination: PropTypes.bool,
  onSearch: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  loading: PropTypes.bool,
  searchDebounceTime: PropTypes.number,
};

export default CustomInputField;