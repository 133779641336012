import React, { useMemo } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import "dayjs/locale/es";
import PropTypes from "prop-types";

const CustomDatePicker = ({
  label,
  value,
  onChange,
  icon: CustomIcon,
  minDate = null,
  variant = "outlined",
  fullWidth = true,
  disabled = false,
  required = false,
  className = "",
  format = "DD/MM/YYYY",
  locale = "es",
  placeholder = "Selecciona una fecha",
  error = false,
  helperText = "",
  inputProps = {},
  sx = {},
  theme = "dark", // Nuevo prop con valor por defecto "dark"
}) => {
  // Definimos los temas dinámicamente
  const customTheme = useMemo(() => {
    const isDark = theme === "dark";
    
    return createTheme({
      palette: {
        mode: isDark ? "dark" : "light",
        primary: {
          main: isDark ? "#90caf9" : "#1976d2",
        },
        background: {
          paper: isDark ? "#1e1e1e" : "#ffffff",
          default: isDark ? "#121212" : "#f5f5f5",
        },
      },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: isDark 
                  ? "rgba(255, 255, 255, 0.23)" 
                  : "rgba(0, 0, 0, 0.23)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: isDark 
                  ? "rgba(255, 255, 255, 0.4)" 
                  : "rgba(0, 0, 0, 0.4)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: isDark ? "#90caf9" : "#1976d2",
              },
            },
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            root: {
              color: isDark ? "#fff" : "#000",
              "&.Mui-selected": {
                backgroundColor: isDark ? "#90caf9" : "#1976d2",
                color: "#fff",
              },
            },
          },
        },
      },
    });
  }, [theme]);

  // Función auxiliar para asegurar que el valor es una instancia válida de dayjs
  const ensureDayjsInstance = (value) => {
    if (!value) return null;
    const dayjsValue = dayjs(value);
    return dayjsValue.isValid() ? dayjsValue : null;
  };

  // Aseguramos que el valor sea una instancia válida de dayjs
  const dayjsValue = ensureDayjsInstance(value);
  
  // Manejador de cambios que asegura el formato correcto
  const handleDateChange = (newValue) => {
    if (onChange) {
      // Si el nuevo valor es válido, lo convertimos a formato ISO
      const processedValue = newValue && newValue.isValid() 
        ? newValue.format("YYYY-MM-DD")
        : null;
      onChange(processedValue);
    }
  };

  // Configuramos el locale de dayjs
  dayjs.locale(locale);
  const IconComponent = () => CustomIcon;

  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <Box
          sx={{ display: "flex", alignItems: "center", width: "100%", ...sx }}
          className={`date-picker-container theme-${theme}`}
        >
          <DatePicker
            label={label}
            value={dayjsValue}
            onChange={handleDateChange}
            minDate={minDate ? ensureDayjsInstance(minDate) : undefined}
            disabled={disabled}
            format={format}
            slots={{
              openPickerIcon: () => (
                <IconComponent
                  style={{
                    width: "15px",
                    height: "15px",
                    color: theme === "dark" ? "white" : "black",
                  }}
                />
              ),
            }}
            slotProps={{
              textField: {
                variant,
                fullWidth,
                required,
                error,
                helperText,
                placeholder,
                className: `${className} theme-${theme}`,
                InputProps: {
                  ...inputProps,
                },
              },
              openPickerButton: {
                sx: {
                  "&:hover": {
                    backgroundColor: theme === "dark" 
                      ? "rgba(144, 202, 249, 0.08)" 
                      : "rgba(25, 118, 210, 0.08)",
                  },
                },
                disabled,
              },
            }}
          />
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

CustomDatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  format: PropTypes.string,
  locale: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputProps: PropTypes.object,
  sx: PropTypes.object,
  theme: PropTypes.oneOf(["dark", "light"]), // Validación para el nuevo prop
};

export default CustomDatePicker;