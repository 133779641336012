import React from 'react';
import PropTypes from 'prop-types';
import PDFIcon from '../../assets/svg/view/pdf.svg'
import ExcelIcon from '../../assets/svg/view/xlsx.svg'
import WordIcon from '../../assets/svg/view/docx.svg'

export default function AttachmentPreview({ files, onRemove }) {
  const getFilePreview = (fileData) => {
    // Verificamos si fileData tiene la estructura correcta
    const file = fileData.file || fileData;
    const preview = fileData.preview;

    // Función auxiliar para obtener el icono según la extensión
    const getFileIcon = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      switch (extension) {
        case 'pdf':
          return {
            src: PDFIcon,
            alt: 'PDF Document'
          };
        case 'doc':
        case 'docx':
          return {
            src: WordIcon,
            alt: 'Word Document'
          };
        case 'xls':
        case 'xlsx':
          return {
            src: ExcelIcon,
            alt: 'Excel Document'
          };
        default:
          return {
            src: PDFIcon, // Podrías usar un icono genérico aquí
            alt: 'Document'
          };
      }
    };

    if (preview) {
      return (
        <img
          src={preview}
          alt={file.name}
          className="attachment-preview-image"
        />
      );
    }

    const fileIcon = getFileIcon(file.name);

    return (
      <div className="file-preview">
        <div className="file-icon">
          <img
            src={fileIcon.src}
            alt={fileIcon.alt}
            width="30"
            height="30"
          />
        </div>
        <span className="file-name">{file.name}</span>
      </div>
    );
  };

  return (
    <div className="attachments-preview">
      {files.map((fileData, index) => (
        <div key={index} className="attachment-item">
          {getFilePreview(fileData)}
          <button
            className="remove-attachment"
            onClick={() => onRemove(index)}
            aria-label="Remove attachment"
          >
            ✕
          </button>
        </div>
      ))}
    </div>
  );
}

AttachmentPreview.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    file: PropTypes.object,
    preview: PropTypes.string
  })).isRequired,
  onRemove: PropTypes.func.isRequired,
};