import React from "react";
import DocumentTypesTable from "../../../components/ui/tables/DocumentTypesTable";
import IdentityDocumentTypesTable from "../../../components/ui/tables/IdentityDocumentTypesTable";
import PhoneCountryCodesTable from "../../../components/ui/tables/PhoneCountryCodesTable";

export function SettingPerson() {
  return (
    <div className="dashboard-document-types">
      <DocumentTypesTable />
      <IdentityDocumentTypesTable />
      <PhoneCountryCodesTable />
      
    </div>
  );
}