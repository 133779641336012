import React, { useState, useEffect } from 'react';

import styles from '../assets/scss/views/WsspConnection.module.scss';
import { useSocket } from '../context/SocketContext';

const WsspConnection = () => {
  const socket = useSocket(); // Utilizar el socket del contexto
  const [qrCode, setQrCode] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState({
    status: 'disconnected',
    message: 'Esperando conexión...'
  });

  useEffect(() => {
    // Verificar que el socket esté disponible
    if (!socket) return;

    // Manejadores de eventos de socket
    const handleConnect = () => {
      console.log('Conectado al servidor de Socket.IO');
      // Solicitar QR inicial
      socket.emit('request-qr');
    };

    const handleWhatsappQR = (data) => {
      setQrCode(data.qr);
      setConnectionStatus({
        status: 'pending',
        message: 'Escanea el código QR para conectar'
      });
    };

    const handleWhatsappAuth = (data) => {
      setConnectionStatus({
        status: data.status,
        message: data.message
      });
    };

    const handleWhatsappDisconnect = (data) => {
      setQrCode(null);
      setConnectionStatus({
        status: 'disconnected',
        message: `Desconectado: ${data.reason}`
      });
    };

    // Registrar eventos
    socket.on('connect', handleConnect);
    socket.on('whatsapp-qr', handleWhatsappQR);
    socket.on('whatsapp-auth', handleWhatsappAuth);
    socket.on('whatsapp-disconnect', handleWhatsappDisconnect);

    // Solicitar QR al montar el componente si el socket ya está conectado
    if (socket.connected) {
      socket.emit('request-qr');
    }

    // Limpieza de eventos
    return () => {
      socket.off('connect', handleConnect);
      socket.off('whatsapp-qr', handleWhatsappQR);
      socket.off('whatsapp-auth', handleWhatsappAuth);
      socket.off('whatsapp-disconnect', handleWhatsappDisconnect);
    };
  }, [socket]); // Dependencia del socket

  // Método para forzar logout
  const handleLogout = () => {
    if (socket) {
      socket.emit('force-logout');
    }
  };

  return (
    <div className={styles.wsspConnectionContainer}>
      <div className={styles.connectionWrapper}>
        <div className={styles.connectionHeader}>
          <h1>Conexión WhatsApp</h1>
          <div className={styles.connectionStatusIndicator}>
            <span 
              className={`
                ${styles.statusDot} 
                ${styles[connectionStatus.status]}
              `}
            ></span>
            <p>{connectionStatus.message}</p>
          </div>
        </div>

        <div className={styles.qrSection}>
          {qrCode ? (
            <div className={styles.qrCodeContainer}>
              <img 
                src={qrCode} 
                alt="Código QR de WhatsApp" 
                className={styles.qrCodeImage}
              />
              <p className={styles.qrInstructions}>
                Escanea este código QR con la aplicación de WhatsApp
              </p>
            </div>
          ) : (
            <div className={styles.noQrContainer}>
              <div className={styles.qrPlaceholder}></div>
              <p>Generando código QR...</p>
            </div>
          )}
        </div>

        <div className={styles.connectionActions}>
          <button 
            className={styles.logoutButton}
            onClick={handleLogout}
            disabled={connectionStatus.status !== 'connected'}
          >
            Cerrar Sesión
          </button>
        </div>
      </div>
    </div>
  );
};

export default WsspConnection;