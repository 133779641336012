import React, { useState } from "react";
import { Pagination, Tooltip } from "@mui/material";
import { useUsersByRole } from "../../../hooks/useVehicleQuery";
import { useNotification } from "../../../context/NotificationContext";
import { useLayout } from "../../../context/LayoutContext";
import CopyIcon from "../../../assets/svg/components/copy.svg";
import LoadingAnimation from "../../loading/LoadingAnimation";
import PropTypes from "prop-types";

// Función para truncar texto
function truncateText(text = "", maxLength = 30) {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
}

export function OperatorTable({ fixedColumnsMode = 3 }) {
  // Para acceder al tema actual
  const { theme } = useLayout();
  
  // Para mostrar notificaciones
  const { showNotification } = useNotification();

  // Estados para la paginación y la búsqueda
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");

  // Asumiendo que en tu backend los operadores tienen roleId = 5
  const roleId = 5;

  // Usamos el hook genérico, pasándole roleId=5
  const { data, isLoading, isError } = useUsersByRole(roleId, page, limit, search);

  // data: { success: true, data: [...], total, currentPage, totalPages }
  const operators = data?.data || [];
  const totalPages = data?.totalPages || 1;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reiniciar a página 1 al cambiar filtro
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Función para copiar texto al portapapeles con notificaciones
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showNotification({
          message: "¡Copiado exitosamente!",
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: "Error al copiar.",
          severity: "error",
        });
      });
  };

  // Manejamos estados de carga o error
  if (isLoading) return <LoadingAnimation />;
  if (isError) return <div className={`GM__${theme}-error-message`}>Hubo un error al cargar los operadores.</div>;

  return (
    <div className={`GM__${theme}-vehicle-table-wrapper`}>
      <div className={`GM__${theme}-header-section`}>
        <h2 className={`GM__${theme}-section-title`}>Operadores</h2>
        <div className={`GM__${theme}-filter-container`}>
          <input
            type="text"
            placeholder="Filtrar por nombre o documento..."
            value={search}
            onChange={handleSearchChange}
            className={`GM__${theme}-search-input`}
          />
        </div>
      </div>

      <div className={`GM__${theme}-vehicle-table-wrapper-table`}>
        <table className={`GM__${theme}-vehicle-table fixed-columns-${fixedColumnsMode}`}>
          <thead>
            <tr>
              <th
                className={`${
                  fixedColumnsMode === 1 || fixedColumnsMode === 3
                    ? "fixed-column fixed-column-1"
                    : ""
                }`}
              >
                Documento
              </th>
              <th
                className={`${
                  fixedColumnsMode === 2 || fixedColumnsMode === 3
                    ? "fixed-column fixed-column-2"
                    : ""
                }`}
              >
                Nombre
              </th>
              <th>Teléfono</th>
              <th>Email</th>
              <th>Activo</th>
            </tr>
          </thead>
          <tbody>
            {operators.length > 0 ? (
              operators.map((op) => (
                <tr key={op.id}>
                  {/* Documento: Truncado, Tooltip y copiable */}
                  <td
                    className={`GM__${theme}-copyable-cell ${
                      fixedColumnsMode === 1 || fixedColumnsMode === 3
                        ? "fixed-column fixed-column-1"
                        : ""
                    }`}
                  >
                    <Tooltip title={op.document_number || "—"}>
                      <span>{truncateText(op.document_number || "", 10)}</span>
                    </Tooltip>
                    {op.document_number && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() => handleCopy(op.document_number)}
                        aria-label="Copiar documento"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  {/* Nombre completo: Truncado, Tooltip y copiable */}
                  <td
                    className={`GM__${theme}-copyable-cell ${
                      fixedColumnsMode === 2 || fixedColumnsMode === 3
                        ? "fixed-column fixed-column-2"
                        : ""
                    }`}
                  >
                    <Tooltip
                      title={`${op.first_name} ${op.first_last_name} ${op.second_last_name || ""}`}
                    >
                      <span>
                        {truncateText(
                          `${op.first_name} ${op.first_last_name} ${op.second_last_name || ""}`,
                          20
                        )}
                      </span>
                    </Tooltip>
                    {(op.first_name || op.first_last_name) && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() =>
                          handleCopy(
                            `${op.first_name} ${op.first_last_name} ${op.second_last_name || ""}`
                          )
                        }
                        aria-label="Copiar nombre completo"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  {/* Teléfono: Truncado, Tooltip y copiable */}
                  <td className={`GM__${theme}-copyable-cell`}>
                    <Tooltip title={op.phone_number || "—"}>
                      <span>{truncateText(op.phone_number || "—", 15)}</span>
                    </Tooltip>
                    {op.phone_number && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() => handleCopy(op.phone_number)}
                        aria-label="Copiar teléfono"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  {/* Email: Truncado, Tooltip y copiable */}
                  <td className={`GM__${theme}-copyable-cell`}>
                    <Tooltip title={op.email || "—"}>
                      <span>{truncateText(op.email || "", 25)}</span>
                    </Tooltip>
                    {op.email && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() => handleCopy(op.email)}
                        aria-label="Copiar email"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  {/* Activo */}
                  <td>{op.active_status ? "Sí" : "No"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className={`GM__${theme}-empty-message`}>
                  No se encontraron operadores
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* PAGINACIÓN */}
      {totalPages > 1 && (
        <div className={`GM__${theme}-pagination-container`}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            className={`GM__${theme}-custom-pagination`}
          />
        </div>
      )}
    </div>
  );
}

OperatorTable.propTypes = {
  fixedColumnsMode: PropTypes.oneOf([1, 2, 3]),
};