import React from "react";
import PropTypes from "prop-types";
import { useVehicleTypes, useVehicleBrands } from "../../hooks/useVehicleQuery";
import SortDropdown from "../ui/dropdawn/SortDropdown";
import CustomInputField from "../ui/inputs/CustomInputField";
import "../../assets/scss/components/vehicle/VehicleInfoSection.scss";

export default function VehicleInfoSection({
  vehicleInfo,
  onChange,
  theme,
  isTrailer,
}) {
  const { data: brandsData } = useVehicleBrands();
  const { data: typesData } = useVehicleTypes();

  const brands = brandsData?.data || [];
  const vehicleTypes = typesData?.data || [];

  // Filtrar tipos de vehículo según sea cabezote o remolque
  const filteredTypes = isTrailer
    ? vehicleTypes.filter((type) => type.category?.includes("Remolque"))
    : vehicleTypes;

  const vehicleLabel = isTrailer ? "Remolque" : "Vehículo";

  const handleBrandChange = (value) => {
    onChange("brand", value);
  };

  const handleTypeChange = (value) => {
    onChange("vehicleTypeId", value);
  };
  const handlePlateChange = (value) => {
    // Convertir a mayúsculas automáticamente
    const upperCaseValue = value.toUpperCase();

    // Actualizar el estado con el nuevo valor
    onChange("plate", upperCaseValue);
  };

  return (
    <div className={`GM__${theme}-vehicle-info-section`}>
      <h2 className={`GM__${theme}-section-title`}>
        Información del {vehicleLabel}
      </h2>

      {isTrailer && (
        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="vehicleTypeId" className={`GM__${theme}-input-label`}>
            Tipo de Remolque
          </label>
          <SortDropdown
            id="vehicleTypeId"
            value={vehicleInfo.vehicleTypeId}
            onChange={handleTypeChange}
            options={filteredTypes.map((type) => ({
              value: type.id,
              label: type.name,
            }))}
            theme={theme}
            placeholder="Seleccione tipo de remolque"
          />
        </div>
      )}

      <div className={`GM__${theme}-info-grid`}>
        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="plate" className={`GM__${theme}-input-label`}>
            Placa
          </label>
          <CustomInputField
            id="plate"
            type="text"
            name="plate"
            value={vehicleInfo.plate}
            onChange={(e) => handlePlateChange(e.target.value)}
            placeholder="Ej. ABC123"
            theme={theme}
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="brand" className={`GM__${theme}-input-label`}>
            Marca
          </label>
          <SortDropdown
            id="brand"
            value={vehicleInfo.brand}
            onChange={handleBrandChange}
            options={brands.map((brand) => ({
              value: brand.id,
              label: brand.name,
            }))}
            theme={theme}
            placeholder="Seleccione una marca"
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="model" className={`GM__${theme}-input-label`}>
            Modelo
          </label>
          <CustomInputField
            id="model"
            type="text"
            name="model"
            value={vehicleInfo.model}
            onChange={(e) => onChange("model", e.target.value)}
            placeholder="Ej. GL 2023"
            theme={theme}
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="year" className={`GM__${theme}-input-label`}>
            Año
          </label>
          <CustomInputField
            id="year"
            type="number"
            name="year"
            value={vehicleInfo.year}
            onChange={(e) => onChange("year", e.target.value)}
            placeholder="Ej. 2023"
            theme={theme}
            min="1900"
            max={new Date().getFullYear() + 1}
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="capacity" className={`GM__${theme}-input-label`}>
            Capacidad (toneladas)
          </label>
          <CustomInputField
            id="capacity"
            type="number"
            name="capacity"
            value={vehicleInfo.capacity}
            onChange={(e) => onChange("capacity", e.target.value)}
            placeholder="Ej. 10"
            theme={theme}
            min="0"
            step="0.1"
          />
        </div>
        <div className={`GM__${theme}-input-group notification-toggle`}>
          <div className={`GM__${theme}-toggles-container`}>
            <div className={`GM__${theme}-toggle-wrapper`}>
              <label
                htmlFor="limitedNotifications"
                className={`GM__${theme}-input-label`}
              >
                Habilitar notificaciones
              </label>
              <div className={`GM__${theme}-toggle-container`}>
                <input
                  id="limitedNotifications"
                  type="checkbox"
                  name="limitedNotifications"
                  checked={vehicleInfo.limitedNotifications}
                  onChange={(e) =>
                    onChange("limitedNotifications", e.target.checked)
                  }
                  className={`GM__${theme}-toggle-input`}
                />
                <span className={`GM__${theme}-toggle-label`}>
                  {vehicleInfo.limitedNotifications
                    ? "Habilitadas"
                    : "Deshabilitadas"}
                </span>
              </div>
            </div>

            <div className={`GM__${theme}-toggle-wrapper`}>
              <label htmlFor="ownFleet" className={`GM__${theme}-input-label`}>
                Flota Propia
              </label>
              <div className={`GM__${theme}-toggle-container`}>
                <input
                  id="ownFleet"
                  type="checkbox"
                  name="ownFleet"
                  checked={vehicleInfo.ownFleet}
                  onChange={(e) => onChange("ownFleet", e.target.checked)}
                  className={`GM__${theme}-toggle-input`}
                />
                <span className={`GM__${theme}-toggle-label`}>
                  {vehicleInfo.ownFleet ? "Sí" : "No"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

VehicleInfoSection.propTypes = {
  vehicleInfo: PropTypes.shape({
    vehicleTypeId: PropTypes.string,
    plate: PropTypes.string,
    brand: PropTypes.string,
    model: PropTypes.string,
    capacity: PropTypes.string,
    year: PropTypes.string,
    ownFleet: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  isTrailer: PropTypes.bool,
};
