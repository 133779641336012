import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import AppHeader from "../components/layout/AppHeader";
import "../assets/scss/layout/DocumentLayout.scss";
import { useEffect } from "react";
import { useDocumentSocket } from "../hooks/useDocumentSocket";
import { useLayout } from "../context/LayoutContext";

const DocumentLayout = ({ children }) => {
  const { documentId } = useParams();
  const { user } = useAuth();
  const { connectToDocument, disconnectFromDocument } = useDocumentSocket();
  const { theme, toggleTheme } = useLayout();

  useEffect(() => {
    if (documentId) {
      connectToDocument(documentId, user?.id);

      return () => {
        disconnectFromDocument(documentId);
      };
    }
  }, [documentId, user, connectToDocument, disconnectFromDocument]);

  return (
    <div className={`document-layout ${theme === 'dark' ? 'dark-theme' : ''}`}>
      <div className="document-container">
        <AppHeader
          showLogo={true}
          documentId={documentId}
          isAuthenticated={!!user}
          theme={theme}
          toggleTheme={toggleTheme}
        />
  
        <main className="document-content">{children}</main>
      </div>
    </div>
  );
};

DocumentLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DocumentLayout;