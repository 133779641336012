import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import YearsImg from '../../assets/img/18_years.png';
import '../../assets/scss/views/Introduction.scss';
import CamaAlta from '../../assets/img/flota/cama_alta.jpg'
import Turbo from '../../assets/img/flota/turbo.jpg'
import Tractocamiones from '../../assets/img/flota/tractomulas.jpg'

// Datos del carrusel - mantener según tu implementación original
const serviciosData = [
  {
    id: 1,
    title: "Carga seca",
    description: "Descripción genérica de lo que vendría a ser la carga seca",
    image: CamaAlta
  },
  {
    id: 2,
    title: "TRACTO CAMIÓN",
    description: "Descripción genérica de lo que vendría a ser la carga líquida",
    image: Tractocamiones
  },
  {
    id: 3,
    title: "Encomiendas",
    description: "Descripción genérica de lo que vendría a ser la carga de encomiendas",
    image: Turbo
  }
];

const Introduction = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  
  // Autoplay para el carrusel
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % serviciosData.length);
    }, 5000);
    
    return () => clearInterval(interval);
  }, []);
  
  // Cambiar a una diapositiva específica
  const goToSlide = (index) => {
    setActiveSlide(index);
  };

  // Función para determinar la clase de cada slide basado en su posición relativa al slide activo
  const getSlideClassName = (index) => {
    if (index === activeSlide) {
      return "carousel-slide active";
    }
    
    // Cálculo de posiciones anterior y siguiente con manejo circular
    const totalSlides = serviciosData.length;
    const prevIndex = activeSlide === 0 ? totalSlides - 1 : activeSlide - 1;
    const nextIndex = (activeSlide + 1) % totalSlides;
    
    if (index === prevIndex) return "carousel-slide prev";
    if (index === nextIndex) return "carousel-slide next";
    
    return "carousel-slide hidden";
  };

  return (
    <Element name="introduction" className="section introduction-section" id="introduction"
    style={{ overflow: 'visible', height: 'auto', minHeight: 'calc(100vh - 82px)', paddingBottom: '75px' }}>
      <div className="introduction-background"></div>
      
      <div className="introduction-container">
        <div className="introduction-content">
          <div className="introduction-header">
            <div className="introduction-title-container">
              <h1 className="introduction-title">Soluciones de Transporte</h1>
              <h1 className="introduction-subtitle">Para Todas Tus Necesidades</h1>
              <p className="introduction-description">
                El transporte que necesitas, con la eficiencia y la seguridad que mereces
              </p>
            </div>
            
            <div className="introduction-image">
              <img 
                src={YearsImg}
                alt="Transporte profesional" 
                className="header-image"
              />
            </div>
          </div>
          
          <div className="introduction-carousel">
            <div className="carousel-container">
              <div className="carousel-wrapper">
                {serviciosData.map((servicio, index) => (
                  <div key={servicio.id} className={getSlideClassName(index)}>
                    <div className="servicio-card">
                      <div className="servicio-image">
                        <img src={servicio.image} alt={servicio.title} />
                      </div>
                      <div className="servicio-info">
                        <h3>{servicio.title}</h3>
                        <p>{servicio.description}</p>
                        
                        <div className="servicio-actions">
                          <button className="btn-info">Más información</button>
                          <button className="btn-cotizar">
                            <span className="icon-user"></span> Cotizar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="carousel-indicators">
              {serviciosData.map((_, index) => (
                <span 
                  key={index} 
                  className={`indicator ${index === activeSlide ? 'active' : ''}`}
                  onClick={() => goToSlide(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Introduction;