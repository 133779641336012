import React, { useState, useMemo } from "react";
import { Modal, Tooltip, Pagination } from "@mui/material";
import PropTypes from "prop-types";
import {
  useCourses,
  useSaveCourse,
  useDeleteCourse,
} from "../../../hooks/useVehicleQuery";
import { useNotification } from "../../../context/NotificationContext";
import { useLayout } from "../../../context/LayoutContext";
import CopyIcon from "../../../assets/svg/components/copy.svg";
import LoadingAnimation from "../../loading/LoadingAnimation";

// Función para truncar texto
function truncateText(text = "", maxLength = 30) {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
}

export function CourseTable({ fixedColumnsMode = 2 }) {
  // Estados para la paginación y búsqueda
  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  const [search, setSearch] = useState("");

  // Obtenemos todos los datos
  const { data, isLoading, isError } = useCourses();
  
  // Procesamos los datos para filtrado y paginación
  const allCourses = data?.data || [];
  
  // Filtrar cursos según el término de búsqueda
  const filteredCourses = useMemo(() => {
    if (!search) return allCourses;
    
    const searchTermLower = search.toLowerCase();
    return allCourses.filter(
      course => 
        (course.name && course.name.toLowerCase().includes(searchTermLower)) ||
        (course.description && course.description.toLowerCase().includes(searchTermLower))
    );
  }, [allCourses, search]);
  
  // Calcular total de páginas y paginar resultados
  const totalPages = Math.ceil(filteredCourses.length / limit);
  const paginatedCourses = useMemo(() => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    return filteredCourses.slice(startIndex, endIndex);
  }, [filteredCourses, page, limit]);

  const saveMutation = useSaveCourse();
  const deleteMutation = useDeleteCourse();

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const { showNotification } = useNotification();
  const { theme } = useLayout();

  if (isLoading) return <LoadingAnimation />;
  if (isError) return <div className={`GM__${theme}-error-message`}>Hubo un error al cargar los cursos.</div>;

  // Abre el modal (crear / editar)
  const handleOpenModal = (course = null) => {
    setEditData(course);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditData(null);
    setShowModal(false);
  };

  // Eliminar (borrado lógico)
  const handleDelete = (id) => {
    if (window.confirm("¿Eliminar este curso?")) {
      deleteMutation.mutate(id);
    }
  };

  // Guardar (crear / editar)
  const handleSave = async (formValues) => {
    await saveMutation.mutateAsync({
      id: formValues.id,
      data: {
        name: formValues.name,
        description: formValues.description,
        price: formValues.price,
        validity_months: formValues.validity_months,
      },
    });
    handleCloseModal();
  };

  // Copiar texto y notificar
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showNotification({
          message: "¡Copiado exitosamente!",
          severity: "success",
        });
      })
      .catch(() => {
        showNotification({
          message: "Error al copiar.",
          severity: "error",
        });
      });
  };
  
  // Función para manejar el cambio en la búsqueda
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reiniciamos a la página 1 cada vez que se filtra
  };

  // Función para manejar el cambio de página
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Formatear precio como moneda
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className={`GM__${theme}-vehicle-table-wrapper`}>
      <div className={`GM__${theme}-header-section`}>
        <h2 className={`GM__${theme}-section-title`}>Cursos</h2>
        <div className={`GM__${theme}-filter-container`}>
          <input
            type="text"
            placeholder="Filtrar por nombre o descripción..."
            value={search}
            onChange={handleSearchChange}
            className={`GM__${theme}-search-input`}
          />
          <button
            className={`GM__${theme}-add-button`}
            onClick={() => handleOpenModal(null)}
          >
            Agregar nuevo curso
          </button>
        </div>
      </div>
      <div className={`GM__${theme}-vehicle-table-wrapper-table`}>
        <table
          className={`GM__${theme}-vehicle-table fixed-columns-${fixedColumnsMode}`}
        >
          <thead>
            <tr>
              <th
                className={`${
                  fixedColumnsMode === 1 || fixedColumnsMode === 3
                    ? "fixed-column fixed-column-1"
                    : ""
                }`}
              >
                ID
              </th>
              <th
                className={`${
                  fixedColumnsMode === 2 || fixedColumnsMode === 3
                    ? "fixed-column fixed-column-2"
                    : ""
                }`}
              >
                Nombre
              </th>
              <th>Descripción</th>
              <th>Precio</th>
              <th>Vigencia (meses)</th>
              <th>Activo</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {paginatedCourses.length > 0 ? (
              paginatedCourses.map((course) => (
                <tr key={course.id}>
                  <td
                    className={`GM__${theme}-copyable-cell ${
                      fixedColumnsMode === 1 || fixedColumnsMode === 3
                        ? "fixed-column fixed-column-1"
                        : ""
                    }`}
                  >
                    <Tooltip title={course.id?.toString() || "—"}>
                      <span>{truncateText(course.id?.toString() || "", 10)}</span>
                    </Tooltip>
                    <button
                      className={`GM__${theme}-copy-icon`}
                      onClick={() => handleCopy(course.id?.toString() || "")}
                      aria-label="Copiar ID"
                    >
                      <img src={CopyIcon} alt="Copiar" />
                    </button>
                  </td>

                  <td
                    className={`GM__${theme}-copyable-cell ${
                      fixedColumnsMode === 2 || fixedColumnsMode === 3
                        ? "fixed-column fixed-column-2"
                        : ""
                    }`}
                  >
                    <Tooltip title={course.name || "—"}>
                      <span>{truncateText(course.name || "", 15)}</span>
                    </Tooltip>
                    {course.name && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() => handleCopy(course.name || "")}
                        aria-label="Copiar nombre"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  <td className={`GM__${theme}-copyable-cell`}>
                    <Tooltip title={course.description || "—"}>
                      <span>{truncateText(course.description || "", 20)}</span>
                    </Tooltip>
                    {course.description && (
                      <button
                        className={`GM__${theme}-copy-icon`}
                        onClick={() => handleCopy(course.description || "")}
                        aria-label="Copiar descripción"
                      >
                        <img src={CopyIcon} alt="Copiar" />
                      </button>
                    )}
                  </td>

                  <td>{formatCurrency(course.price || 0)}</td>
                  <td>{course.validity_months || 12}</td>
                  <td>{course.active ? "Sí" : "No"}</td>

                  <td className={`GM__${theme}-action-buttons`}>
                    <button
                      className={`GM__${theme}-edit-button`}
                      onClick={() => handleOpenModal(course)}
                    >
                      Editar
                    </button>
                    <button
                      className={`GM__${theme}-delete-button`}
                      onClick={() => handleDelete(course.id)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className={`GM__${theme}-empty-message`}>
                  {search ? "No se encontraron cursos con ese filtro" : "No hay cursos registrados"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {/* PAGINACIÓN - Solo se muestra si hay más de una página */}
      {totalPages > 1 && (
        <div className={`GM__${theme}-pagination-container`}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            className={`GM__${theme}-custom-pagination`}
          />
        </div>
      )}

      <Modal
        open={showModal}
        onClose={handleCloseModal}
        className={`GM__modal-container-${theme}`}
        BackdropProps={{
          style: { backgroundColor: "transparent" },
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CourseForm
          data={editData}
          onSave={handleSave}
          onCancel={handleCloseModal}
          theme={theme}
        />
      </Modal>
    </div>
  );
}

CourseTable.propTypes = {
  fixedColumnsMode: PropTypes.oneOf([1, 2, 3]),
};

function CourseForm({ data, onSave, onCancel, theme }) {
  const [formValues, setFormValues] = useState(
    data || {
      id: null,
      name: "",
      description: "",
      price: 70000,
      validity_months: 12,
    }
  );

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Para campos numéricos, convertir a número
    if (name === 'price' || name === 'validity_months') {
      const numValue = parseFloat(value);
      setFormValues((prev) => ({ 
        ...prev, 
        [name]: isNaN(numValue) ? '' : numValue 
      }));
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.name.trim()) newErrors.name = "El nombre es obligatorio.";
    if (!formValues.description.trim())
      newErrors.description = "La descripción es obligatoria.";
    if (!formValues.price && formValues.price !== 0)
      newErrors.price = "El precio es obligatorio.";
    if (!formValues.validity_months && formValues.validity_months !== 0)
      newErrors.validity_months = "La vigencia es obligatoria.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      onSave(formValues);
    }
  };

  return (
    <div className={`GM__${theme}-modal GM__${theme}-vehicle-type-modal`}>
      <div className={`GM__${theme}-modal-header-container`}>
        <h3 className={`GM__${theme}-modal-header`}>
          {data ? "Editar Curso" : "Nuevo Curso"}
        </h3>
        <div className={`GM__${theme}-modal-subtitle`}>
          {data ? "Modificar información del curso" : "Crear nuevo curso"}
        </div>
      </div>

      <div className={`GM__${theme}-modal-content`}>
        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-form-group full-width`}>
            <label className={`GM__${theme}-form-label`} htmlFor="name">
              Nombre
            </label>
            <input
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              placeholder="Ej: Certificado Manejo defensivo"
              className={`GM__${theme}-form-input ${
                errors.name ? "is-invalid" : ""
              }`}
            />
            {errors.name && (
              <span className={`GM__${theme}-form-error`}>{errors.name}</span>
            )}
          </div>
        </div>

        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-form-group full-width`}>
            <label className={`GM__${theme}-form-label`} htmlFor="description">
              Descripción
            </label>
            <input
              id="description"
              name="description"
              value={formValues.description}
              onChange={handleChange}
              placeholder="Ej: Curso para conducción segura y preventiva"
              className={`GM__${theme}-form-input ${
                errors.description ? "is-invalid" : ""
              }`}
            />
            {errors.description && (
              <span className={`GM__${theme}-form-error`}>
                {errors.description}
              </span>
            )}
          </div>
        </div>

        <div className={`GM__${theme}-form-row`}>
          <div className={`GM__${theme}-form-group`}>
            <label className={`GM__${theme}-form-label`} htmlFor="price">
              Precio
            </label>
            <input
              id="price"
              name="price"
              type="number"
              value={formValues.price}
              onChange={handleChange}
              placeholder="Ej: 70000"
              className={`GM__${theme}-form-input ${
                errors.price ? "is-invalid" : ""
              }`}
            />
            {errors.price && (
              <span className={`GM__${theme}-form-error`}>
                {errors.price}
              </span>
            )}
          </div>

          <div className={`GM__${theme}-form-group`}>
            <label className={`GM__${theme}-form-label`} htmlFor="validity_months">
              Vigencia (meses)
            </label>
            <input
              id="validity_months"
              name="validity_months"
              type="number"
              min="1"
              value={formValues.validity_months}
              onChange={handleChange}
              placeholder="Ej: 12"
              className={`GM__${theme}-form-input ${
                errors.validity_months ? "is-invalid" : ""
              }`}
            />
            {errors.validity_months && (
              <span className={`GM__${theme}-form-error`}>
                {errors.validity_months}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className={`GM__${theme}-modal-footer`}>
        <button
          className={`GM__${theme}-btn GM__${theme}-btn-cancel`}
          onClick={onCancel}
        >
          Cancelar
        </button>
        <button
          className={`GM__${theme}-btn GM__${theme}-btn-save`}
          onClick={handleSubmit}
        >
          {data ? "Guardar Cambios" : "Crear"}
        </button>
      </div>
    </div>
  );
}

CourseForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    validity_months: PropTypes.number,
    active: PropTypes.bool,
  }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};