import React from "react";
import dayjs from "dayjs";
import "../../assets/scss/views/Hotspots.scss";
import { useLayout } from "../../context/LayoutContext";
import { Button, Chip, IconButton } from "@mui/material";
import { DownloadIcon } from "lucide-react";
import CriticalPointsTable from "../../components/ui/tables/CriticalPointsTable";
import LoadingAnimation from "../../components/loading/LoadingAnimation";

export default function Hotspots() {
  const { monthlyAnalytics, isLoading } = useLayout();
  const analyticsData = monthlyAnalytics?.processedData || {};
  
  const processCriticalPointsData = () => {
    if (!analyticsData.criticalPoints || !analyticsData.recurrences?.criticalPointViolations) {
      return {
        criticalPoints: [],
        violations: []
      };
    }

    // Procesamos los puntos críticos
    const criticalPoints = analyticsData.criticalPoints.map(point => ({
      id: point.id,
      point_name: point.point_name
    }));

    // Procesamos las violaciones
    const violations = analyticsData.recurrences.criticalPointViolations.map(violation => {
      return violation.occurrences.map(occurrence => ({
        critical_point_id: violation.criticalPointId,
        violation_details: [{
          mes: new Date(occurrence.dateTime).getDate(), // Obtenemos el día del mes
          speed: occurrence.speed,
          location: occurrence.location,
          dateTime: occurrence.dateTime,
          tripData: occurrence.tripData
        }]
      }));
    }).flat();

    return {
      criticalPoints,
      violations
    };
  };

  const { criticalPoints, violations } = processCriticalPointsData();

  const styleSheet = document.createElement("style");
  styleSheet.textContent = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  document.head.appendChild(styleSheet);

  // Función de descarga
  const handleDownload = async () => {
    try {
      // Implementar lógica de descarga basada en la fecha seleccionada
      alert(
        "¡De momento no se ha generado las plantillas, estamos en el proceso!"
      );
    } catch (error) {
      console.error("Error al descargar:", error);
    }
  };
  return (
    <div className={`hotspots-container`}>
      <div className="hotspots-header">
        <h1 className="hotspots-title">Puntos Críticos</h1>
      </div>
      {isLoading && (
        <LoadingAnimation/>
      )}
      <div className="hotspots-content">
        <CriticalPointsTable
          criticalPoints={criticalPoints}
          violations={violations}
          theme="dark"
          period={analyticsData.period}
        />
      </div>

      <div className="hotspots-download-section">
        <div className="download-button">
          <span>Descargar</span>
          <Chip
            label={dayjs(analyticsData.period).format("MMMM YYYY")}
            variant="outlined"
          />
          <IconButton color="primary" onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
        </div>
        <Button
          variant="contained"
          className="download-period-btn"
          onClick={handleDownload}
        >
          Descargar Periodo
        </Button>
        <Button
          variant="contained"
          className="download-year-btn"
          onClick={handleDownload}
        >
          Descargar Año
        </Button>
      </div>
    </div>
  );
}