import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSatelliteData } from "../context/SatelliteDataContext";
import "../assets/scss/views/DataVisualization.scss";
import FloatingCard from "./ui/cards/FloatingCard";
import MapIcon from "../assets/svg/components/map.svg";
import { Paper, useTheme } from "@mui/material";
import LineChart from "./ui/charts/LineChart";
import PieChart from "./ui/charts/PieChart";
import CarouselCards from "./ui/carousel/CarouselCards";
import TransitionCard from "./ui/cards/TransitionCard";

export default function DataVisualization({ data, onBack }) {
  const { dataConvert, handleSaveProcessedData } = useSatelliteData();
  const theme = useTheme();
  const totalExcesos =
    dataConvert.processedData?.globalStats?.excesosVelocidad?.length || 0;
  const processEventData = (vehicles) => {
    const eventData = {
      "Cambios de ruta": [],
      "Aceleración brusca": [],
      "Reporte por distancia": [],
      "Exceso de velocidad": [],
    };

    // Procesamos todos los vehículos
    Object.entries(vehicles).forEach(([key, events]) => {
      const [placa] = key.split("-");

      events.forEach((event) => {
        if (eventData[event.evento]) {
          eventData[event.evento].push({
            placa,
            direccion: event.direccion,
            latitud: event.latitud,
            longitud: event.longitud,
          });
        }
      });
    });

    // Agregamos conteos y organizamos la data final
    return Object.entries(eventData).map(([evento, datos]) => {
      const direccionesPorPlaca = datos.reduce((acc, curr) => {
        if (!acc[curr.placa]) {
          acc[curr.placa] = {
            direcciones: [],
            count: 0,
          };
        }
        acc[curr.placa].direcciones.push({
          direccion: curr.direccion,
          latitud: curr.latitud,
          longitud: curr.longitud,
        });
        acc[curr.placa].count++;
        return acc;
      }, {});

      return {
        evento,
        placasInfo: direccionesPorPlaca,
      };
    });
  };
  const eventData = useMemo(
    () => processEventData(dataConvert.processedData?.vehicles || {}),
    [dataConvert]
  );
  // Datos para el gráfico de pie
  const pieChartData = useMemo(() => {
    const { processedData } = dataConvert;
    const eventCounts = {
      "Cambios de ruta": 0,
      "Aceleración brusca": 0,
      "Reporte por distancia": 0,
      "Exceso de velocidad": 0,
    };

    // Obtener el mes actual de los datos
    const months = processedData?.months || {};
    const currentMonth = Object.keys(months)[0];

    // Procesar eventos por vehículo
    Object.entries(processedData?.vehicles || {}).forEach(([_, events]) => {
      events.forEach((event) => {
        switch (event.evento) {
          case "Cambios de ruta":
            eventCounts["Cambios de ruta"]++;
            break;
          case "Aceleración brusca":
            eventCounts["Aceleración brusca"]++;
            break;
          case "Reporte por distancia":
            eventCounts["Reporte por distancia"]++;
            break;
          case "Exceso de velocidad":
            eventCounts["Exceso de velocidad"]++;
            break;
          default:
            break;
        }
      });
    });

    // Convertir a formato requerido por ECharts
    const formattedData = Object.entries(eventCounts)
      .filter(([_, value]) => value > 0)
      .map(([name, value]) => ({ name, value }));

    return {
      data: formattedData,
      centerText: currentMonth || "Sin fecha disponible",
    };
  }, [dataConvert]);
  // Datos para la grafica line
  const chartData = useMemo(() => {
    const { processedData } = dataConvert;
    const months = processedData?.months || {};
    const currentMonth = Object.keys(months)[0]; // Tomamos el primer mes disponible

    if (!currentMonth) return { xAxisData: [], seriesData: [] };

    // Crear array de días del mes
    const daysInMonth = new Date(
      parseInt(currentMonth.split("-")[0]),
      parseInt(currentMonth.split("-")[1]),
      0
    ).getDate();
    const xAxisData = Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);

    // Inicializar contadores por día
    const tripsByDay = Array(daysInMonth).fill(0);
    const speedExcessByDay = Array(daysInMonth).fill(0);
    const routeChangesByDay = Array(daysInMonth).fill(0);

    // Procesar viajes
    Object.entries(processedData.trips || {}).forEach(([_, trips]) => {
      trips.forEach((trip) => {
        const day = new Date(trip.puntoOrigen?.fecha).getDate();
        tripsByDay[day - 1]++;
      });
    });

    // Procesar eventos por vehículo
    Object.entries(processedData.vehicles || {}).forEach(([key, events]) => {
      events.forEach((event) => {
        const day = new Date(event.fecha).getDate();
        if (event.evento === "Exceso de velocidad") {
          speedExcessByDay[day - 1]++;
        } else if (event.evento === "Reporte por distancia") {
          routeChangesByDay[day - 1]++;
        }
      });
    });

    const seriesData = [
      {
        name: "Viajes totales",
        data: tripsByDay,
      },
      {
        name: "Excesos de velocidad",
        data: speedExcessByDay,
      },
      {
        name: "Cambios de ruta",
        data: routeChangesByDay,
      },
    ];

    return { xAxisData, seriesData };
  }, [dataConvert]);

  // Guardar Datos
  return (
    <div className="data-visualization">
      <div className="cards-data">
        <div className="card">
          <FloatingCard
            title="Viajes en el mes"
            value={dataConvert.processedData?.globalStats?.totalViajes}
            icon={MapIcon}
            colorTheme="theme1"
          />
        </div>
        <div className="card">
          <FloatingCard
            title="Total de registros"
            value={dataConvert.processedData?.globalStats?.totalRecords}
            icon={MapIcon}
            colorTheme="theme1"
          />
        </div>
        <div className="card">
          <FloatingCard
            title="Vehículos totales"
            value={dataConvert.processedData?.globalStats?.vehiculosTotales}
            icon={MapIcon}
            colorTheme="theme2"
          />
        </div>
        <div className="card">
          <FloatingCard
            title="Excesos de velocidad"
            value={totalExcesos}
            icon={MapIcon}
            colorTheme="theme3"
          />
        </div>
        {/* <div className="card">
          <FloatingCard
            title="Cantidad de paradas"
            value={dataConvert.processedData.cantidadParadas}
            icon={MapIcon}
            colorTheme="theme4"
          />
        </div> */}
      </div>
      <div className="charts">
        <div className="chart">
          <Paper elevation={3} style={{ padding: "1rem", height: "100%" }}>
            <LineChart
              title="Análisis de Eventos por Día"
              xAxisData={chartData.xAxisData}
              seriesData={chartData.seriesData}
              theme="dark" // o "light"
              height="400px"
            />
          </Paper>
        </div>
        <div className="chart">
          <Paper elevation={3} style={{ padding: "1rem", height: "100%" }}>
            <PieChart
              title="Distribución de Tipos de Eventos"
              data={pieChartData.data}
              theme={"dark"}
              height="400px"
              centerText={pieChartData.centerText}
            />
          </Paper>
        </div>
      </div>
      <div className="events">
        <div className="carousel">
          <CarouselCards itemsPerView={3} autoRun={true} autoRunInterval={5000}>
          {eventData.map((eventInfo, index) => {
            // Determine the borderColor based on the index
            const borderColor = (() => {
              switch (index) {
                case 0:
                  return "#af5e5e";
                case 1:
                  return "#5eaf5e";
                case 2:
                  return "#5e5eaf";
                default:
                  return "#af5eaf";
              }
            })();

            return (
              <TransitionCard
                key={`${eventInfo.evento}-${index}`} 
                evento={eventInfo.evento}
                placasInfo={eventInfo.placasInfo}
                theme={theme.palette.mode} // 'dark' o 'light'
                borderColor={borderColor}
                autoRunInterval={4000}
              />
            );
          })}
          </CarouselCards>
        </div>
      </div>

      <div className="actions">
        <button className="btn btn-outline" onClick={onBack}>
          Atrás
        </button>
        <button className="btn btn-primary" onClick={()=> handleSaveProcessedData()}>
          Guardar Datos
        </button>
      </div>
    </div>
  );
}
DataVisualization.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBack: PropTypes.func.isRequired,
};
