import { useApi } from "./api";


export const useChatService = () => {
  const api = useApi();

  return {
    // Obtención de conversaciones recientes
    getRecentConversations: async (limit = 5, offset = 0) => {
      try {
        const response = await api.get("/mssg/conversations", {
          params: { limit, offset },
        });
        return response.data;
      } catch (error) {
        console.error("Error obteniendo conversaciones:", error);
        throw error.response?.data || error.message;
      }
    },

    // Obtención de mensajes de una conversación específica
    getConversationMessages: async (conversationId, limit = 50, before = null) => {
      try {
        const response = await api.get(`/mssg/conversations/${conversationId}/messages`, {
          params: { limit, before },
        });
        return response.data;
      } catch (error) {
        console.error("Error obteniendo mensajes:", error);
        throw error.response?.data || error.message;
      }
    },

    // Envío de nuevo mensaje
    sendMessage: async (conversationId, content, files = [], replyToMessageId = null) => {
      try {
        const formData = new FormData();
        formData.append("content", content);
        if (replyToMessageId) formData.append("replyToMessageId", replyToMessageId);
        files.forEach((file) => formData.append("files", file));

        const response = await api.post(`/mssg/conversations/${conversationId}/messages`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        return response.data;
      } catch (error) {
        console.error("Error enviando mensaje:", error);
        throw error.response?.data || error.message;
      }
    },

    // Edición de mensaje
    editMessage: async (messageId, content) => {
      try {
        const response = await api.put(`/mssg/messages/${messageId}`, { content });
        return response.data;
      } catch (error) {
        console.error("Error editando mensaje:", error);
        throw error.response?.data || error.message;
      }
    },

    // Eliminación de mensaje
    deleteMessage: async (messageId) => {
      try {
        const response = await api.delete(`/mssg/messages/${messageId}`);
        return response.data;
      } catch (error) {
        console.error("Error eliminando mensaje:", error);
        throw error.response?.data || error.message;
      }
    },

    // Búsqueda de mensajes
    searchMessages: async (query) => {
      try {
        const response = await api.get("/mssg/search", { params: { query } });
        return response.data;
      } catch (error) {
        console.error("Error en búsqueda de mensajes:", error);
        throw error.response?.data || error.message;
      }
    },

    // Marcar conversación como leída
    markConversationAsRead: async (conversationId) => {
      try {
        const response = await api.post(`/mssg/conversations/${conversationId}/read`);
        return response.data;
      } catch (error) {
        console.error("Error marcando conversación como leída:", error);
        throw error.response?.data || error.message;
      }
    },
    uploadFiles: async (files) => {
      try {
        const formData = new FormData();
        files.forEach(fileData => {
          const file = fileData.file || fileData;
          formData.append('files', file);
        });
  
        const response = await api.post('/files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
  
        return response.data;
      } catch (error) {
        console.error("Error subiendo archivos:", error);
        throw error.response?.data || error.message;
      }
    },
  };
  
};
