// src/utils/routeUtils.js
// src/utils/routeUtils.js
export const calculateRoute = (waypoints) => {
  return new Promise((resolve, reject) => {
    if (!waypoints || waypoints.length < 2) {
      return reject(new Error("Se necesitan al menos dos puntos para calcular una ruta"));
    }
    
    console.log("Calculando ruta entre puntos:", waypoints);
    
    // Verificación de la API de Google Maps
    if (!window.google || !window.google.maps) {
      console.error("Google Maps API no está disponible");
      return resolve(null); // Fallback a línea recta
    }
    
    const directionsService = new window.google.maps.DirectionsService();
    
    const origin = waypoints[0];
    const destination = waypoints[waypoints.length - 1];
    
    const intermediateWaypoints = waypoints.slice(1, -1).map(point => ({
      location: new window.google.maps.LatLng(point.lat, point.lng),
      stopover: true
    }));
    
    const request = {
      origin: new window.google.maps.LatLng(origin.lat, origin.lng),
      destination: new window.google.maps.LatLng(destination.lat, destination.lng),
      waypoints: intermediateWaypoints,
      travelMode: window.google.maps.TravelMode.DRIVING,
      optimizeWaypoints: false,
      avoidHighways: false,
      avoidTolls: false
    };
    
    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        console.log("Ruta calculada exitosamente:", result);
        
        // Verificación adicional para asegurar datos válidos
        if (result && result.routes && result.routes.length > 0) {
          resolve(result);
        } else {
          console.error("Respuesta de dirección inválida:", result);
          resolve(null);
        }
      } else {
        console.error("Error al calcular la ruta:", status);
        resolve(null); // Fallback a línea recta
      }
    });
  });
};

/**
 * Calcula la distancia total de una ruta
 * @param {Object} routeData - Datos de la ruta calculada
 * @returns {Number} - Distancia en kilómetros
 */
export const calculateTotalDistance = (routeData) => {
  if (!routeData || !routeData.routes || !routeData.routes[0]) {
    return 0;
  }

  let total = 0;
  const route = routeData.routes[0];
  const legs = route.legs;

  for (let i = 0; i < legs.length; i++) {
    total += legs[i].distance.value;
  }

  // Convertir de metros a kilómetros
  return total / 1000;
};

/**
 * Verifica si un punto está cerca de una ruta
 * @param {Object} point - Objeto {lat, lng}
 * @param {Object} routeData - Datos de la ruta calculada
 * @param {Number} tolerance - Distancia de tolerancia en metros
 * @returns {Boolean} - true si el punto está cerca de la ruta
 */
export const isPointNearRoute = (point, routeData, tolerance = 50) => {
  if (!routeData || !routeData.routes || !routeData.routes[0]) {
    return false;
  }

  const route = routeData.routes[0];
  const legs = route.legs;

  for (let i = 0; i < legs.length; i++) {
    const steps = legs[i].steps;

    for (let j = 0; j < steps.length; j++) {
      const path = steps[j].path;

      for (let k = 0; k < path.length - 1; k++) {
        const segment = [
          { lat: path[k].lat(), lng: path[k].lng() },
          { lat: path[k + 1].lat(), lng: path[k + 1].lng() },
        ];

        const distance = calculateDistanceFromPointToSegment(
          point,
          segment[0],
          segment[1]
        );

        if (distance <= tolerance) {
          return true;
        }
      }
    }
  }

  return false;
};

/**
 * Calcula la distancia desde un punto a un segmento de línea
 * @param {Object} point - Punto a evaluar {lat, lng}
 * @param {Object} lineStart - Inicio del segmento {lat, lng}
 * @param {Object} lineEnd - Fin del segmento {lat, lng}
 * @returns {Number} - Distancia en metros
 */
const calculateDistanceFromPointToSegment = (point, lineStart, lineEnd) => {
  const geometry = window.google.maps.geometry.spherical;

  // Calcular vectores
  const startToEnd = geometry.computeHeading(
    new window.google.maps.LatLng(lineStart.lat, lineStart.lng),
    new window.google.maps.LatLng(lineEnd.lat, lineEnd.lng)
  );

  const startToPoint = geometry.computeHeading(
    new window.google.maps.LatLng(lineStart.lat, lineStart.lng),
    new window.google.maps.LatLng(point.lat, point.lng)
  );

  // Calcular distancias
  const distanceStartToPoint = geometry.computeDistanceBetween(
    new window.google.maps.LatLng(lineStart.lat, lineStart.lng),
    new window.google.maps.LatLng(point.lat, point.lng)
  );

  // Calcular ángulo
  const angle = Math.abs(startToEnd - startToPoint);

  // Calcular distancia perpendicular
  return Math.abs(distanceStartToPoint * Math.sin((angle * Math.PI) / 180));
};
