import React, { useState } from "react";
import { exportToKmz, exportToGeoJson } from "../../utils/kmzExporter";
import "../../assets/scss/components/ExportManager.scss";

const ExportManager = ({ routes, annotations }) => {
  const [exportOptions, setExportOptions] = useState({
    filename: "mis_rutas",
    includeHiddenRoutes: false,
    includeAnnotations: true,
    format: "kmz"
  });

  const handleExport = async () => {
    try {
      // Filtrar rutas según las opciones
      const filteredRoutes = routes.filter(route => 
        exportOptions.includeHiddenRoutes || route.visible
      );
      
      // Filtrar anotaciones
      const filteredAnnotations = exportOptions.includeAnnotations 
        ? annotations 
        : [];
      
      // Ejecutar la exportación según el formato seleccionado
      if (exportOptions.format === "kmz") {
        await exportToKmz(filteredRoutes, filteredAnnotations, exportOptions.filename);
      } else if (exportOptions.format === "geojson") {
        await exportToGeoJson(filteredRoutes, filteredAnnotations, exportOptions.filename);
      }
      
      // Mensaje de éxito
      alert("Exportación completada con éxito");
    } catch (error) {
      console.error("Error al exportar:", error);
      alert("Error al exportar: " + error.message);
    }
  };

  return (
    <div className="export-manager">
      <h3>Exportar Mapa</h3>
      
      <div className="form-group">
        <label>Nombre del archivo</label>
        <input
          type="text"
          value={exportOptions.filename}
          onChange={(e) => setExportOptions({ ...exportOptions, filename: e.target.value })}
          placeholder="Nombre del archivo"
          className="form-control"
        />
      </div>
      
      <div className="form-group">
        <label>Formato de exportación</label>
        <select
          value={exportOptions.format}
          onChange={(e) => setExportOptions({ ...exportOptions, format: e.target.value })}
          className="form-control"
        >
          <option value="kmz">KMZ (Google Earth, Google Maps)</option>
          <option value="geojson">GeoJSON</option>
        </select>
      </div>
      
      <div className="form-group">
        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={exportOptions.includeHiddenRoutes}
            onChange={(e) => setExportOptions({ ...exportOptions, includeHiddenRoutes: e.target.checked })}
          />
          Incluir rutas ocultas
        </label>
      </div>
      
      <div className="form-group">
        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={exportOptions.includeAnnotations}
            onChange={(e) => setExportOptions({ ...exportOptions, includeAnnotations: e.target.checked })}
          />
          Incluir anotaciones
        </label>
      </div>
      
      <div className="export-stats">
        <div className="stat">
          <span className="stat-label">Rutas a exportar:</span>
          <span className="stat-value">
            {exportOptions.includeHiddenRoutes
              ? routes.length
              : routes.filter(r => r.visible).length}
          </span>
        </div>
        
        <div className="stat">
          <span className="stat-label">Anotaciones a exportar:</span>
          <span className="stat-value">
            {exportOptions.includeAnnotations ? annotations.length : 0}
          </span>
        </div>
      </div>
      
      <button
        className="btn btn-primary export-btn"
        onClick={handleExport}
        disabled={routes.length === 0 && annotations.length === 0}
      >
        Exportar
      </button>
      
      <div className="export-help">
        <h4>Instrucciones</h4>
        <p>
          Los archivos KMZ pueden abrirse con Google Earth y Google Maps. Los archivos
          GeoJSON son compatibles con muchas herramientas de mapeo y GIS.
        </p>
      </div>
    </div>
  );
};

export default ExportManager;