import React from "react";
import CustomInputField from "../ui/inputs/CustomInputField";
import CustomDatePicker from "../ui/CustomDatePicker";
import UploadIcon from "../../assets/svg/components/upload.svg";
import CalendarIcon from "../../assets/svg/components/calendar.svg";
import FileDropzone from "../FileDropZone";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  useDocumentTypes,
  usePhoneCountryCodes,
} from "../../hooks/userComplementaryQuery";
import SortDropdown from "../ui/dropdawn/SortDropdown";

export default function MainSectionAddPersons({
  personalInfo,
  onPersonalInfoChange,
  onDateChange,
  onProfilePhotoChange,
  profilePhotoTypeId,
  theme,
  ownFleet,
}) {
  const { data: documentTypes, isLoading: isLoadingDocTypes } =
    useDocumentTypes();
  const { data: countryCodes, isLoading: isLoadingCountryCodes } =
    usePhoneCountryCodes();
  const documentTypesData = documentTypes?.data || [];
  const countryCodesData = countryCodes?.data || [];

  const handleDocumentTypeChange = (value) => {
    onPersonalInfoChange({
      target: {
        name: "documentTypeId",
        value,
      },
    });
  };
  const handleNationalityChange = (value) => {
    onPersonalInfoChange({
      target: {
        name: "nationality",
        value,
      },
    });
  };
  const handleLimitedNotificationsChange = (e) => {
    onPersonalInfoChange({
      target: {
        name: "limitedNotifications",
        value: e.target.checked,
      },
    });
  };
  const handleOwnFleetChange = (e) => {
    onPersonalInfoChange({
      target: {
        name: "ownFleet",
        value: e.target.checked,
      },
    });
  };

  return (
    <div className={`GM__${theme}-main-section`}>
      <div className={`GM__${theme}-left-column`}>
        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="firstName" className={`GM__${theme}-input-label`}>
            Nombre
          </label>
          <CustomInputField
            id="firstName"
            type="text"
            name="firstName"
            value={personalInfo.firstName}
            onChange={onPersonalInfoChange}
            placeholder="Ej. Juan Carlos"
            theme={theme}
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label htmlFor="firstLastName" className={`GM__${theme}-input-label`}>
            Primer Apellido
          </label>
          <CustomInputField
            id="firstLastName"
            type="text"
            name="firstLastName"
            value={personalInfo.firstLastName}
            onChange={onPersonalInfoChange}
            placeholder="Ej. Pérez"
            theme={theme}
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label
            htmlFor="secondLastName"
            className={`GM__${theme}-input-label`}
          >
            Segundo Apellido
          </label>
          <CustomInputField
            id="secondLastName"
            type="text"
            name="secondLastName"
            value={personalInfo.secondLastName}
            onChange={onPersonalInfoChange}
            placeholder="Ej. Martínez"
            theme={theme}
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label
            htmlFor="documentTypeId"
            className={`GM__${theme}-input-label`}
          >
            Tipo de Documento
          </label>
          <SortDropdown
            value={personalInfo.documentTypeId || ""}
            onChange={handleDocumentTypeChange}
            options={
              !isLoadingDocTypes && documentTypesData
                ? documentTypesData.map((type) => ({
                    value: type.id,
                    label: type.name,
                  }))
                : []
            }
            theme={theme}
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label
            htmlFor="documentNumber"
            className={`GM__${theme}-input-label`}
          >
            Documento de identidad
          </label>
          <CustomInputField
            id="documentNumber"
            type="text"
            name="documentNumber"
            value={personalInfo.documentNumber}
            onChange={onPersonalInfoChange}
            placeholder="Ej. 12345678"
            theme={theme}
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label
            htmlFor="documentExpDate"
            className={`GM__${theme}-input-label`}
          >
            Fecha de Expedición
          </label>
          <CustomDatePicker
            id="documentExpDate"
            value={
              personalInfo.documentExpDate
                ? dayjs(personalInfo.documentExpDate).toDate()
                : null
            }
            onChange={(date) => onDateChange("documentExpDate", date)}
            icon={<img src={CalendarIcon} alt="calendar" />}
            theme={theme}
          />
        </div>

        <div className={`GM__${theme}-input-group`}>
          <label
            htmlFor="documentExpeditionPlace"
            className={`GM__${theme}-input-label`}
          >
            Lugar de Expedición
          </label>
          <CustomInputField
            id="documentExpeditionPlace"
            type="text"
            name="documentExpeditionPlace"
            value={personalInfo.documentExpeditionPlace}
            onChange={onPersonalInfoChange}
            placeholder="Ej. Bogotá"
            theme={theme}
          />
        </div>
      </div>

      <div className={`GM__${theme}-right-column`}>
        <div className={`GM__${theme}-photo-section`}>
          <FileDropzone
            icon={UploadIcon}
            singleFile={true}
            onFileUpload={(files) => {
              if (files && files.length > 0) {
                // Incluimos el documentTypeId en el archivo
                const fileWithType = {
                  ...files[0],
                  documentTypeId: profilePhotoTypeId,
                };
                onProfilePhotoChange([fileWithType]);
              }
            }}
            externalFiles={
              personalInfo.profilePhoto ? [personalInfo.profilePhoto] : []
            }
            size="medium"
            title="Subir foto de perfil"
            acceptedFileTypes={{
              "image/jpeg": [".jpeg", ".jpg"],
              "image/png": [".png"],
            }}
            maxFileSize={4097152}
            theme={theme}
          />
        </div>

        <div className={`GM__${theme}-additional-info`}>
          <div className={`GM__${theme}-input-group`}>
            <label htmlFor="birthDate" className={`GM__${theme}-input-label`}>
              Fecha de Nacimiento
            </label>
            <CustomDatePicker
              id="birthDate"
              value={
                personalInfo.birthDate
                  ? dayjs(personalInfo.birthDate).toDate()
                  : null
              }
              onChange={(date) => onDateChange("birthDate", date)}
              icon={<img src={CalendarIcon} alt="calendar" />}
              theme={theme}
            />
          </div>

          <div className={`GM__${theme}-input-group`}>
            <label htmlFor="nationality" className={`GM__${theme}-input-label`}>
              Nacionalidad
            </label>
            <SortDropdown
              value={personalInfo.nationality || ""}
              onChange={handleNationalityChange}
              options={
                !isLoadingCountryCodes && countryCodesData
                  ? countryCodesData.map((country) => ({
                      value: country.id,
                      label: country.country_name,
                      country_code: country.country_code,
                    }))
                  : []
              }
              type="nationality"
              theme={theme}
              placeholder="Seleccione país"
            />
          </div>
          <div
            className={`GM__${theme}-input-group GM__${theme}-notification-toggle`}
          >
            <div className={`GM__${theme}-toggles-container`}>
              <div className={`GM__${theme}-toggle-wrapper`}>
                <label
                  htmlFor="limitedNotifications"
                  className={`GM__${theme}-input-label`}
                >
                  Habilitar notificaciones
                </label>
                <div className={`GM__${theme}-toggle-container`}>
                  <input
                    id="limitedNotifications"
                    type="checkbox"
                    name="limitedNotifications"
                    checked={personalInfo.limitedNotifications}
                    onChange={handleLimitedNotificationsChange}
                    className={`GM__${theme}-toggle-input`}
                  />
                  <span className={`GM__${theme}-toggle-label`}>
                    {personalInfo.limitedNotifications
                      ? "Habilitadas"
                      : "Deshabilitadas"}
                  </span>
                </div>
              </div>

              <div className={`GM__${theme}-toggle-wrapper`}>
                <label
                  htmlFor="ownFleet"
                  className={`GM__${theme}-input-label`}
                >
                  Flota Propia
                </label>
                <div className={`GM__${theme}-toggle-container`}>
                  <input
                    id="ownFleet"
                    type="checkbox"
                    name="ownFleet"
                    checked={personalInfo.ownFleet}
                    onChange={handleOwnFleetChange}
                    className={`GM__${theme}-toggle-input`}
                  />
                  <span className={`GM__${theme}-toggle-label`}>
                    {personalInfo.ownFleet ? "Sí" : "No"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MainSectionAddPersons.propTypes = {
  personalInfo: PropTypes.shape({
    firstName: PropTypes.string,
    firstLastName: PropTypes.string,
    secondLastName: PropTypes.string,
    birthDate: PropTypes.string,
    documentNumber: PropTypes.string,
    documentExpDate: PropTypes.string,
    documentTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    documentExpeditionPlace: PropTypes.string,
    nationality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profilePhoto: PropTypes.shape({
      file: PropTypes.object,
      name: PropTypes.string,
      type: PropTypes.string,
      size: PropTypes.number,
      content: PropTypes.string,
      preview: PropTypes.string,
      documentTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    limitedNotifications: PropTypes.bool,
    ownFleet: PropTypes.bool,
  }).isRequired,
  onPersonalInfoChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onProfilePhotoChange: PropTypes.func.isRequired,
  profilePhotoTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
