import PropTypes from 'prop-types';
import PublicHeader from './PublicHeader';
import './PublicLayout.scss';

const PublicLayout = ({ children }) => {
  return (
    <div className="public-layout">
      <PublicHeader />
      <main className="public-content" id="public-content">
        {children}
      </main>
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default PublicLayout;