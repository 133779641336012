import React from 'react';
import { Chip, TextField, Select, MenuItem, Button } from '@mui/material';
import '../../assets/scss/components/filters/DocumentFilters.scss';
import PropTypes from 'prop-types';

// Documentos de vehículos que no expiran
export const NON_EXPIRABLE_VEHICLE_DOCUMENTS = [
  "TARJETA DE PROPIEDAD",
  "FOTO DEL VEHICULO"
];

export default function VehicleDocumentFilters({ 
  documents,
  onSearchChange,
  onFilterChange,
  searchValue,
  filterValue,
  onExpirationFilterClick,
  handleOpenMultiDownload,
  theme
}) {
  const getExpirationStatus = () => {
    const now = new Date();
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(now.getDate() + 30);
    
    const filterExpirableDocuments = (doc) => {
      // Si el documento está en la lista de no expirables, lo excluimos
      if (NON_EXPIRABLE_VEHICLE_DOCUMENTS.includes(doc.document_type_name)) {
        return false;
      }
      return doc.expiration_date;
    };
  
    const expired = documents.filter(doc => 
      filterExpirableDocuments(doc) && new Date(doc.expiration_date) < new Date()
    ).length;
    
    const aboutToExpire = documents.filter(doc => {
      if (!filterExpirableDocuments(doc)) return false;
      
      const expirationDate = new Date(doc.expiration_date);
      return expirationDate > new Date() && expirationDate <= thirtyDaysFromNow;
    }).length;
  
    return { expired, aboutToExpire };
  };

  const { expired, aboutToExpire } = getExpirationStatus();
  const shouldShowExpirationStatus = expired > 0 || aboutToExpire > 0;

  return (
    <div className={`GM__${theme}-document-filters-container`}>
      <div className={`GM__${theme}-filters-section`}>
        <div className={`GM__${theme}-expiration-status-section`}>
          {shouldShowExpirationStatus && (
            <Button 
              className={`GM__${theme}-expiration-status-btn`}
              onClick={() => onExpirationFilterClick('expiration')}
              variant="text"
            >
              <div className={`GM__${theme}-expiration-status`}>
                {expired > 0 && (
                  <span className={`GM__${theme}-status-item`}>
                    Documentos expirados 
                    <Chip 
                      label={expired} 
                      size="small" 
                      className={`GM__${theme}-status-chip GM__${theme}-expired`}
                    />
                  </span>
                )}
                {aboutToExpire > 0 && (
                  <span className={`GM__${theme}-status-item`}>
                    A punto de expirar 
                    <Chip 
                      label={aboutToExpire} 
                      size="small" 
                      className={`GM__${theme}-status-chip GM__${theme}-about-to-expire`}
                    />
                  </span>
                )}
              </div>
            </Button>
          )}
        </div>
        
        <div className={`GM__${theme}-right-section`}>
          <div className={`GM__${theme}-filter-controls`}>
            <TextField
              size="small"
              placeholder="Buscar..."
              value={searchValue}
              onChange={onSearchChange}
              className={`GM__${theme}-search-field`}
            />
            
            <Select
              size="small"
              value={filterValue}
              onChange={onFilterChange}
              className={`GM__${theme}-filter-select`}
            >
              <MenuItem value="all">Todos los documentos</MenuItem>
              <MenuItem value="empty">Documentos pendientes</MenuItem>
              <MenuItem value="required">Documentos requeridos</MenuItem>
              <MenuItem value="not-required">Documentos no requeridos</MenuItem>
              <MenuItem value="expiration">Documentos por vencer</MenuItem>
            </Select>
          </div>
          
          <button 
            className={`GM__${theme}-multi-download-btn`} 
            onClick={handleOpenMultiDownload}
          >
            Descargar múltiples
          </button>
        </div>
      </div>
    </div>
  );
}

VehicleDocumentFilters.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      expiration_date: PropTypes.string,
      document_type_name: PropTypes.string
    })
  ).isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  filterValue: PropTypes.oneOf([
    'all',
    'empty',
    'required',
    'not-required',
    'expiration'
  ]).isRequired,
  onExpirationFilterClick: PropTypes.func.isRequired,
  handleOpenMultiDownload: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark'])
};