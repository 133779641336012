import React, { useMemo, useState } from "react";
import { Pagination } from "@mui/material";
import clsx from "clsx";
import { useNotification } from "../../../context/NotificationContext";
import StatusDisplay from "../../loading/StatusDisplay";
import "../../../assets/scss/components/ui/tables/DocumentTypesTable.scss";
import { useDocumentTypes } from "../../../hooks/userComplementaryQuery";
import IdentityDocumentTypeForm from "../modals/IdentityDocumentTypeForm";

const ROWS_PER_PAGE = 5;

function IdentityDocumentTypesTable() {
  const {
    documentTypes,
    isLoading,
    createDocumentType,
    updateDocumentType,
    deleteDocumentType,
  } = useDocumentTypes();

  const { showNotification } = useNotification();
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  const [theme] = useState("light");

  const paginatedData = useMemo(() => {
    const startIndex = (page - 1) * ROWS_PER_PAGE;
    const paginatedDocs = documentTypes.slice(
      startIndex,
      startIndex + ROWS_PER_PAGE
    );
    const totalPages = Math.ceil(documentTypes.length / ROWS_PER_PAGE);

    return {
      items: paginatedDocs,
      totalPages,
    };
  }, [documentTypes, page]);

  const handleOpenModal = (documentType = null) => {
    setEditData(documentType);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditData(null);
    setShowModal(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm("¿Está seguro de eliminar este tipo de documento?")) {
      try {
        await deleteDocumentType(id);
        showNotification({
          message: "Tipo de documento eliminado exitosamente",
          severity: "success",
        });
      } catch (error) {
        showNotification({
          message: "Error al eliminar el tipo de documento",
          severity: "error",
        });
      }
    }
  };

  const handleSave = async (formValues) => {
    const documentData = {
      name: formValues.name,
      category: formValues.category,
      description: formValues.description,
    };

    try {
      if (formValues.id) {
        await updateDocumentType({
          id: formValues.id,
          data: documentData,
        });
      } else {
        await createDocumentType(documentData);
      }

      showNotification({
        message: `Tipo de documento ${formValues.id ? "actualizado" : "creado"} exitosamente`,
        severity: "success",
      });

      handleCloseModal();
    } catch (error) {
      showNotification({
        message: `Error al ${formValues.id ? "actualizar" : "crear"} el tipo de documento`,
        severity: "error",
      });
    }
  };
  if (isLoading) {
    return (
      <StatusDisplay type="loading" message="Cargando tipos de documentos..." />
    );
  }

  if (!documentTypes.length) {
    return (
      <div
        className={clsx("identity-document-types-no-data", `theme-${theme}`)}
      >
        <StatusDisplay
          type={204}
          message="No hay tipos de documentos registrados"
        />
      </div>
    );
  }

  return (
    <div className="custom-table-container">
      <div className="table-header">
        <h2>Tipos de Documentos de Identidad</h2>
        <button
          className="btn btn-primary"
          onClick={() => handleOpenModal(null)}
        >
          Agregar
        </button>
      </div>

      <div className="table-wrapper">
        <table className="custom-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Categoría</th>
              <th>Descripción</th>
              <th>Fecha de Creación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.items.map((docType) => (
              <tr key={docType.id}>
                <td>{docType.id}</td>
                <td>{docType.name}</td>
                <td>{docType.category}</td>
                <td>{docType.description}</td>
                <td>{new Date(docType.created_at).toLocaleDateString()}</td>
                <td className="table-actions">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={() => handleOpenModal(docType)}
                  >
                    Editar
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(docType.id)}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {paginatedData.totalPages > 1 && (
        <div className="pagination-wrapper">
          <Pagination
            count={paginatedData.totalPages}
            page={page}
            onChange={(_, value) => setPage(value)}
            shape="rounded"
            className={`custom-pagination ${theme}`}
          />
        </div>
      )}

      <IdentityDocumentTypeForm
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        data={editData}
        onSave={handleSave}
        onCancel={handleCloseModal}
      />
    </div>
  );
}

export default IdentityDocumentTypesTable;
