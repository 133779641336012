import React from "react";
import PropTypes from "prop-types";
import StreamingMedia from "../../../utils/StreamingMedia";
import "../../../assets/scss/components/ui/modal/PreviewModal.scss"

function PreviewModal({ open, onClose, doc }) {
  return (
    <div className={`preview-modal ${open ? "open" : ""}`}>
      <div className="modal-backdrop" onClick={onClose}></div>
      <div className="modal-content-preview">
        <div className="modal-body">
          {doc && (
            <div className="media-container">
              <StreamingMedia fileId={doc.id} />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button className="close-button" onClick={onClose}>
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
}

PreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  doc: PropTypes.object,
};

export default PreviewModal;
