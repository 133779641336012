import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts';

const PieChart = ({
  title = 'Distribución de Eventos',
  data = [],
  theme = 'light',
  height = '400px',
  centerText = 'Sin datos disponibles',
  hideHeader = false 
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // Configuración detallada de temas
  const themeConfig = {
    light: {
      backgroundColor: '#ffffff',
      textColor: '#333333',
      tooltipBackground: '#ffffff',
      tooltipBorder: '#dddddd',
      colors: ['#5470c6', '#91cc75', '#ee6666', '#fac858', '#73c0de'],
      legendTextColor: '#333333',
      titleColor: '#333333',
      borderColor: '#ffffff',
      emphasisShadowColor: 'rgba(0, 0, 0, 0.2)',
      centerTextColor: '#333333',
      noDataColor: '#909399'
    },
    dark: {
      backgroundColor: '#1f1f1f',
      textColor: '#ffffff',
      tooltipBackground: '#1f1f1f',
      tooltipBorder: '#333333',
      colors: ['#58a6ff', '#7ee787', '#ff7b72', '#ffa657', '#d2a8ff'],
      legendTextColor: '#ffffff',
      titleColor: '#ffffff',
      borderColor: '#1f1f1f',
      emphasisShadowColor: 'rgba(255, 255, 255, 0.1)',
      centerTextColor: '#ffffff',
      noDataColor: '#666666'
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      // Si existe una instancia previa, la destruimos
      if (chartInstance.current) {
        chartInstance.current.dispose();
      }

      // Inicializamos sin especificar tema
      chartInstance.current = echarts.init(chartRef.current);

      return () => {
        chartInstance.current?.dispose();
      };
    }
  }, [theme]);

  useEffect(() => {
    if (!chartInstance.current) return;

    const currentTheme = themeConfig[theme];

    const option = {
      backgroundColor: currentTheme.backgroundColor,
      title: {
        text: data.length === 0 ? 'No hay datos disponibles' : title,
        textStyle: {
          color: currentTheme.titleColor,
          fontSize: 16,
          fontWeight: 'normal'
        },
        left: 'center',
        top: 0,
        padding: [0, 0, 20, 0],
      },
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          return `${params.name}<br/>Cantidad: ${params.value}<br/>Porcentaje: ${params.percent}%`;
        },
        backgroundColor: currentTheme.tooltipBackground,
        borderColor: currentTheme.tooltipBorder,
        borderWidth: 1,
        padding: [8, 12],
        textStyle: {
          color: currentTheme.textColor,
          fontSize: 14
        },
        extraCssText: 'box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);'
      },
      legend: {
        show: !hideHeader, // Controlamos la visibilidad del legend
        top: '5%',
        left: 'center',
        textStyle: {
          color: currentTheme.legendTextColor,
          fontSize: 12
        },
        itemGap: 20,
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10,
        padding: [15, 0],
        formatter: (name) => {
          const item = data.find(d => d.name === name);
          if (item) {
            return `${name} (${item.value})`;
          }
          return name;
        }
      },
      series: [
        {
          name: 'Eventos',
          type: 'pie',
          radius: hideHeader ? ['30%', '70%'] : ['40%', '70%'], // Ajustamos el radio si no hay header
          avoidLabelOverlap: false,
          padAngle: 1,
          itemStyle: {
            borderRadius: 8,
            borderColor: currentTheme.borderColor,
            borderWidth: 2
          },
          label: {
            show: true,
            position: 'center',
            formatter: () => {
              if (data.length === 0) {
                return centerText;
              }
              return `${centerText}`;
            },
            color: currentTheme.centerTextColor,
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: 30,
            rich: {
              total: {
                fontSize: 28,
                fontWeight: 'bold',
                color: currentTheme.centerTextColor
              },
              label: {
                fontSize: 14,
                color: currentTheme.centerTextColor,
                padding: [4, 0]
              }
            }
          },
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              position: 'center',
              formatter: (params) => {
                return `${params.name}\n${params.value}\n(${params.percent}%)`;
              },
              color: currentTheme.centerTextColor,
              fontSize: 20,
              fontWeight: 'bold'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: currentTheme.emphasisShadowColor
            }
          },
          
          labelLine: {
            show: false
          },
          data: data.length > 0 ? data : [
            { 
              value: 100, 
              name: 'Sin datos',
              itemStyle: {
                color: currentTheme.noDataColor
              }
            }
          ],
          color: currentTheme.colors,
          animation: true,
          animationDuration: 1000,
          animationEasing: 'cubicInOut'
        }
      ]
    };

    chartInstance.current.setOption(option, true);
  }, [data, theme, title, centerText]);

  useEffect(() => {
    const handleResize = () => {
      chartInstance.current?.resize();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <div ref={chartRef} style={{ height, width: '100%' }} />;
};

PieChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  theme: PropTypes.oneOf(['light', 'dark']),
  height: PropTypes.string,
  centerText: PropTypes.string,
  hideHeader: PropTypes.bool
};

export default PieChart;