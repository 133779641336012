import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import '../../../assets/scss/components/ui/cards/TransitionCard.scss';

export default function TransitionCard({ 
  evento, 
  placasInfo,
  theme = 'light',
  borderColor = '#af5e5e',
  autoRunInterval = 4000
}) {
  const [currentPlacaIndex, setCurrentPlacaIndex] = useState(0);
  const [currentDireccionIndex, setCurrentDireccionIndex] = useState(0);
  
  // Memoizar las placas para evitar recálculos innecesarios
  const placas = useMemo(() => Object.keys(placasInfo), [placasInfo]);
  
  // Memoizar los datos actuales para evitar recálculos en cada renderización
  const currentPlaca = placas[currentPlacaIndex];
  const currentPlacaData = useMemo(() => 
    placasInfo[currentPlaca], 
    [placasInfo, currentPlaca]
  );
  
  const currentDireccion = useMemo(() => 
    currentPlacaData?.direcciones[currentDireccionIndex],
    [currentPlacaData, currentDireccionIndex]
  );

  // Memoizar la función de actualización
  const updateIndices = useCallback(() => {
    setCurrentDireccionIndex(prev => {
      if (prev + 1 >= currentPlacaData?.direcciones.length) {
        setCurrentPlacaIndex(prevPlaca => 
          prevPlaca + 1 >= placas.length ? 0 : prevPlaca + 1
        );
        return 0;
      }
      return prev + 1;
    });
  }, [currentPlacaData?.direcciones.length, placas.length]);

  // Efecto para el intervalo
  useEffect(() => {
    const interval = setInterval(updateIndices, autoRunInterval);
    return () => clearInterval(interval);
  }, [updateIndices, autoRunInterval]);

  // Memoizar la función de click
  const handleDireccionClick = useCallback(() => {
    if (currentDireccion) {
      const url = `https://www.google.com/maps?q=${currentDireccion.latitud},${currentDireccion.longitud}`;
      window.open(url, '_blank');
    }
  }, [currentDireccion]);

  return (
    <div className={`card-transition ${theme}`}>
      <div 
        className="card-border" 
        style={{ backgroundColor: borderColor }} 
      />
      <div className="card-content">
        <h3 className="card-title">{evento}</h3>
        <p 
          className="card-direction" 
          onClick={handleDireccionClick}
        >
          {currentDireccion?.direccion || 'Sin dirección'}
        </p>
        <div className="card-stats">
          <span className="placa">{currentPlaca}</span>
          <span className="count">
            {currentPlacaData?.count || 0} veces
          </span>
        </div>
      </div>
    </div>
  );
}

TransitionCard.propTypes = {
  evento: PropTypes.string.isRequired,
  placasInfo: PropTypes.objectOf(
    PropTypes.shape({
      direcciones: PropTypes.arrayOf(
        PropTypes.shape({
          direccion: PropTypes.string,
          latitud: PropTypes.number,
          longitud: PropTypes.number,
        })
      ).isRequired,
      count: PropTypes.number
    })
  ).isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  borderColor: PropTypes.string,
  autoRunInterval: PropTypes.number
};